import React from "react";
import { Button, Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const ShowMore = () => {
  const router = useHistory();

  const handleClose = (e) => {
    e.preventDefault();
    router.push("/plans");
  };

  return (
    <Alert
      variant="success"
      className="ue-alert-1"
      style={{ margin: "0.5rem 1rem" }}
    >
      <p style={{ fontSize: 14, margin: 0 }}>
        You have reached your free plan limit. Unlock all insights by upgrading
        your account
      </p>
      <Button variant="primary" onClick={handleClose} size="sm">
        See my options
      </Button>
    </Alert>
  );
};
