/* eslint-disable no-unused-vars */
import React from "react";
import style from "./note.module.css";
import { MdOutlineCheckCircle } from "react-icons/md";

export default function Note() {
  return (
    <div>
      <div className={style.note}>
        <div className={style.noteHeading}>Things to Note</div>
        <div className={style.noteListWrapper}>
          <li className={style.noteList}>
            <span>
              <MdOutlineCheckCircle className={style.noteListIcon} />
            </span>
            We never share your full profile with anyone and it is solely used
            for the purposes of providing you with research opportunities.
          </li>
          <li className={style.noteList}>
            <span>
              <MdOutlineCheckCircle className={style.noteListIcon} />
            </span>
            If you participate in a study, part of your profile may be shared
            with the researchers.
          </li>
          <li className={style.noteList}>
            <span>
              <MdOutlineCheckCircle className={style.noteListIcon} />
            </span>
            Adding more details to your profile will increase the chances of
            getting invited to more studies.
          </li>
          <li className={style.noteList}>
            <span>
              <MdOutlineCheckCircle className={style.noteListIcon} />
            </span>
            Use LinkedIn Import feature to quickly build your profile and get a
            verified badge.
          </li>
        </div>
      </div>
    </div>
  );
}
