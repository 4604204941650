import React from "react";
import { Button, Form } from "react-bootstrap";
import { Text } from "@mantine/core";
import { signup } from "../../../../store/actions/authActions";
import { checkPassword } from "../../../../utils/checkPassword";
import { toastError, toastSuccess } from "../../../../utils/toast";
import css from "./auth.module.css";

const Signup = ({ toggleScreen, testId }) => {
  const handleSignup = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const values = Object.fromEntries(formData.entries());

    const name = `${values.firstName} ${values.lastName}`;

    const notValid = checkPassword(values.password, values.password);
    if (notValid) return toastError(notValid);

    const { data, error } = await signup({
      name,
      email: values?.email,
      password: values?.password,
      organizationName: values?.organizationName,
      resendVerification: values?.resendVerification,
      isParticipant: true,
      testId,
    });

    if (error)
      return toastError(
        error?.message ||
          "Something went wrong while creating your account. Please try again or contact support."
      );

    if (data) {
      toastSuccess("Account created successfully! Please login to continue.");
      toggleScreen();
    }
  };

  return (
    <>
      <Text size="xl" weight={700}>
        Create an account
      </Text>

      <Text size="md" className="mb-4">
        Already have an account?&nbsp;
        <button onClick={toggleScreen} className={css.btn}>
          Login now
        </button>
      </Text>

      <Form onSubmit={handleSignup}>
        <Form.Group className="mb-3 d-flex gap-3">
          <Form.Control
            type="text"
            placeholder="First Name"
            required
            name="firstName"
          />

          <Form.Control
            type="text"
            placeholder="Last Name"
            required
            name="lastName"
          />
        </Form.Group>

        <Form.Control
          type="text"
          placeholder="Organization name"
          name="organizationName"
          className="mb-3"
        />

        <Form.Control
          type="email"
          placeholder="Email address"
          required
          name="email"
          className="mb-3"
        />

        <Form.Control
          type="password"
          placeholder="Password"
          required
          name="password"
          className="mb-3"
        />

        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            label={
              <>
                I agree to User Evaluation&apos;s&nbsp;
                <a
                  href="https://www.userevaluation.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>
                &nbsp;and&nbsp;
                <a
                  href="https://www.userevaluation.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </>
            }
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit" className="w-100">
          Sign up
        </Button>
      </Form>
    </>
  );
};

export default Signup;
