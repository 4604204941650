import React from "react";
import { FaPlus } from "react-icons/fa";

export const TEMPLATES = [
  {
    icon: <FaPlus />,
    name: "Start from Scratch",
    content: "",
  },
  {
    icon: "🌟",
    name: "Discovery Interview",
    content: `I want to conduct a Discovery Interview to understand user needs, pain points, and motivations. This interview will focus on gathering insights into what users really want from our product and what obstacles they currently face. By engaging in open-ended conversations, I aim to uncover hidden needs and desires that might not be immediately apparent, paving the way for innovative solutions and product improvements.
This process will involve carefully crafted questions that encourage users to share their experiences and thoughts in depth. It's essential for understanding the 'why' behind user behavior, which will inform our product development and ensure we're creating something that genuinely meets user needs.`,
  },
  {
    icon: "💻",
    name: "Usability Test",
    content: `I plan to conduct a Usability Test to assess how users interact with our product. This test will involve observing users as they navigate our software, identifying any areas where they struggle or become confused. My goal is to pinpoint usability issues and gather actionable feedback that can be used to enhance the user experience.
During this test, I'll pay close attention to how users complete specific tasks and ask them to verbalize their thought process. This will help me understand their expectations and experiences, allowing us to make targeted improvements that make our product more intuitive and user-friendly.`,
  },
  {
    icon: "😀",
    name: "Customer Satisfaction Interview",
    content: `I aim to conduct a Customer Satisfaction Interview to gauge how happy users are with our product. This involves asking targeted questions to understand their overall satisfaction, what they like most, and what areas need improvement. The goal is to gather feedback that will help us enhance user satisfaction and loyalty.
These interviews will provide valuable insights into how users perceive our product and services. I'll be looking for patterns in feedback that can guide our strategies for product updates, customer service improvements, and overall user experience enhancement.`,
  },
  {
    icon: "🏆",
    name: "Competitive Analysis Interview",
    content: `I intend to undertake a Competitive Analysis Interview to understand how our product stacks up against competitors. This will involve discussing with users their experiences with competitor products, what they prefer, and where they think we can do better. It’s a crucial step for identifying our unique selling points and areas for improvement.
By understanding the strengths and weaknesses of competitors through the eyes of users, I can better position our product in the market. This information is vital for our marketing strategies and for ensuring that our product continues to stand out in a crowded market.`,
  },
  {
    icon: "🗺️",
    name: "Customer Journey Mapping",
    content: `I want to perform a Customer Journey Mapping Interview to chart the entire user journey with our product, from discovery to long-term use. This will involve asking users about each stage of their experience, noting both the high points and pain points. My aim is to identify opportunities for enhancing the user journey at every step.
This comprehensive view will help us understand the user's perspective throughout their entire interaction with our product. It’s essential for ensuring a seamless, enjoyable experience that encourages ongoing engagement and loyalty.`,
  },
  {
    icon: "🔍",
    name: "Pain Point Interview",
    content: `My goal is to conduct a Pain Point Interview to directly address and understand the specific struggles users face with our product. This involves deep diving into their frustrations and challenges, allowing us to prioritize improvements in areas that matter most to our users.
By focusing on pain points, I can gather focused insights that are critical for enhancing user satisfaction. This process will not only help in problem-solving but also in building a product that resonates more deeply with our user base.`,
  },
  {
    icon: "🌈",
    name: "Brand Perception Interview",
    content: `I plan to engage in a Brand Perception Interview to understand how users perceive our brand. This involves discussing our brand identity, values, and how well they resonate with our audience. It's an opportunity to align our brand more closely with user expectations and market trends.
These insights will be invaluable for our marketing and branding strategies, ensuring that we project an image that appeals to our target audience. Understanding how users view us versus how we intend to be seen can highlight gaps and opportunities for improvement.`,
  },
  {
    icon: "👥",
    name: "Persona Development Interview",
    content: `I intend to conduct a Persona Development Interview to create detailed user personas. This will involve talking to a diverse range of users to gather comprehensive data about their backgrounds, needs, preferences, and behaviors. These personas will represent our key user groups, guiding our product development and marketing efforts.
Creating these personas helps ensure that our team keeps the user front and center in everything we do. It's a tool for empathizing with our users and making decisions that cater to their specific needs and preferences.`,
  },
];
