import { Modal } from "@mantine/core";
import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import styles from "./tagPopUp.module.css";
import { deleteTag, moveTagCard } from "../../../store/actions/tagsActions";
import { HiChevronUpDown } from "react-icons/hi2";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
function TagPopUp({
  id,
  show,
  handleHide,
  tagId,
  tagText,
  source,
  sectionTitle,
  differentTags,
  publicBoard,
}) {
  const onDelete = () => {
    deleteTag(id);
    handleHide(false);
    toast.success("The tag has been deleted.");
  };
  return (
    <Modal
      size="lg"
      opened={show}
      onClose={(e) => {
        e?.stopPropagation();
        handleHide(false);
      }}
      title="Tag"
    >
      <div className={styles.tagsModalContainer}>
        <div>
          <Row
            className="project-board-row"
            style={{ flexWrap: "no-wrap"}}
          >
            <Col xs={3} className="project-overview-label">
              Section
            </Col>

            <Col className="project-overview-value" style={{ width: "75%" }}>
              {publicBoard ? (
                sectionTitle
              ) : (
                <DropDown
                  id={id}
                  differentTags={differentTags}
                  tagId={tagId}
                  sectionTitle={sectionTitle}
                />
              )}
            </Col>
          </Row>
          <Row
            className="project-board-row"
            style={{
              padding: "2px 0px",
              marginTop: "6px",
            }}
          >
            <Col
              xs={3}
              className="project-overview-label"
              style={{
                lineHeight: "42px",
              }}
            >
              Source
            </Col>
            <Col className={styles.source}>
              {source ? source : "No source added"}
            </Col>
          </Row>
          <Row
            className="project-board-row"
            style={{
              padding: "2px 0px",
              marginTop: "6px",
            }}
          >
            <Col
              xs={3}
              className="project-overview-label"
              style={{
                lineHeight: "42px",
              }}
            >
              Context
            </Col>
            <Col className={styles.source}>{tagText}</Col>
          </Row>
        </div>

        {!publicBoard && (
          <div
            className={`d-flex justify-content-end ${styles.deleteCardButton}`}
          >
            <Button variant="danger" onClick={onDelete}>
              Delete Card
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
}

function DropDown({ differentTags, id, tagId, sectionTitle }) {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  return (
    <div className={styles.tagsSelectionContainer}>
      <div
        onClick={() => {
          setShow(!show);
        }}
        className={styles.selectedTag}
      >
        <span>{sectionTitle}</span>
        <HiChevronUpDown />
      </div>
      {show && (
        <div className={styles.tagsSelection}>
          {differentTags?.map((tag) => {
            return (
              <div
                key={tag._id}
                onClick={() => {
                  (async () => {
                    let res = await dispatch(
                      moveTagCard({
                        cardId: id,
                        targetLaneId: tag._id,
                        sourceLaneId: tagId,
                      })
                    );
                    if (res?.success) {
                      toast.success("Your tag has been moved.");
                    }
                  })();
                  setShow(false);
                }}
              >
                <span
                  style={{
                    backgroundColor: tag?.color,
                    padding: "5px 10px",
                    borderRadius: "5px",
                  }}
                >
                  {tag?.title}
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default TagPopUp;
