import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Card, Button, Spinner, Col, Row, Container } from "react-bootstrap";
import { IoMdDocument } from "react-icons/io";
import { loadPresentations } from "../../../store/actions/currentProjectActions";
import { toastError } from "../../../utils/toast";
import PromptInput from "./components/PromptInput";
import lookoutWhite from "../../../images/lookout-white.svg";
import lookout from "../../../images/lookout.svg";
import chartImg from "../../../images/people-evaluation.svg";
import chartImgBlack from "../../../images/people-evaluation-black.svg";
import useSocketContext from "../../../utils/SocketContext";

export const PresentationCard = ({ title, url, index }) => {
  const [showPreview, setShowPreview] = useState(false);

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  return (
    <Row className="justify-content-center">
      <Col xs={12} md={8} className="mt-3">
        <Card className="w-100">
          <Card.Body className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "#bce4fd",
                  marginRight: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IoMdDocument size={20} color="#007bff" />
              </div>
              <div>
                <Card.Title>{`${title} ${index + 1}`}</Card.Title>
              </div>
            </div>
            <div>
              <Button variant="outline-primary" href={url}>
                Download
              </Button>{" "}
              <Button variant="outline-secondary" onClick={togglePreview}>
                {showPreview ? "Hide" : "Preview"}
              </Button>
            </div>
          </Card.Body>

          {showPreview && (
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
              width="100%"
              height="600px"
              frameBorder="0"
            ></iframe>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export const PreparingData = ({ darkMode }) => {
  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <div className="text-center">
        <img
          src={darkMode ? chartImg : chartImgBlack}
          alt="processing"
          className="processing-img"
        />
        <br />
        <h1 className="processing-heading">Your data is being processed</h1>
        <p className="processing-paragraph">
          Please give our AI a few minutes before it can answer all your
          questions.
        </p>
        <Spinner animation="border" variant="outline-primary" size="sm" />
        <br />
      </div>
    </div>
  );
};

export default function ProjectPptx() {
  const socketHandlers = useCallback(
    {
      pptxUpdate: (payload) => {
        switch (payload.status) {
          case "Error":
            console.error(payload.message);
            toastError(payload.message);
            setProgressPercent(0);
            break;
          case "Processing":
            setProgressPercent(payload.progress);
            setProgressMessage(payload.message);
            break;
          case "Finished":
            loadPresentations(projectData._id);
            setProgressPercent(0);
            break;
          default:
            console.warn("Unknown pptxUpdate status:", payload.status);
        }
      },
    },
    []
  );

  // const socket = useSocketIO(socketHandlers);
  const socket = useSocketContext();
  useEffect(() => {
    if (socket) {
      socket.attachHandlers(socketHandlers);
    }
    return () => socket.detachHandlers(socketHandlers);
  }, [socket, socketHandlers]);

  const projectData = useSelector((state) => state.currentProject.project);
  const { darkMode } = useSelector((state) => state.theme);
  const [progressMessage, setProgressMessage] = useState("");
  const [progressPercent, setProgressPercent] = useState(0);

  // state for loading presentations
  const [loadedProjectId, setLoadedProjectId] = useState(null);
  useEffect(() => {
    if (projectData?._id && projectData._id !== loadedProjectId) {
      loadPresentations(projectData._id);
      setLoadedProjectId(projectData._id);
    }
  }, [projectData, loadedProjectId]);

  const handleSubmit = async (prompt) => {
    if (!prompt) {
      toastError(
        "Please provide some instructions to the AI to create a presentation."
      );
      return;
    }

    // prompt must be less than 500 words
    if (prompt.split(" ").length > 500) {
      toastError("You can only provide up to 500 words of instructions.");
      return;
    }

    socket.createPptx(projectData._id, prompt);
  };
  const emptyProject = (
    <Container className="d-flex align-items-center justify-content-center h-100">
      <div className="text-center">
        <img
          src={darkMode ? lookoutWhite : lookout}
          alt="processing"
          className="processing-img"
          style={{ maxWidth: "400px" }}
        />
        <br />
        <h1 className="processing-heading">Your project looks empty</h1>
        <p className="processing-paragraph">
          You have not uploaded files to your project yet. Upload files to get
          started.
        </p>

        <Link to={"/project/" + projectData._id}>
          <Button variant="outline-primary">Upload Files</Button>
        </Link>
      </div>
    </Container>
  );
  return (
    <>
      {projectData?._id && projectData?.status === "Empty" && emptyProject}

      {projectData?.status !== "Ready" &&
        !(projectData?._id && projectData?.status === "Empty") && (
          <PreparingData darkMode={darkMode} />
        )}

      {projectData?.status === "Ready" && (
        <>
          <PromptInput
            handleSubmit={handleSubmit}
            progressPercent={progressPercent}
            progressMessage={progressMessage}
          />

          {projectData?.presentations?.length > 0 &&
            projectData?.presentations?.map((pres, index) => {
              return (
                <PresentationCard
                  title={pres.title}
                  url={pres.url}
                  index={index}
                  key={index}
                />
              );
            })}
        </>
      )}
    </>
  );
}
