import React from "react";
import { ListGroup, Container } from "react-bootstrap";
import gradientSparkle from "../../images/gradient-sparkle.svg";
import gradientCheck from "../../images/gradient-check.svg";

export default function EmptyState() {
  return (
    <Container
      className="everything-inside-is-centered "
      style={{
        alignItems: "center",
      }}
    >
      <Container
        style={{
          maxWidth: "1000px",
          lineHeight: "2.5",
          paddingBottom: "20vh",
        }}
      >
        <img src={gradientSparkle} alt="icon" />
        <br />
        <h1 className="gradient-heading">Chat with AI</h1>
        <div className="chatInfoContainer">
          <ListGroup>
            <h4 className="mt-3">Capabilities</h4>
            <ListGroup.Item className="bullet-checkmark">
              <img src={gradientCheck} alt="check" className="gradient-check" />
              Has context of the files you have added
            </ListGroup.Item>
            <ListGroup.Item className="bullet-checkmark">
              <img src={gradientCheck} alt="check" className="gradient-check" />
              Remembers previous messages
            </ListGroup.Item>
            <ListGroup.Item className="bullet-checkmark">
              <img src={gradientCheck} alt="check" className="gradient-check" />
              Can visualize trends using tables, graphs and flow charts
            </ListGroup.Item>
          </ListGroup>
          <ListGroup>
            <h4 className="mt-3">Limitations</h4>
            <ListGroup.Item className="bullet-checkmark">
              <img src={gradientCheck} alt="check" className="gradient-check" />
              May occasionally generate incorrect information
            </ListGroup.Item>
            <ListGroup.Item className="bullet-checkmark">
              <img src={gradientCheck} alt="check" className="gradient-check" />
              May produce harmful content
            </ListGroup.Item>
          </ListGroup>
        </div>
      </Container>
    </Container>
  );
}
