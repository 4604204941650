import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import Button from "./button";
import truncateString from "../../../../../utils/truncateString";
import { useDispatch, useSelector } from "react-redux";
import { ribbonActions } from "../../../../../store/slices/ribbonSlice";

function DropDown({ item, subItems, hideName, icon, isTag }) {
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.ribbon);
  const dropDownRef = useRef(null);
  const handleSave = () => {
    if (isTag) {
      dispatch(ribbonActions.setRibbonData({ name: "tags", value: selected }));
      setShow(false);
      return;
    }
    dispatch(
      ribbonActions.setRibbonData({ name: "sessions", value: selected })
    );
    setShow(false);
  };
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropDownRef.current && !dropDownRef.current.contains(e.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (isTag && data?.tags?.length > 0) {
      setSelected(data?.tags);
      return;
    } else if (data?.sessions?.length) {
      setSelected(data?.sessions);
    }
  }, [data?.tags, data?.sessions, isTag]);
  return (
    <>
      <div className={styles.toolbar__dropdown} ref={dropDownRef}>
        <Button
          className={styles.toolbar__dropdown__item}
          hideName={hideName}
          ele={{ name: item, icon: icon }}
          pressed={show}
          handleClick={() => {
            setShow(!show);
          }}
        />
        {subItems?.length > 0 ? (
          <div
            className={`${styles.toolbar__dropdown__subitems} ${
              show ? styles.active : ""
            }`}
          >
            {show && (
              <>
                {subItems.map((subItem, index) => (
                  <DropDownItems
                    key={index}
                    subItem={subItem}
                    selected={selected}
                    setSelected={setSelected}
                  />
                ))}
                <div className={styles.toolbar__dropdown__selection}>
                  <button
                    className={styles.toolbar__dropdown__selection__item}
                    onClick={() => {
                      setSelected(
                        subItems.map((subItem) => subItem._id || subItem)
                      );
                    }}
                  >
                    Select All
                  </button>
                  <button
                    className={styles.toolbar__dropdown__selection__item}
                    onClick={() => {
                      setSelected([]);
                    }}
                  >
                    Select None
                  </button>
                  <button
                    className={styles.toolbar__dropdown__selection__item}
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </div>
              </>
            )}
          </div>
        ) : (
          <div
            className={`${styles.toolbar__dropdown__subitems} ${
              styles.toolbar__dropdown__subitems__empty
            } ${show ? styles.active : ""}`}
          >
            <h4 style={{ fontSize: "0.85rem" }}>
              {isTag ? "No tags found" : "No sessions found"}
            </h4>
          </div>
        )}
      </div>
    </>
  );
}

const DropDownItems = ({ subItem, setSelected, selected }) => {
  useEffect(() => {
    return () => {
      setSelected([]);
    };
  }, []);
  return (
    <div className={styles.toolbar__dropdown__subitems__item}>
      {truncateString(subItem?.title || subItem, 20)}
      <input
        type="checkbox"
        className={styles.toolbar__dropdown__subitems__item__checkbox}
        onChange={() => {
          if (!subItem._id) {
            if (selected.includes(subItem)) {
              setSelected(selected.filter((id) => id !== subItem));
            } else {
              setSelected([...selected, subItem]);
            }
            return;
          }
          if (selected.includes(subItem._id)) {
            setSelected(selected.filter((id) => id !== subItem._id));
            return;
          }

          setSelected([...selected, subItem._id]);
        }}
        checked={selected.includes(subItem._id || subItem)}
      />
    </div>
  );
};
export default DropDown;
