import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Modal,
  Spinner,
  Dropdown,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { FiFileText, FiVideo, FiMusic, FiFile } from "react-icons/fi";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import debounce from "../../../../utils/debounce";
import { toastSuccess, toastError } from "../../../../utils/toast";
import {
  addVideoToProject,
  loadProjectsAction,
} from "../../../../store/actions/projectActions";
import importIcon from "../../../../images/icons/upload-cloud.svg";
import trashIcon from "../../../../images/icons/trash.svg";
import filmIcon from "../../../../images/icons/film.svg";
import plusSquareIcon from "../../../../images/icons/plus-square.svg";
import { fileTypeWhitelist } from "../constants";
import { FiPaperclip } from "react-icons/fi";
import AddMediaLibrary from "./AddMediaLibrary";
import "./index.css";

// DROPZONE STYLING
const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
// DROPZONE STYLING END

const helpTextDefault = `Drop your audio, video, CSV or text files in the drop zone to initiate processing. 
Upon upload, your project status will be updated to Processing and will remain so until all files have been successfully processed. If processing takes too long, please try refreshing the page.`;

export const NewAsset = ({
  /** Project Id to which these videos will assigned to,
   * if undefined they are just added to user's account and can be seen in asset library */
  projectId,
  runGetProjectData,
  /** @enum button | icon | block */
  buttonType,
  /** callback called each time a file is successfully uploaded */
  onUploaded,
  /** text above dropzone instructing about how upload works */
  helpText,
  /** Custom button component passed from parent */
  buttonComponent,
  setShowAssetBox,
  showAssetBox,
}) => {
  const { account } = useSelector((state) => state.auth);

  const [show, setShow] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedTab, setSelectedTab] = useState("UPLOAD"); // UPLOAD or MEDIA
  const [selectedAssets, setSelectedAssets] = useState([]);

  const handleClose = () => {
    setShow(false);
    if (setShowAssetBox) setShowAssetBox(false);
  };
  const handleShow = () => setShow(true);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (account?.plan?.type === "Free" && acceptedFiles.length > 2) {
        toastError(
          "You can only upload two files per project in the free plan. Please upgrade to a paid plan to upload more files."
        );
        return;
      } else {
        // push accepted files to files array and set status to Waiting...

        acceptedFiles.forEach((file) => {
          setFiles((prev) => [...prev, { file, status: "Waiting...", id: "" }]);
        });

        // Loop through the accepted files and upload them one by one
        for (let i = 0; i < acceptedFiles.length; i++) {
          // Set the current file
          let file = acceptedFiles[i];

          // Check if file size is larger than 500MB
          if (file.size > 500 * 1024 * 1024) {
            // Update the status message for the current file
            let statusMessage = "Error";
            setFiles((prev) =>
              prev.map((fileData) => {
                if (fileData.file.name === file.name) {
                  return { file: fileData.file, status: statusMessage };
                }
                return fileData;
              })
            );

            // Show an error toast
            toastError(
              `Your file ${file.name} is larger than 500MB. Please select a smaller file or use a file compression tool to reduce size.`
            );

            // remove the file from the files array
            const newFiles = files.map((fileData) => {
              if (fileData.file.name !== file.name) {
                return fileData;
              }
            });
            setFiles(newFiles);

            continue;
          }

          // const allowedFileTypes = ["mp4", "webm", "amr", "flac", "wav", "ogg", "mp3", "txt", "audio/mpeg", "audio/ogg", "audio/wav", "audio/flac", "audio/amr", "audio/mp3", "video/mp4", "video/webm", "text/plain"];
          let allowedFileTypes = [
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/pdf",
            "text/plain",
            "text/csv",
            "3ga",
            "8svx",
            "aac",
            "ac3",
            "aif",
            "aiff",
            "alac",
            "amr",
            "ape",
            "au",
            "dss",
            "flac",
            "flv",
            "x-m4a",
            "m4a",
            "m4b",
            "m4p",
            "m4r",
            "mp3",
            "mpga",
            "ogg",
            "oga",
            "mogg",
            "opus",
            "qcp",
            "tta",
            "voc",
            "wav",
            "wma",
            "wv",
            "webm",
            "MTS",
            "M2TS",
            "TS",
            "mov",
            "mp2",
            "mp4",
            "mov",
            "m4p",
            "m4v",
            "mxf",
            "audio/mpeg",
            "audio/m4a",
            "audio/x-m4a",
            "audio/ogg",
            "audio/wav",
            "audio/flac",
            "audio/amr",
            "audio/mp3",
            "video/mp4",
            "video/webm",
            "video/quicktime",
          ];

          // Check if file type is not a video, audio, or text file

          if (!allowedFileTypes.includes(file.type)) {
            // Update the status message for the current file
            let statusMessage = "Error";
            setFiles((prev) =>
              prev.map((fileData) => {
                if (fileData.file.name === file.name) {
                  return { file: fileData.file, status: statusMessage };
                }
                return fileData;
              })
            );

            // Show an error toast
            toastError(
              `File ${file.name} is not a supported file type. Please select a valid audio, video, text or CSV file.`
            );

            // remove the file from the files array
            const newFiles = files.map((fileData) => {
              if (fileData.file.name !== file.name) {
                return fileData;
              }
            });
            setFiles(newFiles);

            continue;
          }

          // Create a new form to handle the request
          let form = new FormData();

          // update status on file
          let statusMessage = "Uploading...";
          setFiles((prev) =>
            prev.map((fileData) => {
              if (fileData.file.name === file.name) {
                return { file: fileData.file, status: statusMessage };
              }
              return fileData;
            })
          );

          form.append("user", account.email);
          // Add the file to the form data
          form.append("file", file);

          try {
            // Upload the file to the server using axios
            // use REACT_APP_API_SERVER_URL
            let response = await axios.post(
              process.env.REACT_APP_API_SERVER_URL + "/api/video/upload",
              form
            );

            // Check the status code of the response
            if (response.status !== 200) {
              throw new Error(response.statusText);
            }

            // Get the response data and add _id to files array
            let { data } = response;

            // Clear the form object

            // Update the status message for the current file
            let statusMessage = "Uploaded";
            setFiles((prev) =>
              prev.map((fileData) => {
                if (fileData.file.name === file.name) {
                  return {
                    file: fileData.file,
                    status: statusMessage,
                    id: data._id,
                  };
                }
                return fileData;
              })
            );

            // upon success
            toastSuccess(`Your file has been uploaded: ${file.name}`);
            if (typeof onUploaded === "function") {
              onUploaded();
            }
          } catch (error) {
            // Update the status message for the current file
            let statusMessage = "Error";
            setFiles((prev) =>
              prev.map((fileData) => {
                if (fileData.file.name === file.name) {
                  return { file: fileData.file, status: statusMessage };
                }
                return fileData;
              })
            );

            toastError(
              `An error occurred while uploading ${file.name}. ` +
                error?.response?.data?.message
            );
          }
        }
      }
    },
    [account, files]
  );

  const deleteFile = (file) => {
    // remove the file from the files state
    const index = files.findIndex(
      (fileData) => fileData.file.name === file.file.name
    );
    if (index !== -1) {
      files.splice(index, 1);
      setFiles([...files]);
    }
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isDragActive,
  } = useDropzone({ onDrop, accept: fileTypeWhitelist });

  const style = useMemo(
    () => ({
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  useEffect(() => {
    setShow(showAssetBox);
  }, [showAssetBox]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // check if there are any files in the files state

    if (files.length === 0 && selectedAssets.length === 0) {
      toastError(
        "Please upload at least one file, or select one from files library"
      );
      return;
    }

    let payload = {
      projectId: projectId,
      userEmail: account.email,
      videos: files.map((file) => file.id),
    };

    if (selectedAssets) {
      payload.videos = [...payload.videos, ...selectedAssets];
    }

    try {
      // Upload the file to the server using sevice
      let { data, error } = await addVideoToProject(payload);

      if (error) {
        toastError(error.message);
        return;
      }
      if (data) {
        toastSuccess(`Your file(s) have been added to the project`);
        if (runGetProjectData) {
          runGetProjectData(projectId, true);
        }

        // Load all the projects again so that data is refreshed in the homepage when the asset is uploaded
        loadProjectsAction(account.email);
        handleClose();
      }
    } catch (error) {
      toastError(
        `An error occurred while adding file(s) to the project. ` +
          error?.response?.data?.message
      );
    }
  };

  let button;
  if (buttonComponent) {
    button = React.cloneElement(buttonComponent, { onClick: handleShow });
  } else if (buttonType === "button" || !buttonType) {
    button = (
      <Button variant="primary" onClick={handleShow}>
        {" "}
        Add File{" "}
      </Button>
    );
  } else if (buttonType === "icon") {
    button = (
      <img
        src={plusSquareIcon}
        alt="Add File"
        onClick={handleShow}
        style={{
          width: "16px",
          height: "16px",
          marginLeft: "10px",
        }}
      />
    );
  } else if (buttonType === "block") {
    button = (
      <div className="add-asset" onClick={handleShow}>
        <img src={plusSquareIcon} alt="film icon" className="mb-2" />
        <p className="mb-1">Add new</p>
      </div>
    );
  } else if (buttonType === "text") {
    button = (
      <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle
          style={{
            height: "1.5rem",
            display: "flex",
            alignItems: "center",
            borderRadius: "0.25rem",
            padding: "0.25rem 0.35rem",
            border: "none",
            fontSize: "0.8rem",
            backgroundColor: "var(--grey3)",
            color: "var(--grey7)",
          }}
          id="dropdown-split-basic"
        >
          <FiPaperclip />
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{
            border: "none",
            // add box shadow to the button
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            padding: "0.5rem",
          }}
        >
          <Dropdown.Item eventKey="1" onClick={handleShow}>
            <FiFileText /> Upload Document
          </Dropdown.Item>
          <Dropdown.Item eventKey="2" onClick={handleShow}>
            <FiVideo /> Upload Video
          </Dropdown.Item>
          <Dropdown.Item eventKey="3" onClick={handleShow}>
            <FiMusic /> Upload Audio
          </Dropdown.Item>
          <Dropdown.Item eventKey="4" onClick={handleShow}>
            <FiFile /> Upload CSV
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  const isAddButtonDisabled = function () {
    if (files.length === 0 && selectedAssets.length === 0) {
      return true;
    }
    const someFilesUploading = files.some(
      (file) => file.status === "Uploading..."
    );
    // return someFilesUploading
    if (someFilesUploading) {
      return true;
    }
    return false;
  };
  const addToProjectButton = projectId && (
    <Button
      variant="primary"
      onClick={debounce(handleSubmit, 1000)}
      // disable button if no files are uploaded or if files are uploading
      disabled={isAddButtonDisabled()}
    >
      Add to Project
    </Button>
  );

  const uploadView = (
    <React.Fragment>
      <p className="mb-3">{helpText || helpTextDefault}</p>

      <div {...getRootProps({ style })} className="project-dropzone">
        <input {...getInputProps()} />
        {isDragActive ? (
          <div className="text-center">
            <img
              src={importIcon}
              alt="Import icon"
              className={"project-dropzone-image"}
            />
            <br />
            <br />
            <h4 className="project-dropzone-heading">Drop files here</h4>
            <p className="project-dropzone-subheading">
              You can drop multiple files.
            </p>
          </div>
        ) : (
          <div className="text-center">
            <img src={importIcon} alt="Import icon" />
            <br />
            <br />
            <h4 className="project-dropzone-heading">
              Drag &amp; drop or click to browse
            </h4>
            <p className="project-dropzone-subheading">
              You can upload research session videos, audio, text or CSV files.
            </p>
          </div>
        )}
      </div>

      {/* display uploaded files */}
      {files.map((file, index) => (
        <div key={index} className="file-container mt-4">
          <div className="file-icon">
            {/* display spinner if status is uploading or waiting */}
            {file.status === "Uploading..." || file.status === "Waiting..." ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <img src={filmIcon} alt="File Icon" />
            )}
          </div>
          <div className="file-details">
            <div className="file-name">{file.file.name}</div>
            <div className="file-size">
              {/* in mbs upto 2 decimal places*/}
              {Math.round((file.file.size / 1000000) * 100) / 100} MB •{" "}
              {file.status}
            </div>
          </div>

          <div className="delete-icon" onClick={() => deleteFile(file)}>
            <img src={trashIcon} alt="Delete Icon" />
          </div>
        </div>
      ))}
    </React.Fragment>
  );

  return (
    <>
      {/* if buttonType is button or undefined */}
      {button}

      {/* longer width model */}
      <Modal
        scrollable
        show={show}
        onHide={handleClose}
        size="lg"
        style={{
          zIndex: "9998",
        }}
        className="responsive-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1rem" }}>Add a New File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {projectId && (
            <ButtonGroup className="mb-2 asset-tabs">
              <ToggleButton
                onClick={() => setSelectedTab("UPLOAD")}
                type="checkbox"
                checked={selectedTab === "UPLOAD"}
              >
                Upload
              </ToggleButton>
              <ToggleButton
                onClick={() => setSelectedTab("MEDIA")}
                type="checkbox"
                checked={selectedTab === "MEDIA"}
              >
                Files Library
              </ToggleButton>
            </ButtonGroup>
          )}

          {selectedTab === "UPLOAD" && uploadView}
          {selectedTab === "MEDIA" && (
            <AddMediaLibrary
              selected={selectedAssets}
              onChange={setSelectedAssets}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {addToProjectButton}
        </Modal.Footer>
      </Modal>
    </>
  );
};
