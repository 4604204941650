import React from "react";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { FormControl, Form, Button, InputGroup } from "react-bootstrap";
import {
  MdOutlineSearch,
  MdOutlinePersonAddAlt,
  MdOutlineContentCopy,
} from "react-icons/md";
import style from "./ribbon.module.css";

function Ribbon({ searchTerm, handleSearchChange, testId }) {
  const [isOpen, { open, close }] = useDisclosure();

  return (
    <div className={style.ribbonContainer}>
      <div className={style.ribbon}>
        <span className={style.ribbonOption}>
          <MdOutlineSearch className={style.ribbonIcon} />
          <FormControl
            type="text"
            placeholder="Search Participants"
            className={style.ribbonSearch}
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </span>
        <span className={style.pipe}></span>
        <span className={style.ribbonOption}>
          <MdOutlinePersonAddAlt className={style.ribbonIcon} />
          <button className={style.ribbonButton} onClick={open}>
            Invite a Participant
          </button>
        </span>
      </div>

      <Modal
        opened={isOpen}
        onClose={close}
        size="lg"
        title="Invite a Participant"
      >
        <p>Share this interview with anyone using the link below</p>
        <InputGroup className="mb-2">
          <Form.Control
            readOnly
            value={`${window.location.origin}/participant/welcome?tId=${testId}`}
          />
          <Button
            className="d-flex align-items-center gap-1"
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.origin}/participant/welcome?tId=${testId}`
              );
            }}
          >
            <MdOutlineContentCopy /> Copy
          </Button>
        </InputGroup>
      </Modal>
    </div>
  );
}

export default Ribbon;
