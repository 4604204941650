import React from "react";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import { Sidebar, SidebarParticipants } from "./sidebar/Sidebar";
import ChatbotComponent from "../utils/hidechatbot";
import TopBar from "./Topbar/Topbar";

ReactGA.initialize("G-5F43YVG7SP");

export const PageLayout = ({ children }) => {
  const { isParticipant, account } = useSelector((state) => state.auth);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
  });

  return (
    <div className="page-layout">
      {isParticipant ? <SidebarParticipants /> : <Sidebar />}
      {account && account.plan && account.plan.type === "Free" && <TopBar />}
      <ChatbotComponent />
      <div className="page-layout__content">{children}</div>
    </div>
  );
};
