import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Spinner,
  FormControl,
} from "react-bootstrap";
import { DatePicker } from "@mantine/dates";
import { PageLayoutWaitlist } from "../../../components/page-layout-waitlist";
import { toastError } from "../../../utils/toast";
import { createLiveChatProject } from "../../../store/actions/projectActions";

export const AppLiveChatImport = () => {
  const { loading } = useSelector((state) => state.project);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [endDate, setEndDate] = useState(new Date());

  const createProject = () => {
    // end date should not be before start date
    if (endDate < startDate) {
      toastError("End date should not be before start date");
      return;
    }

    const startDateRFC3339 = startDate.toISOString().replace("Z", "000+00:00");

    const endDateRFC3339 = endDate.toISOString().replace("Z", "000+00:00");

    createLiveChatProject(startDateRFC3339, endDateRFC3339);
  };
  return (
    <PageLayoutWaitlist>
      <Container>
        <Row className="justify-content-center align-items-center mt-5">
          <Col xs={12} sm={12} md={12}>
            <Card
              style={{
                zIndex: 9999,
                padding: 20,
                maxWidth: 800,
                margin: "auto",
              }}
            >
              <Card.Body>
                <Card.Title>Filter Data for Analysis</Card.Title>
                <Card.Text>
                  Select the date range you want to import data for.
                </Card.Text>
                <Row
                  style={{
                    backgroundColor: "var(--grey2)",
                    padding: 20,
                    borderRadius: 10,
                  }}
                >
                  <Col xs={6} sm={6} md={6}>
                    <p style={{ fontWeight: 600 }}>Select a Start Date</p>
                    <FormControl
                      type="text"
                      placeholder="Start Date"
                      className="mr-sm-2"
                      disabled
                      value={startDate.toLocaleDateString()}
                    />
                    <DatePicker
                      placeholder="Start Date"
                      value={startDate}
                      onChange={setStartDate}
                    />
                  </Col>
                  <Col xs={6} sm={6} md={6}>
                    <p style={{ fontWeight: 600 }}>Select an End Date</p>
                    <FormControl
                      type="text"
                      placeholder="End Date"
                      className="mr-sm-2"
                      disabled
                      value={endDate.toLocaleDateString()}
                    />
                    <DatePicker
                      placeholder="End Date"
                      value={endDate}
                      onChange={setEndDate}
                    />
                  </Col>
                </Row>

                <Button
                  variant="primary"
                  onClick={createProject}
                  disabled={loading}
                  className="mt-3"
                >
                  {loading && <Spinner animation="border" size="sm" />} Import
                  Data & Analyze
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </PageLayoutWaitlist>
  );
};
