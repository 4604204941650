import React from "react";
import SidebarIcon from "./icon";
import { Link } from "react-router-dom";
import logo from "../../../../images/logo-blue-small.svg";
import styles from "./index.module.css";
import { PiUsersFourLight } from "react-icons/pi";
import { MdOutlineDashboard } from "react-icons/md";
import { VscFeedback } from "react-icons/vsc";
import { FiUser } from "react-icons/fi";
function Sidebar(activeRoute, setActiveRoute) {
  return (
    <nav className={styles.adminSidebar}>
      <div>
        <div className={`ue-logo ${styles.logo}`}>
          <img src={logo} alt="User Evaluation" />
        </div>
        <div className={`ue-icon-group mt-2 ${styles.adminOptions}`}>
          <Link to="/admin/users">
            <SidebarIcon
              IconComponent={PiUsersFourLight}
              tooltipText="Users"
              className={
                activeRoute.activeRoute === "/admin/users"
                  ? "ue-icon ue-icon-active"
                  : "ue-icon"
              }
            />
          </Link>
          <Link to="/admin">
            <SidebarIcon
              IconComponent={MdOutlineDashboard}
              tooltipText="Dashboard"
              className={
                activeRoute.activeRoute === "/admin"
                  ? "ue-icon ue-icon-active"
                  : "ue-icon"
              }
            />
          </Link>
          <Link to="/admin/customer-requests">
            <SidebarIcon
              IconComponent={VscFeedback}
              tooltipText="Customer-Requests"
              className={
                activeRoute.activeRoute === "/admin/customer-requests"
                  ? "ue-icon ue-icon-active"
                  : "ue-icon"
              }
            />
          </Link>
          <Link to="/admin/participants">
            <SidebarIcon
              IconComponent={FiUser}
              tooltipText="Participants"
              className={
                activeRoute.activeRoute === "/admin/participants"
                  ? "ue-icon ue-icon-active"
                  : "ue-icon"
              }
            />
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Sidebar;
