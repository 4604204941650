import React, { useEffect, useState } from "react";
import DataInsightsTemplate from "../../project/wrappers/data&Insight";
import Settings from "../../project/components/settings";
import TopBar from "../../project/components/topbar/publicIndex";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SignUp from "./components/signup";
import SignUpReminder from "./components/signUpReminder";
import { useSelector } from "react-redux";
import Tags from "../../project/components/tags";
import styles from "./index.module.css";

function PublicProjectPage({ id }) {
  let { project: projectData } = useSelector((state) => state.currentProject);
  const params = useParams();
  const [activeTab, setActiveTab] = useState(null);
  const isProcessing = projectData?.status === "Preparing";
  const [selectedVideoId, setSelectedVideoId] = useState("");
  useEffect(() => {
    if (params.tab) {
      setActiveTab(params.tab);
    } else {
      setActiveTab("data");
    }
  }, [params.tab]);
  useEffect(() => {
    if (params.assetId) {
      setSelectedVideoId(params.assetId);
    } else {
      setSelectedVideoId(projectData?.videos[0]?._id);
    }
  }, [params.assetId, projectData?.videos[0]]);
  let mapTabToComponent = {
    data: (
      <DataInsightsTemplate
        isProcessing={isProcessing}
        assets={projectData?.videos}
        activeTab={activeTab}
        selectedVideoId={selectedVideoId}
        setSelectedVideoId={setSelectedVideoId}
        projectId={projectData?._id}
        id={id}
      />
    ),
    insights: (
      <DataInsightsTemplate
        isProcessing={isProcessing}
        assets={projectData?.videos}
        selectedVideoId={selectedVideoId}
        setSelectedVideoId={setSelectedVideoId}
        activeTab={activeTab}
        projectId={projectData?._id}
        id={id}
      />
    ),
    chat: <SignUp tab={activeTab} />,
    tags: <Tags newProject={true} id={id} />,
    collection: <SignUp tab={activeTab} />,

    report: <SignUp tab={activeTab} />,
    presentation: <SignUp tab={activeTab} />,
    clips: <SignUp tab={activeTab} />,
    settings: <Settings />,
  };
  return (
    <div className={styles.pageContainer}>
      <div>
        <>
          <TopBar
            name={projectData?.name}
            language={projectData?.language}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            project={projectData}
            id={id}
          />
          {mapTabToComponent[activeTab]}
          <SignUpReminder />
        </>
      </div>
    </div>
  );
}

export default PublicProjectPage;
