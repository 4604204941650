import React from "react";
import { Modal } from "@mantine/core";
import { GoogleLoginBtn } from "../../../auth";
import Singup from "./signup";
import Login from "./login";
import css from "./auth.module.css";
import { useHistory } from "react-router-dom";

const MODAL_TYPE = {
  LOGIN: "LOGIN",
  SIGNUP: "SIGNUP",
};

const ParticipantAuth = ({ opened, onClose, testId }) => {
  const history = useHistory();
  const [currentScreen, setCurrentScreen] = React.useState(MODAL_TYPE.LOGIN);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      title="Login or sign up to continuue"
      size={600}
      className={css.auth}
    >
      {currentScreen === MODAL_TYPE.SIGNUP ? (
        <Singup
          toggleScreen={() => setCurrentScreen(MODAL_TYPE.LOGIN)}
          testId={testId}
        />
      ) : (
        <Login
          toggleScreen={() => setCurrentScreen(MODAL_TYPE.SIGNUP)}
          testId={testId}
        />
      )}

      <GoogleLoginBtn
        isParticipant={true}
        callback={() => history.push(`/participant/start-test?tId=${testId}`)}
      />
    </Modal>
  );
};

export default ParticipantAuth;
