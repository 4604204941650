import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Badge, Col, Nav, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { PageLayout } from "../../components/page-layout";
import backIconWhite from "../../images/icons/arrow-left-white.svg";
import * as projectActions from "../../store/actions/currentProjectActions";
import { useRouteMatch } from "react-router-dom";

// import currentProjectSlice from '../../store/slices/currentProject';
import {
  FiActivity,
  FiColumns,
  FiFileText,
  FiMessageCircle,
  FiFilm,
  FiTag,
} from "react-icons/fi";
import { HiOutlinePresentationChartLine } from "react-icons/hi";
import { InsightsExpandable } from "./components/sidebar/insights-expandable";
import { PagesExpandable } from "./components/sidebar/pages-expandable";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TopNavBar from "./components/top-nav";
import ParentRefContext from "./parentRefContext";

const sideMenuData = [
  {
    name: "Overview",
    icon: <FiFileText style={{ width: 20, height: 20 }} />,
    link: "/p/<projectId>",
  },
  {
    name: "AI Chat",
    icon: <FiMessageCircle style={{ width: 20, height: 20 }} />,
    link: "/p/<projectId>/chat",
  },
  {
    name: "Collection",
    icon: <FiColumns style={{ width: 20, height: 20 }} />,
    link: "/p/<projectId>/board",
  },
  {
    name: "Tags",
    icon: <FiTag style={{ width: 20, height: 20 }} />,
    link: "/p/<projectId>/tags",
  },
  {
    name: "Clips",
    icon: <FiFilm style={{ width: 20, height: 20 }} />,
    link: "/p/<projectId>/clips",
  },
  {
    name: "AI Presentation",
    icon: <HiOutlinePresentationChartLine style={{ width: 20, height: 20 }} />,
    link: "/p/<projectId>/pptx",
  },
  {
    name: "AI Report",
    icon: <FiActivity style={{ width: 20, height: 20 }} />,
    link: "/p/<projectId>/ai-report",
  }
];

/** loads project data and left and top menu */
export default function ProjectPageLayout(props) {
  const {
    account: userAccount,
    loading,
    isAuthenticated,
  } = useSelector((state) => state.auth);
  const { project: projectData, loading: loadingProjectData } = useSelector(
    (state) => state.currentProject
  );
  const parentRef = useRef(null);
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const projectId = match.params.projectId;
  const history = useHistory();
  useEffect(() => {
    if (projectData?._id !== projectId) {
      projectActions.loadProject(projectId);
    }
    // return () => dispatch(currentProjectSlice.actions.resetProject());
  }, [projectId, projectData, dispatch, isAuthenticated]);

  // vertically and horizontally centered Spinner
  if (loading || loadingProjectData)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  let projectStatus = "";
  if (projectData) {
    projectStatus =
      projectData.status === "Preparing" ? "Processing..." : projectData.status;
  }

  const navLinks = sideMenuData.map((item) => (
    <Nav.Link
      key={item.link}
      className={`project-custom-link ${
        history.location.pathname ===
        item.link.replace("<projectId>", projectId)
          ? "active"
          : ""
      }`}
      onClick={() => {
        history.push(item.link.replace("<projectId>", projectId));
      }}
    >
      {item.icon}
      &nbsp;{item.name}
    </Nav.Link>
  ));

  return (
    <PageLayout>
      {projectData && (
        <Row className="project-page">
          <Col xs={3} className="project-sidebar hide-mobile">
            <div>
              <div
                className="d-flex align-items-center project-logo-and-back"
                style={{ cursor: "auto" }}
              >
                <Link
                  to="/projects"
                  className="text-decoration-none"
                  style={{ cursor: "pointer" }}
                >
                  <img src={backIconWhite} alt="back" className="back-icon" />
                </Link>
              </div>
              <h3 className="project-title">{projectData.name}</h3>

              <Badge
                bg="dark"
                className="mb-3"
                style={{
                  marginLeft: 20,
                }}
              >
                {projectStatus}
              </Badge>
              <Nav defaultActiveKey="/link-0" className="flex-column">
                {navLinks}
                <InsightsExpandable
                  projectData={projectData}
                  userAccount={userAccount}
                />

                <PagesExpandable
                  className="project-custom-link"
                  projectId={projectData && projectData._id}
                  userId={userAccount && userAccount._id}
                />
              </Nav>
            </div>
          </Col>
          <Col className="project-content-area" ref={parentRef}>
            <TopNavBar />
            <ParentRefContext.Provider value={parentRef}>
              {props.children}
            </ParentRefContext.Provider>
          </Col>
        </Row>
      )}
    </PageLayout>
  );
}
