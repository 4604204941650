import store from "..";
import { authActions } from "../slices/authSlice";
import { callExternalApi } from "../../services/external-api.service";
import { loadProjectsAction } from "../actions/projectActions";
import { participantActions } from "../slices/participantSlice";
import { organizationActions } from "../slices/organizationSlice";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

const { dispatch } = store;

export const getCelloToken = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/api/user/cello/token`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const { data, error } = await callExternalApi({ config });

  if (error) {
    console.error("getCelloToken", error.message);
  }

  if (data) {
    dispatch(authActions.setCelloToken(data));
  }
};

export const loadAccountAction = (accessToken) => {
  dispatch(authActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/user/account/get`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  // Assuming getCelloToken is an async function, you should handle its promise
  getCelloToken(accessToken).catch((error) => {
    console.error("Failed to get Cello Token:", error);
  });

  // Call external API and handle the response outside of Promise constructor
  return callExternalApi({ config })
    .then(({ data, error }) => {
      if (error) {
        dispatch(authActions.requestFail(error.message));
        return Promise.reject(error);
      } else {
        dispatch(authActions.getAccount({ account: data, accessToken }));

        if (data.role === "participant")
          dispatch(participantActions.setParticipant(data.participant));

        return Promise.resolve(data);
      }
    })
    .catch((error) => {
      // This block will catch any errors that occur in the preceding code
      console.error("Error:", error);
      dispatch(authActions.requestFail(error.message));
      return Promise.reject(error);
    });
};

export const updateAccountNameAction = async (accountId, newName) => {
  const config = {
    url: `${apiServerUrl}/api/user/account/update/name`,
    method: "POST",
    data: { _id: accountId, name: newName },
  };

  const { data, error } = await callExternalApi({ config });
  if (error) dispatch(authActions.requestFail(error.message));
  else dispatch(authActions.updateAccountName(data.name));
};

export const getUsersByOrganization = async (organizationId) => {
  const config = {
    url: `${apiServerUrl}/api/user/users/organization/${organizationId}`,
    method: "GET",
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const removeUserFromOrganization = async (userId, organizationId) => {
  const config = {
    url: `${apiServerUrl}/api/user/${userId}/organization/${organizationId}`,
    method: "DELETE",
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};
// send phone number to server to get verification code
export const sendPhoneNumber = async (phoneNumber) => {
  const config = {
    url: `${apiServerUrl}/api/phone-verification/send`,
    method: "POST",
    data: { phoneNumber },
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

// verify phone number with code
export const verifyPhoneNumber = async (phoneNumber, code) => {
  const config = {
    url: `${apiServerUrl}/api/phone-verification/verify`,
    method: "POST",
    data: { phoneNumber, code },
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

// callback for google auth
export const googleAuth = async (code, invitationId, isParticipant) => {
  dispatch(authActions.requestStart());
  const config = {
    url: `${apiServerUrl}/api/auth/google`,
    method: "POST",
    data: { code, invitationId: invitationId },
    withCredentials: true,
  };

  const { data, error } = await callExternalApi({
    config,
    refreshBypass: true,
  });
  if (error) {
    dispatch(authActions.requestFail(error.message));
  } else {
    dispatch(authActions.setAccessToken(data?.accessToken));
    getCelloToken(data?.accessToken).catch((error) => {
      console.error("Failed to get Cello Token:", error);
    });
    const { error: err } = await signup(
      {
        ...data?.user,
        isGoogleSignup: true,
        invitationId: invitationId,
        isParticipant,
      },
      data?.accessToken
    );
    if (!err && !isParticipant) await loadProjectsAction();
  }
  return { data, error };
};

// callback for livechat auth
export const livechatAuth = async (code) => {
  dispatch(authActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/auth/livechat`,
    method: "POST",
    data: { code },
    withCredentials: true,
  };

  const { data, error } = await callExternalApi({
    config,
    refreshBypass: true,
  });

  if (error) {
    console.error("livechat auth", error.message);
  } else {
    const accessToken = data?.accessToken;
    getCelloToken(accessToken).catch((error) => {
      console.error("Failed to get Cello Token:", error);
    });
    dispatch(authActions.setAccessToken(accessToken));
    dispatch(
      authActions.getAccount({
        account: data?.user,
        accessToken,
      })
    );
    await loadProjectsAction();
  }

  return {
    data,
    error,
  };
};

// logout
export const logout = async () => {
  const config = {
    url: `${apiServerUrl}/api/auth/logout`,
    method: "GET",
    withCredentials: true,
  };

  const { error } = await callExternalApi({
    config,
    refreshBypass: true,
  });
  if (error) {
    console.error("logout", error.message);
  }
  localStorage.clear();
  dispatch(authActions.logout());
};

// refresh token
export const refreshToken = async () => {
  const config = {
    url: `${apiServerUrl}/api/auth/refresh`,
    method: "GET",
    withCredentials: true,
  };

  const { data, error } = await callExternalApi({
    config,
    refreshBypass: true,
  });

  if (error) {
    console.error("refreshToken", error.message);
  }
  if (data) {
    dispatch(authActions.setAccessToken(data?.accessToken));
  }

  return { data, error };
};

// signup
export const signup = async (userData, accessToken) => {
  const celloReferralCode = window.CelloAttribution("getReferral");
  const config = {
    url: `${apiServerUrl}/api/user/signup`,
    method: "POST",
    data: {
      ...userData,
      ...(celloReferralCode && { celloReferralCode }),
    },
  };

  const { data, error } = await callExternalApi({ config });
  if (error) {
    console.error("signup", error.message);
  } else {
    if (accessToken)
      dispatch(authActions.getAccount({ account: data?.user, accessToken }));
  }

  return {
    data,
    error,
  };
};

export const verifyEmail = async (id, token) => {
  const config = {
    url: `${apiServerUrl}/api/auth/verify-email`,
    method: "POST",
    data: { id, token },
  };

  const { data, error } = await callExternalApi({ config });
  if (error) {
    console.error("verifyEmail", error.message);
  }

  return {
    data,
    error,
  };
};

export const resetPassword = async (id, token, password) => {
  const config = {
    url: `${apiServerUrl}/api/auth/reset-password`,
    method: "POST",
    data: { id, token, password },
  };

  const { data, error } = await callExternalApi({ config });
  if (error) {
    console.error("resetPassword", error.message);
  }

  return {
    data,
    error,
  };
};

export const forgotPassword = async (email) => {
  const config = {
    url: `${apiServerUrl}/api/auth/forgot-password`,
    method: "POST",
    data: { email },
  };

  const { data, error } = await callExternalApi({ config });
  if (error) {
    console.error("forgotPassword", error.message);
  }

  return {
    data,
    error,
  };
};

export const signin = async (email, password, isParticipant, invitationId) => {
  const config = {
    url: `${apiServerUrl}/api/auth/signin`,
    method: "POST",
    data: { email, password, invitationId: invitationId },
    withCredentials: true,
  };

  const { data, error } = await callExternalApi({
    config,
    refreshBypass: true,
  });
  if (error) {
    console.error("signin", error.message);
  } else {
    const accessToken = data?.accessToken;
    dispatch(authActions.setAccessToken(accessToken));
    getCelloToken(accessToken).catch((error) => {
      console.error("Failed to get Cello Token:", error);
    });
    dispatch(
      authActions.getAccount({
        account: data?.user,
        accessToken,
      })
    );
    if (!isParticipant) await loadProjectsAction();
  }

  return {
    data,
    error,
  };
};

export const sendKeyWords = async ({ keywords }) => {
  const config = {
    url: `${apiServerUrl}/api/account/keywords`,
    method: "POST",
    data: { keywords },
  };

  const { data, error } = await callExternalApi({ config });
  if (data) {
    dispatch(organizationActions.setCustomVocabulary(keywords))
  }
  return {
    data,
    error,
  };
};
