import React from "react";
import { useSelector } from "react-redux";
import { createBlankProjectAction } from "../../store/actions/projectActions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router";

const SidebarNewIcon = () => {
  const { account } = useSelector((state) => state.auth);
  const history = useHistory();
  const createProject = () => {
    if (account?.plan?.subscribed || account?.phone_verified)
      createBlankProjectAction(account.email);
    else
      history.push({
        pathname: "/phone-verify",
      });
  };

  return (
    <OverlayTrigger
      style={{ display: "inline" }}
      placement="right"
      key={"ot-new"}
      delay={{ show: 80, hide: 200 }}
      overlay={
        <Tooltip id={"button-tooltip-new"} style={{ zIndex: 999999 }}>
          New Project
        </Tooltip>
      }
      popperConfig={{
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              altAxis: true,
              boundary: "viewport",
            },
          },
        ],
      }}
    >
      <div
        className="ue-project-initials-new-project"
        style={{ fontSize: 24, paddingBottom: 3 }}
        onClick={createProject}
      >
        +
      </div>
    </OverlayTrigger>
  );
};

export default SidebarNewIcon;
