//report component for project 
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Row, Col, Spinner, Container } from 'react-bootstrap';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from 'react-chartjs-2';
import { Emoji } from 'emoji-picker-react';

import { regenerateReportSection } from '../../../../store/actions/projectActions';
import { toastError } from "../../../../utils/toast";
import chartImg from "../../../../images/people-evaluation.svg";
import chartImgBlack from "../../../../images/people-evaluation-black.svg";



ChartJS.register(ArcElement, Tooltip, Legend);

let chartOptions = {
  // responsive: true,
  maintainAspectRatio: false,
  cutout: "70%",
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        color: "var(--primary1)",
        font: {
          size: 14,
        },
      },
    },
  },
};

function getLabels(string) {
  // Initialize an empty array to store the labels
  let labels = [];

  // Split the string into lines
  let lines = string.split("\n");

  // Loop through the lines
  for (let line of lines) {
    // Check if the line starts with "Data" and ends with "%"
    if (/^Data\s\d+\sLabel:/.test(line)) {
      // Split the line by the ": " separator
      let parts = line.split(": ");

      // Check if the split resulted in two parts
      if (parts.length === 2) {
        // The label is the second part
        let label = parts[1];

        // Remove the percentage from the end of the label
        let labelWithoutPercentage = label.replace(/\s\d+%$/, "");

        // Add the label to the array
        labels.push(labelWithoutPercentage);
      }
    }
  }

  // Return the array of labels
  return labels;
}

function getPercentages(string) {
  // Initialize an empty array to store the percentages
  const percentages = [];

  // Split the string into lines
  const lines = string.split("\n");

  // Loop through the lines
  for (const line of lines) {
    // Check if the line starts with "Data" and contains "Percentage:"
    if (/^Data\s\d+\sPercentage:/.test(line)) {
      // Extract the percentage from the line
      const percentage = line.split("Percentage: ")[1];

      // Add the percentage to the array
      percentages.push(percentage);
    }
  }

  // Return the array of percentages
  return percentages;
}


const parseStringToData = (string) => {
  // Extract the labels and percentages from the input string
  const labels = getLabels(string);
  const percentages = getPercentages(string);

  // Initialize an array of data values
  const data = percentages.map((percentage) =>
    parseFloat(percentage.replace("%", ""))
  );

  // Initialize an array of colors
  const colors = Array.from({ length: labels.length }, () => {
    // Generate a random color
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    // Create the border color (darker than the background color)
    const borderColor = `rgba(${r * 0.75}, ${g * 0.75}, ${b * 0.75}, 0.2)`;

    // Create the background color (lighter than the border color)
    const backgroundColor = `rgba(${r}, ${g}, ${b}, 1)`;

    // Return the border and background colors as an object
    return { borderColor, backgroundColor };
  });

  // Initialize an array of border colors
  const borderColor = colors.map((color) => color.borderColor);

  // Initialize an array of background colors
  const backgroundColor = colors.map((color) => color.backgroundColor);

  // Create the random data object
  const randomData = {
    labels,
    datasets: [
      {
        label: "Percentage:",
        data,
        backgroundColor,
        borderColor,
        borderWidth: 1,
      },
    ],
  };

  // Return the random data object
  return randomData;
};

export const ReportTab = ({ projectData, runGetProjectData }) => {
  const [regenerateLoading, setRegenerateLoading] = useState(false);
  const { darkMode } = useSelector(state => state.auth)

  const promptLibrary = [
    {
      title: "Summary",
      emojiUnified: "1f4a1",
      description: "Get a clear set of key insights from multiple sessions",
    },
    {
      title: "Pain Points",
      emojiUnified: "1f62c",
      description: "Understand the pain points of session participants",
    },
    {
      title: "Delighters",
      emojiUnified: "1f389",
      description: "Understand what caught participants eyes across sessions",
    },
    {
      title: "Questions for Next Session",
      emojiUnified: "1f914",
      description: "This will tell you what questions you can ask next",
    },
  ]



  const regenerateInsightType = async (insightType) => {
    setRegenerateLoading(true);
    let { data, error } = await regenerateReportSection({
      projectId: projectData._id,
      insightType,
    });

    if (data) {
      runGetProjectData(projectData._id, true);
      setRegenerateLoading(false);
    }

    if (error) {
      console.error(error);
      toastError(
        "Error regenerating insight. Please check your credits or contact support if the problem persists."
      );
      setRegenerateLoading(false);
    }
  };


  return (<>
    {/* make container small and center aligned */}
    <Container
      className="mt-3"
      style={{ maxWidth: 800, margin: "auto" }}
    >
      {/* if there are no insights in videos, show a loader */}
      {projectData?.status !== "Ready" ? (
        <div className="d-flex align-items-center justify-content-center h-100">
          <div className="text-center">
            <img
              src={darkMode ? chartImg : chartImgBlack}
              alt="processing"
              className="processing-img"
            />
            <br />
            <h1 className="processing-heading">
              Your report is being processed
            </h1>
            <p className="processing-paragraph">
              Our AI is working hard to bring those juicy insights
              to you. Please wait until the process finishes.
              Meanwhile, browse the tabs on the left sidebar to see
              whats already been generated.
            </p>
                        <Spinner animation="border" variant='outline-primary' size="sm" />
            <br />
          </div>
        </div>
      ) : (
        <>

          {projectData.insights &&
            projectData.insights.length > 0 &&
            projectData.insights.some(
              (insight) =>
                insight.insightType === "Summary"
            ) && (
              <Card body className="mt-3">
                <Row>
                  <Col xs={6}>
                    <h4>Summary</h4>
                  </Col>
                  <Col>
                    <Button
                      variant="outline-primary"
                      className="float-end"
                      onClick={() => regenerateInsightType("Summary")}
                      disabled={regenerateLoading}
                    >
                      {regenerateLoading ? (
                        <Spinner animation="border" variant='outline-primary' size="sm" />
                      ) : (
                        "Regenerate"
                      )}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mt-3">
                      {/* map insights if insightType in insight is Summary */}
                      {projectData.insights &&
                        projectData.insights.length > 0 &&
                        projectData.insights.map(
                          (insight, index) =>
                            insight.insightType === "Summary" &&
                            // map all insightData from insight
                            insight.insightData.map(
                              (insightData, index) => (
                                <div key={index}>
                                  <p>{insightData.insightText}</p>
                                </div>
                              )
                            )
                        )}

                      {projectData.insights &&
                        projectData.insights.length > 0 &&
                        projectData.insights.every(
                          (insight) => insight.insightType !== "Summary"
                        ) && (
                          <p>
                            Ready for a summary that will blow your
                            mind? Click the regenerate button and see
                            what the AI comes up with!
                          </p>
                        )}

                      {(!projectData.insights ||
                        projectData.insights.length === 0) && (
                          <p>
                            Ready for a summary that will blow your mind?
                            Click the regenerate button and see what the
                            AI comes up with!
                          </p>
                        )}
                    </div>
                  </Col>
                </Row>
              </Card>)}

          {projectData.insights &&
            projectData.insights.length > 0 &&
            projectData.insights.some(
              (insight) =>
                insight.insightType === "Pain Points"
            ) && (
              <Card body className="mt-3">
                <Row>
                  <Col xs={6}>
                    <h4>Pain Points</h4>
                  </Col>
                  <Col>
                    <Button
                      variant="outline-primary"
                      className="float-end"
                      onClick={() =>
                        regenerateInsightType("Pain Points")
                      }
                      disabled={regenerateLoading}
                    >
                      {regenerateLoading ? (
                        <Spinner animation="border" variant='outline-primary' size="sm" />
                      ) : (
                        "Regenerate"
                      )}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mt-3">
                      {/* map insights if insightType in insight is Pain Points */}
                      {projectData.insights &&
                        projectData.insights.length > 0 &&
                        projectData.insights.map(
                          (insight, index) =>
                            insight.insightType === "Pain Points" && (
                              // map all insightData from insight
                              // insight.insightData
                              <Doughnut
                                key={index}
                                options={chartOptions}
                                data={parseStringToData(
                                  insight.insightData
                                )}
                                height="500px"
                                width="500px"
                              />
                            )
                        )}

                      {projectData.insights &&
                        projectData.insights.length > 0 &&
                        projectData.insights.every(
                          (insight) =>
                            insight.insightType !== "Pain Points"
                        ) && (
                          <p>
                            Click on the Regenerate button to get some
                            pain points.
                          </p>
                        )}

                      {(!projectData.insights ||
                        projectData.insights.length === 0) && (
                          <p>
                            Click on the Regenerate button to get some
                            pain points.
                          </p>
                        )}
                    </div>
                  </Col>
                </Row>
              </Card>)}

          {projectData.insights &&
            projectData.insights.length > 0 &&
            projectData.insights.some(
              (insight) =>
                insight.insightType === "Delighters"
            ) && (
              <Card body className="mt-3">
                <Row>
                  <Col xs={6}>
                    <h4>Delighters</h4>
                  </Col>
                  <Col>
                    <Button
                      variant="outline-primary"
                      className="float-end"
                      onClick={() =>
                        regenerateInsightType("Delighters")
                      }
                      disabled={regenerateLoading}
                    >
                      {regenerateLoading ? (
                        <Spinner animation="border" variant='outline-primary' size="sm" />
                      ) : (
                        "Regenerate"
                      )}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mt-3">
                      {/* map insights if insightType in insight is Pain Points */}
                      {projectData.insights &&
                        projectData.insights.length > 0 &&
                        projectData.insights.map(
                          (insight, index) =>
                            insight.insightType === "Delighters" && (
                              // map all insightData from insight
                              // insight.insightData
                              <Doughnut
                                key={index}
                                options={chartOptions}
                                data={parseStringToData(
                                  insight.insightData
                                )}
                                height="500px"
                                width="500px"
                              />
                            )
                        )}

                      {/* show a message if insights are empty or no insight with insightType "Delighters" is found */}
                      {projectData.insights &&
                        projectData.insights.length > 0 &&
                        projectData.insights.every(
                          (insight) =>
                            insight.insightType !== "Delighters"
                        ) && (
                          <p>
                            Click on the Regenerate button to get some
                            delighters.
                          </p>
                        )}

                      {(!projectData.insights ||
                        projectData.insights.length === 0) && (
                          <p>
                            Click on the Regenerate button to get some
                            delighters.
                          </p>
                        )}
                    </div>
                  </Col>
                </Row>
              </Card>)}

          {projectData.insights &&
            projectData.insights.length > 0 &&
            projectData.insights.some(
              (insight) =>
                insight.insightType === "Questions for Next Session"
            ) && (
              <Card body className="mt-3">
                <Row>
                  <Col xs={8}>
                    <h4>Questions for the Next Session</h4>
                  </Col>
                  <Col>
                    <Button
                      variant="outline-primary"
                      className="float-end"
                      onClick={() =>
                        regenerateInsightType(
                          "Questions for Next Session"
                        )
                      }
                      disabled={regenerateLoading}
                    >
                      {regenerateLoading ? (
                        <Spinner animation="border" variant='outline-primary' size="sm" />
                      ) : (
                        "Regenerate"
                      )}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mt-3">
                      {/* map insights if insightType in insight is Summary */}
                      {projectData.insights &&
                        projectData.insights.length > 0 &&
                        projectData.insights.map(
                          (insight, index) =>
                            insight.insightType ===
                            "Questions for Next Session" && (
                              // map all insightData from insight
                              <ol className="gradient-list" key={index}>
                                {insight.insightData.map(
                                  (insightData, index) => (
                                    <li key={index}>
                                      {insightData.insightText}
                                    </li>
                                  )
                                )}
                              </ol>
                            )
                        )}

                      {/* show a message if insights are empty or no insight with insightType "Questions for Next Session" is found */}
                      {projectData.insights &&
                        projectData.insights.length > 0 &&
                        projectData.insights.every(
                          (insight) =>
                            insight.insightType !==
                            "Questions for Next Session"
                        ) && (
                          <p>
                            Get ready for some brain-teasing fun! Click
                            the regenerate button to get some
                            suggestions for your next session.
                          </p>
                        )}

                      {(!projectData.insights ||
                        projectData.insights.length === 0) && (
                          <p>
                            Get ready for some brain-teasing fun! Click
                            the regenerate button to get some suggestions
                            for your next session.
                          </p>
                        )}
                    </div>
                  </Col>
                </Row>
              </Card>)}

          {/* do not show card if projectData.insights has 4 items */}

          {(projectData.insights.length < 4) && (
            <Card body className="mt-3">
              <Row>
                {promptLibrary.map((prompt, index) => (
                  projectData.insights.every(
                    (insight) => insight.insightType !== prompt.title
                  ) && (
                    <Col xs={6} className="ai-insight-box-container" key={index}>
                      <div className="ai-insight-box">
                        <div className="ai-insight-box-emoji">
                          <Emoji unified={prompt.emojiUnified} size="20" />
                        </div>
                        <p className="ai-insight-box-title">{prompt.title}</p>
                        <p className="ai-insight-box-description">{prompt.description}</p>
                        {regenerateLoading ?
                          (<Button variant="outline-primary" disabled>
                            <Spinner animation="border" variant="primary" size="sm" />
                          </Button>)
                          : (<Button variant="outline-primary" onClick={() => regenerateInsightType(prompt.title)}>
                            Add to report
                          </Button>)}
                      </div>
                    </Col>
                  )
                ))}
              </Row>
            </Card>)}






        </>
      )}
    </Container>
  </>)
}