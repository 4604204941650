import React from "react";
import styles from "./index.module.css";
import Page from "./pages";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import PaginationDotted from "./pagination_dotted";
import { Modal } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../../../../store/slices/modalSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
function Onboarding() {
  const { onboarding } = useSelector((state) => state.modal);
  const history = useHistory();
  const { project } = useSelector((state) => state.currentProject);
  const close = () => {
    dispatch(modalActions.hideOnboardingModal());
    history.push(`/project/${project?._id}`);
    localStorage.setItem("onboarding", "false");
  };
  const dispatch = useDispatch();
  const [number, setNumber] = React.useState(0);
  const next = () => {
    if (number < 2) {
      setNumber(number + 1);
    } else {
      setNumber(0);
    }
  };
  const prev = () => {
    if (number > 0) {
      setNumber(number - 1);
    } else {
      setNumber(2);
    }
  };
  return (
    <Modal
      title="Explore your project"
      size="lg"
      opened={onboarding}
      onClose={(e) => {
        e?.stopPropagation();
        close();
      }}
    >
      <Page number={number} />
      <div className={styles.onboarding_buttons}>
        <button onClick={prev} className={styles.onboarding_button}>
          <FiArrowLeft />
        </button>
        <PaginationDotted number={number} />
        <button
          onClick={number === 2 ? close : next}
          className={styles.onboarding_button}
        >
          {number === 2 ? (
            <span className={styles.link}>View Project</span>
          ) : (
            <FiArrowRight />
          )}
        </button>
      </div>
    </Modal>
  );
}

export default Onboarding;
