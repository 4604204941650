import React, { useState } from "react";
import { Button, Dropdown, Nav, Navbar } from "react-bootstrap";
import {
  FiActivity,
  FiColumns,
  FiFileText,
  FiFilm,
  FiMessageCircle,
} from "react-icons/fi";
import { HiOutlinePresentationChartLine } from "react-icons/hi";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch, useHistory } from "react-router-dom";

import { InsightsExpandable } from "./sidebar/insights-expandable";
import { PagesExpandable } from "./sidebar/pages-expandable";
import { deleteProjectAction } from "../../../store/actions/projectActions";
import { getLinkByResourceIdAction } from "../../../store/actions/publicLinkActions";
import moreIcon from "../../../images/icons/more-horizontal-white.svg";
import moreIconLightMode from "../../../images/icons/more-horizontal.svg";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { modalActions } from "../../../store/slices/modalSlice";
import jsPDF from "jspdf";
let doc = new jsPDF();

function savePDF(fileName) {
  doc.html(document.querySelector(".codex-editor__redactor"), {
    callback: function (doc) {
      doc.save(fileName?.length > 0 ? fileName + ".pdf" : "newpdf.pdf");
    },
    margin: [10, 40, 10, 40],
    format: "a4",
    padding: 0,
    html2canvas: {
      scale: 0.2,
    },
    putOnlyUsedFonts: true,
  });
}
const pageTitles = {
  "": "Overview",
  overview: "Overview",
  asset: "Insights",
  report: "Research Report",
  chat: "AI Chat",
  clips: "Clips",
  board: "Collection",
  notes: " ",
  pages: "Pages",
  pptx: "AI Presentation",
  tags: "Tags",
  "ai-report": "AI Report",
};
const sideMenuData = [
  {
    name: "Overview",
    icon: <FiFileText style={{ width: 20, height: 20 }} />,
    link: "/p/<projectId>",
  },
  {
    name: "AI Chat",
    icon: <FiMessageCircle style={{ width: 20, height: 20 }} />,
    link: "/p/<projectId>/chat",
  },
  {
    name: "Clips",
    icon: <FiFilm style={{ width: 20, height: 20 }} />,
    link: "/p/<projectId>/clips",
  },
  {
    name: "Collection",
    icon: <FiColumns style={{ width: 20, height: 20 }} />,
    link: "/p/<projectId>/board",
  },
  {
    name: "AI Presentation",
    icon: <HiOutlinePresentationChartLine style={{ width: 20, height: 20 }} />,
    link: "/p/<projectId>/pptx",
  },
  {
    name: "AI Report",
    icon: <FiActivity style={{ width: 20, height: 20 }} />,
    link: "/p/<projectId>/ai-report",
  },
];

export default function TopNavBar() {
  const [expanded, setExpanded] = useState(false);
  const { darkMode } = useSelector((state) => state.theme);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const match = useRouteMatch();
  const param = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const projectId = match.params.projectId;
  let projectSubPage = history.location.pathname.split("/");
  if (projectSubPage.length === 3) {
    projectSubPage = "";
  } else {
    projectSubPage = projectSubPage[projectSubPage?.length - 1];
  }
  const activeTab = projectSubPage;
  let { noteId, assetId } = param;
  let subPageTitle = pageTitles[projectSubPage];
  if (noteId) {
    subPageTitle = "Pages";
  }
  if (assetId) {
    subPageTitle = "Insights";
  }
  const { project: projectData } = useSelector((state) => state.currentProject);
  const { account: userAccount } = useSelector((state) => state.auth);
  const isProjectReady =
    projectData.status === "Ready" || projectData.status === "Empty";
  return (
    <Navbar
      sticky="top"
      bg={darkMode ? "dark" : "light"}
      expand="lg"
      expanded={expanded}
      className="bg-white"
      variant={darkMode ? "dark" : "light"}
      style={{
        minHeight: 55,
      }}
    >
      <Navbar.Brand className="project-title">{subPageTitle}</Navbar.Brand>

      <Nav className="justify-content-end flex-grow-1 pe-3">
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          style={{
            padding: 0,
            border: 0,
            width: 40,
            marginLeft: "auto",
          }}
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="navbar-mobile-overlaid"
        >
          <Nav className="justify-content-end flex-grow-1 pe-3 navbar-mobile-bg">
            <div className={"navlinks-hide-desktop"}>
              {sideMenuData.map((item, index) => {
                return (
                  <Nav.Link
                    eventKey={`link-${index}`}
                    className="project-custom-link"
                    onClick={() => {
                      history.push(item.link.replace("<projectId>", projectId));
                      setExpanded(false);
                    }}
                    key={item.name}
                  >
                    {item.icon}
                    &nbsp;{item.name}
                  </Nav.Link>
                );
              })}
              <InsightsExpandable
                projectData={projectData}
                // handleInsightClick={handleInsightClick}
                userAccount={userAccount}
                // runGetProjectData={runGetProjectData}
              />
              <PagesExpandable
                className="project-custom-link"
                projectId={projectData && projectData._id}
                userId={userAccount && userAccount._id}
                // setCurrentNotePage={setCurrentNotePage}
                // setActiveTab={setActiveTab}
                // pageUpdated={pageUpdated}
                // setPageUpdated={setPageUpdated}
              />
              {userAccount?.plan?.type !== "Free" &&
                isProjectReady &&
                activeTab === "insights" && (
                  <>
                    <Nav.Link
                      href={`/download/${projectData._id}`}
                      target="_blank"
                    >
                      <Button variant="transparent">Download</Button>
                    </Nav.Link>
                    <Nav.Link
                      href={"/present/" + projectData._id}
                      target="_blank"
                    >
                      <Button variant="transparent">Present</Button>
                    </Nav.Link>
                  </>
                )}
            </div>
            <div className={"hide-mobile"}>
              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  <img
                    src={darkMode ? moreIcon : moreIconLightMode}
                    alt="more options"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{
                    left: "-100px",
                  }}
                >
                  {userAccount?.plan?.type !== "Free" &&
                    projectData._id &&
                    isProjectReady &&
                    activeTab === "insights" && (
                      <>
                        <Dropdown.Item
                          onClick={() =>
                            window.open(
                              `/download/${projectData._id}`,
                              "_blank"
                            )
                          }
                        >
                          Download Report
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            window.open(`/present/${projectData._id}`, "_blank")
                          }
                        >
                          Present Mode
                        </Dropdown.Item>
                      </>
                    )}
                  {projectData._id && isProjectReady && activeTab === "board" && (
                    <>
                      <Dropdown.Item
                        onClick={(e) => {
                          getLinkByResourceIdAction(
                            projectData._id,
                            "project board"
                          );
                          dispatch(modalActions.showShareBoardLinkModal());
                        }}
                      >
                        Share Collection
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          getLinkByResourceIdAction(
                            projectData._id,
                            "project board"
                          );
                          dispatch(modalActions.showExportBoardModal());
                        }}
                      >
                        Export Collection
                      </Dropdown.Item>
                    </>
                  )}
                  {projectData._id &&
                    isProjectReady &&
                    activeTab === "ai-report" && (
                      <>
                        <Dropdown.Item
                          onClick={(e) => {
                            getLinkByResourceIdAction(
                              projectData._id,
                              "project report"
                            );
                            dispatch(modalActions.showShareReportLinkModal());
                          }}
                        >
                          Share Report
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={() => {
                            savePDF(projectData?.name);
                          }}
                        >
                          Export PDF
                        </Dropdown.Item>
                      </>
                    )}
                  <Dropdown.Item
                    onClick={(event) => {
                      event.stopPropagation();
                      if (deleteConfirmation[projectId]) {
                        deleteProjectAction(projectId);
                        history.push("/projects");
                      } else {
                        setDeleteConfirmation({
                          [projectId]: true,
                        });
                      }
                    }}
                  >
                    {deleteConfirmation[projectId] ? (
                      <span style={{ color: "#ff7878" }}>Sure?</span>
                    ) : (
                      <span>Delete Project</span>
                    )}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Nav>
    </Navbar>
  );
}
