import React from "react";
import styles from "../../index.module.css";
import { HiOutlinePlus } from "react-icons/hi";
import { Tooltip } from "@mantine/core";
import topNavStyles from "./index.module.css";
function TopNavBtn({
  name,
  isActive,
  handleClick,
  index,
  handleHoverAnimation,
}) {
  return (
    <div
      className={`${styles.topnavBtn} ${isActive ? "active" : ""}`}
      onClick={handleClick}
      onMouseEnter={(e) => {
        handleHoverAnimation(e);
      }}
      style={{
        opacity: 1,

        transition: "all 0.35s ease-in-out",
      }} // Adjust opacity based on hover or active state
    >
      {name}
    </div>
  );
}
export function AddInsightBtn({ handleClick }) {
  return (
    <Tooltip label="Get Insights with AI" position="right" arrowOffset={32} arrowSize={7} withArrow>
      <div className={topNavStyles.addInsightBtn} onClick={handleClick}>
        <HiOutlinePlus />
      </div>
    </Tooltip>
  );
}
export default TopNavBtn;
