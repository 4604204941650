import {
  switchToDarkModeAction,
  switchToLightModeAction,
} from "../store/actions/themeActions";
import { toastDark } from "../utils/toast";

const handleDarkModeChange = (e) => {
  localStorage.setItem("darkMode", e.target.checked);
  if (e.target.checked) switchToDarkModeAction();
  else switchToLightModeAction();
  toastDark(`Dark mode is ${e.target.checked ? "enabled" : "disabled"}`);
  document.documentElement.setAttribute(
    "data-theme",
    e.target.checked ? "dark" : "light"
  );
};

export default handleDarkModeChange;
