import React, { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FiX } from "react-icons/fi";
import styles from "./create-clip-modal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { modalActions } from "../../../store/slices/modalSlice";
import { createClipAction } from "../../../store/actions/clipActions";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import formatTime from "../../../utils/formatTime";
import MiniPlayer from "../../../components/mini-player";
import GetSpeakerData from "./speakerName";

function CreateClipModal({ show, selectionData, speakers }) {
 
  const projectData = useSelector((state) => state.currentProject.project);
  const projectId = projectData._id;
  const dispatch = useDispatch();
  const location = useLocation();
  const [clipNotes, setClipNotes] = useState("");
  const [tags, setTags] = useState([]);
  const assetId = location?.pathname.split("/")[4];
  const videoRef = useRef(null);
  const [currentPlayerTime, setCurrentPlayerTime] = useState(0);
  const currentWord = currentPlayerTime * 1000;
  const handleSeek = (time) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time / 1000; // Convert to seconds
    }
  };
  const selectedVideo = (projectData?.videos || []).find(
    (vid) => vid._id === assetId
  );
  return (
    <Modal
      show={show}
      centered
      backdrop="static"
      size="lg"
      className="pay-modal-container"
    >
      <Modal.Header style={{ paddingBottom: "0px", border: "none" }}>
        <h6>Create a Clip</h6>
        <button
          style={{
            fontSize: "1.3rem",
            border: "none",
            background: "transparent",
          }}
          aria-label="Close"
        >
          <FiX
            aria-hidden="true"
            onClick={() => {
              setClipNotes("");
              setTags([]);
              dispatch(modalActions.hideCreateClipModal());
            }}
          />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.modalSpeakerSection}>
          {Array.isArray(selectionData) &&selectionData?.map((item, index) => {
            return (
              <div key={index}>
                <p>
                  <GetSpeakerData speakers={speakers} sentenceSpeaker={item.speaker} />
                </p>
                <p>
                  {" "}
                  <span
                    className={styles.timeStamp}
                    onClick={() => handleSeek(item.start)}
                  >
                    {formatTime(item.start)} - {formatTime(item.end)}
                  </span>
                </p>
                <p>
                  {item.words.map((word) => {
                    const isCurrentWord =
                      currentWord >= word.start && currentWord <= word.end;
                    return (
                      <span key={word.start + 1}>
                        <span
                          key={word.start}
                          className={
                            isCurrentWord ? "transcript-highlight" : ""
                          }
                        >
                          {word.text}
                        </span>
                        {word !== item.words[item.words.length - 1] && " "}
                      </span>
                    );
                  })}
                </p>
              </div>
            );
          })}
        </div>
        <div className={styles.clipInfo}>
          <div className={styles.player}>
            <MiniPlayer
              setCurrentPlayerTime={setCurrentPlayerTime}
              file={selectedVideo}
              startTime={selectionData[0]?.start}
              endTime={selectionData[selectionData?.length - 1]?.end}
            />
          </div>
          <div className={styles.notesAndTagsContainer}>
            <div>
              <p>Clip Notes</p>
              <textarea
                className={styles.clipNotes}
                name="clipNotes"
                id="clipNotes"
                cols="30"
                rows="2"
                value={clipNotes}
                onChange={(e) => setClipNotes(e.target.value)}
              ></textarea>
            </div>
            <div>
              <p>Tags</p>
              <TagsInput
                name="tags"
                id="tags"
                className={styles.tags}
                value={tags}
                onChange={(value) => {
                  setTags(value);
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.clipButtonContainer}>
          <div className={styles.otherButton}>
            <button
              onClick={() => {
                setClipNotes("");
                setTags([]);
                dispatch(modalActions.hideCreateClipModal());
              }}
            >
              dismiss
            </button>
            <Button
              variant="primary"
              onClick={() => {
                createClipAction({
                  title: "test",
                  assetId: assetId,
                  projectId: projectId,
                  clipNotes: clipNotes,
                  clipTags: tags,
                  startTime: selectionData[0]?.start,
                  endTime: selectionData[selectionData?.length - 1]?.end,
                });
                setClipNotes("");
                setTags([]);
                dispatch(modalActions.hideCreateClipModal());
              }}
            >
              save clip
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CreateClipModal;

