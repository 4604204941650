import React from "react";
import { Route } from "react-router-dom";
import ACI_DescribeTest from "../ai-curated-interview/describe-test";
import ACI_AdditionalDetails from "../ai-curated-interview/additional-details/index.js";
import ACI_Review from "../ai-curated-interview/review/index.js";
import ACI_Script from "../ai-curated-interview/script/index.js";
import ACI_Screen from "../ai-curated-interview/screen/index.js";
import ACI_SelectFilter from "../ai-curated-interview/select-filter/index.js";
import ACI_FilterAudience from "../ai-curated-interview/select-filter/filter-audience/index.js";
import ACI_AddParticipants from "../ai-curated-interview/select-filter/add-participants/index.js";
import { useDispatch } from "react-redux";
import { testActions } from "../../../store/slices/testSlice.js";

const ACIRoutes = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    let divToHide = document.querySelector("#chat-widget-container");
    if (divToHide) divToHide.style.display = "none";

    return () => {
      if (divToHide) divToHide.style.display = "";
      dispatch(testActions.clearCurrentTest());
    };
  }, []);

  return (
    <>
      <Route
        path="/interviews/ai-curated-interview/:id/describe-test"
        component={ACI_DescribeTest}
      />
      <Route
        path="/interviews/ai-curated-interview/:id/additional-details"
        component={ACI_AdditionalDetails}
      />
      <Route
        path="/interviews/ai-curated-interview/:id/review"
        component={ACI_Review}
      />
      <Route
        path="/interviews/ai-curated-interview/:id/script"
        component={ACI_Script}
      />
      <Route
        path="/interviews/ai-curated-interview/:id/screen"
        component={ACI_Screen}
      />
      <Route
        path="/interviews/ai-curated-interview/:id/select-filter"
        component={ACI_SelectFilter}
      />
      <Route
        path="/interviews/ai-curated-interview/:id/filter-audience"
        component={ACI_FilterAudience}
      />
      <Route
        path="/interviews/ai-curated-interview/:id/add-participants"
        component={ACI_AddParticipants}
      />
    </>
  );
};

export default ACIRoutes;
