import React, { useEffect,useState } from 'react'
import { useSelector } from 'react-redux'
import { Spinner, Table, } from "react-bootstrap"
import { FiVideo, FiMusic, FiFileText, FiColumns, FiMessageCircle,
} from 'react-icons/fi';

import { loadAssets } from '../../../../store/actions/mediaLibraryActions'


/** Add an asset from the media library */
export default function AddMediaLibrary({selected, onChange}) {
    const [loading, setLoading] = useState(true);
    const assets = useSelector(state => state.mediaLibrary.assets)
    const { darkMode } = useSelector(state => state.theme)
    const iconsForAssetType = [
        {
          type: "VIDEO",
          icon: <FiVideo />,
        },
        {
          type: "AUDIO",
          icon: <FiMusic />,
        },
        {
          type: "TEXT",
          icon: <FiFileText />,
        },
        {
          type: "CSV",
          icon: <FiColumns />,
        },
        {
          type: "PDF",
          icon: <FiFileText />,
        },
        {
          type: "DOC",
          icon: <FiFileText />,
        },
        {
          type: "LIVECHAT",
          icon: <FiMessageCircle />,
        },
        {
          type: "OTHER",
          icon: <FiFileText />,
        }
      ];
      useEffect(() => {
        const fetchAssets = async () => {
          try {
            await loadAssets();
            setLoading(false);
          } catch (error) {

            setLoading(false);
          }
        };
    
        if (assets.length === 0) {
          fetchAssets();
        } else {
          setLoading(false);
        }
      }, [assets]);

    const handleCheckbox = (event) => {
        const id = event.target.getAttribute('data-id');

        if(event.target.checked) {
            onChange([...selected, id])
        } else {
            onChange(selected.filter(x => x!==id))
        }
    }

    const rows = assets.map(asset=>(<tr key={asset._id}>
        <td><input 
                onChange={handleCheckbox} 
                checked={selected.includes(asset._id)} 
                data-id={asset._id} 
                type="checkbox" />
        </td>
        <td colSpan={3}>    
            <span className="file-type-icon">
            {iconsForAssetType.find(icon => icon.type === asset.assetType)?.icon || iconsForAssetType.find(icon => icon.type === "OTHER").icon}
            </span>
            {asset.name}
        </td>
        <td>{asset.createdBy.name}</td>
    </tr>))



    return <div>
      {
        loading? <div style={{textAlign:"center",margin:"auto"}}>
          <Spinner animation="border" role="status" variant='primary'/>
        </div> : <Table bordered hover variant={darkMode ? "dark" : "light"} className="media-library-table" style={{ maxHeight: 'calc(80vw - 100px)' }}>
        <thead>
            <tr>
                <th>#</th>
                <th colSpan={3}>File</th>
                <th>Created By</th>
            </tr>
        </thead>
        <tbody>
            {rows}
        </tbody>
    </Table>
      }
        
    </div>
}