import React from "react";
import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Limeline from "../../../../images/limeline.svg";
import LimelineWhite from "../../../../images/limeline-white.svg";
import { BiSolidInfoCircle } from "react-icons/bi";
import style from "../index.module.css";

const LimelineTooltip = ({ title, className }) => {
  const { darkMode } = useSelector((state) => state.theme);

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip className={style.tooltip}>
          Limeline is a tool that helps us create online meetings where the
          meetings are conducted and led by AI. Limeline uses advanced AI models
          to make the call experience more natural for participants.
        </Tooltip>
      }
    >
      <div className={`${style.badge} ${style.limelineBadge} ${className}`}>
        {title}
        <img
          src={darkMode ? LimelineWhite : Limeline}
          alt="Limeline.ai"
          className={style.limelineImg}
        />
        <BiSolidInfoCircle />
      </div>
    </OverlayTrigger>
  );
};

export default LimelineTooltip;
