/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import { TestLayout } from "../components/test-layout";
import style from "./index.module.css";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Table from "@editorjs/table";
import Quote from "@editorjs/quote";
import Delimiter from "@editorjs/delimiter";
import BottomBar from "../components/bottom-bar";
import { useSelector } from "react-redux";
import useSocketContext from "../../../../utils/SocketContext";
import { EMIT_TYPES, EVENT_TYPES } from "../../routes/test-route";
import { Button } from "react-bootstrap";
import { MdRefresh } from "react-icons/md";

const defaultTools = {
  header: Header,
  list: List,
  table: Table,
  quote: Quote,
  delimiter: Delimiter,
};

export const getParsedScript = (script) => {
  try {
    return JSON.parse(script);
  } catch (error) {
    return [];
  }
};

function ACI_Script({ match }) {
  const [loading, setLoading] = useState(true);
  const editorRef = useRef(null);
  const { script: unparsedScript, isGeneratingScript } = useSelector(
    (state) => state.test.currentTest
  );
  const [buttonLoading, setButtonLoading] = React.useState(false);

  const socket = useSocketContext();

  useEffect(() => {
    if (isGeneratingScript) {
      return setLoading(true);
    }

    const script = getParsedScript(unparsedScript);

    editorRef.current = new EditorJS({
      holder: "editorjs",
      tools: defaultTools,
      data: script,
      onReady: () => {
        setLoading(false);
      },
    });

    return () => {
      editorRef.current.destroy?.();
    };
  }, [unparsedScript, isGeneratingScript]);

  React.useEffect(() => {
    if (!socket) return;

    const handler = {
      [EMIT_TYPES.ERROR]: () => {
        setButtonLoading(false);
      },
    };

    socket.attachHandlers(handler);
    return () => {
      socket.detachHandlers(handler);
    };
  }, [socket]);

  const estimatedTime = Math.ceil(
    getParsedScript(unparsedScript).blocks?.reduce((acc, block) => {
      return acc + (block?.data?.text?.split(" ").length || 0);
    }, 0) * 0.02
  );

  return (
    <TestLayout stepNumber={5} testId={match.params.id}>
      <div className={style.wrapper}>
        <div className={style.title}>
          <p className={style.titleHeader}>
            <span>Interview script for AI Agent</span>
            <Button
              className={style.regenerateButton}
              disabled={isGeneratingScript}
              variant="outline-primary"
              onClick={() => {
                socket.testUpdates(EVENT_TYPES.GENERATE_SCRIPT, {
                  testId: match.params.id,
                });
              }}
            >
              <MdRefresh size={24} /> Regenerate
            </Button>
          </p>
          <p className={style.titleDescription}>
            Our AI has generated a script for you, but you can modify it or regnerate using AI
          </p>
        </div>
        <div className={style.main}>
          <div id="editorjs" className={style.editor} />
          {(loading || isGeneratingScript) && (
            <div className={style.loadingText}>
              <Spinner animation="grow" />
              <Spinner animation="grow" />
              <Spinner animation="grow" />
              Writing your research script
            </div>
          )}
        </div>
        <BottomBar
          currentStep={5}
          primaryButton="Save & Next"
          displayScriptInfo
          testId={match.params.id}
          primaryButtonLoading={buttonLoading}
          onPrimaryButtonClick={async () => {
            try {
              setButtonLoading(true);
              const script = editorRef.current
                ? await editorRef.current.save()
                : "";

              socket.testUpdates(EVENT_TYPES.UPDATE_SCRIPT, {
                testId: match.params.id,
                script: JSON.stringify(script),
                duration: estimatedTime,
              });
            } catch (error) {
              setButtonLoading(false);
            }
          }}
          disablePrimaryButton={loading || isGeneratingScript}
          estimatedTime={estimatedTime}
        />
      </div>
    </TestLayout>
  );
}

export default ACI_Script;
