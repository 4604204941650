export default function scrollToLastMessage(
  chatListRef,
  smoothScroll = true,
  setSmoothScroll = null
) {
  if (chatListRef.current && !smoothScroll) {
    chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
    setSmoothScroll(true);
    return;
  }
  // after first time loading, smooth scroll to bottom
  const lastMessage = chatListRef?.current?.lastElementChild;
  lastMessage?.scrollIntoView({ behavior: "smooth" });
}
