export const freeplan = [
  {
    feature: "1 project with 2 file uploads",
    tooltip: "Create 1 project and upload a maximum of 2 files for analysis.",
  },
  {
    feature: "2 hours of transcription in total",
    tooltip: "Transcribe a maximum of 2 hours of audio or video files.",
  },
  {
    feature: "Limit on AI model usage",
    tooltip: "Access to a limited set of AI-powered analytical features.",
  },
  {
    feature: "Limited AI insights",
    tooltip: "You're only shown a few AI insights on the free plan.",
  },
  {
    feature: "Single Collection",
    tooltip: "Organize your AI insights within a single collection.",
  },
  {
    feature: "Clips for audio and video files",
    tooltip: "Extract and save clips from your video or audio files.",
  },
  {
    feature: "1 GB of storage",
    tooltip: "Upload and store up to 1 GB of data.",
  },
];

export const basicPlan = [
  {
    feature: "GPT-4o, Claude 3 and other advanced AI models",
    tooltip: "Access advanced AI models for deeper insights and analytics.",
  },
  {
    feature: "5 projects with 2 files at max",
    tooltip:
      "Manage 5 projects simultaneously with a limit of 2 files per project",
  },
  {
    feature: "5 transcription hours per month",
    tooltip: "Transcribe a maximum of 5 hours of audio or video files.",
  },
  {
    feature: "Unlimited AI Insights for each project",
    tooltip:
      "Gain unlimited access to AI insights for comprehensive data analysis.",
  },
  {
    feature: "Sentiment analysis",
    tooltip:
      "Analyze the sentiment of your data to understand the emotional context.",
  },
  {
    feature: "Collections for each project",
    tooltip: "Organize your AI insights within collections for each project.",
  },
  {
    feature: "Unlimited AI Chat",
    tooltip:
      "Enjoy unrestricted AI chat interactions for in-depth conversations.",
  },
  {
    feature: "Unlimited AI Reports & Presentations",
    tooltip:
      "Generate comprehensive reports and presentations with AI assistance.",
  },
  {
    feature: "AI Tags",
    tooltip: "Automatically tag all your files based on your instructions.",
  },
  {
    feature: "2 GB storage",
    tooltip: "Store a larger amount of data with increased storage capacity.",
  },
];

export const standardPlan = [
  {
    feature: "Everything in Basic, plus",
    tooltip:
      "Includes all features of the Basic plan, with additional benefits.",
  },
  {
    feature: "Unlimited projects",
    tooltip:
      "Manage multiple projects simultaneously without any restrictions.",
  },
  {
    feature: "15 transcription hours per month",
    tooltip: "Transcribe a maximum of 15 hours of audio or video files.",
  },
  {
    feature: "Meeting Notetaker for Google Meet, Microsoft Teams and Zoom",
    tooltip:
      "Transcribe more content with increased transcription hours each month.",
  },
  {
    feature: "Unlimited AI Insights",
    tooltip:
      "Gain unlimited access to AI insights for comprehensive data analysis.",
  },
  {
    feature: "Unlimited Collections",
    tooltip: "Organize your insights and data across multiple collections.",
  },
  {
    feature: "Unlimited AI Reports & Presentations",
    tooltip:
      "Generate comprehensive reports and presentations with AI assistance.",
  },
  {
    feature: "Export and share",
    tooltip: "Easily export and share your insights and data with others.",
  },
  {
    feature: "25 GB storage",
    tooltip: "Store a larger amount of data with increased storage capacity.",
  },
];
export const plusPlan = [
  {
    feature: "Everything in Standard, plus",
    tooltip:
      "Includes all features of the Standard plan, with additional benefits.",
  },
  {
    feature: "5 team members",
    tooltip:
      "Collaborate efficiently with up to 5 team members in your workspace.",
  },
  {
    feature: "50 transcription hours per month",
    tooltip:
      "Enjoy a significant increase in transcription hours for your team's projects.",
  },
  {
    feature: "Advanced AI models",
    tooltip:
      "Gain access to all AI features available, enhancing your team's productivity and insights.",
  },
  {
    feature: "Enhanced AI chat",
    tooltip:
      "Experience an enhanced AI chat functionality, optimized for team use.",
  },
  {
    feature: "White-labelling",
    tooltip:
      "Customize the platform's appearance and functionality to match your brand.",
  },
  {
    feature: "Third-party integrations",
    tooltip: "Integrate with third-party services for enhanced functionality.",
  },
  {
    feature: "Priority Support",
    tooltip:
      "Get prioritized support for any issues or inquiries, ensuring minimal downtime.",
  },
  {
    feature: "Ability to add more team members",
    tooltip:
      "Expand your team within the platform by adding more team members as needed.",
  },
  {
    feature: "1 TB storage",
    tooltip:
      "Benefit from a substantial increase in storage capacity for your team's data and projects.",
  },
];

export const engageBasicPlan = [
  {
    feature: "3 AI interviews (User Evaluation pool)",
    tooltip:
      "Up to 3 interviews per month, curated by AI, using participants from the User Evaluation pool.",
  },
  {
    feature: "6 AI interviews (own participants)",
    tooltip:
      "Up to 6 interviews per month, curated by AI, using your own participants.",
  },
  {
    feature: "15-minute AI-curated call",
    tooltip: "Up to 15-minute AI-curated interviews limit.",
  },
  {
    feature: "Unlimited recording retention",
    tooltip: "Allows for unlimited storage duration of interview recordings.",
  },
];

export const engageStandardPlan = [
  {
    feature: "8 AI interviews (User Evaluation pool)",
    tooltip:
      "Up to 8 interviews per month, curated by AI, using participants from the User Evaluation pool.",
  },
  {
    feature: "20 AI interviews (own participants)",
    tooltip:
      "Up to 20 interviews per month, curated by AI, using your own participants.",
  },
  {
    feature: "30-minute AI-curated call",
    tooltip: "Provides AI-curated interviews, each up to 30 minutes long.",
  },
  {
    feature: "Unlimited recording retention",
    tooltip: "Allows for unlimited storage duration of interview recordings.",
  },
];
export const engagePlusPlan = [
  {
    feature: "20 AI interviews (User Evaluation pool)",
    tooltip:
      "Up to 20 interviews per month, curated by AI, using participants from the User Evaluation pool.",
  },
  {
    feature: "50 AI interviews (own participants)",
    tooltip:
      "Up to 50 interviews per month, curated by AI, using your own participants.",
  },
  {
    feature: "45-minute AI-curated call",
    tooltip: "Provides AI-curated interviews, each up to 30 minutes long.",
  },
  {
    feature: "Unlimited recording retention",
    tooltip: "Allows for unlimited storage duration of interview recordings.",
  },
];
