import React from "react";

function GetSpeakerData({ speakers, sentenceSpeaker }) {
  const getSpeakerNameAndRole = (speakers, sentenceSpeaker) => {
    if (speakers && speakers.length > 0) {
      let spk = speakers.find((speaker) => {
        return speaker.label === sentenceSpeaker;
      });
      if (spk) {
        return spk;
      } else {
        return {
          name: "Speaker " + sentenceSpeaker,
          role: "",
        };
      }
    } else {
      return {
        name: "Speaker " + sentenceSpeaker,
        role: "",
      };
    }
  };

  const speakerNameAndRole = getSpeakerNameAndRole(speakers, sentenceSpeaker);

  return (
    <>
      {speakerNameAndRole.name}{" "}
      {speakerNameAndRole.role.trim().length > 0
        ? `(${speakerNameAndRole.role})`
        : ""}
    </>
  );
}

export default GetSpeakerData;