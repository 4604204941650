import React from "react";
import {
  forgotPassword,
  resetPassword,
} from "../../../store/actions/authActions";
import { toast } from "react-toastify";
import { Form, Button } from "react-bootstrap";
import css from "../index.module.css";
import { useHistory, Link } from "react-router-dom";
import { AuthLayout } from "..";
import { checkPassword } from "../../../utils/checkPassword";
import tick from "../../../images/icons/check-circle.png";

const SCREENS = {
  FORGOT: "FORGOT",
  CHECKMAIL: "CHECKMAIL",
  RESET: "RESET",
  RESET_SUCCESS: "RESET_SUCCESS",
};

const ForgotPassword = () => {
  const dataRef = React.useRef(null);
  const history = useHistory();
  const [screen, setScreen] = React.useState(SCREENS.FORGOT);
  const params = new URLSearchParams(history.location.search);

  const action = params.get("action");
  const id = params.get("id");
  const token = params.get("token");

  React.useEffect(() => {
    if (action === "reset") {
      if (!id || !token)
        return toast.error(
          "Your password reset link seems to be invalid. Please try again or contact support."
        );
      setScreen(SCREENS.RESET);
    }
  }, []);

  const handleForgotPass = (e) => {
    e?.preventDefault();
    const formData = new FormData(e?.target);
    const data = Object.fromEntries(formData.entries());
    dataRef.current = data;

    forgotPassword(data.email).then(({ data, error }) => {
      if (data) {
        // toast.success(data?.message || "We have sent you a link to reset your password via email.");
        setScreen(SCREENS.CHECKMAIL);
      }
      if (error)
        toast.error(
          error?.message ||
            "Something went wrong while sending reset link. Please try again or contact support."
        );
    });
  };

  const handleResetPass = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());

    const notValid = checkPassword(data.password, data.confirmPassword);
    if (notValid) return toast.error(notValid);

    resetPassword(id, token, data.password).then(({ data, error }) => {
      if (data) {
        // toast.success(data?.message || "Your password has been reset successfully.");
        setScreen(SCREENS.RESET_SUCCESS);
      }
      if (error)
        toast.error(
          error?.message ||
            "Something went wrong while resetting your password. Please try again or contact support."
        );
    });
  };

  return (
    <AuthLayout>
      {screen === SCREENS.FORGOT && (
        <div className={css.forgot}>
          <h2>Forgot Password</h2>
          <p className="mb-4 mb-md-5">
            Enter your email address and we&nbsp;ll send you instructions to
            reset your password.
          </p>
          <Form onSubmit={handleForgotPass}>
            <Form.Group>
              <Form.Control
                type="email"
                placeholder="Email address"
                required
                name="email"
              />
            </Form.Group>
            <Button className="w-100" type="submit">
              Send
            </Button>
          </Form>
          <Button
            variant="outline-primary bg-transparent text-pink w-100 mt-0"
            onClick={() => history.push("/login")}
          >
            Back to login
          </Button>
        </div>
      )}

      {screen === SCREENS.CHECKMAIL && (
        <div className={css.checkMail}>
          <h2>Check your email</h2>
          <p className="mb-4 mb-md-5">
            We have sent a reset link to&nbsp;
            {dataRef?.current?.email || "your mail"}.
          </p>
          <p className="mb-0">
            Didn&apos;t receive the email? If not, please check your spam or
            promotion folder. Contact&nbsp;
            <Link
              to="https://userevaluation.com/support"
              target="_blank"
              rel="noopener noreferrer"
            >
              support
            </Link>
            &nbsp;if you run into any issues.
          </p>
          <Form onSubmit={handleForgotPass}>
            <input type="hidden" name="email" value={dataRef.current?.email} />
            <Button className="w-100" type="submit">
              Resend Email
            </Button>
          </Form>
          <Button
            variant="outline-primary bg-transparent text-pink w-100 mt-0"
            onClick={() => history.push("/login")}
          >
            Back to login
          </Button>
        </div>
      )}

      {screen === SCREENS.RESET && (
        <div className={css.reset}>
          <h2>Reset Password</h2>
          <p className="mb-4 mb-md-5">Choose a new password for your account</p>
          <Form onSubmit={handleResetPass}>
            <Form.Group className="mb-4">
              <Form.Control
                type="password"
                placeholder="Password"
                required
                name="password"
                minLength={8}
                maxLength={64}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                required
                name="confirmPassword"
                minLength={8}
                maxLength={64}
              />
            </Form.Group>
            <Button className="w-100" type="submit">
              Reset Password
            </Button>
          </Form>
          <Button
            variant="outline-primary bg-transparent text-pink w-100 mt-0"
            onClick={() => history.push("/login")}
          >
            Back to login
          </Button>
        </div>
      )}

      {screen === SCREENS.RESET_SUCCESS && (
        <div className={css.resetSuccess}>
          <img src={tick} alt="success" />
          <h4>Your password has been changed successfully.</h4>
          <p className="mb-0">Please login to continue.</p>
          <Button className="w-100" onClick={() => history.push("/login")}>
            Login
          </Button>
        </div>
      )}
    </AuthLayout>
  );
};

export default ForgotPassword;
