import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Container, Spinner, Button } from "react-bootstrap";
import Editor from "../../../components/editor";
import { saveReport, loadReport } from "../../../store/actions/currentProjectActions";
import { toastError, toastSuccess } from "../../../utils/toast";
import PromptInput from "./components/PromptInput";
import useSocketContext from "../../../utils/SocketContext";
import lookoutWhite from "../../../images/lookout-white.svg";
import lookout from "../../../images/lookout.svg";
import chartImg from "../../../images/people-evaluation.svg";
import chartImgBlack from "../../../images/people-evaluation-black.svg";

export const PreparingData = ({ darkMode }) => {
    return (
      <div className="d-flex align-items-center justify-content-center h-100">
        <div className="text-center">
          <img
            src={darkMode ? chartImg : chartImgBlack}
            alt="processing"
            className="processing-img"
          />
          <br />
          <h1 className="processing-heading">Your data is being processed</h1>
          <p className="processing-paragraph">
            Please give our AI a few minutes before it can answer all your
            questions.
          </p>
          <Spinner animation="border" variant="outline-primary" size="sm" />
          <br />
        </div>
      </div>
    );
  };


const AIReport = () => {

  const socketHandlers = useCallback({
    reportUpdate: (payload) => {
      switch (payload.status) {
        case 'Error':
          console.error(payload.message);
          toastError(payload.message);
          setProgressPercent(0);
          break;
        case 'Processing':
            setProgressPercent(payload.progress);
            setProgressMessage(payload.message);
          break;
        case 'Writing':
            setProgressPercent(0);
            setProgressMessage(payload.message);
            setAddBlock(payload.blocks[0]);
            setWritingLoader(true);
            break;
        case 'Finished':
            loadReport(projectData._id);
            setProgressPercent(0);
            setWritingLoader(false);
          break;
        default:
          console.warn('Unknown reportUpdate status:', payload.status);
      }
    },
  }, []);


//   const socket = useSocketIO(socketHandlers);
  const socket = useSocketContext()
  useEffect(() => {
    if(socket) {
      socket.attachHandlers(socketHandlers);
    }
    return () => socket.detachHandlers(socketHandlers);
  }, [socket, socketHandlers]);
        const projectData = useSelector((state) => state.currentProject.project);
        const { darkMode } = useSelector(state => state.theme);
        const [progressMessage, setProgressMessage] = useState('');
        const [progressPercent, setProgressPercent] = useState(0);
        const [changeTo, setChangeTo] = useState(null);
        const [addBlock, setAddBlock] = useState(null);
        const [selectedVideos, setSelectedVideos] = useState([]);
        const [writingLoader, setWritingLoader] = useState(false);

        const handleSubmit = async (prompt) => {
            
            if(prompt === '') {
                toastError('Please enter instructions for the AI to generate a report.');
                return;
            }
            if(selectedVideos.length === 0) {
                toastError('Please select at least 1 video to include in the report.');
                return;
            }

            // reset editor
            setChangeTo({
                time: new Date().getTime(),
                blocks: [],
                version: '2.22.2',
            });
    
            socket.createReport(projectData._id, prompt, selectedVideos);
            
        }
  
    const handleEditorChange = async (newData) => {
    //   setEditorData(newData);
      const {data, error} = await saveReport(projectData.reportId, newData);
        if (error) {
            toastError(error.message);
        }

        if (data) {
            toastSuccess('Report saved successfully.');
        }
    };

    const handleVideoCheck = (e) => {
        if (e.target.checked) {
            setSelectedVideos(prevState => [...prevState, e.target.value]);
        } else {
            setSelectedVideos(prevState => prevState.filter(id => id !== e.target.value));
        }
    };

    useEffect(() => {
        loadReport(projectData._id);
    }, [projectData._id]);

    useEffect(() => {
        if(projectData.report && changeTo === null) {
            setChangeTo(projectData.report);
        }
    }, [projectData.report]);
    const emptyProject = (
        <Container className="d-flex align-items-center justify-content-center h-100">
          <div className="text-center">
            <img
              src={darkMode ? lookoutWhite : lookout}
              alt="processing"
              className="processing-img"
              style={{ maxWidth: "400px" }}
            />
            <br />
            <h1 className="processing-heading">Your project looks empty</h1>
            <p className="processing-paragraph">
              You have not uploaded files to your project yet. Upload your first file to get
              started.
            </p>
    
            <Link to={"/p/" + projectData._id}>
              <Button variant="outline-primary">Upload Files</Button>
            </Link>
          </div>
        </Container>
      );
    return (
        <>

{(projectData?._id && projectData?.status === "Empty") && emptyProject}


{(projectData?.status !== "Ready" && !(projectData?._id && projectData?.status === "Empty")) && (
  <PreparingData darkMode={darkMode} />
)}
{projectData?.status === "Ready" && (<>
        <Container fluid>
            <PromptInput
                handleSubmit={handleSubmit}
                progressPercent={progressPercent}
                progressMessage={progressMessage}
                handleVideoCheck={handleVideoCheck}
                writingLoader={writingLoader}
            />

            <Editor
            initialValue={null}
                onChange={handleEditorChange}
                changeTo={changeTo}
                setChangeTo={setChangeTo}
                addBlock={addBlock}
                setAddBlock={setAddBlock}
                writingLoader={writingLoader}
            />
        </Container>
        </>)}
        </>
    );
}

export default AIReport;
