import React from "react";
import { Col, Button, Nav } from "react-bootstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import css from "../index.module.css";
import chatsIcon from "../../../../images/icons/transcripts.svg";

const ThreadsSidebar = ({ className, onLinkClick }) => {
  const { project } = useSelector((state) => state.currentProject);
  const { _id, threads = [] } = project || {};
  const { state, search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(search);

  React.useEffect(() => {
    const threadId = params.get("t");

    // navigate to /chat if threadId is invalid
    if (threadId && !state?.noRedirect) {
      const thread = threads?.find((thread) => thread.id === threadId);
      if (!thread) history.push(`/p/${_id}/chat`);
    }
  }, [search, state, threads, history]);

  return (
    <Col className={`${css.threadsContainer} ${className ?? ""}`}>
      <div>
        <Button variant="outline-primary w-100" as={Link} to={`/p/${_id}/chat`}>
          New Chat
        </Button>
        <Nav className="flex-column mt-0 mt-lg-3">
          {threads.length > 0 &&
            threads.slice().reverse().map((thread, index) => (
              <Nav.Link
                as={Link}
                key={thread.id}
                active={thread.id === params.get("t")}
                className="project-custom-link"
                to={`/p/${_id}/chat?t=${thread.id}`}
                onClick={onLinkClick}
              >
                <img src={chatsIcon} alt="chat" className="project-menu-icon" style={{
                  marginRight: "0.2rem"
                }} />
                {thread?.title ?? `Chat ${index + 1}`}
              </Nav.Link>
            ))}
        </Nav>
      </div>
    </Col>
  );
};

export default ThreadsSidebar;
