import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import UserAvatar from "../../../../components/userAvatar";
import LogoBlueSmall from "../../../../images/logo-blue-small.svg";
import style from "./topbar.module.css";
import { logout } from "../../../../store/actions/authActions";
import { Menu } from "@mantine/core";

export const Topbar = () => {
  const { account, isAuthenticated } = useSelector((state) => state.auth) || {
    picture: null,
  };

  return (
    <div className={style.ueTopbar}>
      <div>
        <div className={style.ueLogo}>
          <Link to="/participant">
            <img src={LogoBlueSmall} alt="User Evaluation" />
          </Link>
        </div>
      </div>
      {isAuthenticated && (
        <div>
          <div className={style.ueProfilePic}>
            <Menu position="bottom-end">
              <Menu.Target>
                <button className="p-0 bg-transparent border-0">
                  {account?.picture ? (
                    <img src={account.picture} alt="Profile" />
                  ) : (
                    <UserAvatar user={account} />
                  )}
                </button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item>
                  <Link
                    to="/participant/settings"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Settings
                  </Link>
                </Menu.Item>

                <Menu.Item>
                  <a
                    rel="noopener noreferrer"
                    href="mailto:support@userevaluation.com"
                  >
                    Help & Support
                  </a>
                </Menu.Item>

                <Menu.Item onClick={logout}>Logout</Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        </div>
      )}
    </div>
  );
};
