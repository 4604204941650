import { createSlice } from "@reduxjs/toolkit";

const noteSlice = createSlice({
  name: "note",
  initialState: {
    notes: [],
    projectId: null, // projectId that the notes belong to
    loading: true,
    error: null
  },
  reducers: {
    requestStart(state, action) {
      state.loading = true;
      state.error = null;
    },
    getNotesByProjectId(state, action) {
      state.loading = false;
      state.notes = action.payload.notes;
      state.projectId = action.payload.projectId;
      state.error = null;
    },
    deleteNote(state, action) {
      state.notes = state.notes.filter((note) => note._id !== action.payload);
      state.loading = false;
      state.error = null;
    },
    createNote(state, action) {
      state.notes = [...state.notes, action.payload];
      state.loading = false;
      state.error = null;
    },
    requestFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export default noteSlice;
export const noteActions = noteSlice.actions;
