import React, { useState } from "react";
import { Table, Pagination } from "react-bootstrap";
import TableRow from "./tableRow";
function ParticipantsTable({ userdata }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  // Logic to calculate the indexes of the items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = userdata.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <Table
        striped
        bordered
        hover
        style={{
          width: "calc(100vw - 300px)",
          color:"var(--grey10)"
        }}
      >
        <thead>
          <tr>
            <th>sn</th>
            <th>name</th>
            <th>email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((user, index) => (
            <React.Fragment key={user?._id}>
              <TableRow user={user} itemNumber={indexOfFirstItem + index + 1} />
            </React.Fragment>
          ))}
        </tbody>
      </Table>

      <Pagination>
        {userdata.length > itemsPerPage && (
          <Pagination.Prev
            onClick={() => {
              if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
              }
            }}
          />
        )}

        {userdata.length > itemsPerPage && (
          <Pagination.Next
            onClick={() => {
              if (currentPage < Math.ceil(userdata.length / itemsPerPage)) {
                setCurrentPage(currentPage + 1);
              }
            }}
          />
        )}
      </Pagination>
    </>
  );
}

export default ParticipantsTable;
