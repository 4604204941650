import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  createThread,
  getMessages,
} from "../../../../store/actions/chatActions";
import { Container, Spinner, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import chartImg from "../../../../images/people-evaluation.svg";
import chartImgBlack from "../../../../images/people-evaluation-black.svg";
import lookoutWhite from "../../../../images/lookout-white.svg";
import lookout from "../../../../images/lookout.svg";
import styles from "./index.module.css";
import store from "../../../../store";
import { chatActions } from "../../../../store/slices/chatSlice";
import { modalActions } from "../../../../store/slices/modalSlice";
import { toastError } from "../../../../utils/toast";
import PromptSuggestions from "../../../../components/prompt-component/promptssuggestions";
import SendArea from "../../../../components/prompt-component/sendArea";
import AssetsWindow from "../../../../components/prompt-component/assets";
import MessageList from "../../../chat/components/messagelist";
import useSocketContext from "../../../../utils/SocketContext";
import { currentProjectActions } from "../../../../store/slices/currentProject";

const { dispatch } = store;

export const socketHandlers = {
  chatLog: (payload) => {
    const messageWithoutTyping = {
      _id: 0 + new Date(),
      message: payload.message,
      typing: false,
      messageType: "Instructions",
      sender: "AI",
    };
    dispatch(chatActions.sendMessage(messageWithoutTyping));
  },
  chatError: (payload) => {
    dispatch(chatActions.requestFail(payload));
    if (payload.message) {
      if (
        payload.message ===
        "You have run out of AI credits. Please upgrade your plan to continue."
      ) {
        // treat this as a 403
        dispatch(modalActions.showPayWallModal(true));
      }
      const messageWithoutTyping = {
        _id: 0 + new Date(),
        message: payload.message,
        typing: false,
        sender: "AI",
      };
      dispatch(chatActions.sendMessage(messageWithoutTyping));
      dispatch(chatActions.requestFinish());
    }
  },
  chunkResponse: (payload) => {
    const msg = {
      ...payload,
    };
    dispatch(chatActions.addTypingMessage(msg));
  },
  chatResponse: (payload) => {
    payload.response.forEach((response) => {
      const typingMessage = {
        ...response,
        typing: false,
      };
      dispatch(chatActions.sendMessage(typingMessage));
    });

    dispatch(chatActions.requestFinish());
  },
  updateThreadTitle: ({ threadId, title }) => {
    dispatch(currentProjectActions.updateThreadTitle({ threadId, title }));
  },
};

export const ChatSocketTab = ({ threadId }) => {
  const { project: projectData } = useSelector((state) => state.currentProject);
  let selectedProjectEmpty =
    projectData?._id && projectData?.status === "Empty";
  const { messages, loading } = useSelector((state) => state.chat);
  const { account } = useSelector((state) => state.auth);
  const [message, setMessage] = useState("");
  const [darkMode, setDarkMode] = useState(false);
  const [smoothScroll, setSmoothScroll] = useState(false);
  const [loadInstructions, setloadInstructions] = useState(false);
  const [showAssetWindow, setShowAssetWindow] = useState(false);
  const [showPromptSuggestion, setShowPromptSuggestion] = useState(true);
  const history = useHistory();
  // get chat data every time the project changes
  useEffect(() => {
    // dark mode
    const darkModeLs = localStorage.getItem("darkMode");
    if (darkModeLs && darkModeLs !== "true") {
      setDarkMode(false);
    } else {
      setDarkMode(true);
    }

    if (threadId) getMessages(projectData?._id, threadId);
  }, [projectData, threadId]);

  // const socket = useSocketIO(socketHandlers);
  const socket = useSocketContext();
  useEffect(() => {
    if (socket) {
      socket.attachHandlers(socketHandlers);
    }
    return () => socket.detachHandlers(socketHandlers);
  }, [socket]);

  // initialise the messages as empty at first load
  useEffect(() => {
    // get the url and if no threadId is present, make the messages empty
    if (!threadId) {
      dispatch(chatActions.setMessages([]));
      setShowPromptSuggestion(true);
    }
  }, [threadId]);

  useEffect(() => {
    if (messages.length > 0) {
      setShowPromptSuggestion(false);
    }
  }, [messages]);

  const handleSendMessage = async (message) => {
    const newMessage = {
      sender: "User",
      message,
      createdAt: new Date().toISOString(),
      projectId: projectData?._id,
      threadId,
      userId: account._id,
    };

    let newThreadId = threadId;

    if (!newThreadId) {
      const { data, error } = await createThread(projectData?._id);

      if (error) return toastError(error.message);

      newThreadId = data.data;
      newMessage.threadId = newThreadId;

      history.push(`/project/${projectData?._id}/chat?t=${newThreadId}`, {
        noRedirect: true,
      });

      // delay 500ms to make sure we switch to the new thread
      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    dispatch(chatActions.requestStart());
    dispatch(chatActions.sendMessage(newMessage));
    setMessage("");
    setloadInstructions(true);
    socket.sendMessage(message, projectData?._id, newThreadId);
    setShowPromptSuggestion(false);
  };

  const emptyProject = (
    <Container className="d-flex align-items-center justify-content-center h-100">
      <div className="text-center">
        <img
          src={darkMode ? lookoutWhite : lookout}
          alt="processing"
          className="processing-img"
          style={{ maxWidth: "400px" }}
        />
        <br />
        <h1 className="processing-heading">Your project looks empty</h1>
        <p className="processing-paragraph">
          You have not uploaded files to your project yet. Upload files to get
          started.
        </p>
        <Link to={"/project/" + projectData?._id}>
          <Button variant="outline-primary">Upload Files</Button>
        </Link>
      </div>
    </Container>
  );
  const chatInput = (
    <div
      className={`mt-2 ${styles.chatInputContainer}`}
      style={{
        backgroundColor: darkMode ? "var(--grey1)" : "white",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "800px",
        }}
      >
        <PromptSuggestions
          handleSendMessage={handleSendMessage}
          showPromptSuggestion={showPromptSuggestion}
          setShowPromptSuggestion={setShowPromptSuggestion}
        />
        <SendArea
          selectedProject={projectData}
          setMessage={setMessage}
          handleSendMessage={handleSendMessage}
          message={message}
          loading={loading}
          setShowAssetWindow={setShowAssetWindow}
          showAssetWindow={showAssetWindow}
        />
        <AssetsWindow
          showAssetWindow={showAssetWindow}
          selectedProject={projectData}
        />
      </div>
    </div>
  );

  return (
    <div className="project-content-area chat-tab overflow-hidden">
      <Container
        className="cc-container expand-width overflow-hidden"
        style={{
          padding: "0",
          position: "relative",
        }}
      >
        {selectedProjectEmpty && emptyProject}

        {projectData?.status !== "Ready" && !selectedProjectEmpty && (
          <PreparingData darkMode={darkMode} />
        )}
        {/* if chat is ready */}
        {projectData?.status === "Ready" && (
          <>
            {/* if selected project empty */}

            {/* if selected project not empty */}
            {!selectedProjectEmpty && (
              <>
                <Container
                  className={`${styles.chatContainer}  expand-width`}
                  style={{
                    position: "static",
                    width: "100%",
                    padding: "0",
                    margin: "0px !important",
                  }}
                >
                  <Container
                    className={`${styles.chatContainer}  expand-width`}
                    style={{
                      position: "static",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      padding: "0",
                      margin: "0",
                      overflowY: "scroll",
                      height: "calc(88vh )",
                      paddingTop: "1rem",
                    }}
                  >
                    <MessageList
                      messages={threadId ? messages : []}
                      smoothScroll={smoothScroll}
                      setSmoothScroll={setSmoothScroll}
                      loadInstructions={loadInstructions}
                      setloadInstructions={setloadInstructions}
                    />

                    {chatInput}
                  </Container>
                </Container>
              </>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export const PreparingData = ({ darkMode }) => {
  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <div className="text-center">
        <img
          src={darkMode ? chartImg : chartImgBlack}
          alt="processing"
          className="processing-img"
        />
        <br />
        <h1 className="processing-heading">Your data is being processed</h1>
        <p className="processing-paragraph">
          Please give our AI a few minutes before it can answer all your
          questions.
        </p>
        <Spinner animation="border" variant="outline-primary" size="sm" />
        <br />
      </div>
    </div>
  );
};
