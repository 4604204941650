/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import style from "./bottom-bar.module.css";

export function handleStepAGS(stepNumber, testId) {
  switch (stepNumber) {
    case 1:
      return "/tests/new";
    case 2:
      return `/tests/ai-generated-survey/${testId}/describe-test`;
    case 3:
      return `/tests/ai-generated-survey/${testId}/select-filter`;
    case 4:
      return `/tests/ai-generated-survey/${testId}/screen`;
    case 5:
      return `/tests/ai-generated-survey/${testId}/additional-details`;
    case 6:
      return `/tests/ai-generated-survey/${testId}/review`;
    default:
      return "/tests/new";
  }
}

function BottomBar({
  currentStep,
  primaryButton = "Next",
  displaySkipButton = false,
  testId,
  primaryButtonAction,
  disablePrimaryButton = false,
}) {
  const { stepThreeChoice } = useSelector((state) => state.test);
  const history = useHistory();
  const [prevStep, setPrevStep] = useState("");
  const [nextStep, setNextStep] = useState("");

  useEffect(() => {
    setPrevStep(handleStepAGS(currentStep - 1, testId));
    setNextStep(handleStepAGS(currentStep + 1, testId));
  }, [currentStep, testId]);

  function handleBackButton() {
    if (currentStep === 3) {
      history.push(`/tests/ai-generated-survey/${testId}/select-filter`);
    } else if (currentStep === 4) {
      if (stepThreeChoice === "filter-audience") {
        history.push(`/tests/ai-generated-survey/${testId}/filter-audience`);
      } else {
        history.push(`/tests/ai-generated-survey/${testId}/add-participants`);
      }
    } else {
      history.push(prevStep);
    }
  }

  return (
    <div className={style.bottomBar}>
      <Button
        type="button"
        variant="secondary"
        className={style.backButton}
        onClick={() => handleBackButton()}
      >
        Back
      </Button>
      <div className={style.rightButtons}>
        {displaySkipButton && (
          <Button
            type="button"
            variant="outline-primary"
            className={style.skipButton}
            onClick={() => history.push(nextStep)}
          >
            Skip Screener
          </Button>
        )}
        <Button
          type="button"
          variant="primary"
          className={style.primaryButton}
          onClick={primaryButtonAction}
          disabled={disablePrimaryButton}
        >
          {primaryButton}
        </Button>
      </div>
    </div>
  );
}

export default BottomBar;
