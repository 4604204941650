import React, { useEffect, useRef, useState } from "react";
import Button from "./button";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../../../../../store/slices/modalSlice";
import { BsStarFill, BsStars } from "react-icons/bs";
import styles from "../index.module.css";
import jsPDF from "jspdf";
import { getLinkByResourceIdAction } from "../../../../../store/actions/publicLinkActions";
import { RxShare2 } from "react-icons/rx";
import { createLaneAction } from "../../../../../store/actions/laneActions";
import ShareCollectionModal from "../../modals/shareCollection";
import { RiShareForward2Line } from "react-icons/ri";
import ExportCollectionModal from "../../modals/exportCollection";
import AITagsModal from "../../modals/addAiTagsModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DropDown from "./dropDowns";
import AddTagBtn from "./AddTagBtn";
import {
  deleteInsights,
  regenerateInsight,
} from "../../../../../store/actions/projectActions";
import { toastError, toastSuccess } from "../../../../../utils/toast";
import {
  loadProject,
  saveReport,
} from "../../../../../store/actions/currentProjectActions";
import { getSentimentByAssetId } from "../../../../../store/actions/sentimentActions";
import { ribbonActions } from "../../../../../store/slices/ribbonSlice";
import { saveAs } from "file-saver";
import formatTranscript from "./formatTranscript";
import dayjs from "dayjs";

let doc = new jsPDF();

// buttons for report and presentation
function savePDF(fileName) {
  const editorContainer = document.querySelector(".codex-editor__redactor");

  doc.html(editorContainer, {
    callback: function (doc) {
      doc.save(fileName?.length > 0 ? fileName + ".pdf" : "newpdf.pdf");
    },
    margin: 8,
    unit: "px",
    format: [
      doc.internal.pageSize.getWidth(),
      doc.internal.pageSize.getHeight(),
    ],
    padding: 0,
    html2canvas: {
      scale: 0.2,
    },
    putOnlyUsedFonts: true,
  });
}

export function CreateWithAIBtn({ tab, hideName }) {
  const dispatch = useDispatch();
  const { genPresentation, genReport } = useSelector((state) => state.modal);
  if (tab === "report") {
    return (
      <>
        <Button
          ele={{ name: "Create with AI", icon: <BsStars /> }}
          hideName={hideName}
          pressed={genReport}
          handleClick={(e) => {
            dispatch(modalActions.showGenReportModal());
          }}
        />
        <span className={styles.toolbar__span}>|</span>
      </>
    );
  }

  return (
    <>
      <Button
        hideName={hideName}
        ele={{ name: "Create with AI", icon: <BsStars /> }}
        pressed={genPresentation}
        handleClick={(e) => {
          dispatch(modalActions.showGenPresentationModal());
        }}
      />
      <span className={styles.toolbar__span}>|</span>
    </>
  );
}

export function ExportPDF({ tab, hideName }) {
  const { project } = useSelector((state) => state.currentProject);
  const { account } = useSelector((state) => state.auth);
  const isFreeOrBasic =
    account?.plan?.type === "Free" ||
    account?.plan?.type === "Basic Monthly" ||
    account?.plan?.type === "Basic Yearly";
  const isActive = project?.reports?.length > 0;
  const dispatch = useDispatch();
  return (
    <>
      <Button
        disabled={!isActive}
        ele={{ name: "Export PDF", icon: <RxShare2 /> }}
        hideName={hideName}
        handleClick={() => {
          if (isFreeOrBasic) {
            dispatch(modalActions.showPayWallModal());
          } else {
            savePDF(project?.name);
          }
        }}
      />
    </>
  );
}

export function AutoSave({ data }) {
  const [saving, setSaving] = useState(false);
  const {
    editorData = {},
    project: { reportId },
  } = useSelector((state) => state.currentProject);
  const lastSave = useRef(editorData.time);
  const timeout = useRef(null);


  const editorModified =
    typeof editorData.time === "number" &&
    typeof lastSave.current === "number" &&
    editorData.time > lastSave.current;

  const handleSave = async (data, silent = false) => {
    if (!data.time) return;
    lastSave.current = data.time;

    setSaving(true);
    const { error } = await saveReport(reportId, data, false);

    if (error && !silent) {
      toastError(error.message);
    }

    setSaving(false);
  };

  useEffect(() => {
    if (!lastSave.current) lastSave.current = editorData.time;

    if (!editorModified) return;

    timeout.current = setTimeout(() => {
      handleSave(editorData, true);
    }, 3000);
  }, [editorModified, editorData.time]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  return (
    <p className="mb-0 d-flex gap-1 align-items-center">
      <span>Last saved: {dayjs(editorData.time).format("HH:MM A")}</span>
      <span>|</span>
      <Button
        ele={{
          name: "Save",
        }}
        disabled={saving || !editorModified}
        handleClick={() => handleSave(editorData)}
        className={styles.toolbar__autosave}
      />
    </p>
  );
}

export const ShareReportBtn = ({ hideName }) => {
  const dispatch = useDispatch();
  const { project: projectData } = useSelector((state) => state.currentProject);
  const { reportLinkSharePopup } = useSelector((state) => state.modal);
  const isActive = projectData?.reports?.length > 0;
  const { account } = useSelector((state) => state.auth);
  const isFreeOrBasic =
    account?.plan?.type === "Free" ||
    account?.plan?.type === "Basic Monthly" ||
    account?.plan?.type === "Basic Yearly";
  return (
    <>
      <Button
        disabled={!isActive || isFreeOrBasic}
        ele={{ name: "Share", icon: <RiShareForward2Line /> }}
        hideName={hideName}
        pressed={reportLinkSharePopup}
        handleClick={() => {
          if (isFreeOrBasic) {
            dispatch(modalActions.showPayWallModal());
          } else {
            getLinkByResourceIdAction(projectData?._id, "project report");
            dispatch(modalActions.showShareReportLinkModal());
          }
        }}
      />
      <span className={styles.toolbar__span}>|</span>
    </>
  );
};

// button for collection and tags
export function AddSection({ hideName }) {
  const { project } = useSelector((state) => state.currentProject);
  const dispatch = useDispatch();
  const projectId = project?._id;
  return (
    <>
      <Button
        ele={{ name: "Add a Section", icon: <BsStarFill /> }}
        hideName={hideName}
        handleClick={(e) => {
          dispatch(createLaneAction("Untitled Section", projectId));
        }}
      />
      <span className={styles.toolbar__span}>|</span>
    </>
  );
}
export function ShareCollectionBtn({ hideName }) {
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state.auth);
  const isFreeOrBasic =
    account?.plan?.type === "Free" ||
    account?.plan?.type === "Basic Monthly" ||
    account?.plan?.type === "Basic Yearly";
  return (
    <>
      <ShareCollectionModal />
      <Button
        ele={{ name: "Share", icon: <RiShareForward2Line /> }}
        hideName={hideName}
        handleClick={(e) => {
          if (isFreeOrBasic) {
            dispatch(modalActions.showPayWallModal());
          } else {
            dispatch(modalActions.showShareBoardLinkModal());
          }
        }}
      />
    </>
  );
}

export function ExportCollectionBtn({ hideName }) {
  const dispatch = useDispatch();
  const { boardExportPopup } = useSelector((state) => state.modal);
  const { account } = useSelector((state) => state.auth);
  const isFreeOrBasic =
    account?.plan?.type === "Free" ||
    account?.plan?.type === "Basic Monthly" ||
    account?.plan?.type === "Basic Yearly";
  return (
    <>
      <ExportCollectionModal />
      <Button
        ele={{ name: "Export CSV", icon: <RxShare2 /> }}
        hideName={hideName}
        pressed={boardExportPopup}
        handleClick={() => {
          if (isFreeOrBasic) {
            dispatch(modalActions.showPayWallModal());
          } else {
            dispatch(modalActions.showExportBoardModal());
          }
        }}
      />
      <span className={styles.toolbar__span}>|</span>
    </>
  );
}
export function ExportTagsButton({ hideName }) {
  const dispatch = useDispatch();
  const { tagBoardPopup } = useSelector((state) => state.modal);
  const { account } = useSelector((state) => state.auth);
  const { tags } = useSelector((state) => state.tags);
  const isTagsAvailable = tags?.length > 0;
  const isFreeOrBasic =
    account?.plan?.type === "Free" ||
    account?.plan?.type === "Basic Monthly" ||
    account?.plan?.type === "Basic Yearly";
  return (
    <>
      <ExportCollectionModal isTag={true} />
      <Button
        disabled={!isTagsAvailable}
        ele={{ name: "Export CSV", icon: <RxShare2 /> }}
        hideName={hideName}
        pressed={tagBoardPopup}
        handleClick={() => {
          if (isFreeOrBasic) {
            dispatch(modalActions.showPayWallModal());
          } else {
            dispatch(modalActions.showTagBoardModal());
          }
        }}
      />
    </>
  );
}
export function AddAiTagBtn({ hideName, icon, islast }) {
  const dispatch = useDispatch();
  const { AITag } = useSelector((state) => state.modal);
  const { account } = useSelector((state) => state.auth);
  const isFree = account?.plan?.type === "Free";
  return (
    <>
      <AITagsModal />
      <Button
        ele={{ name: "Tag with AI", icon: icon }}
        hideName={hideName}
        pressed={AITag}
        handleClick={() => {
          if (isFree) {
            dispatch(modalActions.showPayWallModal());
          } else {
            dispatch(modalActions.showAITagModal());
          }
        }}
      />
      {!islast && <span className={styles.toolbar__span}>|</span>}
    </>
  );
}

export function AddNewTag({ hideName, icon, activeTab, assetId }) {
  const router = useHistory();
  const { project } = useSelector((state) => state.currentProject);
  if (activeTab === "tags") {
    return (
      <>
        <Button
          tooltipText={"Select text from Data tab to create tags manually"}
          ele={{ name: "Add a New Tag", icon: icon }}
          hideName={hideName}
          handleClick={() => {
            router.push(`/project/${project?._id}/data`);
          }}
        />

        <span className={styles.toolbar__span}>|</span>
      </>
    );
  }
  return (
    <>
      <AddTagBtn icon={icon} assetId={assetId} hideName={hideName} />
      {/* <Button
        tooltipText={(!data?.selectionData) && "Select text to add a tag"}
        disabled={!data?.selectionData}
        ele={{ name: "Add a Tag", icon: icon }}
        hideName={hideName}
        handleClick={() => {
          dispatch(modalActions.showAddTagModal());
        }}
      />
      <span className={styles.toolbar__span}>|</span> */}
    </>
  );
}
export function AddAnInsightBtn({ hideName, icon, activeTab }) {
  const router = useHistory();
  const { project } = useSelector((state) => state.currentProject);
  const { data } = useSelector((state) => state.ribbon);
  const dispatch = useDispatch();
  if (activeTab === "insights") {
    return (
      <Button
        tooltipText={"Select text from Data tab to create insights manually"}
        ele={{ name: "Add an Insight", icon: icon }}
        hideName={hideName}
        handleClick={() => {
          router.push(`/project/${project?._id}/data`);
        }}
      />
    );
  }
  return (
    <Button
      tooltipText={
        !data?.selectionData && "Select text to add insights manually"
      }
      ele={{ name: "Add an Insight", icon: icon }}
      hideName={hideName}
      handleClick={() => {
        dispatch(modalActions.showInsightsModal());
      }}
      disabled={!data?.selectionData}
    />
  );
}

export function AddAIInsightBtn({ hideName, icon }) {
  const dispatch = useDispatch();
  const { AIInsights } = useSelector((state) => state.modal);
  return (
    <>
      <Button
        ele={{ name: "Get Insights with AI", icon: icon }}
        hideName={hideName}
        pressed={AIInsights}
        handleClick={() => dispatch(modalActions?.showAIInsightsModal())}
      />
      <span className={styles.toolbar__span}>|</span>
    </>
  );
}
export function FiterByTagBtn({ hideName, icon }) {
  const { clips } = useSelector((state) => state.clip);
  let tags = [...new Set(clips?.map((clip) => clip.clipTags)?.flat())] || [];
  return (
    <DropDown
      item={"Filter by Tags"}
      isTag={true}
      subItems={tags}
      hideName={hideName}
      icon={icon}
    />
  );
}

const regenerateInsightBySession = async (
  insightType,
  videoId,
  projectId,
  setLoading
) => {
  setLoading(true);
  try {
    let { data, error } = await regenerateInsight({
      projectId,
      insightType,
      videoId,
    });
    await loadProject(projectId);
    if (data) {
      toastSuccess("Your new AI insights have been regenerated successfully");
      setLoading(false);
    }

    if (error) {
      console.error(error);
      toastError(error?.message);
      setLoading(false);
    }
  } catch (err) {
    console.error(err);
  }
};

export function RegenerateInsightsBtn({ hideName, icon, videoId }) {
  const { data } = useSelector((state) => state.ribbon);
  const insightType = data?.insightType;
  const { project } = useSelector((state) => state.currentProject);
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Button
        loading={loading}
        ele={{ name: "Regenerate All Insights", icon: icon }}
        tooltipText={"Regenerate all insights in the current insight category"}
        hideName={hideName}
        handleClick={() => {
          if ((insightType, videoId, project?._id, setLoading)) {
            regenerateInsightBySession(
              insightType,
              videoId,
              project?._id,
              setLoading
            );
          } else {
            toastError(
              "Please select an insight type and video to regenerate insights"
            );
          }
        }}
        disabled={!insightType || !videoId || loading}
      />
      <span className={styles.toolbar__span}>|</span>
    </>
  );
}
const deleteInsightBySession = async (
  insightType,
  videoId,
  projectId,
  history
) => {
  let { data, error } = await deleteInsights(insightType, videoId);

  if (data) {
    await loadProject(projectId);
    toastSuccess("Insight deleted");
    history.push(`/project/${projectId}/data`);
  }

  if (error) {
    console.error(error);
  }
};
export function DeleteInsightBtn({ hideName, icon, videoId }) {
  const { data } = useSelector((state) => state.ribbon);
  const insightType = data?.insightType;
  const { project } = useSelector((state) => state.currentProject);
  const history = useHistory();
  return (
    <Button
      ele={{ name: "Delete Insight Type", icon: icon }}
      hideName={hideName}
      handleClick={() => {
        deleteInsightBySession(insightType, videoId, project?._id, history);
      }}
      disabled={!insightType || !videoId}
    />
  );
}

export function FilterBySessionBtn({ hideName, icon }) {
  const { project } = useSelector((state) => state.currentProject);
  const { clips } = useSelector((state) => state.clip);
  const videos = project?.videos
    ?.filter((vid) => {
      return clips?.find((clip) => clip.assetId === vid._id);
    })
    .map((vid) => ({
      title: vid.name,
      _id: vid._id,
    }));
  return (
    <DropDown
      item={"Filter by Sessions"}
      subItems={videos}
      hideName={hideName}
      icon={icon}
      isTag={false}
    />
  );
}

export function TagWithAIBtn({ hideName, icon, islast }) {
  const dispatch = useDispatch();
  const { AITag } = useSelector((state) => state.modal);
  return (
    <>
      <Button
        ele={{ name: "Tag with AI", icon: icon }}
        hideName={hideName}
        pressed={AITag}
        handleClick={() => {
          dispatch(modalActions.showAITagModal());
        }}
      />
      {!islast && <span className={styles.toolbar__span}>|</span>}
    </>
  );
}
export function CreateClipTrimBtn({ hideName, icon }) {
  const dispatch = useDispatch();
  const { project } = useSelector((state) => state.currentProject);
  const doesVideoOrAudioExist = project?.videos?.find(
    (video) => video.assetType === "VIDEO" || video.assetType === "AUDIO"
  );
  return (
    <Button
      tooltipText={"Create clips by trimming video/audio"}
      ele={{ name: "Create a Clip", icon: icon }}
      hideName={hideName}
      disabled={!doesVideoOrAudioExist}
      handleClick={() => {
        dispatch(modalActions.showCreateClipTrimModal());
      }}
    />
  );
}
export function CreateClipBtn({ hideName, icon }) {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.ribbon);
  return (
    <Button
      tooltipText={!data?.selectionData && "Select text to create a clip"}
      ele={{ name: "Create a Clip", icon: icon }}
      hideName={hideName}
      disabled={!data?.selectionData}
      handleClick={() => {
        dispatch(modalActions.showCreateClipModal());
      }}
    />
  );
}
export function SentimentAnalysisBtn({ hideName, icon, name, assetId }) {
  const { account } = useSelector((state) => state.auth);
  const { sentiment, loading } = useSelector((state) => state.sentiment);
  const isSentimentAvailable =
    sentiment?.filter((s) => s.assetId === assetId)?.length > 0;
  const { showSentiment } = useSelector((state) => state.ribbon);
  const dispatch = useDispatch();
  const { project } = useSelector((state) => state.currentProject);
  const isFree = account?.plan?.type === "Free";
  let language = project?.language;

  return (
    <>
      <Button
        ele={{ name: name, icon: icon }}
        hideName={hideName}
        pressed={isSentimentAvailable && showSentiment}
        handleClick={async () => {
          if (isFree) {
            dispatch(modalActions.showPayWallModal());
          } else {
            if (
              language === "en_us" ||
              language === "en_uk" ||
              language === "en_au" ||
              language === "en"
            ) {
              if (!isSentimentAvailable) {
                await getSentimentByAssetId(assetId);
                dispatch(ribbonActions.setSentiment(true));
              } else {
                dispatch(ribbonActions.setSentiment(!showSentiment));
              }
            } else {
              toastError(
                "Sentiment is only available for projects in English language. To enable sentiment for other languages, contact support"
              );
            }
          }
        }}
        loading={loading}
      />
      <span className={styles.toolbar__span}>|</span>
    </>
  );
}
export function ExportTranscriptBtn({ hideName, icon, assetId }) {
  const [loading, setLoading] = useState(false);
  const { account } = useSelector((state) => state.auth);
  const { project } = useSelector((state) => state.currentProject);
  const { assetSpeakers: speakers } = useSelector((state) => state.ribbon);
  const asset = project?.videos?.find((video) => video._id === assetId);

  let transcript = asset?.transcript;
  const isFreeOrBasic =
    account?.plan?.type === "Free" ||
    account?.plan?.type === "Basic Monthly" ||
    account?.plan?.type === "Basic Yearly";
  const dispatch = useDispatch();
  const handleExport = () => {
    setLoading(true);
    if (transcript) {
      let formattedTranscript = formatTranscript(transcript, speakers);

      const blob = new Blob([formattedTranscript], { type: "text/plain" });
      saveAs(blob, `${asset?.name}.txt`);
    }

    setLoading(false); // Set loading state to false after export
  };
  return (
    <>
      <ExportCollectionModal />
      <Button
        ele={{ name: "Export Transcript", icon: icon }}
        hideName={hideName}
        disabled={transcript?.length === 0}
        handleClick={() => {
          if (isFreeOrBasic) {
            dispatch(modalActions.showPayWallModal());
          } else {
            handleExport();
          }
        }}
        loading={loading}
      />
      <span className={styles.toolbar__span}>|</span>
    </>
  );
}

export function DeleteTagBtn({ hideName, icon }) {
  const dispatch = useDispatch();
  const { tags } = useSelector((state) => state.tags);
  const isTagsAvailable = tags?.length > 0;
  return (
    <Button
      ele={{ name: "Bulk Delete", icon: icon }}
      hideName={hideName}
      disabled={!isTagsAvailable}
      handleClick={() => {
        dispatch(modalActions.showDeleteTagModal());
      }}
    />
  );
}
