import store from "..";
import { searchResultsActions } from "../slices/searchResultsSlice";
import { callExternalApi } from "../../services/external-api.service";
import { toastError } from "../../utils/toast";

const { dispatch } = store;

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

// search projects
export const searchProjectsAction = async (query) => {
  dispatch(searchResultsActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/search/global`,
    method: "POST",
    data: {
      query: query
    }
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    console.error(error);
    // if 404 set empty results
    if (error.status === 404) {
      dispatch(searchResultsActions.setNoResults());
    } else {
      dispatch(searchResultsActions.requestFail(error.message));
      toastError("Something went wrong! Please try again later.");
    }
  }
  else {
    if (data.length === 0) {
      dispatch(searchResultsActions.setNoResults());
    } else {
      dispatch(searchResultsActions.setSearchResults({ results: data }));
    }
  }
};

// clear search results
export const clearSearchResultsAction = () => {
  dispatch(searchResultsActions.clearSearchResults());
};
