import React from "react";
import MiniPlayer from "../../../mini-player";

/**
 * @typedef {Object} EditorClipReadDisplayProps
 * @property {Object} data
 * @property {number} data.start
 * @property {number} data.end
 * @property {string} data.url
 * @param {EditorClipReadDisplayProps} props
 */
const EditorClipsDisplay = ({ data }) => {
  const { url, start, end } = data || {};

  return (
    <MiniPlayer
      file={{ assetType: "VIDEO", link: url }}
      startTime={start * 1000}
      endTime={end * 1000}
    />
  );
};

export default EditorClipsDisplay;
