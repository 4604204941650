import React, { useEffect, useRef, useState } from 'react';
import { FormControl, InputGroup, Spinner } from 'react-bootstrap';
import Draggable from 'react-draggable';
import { FiArrowRight } from 'react-icons/fi';
import { MdAutoAwesome } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Transforms, Node } from 'slate';
import { useSlate } from 'slate-react';

import { openAINoteCompletion } from '../../../../../store/actions/noteActions';
// toastError, toastSuccess
import { toastError } from '../../../../../utils/toast';


// Ask AI Button
// Button that opens up a Bootstrap modal
export default function AskAIButton({currentNotePage}) {
  const { darkMode } = useSelector(state => state.theme);
  const { account } = useSelector(state => state.auth);
  const editor = useSlate();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputText, setInputText] = useState('');
  const inputRef = useRef();
  const modalRef = useRef();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const getTextFromSlate = (nodes) => {
    return nodes.map(n => Node.string(n)).join('\n')
  }


  const handleAskAI = async () => {

    // if there is no text, return
    if (inputText === '') {
      toastError('Please enter some instructions');
      return;
    }

    setLoading(true);
    

    const text = getTextFromSlate(editor.children);


    // Send the text to the backend

    const payload = {
      nodeId: currentNotePage,
      noteText: text,
      instructions: inputText,
      userId: account._id
    }
    

    const { data, error } = await openAINoteCompletion(payload);

    if (data) {

      insertText(data.completion);

      setLoading(false);
      
      handleClose();
    }

    if (error) {
      setLoading(false);
      console.error(error);
    }

  }

  // insert text into slate editor by fake typing it
  const insertText = (text) => {
    const textArray = text.split('');
    
    // interval to type the text
    const interval = setInterval(() => {
      if (textArray.length > 0) {
        const char = textArray.shift();

        // insert the character at the end of the editor
        Transforms.insertText(editor, char);
      } else {
        // clear the interval
        clearInterval(interval);
      }
    }, 5);
  }

  const handleExampleClick = (exampleText) => {
    setInputText(exampleText);
  }


  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show]);

  return (
    <>
      <span
        onClick={handleShow}
        style={{
          cursor: 'pointer',
          color: darkMode ? '#aaa' : '#777',
          fontSize: '12px',
          fontWeight: 'bold',
          marginLeft: '0px',
        }}>
        <MdAutoAwesome />&nbsp;&nbsp;Ask AI
      </span>

      {show && (
        <Draggable handle=".drag-handle">
          <div className="ai-modal drag-handle" ref={modalRef}>
            <div className="modal-body">
              <InputGroup>
                <InputGroup.Text style={{ borderRight: 'none' }}>
                  <MdAutoAwesome style={{
                    color: '#0085FF',
                  }} />
                </InputGroup.Text>
                <FormControl
                  className="ai-input"
                  ref={inputRef}
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  placeholder="Ask AI to edit or generate"
                />
                <InputGroup.Text style={{ borderLeft: 'none' }} onClick={handleAskAI}>
                  {(loading) ? <Spinner animation="border" size="sm" variant="primary" /> : <FiArrowRight />}
                </InputGroup.Text>
              </InputGroup>
              <p className="prompt-header">Sample instructions</p>
              <div className="example-prompts">
                <p onClick={() => handleExampleClick("Continue writing")}>Continue writing...</p>
                <p onClick={() => handleExampleClick('Write a research plan')}>Write a research plan...</p>
                <p onClick={() => handleExampleClick('Summarize insights from this document')}>Summarize insights from this document</p>
                <p onClick={() => handleExampleClick('Create a list of action items from this document')}>Create a list of action items from this document</p>
              </div>
            </div>
          </div>
        </Draggable>
      )}
    </>
  );
}
