/* eslint-disable no-unused-vars */
import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { MdPeopleAlt, MdEditDocument, MdCalendarToday } from "react-icons/md";
import { FaClock, FaMoneyBillWaveAlt } from "react-icons/fa";
import { BiSolidDetail } from "react-icons/bi";
import { TestLayout } from "../components/test-layout";
import style from "./index.module.css";
import BottomBar from "../components/bottom-bar";
import useSocketContext from "../../../../utils/SocketContext";
import { EMIT_TYPES, EVENT_TYPES } from "../../routes/test-route";
import { useSelector } from "react-redux";
import { uploadNDA } from "../../../../store/actions/testActions";
import { GoLinkExternal } from "react-icons/go";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { toastError } from "../../../../utils/toast";

const UE_NDA = "/user-evaluation-std-nda.pdf";

function ACI_AdditionalDetails({ match }) {
  const socket = useSocketContext();
  const { currentTest } = useSelector((state) => state.test);
  const {
    account: { engagePlan = {} },
  } = useSelector((state) => state.auth);

  const [docSign, setDocSign] = React.useState({
    uploadDoc: false,
    selectedRadio: "useUENDA",
  });
  const [buttonLoading, setButtonLoading] = React.useState(false);

  const [values, setValues] = React.useState({
    accent: "American",
    voice: "f",
  });
  const formRef = React.useRef(null);
  const fileInputRef = React.useRef(null);

  React.useEffect(() => {
    if (currentTest) {
      const { accent, voice, ndaLink } = currentTest;
      setValues({
        accent,
        voice,
      });

      if (ndaLink) {
        setDocSign({
          uploadDoc: ndaLink !== "" && ndaLink !== UE_NDA,
          selectedRadio:
            ndaLink === ""
              ? "notApplicable"
              : ndaLink === UE_NDA
              ? "useUENDA"
              : "custom",
        });
      }
    }
  }, [currentTest]);

  React.useEffect(() => {
    if (!socket) return;

    const handler = {
      [EMIT_TYPES.ERROR]: () => {
        setButtonLoading(false);
      },
    };

    socket.attachHandlers(handler);
    return () => {
      socket.detachHandlers(handler);
    };
  }, [socket]);

  const submitAdditionalDetails = (e) => {
    e?.preventDefault();

    if (docSign.uploadDoc && !currentTest.ndaLink)
      return toastError("Please upload the document for signature");

    setButtonLoading(true);

    const data = new FormData(e.currentTarget);
    const payload = Object.fromEntries(data.entries());
    payload.accent = values.accent || "American";
    payload.voice = values.voice || "f";
    payload.nParticipants = parseInt(payload.nParticipants);
    payload.reward = parseInt(process.env.REACT_APP_DEFAULT_REWARD);
    payload.duration = parseInt(payload.duration);

    socket.testUpdates(EVENT_TYPES.ADDITIONAL_DETAILS, {
      testId: match.params.id,
      ...payload,
    });
  };

  const { total: aiInterviewsUETotal, used: aiInterviewsUEUsed } =
    engagePlan.aiInterviewsUEPool;
  const { total: aiInterviewsOwnTotal, used: aiInterviewsOwnUsed } =
    engagePlan.aiInterviewsOwnParticipants;

  const maxParticipantLimit =
    aiInterviewsUETotal -
    aiInterviewsUEUsed +
    aiInterviewsOwnTotal -
    aiInterviewsOwnUsed;

  return (
    <TestLayout stepNumber={6} testId={match.params.id}>
      <div className={style.wrapper}>
        <div className={style.title}>Participants & Agents Settings</div>
        <div className={style.subtitle}>
          How would you like to recruit participants for this study?
        </div>
        <div className={style.main}>
          <Form
            className={style.form}
            ref={formRef}
            onSubmit={submitAdditionalDetails}
          >
            <div className={style.inputGroup}>
              <label>
                <MdPeopleAlt color="#0085FF" />
                &nbsp; How many participants would you like to recruit?
              </label>
              <Form.Control
                key={currentTest.nParticipants}
                type="number"
                name="nParticipants"
                id="nParticipants"
                min={currentTest.invitedParticipants?.length || 5}
                defaultValue={
                  currentTest.nParticipants ||
                  currentTest.invitedParticipants?.length ||
                  5
                }
                max={maxParticipantLimit}
                placeholder="Number of participants"
                className={style.input}
                required
              />
            </div>
            {/* <div className={style.inputGroup}>
              <label>
                <FaMoneyBillWaveAlt color="#0085FF" />
                &nbsp; Set an incentive for the participants for each session
              </label>
              <InputGroup className={style.inputWrapper}>
                <InputGroup.Text className={style.currency}>$</InputGroup.Text>
                <Form.Control
                  key={currentTest.reward}
                  type="number"
                  name="reward"
                  id="reward"
                  min={5}
                  defaultValue={currentTest.reward || 30}
                  placeholder="Incentive per session"
                  className={`${style.input} ${style.incentiveInput}`}
                  required
                />
              </InputGroup>
              <Form.Text className="text-muted">
                Note that User Evaluation will charge $2.5 per session. Minimum
                reward per session is $5 and User Evaluation will automatically
                distribute this incentive.
              </Form.Text>
            </div> */}
            <div className={style.inputGroup}>
              <label>
                <FaClock color="#0085FF" />
                &nbsp;Time allocated
              </label>
              <InputGroup className={style.inputWrapper}>
                <Form.Control
                  key={currentTest.duration}
                  type="number"
                  name="duration"
                  id="duration"
                  min={15}
                  max={engagePlan?.callLength || 15}
                  defaultValue={Math.max(currentTest.duration || 0, 15)}
                  placeholder="Incentive per session"
                  className={`${style.input} ${style.timeAllocatedInput}`}
                  required
                />
                <InputGroup.Text className={style.timeAllocated}>
                  mins
                </InputGroup.Text>
              </InputGroup>
              <Form.Text className="text-muted">
                Enter an estimated time allocated that we can tell participants.
                Actual time may vary.
              </Form.Text>
            </div>
            {/* <div className={style.inputGroup}>
              <label>
                <BiSolidPhoneCall color="#0085FF" />
                &nbsp; Set an incentive for the participants for each session
              </label>
              <div className={style.accentAndVoice}>
                <div className={style.accentGroup}>
                  <label>Accent</label>
                  <ButtonGroup>
                    <Button
                      variant="primary"
                      className={style.button}
                      active={values.accent === "American"}
                      onClick={() =>
                        setValues({ ...values, accent: "American" })
                      }
                    >
                      🇺🇸 American
                    </Button>
                    <Button
                      variant="primary"
                      className={style.button}
                      active={values.accent === "British"}
                      onClick={() =>
                        setValues({ ...values, accent: "British" })
                      }
                    >
                      🇬🇧 British
                    </Button>
                  </ButtonGroup>
                </div>
                <div className={style.voiceGroup}>
                  <label>Voice</label>
                  <ButtonGroup>
                    <Button
                      variant="primary"
                      className={style.button}
                      active={values.voice === "f"}
                      onClick={() => setValues({ ...values, voice: "f" })}
                    >
                      Female
                    </Button>
                    <Button
                      variant="primary"
                      className={style.button}
                      active={values.voice === "m"}
                      onClick={() => setValues({ ...values, voice: "m" })}
                    >
                      Male
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div> */}
            <div className={style.inputGroup}>
              <label>
                <MdEditDocument color="#0085FF" />
                &nbsp; Request a document signature
              </label>
              <Form.Check
                type="radio"
                id="notApplicable"
                name="ndaLink"
                value=""
                checked={docSign.selectedRadio === "notApplicable"}
                onClick={() => {
                  setDocSign({
                    uploadDoc: false,
                    selectedRadio: "notApplicable",
                  });
                }}
                label={<span>Not applicable</span>}
              />
              <Form.Check
                type="radio"
                id="useUENDA"
                name="ndaLink"
                value={UE_NDA}
                checked={docSign.selectedRadio === "useUENDA"}
                onClick={() => {
                  setDocSign({
                    uploadDoc: false,
                    selectedRadio: "useUENDA",
                  });
                }}
                label={
                  <span>
                    Include <strong>User Evaluation</strong>&apos;s standard
                    NDA&nbsp;
                    <Link
                      to="/user-evaluation-std-nda.pdf"
                      target="_blank"
                      className={style.link}
                    >
                      (Preview NDA)
                    </Link>
                  </span>
                }
              />
              <Form.Check
                type="radio"
                id="custom"
                name="ndaLink"
                value={currentTest.ndaLink || ""}
                checked={docSign.selectedRadio === "custom"}
                onClick={() => {
                  setDocSign({
                    uploadDoc: true,
                    selectedRadio: "custom",
                  });
                }}
                label={<span>Upload a document for signature</span>}
              />
              {docSign.uploadDoc && (
                <div className={style.uploadBox}>
                  <input
                    className="visually-hidden"
                    type="file"
                    ref={fileInputRef}
                    onChange={(e) => {
                      const formData = new FormData();
                      formData.append("file", e.target.files[0]);
                      uploadNDA(match.params.id, formData);
                    }}
                  />
                  <Button onClick={() => fileInputRef.current.click()}>
                    Upload
                  </Button>
                  <div className={style.uploadInfo}>
                    {currentTest.ndaLink && currentTest.ndaLink !== UE_NDA ? (
                      <a
                        href={currentTest.ndaLink}
                        target="_blank"
                        rel="noreferrer"
                        className={style.link}
                      >
                        View uploaded document <GoLinkExternal />
                      </a>
                    ) : (
                      <>
                        <span className={style.uploadInfoHeader}>
                          Upload a document for signature
                        </span>
                        <span>
                          Your NDA, release form, or other document will be
                          signed by participants before they take the test.
                        </span>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className={style.inputGroup}>
              <label>
                <MdCalendarToday color="#0085FF" />
                &nbsp; Interview deadline
              </label>
              <Form.Control
                type="date"
                name="deadline"
                id="deadline"
                defaultValue={dayjs(currentTest.deadline?.split("T")[0])
                  .add(7, "day")
                  .format("YYYY-MM-DD")}
                className={style.input}
                min={dayjs(currentTest.deadline?.split("T")[0])
                  .add(7, "day")
                  .format("YYYY-MM-DD")}
                required
              />
            </div>
            <div className={style.inputGroup}>
              <label>
                <BiSolidDetail color="#0085FF" />
                &nbsp; Additional details
              </label>
              <div className={style.inputGroup}>
                <label>Task instructions</label>
                <Form.Control
                  as="textarea"
                  name="instructions"
                  id="instructions"
                  defaultValue={currentTest.instructions}
                  placeholder="These details will be shown to the participant before the call"
                  className={`${style.input} ${style.textarea}`}
                />
              </div>
              <div className={style.inputGroup}>
                <label>End of task note</label>
                <Form.Control
                  as="textarea"
                  name="endNote"
                  id="endNote"
                  defaultValue={currentTest.endNote}
                  placeholder="These details will be shown to the participant after the call"
                  className={`${style.input} ${style.textarea}`}
                />
              </div>
            </div>
          </Form>
        </div>
        <BottomBar
          currentStep={6}
          primaryButton="Review"
          testId={match.params.id}
          primaryButtonLoading={buttonLoading}
          onPrimaryButtonClick={() => formRef.current.requestSubmit()}
        />
      </div>
    </TestLayout>
  );
}

export default ACI_AdditionalDetails;
