import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { FaTag } from "react-icons/fa";
import styles from "./tagPopUpBtn.module.css";
import { TagPopUp } from "./popup-clips-tags-btn";
function TagPopUpBtn({
  isOpen,
  setIsOpen,
  assetId,
  projectId,
  data,
  isDocument,
  isCSV,
  selectedUtterance,
}) {
  const [tagPopUpOpen, setTagPopUpOpen] = useState(false);
  const wrapperRef = useRef(null);
  useEffect(() => {
    function handleClick(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setTagPopUpOpen(false);
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [setIsOpen]);

  if (!isOpen) {
    return null;
  }
  return (
    <div
      ref={wrapperRef}
      className={`${styles.container} ${isOpen ? styles.open : ""} `}
    >
      <button
        className={tagPopUpOpen ? styles.active : ""}
        onClick={() => {setTagPopUpOpen(true)
      
        }
        }
      >
        <FaTag />
        <TagPopUp
          isOpen={tagPopUpOpen}
          data={data}
          setIsOpen={setIsOpen}
          assetId={assetId}
          projectId={projectId}
          selectedUtterance={selectedUtterance?selectedUtterance:0}
          isDocument={isDocument}
          isCSV={isCSV}
        />
        Add a Tag
      </button>
    </div>
  );
}

export default TagPopUpBtn;
