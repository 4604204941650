import React from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../store/slices/modalSlice";
import CustomerRequestsModal from "../../../components/customer-requests-modal";

function Other() {
  const dispatch = useDispatch();

  const openCustomerRequestsModal = () => {
    dispatch(modalActions.showCustomerRequestsModal());
  };
  return (
    <div>
      <CustomerRequestsModal type="account_deletion" />
      <h2 className="mt-3 mb-3" style={{fontSize: 20}}>Delete account</h2>
      <p>
        You can request your account to be deleted using the button below.
        Please note that once we delete your account,{" "}
        <strong>all your data will be lost</strong>. It takes a few days for
        your account to be fully deleted from our systems.
      </p>
      <button
        className="btn btn-danger mt-3"
        onClick={openCustomerRequestsModal}
      >
        Delete my account
      </button>
    </div>
  );
}

export default Other;
