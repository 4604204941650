import { Button, Col, Form, Row } from "react-bootstrap";
import React from "react";
import { toastError, toastSuccess } from "../../../utils/toast";
import { useState, useEffect } from "react";
import {
  getOrganizationById,
  updateOrganizationName,
  uploadOrganisationLogo,
} from "../../../store/actions/organisationActions";
import { useDispatch, useSelector } from "react-redux";
import styles from "./organization.module.css";
import { AiFillFileImage } from "react-icons/ai";
import { Spinner } from "react-bootstrap";
import { modalActions } from "../../../store/slices/modalSlice";
function Organization({ account }) {
  const [name, setName] = useState("");
  const { organization } = useSelector((state) => state.organization);
  const [logo, setLogo] = useState(null);
  const isPlus =
    account.plan.type === "Plus Monthly" || account.plan.type === "Plus Yearly";
  const dispatch = useDispatch();
  useEffect(() => {
    getOrganizationById(account.organization);
  }, [account.organization]);
  useEffect(() => {
    organization.length > 0 &&
      organization[0].name &&
      setName(organization[0].name);
  }, [organization]);

  const handleUpdateOrganization = async (e) => {
    if (!isPlus) {
      dispatch(modalActions.showPayWallModal());
    }

    e.preventDefault();
    if (name !== organization[0].name) {
      const { error } = await updateOrganizationName(
        account.organization,
        name
      );
      if (error) {
        toastError(
          "Something went wrong and we could not update organization name. Please contact support if the problem persists."
        );
      } else {
        toastSuccess("Organization name has been updated");
      }
    }
    if (logo !== organization[0].logo && logo) {
      const fd = new FormData();
      fd.append("logo", logo, logo.name);
      uploadOrganisationLogo({
        organizationId: account.organization,
        logo: fd,
      });

      toastSuccess("Your organization logo has been updated");
    }
    if (logo === "") {
      uploadOrganisationLogo({
        organizationId: account.organization,
        logo: "",
      });
      toastSuccess("Your organization logo has been removed");
    }
  };
  return (
    <>
      {organization?.length ? (
        <Form>
          <Form.Group controlId="formLastName" className="mt-sm-4 mt-2">
            <div className={styles.logoSection}>
              <div>
                <Form.Label>Organization Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Your organization name "
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className={styles.imageContainer}>
                <Form.Label>
                  Organization Logo (image file, max size 5 MB)
                </Form.Label>
                <div>
                  <div className={styles.imagePlaceholder}>
                    {(logo && URL.createObjectURL(logo)) ||
                    (logo !== "" && organization[0].logo) ? (
                      <img
                        src={`${
                          (logo && URL.createObjectURL(logo)) ||
                          organization[0].logo
                        }`}
                        alt="logo"
                        className={styles.images}
                      />
                    ) : (
                      <AiFillFileImage className={styles.imageIcon} />
                    )}
                  </div>
                  <Button
                    variant="outline-primary"
                    className="mt-2 mx-1 pointer"
                  >
                    <label htmlFor={"image"} className="pointer">
                      Browse
                      <input
                        type="file"
                        id="image"
                        accept="image/png,image/jpeg,image/jpg"
                        className={styles.hidden}
                        onChange={(e) => {
                          if (e.target.files && e.target.files[0]) {
                            const maxAllowedSize = 5 * 1024 * 1024;
                            if (e.target.files[0].size > maxAllowedSize) {
                              e.target.value = "";
                              toastError(
                                "Your file is too large. Please select an image less than 5 MB."
                              );
                              return;
                            }
                          }
                          setLogo(e.target.files[0]);
                        }}
                      />
                    </label>
                  </Button>
                  <Button
                    variant="outline-danger"
                    className=" mt-2 mx-1 pointer "
                    onClick={() => setLogo("")}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            </div>
          </Form.Group>
          <Row className="mt-4">
            <Col>
              <Form.Group controlId="formButton">
                <Button variant="primary" onClick={handleUpdateOrganization}>
                  Save Changes
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      ) : (
        <div className="d-flex justify-content-center align-items-center ">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </>
  );
}

export default Organization;
