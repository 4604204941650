/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { PageLayout } from "../../components/page-layout";
import UserAvatar from "../../components/userAvatar";
import { Container, FormControl, Button, Table, Form } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useHistory } from "react-router-dom";
import { FiPlus, FiSearch } from "react-icons/fi";
import { TextInput, Button as MantineButton } from "@mantine/core";
import { FaAngleDown } from "react-icons/fa6";
import style from "./index.module.css";
import {
  getPastParticipants,
  removeParticipantFromOrganization,
} from "../../store/actions/testActions";
import dayjs from "dayjs";

const pageSizeOptions = [10, 25, 50, 100];

export const Participants = () => {
  const { account } = useSelector((state) => state.auth) || { picture: null };
  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);

  const { pastParticipants = [] } = useSelector((state) => state.test);

  React.useEffect(() => {
    getPastParticipants();
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredParticipants = pastParticipants.filter((participant) =>
    Object.values(participant)
      .join(" ")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentParticipants = filteredParticipants.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  return (
    <PageLayout>
      <Container className={style.container}>
        <div className={style.parentWrapper}>
          <div className={style.participantsHeader}>
            <div style={{ minWidth: 300, width: "33%", fontWeight: "600" }}>
              Participants
            </div>

            <TextInput
              placeholder="Search participants..."
              className={style.searchInput}
              value={searchTerm}
              onChange={handleSearchChange}
              rightSection={<FiSearch />}
              styles={{ input: { paddingRight: "2rem" } }}
            />

            <div className={style.participantButtonContainer}>
              <MantineButton
                leftIcon={<FiPlus style={{ marginRight: "0.625rem" }} />}
                onClick={() => history.push({ pathname: "/interviews/new" })}
                variant="filled"
                className={style.newParticipantButton}
              >
                New Participant
              </MantineButton>
            </div>
          </div>

          <div className={style.table}>
            <Table hover>
              <thead className={style.tableHeader}>
                <tr className={style.row}>
                  <th></th>
                  <th>Name</th>
                  <th>Job Title</th>
                  <th>Email</th>
                  <th>Date Added</th>
                  <th>Location</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {currentParticipants.map(
                  ({ participant, dateAdded }, index) => (
                    <tr key={index} className={style.row}>
                      <td className={style.profilePicCell}>
                        <div className={style.profilePic}>
                          {participant?.picture ? (
                            <img
                              src={participant.picture}
                              alt="Profile"
                              className={style.picture}
                            />
                          ) : (
                            <UserAvatar
                              user={{ name: participant.name }}
                              style={{
                                height: "40px",
                                width: "40px",
                                margin: "auto",
                              }}
                            />
                          )}
                        </div>
                      </td>
                      <td>{participant.name || "-"}</td>
                      <td>{participant.occupation || "-"}</td>
                      <td>{participant.email || "-"}</td>
                      <td>
                        {dateAdded
                          ? dayjs(dateAdded).format("DD/MM/YYYY")
                          : "-"}
                      </td>
                      <td>
                        {`${participant.city || ""} ${
                          participant.state || ""
                        } ${participant.country || ""}`.trim() || "-"}
                      </td>
                      <td>
                        <DropdownButton
                          className={style.dropdownButton}
                          variant="link"
                          drop="down"
                          title={
                            <span>
                              Options&nbsp;
                              <FaAngleDown />
                            </span>
                          }
                        >
                          <Dropdown.Item as="button">
                            Invite to a Test
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            className="text-danger"
                            onClick={() => {
                              removeParticipantFromOrganization(
                                participant._id
                              );
                            }}
                          >
                            Remove
                          </Dropdown.Item>
                        </DropdownButton>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>

          <div className={style.bottomBar}>
            <Pagination className={style.pagination}>
              <Pagination.First onClick={() => handlePageChange(1)} />
              <Pagination.Prev
                onClick={() =>
                  handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
                }
              />
              {Array.from({
                length: Math.ceil(pastParticipants.length / pageSize),
              }).map((item, index) => (
                <Pagination.Item
                  key={index}
                  active={index + 1 === currentPage}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() =>
                  handlePageChange(
                    currentPage < Math.ceil(pastParticipants.length / pageSize)
                      ? currentPage + 1
                      : currentPage
                  )
                }
              />
              <Pagination.Last
                onClick={() =>
                  handlePageChange(
                    Math.ceil(pastParticipants.length / pageSize)
                  )
                }
              />
            </Pagination>
            <div className={style.bottomBarRight}>
              <div>{`Showing ${indexOfFirstItem + 1}-${Math.min(
                indexOfLastItem,
                pastParticipants.length
              )} of ${pastParticipants.length}`}</div>
              <Form.Select
                className={style.formSelect + " dropup"}
                onChange={handlePageSizeChange}
                value={pageSize}
              >
                {pageSizeOptions.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
        </div>
      </Container>
    </PageLayout>
  );
};
