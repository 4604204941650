import { Modal } from "@mantine/core";
import { Button } from "react-bootstrap";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../../store/slices/modalSlice";
import { toast } from "react-toastify";
import styles from "./index.module.css";
import { createCustomerRequest } from "../../store/actions/customerRequestsActions";

const requestTypes = {
  manual_phone_verification: {
    title: "Manual Phone Verification",
    label:
      "Please tell us why you’d like to opt for manual verification. Note that it might take 24-48 hours to verify your account.",
    buttonText: "Submit",
  },
  account_deletion: {
    title: "Delete Account",
    label: "Please tell us why you’d like to delete this account.",
    buttonText: "Delete Account",
  },
};

function CustomerRequestsModal({ type }) {
  const { customerRequests: show } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const [request, setRequest] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (request === "") {
      toast.error("Please fill in the input field");
      return;
    }
    const requestData = {
      type,
      request,
    };
    dispatch(createCustomerRequest(requestData));
    setRequest("");
    dispatch(modalActions.hideCustomerRequestsModal());
  };

  return (
    <Modal
      size="lg"
      opened={show}
      onClose={(e) => {
        dispatch(modalActions.hideCustomerRequestsModal());
      }}
      title={requestTypes[type].title}
    >
      <div className={styles.notetaker_inputs}>
        <div className={styles.notetaker_input}>
          <label htmlFor="request">{requestTypes[type].label}</label>
          <textarea
            name="request"
            id="request"
            cols="30"
            rows="5"
            value={request}
            placeholder="Type your response here"
            onChange={(e) => setRequest(e.target.value)}
          ></textarea>
        </div>
        <div className={styles.notetaker_action__btns}>
          <Button
            className={styles.cancelBtn}
            variant="light"
            onClick={() => dispatch(modalActions.hideCustomerRequestsModal())}
          >
            Cancel
          </Button>
          <Button
            variant={type === "account_deletion" ? "danger" : "primary"}
            onClick={handleSubmit}
          >
            {requestTypes[type].buttonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default CustomerRequestsModal;
