const truncateString = (string, limit) => {
  if (!string) {
    return '';
  }

  if (string.length > limit) {
    return string.substring(0, limit) + '...';
  } else {
    return string;
  }
}

export default truncateString;