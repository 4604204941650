import React from "react";
import styles from "../index.module.css";
import PropTypes from "prop-types";
function Switch({ leftlabel, rightlabel, isRight, handleRight, isSentimentAvailable }) {
  return (
    <div
      className={`${styles.toolbar__section} ${
        isSentimentAvailable ? styles.disabled : ""
      } flex-grow-0`}
    >
      <>
        <span style={{ color: isRight ? "" : "var(--grey10)" }}>
          {leftlabel}{" "}
        </span>
        <input
          disabled={isSentimentAvailable}
          type="checkbox"
          id="switch"
          className={styles.checkbox}
          checked={isRight}
          onChange={handleRight}
        />
        <label htmlFor="switch" className={styles.toggle} />
        <span style={{ color: isRight ? "var(--grey10)" : "" }}>
          {rightlabel}{" "}
        </span>
      </>
    </div>
  );
}

Switch.propTypes = {
  type: PropTypes.oneOf([
    "Video/Audio",
    "Text/CSV/Chat",
    "Insights",
    "Collection",
    "Clips",
    "report",
    "Tags",
    "Presentation",
  ]).isRequired,
  leftlabel: PropTypes.string,
  rightlabel: PropTypes.string,
  isRight: PropTypes.bool.isRequired,
};

export default Switch;
