import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "react-bootstrap";
import { Modal } from "@mantine/core";
import csvDownload from "json-to-csv-export";
import { modalActions } from "../../../../store/slices/modalSlice";
import { useGetCollectionCsvData } from "../../utils/usegetcollectioncsvdata";
function ExportCollectionModal({ isTag }) {
  const { boardExportPopup, tagBoardPopup } = useSelector(
    (state) => state.modal
  );

  const dispatch = useDispatch();
  const [csvExportSettings, setCsvExportSettings] = useState({
    includeSource: true, // isTag then include text tagId.text
    includeNotes: true, // isTag do not include it (wont be used)
    includeTags: true, // isTag then include asset name as tagId.asset.name
  });
  const { project: projectData } = useSelector((state) => state.currentProject);
  const csvData = useGetCollectionCsvData(csvExportSettings, isTag);

  return (
    <Modal
      size="xl"
      opened={isTag ? tagBoardPopup : boardExportPopup}
      onClose={() =>
        isTag
          ? dispatch(modalActions.hideTagBoardModal())
          : dispatch(modalActions.hideExportBoardModal())
      }
      title={isTag ? "Export Tags" : "Export Collection"}
    >
      <p>
        Export this {isTag ? "tag " : "collection "} as a CSV file and import it
        to your favorite tools like Miro, Mural, and more.
      </p>
      {/* checkbox to change csv export settings arrage vertically */}
      <div className="d-flex flex-column mb-3">
        <Form.Check
          type="checkbox"
          label={isTag ? "Include text" : "Include source"}
          checked={csvExportSettings.includeSource}
          onChange={(e) => {
            setCsvExportSettings({
              ...csvExportSettings,
              includeSource: e.target.checked,
            });
          }}
        />
        {!isTag && (
          <Form.Check
            type="checkbox"
            label="Include notes"
            checked={csvExportSettings.includeNotes}
            onChange={(e) => {
              setCsvExportSettings({
                ...csvExportSettings,
                includeNotes: e.target.checked,
              });
            }}
          />
        )}

        <Form.Check
          type="checkbox"
          label={isTag ? "Include asset name" : "Include tags"}
          checked={csvExportSettings.includeTags}
          onChange={(e) => {
            setCsvExportSettings({
              ...csvExportSettings,
              includeTags: e.target.checked,
            });
          }}
        />
      </div>

      <div className="d-flex mt-4">
        <Button
          variant="primary"
          className="me-2"
          style={{ minWidth: 150 }}
          onClick={() => {
            const dataToConvert = {
              data: csvData,
              filename: projectData.name + ".csv",
              delimiter: ",",
            };

            csvDownload(dataToConvert);
          }}
        >
          Export CSV
        </Button>
        <Button
          variant="outline-primary"
          style={{ minWidth: 80 }}
          onClick={() => {
            window.open(
              "https://docs.userevaluation.com/docs/guides/export-collection-to-miro-or-mural/",
              "_blank"
            );
          }}
        >
          Learn how to import to Miro or Mural
        </Button>
      </div>
    </Modal>
  );
}

export default ExportCollectionModal;
