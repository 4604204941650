import React from "react";
import styles from "./index.module.css";
import { useSelector } from "react-redux";
import UserAvatar from "../../../../components/userAvatar";
function Profile() {
  const { account } = useSelector((state) => state.auth);

  return (
    <div className={styles.profileContainer}>
      <div className={styles.profile}>
        <div>
          <p style={{ marginLeft: "1rem" }}>{account?.name}</p>
          <p>admin</p>
        </div>
        <div>
          {account?.picture ? (
            <img src={account.picture} alt="Profile" />
          ) : (
            <UserAvatar user={account} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Profile;
