import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FiX } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { deleteInsight } from "../../../../../store/actions/projectActions";

function DeleteBtn({ singleInsight, videoId ,insightType}) {
  const [showReminder, setShowReminder] = useState(false);
  const handleDelete = async () => {
    deleteInsight(
      singleInsight?._id,
      videoId,
      insightType
    );
    
  };
  return (
    <>
      {showReminder ? (
        <Button
          size="sm"
          style={{
            backgroundColor: "var(--ue-sidebar-project-initials-bg-new)",
            border: "none",
          }}
        >
          <FiX
            className="project-insight-icons-red"
            onClick={(e) => {
              e.stopPropagation();
              setShowReminder(false);
            }}
          />
          <span
            style={{
              color: " var(--pink)",
              fontWeight: "500",
            }}
            onClick={()=>handleDelete()}
          >
            Sure ?
          </span>
        </Button>
      ) : (
        <Button
          variant="transparent"
          size="sm"
          onClick={() => {
            setShowReminder(true);
          }}
        >
          <RiDeleteBinLine className="project-insight-icons-red" />
          Delete
        </Button>
      )}
    </>
  );
}

export default DeleteBtn;
