import React, { useRef, useState, useEffect } from "react";
import { FiPause, FiPlay } from "react-icons/fi";
import styles from "./mini-player.module.css";
import formatTime from "../utils/formatTime";
import { VscDebugRestart } from "react-icons/vsc";
import { AiFillAudio } from "react-icons/ai";
function MiniPlayer({ file, startTime, endTime }) {
  const videoRef = useRef(null);
  const [play, setPlay] = useState(false);
  const [progress, setProgress] = useState(0);
  const duration = endTime / 1000 - startTime / 1000;
  const [restart, setRestart] = useState(false);
  const [currentTime, setCurrentTime] = useState(startTime);
  const typeOfFile = file.assetType;

  function refreshProgress() {
    setProgress(0);
    videoRef.current.currentTime = startTime / 1000;
    videoRef.current.play();
    setCurrentTime(startTime);
  }

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.currentTime = startTime / 1000;
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (play) {
      videoElement.play();
    } else {
      videoElement.pause();
    }
  }, [play]);

  const handlePlayControlClick = () => {
    if (restart) {
      setPlay(true);
      setRestart(false);
      refreshProgress();
    } else if (play) {
      setPlay(false);
    } else {
      setPlay(true);
    }
  };

  return (
    <div
      className={styles.miniPlayerContainer}
      style={{ position: "relative", overflow: "hidden" }}
    >
      {typeOfFile === "AUDIO" ? (
        <div className={styles.audioOverlay}>
          <AiFillAudio />
        </div>
      ) : null}
      <video
        crossOrigin="anonymous"
        ref={videoRef}
        onTimeUpdate={() => {
          setCurrentTime(videoRef.current?.currentTime * 1000);
          if (progress >= 1) {
            setPlay(false);
            videoRef.current.pause();
            setRestart(true);
          } else {
            setProgress(
              (videoRef.current?.currentTime - startTime / 1000) / duration
            );
          }
        }}
      >
        <source src={file.link} type="video/mp4" />
      </video>

      <div
        className={styles.playControl}
        id="restart"
        onClick={handlePlayControlClick}
      >
        {restart ? <VscDebugRestart /> : play ? <FiPause /> : <FiPlay />}
      </div>

      <div className={styles.progress}>
        <div className={styles.timeStamp}>
          {formatTime(currentTime)}/{formatTime(endTime)}
        </div>
        <div className={styles.progressContainer}>
          <div style={{ width: `${progress * 100}%` }}></div>
        </div>
      </div>
    </div>
  );
}

export default MiniPlayer;
