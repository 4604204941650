import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { getProject } from "../../store/actions/projectActions";
import { PageLoader } from "../../components/page-loader";
import { Route, Switch } from "react-router-dom";
import TabPage from "./pages/Tabs";
import { ErrorPage } from "./components/errorPage";
import Upload from "./components/upload";
import {
  Redirect,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
const runGetProjectData = async (
  id,
  isMounted,
  setErrorPage,
  history,
  setLoading
) => {
  let projectReady = false;

  while (!projectReady && id) {
    let { data, error } = await getProject({ id });
    setLoading(false);
    if (!isMounted) {
      return;
    }
    if ((data && data?.status === "Ready") || data?.status === "Empty") {
      projectReady = true;
      if (data?.status === "Empty" || data?.videos?.length === 0) {
        history.push(`/project/${id}/upload`);
      }
    } else if (error) {
      setErrorPage(true);
      projectReady = true;
    } else {
      await new Promise((resolve) => setTimeout(resolve, 10000));
    }
  }
};
function ProPage({ match }) {
  const [loading, setLoading] = useState(true);
  const { project } = useSelector((state) => state.currentProject);
  let projectId = match.params.projectId;
  const [errorPage, setErrorPage] = useState(false);
  const history = useHistory();
  useEffect(() => {
    let isMounted = true;

    const getProjectData = async () => {
      runGetProjectData(
        projectId,
        isMounted,
        setErrorPage,
        history,
        setLoading
      );
    };
    getProjectData();
  }, []);

  useEffect(() => {
    if (project?._id !== projectId) {
      getProject({ id: projectId });
    }

    if (
      project &&
      project?._id === projectId &&
      project?.videos?.length === 0 &&
      projectId
    ) {
      history.push(`/project/${projectId}/upload`);
    }

    // Hide LiveChat on Project Page
    let divToHide = document.querySelector("#chat-widget-container");

    if (divToHide) {
      divToHide.style.display = "none";
    }

    return () => {
      if (divToHide) {
        divToHide.style.display = "";
      }
    };
  }, [projectId, project]);

  if (errorPage) {
    return <ErrorPage />;
  }
  if (loading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <>
      <div className={styles.pageContainer}>
        <React.Fragment>
          <Switch>
            <Route path="/project/:projectId/upload" component={Upload} />
            <Route path="/project/:projectId/library" component={Upload} />
            <Route path="/project/:projectId/notetaker" component={Upload} />
            <Route
              path="/project/:projectId/:tab/:assetId"
              component={TabPage}
            />
            <Route path="/project/:projectId/:tab" component={TabPage} />
            <Route path="/project/:projectId" component={TabPage} />
            <Redirect to={`/project/${projectId}`} />
          </Switch>
        </React.Fragment>
      </div>
    </>
  );
}

export default ProPage;
