import React from "react";
import { Col, Nav } from "react-bootstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./index.module.css";
// import chatsIcon from "../../../../images/icons/transcripts.svg";
import { FiMessageCircle, FiMoreHorizontal, FiTrash } from "react-icons/fi";
import { BsStars } from "react-icons/bs";
import ProjectButton from "../buttons/secondaryButton";
import { useState } from "react";
import truncateString from "../../../../utils/truncateString";
import {
  deletePresentation,
  deleteReport,
  loadAllReports,
  loadPresentations,
  loadReportById,
} from "../../../../store/actions/currentProjectActions";
import { useEffect } from "react";
import { Menu, Modal, Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
// chat report presentation-> CRP
const CRPSidebar = ({
  className,
  onLinkClick,
  tab,
  handleOpen,
  isSmallScreen,
}) => {
  const { project } = useSelector((state) => state.currentProject);
  const { _id, threads = [] } = project || {};
  const { search } = useLocation();
  const [selected, setSelected] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const history = useHistory();
  const params = new URLSearchParams(search);
  const [opened, { open, close }] = useDisclosure(false);
  useEffect(() => {
    if (project?._id && tab === "report") {
      loadAllReports(project._id);
    }
    if (project?._id && tab === "presentation") {
      loadPresentations(project._id);
    }
  }, [project?._id, tab]);

  return (
    <Col
      className={`${styles.threadsContainer} ${className ?? ""} ${
        isSmallScreen ? styles.mobThreadsContainer : ""
      }`}
    >
      <HandleDeleteModal
        selected={selected}
        opened={opened}
        close={close}
        id={project?._id}
      />
      <Link to={`/project/${_id}/${tab}`} className={styles.newChatBtn}>
        <ProjectButton
          size={"large"}
          varient={"secondary"}
          onClick={handleOpen}
        >
          {mapTabToIcon[tab]}
          New {tab?.charAt(0).toUpperCase() + tab?.slice(1)}
        </ProjectButton>
      </Link>
      <Nav className="flex-column mt-0 mt-lg-3">
        {tab === "chat" &&
          threads.length > 0 &&
          threads
            .slice()
            .reverse()
            .map((thread, index) => (
              <Nav.Link
                style={{ fontSize: "0.85rem" }}
                as={Link}
                key={thread.id}
                active={thread.id === params.get("t")}
                className={`project-custom-link ${styles.threadLink}`}
                to={`/project/${_id}/chat?t=${thread.id}`}
                onClick={onLinkClick}
              >
                <FiMessageCircle
                  style={{
                    marginRight: "0.2rem",
                    width: "20px",
                    height: "20px",
                    flexShrink: 0,
                    flexGrow: 0,
                  }}
                />
                {thread?.title ?? `Chat ${index + 1}`}
              </Nav.Link>
            ))}
        {tab === "presentation" &&
          project?.presentations?.map((presentation, index) => (
            <Nav.Link
              style={{ fontSize: "0.85rem" }}
              as={Link}
              key={index}
              active={false}
              className={`project-custom-link ${styles.threadLink} ${
                presentation?._id === params.get("t")
                  ? "active"
                  : !params.get("t") && index === 0
                  ? "active"
                  : ""
              }`}
              to={`/project/${_id}/presentation?t=${presentation._id}`}
              onClick={onLinkClick}
            >
              {truncateString(presentation?.title, 20) ??
                `Presentation ${index + 1}`}
              <div className={styles.editThread}>
                <Menu
                  className="menu-container"
                  shadow="md"
                  transitionProps={{
                    transition: "pop-top-right",
                    duration: 150,
                  }}
                  position="bottom-left"
                >
                  <Menu.Target>
                    <Button variant="white">
                      <FiMoreHorizontal />
                    </Button>
                  </Menu.Target>

                  <Menu.Dropdown className="menu-dropdown">
                    <Menu.Item
                      onClick={() => {
                        open();
                        setSelected({ ...presentation, name: "presentation" });
                      }}
                      className={styles.menuItem}
                      style={{ color: "var(--bs-danger)" }}
                    >
                      <FiTrash /> Delete Presentation
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </div>
            </Nav.Link>
          ))}
        {tab === "report" &&
          project?.reports &&
          project?.reports.length > 0 &&
          project?.reports?.map((report, index) => (
            <button
              style={{ fontSize: "0.85rem" }}
              key={index}
              className={`project-custom-link ${styles.threadLink} ${
                index === activeIndex
                  ? "active"
                  : !activeIndex && index === 0
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                history.push(`/project/${_id}/report?t=${report._id}`);
                setActiveIndex(index);
                handleGetReport(report._id);
              }}
            >
              {truncateString(report?.prompt, 20) ?? `Report ${index + 1}`}
              <div className={styles.editThread}>
                <Menu
                  className="menu-container"
                  shadow="md"
                  transitionProps={{
                    transition: "pop-top-right",
                    duration: 150,
                  }}
                  position="bottom-left"
                >
                  <Menu.Target>
                    <Button variant="white">
                      <FiMoreHorizontal />
                    </Button>
                  </Menu.Target>

                  <Menu.Dropdown className="menu-dropdown">
                    <Menu.Item
                      onClick={() => {
                        open();
                        setSelected({
                          ...report,
                          name: "report",
                          title: report?.prompt,
                        });
                      }}
                      className={styles.menuItem}
                      style={{ color: "var(--bs-danger)" }}
                    >
                      <FiTrash /> Delete Report
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </div>
            </button>
          ))}
      </Nav>
    </Col>
  );
};
const HandleDeleteModal = ({ selected, opened, close, id }) => {
  if (!selected) {
    return null;
  }
  return (
    <Modal opened={opened} onClose={close} title={`Delete ${selected?.name}?`}>
      This will delete{" "}
      {truncateString(selected?.title, 20) ?? `Untitled`}{" "}
      {selected.name}. Are you sure you would like to delete it?
      <div className="d-flex justify-content-end mt-3">
        <Button
          onClick={close}
          variant="subtle"
          style={{ marginRight: "1rem" }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (selected.name === "report") {
              deleteReport(selected._id, id);
            } else {
              deletePresentation(selected._id, id);
            }

            close();
          }}
          variant="filled"
          color="red"
          style={{ color: "white" }}
        >
          Delete
        </Button>
      </div>
    </Modal>
  );
};
const handleGetReport = async (reportId) => {
  await loadReportById(reportId);
};
const mapTabToIcon = {
  Chat: (
    <FiMessageCircle style={{ marginRight: "0.2rem", fontSize: "1.25rem" }} />
  ),
  Report: <BsStars style={{ marginRight: "0.2rem", fontSize: "1.25rem" }} />,
  Presentation: (
    <BsStars style={{ marginRight: "0.2rem", fontSize: "1.25rem" }} />
  ),
};
export default CRPSidebar;
