import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function ChatbotComponent() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const pageParam = searchParams.get('page');
    useEffect(() => {
        // Hide the chatbot when component mounts (on home page)
        if (location.pathname === "/chat" || pageParam === "chat") {
            window.$crisp.push(['do', 'chat:hide']);
            return;

        }
        // Show the chatbot when component mounts (on other pages)
        window.$crisp.push(['do', 'chat:show']);
    }, [location.pathname]);

    return <></>; // Placeholder component, doesn't render anything
}

export default ChatbotComponent;

