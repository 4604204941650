import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { modalActions } from "../../../store/slices/modalSlice";
import { useDispatch } from "react-redux";
import styles from "./create-insight-modal.module.css";
import { addInsight } from "../../../store/actions/projectActions";
function CreateInsightsModal({ data, assetId ,collectInsight}) {
  const { insights: show } = useSelector((state) => state.modal);
  const { project } = useSelector((state) => state.currentProject);

  const asset = project?.videos?.find((asset) => asset._id === assetId);
  const dispatch = useDispatch();
  const [insightType, setInsightType] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [insight, setInsight] = useState({
    insightText: "",
    source: "",
  });

  useEffect(() => {
    setInsightType(asset?.insights[0]?.insightType || "");
  }, [asset?.insights]);

  useEffect(() => {
    setInsight((insight) => ({
      ...insight,
      source: data[0]?.words.map((word) => word.text).join(" "),
    }));
  }, [data]);

  let source = data[0]?.words.map((word) => word.text).join(" ");

  const handleInsightTypeChange = (e) => {
    setInsightType(e.target.value);
  };

  const handleInsightChange = (e) => {
    // set insight based name of input
    setInsight({ ...insight, [e.target.name]: e.target.value });
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        dispatch(modalActions.hideInsightsModal());
      }}
      centered
      backdrop="static"
      size="lg"
      className="pay-modal-container"
    >
      <Modal.Header
        closeButton
        style={{ paddingBottom: "0px", border: "none" }}
      >
        <h6>Add an Insight</h6>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.source}>
          <p>Source</p>
          <div className="bd-callout bd-callout-secondary">{source}</div>
        </div>
        <div className={styles.insight}>
          <p>Insight</p>
          <textarea
            name="insightText"
            onChange={(e) => handleInsightChange(e)}
            className="form-control"
            rows="3"
            placeholder="Add an insight related to the selected source text"
            style={{ resize: "none" }}
          ></textarea>
        </div>
        <div className={styles.insight__type}>
          <Form.Group
            controlId="inlineFormCustomSelectPref"
            className="my-1 mr-sm-2 w-50"
          >
            <Form.Label>Insight Type</Form.Label>
            <Form.Control
              as="select"
              name="insightType"
              onChange={handleInsightTypeChange}
            >
              {asset?.insights?.map((insight) => {
                return (
                  <option value={insight.insightType} key={insight?._id}>
                    {insight.insightType}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </div>
        <div className={styles.checkbox}>
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheckbox"
            checked={isChecked} // Use the state to determine whether the checkbox should be checked
            onChange={() => {
              setIsChecked(!isChecked);
            }} // Attach the onChange event handler to update the state
          />
          <label className="form-check-label" htmlFor="exampleCheckbox">
            Add to collection
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ borderTop: "none" }}>
        <button
          className="btn btn-primary"
          onClick={() => {
            if(isChecked){
              collectInsight({insightType}, insight, assetId);
            }
            addInsight(insightType, insight, assetId);
            dispatch(modalActions.hideInsightsModal());
          }}
          disabled={!insightType || !insight.insightText}
        >
          Add Insight
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateInsightsModal;
