import { createSlice } from "@reduxjs/toolkit";

const publicLinkSlice = createSlice({
  name: "publicLink",
  initialState: {
    link: null,
    loading: true,
    error: null
  },
  reducers: {
    requestStart(state, action) {
      state.loading = true;
      state.error = null;
    },
    getLink(state, action) {
      state.loading = false;
      state.link = action.payload.link;
      state.error = null;
    },
    deleteLink(state, action) {
      state.link = null;
      state.loading = false;
      state.error = null;
    },
    createLink(state, action) {
      state.link = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateLink(state, action) {
      state.link = action.payload;
      state.loading = false;
      state.error = null;
    },
    requestFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export default publicLinkSlice;
export const publicLinkActions = publicLinkSlice.actions;
