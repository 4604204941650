import store from "..";
import { publicLinkActions } from "../slices/publicLinkSlice";
import { callExternalApi,callExternalApiWithoutToken } from "../../services/external-api.service";
import { toastError, toastSuccess } from "../../utils/toast";

const { dispatch } = store;

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

// load public link by id
export const getLinkByIdAction = async (id) => {
  dispatch(publicLinkActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/publiclink/${id}`,
    method: "GET",
  };
  const { data, error } = await callExternalApiWithoutToken({ config });
  return {
    data: data || null,
    error
  };
  
};

// load public link by project id
export const getLinkByResourceIdAction = async (id, resource) => {
    dispatch(publicLinkActions.requestStart());

    const config = {
        url: `${apiServerUrl}/api/publiclink/resource/link`,
        method: "POST",
        data: { resource, id }
    };
    const { data, error } = await callExternalApi({ config });

    if (error) dispatch(publicLinkActions.requestFail(error.message));
    else dispatch(publicLinkActions.getLink({ link: data[0] }));
};

// create public link
export const createLinkAction = async (payload) => {

  dispatch(publicLinkActions.requestStart());
  const { resource, createdBy, resourceId } = payload;

  const config = {
    url: `${apiServerUrl}/api/publiclink/create`,
    method: "POST",
    data: { resource, createdBy, resourceId }
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    dispatch(publicLinkActions.requestFail(error.message));
    toastError(error.message);
  } else {
    dispatch(publicLinkActions.createLink(data));
    toastSuccess("Public link created successfully!");
  }
};

// update public link
export const updateLinkAction = async (id, dataToUpdate) => {
  dispatch(publicLinkActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/publiclink/${id}`,
    method: "PUT",
    data: dataToUpdate
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    dispatch(publicLinkActions.requestFail(error.message));
    toastError(error.message);
  } else {
    dispatch(publicLinkActions.updateLink(data));
    toastSuccess("Public link updated successfully!");
  }
};

// delete public link
export const deleteLinkAction = async (id) => {
  dispatch(publicLinkActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/publiclink/${id}`,
    method: "DELETE",
  };
  const { error } = await callExternalApi({ config });

  if (error) {
    dispatch(publicLinkActions.requestFail(error.message));
    toastError(error.message);
  } else {
    dispatch(publicLinkActions.deleteLink(id));
    toastSuccess("Public link deleted");
  }
};
