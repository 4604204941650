import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { PageLayoutWaitlist } from "../../../components/page-layout-waitlist";
import { livechatAuth } from "../../../store/actions/authActions";
import LiveChatLogo from "../../../images/livechat-logo.png";

export const AppLiveChatCallback = () => {
  const history = useHistory();
  const [isRetryDisabled, setIsRetryDisabled] = useState(true);

  // capture code from query string
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");

  // send code to backend ONCE
  useEffect(() => {
    if (code) {
      livechatAuth(code).then(({ data, error }) => {
        if (error) {
          console.error("error", error);
        } else {
          history.push("/apps/livechat/import");
        }
      });
    }
  }, [code]);

  useEffect(() => {
    // disable this button in 4 seconds timeout
    setTimeout(() => {
      setIsRetryDisabled(false);
    }, 4000);
  }, []);

  return (
    <PageLayoutWaitlist>
      <Container>
        <Row className="justify-content-center align-items-center mt-5">
          <Col xs={12} sm={6} md={6}>
            <Card className="text-center" style={{ zIndex: 9999, padding: 20 }}>
              <Card.Body>
                <img
                  src={LiveChatLogo}
                  alt="LiveChat Logo"
                  style={{
                    width: "100px",
                    height: "100px",
                    border: "2px solid #fff",
                    borderRadius: "8px",
                    boxShadow: "0 0 10px #ccc",
                    marginBottom: "40px",
                  }}
                />
                <Card.Title>Logging you in</Card.Title>
                <Card.Text>
                  We&apos;re logging you in to User Evaluation. If you&apos;re
                  not redirected in a few seconds, click the button below to
                  authenticate with LiveChat again.
                </Card.Text>
                {/* enable this button in 4 seconds timeout */}
                <Button
                  variant="primary"
                  href="/apps/livechat/authenticate"
                  id="livechat-auth-button"
                  disabled={isRetryDisabled}
                >
                  {isRetryDisabled ? <><Spinner animation="border" size="sm" /> Authenticating...</> :
                  `Retry Authentication` }
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </PageLayoutWaitlist>
  );
};
