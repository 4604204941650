import React from "react";
import styles from "./index.module.css";
import Player from "../../../../components/player";
import AudioPlayer from "../../../../components/audio-player";
function AssetPlayer({
  isVideo,
  selectedVideo,
  videoRef,
  currentPlayerTime,
  setCurrentPlayerTime,
}) {
  return (
    <div className={styles.assetPlayerContainer} style={{ width: "100%" }}>
      {isVideo ? (
        <Player
          file={selectedVideo}
          videoRef={videoRef}
          currentPlayerTime={currentPlayerTime}
          setCurrentPlayerTime={setCurrentPlayerTime}
          onTimeUpdate={(e) => {
            setCurrentPlayerTime(e.target?.currentTime);
          }}
          isVideo={isVideo}
          className={styles.video}
          containerClassName={styles.videoContainer}
        />
      ) : (
        <div className={styles.audioContainer}>
          <AudioPlayer
            url={selectedVideo.link}
            videoRef={videoRef}
            setCurrentPlayerTime={setCurrentPlayerTime}
          />
        </div>
      )}
    </div>
  );
}

export default AssetPlayer;
