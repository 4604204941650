import React from "react";
import { Link } from "react-router-dom";
import backIconWhite from "../../../../images/icons/arrow-left-white.svg";
import styles from "../../index.module.css";
import TopNav from "../topNav";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { TfiShare } from "react-icons/tfi";
import ProjectButton from "../buttons/secondaryButton";
import EditableTitle from "./components/EditableTitle";
import { supportedLanguages } from "./languages";
import { useDisclosure } from "@mantine/hooks";
import { Burger } from "@mantine/core";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../../../../store/slices/modalSlice";
import ShareProjectModal from "./components/shareProjectModal";
const tabs = [
  { name: "Data", value: "data" },
  { name: "Insights", value: "insights" },
  { name: "Chat", value: "chat" },
  { name: "Tags", value: "tags" },
  { name: "Collection", value: "collection" },
  { name: "Report", value: "report" },
  { name: "Presentation", value: "presentation" },
  { name: "Clips", value: "clips" },
];

function TopBar({
  name,
  language,
  activeTab,
  project,
  isUpload,
  id,
  setActiveTab,
}) {
  const [opened, { toggle }] = useDisclosure();
  const loading = project?.status === "Preparing";
  const isPublic = id ? true : false;
  const { account } = useSelector((state) => state.auth);
  const isFree = account?.plan?.type === "Free";
  const dispatch = useDispatch();
  return (
    <div className={styles.topBarContainer}>
      <ShareProjectModal />
      <div className={styles.topBarContainer__title}>
        {!id && (
          <Link to="/projects" className={styles.goBackBtn}>
            <img src={backIconWhite} alt="back" className="back-icon" />
          </Link>
        )}
        <EditableTitle initialName={name} isPublic={isPublic} />

        <div className={styles.public__topbar__language}>
          {language?.toLocaleLowerCase() === "detect" ? (
            `🌐`
          ) : (
            <span
              className={`fi fi-${
                supportedLanguages
                  ?.find(
                    (lan) =>
                      lan.code.toLocaleLowerCase() ===
                      language?.toLocaleLowerCase()
                  )
                  ?.country_code?.toLowerCase() || "default"
              }`}
            ></span>
          )}
          {supportedLanguages?.find(
            (lan) =>
              lan.code.toLocaleLowerCase() === language?.toLocaleLowerCase()
          )?.language || "Detect Language"}
        </div>

        <div className="processingSpinner">
          {loading && (
            <Spinner
              style={{ width: "20px", height: "20px" }}
              animation="border"
              variant="primary"
            />
          )}
        </div>
        <Burger
          className={styles.hiddenOnDesktop}
          style={{
            position: "absolute",
            right: "10px",
            top: "10px",
            color: "var(--grey10)",
          }}
          opened={opened}
          onClick={toggle}
          aria-label="Toggle navigation"
        />
      </div>

      {!isUpload && (
        <div className={`${styles.navContainer} ${opened ? "" : styles.hide}`}>
          <TopNav
            activeTab={activeTab}
            tabs={tabs}
            projectId={project?._id}
            toggle={toggle}
            id={id}
            setActiveTab={setActiveTab}
          />
          <div className={styles.topBarContainer__buttons}>
            <ProjectButton
              color={"var(--grey1)"}
              backgroundColor={"var(--pink)"}
              size={"small"}
              varient={"primary"}
              onClick={() => {
                if (isFree) {
                  dispatch(modalActions.showPayWallModal());
                } else {
                  dispatch(modalActions.showShareProjectModal());
                }
              }}
            >
              <TfiShare />
              Share
            </ProjectButton>
          </div>
        </div>
      )}
    </div>
  );
}

export default TopBar;
