import React from "react";
import Player from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import ParentRefContext from "../pages/project-vnext/parentRefContext";
import { FiXCircle } from "react-icons/fi";
import css from "./audio-player.module.css";

export default function AudioPlayer({ url, setCurrentPlayerTime, videoRef }) {
  const ref = React.useRef(null);
  const [hover, setHover] = React.useState(0);
  const parentRef = React.useContext(ParentRefContext);
  const closeRef = React.useRef({
    closed: false,
  });

  React.useEffect(() => {
    if (window.innerWidth < 768 || !parentRef?.current) return;

    const handleParentScroll = () => {
      if (parentRef?.current.scrollTop > 250 && !closeRef.current.closed)
        setHover(true);
      else setHover(false);
    };

    const parentElement = parentRef.current;
    parentElement.addEventListener("scroll", handleParentScroll);
    return () =>
      parentElement.removeEventListener("scroll", handleParentScroll);
  }, [parentRef]);

  React.useEffect(() => {
    if (!ref.current) return;
    const player = ref.current.audio.current;
    if (!player) return;

    videoRef.current = player;

    const updateTime = () => {
      setCurrentPlayerTime(player.currentTime);
    };

    player.addEventListener("timeupdate", updateTime);

    return () => player.removeEventListener("timeupdate", updateTime);
  }, []);

  return (
    <div className={hover ? css.floating : ""}>
      <Player src={url} ref={ref} />
      <FiXCircle
        color="black"
        className={hover ? "d-block" : "d-none"}
        onClick={() => {
          setHover(false);
          closeRef.current.closed = true;
        }}
      />
    </div>
  );
}
