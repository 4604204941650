/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { MdDelete } from "react-icons/md";
import { TestLayout } from "../components/test-layout";
import style from "./index.module.css";
import ListContainer from "./components/list-container";
import BottomBar, { handleStep } from "../components/bottom-bar";
import { useSelector } from "react-redux";
import useSocketContext from "../../../../utils/SocketContext";
import { EVENT_TYPES } from "../../routes/test-route";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Spinner } from "react-bootstrap";
import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

export const QUES_TYPES = [
  "Text Input",
  "Multi-line Input",
  "Number Input",
  "Multiple Choice",
  "Checkboxes",
  // "Date",
];

export const QUES_MAP = {
  textInput: "Text Input",
  multiLineInput: "Multi-line Input",
  numberInput: "Number Input",
  checkBox: "Checkboxes",
  multipleChoice: "Multiple Choice",
  // date: "Date",
  // Date: "date",
  "Text Input": "textInput",
  "Multi-line Input": "multiLineInput",
  "Number Input": "numberInput",
  Checkboxes: "checkBox",
  "Multiple Choice": "multipleChoice",
};

function ACI_Screen({ match }) {
  const [questionType, setQuestionType] = useState(QUES_TYPES[0]);
  const [validated, setValidated] = useState(false);
  const [items, setItems] = useState([]);
  const [formData, setFormData] = useState({
    type: questionType,
    label: "",
    placeholder: "",
    selectionConditionAI: "",
    options: [],
  });
  const [options, setOptions] = useState([
    { option: "", qualifyIfChosen: false },
  ]);

  const [opened, { open, close }] = useDisclosure(false);

  const { questions, isGeneratingScreener } = useSelector(
    (state) => state.test.currentTest
  );

  const socket = useSocketContext();
  const history = useHistory();

  const handleTypeChange = (e) => {
    setQuestionType(e.target.value);
    setFormData(() => ({
      type: e.target.value,
      label: "",
      placeholder: "",
      selectionConditionAI: "",
      options: [],
    }));
    setOptions([{ option: "", qualifyIfChosen: false }]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (values) => {
    setFormData((prevData) => ({
      ...prevData,
      options: values,
    }));
  };

  const handleInsert = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);
      setItems((prevItems) => [...prevItems, formData]); // might need to remove this

      socket.testUpdates(EVENT_TYPES.CREATE_UPDATE_QUESTION, {
        testId: match.params.id,
        ...formData,
        type: QUES_MAP[formData.type],
      });

      setFormData({
        type: questionType,
        label: "",
        placeholder: "",
        selectionConditionAI: "",
        options: [],
      });
      setOptions([{ option: "", qualifyIfChosen: false }]);
    }
  };

  const handleDelete = (index) => {
    socket.testUpdates(EVENT_TYPES.DELETE_QUESTION, {
      testId: match.params.id,
      questionId: items[index]._id,
    });
  };

  const handleDuplicate = (index) => {
    setItems((prevItems) => [...prevItems, items[index]]);
  };

  const handleEdit = (index) => {
    const editedItem = items[index];
    setQuestionType(editedItem.type);
    setFormData(editedItem);
    setOptions(editedItem.options);
    handleDelete(index);
    setValidated(true);
  };

  useEffect(() => {
    const newItems = (questions || []).map((question) => ({
      ...question,
      type: QUES_MAP[question.type],
    }));

    setItems(newItems);
  }, [questions]);

  let inputDisplay = (
    <TextInput formData={formData} handleChange={handleChange} />
  );

  switch (questionType) {
    case QUES_TYPES[0]:
      inputDisplay = (
        <TextInput formData={formData} handleChange={handleChange} />
      );
      break;
    case QUES_TYPES[1]:
      inputDisplay = (
        <TextInput formData={formData} handleChange={handleChange} />
      );
      break;
    case QUES_TYPES[2]:
      inputDisplay = (
        <NumberInput formData={formData} handleChange={handleChange} />
      );
      break;
    case QUES_TYPES[3]:
      inputDisplay = (
        <CheckboxInput
          options={options}
          setOptions={setOptions}
          handleCheckboxChange={handleCheckboxChange}
        />
      );
      break;
    case QUES_TYPES[4]:
      inputDisplay = (
        <CheckboxInput
          options={options}
          setOptions={setOptions}
          handleCheckboxChange={handleCheckboxChange}
        />
      );
      break;
    case QUES_TYPES[5]:
      inputDisplay = <Date formData={formData} handleChange={handleChange} />;
      break;
    default:
      inputDisplay = (
        <TextInput formData={formData} handleChange={handleChange} />
      );
      break;
  }

  return (
    <TestLayout stepNumber={4} testId={match.params.id}>
      <div className={style.wrapper}>
        <div className={style.title}>Screener</div>
        <div className={style.subtitle}>
          Build your screener or{" "}
          <span
            className={isGeneratingScreener ? "" : style.skip}
            onClick={isGeneratingScreener ? () => {} : open}
          >
            skip it
          </span>
        </div>
        <div className={style.main}>
          {(!questions || !questions.length) && isGeneratingScreener ? (
            <p className="mt-4 d-flex gap-2 mx-auto">
              <Spinner animation="border" variant="primary" />
              <span>
                Please wait while the AI drafts screener questions for this
                interview
              </span>
            </p>
          ) : (
            <>
              <div className={style.inputSection}>
                <div className={style.sectionTitle}>Add a New Question</div>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleInsert}
                  className={style.form}
                >
                  <div className={style.inputGroup}>
                    <label htmlFor="type">Question Type</label>
                    <Form.Select
                      name="type"
                      id="type"
                      className={style.input}
                      required
                      onChange={handleTypeChange}
                      value={formData.type}
                    >
                      {QUES_TYPES.map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div className={style.inputGroup}>
                    <label htmlFor="label">Label</label>
                    <Form.Control
                      type="text"
                      name="label"
                      id="label"
                      placeholder="Label for your question"
                      className={style.input}
                      value={formData.label}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  {inputDisplay}
                  {/* <button type="submit" className={style.insert}>
                Insert
              </button> */}
                  <Button
                    type="submit"
                    variant="primary"
                    className={style.insert}
                  >
                    Insert
                  </Button>
                </Form>
              </div>
              <DndProvider backend={HTML5Backend}>
                <div className={style.sequenceSection}>
                  <div className={style.sectionTitle}>Sequence</div>
                  <ListContainer
                    items={items}
                    setItems={setItems}
                    handleEdit={handleEdit}
                    handleDuplicate={handleDuplicate}
                    handleDelete={handleDelete}
                  />
                </div>
              </DndProvider>
            </>
          )}
        </div>
        <BottomBar
          currentStep={4}
          displaySkipButton={true}
          onSkipButtonClick={open}
          disableSkipButton={isGeneratingScreener}
          testId={match.params.id}
          disablePrimaryButton={
            (!questions || !questions.length) && isGeneratingScreener
          }
          onPrimaryButtonClick={() => {
            history.push(handleStep(5, match.params.id));
          }}
        />
        <Modal opened={opened} onClose={close} title="Skip Screener" centered>
          <p>
            Are you sure you want to skip the screener? Skipping it will remove
            all questions.
          </p>
          <p className="m-0 d-flex justify-content-end gap-3">
            <Button onClick={close} variant="outline-secondary" size="sm">
              Cancel
            </Button>
            <Button
              size="sm"
              onClick={() => {
                socket.testUpdates(EVENT_TYPES.SKIP_SCREENER, {
                  testId: match.params.id,
                });
              }}
            >
              Confirm
            </Button>
          </p>
        </Modal>
      </div>
    </TestLayout>
  );
}

export function TextInput({ formData, handleChange }) {
  return (
    <>
      <div className={style.inputGroup}>
        <label htmlFor="placeholder">Placeholder</label>
        <Form.Control
          type="text"
          name="placeholder"
          id="placeholder"
          placeholder="Optionally add a placeholder"
          className={style.input}
          value={formData.placeholder}
          onChange={handleChange}
        />
      </div>
      <div className={style.inputGroup}>
        <label htmlFor="selectionConditionAI">Qualify with AI</label>
        <Form.Control
          as="textarea"
          name="selectionConditionAI"
          id="selectionConditionAI"
          cols="30"
          rows="5"
          placeholder="Simply write a condition for the answer that would qualify the participant"
          className={style.input}
          value={formData.selectionConditionAI}
          onChange={handleChange}
        />
      </div>
    </>
  );
}

export function NumberInput({ formData, handleChange }) {
  return (
    <>
      <div className={style.inputGroup}>
        <label htmlFor="placeholder">Placeholder</label>
        <Form.Control
          type="text"
          name="placeholder"
          id="placeholder"
          placeholder="Optionally add a placeholder"
          className={style.input}
          value={formData.placeholder}
          onChange={handleChange}
        />
      </div>
      <div className={style.inputGroup}>
        <label htmlFor="selectionConditionAI">Number to Qualify</label>
        <Form.Control
          type="number"
          name="selectionConditionAI"
          id="selectionConditionAI"
          placeholder="Enter a number that would qualify this participant"
          className={style.input}
          value={formData.selectionConditionAI}
          onChange={handleChange}
        />
      </div>
    </>
  );
}

export function CheckboxInput({ options, setOptions, handleCheckboxChange }) {
  const addOption = () => {
    setOptions([...options, { option: "", qualifyIfChosen: false }]);
  };

  const removeOption = (index) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  const handleContentChange = (index, value) => {
    const newOptions = structuredClone([...options]);
    newOptions[index].option = value;
    setOptions(newOptions);
    handleCheckboxChange(newOptions);
  };

  const handleQualificationChange = (index, value) => {
    const newOptions = structuredClone([...options]);
    newOptions[index].qualifyIfChosen = value;
    setOptions(newOptions);
    handleCheckboxChange(newOptions);
  };

  return (
    <>
      <div className={style.inputGroup}>
        <label htmlFor="options">Options</label>

        {options.map((option, index) => (
          <div key={index} className={style.inputGroup}>
            <div className={style.option + " checkbox-input"}>
              <span className={style.optionNumber}>{index + 1}</span>
              <Form.Control
                type="text"
                placeholder="Add option content"
                className={style.input}
                value={option.option}
                onChange={(e) => handleContentChange(index, e.target.value)}
                required={true}
              />
              <Form.Select
                className={`${style.input} ${style.qualify}`}
                value={option.qualifyIfChosen}
                onChange={(e) =>
                  handleQualificationChange(index, e.target.value)
                }
                required={true}
              >
                <option value={false}>Reject</option>
                <option value={true}>Accept</option>
              </Form.Select>
              <MdDelete
                className={style.delete}
                onClick={() => removeOption(index)}
              />
            </div>
          </div>
        ))}
      </div>

      <Button
        variant="outline-primary"
        className={style.addOption}
        onClick={addOption}
      >
        Add another option
      </Button>
    </>
  );
}

export function Date({ formData, handleChange }) {
  return (
    <>
      <div className={style.inputGroup}>
        <label htmlFor="selectionConditionAI">Date to Qualify</label>
        <Form.Control
          type="date"
          name="selectionConditionAI"
          id="selectionConditionAI"
          className={style.input}
          value={formData.selectionConditionAI}
          onChange={handleChange}
        />
      </div>
    </>
  );
}

export default ACI_Screen;
