import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Table from "@editorjs/table";
// import Quote from "@editorjs/quote";
import Delimiter from "@editorjs/delimiter";
import Image from "@editorjs/image";
import ChartTool from "./tools/ChartTool";
import styles from "./index.module.css";
import axios from "axios";
import QuoteTool from "./tools/QuoteTool";
import { currentProjectActions } from "../../store/slices/currentProject";
import VideoTool from "./tools/VideoTool";
import ClipsTool from "./tools/ClipsTool";

const defaultTools = {
  header: Header,
  list: List,
  table: Table,
  // quote: Quote,
  delimiter: Delimiter,
  chart: ChartTool,
  quote: QuoteTool,
  image: {
    class: Image,
    config: {
      captionPlaceholder: "Add a caption",
      uploader: {
        uploadByFile: async (file) => {
          const form = new FormData();
          form.append("file", file);

          const { data } = await axios.post(
            process.env.REACT_APP_API_SERVER_URL + "/api/video/upload/asset",
            form
          );

          return {
            success: 1,
            file: {
              url: data.link,
            },
          };
        },
      },
    },
  },
  video: VideoTool,
  clips: ClipsTool,
};

function applyCSSOverrides() {
  const style = document.createElement("style");

  // Define your CSS here
  style.innerHTML = `
    .ce-popover {
      --color-border: var(--grey6);
      --color-shadow: rgba(13,20,33,.13);
      --color-background: var(--grey1);
      --color-text-primary: var(--grey10);
      --color-text-secondary: var(--grey8);
      --color-border-icon: var(--grey8);
      --color-border-icon-disabled: var(--grey6);
      --color-text-icon-active: var(--pink);
      --color-background-icon-active: var(--pink);
      --color-background-item-focus: var(--grey6);
      --color-shadow-item-focus: rgba(13,20,33,.13);
      --color-background-item-hover: var(--grey6);
      --color-background-item-confirm: var(--pink);
      --color-background-item-confirm-hover: var(--pink);
    }
    .ce-toolbar__plus {
      color: var(--grey10);
    }
    .cdx-search-field {
      color: var(--grey10);
      background-color: var(--grey1);
      border: 1px solid var(--grey6);
    }

    .ce-popover-item__icon {
      color: var(--grey7);
      background: var(--grey1);
      border: 1px solid var(--grey3);
    }

    .ce-toolbar__settings-btn {
      color: var(--grey10);
    }

    `;

  // Append the style to the document head
  document.head.appendChild(style);
}

const Editor = ({
  tools = defaultTools,
  initialValue,
  autofocus = false,
  changeTo,
  setChangeTo,
  addBlock,
  setAddBlock,
  readOnly = false,
}) => {
  const { darkMode } = useSelector((state) => state.theme);
  const [loading, setLoading] = useState(true);
  const editorJsRef = useRef(null);
  const editorInstance = useRef(null);
  const dispatch = useDispatch();
  const timeout = useRef(null);
  const prevData = useRef(null);

  useEffect(() => {
    return () => {
      console.log("destroying editor");
      clearTimeout(timeout.current);
    };
  }, []);

  // FOR DARK MODE
  useEffect(() => {
    if (darkMode) {
      applyCSSOverrides();
    }
  }, [darkMode]);

  const value = useMemo(() => initialValue, [initialValue]); // memoize value prop
  //   whenever there is a changeTo, we name to update the editor with the new data but track the changeTo
  //   so that we can update the editor with the new data when the changeTo is done
  useEffect(() => {
    if (editorInstance.current && changeTo) {
      editorInstance.current.isReady.then(() => {
        editorInstance.current.blocks.render(changeTo);
        prevData.current = changeTo;
        setChangeTo(null);
      });
    }
  }, [changeTo]);

  // for adding a new block
  useEffect(() => {
    if (editorInstance.current && addBlock) {
      editorInstance.current.isReady.then(() => {
        editorInstance.current.blocks.insert(addBlock.type, addBlock.data);
        setAddBlock(null);
      });
    }
  }, [addBlock]);

  useEffect(() => {
    const initEditor = async () => {
      if (
        editorInstance.current &&
        typeof editorInstance.current.destroy === "function"
      ) {
        await editorInstance.current.destroy();
        editorInstance.current = null;
      }

      if (editorJsRef.current) {
        editorInstance.current = new EditorJS({
          readOnly: readOnly,
          placeholder: 'Type anything here or hit "Tab" to start',
          holder: editorJsRef.current,
          tools: tools,
          data: value,
          autofocus: autofocus,
          minHeight: readOnly ? 0 : 300,
          onChange: async () => {
            if (editorInstance.current) {
              const currentData = await editorInstance.current.save();

              // added string comparison to prevent uneccessary dispatches due to unknown bug in clips tool
              if (
                currentData &&
                JSON.stringify(prevData.current.blocks) !==
                  JSON.stringify(currentData.blocks)
              ) {
                prevData.current = currentData;

                clearTimeout(timeout.current);
                setTimeout(() => {
                  dispatch(currentProjectActions.setEditorData(currentData));
                }, 2000);
              }
            }
          },
        });
      }
    };

    if (loading) {
      initEditor().then(() => setLoading(false));
    }

    // Clean up function
    return async () => {
      if (
        editorInstance.current &&
        typeof editorInstance.current.destroy === "function"
      ) {
        await editorInstance.current.destroy();
        editorInstance.current = null;
      }
    };
  }, [tools, value, autofocus, loading]);

  return (
    <div className={styles.editorContainer}>
      <div ref={editorJsRef} />
    </div>
  );
};

export default Editor;
