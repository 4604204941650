import store from "..";
import { chatActions } from "../slices/chatSlice";
import { callExternalApi } from "../../services/external-api.service";
import { toastError } from "../../utils/toast";
import { currentProjectActions } from "../slices/currentProject";

const { dispatch } = store;

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

// get messages
export const getMessages = async (projectId, threadId) => {
  dispatch(chatActions.requestStart());
  const config = {
    url: `${apiServerUrl}/api/chat/messages`,
    method: "GET",
    params: { projectId, threadId },
  };

  const { data, error } = await callExternalApi({ config });

  if (error) dispatch(chatActions.requestFail(error.message));
  else dispatch(chatActions.getMessages({ messages: data }));
};

// send message
export const sendMessage = async (payload) => {
  if (!payload.followup) {
    // fix date
    payload.createdAt = new Date().toISOString();
    dispatch(chatActions.sendMessage(payload));

    // Create AI understanding message
    const understandingMsg = {
      sender: "AI",
      message: "Understanding your message",
      messageType: "Instructions",
      projectId: payload.projectId,
      createdAt: new Date().toISOString(),
    };
    dispatch(chatActions.sendMessage(understandingMsg));
  }

  dispatch(chatActions.requestStart());

  const config = {
    url: payload.followup
      ? `${apiServerUrl}/api/chat/send/followup`
      : `${apiServerUrl}/api/chat/send/intro`,
    method: "POST",
    data: payload,
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    toastError(error.message);
    dispatch(chatActions.requestFail(error.message));
  } else if (data && Array.isArray(data)) {
    const response = data[0];
    if (response.messageType === "Instructions") {
      const messageWithoutTyping = {
        ...response,
        typing: false,
      };
      dispatch(chatActions.sendMessage(messageWithoutTyping));
      // Make another request with same data + intent
      const newPayload = {
        ...payload,
        intent: response.metadata.intent,
        followup: true,
      };
      sendMessage(newPayload);
    } else {
      // Create a typing animation effect only for the first message
      const words = response.message.split(" ");
      const typingDelay = 80; // Adjust this value for typing speed

      // Create a new message with the first word
      const firstWordMessage = {
        ...response,
        message: words[0],
        id: "typing-" + response.id,
        typing: true,
      };
      dispatch(chatActions.sendMessage(firstWordMessage));

      // Update the message with the rest of the words
      words.slice(1).forEach((word, index) => {
        setTimeout(() => {
          const existingMessage = words.slice(0, index + 1).join(" ");
          const updatedMessage = {
            ...response,
            message: existingMessage + " " + word,
            id: "typing-" + response.id,
            typing: true,
          };
          dispatch(chatActions.updateMessage(updatedMessage));

          // Once the typing animation is finished, replace the temporary message with the final message
          if (index === words.length - 2) {
            setTimeout(() => {
              dispatch(
                chatActions.deleteMessage({ id: "typing-" + response.id })
              );
              const finalMessage = {
                ...response,
                typing: false, // Set the typing key to false for the final message
              };
              dispatch(chatActions.sendMessage(finalMessage));

              // Dispatch remaining messages without typing animation
              data.slice(1).forEach((message) => {
                const messageWithoutTyping = {
                  ...message,
                  typing: false,
                };
                dispatch(chatActions.sendMessage(messageWithoutTyping));
              });
            }, typingDelay);
          }
        }, typingDelay * (index + 1));
      });
    }
  }
};

export const createThread = async (projectId) => {
  dispatch(chatActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/chat/thread`,
    method: "POST",
    data: { projectId },
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    dispatch(chatActions.requestFail(error.message));
    console.error(error);
  } else {
    dispatch(currentProjectActions.addThread({ id: data.data }));
    dispatch(chatActions.requestFinish());
  }

  return { data, error };
};

// delete messages by project id
// export const deleteMessagesByProjectId = async (projectId) => {
//   dispatch(chatActions.requestStart());

//   const config = {
//     url: `${apiServerUrl}/api/chat/project/${projectId}`,
//     method: "DELETE",
//     headers: {
//       "content-type": "application/json",
//     },
//   };

//   const { error } = await callExternalApi({ config });

//   if (error) dispatch(chatActions.requestFail(error.message));
//   else {
//     // show success toast
//     toastSuccess("AI chat history was cleared");
//     dispatch(chatActions.deleteMessagesByProjectId({ projectId }));
//   }
// }
