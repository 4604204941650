import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { loadProjectsAction } from "../store/actions/projectActions";
import { refreshToken, loadAccountAction } from "../store/actions/authActions";

const PUBLIC_PAGES = ["signup", "login"];
export const AUTH_SOURCES = {
  PUBLIC: "public",
  PROTECTED: "protected",
  ROOT: "root",
  PARTICIPANT_WELCOME: "participant_welcome",
};

const useAuth = (src) => {
  const { isAuthenticated, account } = useSelector((state) => state.auth);
  const [authLoading, setAuthLoading] = React.useState(true);
  const history = useHistory();

  const tryRefresh =
    !isAuthenticated &&
    (PUBLIC_PAGES.some((page) => window.location.pathname.includes(page)) ||
      src === AUTH_SOURCES.PROTECTED ||
      src === AUTH_SOURCES.ROOT ||
      src === AUTH_SOURCES.PARTICIPANT_WELCOME);

  React.useEffect(() => {
    if (!account) return;

    if (account?.accountActive) {
      // stay on the current auth'd page and
      // dont redirect to login page in case of participant welcome page
      if (
        ![AUTH_SOURCES.PROTECTED, AUTH_SOURCES.PARTICIPANT_WELCOME].includes(
          src
        )
      )
        history.push("/projects");
    } else {
      history.push("/waitlist");
    }
  }, [account, history, src]);

  React.useEffect(() => {
    (async function () {
      if (tryRefresh) {
        const { data } = await refreshToken();
        if (data) {
          await loadAccountAction(data.accessToken);
          await loadProjectsAction();
        } else {
          // navigate to login page if refresh token fails
          // and don't redirect in case of participant welcome page
          if (
            ![AUTH_SOURCES.PUBLIC, AUTH_SOURCES.PARTICIPANT_WELCOME].includes(
              src
            )
          ) {
            history.push("/login");
          }
        }
      }
      setAuthLoading(false);
    })();
  }, [tryRefresh, src, history]);

  return { authLoading };
};

export default useAuth;
