import { Modal } from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../../../../store/slices/modalSlice";
import IconBtn from "../buttons/icon-button";
import {
  FiColumns,
  FiFileText,
  FiMessageCircle,
  FiMusic,
  FiVideo,
} from "react-icons/fi";
import styles from "./createTrimModal.module.css";
import truncateString from "../../../../utils/truncateString";
import Player from "./player";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import "./index.css";
import { debounce } from "lodash";
import { Button } from "@mantine/core";
import { createClipAction } from "../../../../store/actions/clipActions";
import TagsInput from "react-tagsinput";

function CreateTrimClipModal() {
  const dispatch = useDispatch();
  const { clipTrim: show } = useSelector((state) => state.modal);
  const { project } = useSelector((state) => state.currentProject);
  const [assetId, setAssetId] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [value, setValue] = useState([0, 0]);
  const [clipNotes, setClipNotes] = useState("");
  const [tags, setTags] = useState([]);
  const [currentTag, setCurrentTag] = useState("");
  useEffect(() => {
    if (assets?.length > 0) {
      setAssetId(assets[0]._id);
    }
  }, [show]);
  useEffect(() => {
    if (selectedVideo) {
      setValue([0, selectedVideo.duration]);
    }
  }, [selectedVideo]);
  useEffect(() => {
    if (assetId)
      setSelectedVideo(assets?.find((video) => video._id === assetId));
  }, [assetId]);

  const assets = project?.videos?.filter(
    (video) => video.assetType === "VIDEO" || video.assetType === "AUDIO"
  );

  useEffect(() => {
    debounce(() => {
      setCurrentPlayerTime(value[0]);
    }, 1000);
  }, [value[0]]);

  const [currentPlayerTime, setCurrentPlayerTime] = useState(0);

  const isVideo = selectedVideo?.assetType === "VIDEO";
  const videoRef = useRef(null);

  return (
    <Modal
      opened={show}
      size={"xl"}
      title="Create a Clip"
      onClose={() => dispatch(modalActions.hideCreateClipTrimModal())}
    >
      <div className={styles.container}>
        <div className={styles.clipTrimContainer}>
          <div className={styles.sidebar}>
            {assets?.map((ele, i) => (
              <div className={styles.sidebar__btn} key={i}>
                <IconBtn
                  handleClick={() => setAssetId(ele._id)}
                  type={ele?.assetType}
                  thumbnail={ele?.thumbnail}
                  icon={iconsForAssetType[ele.assetType] || <FiFileText />}
                  isActive={ele._id === assetId}
                />
                <span className={styles.sidebar__span}>
                  {truncateString(ele.name, 8)}
                </span>
              </div>
            ))}
          </div>
          <div className={styles.content}>
            <div className={styles.player}>
              {selectedVideo && (
                <Player
                  file={selectedVideo}
                  videoRef={videoRef}
                  currentPlayerTime={currentPlayerTime}
                  setCurrentPlayerTime={setCurrentPlayerTime}
                  onTimeUpdate={(e) => {
                    setCurrentPlayerTime(e.target?.currentTime);
                  }}
                  isVideo={isVideo}
                  gotoTime={value[0]}
                  endTime={value[1]}
                  style={{ width: "100%" }}
                />
              )}
            </div>

            <div className={styles.trimmer}>
              <RangeSlider
                id="range-slider-ab"
                className="margin-lg"
                step={1}
                rangeSlideDisabled={false}
                onInput={(value) => {
                  if (value[0] > value[1]) return;
                  setValue(value);
                }}
                value={value}
                min={0}
                max={selectedVideo?.duration}
              />
              <div className={styles.timings}>
                <p>Clip Start/End Time:</p>
                <div>
                  Start time{" "}
                  <TimeInput
                    currentTime={value[0]}
                    setValue={(val) => {
                      setValue([val, value[1]]);
                    }}
                    maxVal={value[1]}
                  />
                </div>
                <div>
                  End time{" "}
                  <TimeInput
                    currentTime={value[1]}
                    setValue={(val) => {
                      setValue([value[0], val]);
                    }}
                    maxVal={selectedVideo?.duration}
                  />
                </div>
              </div>
            </div>

            <div className={styles.clipInfo}>
              <div className={styles.notesAndTagsContainer}>
                <div>
                  <p>Tags</p>
                  <TagsInput
                    name="tags"
                    id="tags"
                    className={styles.tags}
                    value={tags}
                    onChange={(value) => {
                      setTags(value);
                    }}
                    inputValue={currentTag}
                    onChangeInput={(value) => {
                      setCurrentTag(value);
                    }}
                  />
                </div>
                <div>
                  <p>Clip Notes</p>
                  <textarea
                    className={styles.clipNotes}
                    name="clipNotes"
                    id="clipNotes"
                    cols="30"
                    rows="1"
                    value={clipNotes}
                    placeholder="Type your notes here"
                    onChange={(e) => setClipNotes(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.actionButton}>
          <Button
            style={{
              borderRadius: "0.5rem",
            }}
            variant="default"
            onClick={() => {
              dispatch(modalActions.hideCreateClipTrimModal());
            }}
          >
            Cancel
          </Button>
          <Button
            className={styles.createButton}
            variant="filled"
            style={{
              borderRadius: "0.5rem",
            }}
            onClick={() => {
              createClipAction({
                title: "test",
                assetId: assetId,
                projectId: project._id,
                clipNotes: clipNotes,
                clipTags:
                  tags.length > 0
                    ? tags
                    : currentTag !== ""
                    ? [currentTag]
                    : [],
                startTime: value[0] * 1000,
                endTime: value[1] * 1000,
              });
              dispatch(modalActions.hideCreateClipTrimModal());
            }}
          >
            Create Clip
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export function TimeInput({ currentTime, setValue, maxVal }) {
  const [minutes, setMinutes] = useState(currentTime / 60);
  const [seconds, setSeconds] = useState(currentTime % 60);
  const maxMinutes = Math.floor(maxVal / 60);

  useEffect(() => {
    setMinutes(Math.floor(currentTime / 60));
    setSeconds(Math.floor(currentTime % 60));
  }, [currentTime]);

  const handleMinutesChange = (e) => {
    let parsedNumber = parseInt(e.target.value);

    if (parsedNumber < 0 || parsedNumber > maxMinutes) return;

    setMinutes(parsedNumber);
  };
  const handleMinuteInputBlurEnter = () => {
    if (minutes < 0 || minutes > maxMinutes) {
      return;
    }

    if (typeof seconds === "number" && typeof minutes === "number") {
      setValue(minutes * 60 + seconds);
    }
  };

  const handleSecondsChange = (e) => {
    let parsedNumber = parseInt(e.target.value);
    let calculateTime = minutes * 60 + parsedNumber;

    if (!(parsedNumber < 0 || parsedNumber > 59 || calculateTime > maxVal)) {
      setSeconds(parsedNumber);
    }
  };
  const handleSecondInputBlurEnter = () => {
    if (typeof seconds === "number" && typeof minutes === "number") {
      setValue(minutes * 60 + seconds);
    }
  };

  return (
    <div className={styles.timeInput}>
      <input
        placeholder="MM"
        type="number"
        value={minutes}
        onChange={handleMinutesChange}
        onBlur={handleMinuteInputBlurEnter}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            handleMinuteInputBlurEnter();
          }
        }}
      />
      <span>:</span>
      <input
        placeholder="SS"
        type="number"
        value={seconds}
        onChange={handleSecondsChange}
        onBlur={() => {
          handleSecondInputBlurEnter();
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            handleSecondInputBlurEnter();
          }
        }}
      />
    </div>
  );
}
export const iconsForAssetType = [
  { type: "VIDEO", icon: <FiVideo /> },
  { type: "AUDIO", icon: <FiMusic /> },
  { type: "TEXT", icon: <FiFileText /> },
  { type: "CSV", icon: <FiColumns /> },
  { type: "PDF", icon: <FiFileText /> },
  { type: "DOC", icon: <FiFileText /> },
  { type: "LIVECHAT", icon: <FiMessageCircle /> },
  { type: "OTHER", icon: <FiFileText /> },
];
export default CreateTrimClipModal;
