import { toast } from "react-toastify";
import store from "../store";

let theme = store.getState().theme.darkMode ? "dark" : "light"

let defaultStyling = {
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: theme,
  hideProgressBar: false,
}


store.subscribe(() => {
  theme = store.getState().theme.darkMode ? "dark" : "light"

  defaultStyling = {
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: theme,
    hideProgressBar: false,
  }

})





export function toastPromise(promise, config) {
  toast.promise(promise, config, {
    ...defaultStyling,
    position: "top-center",
    autoClose: 5000,
  })
}

export function toastSuccess(message) {
  toast.success(message, {
    ...defaultStyling,
    position: "top-center",
    autoClose: 5000,
  });
}

export function toastError(message) {
  toast.error(message, {
    ...defaultStyling,
    position: "top-center",
    autoClose: 5000,
  });
}

export function toastDark(message) {
  toast.dark(message, {
    ...defaultStyling,
    position: "top-right",
    autoClose: 3000,
  });
}