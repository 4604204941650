import React from "react";
import { Link } from "react-router-dom";
import styles from "./Card.module.css";

const Card = ({ Icon, iconColor, heading, bodyText, url, linkText }) => {
  const circleBgColor = `${iconColor}1A`; // Assuming iconColor is in hex format and adding 10% opacity

  return (
    <div className={styles.card}>
      <div
        className={styles.iconCircle}
        style={{ backgroundColor: circleBgColor }}
      >
        <Icon color={iconColor} size="1.5rem" />
      </div>
      <h2>{heading}</h2>
      <p>{bodyText}</p>
      {url?.length > 0 && (
        <Link
          to={url}
          style={{
            color: iconColor,
            fontWeight: "bold",
            textDecoration: "none",
            marginTop: "1rem",
            display: "inline-block",
          }}
        >
          {linkText}
        </Link>
      )}
    </div>
  );
};

export default Card;
