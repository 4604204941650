/* eslint-disable no-unused-vars */
import { toast } from "react-toastify";
import {
  fetchMeetingsStart,
  fetchMeetingsSuccess,
  fetchMeetingsFailure,
  addMeetingStart,
  addMeetingSuccess,
  addMeetingFailure,
  deleteMeetingStart,
  deleteMeetingSuccess,
  deleteMeetingFailure,
} from "../slices/virtualParticipantSlice";
import { callExternalApi } from "../../services/external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const fetchMeetingsByProject = (id) => async (dispatch) => {
  dispatch(fetchMeetingsStart());

  const config = {
    url: `${apiServerUrl}/api/virtual-participant-meeting/project/${id}`,
    method: "GET",
  };

  const { data, error } = await callExternalApi({ config });
  if (error) {
    console.error("Failed to fetch meetings");
    dispatch(fetchMeetingsFailure(error.message));
    return;
  }
  dispatch(fetchMeetingsSuccess(data));
};

export const addMeeting = (meetingData) => async (dispatch) => {
  dispatch(addMeetingStart());

  const config = {
    url: `${apiServerUrl}/api/virtual-participant-meeting`,
    method: "POST",
    data: meetingData,
  };

  // Make API call to add meeting
  const { data, error } = await callExternalApi({ config });
  if (error) {
    toast.error("Failed to add meeting");
    dispatch(addMeetingFailure(error.message));
    return;
  }
  dispatch(addMeetingSuccess(data));
  toast.success("Meeting added successfully");
};

export const deleteMeeting = (id) => async (dispatch) => {
  dispatch(deleteMeetingStart());

  const config = {
    url: `${apiServerUrl}/api/virtual-participant-meeting/${id}`,
    method: "DELETE",
  };

  // Make API call to delete meeting
  const { error } = await callExternalApi({ config });
  if (error) {
    toast.error("Failed to delete meeting");
    dispatch(deleteMeetingFailure(error.message));
    return;
  }
  dispatch(deleteMeetingSuccess(id));
  toast.success("Meeting deleted successfully");
};
