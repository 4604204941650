import React, { useEffect, useState } from "react";
import ClipsShower from "./clipsShower";
import { useSelector } from "react-redux";
import { getAllClips } from "../../../../store/actions/clipActions";
import { ClipsLoadingState } from "../ProcessingStates";
import CreateTrimClipModal from "./createTrimClipModal";
function Clips({ projectId, isProcessing }) {
  const { clips, loading } = useSelector((state) => state.clip);
  const [clipsToShow, setClipsToShow] = useState([]);
  const { data } = useSelector((state) => state.ribbon);
  useEffect(() => {
    if (projectId) {
      getAllClips(projectId);
    }
  }, [projectId]);
  useEffect(() => {
    if (clips.length > 0) {
      let clipsFiltered = clips;
      if (data?.searchTerm?.length > 0) {
        let clipsFiltered = clips.filter(
          (clip) =>
            clip.title
              ?.toLowerCase()
              .includes(data.searchTerm?.toLowerCase()) ||
            clip.clipNotes
              ?.toLowerCase()
              ?.includes(data.searchTerm?.toLowerCase()) ||
            clip.clipTags?.some((tag) =>
              tag?.toLowerCase().includes(data.searchTerm?.toLowerCase())
            )
        );

        setClipsToShow(clipsFiltered);
        return;
      }
      if (data?.tags?.length > 0) {
        clipsFiltered = clipsFiltered.filter((clip) =>
          clip.clipTags?.some((tag) => data.tags.includes(tag))
        );
      }
      if (data?.sessions?.length > 0) {
        clipsFiltered = clipsFiltered.filter((clip) =>
          data.sessions.includes(clip.assetId)
        );
      }
      setClipsToShow(clipsFiltered);
    }
  }, [clips, data?.searchTerm, data?.tags, data?.sessions]);

  return (
    <>
      <CreateTrimClipModal />
      {isProcessing ? (
        <ClipsLoadingState />
      ) : (
        <div>
          {clips.length === 0 && !loading ? (
            <ClipsLoadingState />
          ) : (
            <ClipsShower clips={clipsToShow} />
          )}
        </div>
      )}
    </>
  );
}

export default Clips;
