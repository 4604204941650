import store from "..";
import { settingsActions } from "../slices/settingsSlice";
import { callExternalApi } from "../../services/external-api.service";
import { toastSuccess } from "../../utils/toast";

const { dispatch } = store;

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getApiKeysDataAction = async (userId) => {
  dispatch(settingsActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/apikey/${userId}`,
    method: "GET",
  };

  const { data, error } = await callExternalApi({ config });

  if (data) return dispatch(settingsActions.getApiKeys({ apiKeys: data }));
  if (error) return dispatch(settingsActions.requestFail(error));
};


export const handleDeleteAPIKeyAction = async ({ id }) => {
  dispatch(settingsActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/apikey/${id}`,
    method: "DELETE",
  };
  const { data, error } = await callExternalApi({ config });

  if (data) {
    dispatch(settingsActions.deleteApiKey({ id }));
    toastSuccess("Your API key has been deleted");
  }

  if (error) {
    console.error(error);
  }
};

export const handleGenerateApiKeyAction = async ({ userId }) => {
  dispatch(settingsActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/apikey/create`,
    method: "POST",
    data: { userId }
  };

  const { data, error } = await callExternalApi({ config });

  if (data) {
    dispatch(settingsActions.generateApiKey(data));
    toastSuccess("Your API key has been generated");
  }
  if (error) dispatch(settingsActions.requestFail(error));
};

export const showApiKey = (keyId) => {
  dispatch(settingsActions.showApiKey(keyId));
};

export const hideApiKey = (keyId) => {
  dispatch(settingsActions.hideApiKey(keyId));
};