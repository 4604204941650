import { Modal } from "@mantine/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../../../../store/slices/modalSlice";
import { toast } from "react-toastify";
import styles from "./index.module.css";
import { Button } from "react-bootstrap";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { addMeeting } from "../../../../store/actions/virtualParticipantActions";

// toast.configure();
dayjs.extend(utc);
dayjs.extend(timezone);

function AddNotetakerModal() {
  const { notetaker: show } = useSelector((state) => state.modal);
  const { project } = useSelector((state) => state.currentProject);
  const { account } = useSelector((state) => state.auth);
  const [meetLink, setMeetLink] = useState("");
  const [meetingDateTime, setMeetingDateTime] = useState(dayjs());
  const dispatch = useDispatch();
  function isHttpValid(str) {
    try {
      const newUrl = new URL(str);
      return newUrl.protocol === "https:";
    } catch (err) {
      return false;
    }
  }

  const handleDateChange = (e) => {
    const newDate = dayjs(e.target.value);
    setMeetingDateTime(newDate);
  };

  function isValidMeetingLink(str) {
    const meetRegex = /^(https:\/\/)?meet\.google\.com\//;
    const teamsRegex = /^(https:\/\/)?teams\.live\.com\/meet\//;
    const zoomRegex = /^(https:\/\/)?([\da-z.-]+)\.zoom\.us\//;
    return meetRegex.test(str) || teamsRegex.test(str) || zoomRegex.test(str);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      account?.plan?.storageCredits?.total -
        account?.plan?.storageCredits?.used <=
      0
    ) {
      toast.error("Insufficient storage credits. Please upgrade your plan.");
      return;
    }

    if (meetLink === "" || meetingDateTime === "") {
      toast.error("All fields are required.");
      return;
    }

    if (isValidMeetingLink(meetLink) === false) {
      toast.error("Please enter a valid meeting link.");
      return;
    }

    if (meetingDateTime.isBefore(dayjs().add(9, "minute"))) {
      toast.error(
        "Meeting date and time can only be set 10 minutes in the future."
      );
      return;
    }

    dispatch(
      addMeeting({
        meetingUrl: meetLink,
        meetingDateTime: meetingDateTime.utc().format(),
        timezone: dayjs.tz.guess(),
        project: project._id,
      })
    );

    setMeetLink("");
    setMeetingDateTime(dayjs());
    dispatch(modalActions.hideNotetakerModal());
  };

  return (
    <Modal
      size="lg"
      opened={show}
      onClose={(e) => {
        dispatch(modalActions.hideNotetakerModal());
      }}
      title="Add a Notetaker to a Meeting"
    >
      <p>Enter the meeting link to get started.</p>
      <div className={styles.notetaker_inputs}>
        <div className={styles.notetaker_input}>
          <label htmlFor="meet-link">Meeting Link </label>
          <input
            type="url"
            id="meet-link"
            name="meet-link"
            pattern="https://.*"
            aria-describedby="meet-link"
            placeholder="e.g., https://meet.google.com/..."
            value={meetLink}
            onChange={(e) => setMeetLink(e.target.value)}
          />
        </div>
        <div className={styles.notetaker_input}>
          <label htmlFor="meet-date">Meeting Date and Time</label>
          <input
            type="datetime-local"
            id="meet-date"
            name="meet-date"
            aria-describedby="meet-date"
            placeholder="Select a date"
            value={meetingDateTime.format("YYYY-MM-DDTHH:mm")}
            onChange={handleDateChange}
          />
        </div>

        <p className={styles.notetaker_timezone}>
          Your timezone is {dayjs.tz.guess()}
        </p>
        <div className={styles.notetaker_action__btns}>
          <Button
            variant="light"
            className={styles.left}
            onClick={() => {
              dispatch(modalActions.hideNotetakerModal());
            }}
          >
            Close
          </Button>
          <Button
            varient="primary"
            onClick={handleSubmit}
            disabled={!isHttpValid(meetLink) || !meetingDateTime}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default AddNotetakerModal;
