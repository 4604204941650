/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { TestLayout } from "../../components/test-layout";
import style from "./index.module.css";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import BottomBar from "../../components/bottom-bar";
import { LOCATION, QUALIFICATION } from "../../../commons/filter-data";
import { useHistory } from "react-router-dom";
import useSocketContext from "../../../../../utils/SocketContext";
import { EMIT_TYPES, EVENT_TYPES } from "../../../routes/test-route";
import { toastError } from "../../../../../utils/toast";
import { useSelector } from "react-redux";

function FilterAudience({ match }) {
  const history = useHistory();
  const socket = useSocketContext();
  const { currentTest } = useSelector((state) => state.test);

  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedQualification, setSelectedQualification] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const groupedData = QUALIFICATION.reduce((acc, item) => {
    const category = item.name.split(" - ")[0];
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});

  const handleCheckboxChange = (category, value) => {
    switch (category) {
      case "location":
        setSelectedLocation((prevSelected) =>
          prevSelected.includes(value)
            ? prevSelected.filter((item) => item !== value)
            : [...prevSelected, value]
        );
        break;
      case "qualification":
        setSelectedQualification((prevSelected) =>
          !prevSelected.includes(value) && prevSelected.length < 2
            ? [...prevSelected, value]
            : prevSelected.filter((item) => item !== value)
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (currentTest) {
      if (currentTest.locations && !selectedLocation.length)
        setSelectedLocation(currentTest.locations);
      if (currentTest.qualifications && !selectedQualification.length)
        setSelectedQualification(currentTest.qualifications);
    }
  }, [currentTest]);

  React.useEffect(() => {
    if (!socket) return;

    const handler = {
      [EMIT_TYPES.ERROR]: () => {
        setButtonLoading(false);
      },
    };

    socket.attachHandlers(handler);
    return () => {
      socket.detachHandlers(handler);
    };
  }, [socket]);

  const filteredLocations = LOCATION.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <TestLayout stepNumber={3} testId={match.params.id}>
      <div className={style.wrapper}>
        <div className={style.title}>Filter</div>
        <div className={style.subtitle}>
          Select participants or share a link
        </div>
        <div className={style.description}>
          Use the options below to filter your audience. Note that besides
          location, you can only choose 2 filters. Moreover, the AI might have
          chosen some filters for you already.
        </div>
        <div className={style.main}>
          <Accordion className={style.accordion}>
            <Accordion.Item
              eventKey="location"
              className={`${style.accordionItem} ${
                selectedLocation.length > 0 ? style.checkedAccordionItem : ""
              }`}
            >
              <Accordion.Header className={style.accordionHeader}>
                <p className="m-0 position-relative d-flex justify-content-between align-items-center w-100 pe-3">
                  <span>Location</span>
                  <Form.Control
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                    className={style.searchInput}
                    placeholder="Type to search..."
                  />
                </p>
              </Accordion.Header>
              <Accordion.Body
                className={`${style.accordionBody} ${style.locations}`}
              >
                {filteredLocations.length
                  ? filteredLocations.map((item) => (
                      <Form.Check
                        key={item.value}
                        type="checkbox"
                        label={item.name}
                        id={item.value}
                        checked={selectedLocation.includes(item.value)}
                        onChange={() =>
                          handleCheckboxChange("location", item.value)
                        }
                      />
                    ))
                  : "No location found"}
              </Accordion.Body>
            </Accordion.Item>
            {Object.entries(groupedData).map(([category, items]) => {
              const isCategoryChecked = items.some((item) =>
                selectedQualification.includes(item.value)
              );

              const categoryBorderColorClass = isCategoryChecked
                ? style.checkedAccordionItem
                : "";

              const categoryKey = category.toLowerCase();
              const headerLabel = category;

              return (
                <Accordion.Item
                  key={category}
                  eventKey={categoryKey}
                  className={`${style.accordionItem} ${categoryBorderColorClass}`}
                >
                  <Accordion.Header className={style.accordionHeader}>
                    {headerLabel}
                  </Accordion.Header>
                  <Accordion.Body className={style.accordionBody}>
                    {items.map((item) => {
                      const checkboxLabel = item.name.split(" - ")[1];
                      const isChecked = selectedQualification.includes(
                        item.value
                      );
                      const isDisabled =
                        !isChecked && selectedQualification.length >= 2;

                      return (
                        <Form.Check
                          key={item.value}
                          type="checkbox"
                          label={checkboxLabel}
                          onChange={() =>
                            handleCheckboxChange("qualification", item.value)
                          }
                          checked={isChecked}
                          disabled={isDisabled}
                        />
                      );
                    })}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
        <BottomBar
          currentStep={3}
          testId={match.params.id}
          primaryButtonLoading={buttonLoading}
          onPrimaryButtonClick={() => {
            if (selectedQualification.length > 2) {
              return toastError("Maximum 2 qualifications can be selected.");
            }

            setButtonLoading(true);

            socket.testUpdates(EVENT_TYPES.PARTICIPANTS_FILTER, {
              testId: match.params.id,
              locations: selectedLocation,
              qualifications: selectedQualification,
            });
          }}
        />
      </div>
    </TestLayout>
  );
}

export default React.memo(FilterAudience);
