import React from "react";
import styles from "./emptyState.module.css";
const EmptyStatePDF = ({ type }) => {
  if (type === "insights") {
    return (
      <div className={styles.processingContainer}>
        <p style={{ textAlign: "center" }}>
          We couldn&apos;t find any insight in this file
        </p>
      </div>
    );
  }
  return (
    <div className={styles.processingContainer}>
      <p style={{ textAlign: "center" }}>
        We couldn&apos;t find any content in this file
      </p>
    </div>
  );
};

export default EmptyStatePDF;
