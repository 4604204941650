import store from "..";
import { clipActions } from "../slices/clipSlice";
import { callExternalApi } from "../../services/external-api.service";
import { toastError } from "../../utils/toast";
import { toast } from "react-toastify";
const { dispatch } = store;
const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

// get all clips from project
export const getClipsByProjectId = async (projectId) => {
  //   dispatch(clipsActions.requestStart());
  const config = {
    url: `${apiServerUrl}/api/projects/${projectId}/clips`,
    method: "GET",
  };

  const { data, error } = await callExternalApi({ config });

  if (error) dispatch(clipActions.requestFail(error.message));
  else dispatch(clipActions.getClipsByProjectId({ clips: data }));
};
// create blank project
export const createClipAction = async ({
  title,
  assetId,
  clipNotes,
  clipTags,
  startTime,
  endTime,
  projectId,
}) => {
  dispatch(clipActions.requestStart());
  const config = {
    url: `${apiServerUrl}/api/projects/${projectId}/clips`,
    method: "POST",
    data: {
      title,
      assetId,
      clipNotes,
      clipTags,
      startTime,
      endTime,
    },
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    dispatch(clipActions.requestFail(error.message));
    toastError(
      "Something went wrong! Please check your credits or try again later."
    );
  } else {
    toast.success("Clip created successfully and saved to the Clips section. We are processing it for download. You can continue browsing in the meantime.");
    dispatch(clipActions.createClip(data));
  }
};

export const getAllClips = async (projectId) => {
  const config = {
    url: `${apiServerUrl}/api/projects/${projectId}/clips`,
    method: "GET",
  };

  let { data, error } = await callExternalApi({ config });
  data=data.map((clip, i) => {
    return {
      ...clip,
      title: `Clip ${i + 1}`,
    };
  })
  if (error) {
    dispatch(clipActions.requestFail(error.message));
    toastError(
      "Something went wrong! Please check your credits or try again later."
    );
  } else dispatch(clipActions.getClipsByProjectId({ clips: data }));
};

export const deleteClip = async ( projectId, clipId ) => {
  const config = {
    url: `${apiServerUrl}/api/projects/${projectId}/clips/${clipId}`,
    method: "DELETE",
  };
  const { error } = await callExternalApi({ config });
  if (error) {
    dispatch(clipActions.requestFail(error.message));
    toastError(
      "Something went wrong! Please check your credits or try again later."
    );
  } else {
    dispatch(clipActions.deleteClip(clipId));
    toast.success("Clip deleted successfully");
  }
};

export const getClip = async ({ projectId, clipId }) => {
  const config = {
    url: `${apiServerUrl}/api/projects/${projectId}/clips/${clipId}`,
    method: "GET",
  };
  const { data, error } = await callExternalApi({ config });
  if (error) {
    dispatch(clipActions.requestFail(error.message));
    toastError(
      "Something went wrong! Please check your credits or try again later."
    );
  } else {
    dispatch(clipActions.getClipByClipId(data));
  }
};
