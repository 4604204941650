import React from "react";
import { Button, Col } from "react-bootstrap";
import { Tooltip as MantineTooltip } from "@mantine/core";
import styles from "./index.module.css";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { FaCheck } from "react-icons/fa6";
function PlanCard({
  billing,
  planName,
  plan,
  handleLink,
  planLinks,
  monthlyCost,
  yearlyCost,
  title,
  moto,
  includeText,
  isEngage,
}) {
  const isMonthly = billing === "monthly";
  const realPlanName = isMonthly ? `${title} Monthly` : `${title} Yearly`;

  return (
    <Col xs={12} md={isEngage ? 4 : 3} sm={6}>
      <div className={`card mb-4 shadow-sm ` + styles.plansCardTopBorder}>
        <div className={"card-header " + styles.plansCardHeader}>
          <h4 className={"my-0 text-dark font-weight-bold " + styles.planTitle}>
            {title}
          </h4>
        </div>

        <div className="card-body" style={{ paddingTop: "revert" }}>
          <div className="mb-3 plan-feature"> {moto} </div>

          <h1 className={styles.planPrice}>
            ${billing === "monthly" ? monthlyCost : yearlyCost}
            <span
              className="text-muted text-small font-weight-normal ml-2"
              style={{ fontSize: "15px", color: "black" }}
            >
              / month
            </span>
          </h1>
          <div className="mb-3 plan-feature">
            {billing === "monthly" ? "Monthly" : "Annual"} billing
          </div>
          {title === "Free" ? (
            planName === "Free" ? (
              <button
                className={
                  "btn btn-lg btn-block btn-primary " +
                  styles.plansButtonInactive
                }
                disabled={true}
              >
                Current Plan
              </button>
            ) : (
              <Button
                className={
                  styles.plansButtonActive +
                  " btn btn-lg btn-block w-90 mw-100 mx-auto"
                }
                href="https://billing.stripe.com/p/login/aEUaFi5Ia3mI7gQ000"
                target="_blank"
                rel="noopener noreferrer"
              >
                Downgrade
              </Button>
            )
          ) : planName === realPlanName ? (
            <button
              className={
                "btn btn-lg btn-block btn-primary " + styles.plansButtonInactive
              }
              disabled={true}
            >
              Current Plan
            </button>
          ) : (
            <>
              {billing === "monthly" ? (
                <Button
                  className={
                    styles.plansButtonActive +
                    " btn btn-lg btn-block w-90 mw-100 mx-auto"
                  }
                  onClick={() => handleLink(planLinks[0])}
                >
                  Get started
                </Button>
              ) : (
                <Button
                  className={
                    styles.plansButtonActive +
                    " btn btn-lg btn-block w-90 mw-100 mx-auto"
                  }
                  onClick={() => handleLink(planLinks[1])}
                >
                  Get started
                </Button>
              )}
            </>
          )}

          <ul className="list-unstyled mt-3 mb-4">
            <li
              className={`plan-feature-highlight font-weight-bold ${styles.listhead}`}
            >
              {includeText}
            </li>

            {plan.map((item, index) => {
              return (
                <ToolTipListElement
                  key={index}
                  text={item.feature}
                  toolTipText={item.tooltip}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </Col>
  );
}
const ToolTipListElement = ({ text, toolTipText }) => {
  return (
    <li className="plan-feature">
      <FaCheck className={styles.plansCheckmark} />
      <span className="plan-feature-highlight">{text}</span>
      <MantineTooltip position="top" label={toolTipText} placement="center">
        <span>
          <IoIosInformationCircleOutline style={{ margin: "5px" }} />
        </span>
      </MantineTooltip>
    </li>
  );
};
export default PlanCard;
