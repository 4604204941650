import React from "react";
import style from "./style.module.css";
import { Topbar } from "./components/topbar";
import blocked from "../../../images/tests/blocked.svg";

const TestConcluded = () => {
  return (
    <div className={style.wrapper}>
      <Topbar />
      <div className={`${style.content} col-6 text-center`}>
        <img src={blocked} alt="blocked" />
        <p
          style={{
            fontSize: "28px",
            fontWeight: "500",
            marginTop: "1rem",
          }}
        >
          This study has now concluded, and is no longer accepting submissions.
          Thank you for your interest.
        </p>
      </div>
    </div>
  );
};

export default TestConcluded;
