import { callExternalApi } from "../../services/external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const createSession = async (payload) => {
    
    const config = {
        url: `${apiServerUrl}/api/payment/session/create`,
        method: "POST",
        data: payload,
    };
    const { data, error } = await callExternalApi({ config });
    return {
        data: data || null,
        error,
    };
};