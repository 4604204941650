import store from "..";
import { themeActions } from "../slices/themeSlice";

const { dispatch } = store;

export const switchToLightModeAction = () => {
  dispatch(themeActions.changeMode("light"));
}

export const switchToDarkModeAction = () => {
  dispatch(themeActions.changeMode("dark"));
}