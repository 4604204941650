import { Button, ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";

function PlanAndUsage() {
  const { account, loading } = useSelector((state) => state.auth);

  const engageAccount = account?.engagePlan;

  if (loading) return "Loading...";

  let remainingUEPool =
    engageAccount?.aiInterviewsUEPool?.total -
    engageAccount?.aiInterviewsUEPool?.used;
  let remainingOwnParticipants =
    engageAccount?.aiInterviewsOwnParticipants?.total -
    engageAccount?.aiInterviewsOwnParticipants?.used;
  if (isNaN(remainingUEPool)) remainingUEPool = 0;
  if (isNaN(remainingOwnParticipants)) remainingOwnParticipants = 0;
  return (
    <>
      <div className="mt-sm-4 mt-2">
        <p>Analyze Plan: {account?.plan?.type}</p>
        <p className="mt-sm-4 mt-2">
          Transcription credits remaining (
          {(
            account?.plan?.transcriptionCredits?.total -
            account?.plan?.transcriptionCredits?.used
          ).toFixed(1)}{" "}
          hours):
        </p>
        <ProgressBar
          now={
            account?.plan?.transcriptionCredits?.total -
            account?.plan?.transcriptionCredits?.used
          }
          max={account?.plan?.transcriptionCredits?.total}
        />
        <p className="mt-sm-4 mt-2">
          Storage remaining (
          {(
            account?.plan?.storageCredits?.total -
            account?.plan?.storageCredits?.used
          ).toFixed(1)}{" "}
          GB):
        </p>
        <ProgressBar
          now={
            account?.plan?.storageCredits?.total -
            account?.plan?.storageCredits?.used
          }
          max={account?.plan?.storageCredits?.total}
        />
        <p className="mt-sm-4 mt-2">
          Team member invites remaining (
          {(
            account?.plan?.teamCredits?.total - account?.plan?.teamCredits?.used
          ).toFixed(1)}{" "}
          members):
        </p>
        <ProgressBar
          now={
            account?.plan?.teamCredits?.total - account?.plan?.teamCredits?.used
          }
          max={account?.plan?.teamCredits?.total}
        />
        {account?.plan?.type === "Free" && (
          <>
            <p className="mt-sm-4 mt-2">
              Projects remaining (
              {account?.plan?.projectCredits
                ? account?.plan?.projectCredits?.total -
                  account?.plan?.projectCredits?.used
                : "1"}{" "}
              project)
            </p>
            {account?.plan?.projectCredits ? (
              <ProgressBar
                now={
                  account?.plan?.projectCredits?.total -
                  account?.plan?.projectCredits?.used
                }
                max={account?.plan?.projectCredits?.total}
              />
            ) : (
              <ProgressBar now={1} max={1} />
            )}
          </>
        )}
      </div>
      <div className="mt-sm-4 mt-2">
        <p>Engage Plan: {engageAccount?.type || "No plan selected"}</p>
        <p className="mt-sm-4 mt-2">
          AI interviews with User Evaluation participants ({remainingUEPool}{" "}
          remaining):
        </p>
        <ProgressBar
          now={remainingUEPool}
          max={engageAccount?.aiInterviewsUEPool?.total}
        />

        <p className="mt-sm-4 mt-2">
          {" "}
          AI interviews with your own participants ({
            remainingOwnParticipants
          }{" "}
          remaining):
        </p>
        <ProgressBar
          now={remainingOwnParticipants}
          max={engageAccount?.aiInterviewsOwnParticipants?.total}
        />
      </div>

      <div className="mt-sm-4 mt-2 d-flex justify-content-between">
        <Link to="/plans">
          <Button variant="success">Upgrade Plan</Button>
        </Link>
        {account?.plan?.type !== "Free" && (
          <a
            href="https://billing.stripe.com/p/login/aEUaFi5Ia3mI7gQ000"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="primary">Manage Subscription</Button>
          </a>
        )}
      </div>
    </>
  );
}

export default PlanAndUsage;
