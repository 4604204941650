import { useSelector } from 'react-redux'

export const useGetCollectionCsvData = ({includeSource = true, includeNotes = true, includeTags = true}) => {

  const { lanes } = useSelector((state) => state.lanes);
    // First, find the maximum number of cards across all lanes
    const maxCards = Math.max(...lanes.map(lane => lane.cards.length));

    // Initialize the result array
    const result = Array.from({ length: maxCards }, (_, index) => ({ 'ID': index + 1 }));
  
    // Map for tracking duplicate lane titles
    const laneTitleCount = {};
  
    // Fill up the result array
    for (let laneIndex = 0; laneIndex < lanes.length; laneIndex++) {
      const lane = lanes[laneIndex];
      
      // Check for duplicate lane titles
      laneTitleCount[lane.title] = laneTitleCount[lane.title] ? laneTitleCount[lane.title] + 1 : 1;
      const currentTitleCount = laneTitleCount[lane.title];
      const titleToUse = currentTitleCount > 1 ? `${lane.title} ${currentTitleCount}` : lane.title;
  
      for (let i = 0; i < maxCards; i++) {
        const card = lane.cards[i];
        let cardString = "";
  
        if (card) {
          cardString += `Insight: ${card.insight}\n\n\n`;
  
          if (card.source && includeSource) {
            cardString += `Source: ${card.source}\n\n\n`;
          }
  
          if (card.notes && includeNotes) {
            cardString += `Notes: ${card.notes}\n\n\n`;
          }
  
          if (card.type && card.type.length > 0 && includeTags) {
            cardString += `Tags: ${card.type.join(", ")}`;
          }
        }
  
        result[i][titleToUse] = cardString;
      }
    }
  
    return result;
};