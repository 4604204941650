import { Button, Col, Form, Row } from "react-bootstrap";
import handleDarkModeChange from "../../../utils/handleDarkModeChange";
import React, { useEffect } from "react";
import { toastError, toastSuccess } from "../../../utils/toast";
import { updateAccountNameAction } from "../../../store/actions/authActions";
import getTimeSince from "../../../utils/getTimeSince";
import UserAvatar from "../../../components/userAvatar";
import { useSelector } from "react-redux";
import { useState } from "react";
import { logout } from "../../../store/actions/authActions";

function Account() {
  const { account, loading, error } = useSelector((state) => state.auth);
  const { darkMode } = useSelector((state) => state.theme);
  const [name, setName] = useState("");

  useEffect(() => {
    if (account?.name) setName(account.name);
  }, [account]);

  const handleUpdateAccountName = async (e) => {
    e.preventDefault();
    if (!account.name) return toastError("Please enter your full name.");

    await updateAccountNameAction(account._id, name);

    if (error) {
      console.error(error);
      return toastError(error);
    }

    toastSuccess("Your name has been updated.");
  };

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
  };

  if (loading) return "";
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        {account && <UserAvatar user={account} />}
        <div style={{ marginLeft: 12 }}>{account?.name}</div>
      </div>
      <Form>
        <Form.Group controlId="formLastName" className="mt-sm-4 mt-2">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Your full name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formEmail" className="mt-sm-4 mt-2">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Email"
            disabled={true}
            value={account?.email}
          />
        </Form.Group>
        <Form.Group controlId="userSince" className="mt-sm-4 mt-2">
          <Form.Label>User Since</Form.Label>
          <Form.Control
            type="text"
            placeholder="User Since"
            disabled={true}
            value={getTimeSince(account?.createdAt)}
          />
        </Form.Group>
        {/* Button */}
        <Form.Group controlId="formButton" className="mt-sm-4 mt-2">
          <Form.Label>Dark Mode ({darkMode ? "On" : "Off"})</Form.Label>
          <Form.Check
            type="switch"
            label=""
            checked={darkMode}
            onChange={handleDarkModeChange}
          />
        </Form.Group>

        <Row className="mt-4">
          <Col>
            <Form.Group controlId="formButton">
              <Button variant="primary" onClick={handleUpdateAccountName}>
                Save Changes
              </Button>
            </Form.Group>
          </Col>
          <Col style={{ textAlign: "right", opacity: 0.5 }}>
            <a href="https://userevaluation.com/support">Help</a>
            <a
              href="/login"
              type="button"
              className="bg-transparent border-0"
              onClick={handleLogout}
              style={{ marginLeft: 20 }}
            >
              Logout
            </a>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default Account;
