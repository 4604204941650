import React from "react";
import { PiVideoBold } from "react-icons/pi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import styles from "./index.module.css";

function IconBtn({ type, thumbnail, icon, isActive, handleClick }) {
  const history = useHistory();
  const { project } = useSelector((state) => state.currentProject);
  if (type === "VIDEO") {
    if (thumbnail) {
      return (
        <button
          className={`${isActive ? styles.active : ""} ${styles.iconContainer}`}
          onClick={handleClick}
        >
          <img src={thumbnail} alt="icon" />
        </button>
      );
    }
    return (
      <button
        className={`${isActive ? styles.active : ""} ${styles.iconContainer}`}
        onClick={handleClick}
      >
        <PiVideoBold />
      </button>
    );
  }
  if (type === "Plus") {
    return (
      <div
        className={`${styles.plusIconContainer} ${styles.iconContainer}`}
        onClick={() => {
          history.push(`/project/${project?._id}/upload`);
        }}
      >
        {icon}
      </div>
    );
  }

  return (
    <button
      className={`${isActive ? styles.active : ""} ${styles.iconContainer}`}
      onClick={handleClick}
    >
      {icon}
    </button>
  );
}

export default IconBtn;
