import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import {
  MdOutlineNavigateNext,
  MdOutlineNavigateBefore,
  MdCheck,
} from "react-icons/md";
import styles from "./index.module.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { checkAnswers, joinTest } from "../../store/actions/testActions";
import Rejected from "./rejected";

const CheckboxWithNumber = ({
  number,
  label,
  onChange,
  isSelected,
  ...props
}) => {
  const handleOnClick = (e) => {
    onChange(label, !isSelected); // Propagate the change
  };

  const containerClasses = `${styles.checkboxContainer} ${
    isSelected ? styles.checkboxContainerSelected : ""
  }`;

  return (
    <div className={containerClasses} onClick={handleOnClick}>
      <input
        type="checkbox"
        onChange={(e) => onChange(e)}
        checked={isSelected}
        className={styles.hiddenCheckbox}
        {...props}
      />
      <span className={styles.checkboxNumber}>{number}</span>
      <label>{label}</label>
      {isSelected && <MdCheck className={styles.checkmarkIcon} />}
    </div>
  );
};

const RadioWithNumber = ({ number, label, onChange, isSelected, ...props }) => {
  const handleOnClick = () => {
    if (!isSelected) {
      onChange(); // Propagate the change only if not already selected
    }
  };

  const containerClasses = `${styles.checkboxContainer} ${
    isSelected ? styles.checkboxContainerSelected : ""
  }`;

  return (
    <div className={containerClasses} onClick={handleOnClick}>
      <input
        type="radio"
        onChange={handleOnClick}
        checked={isSelected}
        className={styles.hiddenCheckbox}
        {...props}
      />
      <span className={styles.checkboxNumber}>{number}</span>
      <label>{label}</label>
      {isSelected && <MdCheck className={styles.checkmarkIcon} />}
    </div>
  );
};

const Question = ({
  question = "",
  subtext = "",
  updateAnswer,
  type = "",
  options = [],
  key,
  answers,
  currentQuestionIndex,
}) => {
  const handleCheckboxChange = (option, isSelected) => {
    let updatedAnswers = answers[currentQuestionIndex] || [];
    if (isSelected) {
      updatedAnswers = [...updatedAnswers, option];
    } else {
      updatedAnswers = updatedAnswers.filter((item) => item !== option);
    }
    updateAnswer(updatedAnswers);
  };

  const words = question.split(" ");
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const renderQuestionType = () => {
    switch (type) {
      case "description":
        return (
          <div className={styles.descriptionText}>
            <p>{subtext}</p>
          </div>
        );
      case "textInput":
      case "multiLineInput":
        return (
          <textarea
            className={styles.textInput}
            rows={type === "multiLineInput" ? 4 : 2}
            type="text"
            ref={inputRef}
            value={answers[currentQuestionIndex] || ""}
            onChange={(e) => updateAnswer(e.target.value)}
            placeholder="Type your answer here"
          />
        );
      case "multipleChoice":
        return (
          <div>
            {options.map(({ option }, index) => (
              <RadioWithNumber
                key={index}
                number={index + 1}
                label={option}
                value={answers[currentQuestionIndex] || ""}
                isSelected={answers[currentQuestionIndex] === option}
                onChange={() => {
                  updateAnswer(option);
                }}
              />
            ))}
          </div>
        );
      case "checkBox":
        return (
          <div>
            {options.map(({ option }, index) => (
              <CheckboxWithNumber
                key={index}
                number={index + 1}
                label={option}
                value={answers[currentQuestionIndex] || ""}
                onChange={(option, isSelected) =>
                  handleCheckboxChange(option, isSelected)
                }
                isSelected={(answers[currentQuestionIndex] || []).includes(
                  option
                )}
              />
            ))}
          </div>
        );
      case "numberInput":
        return (
          <input
            className={styles.textInput}
            type="number"
            value={answers[currentQuestionIndex] || ""}
            ref={inputRef}
            onChange={(e) => updateAnswer(e.target.value)}
            placeholder="Type your answer here"
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.question} key={key}>
      <h2 className={styles.questionTitle}>
        {words.map((word, index) => (
          <>
            <span
              key={index}
              style={{ animationDelay: `${index * 0.2}s` }}
              className={styles.word}
            >
              {word}
            </span>
            &nbsp;
          </>
        ))}
      </h2>
      {renderQuestionType()}
    </div>
  );
};

export const Form = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [disableSumbit, setDisableSumbit] = useState(false);
  const [rejected, setRejected] = useState(false);

  const {
    currentTest: { questions, instructions, ndaLink },
  } = useSelector((state) => state.test);

  const allQuestions = [
    {
      type: "description",
      label: "Please read the following instructions carefully.",
      placeholder: instructions,
    },
    ...(questions || []),
  ];

  const currentQuestion = allQuestions[currentQuestionIndex];

  const params = new URLSearchParams(window.location.search);
  const testId = params.get("tId");

  useEffect(() => {
    if (!questions) history.push("/participant/start-test?tId=" + testId);
  }, [questions, history, testId]);

  const validateInput = () => {
    const answer = answers[currentQuestionIndex];
    if (currentQuestion.type === "description") {
      return true; // Always valid for description type
    }
    switch (currentQuestion.type) {
      case "textInput":
      case "multiLineInput":
        return answer?.length > 1;
      case "numberInput":
        return answer?.length > 0;
      case "multipleChoice":
        return answer !== undefined;
      case "checkBox":
        return answer?.length > 0;
      default:
        return true;
    }
  };

  const updateAnswer = (answer) => {
    setAnswers({ ...answers, [currentQuestionIndex]: answer });
  };

  const hasNext = currentQuestionIndex < allQuestions.length - 1;
  const hasPrev = currentQuestionIndex > 0;
  const hasAnswered = answers[currentQuestionIndex] !== undefined;
  let allQuestionsAnswered =
    allQuestions.length === Object.keys(answers).length;

  //   adjustment for when there are any number of description type questions
  if (allQuestions.filter((q) => q.type === "description").length > 0) {
    allQuestionsAnswered =
      allQuestions.filter((q) => q.type !== "description").length ===
      Object.keys(answers).length;
  }

  const handleNext = () => {
    if (validateInput()) {
      setError(null);
      if (hasNext) setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setError("Please provide a valid answer.");
    }
  };

  const handleSubmit = async () => {
    if (!allQuestionsAnswered) return setError("Please answer all questions.");

    setError(null);

    setDisableSumbit(true);

    const { data, error: checkAnswersErr } = await checkAnswers(
      testId,
      answers
    );

    if (checkAnswersErr || !data.isQualified) {
      setRejected(true);
      return setDisableSumbit(false);
    }

    if (ndaLink)
      return history.push(`/participant/sign-document?tId=${testId}`);

    const { error } = await joinTest(testId);
    if (!error) return history.push(`/participant/redirect?tId=${testId}`);

    setDisableSumbit(false);
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setError(null);
    }
  };

  React.useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        // don't move to next question if it's a multiLineInput
        if (allQuestions[currentQuestionIndex]?.type === "multiLineInput")
          return;

        if (hasNext) {
          handleNext();
        } else if (!hasNext && allQuestionsAnswered) {
          handleSubmit();
        }
      } else if (
        !["textInput", "multiLineInput"].includes(
          allQuestions[currentQuestionIndex]?.type
        )
      ) {
        if (e.key === "ArrowRight" && hasNext) {
          handleNext();
        } else if (e.key === "ArrowLeft" && hasPrev) {
          handleBack();
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [
    hasNext,
    hasPrev,
    allQuestionsAnswered,
    currentQuestionIndex,
    allQuestions,
  ]);

  return (
    <div className={styles.formPage}>
      <div
        className={styles.progressBar}
        style={{
          width: `${((currentQuestionIndex + 1) / allQuestions.length) * 100}%`,
        }}
      ></div>
      <Row className="vh-100 justify-content-center align-items-center">
        <Col>
          <div className={styles.formContainer}>
            <div className={styles.questionContainer}>
              {rejected ? (
                <Rejected />
              ) : (
                  <Question
                    question={currentQuestion.label}
                    subtext={currentQuestion.placeholder}
                    type={currentQuestion.type}
                    options={currentQuestion.options}
                    updateAnswer={updateAnswer}
                    key={currentQuestionIndex}
                    answers={answers}
                    currentQuestionIndex={currentQuestionIndex}
                  />
              )}

              <div className={styles.navigationButtons}>
                {((hasNext && hasAnswered) ||
                  currentQuestion.type === "description") && (
                  <button className={styles.submitAns} onClick={handleNext}>
                    OK
                  </button>
                )}
                {!rejected && !hasNext && allQuestionsAnswered && (
                  <button
                    className={styles.submitAns}
                    onClick={handleSubmit}
                    disabled={disableSumbit}
                  >
                    Submit
                  </button>
                )}
                {error && <div className={styles.error}>{error}</div>}
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <div className={styles.footer}>
        <a
          href="https://www.userevaluation.com"
          target="_blank"
          rel="noreferrer"
        >
          <div className={styles.footerSignature}>
            Powered by{" "}
            <span className={styles.footerSignatureBold}>User Evaluation</span>
          </div>
        </a>
        <div className={styles.footerNavigation}>
          <button
            onClick={handleBack}
            className={
              (hasPrev && !rejected ? styles.active : styles.disabled) +
              " " +
              styles.footerNavigationButton +
              " " +
              styles.footerNavigationButtonLeft
            }
            disabled={!hasPrev || rejected}
          >
            <MdOutlineNavigateBefore style={{ fontSize: "1.5rem" }} />
          </button>
          <button
            onClick={handleNext}
            className={
              (hasNext && !rejected ? styles.active : styles.disabled) +
              " " +
              styles.footerNavigationButton +
              " " +
              styles.footerNavigationButtonRight
            }
            disabled={!hasNext || rejected}
          >
            <MdOutlineNavigateNext style={{ fontSize: "1.5rem" }} />
          </button>
        </div>
      </div>
    </div>
  );
};
