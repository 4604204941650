function formatTime(t) {
  const time = new Date(t).getTime() / 1000; // Convert start time to seconds

  const minutes = Math.floor(time / 60); // Get minutes for start time
  const seconds = Math.floor(time % 60); // Get seconds for start time

  const formattedTime = `${minutes}:${seconds.toString().padStart(2, "0")}`; // Format start time as mm:ss

  return formattedTime; // Return formatted time range
}


export default formatTime