import React, { useEffect, useMemo, useState } from "react";
import styles from "./index.module.css";
import ParticipantsTable from "./participantsTable";

import { useSelector } from "react-redux";

import { debounce } from "lodash";
import { getParticipants } from "../../../../store/actions/adminActions";

function Participants() {
  const { participants: userdata } = useSelector((state) => state.admin);
  const [showUsers, setShowUsers] = useState([]);
  const [search, setSearch] = useState("");

  const debouncedSearch = debounce((search) => {
    setShowUsers((prev) =>
      prev.filter(
        (user) => user.email.includes(search) || user.name.includes(search)
      )
    );
  }, 200);

  useMemo(() => {
    if (search === "") {
      setShowUsers(userdata);
    }
    debouncedSearch(search, userdata);
  }, [search, userdata]);

  useEffect(() => {
    if (userdata) {
      setShowUsers(userdata);
    }
  }, [userdata]);

  useEffect(() => {
    getParticipants();
  }, []);
  return (
    <div className={styles.userContainer}>
      <h2>Participants</h2>
      <div className={styles.filterContainer}>
        <label htmlFor="search">Search:</label>
        <input
          type="text"
          id="search"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
      </div>

      {<ParticipantsTable userdata={showUsers} />}
    </div>
  );
}

export default Participants;
