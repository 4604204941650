import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Form,
  Button,
  ProgressBar,
  Row,
  Col,
  Spinner,
  Accordion,
  Modal,
  ListGroup,
} from "react-bootstrap";
import { HiSparkles } from "react-icons/hi";
import { TbHistory } from "react-icons/tb";
import {
  loadAllReports,
  loadReportById,
} from "../../../../store/actions/currentProjectActions";
import { toastError } from "../../../../utils/toast";
import styles from "./PromptInput.module.css";
const PromptInput = ({
  handleSubmit,
  progressPercent,
  progressMessage,
  handleVideoCheck,
  writingLoader,
}) => {
  const projectData = useSelector((state) => state.currentProject.project);
  const [prompt, setPrompt] = useState("");
  const [activeKey, setActiveKey] = useState("0");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (writingLoader) {
      setActiveKey(null);
    }
  }, [writingLoader]);

  const handleAccordionSelect = (key) => {
    if (key === activeKey) {
      setActiveKey(null);
    } else {
      setActiveKey(key);
    }
  };

  const handleGetReport = async (reportId) => {
    setLoading(true);
    const { data, error } = await loadReportById(reportId);
    if (error) {
      setLoading(false);
      toastError(error.message);
    }
    if (data) {
      setLoading(false);
      setShowModal(false);
    }
  };

  const handleHistoryClick = () => {
    loadAllReports(projectData._id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Row className="justify-content-center">
      <Col xs={12} md={8} className="mt-5">
        <Accordion
          activeKey={activeKey}
          onSelect={handleAccordionSelect}
          flush
          className="mb-3 w-100"
        >
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {writingLoader ? (
                <Spinner animation="border" variant="primary" size="sm" />
              ) : (
                <HiSparkles />
              )}
              &nbsp; Generate an AI Report
            </Accordion.Header>
            <Accordion.Body>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="mb-3">
                  AI Report combines insights from all your files, it can
                  create tables, charts and analysis.
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Tell the AI what to include in your report, like highlights, charts, or headings."
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                controlId="formBasicCheckbox"
               
              >
                <p>Files to include:</p>
                <div className={styles.checkboxGroup}>
                  {projectData.videos.length > 0 &&
                    projectData.videos.map((video, idx) => {
                      const inputId = `video_${video._id}`;
                      return (
                        <Form.Check
                          type="checkbox"
                          label={video.name}
                          value={video._id}
                          onChange={handleVideoCheck}
                          key={idx}
                          id={inputId}
                        />
                      );
                    })}
                </div>
              </Form.Group>
              {progressPercent === 0 && (
                <div className="d-flex justify-content-end">
                  <Button
                    variant="secondary"
                    className="mt-3 me-3"
                    onClick={handleHistoryClick}
                    disabled={progressPercent !== 0 || writingLoader}
                  >
                    <TbHistory />
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    className="mt-3"
                    onClick={() => handleSubmit(prompt)}
                    disabled={progressPercent !== 0 || writingLoader}
                  >
                    {writingLoader ? (
                      <Spinner animation="border" variant="primary" size="sm" />
                    ) : (
                      "Generate Report"
                    )}
                  </Button>
                </div>
              )}
              {progressPercent !== 0 && (
                <>
                  <ProgressBar
                    striped
                    animated
                    variant="info"
                    now={progressPercent}
                    className="mt-3"
                  />
                  <p className="mt-2">{progressMessage}</p>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {loading && (
                <>
                  <Spinner animation="border" variant="primary" size="sm" />
                  &nbsp;
                </>
              )}
              Version History
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup>
              {projectData?.reports &&
                projectData?.reports.length > 0 &&
                projectData.reports.map((report, idx) => {
                  return (
                    <ListGroup.Item
                      action
                      key={idx}
                      onClick={() => handleGetReport(report._id)}
                    >
                      <div className="ms-2 me-auto mb-1 mt-1">
                        <div className="fw-bold">{report.prompt}</div>
                        {report.createdAt}
                      </div>
                    </ListGroup.Item>
                  );
                })}
              {/* show a paragraph if no reports */}
              {projectData?.reports && projectData?.reports.length === 0 && (
                <p>No reports found.</p>
              )}
            </ListGroup>
          </Modal.Body>
        </Modal>
      </Col>
    </Row>
  );
};

export default PromptInput;
