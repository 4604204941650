import React from "react";
import { useSelector } from "react-redux";
import { ChatSocketTab } from "../components/tabs/chatSocket";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import ThreadsSidebar from "./components/threads-sidebar";
import css from "./index.module.css";

const ProjectChat = ({ match }) => {
  const { account } = useSelector((state) => state.auth);
  const { project } = useSelector((state) => state.currentProject);
  const [openChatHistory, setOpenChatHistory] = React.useState(false);

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  return (
    <Container fluid className="overflow-hidden">
      <Row className={css.mobileThreadsStrip}>
        <Col>
          <Button
            as={Link}
            variant="outline-primary"
            to={`/p/${match.params.projectId}/chat`}
            onClick={() => setOpenChatHistory(false)}
          >
            New Chat
          </Button>
        </Col>
        <Col className="text-end">
          <Button
            className={css.chatHistory}
            onClick={() => setOpenChatHistory(!openChatHistory)}
          >
            Chat History
          </Button>
        </Col>
      </Row>
      <Row className={openChatHistory ? css.mobChatHistory : ""}>
        <ThreadsSidebar onLinkClick={() => setOpenChatHistory(false)} />
        <Col className="px-0 overflow-hidden">
          <ChatSocketTab
            projectData={project}
            userAccount={account}
            threadId={params.get("t")}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ProjectChat;
