/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import style from "./edit.module.css";
import { Button, Form } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { updateParticipant } from "../../../../../store/actions/participantActions";
import dayjs from "dayjs";

export default function EditExperiences({ data, onClose }) {
  const [validated, setValidated] = useState(false);

  const [experiences, setExperiences] = useState(() => {
    return data?.length > 0
      ? data
      : [
          {
            title: "",
            company_name: "",
            employment_type: "",
            location: "",
            start_date: "",
            end_date: "",
            description: "",
          },
        ];
  });

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    setExperiences((prevExperiences) => {
      const updatedExperiences = [...prevExperiences];
      updatedExperiences[index] = {
        ...updatedExperiences[index],
        [name]: value,
      };

      return updatedExperiences;
    });
  };

  const handleAddExperience = () => {
    setExperiences((prevExperiences) => [
      ...prevExperiences,
      {
        title: "",
        company_name: "",
        employment_type: "",
        location: "",
        start_date: "",
        end_date: "",
        description: "",
      },
    ]);
  };

  const handleRemoveExperience = (index) => {
    if (experiences.length === 1) return;
    setExperiences((prevExperiences) => {
      const updatedExperiences = [...prevExperiences];
      updatedExperiences.splice(index, 1);
      return updatedExperiences;
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      const { error } = await updateParticipant("experience", experiences);
      if (!error) onClose();
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSave}>
      {experiences.map((experience, index) => (
        <Form.Group
          controlId={`formExperience${index}`}
          className="mb-2"
          key={index}
        >
          <Form.Label className={style.formLabel}>
            <span>
              Experience {index + 1} {index === 0 && " (Latest)"}
            </span>
            <span
              className={style.deleteIcon}
              onClick={() => handleRemoveExperience(index)}
            >
              <MdDelete />
            </span>
          </Form.Label>
          <div className={style.formInput}>
            <Form.Control
              type="text"
              placeholder="Position"
              name="title"
              value={experience.title}
              onChange={(e) => handleInputChange(e, index)}
              required
            />

            <Form.Control
              type="text"
              placeholder="Company"
              name="company_name"
              value={experience.company_name}
              onChange={(e) => handleInputChange(e, index)}
              required
            />

            <Form.Control
              type="text"
              placeholder="Employment Type"
              name="employment_type"
              value={experience.employment_type}
              onChange={(e) => handleInputChange(e, index)}
              required
            />

            <Form.Control
              type="text"
              placeholder="Location (City, State, Country)"
              name="location"
              value={experience.location}
              onChange={(e) => handleInputChange(e, index)}
              required
            />

            <Form.Control
              type="date"
              placeholder="Start Date"
              name="start_date"
              value={dayjs(experience.start_date).format("YYYY-MM-DD")}
              onChange={(e) => handleInputChange(e, index)}
              required
            />

            <Form.Control
              type="date"
              placeholder="End Date"
              name="end_date"
              value={dayjs(experience.end_date).format("YYYY-MM-DD")}
              onChange={(e) => handleInputChange(e, index)}
            />

            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Description"
              name="description"
              value={experience.description}
              onChange={(e) => handleInputChange(e, index)}
            />
          </div>
        </Form.Group>
      ))}
      <div className={style.addEntry} onClick={handleAddExperience}>
        + Add another entry
      </div>

      <Form.Group
        controlId="formButtons"
        className="d-flex justify-content-between mt-3"
      >
        <Button
          variant="secondary"
          className={style.closeButton}
          onClick={onClose}
        >
          Close
        </Button>
        <Button type="submit" variant="primary" className={style.saveButton}>
          Save
        </Button>
      </Form.Group>
    </Form>
  );
}
