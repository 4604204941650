import { Modal, Button } from "@mantine/core";
import React from "react";
import { deleteProjectAction } from "../../../store/actions/projectActions";

const DeleteConfirmationModal = ({ show, setShow, projectId }) => {
  const handleDelete = () => {
    deleteProjectAction(projectId);
    setShow(false);
  };

  return (
    <Modal
      opened={show}
      onClose={() => setShow(false)}
      title="Confirm Deletion"
    >
      <p>
        Are you sure you would like to delete this project? Free users cannot
        create new projects.
      </p>
      <footer
        style={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}
      >
        <Button variant="default" onClick={() => setShow(false)}>
          No
        </Button>
        <Button
          variant="filled"
          color="red"
          style={{ color: "white" }}
          onClick={handleDelete}
        >
          Yes, delete it
        </Button>
      </footer>
    </Modal>
  );
};

export default DeleteConfirmationModal;
