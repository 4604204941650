import React from "react";
import ReactDOM from "react-dom";
import EditorClips from "./EditorClips";
import { Provider } from "react-redux";
import store from "../../../../store";
import EditorClipsDisplay from "./EditorClipsDisplay";

export default class ClipsTool {
  constructor({ data, config, api, readOnly }) {
    this.discard = () => api.blocks.delete(api.blocks.getCurrentBlockIndex());
    this.data = data || {
      start: 0,
      end: 0,
      url: "",
      caption: "",
    };
    this.config = config;
    this.api = api;
    this.readOnly = config.readOnly || readOnly || false;
    this.nodes = {
      container: null,
      caption: null,
      videoHolder: null,
    };
  }

  static get toolbox() {
    return {
      title: "Clips",
      icon: '<svg stroke="currentColor" fill="currentColor" style="padding: 2px;" stroke-width="0" viewBox="0 0 24 24" height="200px" width="200px" xmlns="http://www.w3.org/2000/svg"><path d="M19.187 3.588a2.75 2.75 0 0 0-3.889 0L5.575 13.31a4.5 4.5 0 0 0 6.364 6.364l8.662-8.662a.75.75 0 0 1 1.061 1.06L13 20.735a6 6 0 0 1-8.485-8.485l9.723-9.723a4.247 4.247 0 0 1 4.124-1.139 4.247 4.247 0 0 1 3.025 3.025 4.247 4.247 0 0 1-1.139 4.124l-9.193 9.193a2.64 2.64 0 0 1-1.858.779 2.626 2.626 0 0 1-1.854-.779c-.196-.196-.338-.47-.43-.726a2.822 2.822 0 0 1-.168-.946c0-.7.284-1.373.775-1.864l8.132-8.131a.749.749 0 0 1 1.275.326.749.749 0 0 1-.215.734l-8.131 8.132a1.148 1.148 0 0 0-.336.803c.003.204.053.405.146.587.01.018.018.028.02.032.22.215.501.332.786.332.29 0 .58-.121.798-.34l9.192-9.192a2.75 2.75 0 0 0 0-3.89Z"></path></svg>',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  onDataChange = (newData) => {
    console.log("newData", newData);
    this.data = newData;
    this._renderReact();
  };

  render() {
    const container = document.createElement("div");
    container.classList.add(
      "border",
      "border-1",
      "rounded-3",
      "p-3",
      "my-3",
      "w-fit-content",
      "mx-auto",
      "this-is-a-video"
    );
    this.nodes.container = container;

    this.nodes.videoHolder = document.createElement("div");
    this.nodes.container.appendChild(this.nodes.videoHolder);

    const caption = document.createElement("p");
    caption.innerText = this.data.caption || "Enter a caption";
    caption.setAttribute("contenteditable", "true");
    caption.classList.add("clips-tool__caption");
    this.nodes.caption = caption;

    this._renderReact();

    return container;
  }

  _renderReact() {
    if (this.data.url && this.data.end) {
      ReactDOM.render(
        <EditorClipsDisplay data={this.data} />,
        this.nodes.videoHolder
      );
      this.nodes.container.appendChild(this.nodes.caption);
    } else {
      ReactDOM.render(
        <Provider store={store}>
          <EditorClips
            onDataChange={this.onDataChange}
            discard={this.discard}
          />
        </Provider>,
        this.nodes.videoHolder
      );
    }
  }

  save() {
    return {
      ...this.data,
      caption: this.data.url ? this.nodes.caption.innerText : "Enter a caption",
    };
  }
}
