import { createSlice } from "@reduxjs/toolkit";

/** project currently loaded in /p/<projectId> page */
const currentProjectSlice = createSlice({
  name: "currentProject",
  initialState: {
    isPublic: false,
    id: undefined,
    project: undefined,
    loading: true,
    editorData: undefined,
    error: null,
  },
  reducers: {
    resetProject(state) {
      state.project = undefined;
      state.loading = true;
    },
    setAssetTranscript(state, { payload }) {
      const asset = state.project?.videos.find(
        (vid) => vid._id === payload.asset._id
      );
      if (asset) {
        // we can update other properties of as well since entire asset object is fetched
        asset.transcript = payload.asset.transcript;
      }
    },
    setAssetTitle(state, { payload }) {
      const asset = state.project.videos.find(
        (vid) => vid._id === payload.asset._id
      );
      asset.name = payload.asset.title;
    },
    setProject(state, { payload }) {
      state.project = payload.project;
      state.loading = false;
    },
    setProjectPresentations(state, { payload }) {
      state.project.presentations = payload.presentations;
    },
    setStatus(state, { payload }) {
      state.project.status = payload.status;
    },
    setProjectReport(state, { payload }) {
      if (payload.content) {
        state.project.report = payload.content;
        state.editorData = payload.content;
        state.project.reportId = payload._id;
      }
    },
    setProjectReports(state, { payload }) {
      state.project.reports = payload;
    },
    addProjectPresentation(state, { payload }) {
      if (!state.project.presentations) {
        state.project.presentations = [];
      }
      state.project.presentations.push(payload);
    },
    setLoading(state) {
      state.loading = true;
    },
    resetLoading(state) {
      state.loading = false;
    },
    addInsight(state, { payload }) {
      const asset = state.project.videos.find(
        (vid) => vid._id === payload.assetId
      );
      asset.insights
        .find((insight) => insight.insightType === payload.insightType)
        ?.insights.push(payload.insight);
    },
    deleteInsight(state, { payload }) {
      const { videoId, insightId, insightType } = payload;
      const asset = state.project.videos.find((vid) => vid._id === videoId);
      const insightIndex = asset.insights.findIndex(
        (insight) => insight.insightType === insightType
      );
      const insight = asset.insights[insightIndex];
      const insightIndexInInsights = insight.insights.findIndex(
        (insight) => insight._id === insightId
      );
      insight.insights.splice(insightIndexInInsights, 1);
    },
    setAssetNotes(state, { payload }) {
      state.project.videos.forEach((vid) => {
        if (vid._id === payload.assetId) {
          vid.notes = payload.notes;
        }
      });
    },
    setInsight(state, { payload }) {
      const { videoId, insightId, insightType, insight } = payload;
      const asset = state.project.videos.find((vid) => vid._id === videoId);
      const insightIndex = asset.insights.findIndex(
        (insight) => insight.insightType === insightType
      );
      const insightIndexInInsights = asset.insights[
        insightIndex
      ].insights.findIndex((insight) => insight._id === insightId);
      asset.insights[insightIndex].insights[insightIndexInInsights] = insight;
    },
    setUtteranceInTranscript(state, { payload }) {
      const { assetId, utterance } = payload;
      const asset = state.project.videos.find((vid) => vid._id === assetId);
      const utteranceIndex = asset.transcript[0]?.utterances?.findIndex(
        (ut) => utterance.start === ut?.start && utterance.end === ut?.end
      );
      asset.transcript[0].utterances[utteranceIndex] = utterance;
    },
    updateInsight(state, { payload }) {
      const { assetId, insightId, insightType, insight } = payload;
      const asset = state.project.videos.find((vid) => vid._id === assetId);
      const prevIndex = asset.insights.findIndex(
        (ins) => ins.insightType === insight?.insightType
      );
      const prevInsights = asset.insights[prevIndex];
      const insightIndexInInsights = prevInsights.insights.findIndex(
        (ins) => ins._id === insightId
      );
      const prevInsight = prevInsights.insights[insightIndexInInsights];
      const newInsights = asset.insights.find(
        (ins) => ins.insightType === insightType
      );
      newInsights.insights.push(prevInsight);
      prevInsights.insights.splice(insightIndexInInsights, 1);
    },
    addThread(state, { payload }) {
      state.project = {
        ...state.project,
        threads: [...state.project.threads, payload],
      };
    },
    updateThreadTitle(state, { payload }) {
      const { threadId, title } = payload;
      const threadIndex = state.project.threads.findIndex(
        (thread) => thread.id === threadId
      );
      state.project.threads[threadIndex].title = title;
    },
    setPublic(state, { payload }) {
      state.isPublic = payload.isPublic;
      state.id = payload.id;
    },
    deleteProjectPresentation(state, { payload }) {
      state.project.presentations = state.project.presentations.filter(
        (presentation) => presentation._id !== payload
      );
    },
    deleteProjectReport(state, { payload }) {
      state.project.reports = state.project.reports.filter(
        (report) => report._id !== payload
      );
    },
    addTagToAsset(state, { payload }) {
      state.project.videos.forEach((vid) => {
        if (vid._id === payload.assetId) {
          vid.tags.push(payload.tag);
        }
      });
    },
    setAssetThumbnail(state, { payload }) {
      const asset = state.project.videos.find(
        (vid) => vid._id === payload.assetId
      );
      asset.thumbnail = payload.thumbnail;
    },
    setEditorData(state, { payload }) {
      state.editorData = payload;
    },
    updateProjectName(state, { payload }) {
      state.project.name = payload.name;
    },
    updateAssetSpeakers(state, { payload }) {
      const asset = state.project?.videos.find(
        (vid) => vid._id === payload._id || vid._id === payload.assetId
      );
      if (asset) {
        asset.speakers = payload.speakers || [];
      }
    },
  },
});

export default currentProjectSlice;
export const currentProjectActions = currentProjectSlice.actions;
