/* eslint-disable no-unused-vars */
import React from "react";
import style from "./index.module.css";
import { PageLayout } from "../../../components/page-layout";
import CoverImage from "./components/cover-image";
import Profile from "./components/profile";
import Note from "./components/note";
import * as Sections from "./sections/sections";
import EditProfileButtons from "./sections/edit-profile-buttons";
import { getParticipant } from "../../../store/actions/participantActions";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export const ParticipantProfile = () => {
  const history = useHistory();
  const { user, isAdmin } = useSelector((state) => state.auth);
  const { profileEmail } = useSelector((state) => state.admin);

  React.useEffect(() => {
    if (isAdmin) {
      if (profileEmail) {
        getParticipant(profileEmail);
      }
    } else {
      getParticipant();
    }
  }, [profileEmail, isAdmin]);

  const hasPhoneBeenVerified = user.phone_verified;
  const hasPromptBeenViewed = localStorage.getItem("participantPromptViewed");

  React.useEffect(() => {
    if (isAdmin) {
      return;
    }
    if (hasPhoneBeenVerified !== true) {
      history.push("/participant/phone-verification");
    } else if (hasPromptBeenViewed !== "true") {
      history.push("/participant/prompt");
    }
  }, [hasPhoneBeenVerified, hasPromptBeenViewed, history, isAdmin]);

  let content = (
    <div className={style.wrapper}>
      <CoverImage projectDataId={1} />
      <div className={style.content}>
        <Profile />
        <EditProfileButtons />
        <div className={style.profileDetails}>
          <Sections.Summary />
          <Sections.Experience />
          <Sections.Education />
          <Sections.MoreDetails />
          <Sections.Volunteering />
          <Sections.Certifications />
          <Sections.Recommendations />
        </div>
        <Note />
      </div>
    </div>
  );

  if (!isAdmin) {
    return (
      <PageLayout>
        <div className={style.parentWrapper}>{content}</div>
      </PageLayout>
    );
  }
  return <div style={{ background: "var(--grey3" }}>{content}</div>;
};
