import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './TopBar.css';

const TopBar = () => {
  const [isVisible, setIsVisible] = useState(true);
  const location = useLocation();

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const isPlansPage = location.pathname === '/plans';

  return !isPlansPage && isVisible ? (
    <div className="top-bar">
      <span className="free-plan-message">
        Free plans are limited to one project, up to 2 files, and limited AI requests.
      </span>
      <div className="upgrade-button">
        <div className='upgrade-text'>
        <Link to="/plans" className="upgrade-now">
          Upgrade now
        </Link>
        </div>
      </div>
      <div className="cross-icon" onClick={toggleVisibility}>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
          <path d="M18.8774 10.7383L10.3519 19.2638" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M10.3531 10.7383L18.8786 19.2638" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </div>
    </div>
  ) : null;
};

export default TopBar;
