import React from "react";
import { BsStars } from "react-icons/bs";
import { modalActions } from "../../../store/slices/modalSlice";
import { useDispatch } from "react-redux";
import styles from "./aiTagsModal.module.css";


function AITagsButton() {
  const dispatch = useDispatch();

  return (
    <button
      className={`${styles.aiTagBtn} btn`}
      style={{ color: "var(--pink)", marginLeft: "auto" }}
      onClick={() => {
        dispatch(modalActions.showAITagModal());
      }}
    >
      <BsStars /> Tag with AI
    </button>
  );
}

export default AITagsButton;
