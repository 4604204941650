import React, { useEffect } from "react";
import styles from "./index.module.css";
import { Button } from "react-bootstrap";
import { FaPlus } from "react-icons/fa6";
import { modalActions } from "../../../../store/slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import AddNotetakerModal from "./addNotetakerModal";
import { MdDeleteOutline } from "react-icons/md";
import meet from "../../../../images/meet.svg";
import teams from "../../../../images/teams.svg";
import zoom from "../../../../images/zoom.svg";
import {
  deleteMeeting,
  fetchMeetingsByProject,
} from "../../../../store/actions/virtualParticipantActions";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

function Notetaker() {
  const dispatch = useDispatch();
  const { project } = useSelector((state) => state.currentProject);
  const meetings = useSelector(
    (state) => state.virtualParticipantMeeting.meetings
  );

  const { account } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchMeetingsByProject(project._id));
  }, [dispatch, project]);

  function handleAddNotetaker() {
    if (account?.plan?.type === "Free" || account?.plan?.type === "Basic Monthly"||account?.plan?.type==="Basic Yearly") {
      dispatch(modalActions.showPayWallModal(true));
      return;
    }
    dispatch(modalActions.showNotetakerModal());
  }

  return (
    <div className={styles.notetaker_container}>
      <AddNotetakerModal />
      <h2 className={styles.notetaker_title}>
        Auto-record meetings with User Evaluation’s Notetaker
      </h2>
      <p className={styles.notetaker_description}>
        If you’re conducting calls on Google Meet, Microsoft Teams or Zoom, let
        User Evaluation’s Meeting Notetaker record your upcoming meetings and
        add them to your projects automatically. All you need to do is to
        provide the meeting link, date and time. We’ll take care of the rest.
      </p>
      <div className={styles.notes_container}>
        {meetings.map((meeting, index) => {
          return (
            <div key={index} className={styles.notetaker_note__info}>
              <div className={styles.notetaker_note}>
                {meeting?.platform === "meet" && (
                  <img src={meet} alt="" className={styles.platform_icon} />
                )}
                {meeting?.platform === "teams" && (
                  <img src={teams} alt="" className={styles.platform_icon} />
                )}
                {meeting?.platform === "zoom" && (
                  <img src={zoom} alt="" className={styles.platform_icon} />
                )}
                <div>
                  <h3>
                    Meeting at{" "}
                    {dayjs(meeting?.meetingDateTime).format("hh:mm a")} on{" "}
                    {dayjs(meeting?.meetingDateTime).format("MMMM D, YYYY")}
                  </h3>
                  <div>{meeting?.meetingUrl}</div>
                </div>
              </div>
              <button
                className={styles.note_delete}
                onClick={() => {
                  dispatch(deleteMeeting(meeting?._id));
                }}
              >
                <MdDeleteOutline />
              </button>
            </div>
          );
        })}
      </div>
      <Button
        variant="primary"
        className={styles.notetaker_action__btn}
        onClick={handleAddNotetaker}
      >
        <FaPlus />
        Add Notetaker to Meeting
      </Button>
    </div>
  );
}

export default Notetaker;
