import React, { useEffect } from "react";

import { BoardTab } from "../../../components/board/board";
import { useSelector } from "react-redux";
import {
  getTagNames,
  getTagsByProjectId,
} from "../../../store/actions/tagsActions";
import { Spinner } from "react-bootstrap";
import { useState } from "react";
import EmptyState from "./emptyState";

function Tags({newProject}) {
  const { tags } = useSelector((state) => state.tags);
  const [loading, setLoading] = useState(true);
  const projectData = useSelector((state) => state.currentProject.project);
  const [tagNames, setTagNames] = useState([]);
  useEffect(() => {
    getTagsByProjectId(projectData._id);
    setLoading(false);
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const newTagNames = await getTagNames(projectData._id);
      setTagNames(newTagNames);
    };

    fetchData();
  }, [projectData._id, tags]);
  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          {tags?.length === 0 ? (
            <>
              <EmptyState />
            </>
          ) : (
            <BoardTab
              newProject={newProject}
              projectData={projectData}
              isTag={true}
              tags={tags}
              tagNames={tagNames}
            />
          )}
        </>
      )}
    </>
  );
}

export default Tags;
