import store from "..";
import { noteActions } from "../slices/noteSlice";
import { callExternalApi } from "../../services/external-api.service";
import { toastError, toastSuccess } from "../../utils/toast";

const { dispatch } = store;

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

// load notes by project id
export const getNotesByProjectIdAction = async (projectId) => {
  dispatch(noteActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/note/project/${projectId}`,
    method: "GET",
  };
  const { data, error } = await callExternalApi({ config });

  if (error) dispatch(noteActions.requestFail(error.message));
  else dispatch(noteActions.getNotesByProjectId({ notes: data, projectId }));
};

// create note
export const createNoteAction = async (userId, projectId) => {
  dispatch(noteActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/note/create`,
    method: "POST",
    data: { userId, projectId }
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    dispatch(noteActions.requestFail(error.message));
    toastError(error.message);
  } else {
    dispatch(noteActions.createNote(data));
    toastSuccess("Page created successfully!");
  }
};

// delete note
export const deleteNote = async (noteId) => {
  dispatch(noteActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/note/${noteId}`,
    method: "DELETE",
  };
  const { error } = await callExternalApi({ config });

  if (error) {
    dispatch(noteActions.requestFail(error.message));
    toastError(error.message);
  } else {
    dispatch(noteActions.deleteNote(noteId));
    toastSuccess("Page deleted");
  }
};


// Get a single note
export const getNote = async (noteId) => {
  const config = {
    url: `${apiServerUrl}/api/note/${noteId}`,
    method: "GET",
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error
  };
};


// Get all notes by projectId
export const getNotesByProjectId = async (projectId) => {
  const config = {
    url: `${apiServerUrl}/api/note/project/${projectId}`,
    method: "GET",
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error
  };
};


// Update a specific note
export const updateNote = async (noteId, dataToUpdate) => {
  const config = {
    url: `${apiServerUrl}/api/note/${noteId}`,
    method: "PUT",
    data: dataToUpdate
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error
  };
};

// OpenAI note completion
export const openAINoteCompletion = async (payload) => {
  const config = {
    url: `${apiServerUrl}/api/note/completion`,
    method: "POST",
    data: payload
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error
  };
}