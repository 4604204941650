import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Emoji } from 'emoji-picker-react';
import { useHistory, useParams } from "react-router-dom";

import { toastError, } from "../../../../utils/toast";
import truncateString from "../../../../utils/truncateString";
import { createNoteAction, deleteNote, getNotesByProjectIdAction } from "../../../../store/actions/noteActions"
import plusSquareIcon from "../../../../images/icons/plus-square.svg";
import styles from './style.module.css'

function SingleNoteItem({page, onClick, onDelete}) {
  return <li className={styles.listItem}>
  <span
    onClick={onClick}>
    <Emoji unified={page.emoji} size="20" /> {truncateString(page.title, 26)}
  </span>
  <span onClick={onDelete}>
    {/* material delete */}
    <i className={`material-icons ${styles.deleteIcon}`}>delete</i>
  </span>
</li>
}

export const PagesExpandable = ({
  userId,
}) => {
  const { isAuthenticated } = useSelector(state => state.auth)
  const { notes, error, ...noteSlice } = useSelector(state => state.note)
  const [expanded, setExpanded] = useState(true);
  const history = useHistory();

  const params = useParams();

  let projectId = params.projectId
  const setCurrentNotePage = noteId => {
    history.push(`/p/${projectId}/notes/${noteId}`)
  }

  useEffect(() => {
    if (!isAuthenticated) return
    if (!projectId) return
    if(noteSlice.projectId === projectId) return ;
    getNotesByProjectIdAction(projectId)
      .catch(err => toastError('Error loading your notes.'));
  }, [isAuthenticated, projectId, noteSlice.projectId]);


  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const arrowStyle = {
    display: 'inline-block',
    transform: `rotate(${expanded
      ? '90'
      : '0'}deg)`
  }

  const noPagesLabel = notes.length === 0 ? <li>No pages created</li>:null;

  function handleDeleteNote(pageId){
    const urlParts = window.location.href.split('/')
    const currentPageNoteId = urlParts[urlParts.length-1];
  

    if(pageId === currentPageNoteId) {
      history.push(`/p/${projectId}`)
    }
    deleteNote(pageId);
  }

  return (
    <div className="pages-link-group">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <span onClick={handleExpand}>
          <span style={arrowStyle}>&#x276F;</span>&nbsp;&nbsp;
          <span
            className={styles.title}>Pages</span>
        </span>
        <span onClick={() => {
          createNoteAction(userId, projectId)
            .then(() => {
              setExpanded(true);
            })
        }}><img
          src={plusSquareIcon}
          alt="add-page"
          className={styles.addIcon} /></span>
      </div>
      <ul className="pages-link-list">

        {notes.length > 0 && expanded && notes.map((page) => (
            <SingleNoteItem 
              key={page._id} 
              page={page} 
              onClick={() =>  setCurrentNotePage(page._id)} 
              onDelete={() => handleDeleteNote(page._id)} 
            />
          ))
        }

        {noPagesLabel}
      
        </ul>
    </div>
  );
}