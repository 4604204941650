import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    users: [],
    participants: [],
    loading: null,
    error: null,
    profileEmail: null,
  },
  reducers: {
    setProfile(state, action) {
      state.profileEmail = action.payload;
    },
    requestStart(state, action) {
      state.loading = true;
      state.error = null;
    },
    setUsers(state, action) {
      state.users = action.payload;
    },
    setParticipants(state, action) {
      state.participants = action.payload;
    },
    removeParticipants(state, action) {
      state.participants = state.participants.filter(
        (participant) => participant._id !== action.payload
      );
    },
    requestFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    requestSuccess(state) {
      state.loading = false;
      state.error = null;
    },
    removeUser(state, action) {
      state.users = state.users.filter((user) => user._id !== action.payload);
    },
    verifyUser(state, action) {
      const { userId, type } = action.payload;
      let val = {};
      if (type === "email") {
        val = { email_verified: true };
      } else if (type === "phone") {
        val = { phone_verified: true };
      }
      state.users = state.users.map((user) =>
        user._id === userId ? { ...user, ...val } : user
      );
    },
    changeUserPlan(state, action) {
      state.users = state.users.map((user) =>
        user._id === action.payload.userId
          ? { ...user, plan: action.payload.plan }
          : user
      );
    },
    makeUserAdmin(state, action) {
      state.users = state.users.map((user) =>
        user._id === action.payload.userId
          ? { ...user, roles: [...user.roles, "admin"] }
          : user
      );
    },
    removeAdmin(state, action) {
      state.users = state.users.map((user) =>
        user._id === action.payload.userId
          ? {
              ...user,
              roles: user.roles.filter((role) => role !== "admin"),
            }
          : user
      );
    },

    degradeUser(state, action) {
      state.users = state.users.map((user) =>
        user.email === action.payload
          ? { ...user, degradedModel: !user.degradedModel }
          : user
      );
    },
  },
});

export default adminSlice;
export const adminActions = adminSlice.actions;
