import React, { useState, useCallback, useMemo, useEffect } from "react";
import TopBar from "../topbar";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Button } from "@mantine/core";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { toastSuccess, toastError } from "../../../../utils/toast";
import {
  addVideoToProject,
  getProject,
  loadProjectsAction,
} from "../../../../store/actions/projectActions";
import importIcon from "../../../../images/icons/upload-cloud.svg";
import { fileTypeWhitelist } from "../new-asset/constants";
import styles from "./index.module.css";
import "./index.css";
import Footer from "./Footer";
import { loadAssets } from "../../../../store/actions/mediaLibraryActions";
import BrowseFileLib from "./BrowseFilesLib";
import { debounce } from "lodash";
// import { Link } from "react-router-dom";
import { FiFileText } from "react-icons/fi";
import { LuFileVideo, LuFileAudio } from "react-icons/lu";
import { TbTable } from "react-icons/tb";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Notetaker from "./notetaker";
import { modalActions } from "../../../../store/slices/modalSlice";
import TopNav from "../topNav";
import RenderFiles from "./renderFiles";

// DROPZONE STYLING
const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function Upload({ match }) {
  const tab = match.params.tab || "upload";
  const { account } = useSelector((state) => state.auth);
  const [files, setFiles] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const { project } = useSelector((state) => state.currentProject);
  const [activeTab, setActiveTab] = useState(tab);

  const history = useHistory();
  const dispatch = useDispatch();
  const isFree = account?.plan?.type === "Free";
  let projectId = project?._id;
  const isEmpty = project?.videos?.length === 0;

  const helpTextDefault = `${
    isEmpty ? "Your project looks empty." : ""
  } Upload your files below to get started.`;
  useEffect(() => {
    history.push(`/project/${projectId}/${activeTab}`);
  }, [activeTab]);

  const runGetProjectData = async (id, isMounted, setErrorPage) => {
    let projectReady = false;

    while (!projectReady) {
      let { data, error } = await getProject({ id });

      if (!isMounted) {
        return;
      }
      if (data) {
        projectReady = true;
      } else if (error) {
        console.error(error);
        setErrorPage(true);
      }
    }
  };
  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (account?.plan?.type === "Free" && acceptedFiles.length > 2) {
        toastError(
          "You can only upload two files per project in the free plan. Please upgrade to a paid plan to upload more files."
        );
        return;
      } else {
        // push accepted files to files array and set status to Waiting...

        acceptedFiles.forEach((file) => {
          setFiles((prev) => [...prev, { file, status: "Waiting...", id: "" }]);
        });

        // Loop through the accepted files and upload them one by one
        for (let i = 0; i < acceptedFiles.length; i++) {
          // Set the current file
          let file = acceptedFiles[i];

          // Check if file size is larger than 500MB
          if (file.size > 500 * 1024 * 1024) {
            // Update the status message for the current file
            let statusMessage = "Error";
            setFiles((prev) =>
              prev.map((fileData) => {
                if (fileData.file.name === file.name) {
                  return { file: fileData.file, status: statusMessage };
                }
                return fileData;
              })
            );

            // Show an error toast
            toastError(
              `Your file ${file.name} is larger than 500MB. Please select a smaller file or use a file compression tool to reduce size.`
            );

            // remove the file from the files array
            const newFiles = files.map((fileData) => {
              if (fileData.file.name !== file.name) {
                return fileData;
              }
            });
            setFiles(newFiles);

            continue;
          }

          // const allowedFileTypes = ["mp4", "webm", "amr", "flac", "wav", "ogg", "mp3", "txt", "audio/mpeg", "audio/ogg", "audio/wav", "audio/flac", "audio/amr", "audio/mp3", "video/mp4", "video/webm", "text/plain"];
          let allowedFileTypes = [
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/pdf",
            "text/plain",
            "text/csv",
            "3ga",
            "8svx",
            "aac",
            "ac3",
            "aif",
            "aiff",
            "alac",
            "amr",
            "ape",
            "au",
            "dss",
            "flac",
            "flv",
            "x-m4a",
            "m4a",
            "m4b",
            "m4p",
            "m4r",
            "mp3",
            "mpga",
            "ogg",
            "oga",
            "mogg",
            "opus",
            "qcp",
            "tta",
            "voc",
            "wav",
            "wma",
            "wv",
            "webm",
            "MTS",
            "M2TS",
            "TS",
            "mov",
            "mp2",
            "mp4",
            "mov",
            "m4p",
            "m4v",
            "mxf",
            "audio/mpeg",
            "audio/m4a",
            "audio/mp4",
            "audio/x-m4a",
            "audio/ogg",
            "audio/wav",
            "audio/flac",
            "audio/amr",
            "audio/mp3",
            "video/mp4",
            "video/webm",
            "video/quicktime",
          ];

          // Check if file type is not a video, audio, or text file

          if (!allowedFileTypes.includes(file.type)) {
            // Update the status message for the current file
            let statusMessage = "Error";
            setFiles((prev) =>
              prev.map((fileData) => {
                if (fileData.file.name === file.name) {
                  return { file: fileData.file, status: statusMessage };
                }
                return fileData;
              })
            );

            // Show an error toast
            toastError(
              `File ${file.name} is not a supported file type. Please select a valid audio, video, text or CSV file.`
            );

            // remove the file from the files array
            const newFiles = files.map((fileData) => {
              if (fileData.file.name !== file.name) {
                return fileData;
              }
            });
            setFiles(newFiles);

            continue;
          }

          // Create a new form to handle the request
          let form = new FormData();

          // update status on file
          let statusMessage = "Uploading...";
          setFiles((prev) =>
            prev.map((fileData) => {
              if (fileData.file.name === file.name) {
                return { file: fileData.file, status: statusMessage };
              }
              return fileData;
            })
          );

          form.append("user", account.email);
          // Add the file to the form data
          form.append("file", file);

          try {
            // Upload the file to the server using axios
            // use REACT_APP_API_SERVER_URL
            let response = await axios.post(
              process.env.REACT_APP_API_SERVER_URL + "/api/video/upload",
              form
            );

            // Check the status code of the response
            if (response.status !== 200) {
              throw new Error(response.statusText);
            }

            // Get the response data and add _id to files array
            let { data } = response;

            // Clear the form object

            // Update the status message for the current file
            let statusMessage = "Uploaded";
            setFiles((prev) =>
              prev.map((fileData) => {
                if (fileData.file.name === file.name) {
                  return {
                    file: fileData.file,
                    status: statusMessage,
                    id: data._id,
                  };
                }
                return fileData;
              })
            );

            // upon success
            toastSuccess(`Your file has been uploaded: ${file.name}`);
            loadAssets();
          } catch (error) {
            // Update the status message for the current file
            let statusMessage = "Error";
            setFiles((prev) =>
              prev.map((fileData) => {
                if (fileData.file.name === file.name) {
                  return { file: fileData.file, status: statusMessage };
                }
                return fileData;
              })
            );

            toastError(
              `An error occurred while uploading ${file.name}. ` +
                error?.response?.data?.message
            );
          }
        }
      }
    },
    [account, files]
  );

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isDragActive,
  } = useDropzone({ onDrop, accept: fileTypeWhitelist });

  const style = useMemo(
    () => ({
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  const uploadView = (
    <div className={styles.uploadContainer}>
      <h4 className="headingPara mb-2">Upload files to get started</h4>
      <p className="para mb-2">{helpTextDefault}</p>
      {isFree && (
        <p className="lighterPara mb-2">
          Your free plan is limited to 2 file uploads
        </p>
      )}
      <div
        {...getRootProps({ style })}
        className={
          " my-2 project-dropzone " + `${styles.dropZone} project-dropzone-new`
        }
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <div className="text-center">
            <img
              src={importIcon}
              alt="Import icon"
              className={"project-dropzone-image"}
            />
            <br />
            <br />
            <h4 className="project-dropzone-heading">Drop files here</h4>
            <p className="project-dropzone-subheading">
              You can drop multiple files.
            </p>
          </div>
        ) : (
          <div className="text-center ">
            <div className="project-dropzone-icons my-4">
              <div className="project-dropzone-icon">
                <FiFileText />
              </div>
              <div className="project-dropzone-icon">
                <LuFileVideo />
              </div>
              <div className="project-dropzone-icon">
                <TbTable />
              </div>
              <div className="project-dropzone-icon">
                <LuFileAudio />
              </div>
            </div>

            <h4 className="project-dropzone-heading-new">
              Drag and drop your files here or click to browse
            </h4>
            <p className="project-dropzone-subheading">
              Audio, Video, Text and CSV files allowed. Max 500 MB.
            </p>
          </div>
        )}
      </div>

      {/* display uploaded files */}
      <RenderFiles
        files={files}
       
        setFiles={setFiles}
      />
    </div>
  );

  const isAddButtonDisabled = function () {
    if (files.length === 0 && selectedAssets.length === 0) {
      return true;
    }
    const someFilesUploading = files.some(
      (file) => file.status === "Uploading..."
    );
    // return someFilesUploading
    if (someFilesUploading) {
      return true;
    }
    return false;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // check if there are any files in the files state
    if (
      project?.videos?.length === 0 &&
      (files.length >= 1 || selectedAssets.length >= 1)
    ) {
      dispatch(modalActions.showOnboardingModal());
    }
    if (files.length === 0 && selectedAssets.length === 0) {
      toastError(
        "Please upload at least one file, or select one from files library"
      );
      return;
    }

    let payload = {
      projectId: projectId,
      userEmail: account.email,
      videos: files.map((file) => file.id),
    };

    if (selectedAssets) {
      payload.videos = [...payload.videos, ...selectedAssets];
    }

    try {
      // Upload the file to the server using sevice
      let { data, error } = await addVideoToProject(payload);

      if (error) {
        toastError(error.message);
        return;
      }
      if (data) {
        toastSuccess(`Your file(s) have been added to the project`);
        history.push(`/project/${projectId}`);
        if (runGetProjectData) {
          runGetProjectData(projectId, true);
        }

        // Load all the projects again so that data is refreshed in the homepage when the asset is uploaded
        loadProjectsAction(account.email);
      }
    } catch (error) {
      toastError(
        `An error occurred while adding file(s) to the project. ` +
          error?.response?.data?.message
      );
    }
  };

  return (
    <div>
     
      <TopBar
        name={project?.name}
        language={project?.language}
        projectId={project?._id}
        isUpload={true}
      />
      <div className="mt-2">
        <TopNav
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          tabs={[
            { name: "Upload Files", value: "upload" },
            { name: "Browse Files Library", value: "library" },
            { name: "Meeting Notetaker", value: "notetaker" },
          ]}
          type={"files"}
          projectId={projectId}
        />
      </div>

      {projectId && (
        <Tabs color="blue" value={activeTab}>
          <Tabs.Panel value="notetaker" pt="xs">
            <Notetaker />
          </Tabs.Panel>
          <Tabs.Panel value="upload" pt="xs">
            <div className={styles.container}>{uploadView}</div>
          </Tabs.Panel>

          <Tabs.Panel value="library" pt="xs">
            <div className={styles.browseFileLibContainer}>
              <h4 className="headingPara">Add files to get started</h4>
              <p className="para">{helpTextDefault}</p>
              {isFree && (
                <p className="lighterPara">
                  Your free plan is limited to 2 file uploads
                </p>
              )}
              <BrowseFileLib
                selected={selectedAssets}
                onChange={setSelectedAssets}
              />
            </div>
          </Tabs.Panel>
        </Tabs>
      )}
      <Footer
        handleSubmit={handleSubmit}
        isAddButtonDisabled={isAddButtonDisabled()}
      >
        <Button
          style={{ color: "#fff", borderRadius: "0.5rem" }}
          variant="filled"
          onClick={debounce(handleSubmit, 1000)}
          // disable button if no files are uploaded or if files are uploading
          disabled={isAddButtonDisabled()}
        >
          Add to Project
        </Button>
      </Footer>
    </div>
  );
}

export default Upload;
