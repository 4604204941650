import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Container,
  Button,
  Row,
  Col,
  Nav,
  Spinner,
  Modal,
  Dropdown,
  Form,
} from "react-bootstrap";
import { Emoji } from "emoji-picker-react";
import Player from "../../../../../components/player";
import {
  regenerateInsight,
  // updateInsight,
  deleteInsights,
  deleteVideoById,
  getAssetById,
} from "../../../../../store/actions/projectActions";

import {
  createLaneAction,
  createCardAction,
  loadLanesAction,
} from "../../../../../store/actions/laneActions";

import EditVideoTitleModal from "./EditVideoTitleModal";
import { TranscriptPrinter } from "../../transcript-printer";
import { RAIDisclaimer } from "../../../../../components/rai-disclaimer";

import { FiPlusCircle, FiEdit2, FiPlayCircle } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import moreIcon from "../../../../../images/icons/more-horizontal-white.svg";
import moreIconLightMode from "../../../../../images/icons/more-horizontal.svg";

import { toastError, toastSuccess } from "../../../../../utils/toast";
import formatDuration from "../../../../../utils/formatDuration";
import CsvTable from "./CsvTable";
import "./index.css";
import { currentProjectActions } from "../../../../../store/slices/currentProject";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import formatTime from "../../../../../utils/formatTime";
import HighlightIcon from "./highlightIcon";
import AudioPlayer from "../../../../../components/audio-player";
import { mapTabWithLastWords } from "./mapTabWIthLastWords";
import DeleteBtn from "./deleteInsight";
import CollectedBtn from "./collectedBtn";
let timeFull = 40;
let speakers = [];

const promptLibrary = [
  {
    title: "Opportunity Areas",
    emojiUnified: "1f4a1",
    description: "Understand what people are looking for",
  },
  {
    title: "Jobs To Be Done",
    emojiUnified: "1f4cb",
    description: "Quickly identify the jobs to be done",
  },
  {
    title: "Bug Reports",
    emojiUnified: "1f41b",
    description: "Identify bugs reported in the sessions",
  },
  {
    title: "Feature Requests",
    emojiUnified: "1f680",
    description: "Identify features requested in the sessions",
  },
  {
    title: "Opposing Views",
    emojiUnified: "1f4a2",
    description: "Understand the opposing views in the session",
  },
  {
    title: "Quotable Quotes",
    emojiUnified: "1f4ac",
    description: "Get a list of quotes from the session",
  },
  {
    title: "Topics",
    emojiUnified: "1f4c8",
    description: "Understand the topics discussed in the session",
  },
  {
    title: "Questions for Next Session",
    emojiUnified: "1f914",
    description: "This will tell you what questions you can ask next",
  },
  {
    title: "Key Insights",
    emojiUnified: "1f4d8",
    description: "Get a clear summary of the session",
  },
  {
    title: "Pain Points",
    emojiUnified: "1f62c",
    description: "Understand the pain points of session participants",
  },
  {
    title: "Delighters",
    emojiUnified: "1f389",
    description: "Understand what caught participants eyes",
  }
];

export const InsightsTab = ({
  selectedVideo,
  projectData,
  runGetProjectData,
  selectedInsightTab,
  setSelectedVideo,
  setSelectedInsightTab,
  selectedTimestamps,
  setSelectedTimestamps,
}) => {
  const [regenerateLoading, setRegenerateLoading] = useState(false);
  const [currentPlayerTime, setCurrentPlayerTime] = useState(0);
  const [showAIInsights, setShowAIInsights] = useState(false);
  const [showEditTitle, setShowEditTitle] = useState(false);
  const [showDeleteVideo, setShowDeleteVideo] = useState(false);
  const [allSpeakers, setAllSpeakers] = useState([]);
  const [insightGenerationLoading, setInsightGenerationLoading] =
    useState(false);
  const [deleteConfirmationInsights, setDeleteConfirmationInsights] = useState(
    {}
  );
  const [showTag, setShowTag] = useState(true);
  const [showInsight, setShowInsight] = useState(false);
  const [showSources, setShowSources] = useState(true);
  const [insightLoadingStates, setInsightLoadingStates] = useState({});
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { darkMode } = useSelector((state) => state.theme);
  const { lanes } = useSelector((state) => state.lanes);
  let laneCards = lanes?.map((lane) => lane.cards).flat();

  const history = useHistory();
  const location = useLocation();
  // get the seleted insight tab from the url
  // const urlParams = new URLSearchParams(location.search);
  const parts = location?.pathname?.split("/");
  const lastWord = parts[parts.length - 1];

  useEffect(() => {
    if (projectData?._id) {
      dispatch(loadLanesAction(projectData._id));
    }
  }, [projectData?._id]);

  useEffect(() => {
    mapTabWithLastWords(lastWord, setSelectedInsightTab);
  }, [lastWord]);

  const dispatch = useDispatch();
  const videoRef = useRef();

  // let extension = getExtension(selectedVideo.link);
  let isVideo = selectedVideo.assetType === "VIDEO";
  let isAudio = selectedVideo.assetType === "AUDIO";

  // What i want to do now, is whenever the videoplayer time changes, i want to set the currentPlayerTime to that.
  const handleSeek = (time) => {
    videoRef.current.pause();
    if (videoRef.current) {
      videoRef.current.currentTime = time / 1000; // Convert to seconds
    }
    var timer = setInterval(function () {
      if (
        (videoRef.current.paused && videoRef.current.readyState == 4) ||
        !videoRef.current.paused
      ) {
        videoRef.current.play();
        clearInterval(timer);
      }
    }, 50);
  };
  if (isVideo || isAudio) {
    speakers = createVideoSpeaker(selectedVideo, darkMode, allSpeakers);
  }

  useEffect(() => {
    async function getSpeakers() {
      if (isVideo || isAudio) {
        const { data } = await getAssetById(selectedVideo._id);
        if (data) {
          setAllSpeakers(data.speakers);
        }
      }
    }
    getSpeakers();
  }, [selectedVideo._id]);

  const deleteInsightBySession = async (insightId, videoId) => {
    if (!isAuthenticated) return;
    let { data, error } = await deleteInsights(insightId, videoId);

    if (data) {
      runGetProjectData(projectData._id, true);
      // go to transcript tab
      setSelectedInsightTab("Transcript");
      toastSuccess("Insight deleted");
    }

    if (error) {
      console.error(error);
    }
  };

  const regenerateInsightBySession = async (insightType, videoId) => {
    setRegenerateLoading(true);
    if (!isAuthenticated) return;

    let { data, error } = await regenerateInsight({
      projectId: projectData._id,
      insightType,
      videoId,
    });

    if (data) {
      runGetProjectData(projectData._id, true);
      setRegenerateLoading(false);
      toastSuccess("Your new AI insights have been regenerated successfully");
    }

    if (error) {
      console.error(error);
      toastError(error?.message);
      setRegenerateLoading(false);
    }
  };

  const handleHideAIInsights = () => {
    setShowAIInsights(false);
  };

  const handleAddAIInsight = async (insightType) => {
    setInsightLoadingStates((prevState) => ({
      ...prevState,
      [insightType]: true,
    }));
    setInsightGenerationLoading(true);

    let payload = {
      projectId: projectData._id,
      insightType,
      videoId: selectedVideo._id,
    };

    if (!isAuthenticated) return;
    let { data, error } = await regenerateInsight(payload);

    if (data) {
      toastSuccess("AI Insight added successfully");
      await runGetProjectData(projectData._id, true);
      setShowAIInsights(false);
      setInsightLoadingStates((prevState) => ({
        ...prevState,
        [insightType]: false,
      }));
      setInsightGenerationLoading(false);
    }

    if (error) {
      console.error(error);
      setInsightLoadingStates((prevState) => ({
        ...prevState,
        [insightType]: false,
      }));
      setInsightGenerationLoading(false);
    }
  };

  const updateVideoTitle = (title) => {
    dispatch(
      currentProjectActions.setAssetTitle({
        asset: { _id: selectedVideo._id, title },
      })
    );
    // runGetProjectData(projectData._id, true);
    setShowEditTitle(false);
  };

  const handleDeleteVideo = async (videoId) => {
    if (!isAuthenticated) return;
    let { data, error } = await deleteVideoById(videoId, projectData._id);

    if (data) {
      // Go back to project main page
      history.push(`/p/${projectData._id}`);
      runGetProjectData(projectData._id);
    }

    if (error) {
      console.error(error);
    }
  };

  const isDocument =
    selectedVideo &&
    ["TEXT", "PDF", "DOC", "CSV", "LIVECHAT"].includes(selectedVideo.assetType);
  const isCSV = selectedVideo.assetType === "CSV";
  const isPDF_DOC_TEXT = ["TEXT", "PDF", "DOC", "LIVECHAT"].includes(
    selectedVideo.assetType
  );

  const collectInsight = async (insight, singleInsight, videoId) => {
    let uncategorizedLaneId;

    let uncategorizedLane =
      lanes && lanes.find((lane) => lane.title === insight?.insightType);

    if (uncategorizedLane === undefined) {
      const newLane = await dispatch(
        createLaneAction(insight?.insightType, projectData._id)
      );
      uncategorizedLaneId = newLane._id;
    } else {
      uncategorizedLaneId = uncategorizedLane._id;
    }

    // in type also add the name of the video

    const payload = {
      laneId: uncategorizedLaneId,
      insight: singleInsight.insightText,
      source: singleInsight.source,
      type: [selectedVideo.name],
      projectId: projectData._id,
      notes: "",
    };

    await dispatch(createCardAction(payload));
  };
  if (selectedVideo === null) return "";

  let timeStamps = [];
  getTimeStampForInsight(
    selectedVideo.insights,
    selectedVideo.transcript,
    timeStamps,
    isPDF_DOC_TEXT
  );
  return (
    <Container
      className="mt-4"
      style={{
        maxWidth: isCSV ? "" : "1000px",
        margin: "0 auto",
      }}
    >
      {selectedVideo && (
        <Row className={isVideo && `project-video-row`}>
          {!isDocument && (
            <Col className="project-video-col">
              {isVideo ? (
                <Player
                  file={selectedVideo}
                  currentPlayerTime={currentPlayerTime}
                  setCurrentPlayerTime={setCurrentPlayerTime}
                  videoRef={videoRef}
                  onTimeUpdate={(e) =>
                    setCurrentPlayerTime(e.target?.currentTime)
                  }
                  isVideo={isVideo}
                />
              ) : (
                <AudioPlayer
                  url={selectedVideo.link}
                  setCurrentPlayerTime={setCurrentPlayerTime}
                  videoRef={videoRef}
                />
              )}
            </Col>
          )}
          <Col className="project-video-col">
            <div className="project-video-actions">
              <Button
                variant="secondary"
                onClick={() => setShowEditTitle(true)}
              >
                <FiEdit2 />
                Edit Title
              </Button>
              &nbsp;
              <Button
                variant="secondary"
                onClick={() => setShowDeleteVideo(true)}
              >
                <RiDeleteBinLine />
                Remove File
              </Button>
            </div>

            <h5 className={`${(isVideo || isAudio) && "smallerFontSize"}`}>
              {selectedVideo.name}{" "}
              <span className="timings">
                {!isDocument && formatDuration(selectedVideo.duration)}
              </span>
            </h5>
            <Modal
              show={showDeleteVideo}
              onHide={() => setShowDeleteVideo(false)}
              size="md"
            >
              <Modal.Header closeButton>
                <Modal.Title>Remove File</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  Are you sure you want to remove this file from this project?
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowDeleteVideo(false)}
                >
                  Close
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    handleDeleteVideo(selectedVideo._id);
                    setShowDeleteVideo(false);
                  }}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
            <EditVideoTitleModal
              onClose={() => setShowEditTitle(false)}
              show={showEditTitle}
              videoTitle={selectedVideo.name}
              videoId={selectedVideo._id}
              onChange={updateVideoTitle}
            />
            {(isVideo || isAudio) && (
              <div className="speakers-time-graph">
                {speakers?.map((speaker) => {
                  return (
                    <div key={speaker._id}>
                      <p>
                        {speaker.name
                          ? speaker.name
                          : `Speaker ${speaker.speaker}`}
                        {speaker.role?.trim().length > 0
                          ? " (" + speaker.role + ")"
                          : ""}
                      </p>
                      <div className="speaker-speaking-part">
                        {speaker.parts.map((part) => {
                          return (
                            <div
                              onClick={() => {
                                handleSeek(part.start);
                              }}
                              key={part._id}
                              style={{
                                backgroundColor: `${speaker.color}`,
                                width: `${
                                  ((part.end - part.start) / timeFull) * 100
                                }%`,
                                left: `${(part.start / timeFull) * 100}%`,
                              }}
                            ></div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </Col>
        </Row>
      )}

      <Row className="make-gap">
        <Col>
          <Nav
            className="mt-4 mb-4 project-insight-tabs"
            variant="tabs"
            style={{
              borderBottom: "1px solid var(--darkGray)",
            }}
            activeKey={selectedInsightTab}
          >
            {/* nav item with active conditional */}
            <Nav.Item>
              <Nav.Link
                eventKey="Transcript"
                onClick={() => {
                  history.push(
                    `/p/${projectData._id}/asset/${selectedVideo._id}`
                  );
                  setSelectedInsightTab("Transcript");
                }}
              >
                {isDocument ? "Content" : "Transcript"}
              </Nav.Link>
            </Nav.Item>
            {/* map selectedVideo.insights.insightType */}
            {selectedVideo &&
              selectedVideo.insights &&
              selectedVideo.insights.map((insight, index) => (
                <Nav.Item key={index}>
                  <Nav.Link
                    eventKey={insight.insightType}
                    onMouseDown={() => {
                      setSelectedInsightTab(insight.insightType);
                      history.push(
                        `/p/${projectData._id}/asset/${selectedVideo._id}/${insight.insightType}`
                      );
                    }}
                  >
                    {insight.insightType}
                  </Nav.Link>
                </Nav.Item>
              ))}
            <Nav.Item style={{ marginLeft: "auto" }}>
              <Button
                variant="primary"
                onClick={() => setShowAIInsights(true)}
                // disabled if transcript is not available
                disabled={
                  selectedVideo &&
                  selectedVideo.transcript &&
                  selectedVideo.transcript.length === 0
                }
              >
                Add AI Insights
              </Button>
            </Nav.Item>
          </Nav>
          {selectedInsightTab === "Transcript" && !isCSV && (
            <TranscriptPrinter
              showTag={showTag}
              setShowTag={setShowTag}
              showInsight={showInsight}
              setShowInsight={setShowInsight}
              collectInsight={collectInsight}
              selectedTimestamps={selectedTimestamps}
              speakers={allSpeakers}
              setSpeakers={setAllSpeakers}
              isDocument={isDocument}
              selectedVideo={selectedVideo}
              transcriptObj={
                selectedVideo &&
                selectedVideo.transcript &&
                selectedVideo.transcript[0]
              }
              currentWord={currentPlayerTime * 1000}
              projectLang={projectData.language}
              handleSeek={handleSeek}
              assetId={selectedVideo?._id}
            />
          )}
          {selectedInsightTab === "Transcript" && isCSV && (
            <CsvTable
              projectId={projectData._id}
              assetId={selectedVideo._id}
              rawData={selectedVideo?.transcript?.[0]?.text || ""}
            />
          )}

          {selectedInsightTab !== "Transcript" &&
            selectedVideo.insights.length !== 0 &&
            selectedVideo.insights.map((insight, index) => {
              // list of all insights by insightType (selectedInsightTab)
              if (insight.insightType === selectedInsightTab) {
                return (
                  <div key={index}>
                    <RAIDisclaimer />
                    <Row className="mt-3 mb-3">
                      <Col style={{ display: "flex", alignItems: "center" }}>
                        <h5 style={{ marginRight: "20px" }}>
                          {insight.insightType}
                        </h5>
                        <div>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            className="show-sources-switch"
                            label={
                              showSources ? "Hide Sources" : "Show Sources"
                            }
                            checked={showSources}
                            onChange={() => setShowSources(!showSources)}
                          />
                        </div>
                      </Col>

                      <Col style={{ textAlign: "right" }}>
                        <Button
                          variant="primary"
                          size="sm"
                          style={{ float: "right" }}
                          onClick={() =>
                            regenerateInsightBySession(
                              insight.insightType,
                              selectedVideo._id
                            )
                          }
                          disabled={regenerateLoading}
                        >
                          {regenerateLoading ? (
                            <Spinner
                              animation="border"
                              variant="primary"
                              size="sm"
                            />
                          ) : (
                            <span>Regenerate</span>
                          )}
                        </Button>
                        <Dropdown
                          style={{ float: "right", marginRight: "10px" }}
                        >
                          <Dropdown.Toggle
                            variant="secondary"
                            id="dropdown-basic"
                            size="sm"
                          >
                            <img
                              src={darkMode ? moreIcon : moreIconLightMode}
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(event) => {
                                event.stopPropagation();
                                if (
                                  deleteConfirmationInsights[projectData._id]
                                ) {
                                  deleteInsightBySession(
                                    insight.insightType,
                                    selectedVideo._id
                                  );
                                } else {
                                  setDeleteConfirmationInsights({
                                    [projectData._id]: true,
                                  });
                                }
                              }}
                            >
                              {deleteConfirmationInsights[projectData._id] ? (
                                <span style={{ color: "#ff7878" }}>Sure?</span>
                              ) : (
                                <span>Delete Insight Type</span>
                              )}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                    {insight &&
                      insight.insights &&
                      insight.insights.length !== 0 &&
                      insight.insights.map((singleInsight, index) => {
                        let insightType = insight.insightType;
                        let time = null;
                        let normalTime = null;
                        let endTime = null;
                        let isCollected = laneCards.some((card) => {
                          return (
                            card.insight === singleInsight.insightText &&
                            card.source === singleInsight.source
                          );
                        });
                        if (timeStamps[0]) {
                          timeStamps[0][insight.insightType].forEach(
                            (stamp) => {
                              if (stamp["index"] == index) {
                                if (stamp["startTime"]) {
                                  time = stamp["startTime"];
                                  normalTime = time;
                                  endTime = stamp["endTime"];
                                  time = formatTime(time);
                                }
                              }
                            }
                          );
                        }
                        return (
                          singleInsight?.insightText && (
                            <div
                              className={
                                `session-insight-row` +
                                (showSources && ` sources-shown-card`)
                              }
                            >
                              <Row key={index} style={{ padding: "5px" }}>
                                <Col>
                                  <p
                                    className={
                                      singleInsight.visible
                                        ? "insight-text"
                                        : "insight-text-hidden"
                                    }
                                  >
                                    {singleInsight.insightText}&nbsp;
                                  </p>
                                  {/* New source text div */}
                                  {showSources && singleInsight.source && (
                                    <div className="source-text">
                                      Source:{" "}
                                      {singleInsight.source.length > 800
                                        ? singleInsight.source.slice(0, 800) +
                                          "..."
                                        : singleInsight.source}
                                    </div>
                                  )}
                                </Col>
                              </Row>
                              <Row style={{ padding: "5px" }}>
                                <div className="source-tools">
                                  {/* OverlayTrigger with tooltip saying 'Collect Insight' */}
                                  {isCollected ? (
                                    <CollectedBtn
                                      collectInsight={collectInsight}
                                      insight={insight}
                                      singleInsight={singleInsight}
                                      name={selectedVideo?.name}
                                    />
                                  ) : (
                                    <>
                                      <Button
                                        variant="transparent"
                                        onClick={() =>
                                          collectInsight(
                                            insight,
                                            singleInsight,
                                            selectedVideo?.name
                                          )
                                        }
                                      >
                                        <FiPlusCircle className="project-insight-icons" />
                                        <span>Add to Collection</span>
                                      </Button>
                                    </>
                                  )}

                                  {(isVideo || isAudio || isPDF_DOC_TEXT) &&
                                    normalTime && (
                                      <>
                                        <HighlightIcon
                                          index={index}
                                          setSelectedInsightTab={
                                            setSelectedInsightTab
                                          }
                                          normalTime={{
                                            start: normalTime,
                                            end: endTime,
                                          }}
                                        />
                                      </>
                                    )}
                                  {time && (isVideo || isAudio) && (
                                    <>
                                      <Button
                                        onClick={() => {
                                          if (normalTime) {
                                            handleSeek(normalTime);
                                          }
                                        }}
                                        variant="transparent"
                                        size="sm"
                                      >
                                        <FiPlayCircle className="project-insight-icons" />
                                        Play Context
                                      </Button>
                                    </>
                                  )}
                                  <DeleteBtn
                                    singleInsight={singleInsight}
                                    videoId={selectedVideo?._id}
                                    insightType={insightType}
                                  />
                                </div>
                              </Row>
                            </div>
                          )
                        );
                      })}
                  </div>
                );
              }
            })}
        </Col>
      </Row>

      <Modal show={showAIInsights} onHide={handleHideAIInsights} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>AI Insights</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Choose an AI template from the list below and get insights for that
            section.
          </p>
          <Row>
            {promptLibrary.map((prompt, index) => (
              <Col xs={4} className="ai-insight-box-container" key={index}>
                <div className="ai-insight-box">
                  <div className="ai-insight-box-emoji">
                    <Emoji unified={prompt.emojiUnified} size="20" />
                  </div>
                  <p className="ai-insight-box-title">{prompt.title}</p>
                  <p className="ai-insight-box-description">
                    {prompt.description}
                  </p>
                  <Button
                    variant="outline-primary"
                    disabled={
                      selectedVideo.insights.some(
                        (insight) => insight.insightType === prompt.title
                      ) || insightGenerationLoading
                    }
                    onClick={() => handleAddAIInsight(prompt.title)}
                  >
                    {insightLoadingStates[prompt.title] ? (
                      <Spinner animation="border" variant="primary" size="sm" />
                    ) : selectedVideo.insights.some(
                        (insight) => insight.insightType === prompt.title
                      ) ? (
                      "Already in use"
                    ) : (
                      "Use this template"
                    )}
                  </Button>
                </div>
              </Col>
            ))}
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

const mapColortoSpeaker = (speaker, darkMode) => {
  let colors = [];
  if (darkMode) {
    colors = [
      "#004080",
      "#864B86",
      "#403D75",
      "#21662B",
      "#005859",
      "#C74320",
      "#724747",
      "#C0463A",
      "#C06060",
      "#466B47",
      "#FF808B",
      "#853333",
      "#B3A566",
      "#404000",
      "#335A54",
      "#59808E",
      "#559E18",
      "#B3A000",
      "#B33973",
      "#86634B",
      "#105958",
      "#C03221",
      "#216633",
      "#3FCFAF",
      "#C02600",
      "#007D4D",
    ];
  } else {
    colors = [
      "#0085FF",
      "#DDA0DD",
      "#7B68EE",
      "#32CD32",
      "#00CED1",
      "#FF7F50",
      "#BC8F8F",
      "#FA8072",
      "#F08080",
      "#8FBC8F",
      "#FFC0CB",
      "#CD5C5C",
      "#F0E68C",
      "#808000",
      "#66CDAA",
      "#B0E0E6",
      "#ADFF2F",
      "#FFD700",
      "#FF69B4",
      "#D2B48C",
      "#20B2AA",
      "#FF6347",
      "#3CB371",
      "#7FFFD4",
      "#FF4500",
      "#00FA9A",
    ];
  }
  return colors[(speaker.charCodeAt(0) - 65) % colors.length];
};

const createVideoSpeaker = (selectedVideo, darkMode, allSpeakers) => {
  if (
    selectedVideo &&
    selectedVideo.transcript &&
    selectedVideo.transcript[0] &&
    selectedVideo.transcript[0].words &&
    selectedVideo.transcript[0].words.length > 0
  ) {
    let start = selectedVideo.transcript[0]?.words[0]?.start;
    let end = selectedVideo.transcript[0]?.words?.slice(-1)[0].end;
    let duration = end - start;
    timeFull = duration;
    let speakerData = [];
    if (selectedVideo.transcript[0].utterances) {
      selectedVideo.transcript[0].utterances.forEach((utterance) => {
        let speaker = utterance.speaker;
        let start = utterance.start;
        let end = utterance.end;
        let flag = false;
        for (let i = 0; i < speakerData.length; i++) {
          if (speakerData[i].speaker === speaker) {
            flag = true;
            speakerData[i].parts.push({
              start: start,
              end: end,
            });
          }
        }
        if (!flag) {
          speakerData.push({
            speaker: speaker,
            color: mapColortoSpeaker(speaker, darkMode),
            parts: [
              {
                start: start,
                end: end,
              },
            ],
          });
        }
      });
    }

    // merge name of speaker into speakerData from allSpeakers
    if (allSpeakers && allSpeakers.length > 0) {
      speakerData.forEach((speaker) => {
        let spk = allSpeakers.find((spk) => {
          return spk.label === speaker.speaker;
        });
        if (spk) {
          speaker.name = spk.name;
          speaker.role = spk.role;
        }
      });
    }

    let newSpeakerData = [];
    speakerData.forEach((speaker) => {
      let spk = newSpeakerData.find((spk) => {
        if (speaker.name === undefined || speaker.role === undefined) {
          return false;
        }
        return spk.name === speaker.name && spk.role === speaker.role;
      });
      if (spk) {
        spk.parts = spk.parts.concat(speaker.parts);
      } else {
        newSpeakerData.push({
          name: speaker.name,
          role: speaker.role,
          speaker: speaker.speaker,
          color: speaker.color,
          parts: speaker.parts,
        });
      }
    });
    speakerData = newSpeakerData;
    return speakerData;
  }
};

export const getTimeStampForInsight = (
  insight,
  transcript,
  timestamps,
  isPDF_DOC_TEXT
) => {
  if (isPDF_DOC_TEXT && insight && transcript && transcript[0]) {
    let sources = [];
    insight.forEach((insight) => {
      let insightToAdd = {};
      insightToAdd[insight.insightType] = [];
      insight.insights.forEach((singleInsight) => {
        if (singleInsight.source) {
          insightToAdd[insight.insightType].push(singleInsight.source);
        }
      });
      sources.push(insightToAdd);
    });
    if (transcript[0]?.text) {
      let allWords = transcript[0]?.text?.split("\n");
      let timeStampObject = {};
      sources.forEach((source) => {
        Object.keys(source).forEach((key) => {
          timeStampObject[key] = [];
          let sentences = source[key];
          sentences.forEach((sentence, i) => {
            let words = sentence.split(" ");
            let wordIndex = matchStringAndGiveWordIndexForDoc(allWords, words);
            if (wordIndex.paraIndex !== -1) {
              timeStampObject[key].push({
                index: i,
                paraIndex: wordIndex.paraIndex,
                startTime: wordIndex.ind,
                endTime: wordIndex.ind + words?.length,
              });
            }
          });
        });
      });
      timestamps.push(timeStampObject);
    }
  }
  if (insight && transcript && transcript[0]) {
    let sources = [];
    insight.forEach((insight) => {
      let insightToAdd = {};
      insightToAdd[insight.insightType] = [];
      insight.insights.forEach((singleInsight) => {
        if (singleInsight.source) {
          insightToAdd[insight.insightType].push(singleInsight.source);
        }
      });
      sources.push(insightToAdd);
    });
    if (transcript[0].words) {
      let allWords = transcript[0].words.map((word) => {
        return word.text?.toLowerCase();
      });
      let timeStampObject = {};
      sources.forEach((source) => {
        Object.keys(source).forEach((key) => {
          timeStampObject[key] = [];
          let sentences = source[key];
          sentences.forEach((sentence, i) => {
            // remove quotation mark from sentence
            if (
              sentence.length >= 2 &&
              sentence.startsWith("'") &&
              sentence.endsWith("'")
            ) {
              sentence = sentence.slice(1, -1);
            } else if (
              sentence.length >= 2 &&
              sentence.startsWith('"') &&
              sentence.endsWith('"')
            ) {
              sentence = sentence.slice(1, -1);
            }
            let words = sentence?.toLowerCase().split(" ");
            let wordIndex = matchStringAndGiveWordIndex(allWords, words);
            if (wordIndex !== -1) {
              timeStampObject[key].push({
                index: i,
                startTime: transcript[0]?.words[wordIndex]?.start,
                endTime:
                  transcript[0]?.words[wordIndex + words.length - 1]?.end,
              });
            }
          });
        });
      });
      timestamps.push(timeStampObject);
    } else {
      if (transcript[0].text) {
        let allWords = transcript[0].text?.toLowerCase().split(" ");
        let timeStampObject = {};
        sources.forEach((source) => {
          Object.keys(source).forEach((key) => {
            timeStampObject[key] = [];
            let sentences = source[key];
            sentences.forEach((sentence, i) => {
              // remove quotation mark from sentence
              if (
                sentence.length >= 2 &&
                sentence.startsWith("'") &&
                sentence.endsWith("'")
              ) {
                sentence = sentence.slice(1, -1);
              } else if (
                sentence.length >= 2 &&
                sentence.startsWith('"') &&
                sentence.endsWith('"')
              ) {
                sentence = sentence.slice(1, -1);
              }
              let words = sentence.split(" ");
              let wordIndex = matchStringAndGiveWordIndex(allWords, words);
              if (wordIndex !== -1) {
                timeStampObject[key].push({
                  index: i,
                });
              }
            });
          });
        });
        timestamps.push(timeStampObject);
      }
    }
  }
};
const matchStringAndGiveWordIndexForDoc = (mainWords, subWords) => {
  mainWords?.forEach((words, ind) => {
    words = words.split(" ");
    for (let index = 0; index <= words.length - subWords.length; index++) {
      if (arraysEqual(words.slice(index, index + subWords.length), subWords)) {
        return {
          paraIndex: index,
          ind: ind,
        };
      }
    }
  });

  return {
    paraIndex: -1,
    ind: -1,
  };
};
const matchStringAndGiveWordIndex = (mainWords, subWords) => {
  if (subWords.length >= 6) {
    const startPattern = subWords.slice(0, 3);
    const endPattern = subWords.slice(-3);

    for (
      let index = 0;
      index <= mainWords.length - startPattern.length;
      index++
    ) {
      if (arraysEqual(mainWords.slice(index, index + 3), startPattern)) {
        for (
          let endIndex = index;
          endIndex < mainWords.length - endPattern.length + 1;
          endIndex++
        ) {
          if (
            arraysEqual(mainWords.slice(endIndex, endIndex + 3), endPattern)
          ) {
            // Potential match found. This might be the beginning of your subWords in mainWords
            return index;
          }
        }
      }
    }
  } else {
    // If there are less than 6 words, perform the exact match
    for (let index = 0; index <= mainWords.length - subWords.length; index++) {
      if (
        arraysEqual(mainWords.slice(index, index + subWords.length), subWords)
      ) {
        return index;
      }
    }
  }

  return -1;
};

// Utility function to check if two arrays are equal
const arraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
};
