import React from "react";
import { Button } from "react-bootstrap";
import css from "../index.module.css";
import { Link } from "react-router-dom";

const Verify = ({ data, handleCreateAccount }) => {
  const timer = React.useRef();
  const [timeLeft, setTimeLeft] = React.useState(60);

  const callback = () => {
    setTimeLeft(60);
    timer.current = setInterval(() => {
      setTimeLeft((timeLeft) => {
        if (timeLeft === 0) {
          clearInterval(timer.current);
        }

        return timeLeft - 1;
      });
    }, 1000);
  };

  React.useEffect(() => {
    callback();

    return () => {
      clearInterval(timer.current);
    };
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    handleCreateAccount(e, callback);
  };

  return (
    <div className={css.verify}>
      <h2>Verify your email address</h2>
      <p className="mb-5">
        We sent a verification email to&nbsp;
        {data?.email || "your email"}.
      </p>
      <p className="mb-0">
        Didn&apos;t receive the email? If not, please check your spam or
        promotions folder. Contact&nbsp;
        <Link to="/support" target="_blank" rel="noopener noreferrer">
          support
        </Link>
        &nbsp;if you run into any issues.
      </p>
      <form onSubmit={onSubmit}>
        <input type="hidden" name="firstName" value={data?.firstName} />
        <input type="hidden" name="lastName" value={data?.lastName} />
        <input
          type="hidden"
          name="organizationName"
          value={data?.organizationName}
        />
        <input type="hidden" name="email" value={data?.email} />
        <input type="hidden" name="password" value={data?.password} />
        <input type="hidden" name="resendVerification" value={true} />
        <Button className={css.resendBtn} type="submit" disabled={timeLeft > 0}>
          Resend Email {timeLeft > 0 && `(${timeLeft}s)`}
        </Button>
      </form>
    </div>
  );
};

export default Verify;
