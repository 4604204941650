/* eslint-disable no-unused-vars */
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import style from "./aside.module.css";

function Aside({ stepNumber, testId }) {
  const { stepThreeChoice, currentTest } = useSelector((state) => state.test);
  const history = useHistory();

  const stepNames = [
    "Start",
    "Describe",
    "Select or Filter",
    "Screen",
    "Script",
    "Additional Details",
    "Review & Launch",
  ];

  function handleStep(stepNumber) {
    switch (stepNumber) {
      case 1:
        return "/interviews/new";
      case 2:
        return `/interviews/ai-curated-interview/${testId}/describe-test`;
      case 3:
        if (stepThreeChoice === "filter-audience") {
          return `/interviews/ai-curated-interview/${testId}/filter-audience`;
        } else {
          return `/interviews/ai-curated-interview/${testId}/add-participants`;
        }
      case 4:
        return `/interviews/ai-curated-interview/${testId}/screen`;
      case 5:
        return `/interviews/ai-curated-interview/${testId}/script`;
      case 6:
        return `/interviews/ai-curated-interview/${testId}/additional-details`;
      case 7:
        return `/interviews/ai-curated-interview/${testId}/review`;
      default:
        return "/interviews/new";
    }
  }

  return (
    <div className={style.aside}>
      <div className={style.test}>
        <div className={style.testName}>
          {currentTest.title || "New Interview"}
        </div>
        <div className={style.testType}>AI-Curated Interview</div>
      </div>
      <ul className={style.steps}>
        {stepNames.map((stepName, index) => (
          <li
            key={index}
            className={stepNumber >= index + 1 ? style.active : ""}
          >
            <span className={style.number}>
              {stepNumber > index + 1 ? <FaCheck /> : index + 1}
            </span>
            <span
              className={stepNumber >= index + 1 ? style.cursorPointer : ""}
              onClick={() =>
                stepNumber > index + 1
                  ? history.push(handleStep(index + 1))
                  : ""
              }
            >
              {stepName}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Aside;
