/* eslint-disable no-unused-vars */
import React from "react";
import { TestLayout } from "../components/test-layout";
import style from "./index.module.css";
import Form from "react-bootstrap/Form";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import BottomBar from "../components/bottom-bar";
import useSocketContext from "../../../../utils/SocketContext";
import { EMIT_TYPES, EVENT_TYPES } from "../../routes/test-route";
import { toastError } from "../../../../utils/toast";
import { useSelector } from "react-redux";
import { TEMPLATES } from "../../commons/test-templates";

function ACI_DescribeTest({ match }) {
  const socket = useSocketContext();
  const inputRef = React.useRef();
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const { currentTest } = useSelector((state) => state.test);
  const scrollerRef = React.useRef();
  const [selectedTemplate, setSelectedTemplate] = React.useState(0);

  const scrollLeft = () => {
    scrollerRef.current.scrollLeft -= 200;
  };

  const scrollRight = () => {
    scrollerRef.current.scrollLeft += 200;
  };

  React.useEffect(() => {
    if (!socket) return;

    const handler = {
      [EMIT_TYPES.ERROR]: () => {
        setButtonLoading(false);
      },
    };

    socket.attachHandlers(handler);
    return () => {
      socket.detachHandlers(handler);
    };
  }, [socket]);

  return (
    <TestLayout stepNumber={2} testId={match.params.id}>
      <div className={style.wrapper}>
        <div className={style.title}>Describe your interview</div>
        <div className={style.subtitle}>
          Describe what you&apos;d like to test or choose a template
        </div>
        <div className={style.description}>
          Your instructions will be used by User Evaluation to write a research
          script for the AI agent and filters which you will be able to modify.{" "}
        </div>
        <div className={style.main}>
          <div className="position-relative">
            <div
              className={`${style.arrow} ${style.left}`}
              onClick={scrollLeft}
            >
              <div className={style.arrowIcon}>
                <FaAngleLeft />
              </div>
            </div>
            <div className={style.slider} ref={scrollerRef}>
              {TEMPLATES.map(({ icon, name, content }, i) => (
                <div
                  className={`${style.template} ${
                    selectedTemplate === i && style.activeTemplate
                  }`}
                  key={name}
                  onClick={() => {
                    inputRef.current.value = content;
                    setSelectedTemplate(i);
                  }}
                >
                  <div className={style.templateIcon}>{icon}</div>
                  <div className={style.templateName}>{name}</div>
                </div>
              ))}
            </div>
            <div
              className={`${style.arrow} ${style.right}`}
              onClick={scrollRight}
            >
              <div className={style.arrowIcon}>
                <FaAngleRight />
              </div>
            </div>
          </div>

          <Form.Control
            ref={inputRef}
            as="textarea"
            size="sm"
            rows={12}
            defaultValue={currentTest.description || ""}
            className={style.input}
            placeholder="Describe all you want to test and the AI will come up with the interview script for you to edit. Note that the AI can go off script during the interview to dive deep."
          />
        </div>
        <BottomBar
          currentStep={2}
          primaryButton="Generate"
          testId={match.params.id}
          primaryButtonLoading={buttonLoading}
          onPrimaryButtonClick={() => {
            if (!inputRef.current.value) {
              return toastError(
                "Please enter a description for your interview."
              );
            }

            setButtonLoading(true);

            socket.testUpdates(EVENT_TYPES.CREATE_TEST, {
              type: "ai-curated-interview",
              testId: match.params.id === "new" ? null : match.params.id,
              description: inputRef.current.value,
            });
          }}
        />
      </div>
    </TestLayout>
  );
}

export default ACI_DescribeTest;
