import React from "react";
import css from "./index.module.css";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import logo from "../../images/logo-wide.svg";
import logoMob from "../../images/logo-wide-mob.svg";
import tick from "../../images/icons/check-circle.png";
import { useHistory } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { useGoogleLogin } from "@react-oauth/google";
import {
  googleAuth,
  signup,
  verifyEmail,
} from "../../store/actions/authActions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { checkPassword } from "../../utils/checkPassword";
import ForgotPassword from "./forgot-password";
import Login from "./login";
import useAuth, { AUTH_SOURCES } from "../../hooks/useAuth";
import { PageLoader } from "../../components/page-loader";
import Verify from "./verify";

const SCREENS = {
  SIGNUP: "SIGNUP",
  VERIFY: "VERIFY",
  VERIFIED: "VERIFIED",
};

const Auth = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);

  const [screen, setScreen] = React.useState(SCREENS.SIGNUP);
  const [accountType, setAccountType] = React.useState("researcher");
  const dataRef = React.useRef({});
  const invitationId = params.get("invite");
  const activationCode = params.get("activation_code");

  React.useEffect(() => {
    if (!isAuthenticated) {
      const action = params.get("action");
      const id = params.get("id");
      const token = params.get("token");

      if (action === "verify") {
        if (!id || !token)
          return toast.error(
            "Your verification link seems to be invalid. Please try again or contact support."
          );

        verifyEmail(id, token).then(({ data, error }) => {
          if (data) {
            // toast.success(
            //   data?.message || "Your email has been verified successfully."
            // );
            setScreen(SCREENS.VERIFIED);
          }
          if (error)
            toast.error(
              error?.message ||
                "Something went wrong while verifying your email. Please try again or contact support."
            );
        });
      }
    }
  }, []);

  const handleCreateAccount = (e, callback = () => {}) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    dataRef.current = data;

    const name = `${data.firstName} ${data.lastName}`;

    const notValid = checkPassword(data.password, data.password);
    if (notValid) return toast.error(notValid);

    signup({
      name,
      email: data?.email,
      password: data?.password,
      organizationName: data?.organizationName,
      invitationId: invitationId,
      resendVerification: data?.resendVerification,
      isParticipant: accountType === "participant",
      activationCode: activationCode,
    }).then(({ data, error }) => {
      if (error)
        return toast.error(
          error?.message ||
            "Something went wrong while creating your account. Please try again or contact support."
        );

      if (data) {
        callback();
        if (data?.message) {
          if (invitationId) history.push("/login");
          else setScreen(SCREENS.VERIFY);
        }
      }
    });
  };

  return (
    <AuthLayout>
      {screen === SCREENS.SIGNUP && (
        <div className={css.signup}>
          <div className="mb-2">
            <h2>{invitationId ? "Join your team" : "Create a free account"}</h2>
            <p>
              {invitationId ? `You have been invited to join a team. ` : ""}
              Already have an account?&nbsp;
              <Button
                variant="link"
                className="p-0 border-0 text-decoration-none"
                onClick={() => history.push("/login")}
              >
                Login
              </Button>
            </p>
          </div>

          <Form onSubmit={handleCreateAccount}>
            <Form.Group className="w-100 d-flex flex-column flex-wrap mb-3">
              <Form.Label>Choose your account type</Form.Label>
              <div className="d-flex flex-wrap gap-3">
                <Form.Check
                  type="radio"
                  label="I'm a Researcher"
                  name="accountType"
                  id="researcher"
                  value="researcher"
                  checked={accountType === "researcher"}
                  className={
                    accountType === "researcher" ? "text-primary" : null
                  }
                  onChange={(e) => setAccountType(e.target.value)}
                />
                <Form.Check
                  type="radio"
                  label="I'm a Participant"
                  name="accountType"
                  id="participant"
                  value="participant"
                  checked={accountType === "participant"}
                  className={
                    accountType === "participant" ? "text-primary" : null
                  }
                  onChange={(e) => setAccountType(e.target.value)}
                />
              </div>
            </Form.Group>
            <Form.Group className="w-100 d-flex flex-column flex-md-row gap-3 mb-3">
              <Form.Control
                type="text"
                placeholder="First Name"
                className="w-100 w-md-50"
                required
                name="firstName"
              />
              <Form.Control
                type="text"
                placeholder="Last Name"
                className="w-100 w-md-50"
                required
                name="lastName"
              />
            </Form.Group>
            <Form.Group className={`mb-3 ${invitationId ? "d-none" : ""}`}>
              <Form.Control
                type="text"
                placeholder="Organization name"
                name="organizationName"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="email"
                placeholder="Email address"
                required
                name="email"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="password"
                placeholder="Password"
                required
                name="password"
                minLength={8}
                maxLength={264}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="checkbox"
                label={
                  <>
                    I agree to User Evaluation&apos;s&nbsp;
                    <a
                      href="https://www.userevaluation.com/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Service
                    </a>
                    &nbsp;and&nbsp;
                    <a
                      href="https://www.userevaluation.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </>
                }
                required
              />
            </Form.Group>
            <Button type="submit" className="w-100">
              Sign Up
            </Button>
            <GoogleLoginBtn
              invitationId={invitationId}
              isParticipant={accountType === "participant"}
            />
          </Form>
        </div>
      )}
      {screen === SCREENS.VERIFY && (
        <Verify
          data={dataRef.current}
          handleCreateAccount={handleCreateAccount}
        />
      )}
      {screen === SCREENS.VERIFIED && (
        <div className={css.resetSuccess}>
          <img src={tick} alt="success" />
          <h4>Email verification successful.</h4>
          <p className="mb-0">Please login to continue.</p>
          <Button className="w-100" onClick={() => history.push("/login")}>
            Login
          </Button>
        </div>
      )}
    </AuthLayout>
  );
};

const GoogleLoginBtn = ({
  invitationId,
  isParticipant,
  callback = () => {},
}) => {
  const login = useGoogleLogin({
    flow: "auth-code",
    onSuccess: ({ code }) => {
      googleAuth(code, invitationId, isParticipant).then(({ error, data }) => {
        if (error)
          return toast.error(
            error?.message ||
              "Something went wrong. Please try again or contact support."
          );

        if (data) {
          toast.success(data?.message);
          callback();
        }
      });
    },
    onFailure: (res) => {
      console.error(res);
    },
  });

  return (
    <>
      <Row>
        <Col>
          <hr />
        </Col>
        <Col xs="auto">or</Col>
        <Col>
          <hr />
        </Col>
      </Row>
      <Button
        onClick={login}
        variant="outline-primary bg-transparent text-black w-100 d-flex align-items-center justify-content-center gap-2"
      >
        <FcGoogle height={19} width={19} />
        Continue with Google
      </Button>
    </>
  );
};

const AuthLayout = ({ children }) => {
  const { authLoading } = useAuth(AUTH_SOURCES.PUBLIC);

  if (authLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <Container fluid>
      <Row>
        <Col md={6} className={css.left}>
          <img
            src={logo}
            alt="userevaluation logo"
            className="d-none d-md-block"
          />
          <img
            src={logoMob}
            alt="userevaluation logo"
            className="d-block d-md-none"
          />
          <div className="d-none d-md-block">
            <h1>AI Copilot for Customer Understanding</h1>
            <p>
              Leverage AI to uncover insights from your customer conversations
              faster.
            </p>
          </div>
        </Col>
        <Col md={6} className={css.right}>
          {children}
        </Col>
      </Row>
    </Container>
  );
};

export { Auth, GoogleLoginBtn, ForgotPassword, Login, AuthLayout };

