import React, { useState, useEffect, useRef } from "react";
import debounce from "lodash.debounce";
import styles from "../../../index.module.css";
import { useSelector } from "react-redux";

const EditableTitle = ({ initialName, handleUpdate }) => {
  const [name, setName] = useState(initialName);
  const [inputWidth, setInputWidth] = useState("auto");
  const [isEditing, setIsEditing] = useState(false);
  const textWidthRef = useRef(null);
  const { isPublic } = useSelector((state) => state.currentProject);
  useEffect(() => {
    setName(initialName);
  }, [initialName]);

  const debouncedUpdate = debounce((value) => {
    handleUpdate("name", value);
  }, 1000);

  useEffect(() => {
    if (!isEditing && name !== initialName) {
      debouncedUpdate(name);
    }
  }, [isEditing, name]);

  useEffect(() => {
    if (textWidthRef.current) {
      setInputWidth(`${textWidthRef.current.offsetWidth + 16}px`); // Add some padding to width
    }
  }, [name]);

  return (
    <>
      {isPublic ? (
        <p className={styles.publicText}>{name}</p>
      ) : (
        <>
          {" "}
          <span ref={textWidthRef} className={styles.hiddenText}>
            {name}
          </span>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={() => setIsEditing(false)}
            onFocus={() => setIsEditing(true)}
            className={`${styles.editableText} ${
              isEditing ? styles.editing : ""
            }`}
            style={{
              width: inputWidth,
              fontSize: "0.95rem",
              fontWeight: "600",
            }}
          />
        </>
      )}
    </>
  );
};

export default EditableTitle;
