import React, { useState } from "react";
import styles from "./index.module.css";
import { Button } from "react-bootstrap";
import { FiTrash } from "react-icons/fi";
import { deleteUser } from "../../../../store/actions/adminActions";
import { useHistory } from "react-router-dom";
import { adminActions } from "../../../../store/slices/adminSlice";
import { useDispatch } from "react-redux";
function TableRow({ user, itemNumber }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  return (
    <tr className={styles.rowContainer}>
      <td>{itemNumber}</td>
      <td>{user.name}</td>
      <td>{user.email}</td>
      <td>
        <div className={styles.actionButtons}>
          <Button
            onClick={() => {
              dispatch(adminActions.setProfile(user.email));
              history.push("/admin/participants/profile");
            }}
          >
            {" "}
            Show Profile{" "}
          </Button>

          <br />
          {deleteConfirmation ? (
            <Button
              variant="danger"
              onClick={() => {
                deleteUser(user?._id);
                dispatch(adminActions.removeParticipants(user?._id));
                setDeleteConfirmation(false);
              }}
            >
              Sure ?
            </Button>
          ) : (
            <Button
              variant="danger"
              onClick={() => {
                setDeleteConfirmation(true);
              }}
            >
              Delete <FiTrash />
            </Button>
          )}
        </div>
      </td>
    </tr>
  );
}

export default TableRow;
