import React from "react";
import { SignUpState } from "../../../project/components/ProcessingStates";
import styles from "./signup.module.css";
function SignUp({tab}) {
  return (
    <div className={styles.signUpContainer}>
      <SignUpState type={tab} />
    </div>
  );
}

export default SignUp;
