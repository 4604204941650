import { createSlice } from "@reduxjs/toolkit";

const searchResultsSlice = createSlice({
  name: "searchResults",
  initialState: {
    results: [],
    loading: true,
    error: null,
    noResults: false
  },
  reducers: {
    requestStart(state, action) {
      state.loading = true;
      state.error = null;
      state.noResults = false;
    },
    setSearchResults(state, action) {
      state.loading = false;
      state.results = action.payload.results;
      state.error = null;
      state.noResults = false;
    },
    setNoResults(state, action) {
      state.loading = false;
      state.results = [];
      state.noResults = true;
      state.error = null;
    },
    clearSearchResults(state, action) {
      state.results = [];
      state.loading = false;
      state.error = null;
      state.noResults = false;
    },
    requestFail(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.noResults = false;
    },
  }
});

export default searchResultsSlice;
export const searchResultsActions = searchResultsSlice.actions;
