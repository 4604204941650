import React from "react";
import Card from "./card";
import {
  FiActivity as AiFillActivity,
  FiFileText as AiFillFileText,
  FiMessageCircle as AiFillMessage,
} from "react-icons/fi";
import { HiOutlinePresentationChartLine as AiFillPresentation } from "react-icons/hi";
import styles from "./index.module.css";

const CardContainer = ({ projectData }) => {
  // check if _id doesn't exist
  if (!projectData?._id) return null;

  return (
    <div className={styles.cardContainer}>
      <Card
        Icon={AiFillActivity}
        iconColor="#0085FF"
        heading="AI Insights"
        bodyText="Review insights generated by AI based on all your data, and organize the ones you would like to share."
        url={"/project/" + projectData?._id + "/insights"}
        linkText="View Insights"
      />
      <Card
        Icon={AiFillMessage}
        iconColor="#6C47D7"
        heading="AI Chat"
        bodyText="Ask questions about all your content and get answers as text, tables, and data visualizations."
        url={"/project/" + projectData?._id + "/chat"}
        linkText="Chat Now"
      />
      <Card
        Icon={AiFillFileText}
        iconColor="#E2626E"
        heading="AI Report"
        bodyText="Get an AI report generated using all files in this project. You’ll be able to modify, export and share it."
        url={"/project/" + projectData?._id + "/report"}
        linkText="Generate a Report"
      />
      <Card
        Icon={AiFillPresentation}
        iconColor="#FF6E2A"
        heading="AI Presentation"
        bodyText="Generate an AI presentation using all files in this project. You’ll get a PPTX file that you can download."
        url={"/project/" + projectData?._id + "/presentation"}
        linkText="Generate a PPTX"
      />
    </div>
  );
};

export default CardContainer;
