import { createSlice } from "@reduxjs/toolkit";

const projectSlice = createSlice({
  name: "project",
  initialState: {
    projects: [],
    loading: false,
    error: null,
    loaded: false,
  },

  reducers: {
    requestStart(state, action) {
      state.loading = true;
      state.error = null;
    },
    getProjects(state, action) {
      state.loading = false;
      state.projects = action.payload.projects;
      state.error = null;
      state.loaded = true;
    },
    addTagToVideo(state, action) {
      state.projects.forEach((project) => {
        project.videos = project.videos.map((video) => {
          if (action.videoId === video._id) {
            return {
              ...video,
              tags: project.tags,
            };
          }
          return video;
        });
      });
    },
    deleteProjectSlice(state, action) {
      state.projects = state.projects.filter(
        (project) => project._id !== action.payload._id
      );
      state.loading = false;
      state.error = null;
    },
    createBlankProjectSlice(state, action) {
      state.projects = [...state.projects, action.payload];
      state.loading = false;
      state.error = null;
    },
    updateProject(state, action) {
      let project = state.projects.find(
        (project) => project._id === action.payload._id
      );
      if (!project) {
        return;
      }
      Object.keys(action.payload).forEach((key) => {
        project[key] = action.payload[key];
      });
    },
    /**
     * @todo remove this function
     * @deprecated use updateProject instead
     */
    updateProjectNameOrDescription(state, action) {
      let project = state.projects.find(
        (project) => project._id === action.payload._id
      );
      if (action.payload.name) {
        project.name = action.payload.name;
      }
      if (action.payload.description) {
        project.description = action.payload.description;
      }
      state.loading = false;
      state.error = null;
    },
    requestFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default projectSlice;
export const projectActions = projectSlice.actions;
