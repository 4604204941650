import { createSlice } from "@reduxjs/toolkit";

const ribbonSlice = createSlice({
  name: "ribbon",
  initialState: {
    data: {},
    pressedBtn: "",
    showInsights: true,
    showSentiment: false,
    assetSpeakers: [],
  },
  reducers: {
    setRibbonData(state, action) {
      const { name, value } = action.payload;
      state.data[name] = value;
    },
    setPressedBtn(state, action) {
      state.pressedBtn = action.payload.pressedBtn;
    },
    resetRibbon(state, action) {
      state.data = {};
      state.pressedBtn = "";
    },
    setInsights(state, action) {
      state.showInsights = action.payload.showInsights;
    },
    setSentiment(state, action) {
      state.showSentiment = action.payload;
    },
    setSpeakers(state, action) {
      state.assetSpeakers = action.payload;
    },
  },
});

export default ribbonSlice;
export const ribbonActions = ribbonSlice.actions;
