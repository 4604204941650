import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom';


import { zoomOauth } from '../../../store/actions/integrationActions'

export default function ZoomOauthPage({match}) {
    const { app } = match.params;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    const history = useHistory()
    useEffect(() => {
        if (code && app) {
            zoomOauth(app, code).then(() => {
                history.push(`/integration/${app}`)
            })
        }
    }, [code, history])
    return <h1>Loading</h1>
}