import React, { useEffect } from "react";
import ZapierImage from "../../../images/app-icon-01.png";
import Integration from "../Integration";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
export default function ZapierIntegrationPage() {
  const history = useHistory();
  const { account: userAccount } = useSelector((state) => state.auth);
  const isNotFree = userAccount?.plan?.type !== "Free";
  useEffect(() => {
    if (!isNotFree) {
      history.push("/plans");
    }
  }, [isNotFree]);

  const details = (
    <React.Fragment>
      <h4>Zapier</h4>
      <p>
        Zapier is an online automation tool that connects your favorite apps,
        such as Gmail, Slack, Mailchimp, and more. You can connect two or more
        apps to automate repetitive tasks without coding or relying on
        developers to build the integration.
      </p>
      <h4>Additional Requirements</h4>
      <ul>
        <li>
          You must have a Zapier account to be able to connect User Evaluation
          to other apps.
        </li>
        <li>
          You will need a User Evaluation API key which you can generate from
          the Settings page.
        </li>
      </ul>
    </React.Fragment>
  );
  return (
    <Integration
      app={{
        name: "ZAPIER",
        appUrl: "",
      }}
      image={ZapierImage}
      details={details}
    />
  );
}
