const darkImage1 = require("./dark-01.png");
const darkImage2 = require("./dark-02.png");
const darkImage3 = require("./dark-03.png");
const darkImage4 = require("./dark-04.png");
const darkImage5 = require("./dark-05.png");
const darkImage6 = require("./dark-06.png");
const darkImage7 = require("./dark-07.png");
const darkImage8 = require("./dark-08.png");
const darkImage9 = require("./dark-09.png");
const darkImage10 = require("./dark-10.png");

const lightImage1 = require("./lignt-01.png");
const lightImage2 = require("./lignt-02.png");
const lightImage3 = require("./lignt-03.png");
const lightImage4 = require("./lignt-04.png");
const lightImage5 = require("./lignt-05.png");
const lightImage6 = require("./lignt-06.png");
const lightImage7 = require("./lignt-07.png");
const lightImage8 = require("./lignt-08.png");
const lightImage9 = require("./lignt-09.png");
const lightImage10 = require("./lignt-10.png");

const darkImages = [
  darkImage1,
  darkImage2,
  darkImage3,
  darkImage4,
  darkImage5,
  darkImage6,
  darkImage7,
  darkImage8,
  darkImage9,
  darkImage10,
];

const lightImages = [
  lightImage1,
  lightImage2,
  lightImage3,
  lightImage4,
  lightImage5,
  lightImage6,
  lightImage7,
  lightImage8,
  lightImage9,
  lightImage10,
];

// export images;
export { darkImages, lightImages };
