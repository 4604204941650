import React from "react";
import { Container, Button } from "react-bootstrap";
import lookout from "../../../../images/lookout.svg";
import lookoutWhite from "../../../../images/lookout-white.svg";
import { useSelector } from "react-redux";
export default function EmptyState() {
  const { darkMode } = useSelector((state) => state.theme);

  return (
    <Container className="d-flex align-items-center justify-content-center h-100">
      <div className="text-center">
        <img
          src={darkMode ? lookoutWhite : lookout}
          alt="processing"
          className="processing-img"
          style={{ maxWidth: "60%" }}
        />
        <br />
        <h1 className="processing-heading">No clips found</h1>
        <p className="processing-paragraph" style={{ maxWidth: "60%", margin: "auto", lineHeight: '1.3rem' }}>
          You have not created any clips yet. Head over to a video or audio file and select text in transcript to create a clip.<br/><br/>
          <Button variant="outline-primary" className="px-4" href="https://docs.userevaluation.com/docs/guides/create-and-download-clips" target="_blank">
            How to create a clip?
          </Button>
        </p>
      </div>
    </Container>
  );
}
