import React from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const SidebarNewIcon = () => {
  return (
    <OverlayTrigger
      style={{ display: "inline" }}
      placement="right"
      key={"ot-new"}
      delay={{ show: 80, hide: 200 }}
      overlay={(
        <Tooltip id={"button-tooltip-new"} style={{ zIndex: 999999}}>
          Upgrade account to unlock more features
        </Tooltip>)}
      popperConfig={{
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              altAxis: true,
              boundary: "viewport",
            },
          },
        ],
      }}
    >
      {/* Link to /plans */}

<Link to="/plans">
      <div className="ue-project-initials" style={{fontSize: 24, marginBottom: 12}}>
        🚀
    </div>
    </Link>
    </OverlayTrigger>
  );
};

export default SidebarNewIcon;