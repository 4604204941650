import { createSlice } from "@reduxjs/toolkit";

const testSlice = createSlice({
  name: "test",
  initialState: {
    loading: false,
    error: null,
    stepThreeChoice: "",
    allTests: [],
    currentTest: {},
    testProgress: [],
    participants: [],
    pastParticipants: [],
  },
  reducers: {
    requestStart(state) {
      state.loading = true;
      state.error = null;
    },
    requestFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    requestSuccess(state) {
      state.loading = false;
      state.error = null;
    },
    stepThreeChoice(state, action) {
      state.stepThreeChoice = action.payload;
    },
    setAllTests(state, action) {
      state.allTests = action.payload.allTests;
    },
    clearCurrentTest(state) {
      state.currentTest = {};
      state.testProgress = [];
      state.participants = [];
    },
    setCurrentTestProgress(state, action) {
      state.testProgress = action.payload || [];
    },
    clearTestProgress(state) {
      state.testProgress = [];
    },
    setCurrentTestParticipants(state, action) {
      state.participants = action.payload.participants || [];
    },
    setPastParticipants(state, action) {
      state.pastParticipants = action.payload || [];
    },
    removeParticipantFromOrg(state, action) {
      state.pastParticipants = state.participants.filter(
        (participant) => participant._id !== action.payload
      );
    },
    updateCurrentTest: (state, action) => {
      let script = action.payload.script || state.currentTest.script || [];

      state.currentTest = {
        ...state.currentTest,
        ...action.payload,
        script,
      };
    },
  },
});

export default testSlice;
export const testActions = testSlice.actions;
