/* eslint-disable no-unused-vars */
import React from "react";
import style from "./style.module.css";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Topbar } from "./components/topbar";
import useAuth, { AUTH_SOURCES } from "../../../hooks/useAuth";
import { useSelector } from "react-redux";
import ParticipantAuth from "./components/participant-auth";
import { useDisclosure } from "@mantine/hooks";

export const WelcomeToTest = () => {
  const history = useHistory();
  useAuth(AUTH_SOURCES.PARTICIPANT_WELCOME);
  const { account, isAuthenticated } = useSelector((state) => state.auth);
  const [opened, { open, close }] = useDisclosure();

  const params = new URLSearchParams(window.location.search);
  const testId = params.get("tId");

  const forwardUrl = `/participant/start-test?tId=${testId}`;

  React.useEffect(() => {
    if (!testId) return history.push("/login");

    if (!account) return;

    if (account.role !== "participant") history.push("/projects");
  }, [account, testId, history]);

  return (
    <div className={style.wrapper}>
      <Topbar />
      <div className={style.content}>
        <div className={style.heading}>
          Welcome to User Evaluation AI Interview
        </div>
        <div className={style.description}>
          <p>
            The testing experience is powered by Limeline. You will be taken to
            our partner site to talk to the AI.
          </p>
          <p>
            Please remember that there are
            <span className={style.bold}>
              &nbsp;no &apos;right or wrong&apos; answers
            </span>
            . We want to hear your impressions, any issues you have encountered
            and ideas for how the product or service can be improved.
          </p>
        </div>
        <Button
          onClick={() => {
            if (isAuthenticated) history.push(forwardUrl);
            else open();
          }}
          className={style.button}
        >
          Continue
        </Button>
      </div>

      <ParticipantAuth opened={opened} onClose={close} testId={testId} />
    </div>
  );
};
