import { toast } from "react-toastify";
import store from "..";
import { callExternalApi } from "../../services/external-api.service";
// import { toastSuccess } from "../../utils/toast";
import currentProjectSlice, {
  currentProjectActions,
} from "../slices/currentProject";
import projectSlice from "../slices/projectSlice";
import { getProject } from "./projectActions";
import { ribbonActions } from "../slices/ribbonSlice";
const { dispatch } = store;

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

const actions = currentProjectSlice.actions;

/** load a specific project when /p/<projectId> page is mounted */
export const loadProject = async function (projectId) {
  dispatch(actions.setLoading());
  try {
    const config = {
      url: `${apiServerUrl}/api/projects/${projectId}`,
      method: "GET",
    };

    const { data, error } = await callExternalApi({ config });
    if (error) {
      console.error(error);
    }
    dispatch(actions.setProject({ project: data }));
  } catch (err) {
    console.error(err);
  }
  dispatch(actions.resetLoading());
};

/** load asset info when it is selected from the left menu */
export const loadAsset = async function (assetId) {
  // dispatch(actions.setLoading());
  try {
    const config = {
      url: `${apiServerUrl}/api/assets/${assetId}`,
      method: "GET",
    };

    const { data, error } = await callExternalApi({ config });
    if (error) {
      console.error(error);
    }
    dispatch(actions.setAssetTranscript({ asset: data }));
    dispatch(actions.updateAssetSpeakers(data));

    return { data, error };
  } catch (err) {
    console.error(err);
  }
  // dispatch(actions.resetLoading());
};

// @TODO: REMOVE this deprecated function
// create presentation
export const createPresentation = async (payload) => {
  const config = {
    url: `${apiServerUrl}/api/pptx/create`,
    method: "POST",
    data: payload,
  };
  const { data, error } = await callExternalApi({ config });

  dispatch(actions.addProjectPresentation(data));

  return {
    data: data || null,
    error,
  };
};

/** load presentations when /p/<projectId>/pptx page is mounted */
export const loadPresentations = async function (pid) {
  // dispatch(actions.setLoading());
  try {
    const config = {
      url: `${apiServerUrl}/api/pptx/${pid}`,
      method: "GET",
    };
    const { data, error } = await callExternalApi({ config });
    if (error) {
      console.error(error);
    }
    dispatch(actions.setProjectPresentations(data));
  } catch (err) {
    console.error(err);
  }
  // dispatch(actions.resetLoading());
};

// create report at POST /api/reports
// updateStore is to prevent updating redux, so there's no screen flash when saving
export const saveReport = async (reportId, content, updateStore = true) => {
  const config = {
    url: `${apiServerUrl}/api/reports/save/${reportId}`,
    method: "POST",
    data: {
      content,
    },
  };
  const { data, error } = await callExternalApi({ config });

  if (error) {
    console.error(error);
  }

  if (data && updateStore) {
    dispatch(actions.setProjectReport(data));
  }

  return {
    data: data || null,
    error,
  };
};

// load report at GET /api/reports/:projectId
export const loadReport = async (projectId) => {
  try {
    const config = {
      url: `${apiServerUrl}/api/reports/${projectId}`,
      method: "GET",
    };
    const { data, error } = await callExternalApi({ config });
    if (error) {
      console.error(error);
    }

    if (data) {
      dispatch(actions.setProjectReport(data));
    }
  } catch (err) {
    console.error(err);
  }
};

// Load all reports for a project
// router.get('/all/:projectId', controller.listAllReports);

export const loadAllReports = async (projectId) => {
  try {
    const config = {
      url: `${apiServerUrl}/api/reports/all/${projectId}`,
      method: "GET",
    };
    const { data, error } = await callExternalApi({ config });
    if (error) {
      console.error(error);
    }

    if (data) {
      dispatch(actions.setProjectReports(data));
    }
  } catch (err) {
    console.error(err);
  }
};

// Load a report by id
// router.get('/item/:reportId', controller.listReportById);

export const loadReportById = async (reportId) => {
  try {
    const config = {
      url: `${apiServerUrl}/api/reports/item/${reportId}`,
      method: "GET",
    };
    const { data, error } = await callExternalApi({ config });
    if (error) {
      console.error(error);
    }

    if (data) {
      dispatch(actions.setProjectReport(data));
    }

    return {
      data: data || null,
      error,
    };
  } catch (err) {
    console.error(err);
  }
};

export const deleteReport = async (reportId, projectId) => {
  try {
    const config = {
      url: `${apiServerUrl}/api/reports/${projectId}/${reportId}`,
      method: "DELETE",
    };
    const { data, error } = await callExternalApi({ config });
    if (error) {
      console.error(error);
    }

    if (data) {
      dispatch(actions.deleteProjectReport(reportId));
      toast.success("Report deleted successfully");
    }

    return {
      data: data || null,
      error,
    };
  } catch (err) {
    console.error(err);
  }
};
export const deletePresentation = async (presentationId, projectId) => {
  try {
    const config = {
      url: `${apiServerUrl}/api/pptx/${projectId}/${presentationId}`,
      method: "DELETE",
    };
    const { data, error } = await callExternalApi({ config });
    if (error) {
      console.error(error);
    }

    if (data) {
      dispatch(actions.deleteProjectPresentation(presentationId));
      toast.success("Presentation deleted successfully");
    }

    return {
      data: data || null,
      error,
    };
  } catch (err) {
    console.error(err);
  }
};

export const handleCurrentProjectStatusUpdate = async (projectData) => {
  const state = store.getState();
  if (!state.currentProject.project) {
    return;
  }
  if (
    state.currentProject.project._id !== projectData._id ||
    state.currentProject.project.status === projectData.status
  ) {
    // if this update is not for current project then skip
    return;
  }
  if (projectData) {
    // call api to update the project insights when status is changed
    let { data, error } = await getProject({ id: projectData._id });

    if (data) {
      dispatch(currentProjectSlice.actions.setProject({ ...data }));
    }
    if (error) {
      console.error(error);
    }
    dispatch(projectSlice.actions.updateProject({ ...projectData }));
  }
};

export const handleCurrentProjectNameUpdate = async (projectData) => {
  const state = store.getState();
  if (!state.currentProject.project) {
    return;
  }
  if (
    state.currentProject.project._id !== projectData._id ||
    state.currentProject.project.name === projectData.name
  ) {
    // if this update is not for current project then skip
    return;
  }
  dispatch(currentProjectSlice.actions.updateProjectName({ ...projectData }));
};

export const handleCurrentProjectSpeakersUpdate = async (projectData) => {
  const state = store.getState();
  if (!state.currentProject.project) {
    return;
  }

  if (state.currentProject.project._id !== projectData._id) {
    // if this update is not for current project then skip
    return;
  }

  dispatch(currentProjectActions.updateAssetSpeakers(projectData));
  dispatch(ribbonActions.setSpeakers(projectData.speakers));
};