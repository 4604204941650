import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { Modal } from "@mantine/core";
import { modalActions } from "../../../../../store/slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  createLinkAction,
  getLinkByResourceIdAction,
} from "../../../../../store/actions/publicLinkActions";
import { toastSuccess } from "../../../../../utils/toast";

function ShareProjectModal() {
  const dispatch = useDispatch();
  const { shareProject: show } = useSelector((state) => state.modal);
  const { account: userAccount } = useSelector((state) => state.auth);
  const { link } = useSelector((state) => state.publicLink);
  const { project, isPublic, id } = useSelector(
    (state) => state.currentProject
  );
  const projectId = project?._id;
  useEffect(() => {
    const getResource = async () => {
      await getLinkByResourceIdAction(projectId, "project");
    };
    if (projectId) {
      getResource();
    }
  }, [projectId]);

  const shareProject = async (resourceId) => {
    const payload = {
      resourceId,
      resource: "project",
      createdBy: userAccount._id,
    };

    await createLinkAction(payload);
  };
  const copyLink = async (link) => {
    await navigator.clipboard.writeText(link);
    toastSuccess("Link copied to clipboard");
  };
  return (
    <Modal
      size="xl"
      opened={show}
      onClose={() => dispatch(modalActions.hideShareProjectModal())}
      title="Share Project"
    >
      <p>
        Share this project with people outisde your organization using the link
        below
      </p>
      <div className="d-flex">
        <input
          type="text"
          readOnly
          value={
            isPublic
              ? `https://app.userevaluation.com/public/` + id
              : link && link._id !== undefined && link.resource === "project"
              ? "https://app.userevaluation.com/public/" + link?._id
              : "Click Create Link to generate a link"
          }
          className="form-control me-2"
        />
        {!isPublic && (
          <Button
            variant="outline-primary"
            className="me-2"
            style={{ minWidth: 150 }}
            onClick={() => {
              shareProject(projectId);
            }}
          >
            {link && link._id !== undefined && link.resource === "project"
              ? "Update Link"
              : "Create Link"}
          </Button>
        )}

        <Button
          variant="primary"
          style={{ minWidth: 80 }}
          onClick={() => {
            if (isPublic) {
              copyLink(`https://app.userevaluation.com/public/` + id);
            } else {
              copyLink(`https://app.userevaluation.com/public/` + link?._id);
            }
          }}
        >
          Copy
        </Button>
      </div>
    </Modal>
  );
}

export default ShareProjectModal;
