/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { TestLayout } from "../components/test-layout";
import { testActions } from "../../../../store/slices/testSlice";
import style from "./index.module.css";
import { BsPeopleFill } from "react-icons/bs";
import { IoPeopleCircle, IoLink } from "react-icons/io5";

function ACI_SelectFilter({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <TestLayout stepNumber={3} testId={match.params.id}>
      <div className={style.wrapper}>
        <div className={style.title}>Launch an AI-curated test</div>
        <div className={style.subtitle}>
          Which audience would you like to invite?
        </div>
        <div className={style.cards}>
          <div
            className={`${style.card} ${style.uePanel}`}
            onClick={() => {
              dispatch(testActions.stepThreeChoice("filter-audience"));
              history.push(
                `/interviews/ai-curated-interview/${match.params.id}/filter-audience`
              );
            }}
          >
            <div className={style.icon}>
              <BsPeopleFill />
            </div>
            <div className={style.name}>User Evaluation&apos;s Panel</div>
            <div className={style.description}>
              Get access to 500K verified participant who are ready to respond
              to your queries.
            </div>
          </div>
          <div
            className={`${style.card} ${style.community}`}
            onClick={() => {
              dispatch(
                testActions.stepThreeChoice("invite-saved-participants")
              );
              history.push(
                `/interviews/ai-curated-interview/${match.params.id}/add-participants`
              );
            }}
          >
            <div className={style.icon}>
              <IoPeopleCircle />
            </div>
            <div className={style.name}>Invite from Community</div>
            <div className={style.description}>
              Re-invite testers that have participated or applied to participate
              in previous studies.
            </div>
          </div>
          <div
            className={`${style.card} ${style.email}`}
            onClick={() => {
              dispatch(testActions.stepThreeChoice("share-link"));
              history.push(
                `/interviews/ai-curated-interview/${match.params.id}/add-participants`
              );
            }}
          >
            <div className={style.icon}>
              <IoLink />
            </div>
            <div className={style.name}>Invite via Email or URL</div>
            <div className={style.description}>
              Share test via email or a shareable link that you can send
              anywhere.
            </div>
          </div>
        </div>
      </div>
    </TestLayout>
  );
}

export default ACI_SelectFilter;
