import React from "react";
import { TestLayout } from "../ai-curated-interview/components/test-layout";
import { IoSparkles } from "react-icons/io5";
// import { FaListUl } from "react-icons/fa";
// import { TbDeviceDesktopSearch } from "react-icons/tb";
import { useHistory } from "react-router-dom";
import style from "./index.module.css";
import { useSelector } from "react-redux";
import LimelineTooltip from "./components/limeline-tooltip";

function LaunchTest() {
  const history = useHistory();
  const { darkMode } = useSelector((state) => state.theme); // eslint-disable-line

  return (
    <TestLayout stepNumber={1}>
      <div className={style.wrapper}>
        <div className={style.title}>Create an interview</div>
        <div className={style.subtitle}>
          What type of interview would you like to conduct?
        </div>

        <TestCard
          title="AI-Curated Interview"
          description="Get an AI agent to conduct qualitative interviews on your behalf. You'll get audio recordings, transcripts, summaries, and insights for all interviews."
          icon={<IoSparkles />}
          badges={["AI-Curated", "Remote", "Unmoderated"]}
          onClick={() => {
            history.push("/interviews/ai-curated-interview/new/describe-test");
          }}
          className="aiCuratedInterview"
          extra={<LimelineTooltip title="Powered by" />}
        />

        <TestCard
          title="Live Interview"
          description="Collect feedback, thoughts, and opinions through a live interview with participant. You'll get a video recording, transcript, summary and insights for all interviews."
          icon={<IoSparkles />}
          badges={["AI-Curated", "Remote", "Unmoderated"]}
          className="aiGeneratedSurvey"
          disabled
        />

        {/* <TestCard
          title="AI-Curated Survey"
          description="A flexible survey to let users chat with AI and get quantitative answers. You will get the chat transcripts, summary and insights of all sessions."
          icon={<FaListUl />}
          badges={["AI-Generated", "Remote", "Unmoderated"]}
          onClick={() => {
            history.push("/tests/ai-curated-survey/new/describe-test");
          }}
          className="aiCuratedSurvey"
        />

        <TestCard
          title="AI-Generated Survey"
          description="Collect feedback, thoughts, and opinions through a survey generated by AI. This is simply a survey, and does not include distributing a link or actual product usage."
          icon={<TbDeviceDesktopSearch />}
          badges={["AI-Generated", "Remote", "Unmoderated"]}
          onClick={() => {
            history.push("/tests/ai-generated-survey/new/describe-test");
          }}
          className="aiGeneratedSurvey"
        /> */}
      </div>
    </TestLayout>
  );
}

export default LaunchTest;

const TestCard = ({
  title,
  className,
  description,
  icon,
  badges,
  onClick,
  extra = null,
  disabled = false,
}) => {
  return (
    <div
      className={`${style.card} ${style[className]} ${
        disabled ? style.disabled : ""
      }`}
      onClick={onClick}
    >
      <div className={style.icon}>{icon}</div>
      <div className={style.content}>
        <div className={style.testType}>{title}</div>
        <div className={style.description}>{description}</div>
        <div className={style.badges}>
          <div className={style.groupBadges}>
            {badges.map((badge) => (
              <div className={style.badge} key={badge}>
                {badge}
              </div>
            ))}
          </div>
          {extra}
        </div>
      </div>
    </div>
  );
};
