import React, { useState, useEffect, useRef } from "react";
import debounce from "lodash.debounce";
import styles from "./index.module.css";

const EditableLaneTitle = ({ initialName, handleUpdate, color, isTag }) => {
  const [name, setName] = useState(initialName);
  const [isEditing, setIsEditing] = useState(false);
  const textWidthRef = useRef(null);
  const textAreaRef = useRef(null);

  const debouncedUpdate = debounce((value) => {
    handleUpdate("title", value);
  }, 1000);

  useEffect(() => {
    if (!isEditing && name !== initialName) {
      debouncedUpdate(name);
    }
  }, [isEditing, name]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto'; 
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [textAreaRef.current]);
  function auto_grow(e) {
    e.target.style.height = e.target.scrollHeight + "px";
  }
  const content = (
    <div className={styles.sectionTitle}>
      <div
        style={{
          backgroundColor: color ? color : "",
          color: isTag ? "#000" : "var(--grey10)",
          marginBottom: "0px",
        }}
        ref={textWidthRef}
        className={styles.hiddenText}
      >
        {name}
      </div>

      <textarea
        rows={1}
        ref={textAreaRef}
        onInput={auto_grow}
        value={name}
        onChange={(e) => setName(e.target.value.trimStart())}
        onBlur={() => setIsEditing(false)}
        onFocus={() => setIsEditing(true)}
        className={`${styles.editableText} ${isEditing ? styles.editing : ""}`}
        style={{
          width: "100%",
          fontSize: "0.95rem",
          backgroundColor: color ? color : "",
          color: isTag ? "#000" : "var(--grey10)",
          maxHeight: "200px",
          paddingLeft: "8px",
        }}
      />
    </div>
  );
  return <>{content}</>;
};

export default EditableLaneTitle;
