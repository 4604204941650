import React from "react";
import { PageLayoutWaitlist } from '../../components/page-layout-waitlist';

export const NotFoundPage = () => {
  return (
    <PageLayoutWaitlist>
      <div className="content-layout mt-4 text-center">
        <h4 id="page-title" className="content__title">
          Oops! The page you are looking for does not exist.
        </h4>
      </div>
    </PageLayoutWaitlist>
  );
};
