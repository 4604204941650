import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    paywall: false,
    createclip: false,
    speakernamechange: false,
    showclip: false,
    boardLinkSharePopup: false,
    boardExportPopup: false,
    reportLinkSharePopup: false,
    imageCrop: false,
    AITag: false,
    insights: false,
    genPresentation: false,
    genReport: false,
    AIInsights: false,
    addInsight: false,
    addTag: false,
    addAsset: false,
    tagBoardPopup: false,
    shareProject: false,
    signUpReminder: true,
    notetaker: false,
    onboarding: false,
    deleteTag: false,
    customerRequests: false,
    clipTrim: false,
  },
  reducers: {
    showPayWallModal(state) {
      state.paywall = true;
    },
    hidePayWallModal(state) {
      state.paywall = false;
    },
    showCreateClipTrimModal(state) {
      state.clipTrim = true;
    },
    hideCreateClipTrimModal(state) {
      state.clipTrim = false;
    },
    showCreateClipModal(state) {
      state.createclip = true;
    },
    hideCreateClipModal(state) {
      state.createclip = false;
    },
    showSpeakerNameChangeModal(state) {
      state.speakernamechange = true;
    },
    hideSpeakerNameChangeModal(state) {
      state.speakernamechange = false;
    },
    showClipModal(state) {
      state.showclip = true;
    },
    hideClipModal(state) {
      state.showclip = false;
    },
    showShareBoardLinkModal(state) {
      state.boardLinkSharePopup = true;
    },
    hideShareBoardLinkModal(state) {
      state.boardLinkSharePopup = false;
    },
    showExportBoardModal(state) {
      state.boardExportPopup = true;
    },
    hideExportBoardModal(state) {
      state.boardExportPopup = false;
    },
    showShareReportLinkModal(state) {
      state.reportLinkSharePopup = true;
    },
    hideShareReportLinkModal(state) {
      state.reportLinkSharePopup = false;
    },
    showImageCropModal(state) {
      state.imageCrop = true;
    },
    hideImageCropModal(state) {
      state.imageCrop = false;
    },
    showAITagModal(state) {
      state.AITag = true;
    },
    hideAITagModal(state) {
      state.AITag = false;
    },
    showInsightsModal(state) {
      state.insights = true;
    },
    hideInsightsModal(state) {
      state.insights = false;
    },
    showGenPresentationModal(state) {
      state.genPresentation = true;
    },
    hideGenPresentationModal(state) {
      state.genPresentation = false;
    },
    showGenReportModal(state) {
      state.genReport = true;
    },
    hideGenReportModal(state) {
      state.genReport = false;
    },
    showAIInsightsModal(state) {
      state.AIInsights = true;
    },
    hideAIInsightsModal(state) {
      state.AIInsights = false;
    },
    showAddInsightModal(state) {
      state.addInsight = true;
    },
    hideAddInsightModal(state) {
      state.addInsight = false;
    },
    showAddTagModal(state) {
      state.addTag = true;
    },
    hideAddTagModal(state) {
      state.addTag = false;
    },
    showAddAssetModal(state) {
      state.addAsset = true;
    },
    hideAddAssetModal(state) {
      state.addAsset = false;
    },
    showTagBoardModal(state) {
      state.tagBoardPopup = true;
    },
    hideTagBoardModal(state) {
      state.tagBoardPopup = false;
    },
    showShareProjectModal(state) {
      state.shareProject = true;
    },
    hideShareProjectModal(state) {
      state.shareProject = false;
    },
    showSignUpReminderModal(state) {
      state.signUpReminder = true;
    },
    hideSignUpReminderModal(state) {
      state.signUpReminder = false;
    },
    showNotetakerModal(state) {
      state.notetaker = true;
    },
    hideNotetakerModal(state) {
      state.notetaker = false;
    },
    showOnboardingModal(state) {
      state.onboarding = true;
    },
    hideOnboardingModal(state) {
      state.onboarding = false;
    },
    showDeleteTagModal(state) {
      state.deleteTag = true;
    },
    hideDeleteTagModal(state) {
      state.deleteTag = false;
    },
    showCustomerRequestsModal(state) {
      state.customerRequests = true;
    },
    hideCustomerRequestsModal(state) {
      state.customerRequests = false;
    },
  },
});

export default modalSlice;
export const modalActions = modalSlice.actions;
