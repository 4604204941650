import { Tooltip } from "@mantine/core";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FiPlusCircle } from "react-icons/fi";
import { GoCheckCircleFill } from "react-icons/go";

function CollectedBtn({
  collectInsight,
  insight,
  singleInsight,
  name,
  showSources,
  fake,
}) {
  const [show, setShow] = React.useState(false);

  if (fake) {
    return (
      <Button
        variant="transparent"
        className="project-insight-btn"
        style={{ color: "var(--grey5)" }}
      >
        <FiPlusCircle
          className="project-insight-icons"
          style={{ color: "var(--grey5)" }}
        />{" "}
        Add to Collection
      </Button>
    );
  }

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} style={{ zIndex: 9999 }}>
        <Modal.Header closeButton>
          <Modal.Title>Collect Insight</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You have already added this insight to the Collection. Would you
            like to add it again?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShow(false);
              collectInsight(insight, singleInsight, name);
            }}
          >
            Yes, add it
          </Button>
        </Modal.Footer>
      </Modal>
      {showSources ? (
        <Button
          style={{
            backgroundColor: "var(--ue-sidebar-project-initials-bg-new)",
            border: "none",
          }}
          className="project-insight-btn"
          size="sm"
          onClick={() => {
            setShow(true);
          }}
        >
          <GoCheckCircleFill className="project-insight-icons" />
          <span
            style={{
              color: " var(--pink)",
              fontWeight: "500",
            }}
          >
            Collected
          </span>
        </Button>
      ) : (
        <>
          <Tooltip label="Collected">
            <Button
              style={{
                backgroundColor: "var(--ue-sidebar-project-initials-bg-new)",
                border: "none",
                padding: "0px",
              }}
              className="project-insight-btn"
              size="sm"
              onClick={() => {
                setShow(true);
              }}
            >
              <GoCheckCircleFill
                style={{ padding: "0px", margin: "0px" }}
                className="project-insight-icons"
              />
            </Button>
          </Tooltip>
        </>
      )}
    </>
  );
}
export function CollectBtn({
  selectedInsightTab,
  singleInsight,
  collectInsight,
  name,
  showSources,
  fake,
}) {
  if (fake) {
    return (
      <Button
        variant="transparent"
        className="project-insight-btn"
        style={{ color: "var(--grey5)" }}
      >
        <FiPlusCircle
          className="project-insight-icons"
          style={{ color: "var(--grey5)" }}
        />{" "}
        Add to Collection
      </Button>
    );
  }

  if (!showSources) {
    return (
      <Tooltip label="Add to Collection" position="left">
        <Button
          variant="transparent"
          className="project-insight-btn"
          onClick={() =>
            collectInsight(selectedInsightTab, singleInsight, name)
          }
        >
          <FiPlusCircle
            className="project-insight-icons"
            style={{
              margin: "0px",
            }}
          />
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button
      variant="transparent"
      className="project-insight-btn"
      onClick={() => collectInsight(selectedInsightTab, singleInsight, name)}
    >
      <FiPlusCircle className="project-insight-icons" />
      <span>Add to Collection</span>
    </Button>
  );
}

export default CollectedBtn;
