import React, { useEffect } from "react";
import styles from "./index.module.css";
import { Alert } from "react-bootstrap";
function PlansHeader({ account, currentTab, changeTab }) {
  const underLineRef = React.useRef(null);
  const firstElement = React.useRef(null);
  useEffect(() => {
    const underline = underLineRef.current;
    const item = firstElement.current;
    underline.style.width = item.offsetWidth + "px";
    underline.style.left = item.offsetLeft + "px";
  }, []);

  const handleClickAnimation = (e) => {
    const item = e.target;
    const underline = underLineRef.current;
    underline.style.width = item.offsetWidth + "px";
    underline.style.left = item.offsetLeft + "px";
  };

  return (
    <div className={styles.plansContainer}>
      <div>
        {account?.celloReferralCode && (
          <Alert variant="success" className="mt-4 mb-4">
            <strong>You are eligible for a 50% discount on all plans.</strong>{" "}
            Thank you for subscribing using a referral link!
          </Alert>
        )}
      </div>
      <div style={{ marginBottom: "12px" }}>
        <div className="mt-4">
          <span style={{ fontWeight: "500" }}>Lukas Schmidt</span>
          <span className="plan-feature" style={{ fontSize: "16px" }}>
            , Senior Product Designer at Urban Mobility Corp
          </span>
        </div>
      </div>
      <div className={styles.plansHeadingMain}>
        &quot;User Evaluation pays for itself 10x over&quot;
      </div>
      <div className={styles.plansSubheadingMain}>
        Uncover hidden insights from your customer data
      </div>

      <div className={styles.optionsContainer}>
        <button
          ref={firstElement}
          className={`${styles.styleslessBtn} ${
            currentTab === "analyze" ? styles.active : ""
          }`}
          onClick={(e) => {
            handleClickAnimation(e);
            changeTab("analyze");
          }}
        >
          Analyze
        </button>
        <button
          onClick={(e) => {
            changeTab("engage");
            handleClickAnimation(e);
          }}
          className={`${styles.styleslessBtn} ${
            currentTab === "engage" ? styles.active : ""
          }`}
        >
          Engage
        </button>
        <div className={styles.underline} ref={underLineRef}></div>
      </div>
    </div>
  );
}

export default PlansHeader;
