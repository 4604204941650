import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Container, Spinner, Button, ProgressBar } from "react-bootstrap";
import Editor from "../../../../components/editor";
import {
  loadReport,
  loadAllReports,
} from "../../../../store/actions/currentProjectActions";
import { toastError } from "../../../../utils/toast";
import useSocketContext from "../../../../utils/SocketContext";
import lookoutWhite from "../../../../images/lookout-white.svg";
import lookout from "../../../../images/lookout.svg";
import chartImg from "../../../../images/people-evaluation.svg";
import chartImgBlack from "../../../../images/people-evaluation-black.svg";
import ReportGen from "../modals/reportGenModal";
import { modalActions } from "../../../../store/slices/modalSlice";
import EmptyState from "../emptyStates/emptyReport";

export const PreparingData = ({ darkMode }) => {
  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <div className="text-center">
        <img
          src={darkMode ? chartImg : chartImgBlack}
          alt="processing"
          className="processing-img"
        />
        <br />
        <h1 className="processing-heading">Your data is being processed</h1>
        <p className="processing-paragraph">
          Please give our AI a few minutes before you can generate an AI report.
        </p>
        <Spinner animation="border" variant="outline-primary" size="sm" />
        <br />
      </div>
    </div>
  );
};

const AIReport = () => {
  const socketHandlers = useCallback(
    {
      reportUpdate: (payload) => {
        switch (payload.status) {
          case "Error":
            console.error(payload.message);
            toastError(payload.message);
            setProgressPercent(0);
            break;
          case "Processing":
            setProgressPercent(payload.progress);
            setProgressMessage(payload.message);
            break;
          case "Writing":
            setProgressMessage(payload.message);
            setAddBlock(payload.blocks[0]);
            setWritingLoader(true);
            break;
          case "Finished":
            setCanShowEmpty(false);
            loadReport(projectData._id);
            loadAllReports(projectData._id);
            setProgressPercent(0);
            setWritingLoader(false);
            break;
          default:
            console.warn("Unknown reportUpdate status:", payload.status);
        }
      },
    },
    []
  );

  //   const socket = useSocketIO(socketHandlers);
  const socket = useSocketContext();
  useEffect(() => {
    if (socket) {
      socket.attachHandlers(socketHandlers);
    }
    return () => socket.detachHandlers(socketHandlers);
  }, [socket, socketHandlers]);
  const projectData = useSelector((state) => state.currentProject.project);
  const { darkMode } = useSelector((state) => state.theme);
  const { genReport } = useSelector((state) => state.modal);
  const [canShowEmpty, setCanShowEmpty] = useState(true);
  const [progressMessage, setProgressMessage] = useState("");
  const [progressPercent, setProgressPercent] = useState(0);
  const [changeTo, setChangeTo] = useState(null);
  const [addBlock, setAddBlock] = useState(null);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [writingLoader, setWritingLoader] = useState(false);
  const handleSubmit = async (prompt) => {
    dispatch(modalActions.hideGenReportModal());
    if (prompt === "") {
      toastError("Please enter instructions for the AI to generate a report.");
      return;
    }
    if (selectedVideos.length === 0) {
      toastError("Please select at least 1 video to include in the report.");
      return;
    }

    // reset editor
    setChangeTo({
      time: new Date().getTime(),
      blocks: [],
      version: "2.22.2",
    });

    socket.createReport(projectData._id, prompt, selectedVideos);
  };
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(modalActions.hideGenReportModal());
  };

  useEffect(() => {
    loadReport(projectData._id);
  }, [projectData._id]);

  useEffect(() => {
    if (projectData.report && changeTo === null) {
      setChangeTo(projectData.report);
    }
  }, [projectData.report]);
  const emptyProject = (
    <Container className="d-flex align-items-center justify-content-center h-100">
      <div className="text-center">
        <img
          src={darkMode ? lookoutWhite : lookout}
          alt="processing"
          className="processing-img"
          style={{ maxWidth: "400px" }}
        />
        <br />
        <h1 className="processing-heading">Your project looks empty</h1>
        <p className="processing-paragraph">
          You have not uploaded files to your project yet. Upload your first
          file to get started.
        </p>

        <Link to={"/project/" + projectData._id}>
          <Button variant="outline-primary">Upload Files</Button>
        </Link>
      </div>
    </Container>
  );
  return (
    <>
      <ReportGen
        handleClose={handleClose}
        show={genReport}
        setSelectedVideos={setSelectedVideos}
        selectedVideos={selectedVideos}
        progressMessage={progressMessage}
        progressPercent={progressPercent}
        writingLoader={writingLoader}
        handleSubmit={handleSubmit}
      />
      {projectData?._id && projectData?.status === "Empty" && emptyProject}
      {projectData?.status !== "Ready" &&
        !(projectData?._id && projectData?.status === "Empty") && (
          <PreparingData darkMode={darkMode} />
        )}
      {projectData?.reports?.length === 0 &&
        canShowEmpty &&
        projectData?.status === "Ready" &&
        progressPercent === 0 && <EmptyState />}
      {projectData?.status === "Ready" && (
        <>
          <Container>
            {progressPercent !== 0 && (
              <>
                <ProgressBar
                  striped
                  animated
                  variant="info"
                  now={progressPercent}
                  className="mt-3"
                />
                <p className="mt-2">{progressMessage}</p>
              </>
            )}

            <Editor
              initialValue={null}
              changeTo={changeTo}
              setChangeTo={setChangeTo}
              addBlock={addBlock}
              setAddBlock={setAddBlock}
            />
          </Container>
        </>
      )}
    </>
  );
};

export default AIReport;
