import React, { useState } from "react";
import ClipCard from "./clipCards";
import styles from "./index.module.css";
import ShowClipModal from "./showClipModal";
import { useSelector } from "react-redux";
import { ClipsLoadingState } from "../ProcessingStates";
function ClipsShower({ clips }) {
  const [selectedClipId, setSelectedClipId] = useState(null);
  const { showclip } = useSelector((state) => state.modal);

  const selectedClip = clips.find((clip) => clip._id === selectedClipId);
  if (clips?.length === 0) {
    return (
      <div className={styles.emptyState}>
        <ClipsLoadingState />
      </div>
    );
  }

  return (
    <div className={styles.cardsContainer}>
      <ShowClipModal show={showclip} selectedClip={selectedClip} />
      {clips?.map((card, index) => {
        return (
          <ClipCard
            key={index}
            clip={card}
            setSelectedClipId={setSelectedClipId}
          />
        );
      })}
    </div>
  );
}

export default ClipsShower;
