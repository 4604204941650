import React, { useEffect, useState } from "react";
import CRPTemplate from "../wrappers/chat&report&presentation";
import DataInsightsTemplate from "../wrappers/data&Insight";
import TCTemplate from "../wrappers/tag&clips";
import Tags from "../components/tags";
import { BoardTab } from "../../../components/board/board";
import Clips from "../components/clips";
import Settings from "../components/settings";
import {  useSelector } from "react-redux";
import TopBar from "../components/topbar";
import Onboarding from "../components/onboarding/onboarding";
function TabPage({ match }) {
  const projectId = match.params.projectId;
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [activeTab, setActiveTab] = useState(null);
  const { project: projectData } = useSelector((state) => state.currentProject);
  const isProcessing = projectData?.status === "Preparing";
  useEffect(() => {
    if (match.params.tab) {
      setActiveTab(match.params.tab);
    } else {
      setActiveTab("data");
    }
  }, [match.params.tab]);
  useEffect(() => {
    if (match.params.assetId) {
      setSelectedVideoId(match.params.assetId);
    } else {
      setSelectedVideoId(projectData?.videos[0]?._id);
    }
  }, [match.params]);
  let mapTabToComponent = {
    data: (
      <DataInsightsTemplate
        isProcessing={isProcessing}
        assets={projectData?.videos}
        selectedVideoId={selectedVideoId}
        activeTab={activeTab}
        projectId={projectId}
        setSelectedVideoId={setSelectedVideoId}
      />
    ),
    insights: (
      <DataInsightsTemplate
        isProcessing={isProcessing}
        assets={projectData?.videos}
        selectedVideoId={selectedVideoId}
        activeTab={activeTab}
        projectId={projectId}
        setSelectedVideoId={setSelectedVideoId}
      />
    ),
    chat: <CRPTemplate activeTab={activeTab} isProcessing={isProcessing} />,
    tags: (
      <TCTemplate activeTab={activeTab} isProcessing={isProcessing}>
        <Tags newProject={true} />
      </TCTemplate>
    ),
    collection: (
      <TCTemplate activeTab={activeTab} isProcessing={isProcessing}>
        <BoardTab projectData={projectData} newProject={true} />
      </TCTemplate>
    ),
    report: (
      <CRPTemplate
        projectId={projectId}
        activeTab={activeTab}
        isProcessing={isProcessing}
      />
    ),
    presentation: (
      <CRPTemplate
        projectId={projectId}
        activeTab={activeTab}
        isProcessing={isProcessing}
      />
    ),
    clips: (
      <TCTemplate activeTab={activeTab}>
        <Clips projectId={projectId} isProcessing={isProcessing} />
      </TCTemplate>
    ),
    settings: <Settings />,
  };

  return (
    <div>
      <Onboarding />
      <>
        <TopBar
          name={projectData?.name}
          language={projectData?.language}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          projectId={projectId}
          selectedVideoId={selectedVideoId}
        />
        {mapTabToComponent[activeTab]}
      </>
    </div>
  );
}

export default TabPage;
