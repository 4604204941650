import React, { useEffect } from "react";
import { FiCheck } from "react-icons/fi";
import "./loadercheckbox.css";
function LoaderCheck({loadInstructions,setloadInstructions}) {
  useEffect(() => {
    if(loadInstructions) {
      return;
    }

    setTimeout(() => {
      setloadInstructions(false);
    } , 1000)
  }, [])
  return (
    <div>
      {loadInstructions && (
        <div className="loader">
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}
      {!loadInstructions && <FiCheck />}
    </div>
  );
}

export default LoaderCheck;
