import store from "..";
import { integrationActions } from "../slices/integrationSlice";

import { callExternalApi } from "../../services/external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
const { dispatch } = store;


export const getIntegrationApps = async () => {
    dispatch(integrationActions.startLoading())
    const config = {
        url: `${apiServerUrl}/api/integrations/`,
        method: "GET",
        params: {},
    };
    const { data } = await callExternalApi({ config });
    dispatch(integrationActions.setApps({ apps: data.integrations }))
    dispatch(integrationActions.stopLoading())
}


export const zoomOauth = async (app, code) => {
    dispatch(integrationActions.startLoading())
    const config = {
        url: `${apiServerUrl}/api/integrations/${app}/oauth?code=${code}`,
        method: "POST",
    };
     await callExternalApi({ config });
    await getIntegrationApps();
    dispatch(integrationActions.stopLoading())
}


/**
 * 
 * @param {String[]} meetingIds 
 */
export const importZoomRecording = async(meetingIds) => {
    dispatch(integrationActions.startLoading());
    const config = {
        url: `${apiServerUrl}/api/integrations/zoom/import`,
        method: "POST",
        data: {meetingIds}
    };
     await callExternalApi({ config });

    dispatch(integrationActions.stopLoading());
}