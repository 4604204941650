function getTimeSince(timestamp) {
  const date = new Date(timestamp);
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - date.getTime();

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(weeks / 4);
  const years = Math.floor(days / 365);

  if (seconds < 60) {
    if (seconds === 1) {
      return "1 second ago";
    }
    return "few seconds ago";
  }

  if (minutes < 60) {
    if (minutes === 1) {
      return "1 minute ago";
    }
    return `${minutes} minutes ago`;
  }

  if (hours < 24) {
    if (hours === 1) {
      return "1 hour ago";
    }
    return `${hours} hours ago`;
  }

  if (days < 7) {
    if (days === 1) {
      return "1 day ago";
    }
    return `${days} days ago`;
  }

  if (weeks < 4) {
    if (weeks === 1) {
      return "1 week ago";
    }
    return `${weeks} weeks ago`;
  }

  if (months < 12) {
    if (months === 1) {
      return "1 month ago";
    }
    return `${months} months ago`;
  }

  if (years === 1) {
    return "1 year ago";
  }
  return `${years} years ago`;
}

export default getTimeSince;
