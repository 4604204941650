import React, { useRef, useEffect, useState } from "react";
import Message from "../../../components/chat/message";
import { ListGroup } from "react-bootstrap";
import EmptyState from "../../../components/chat/emptyState";
// import { FiCheck } from "react-icons/fi";
// import "./messagelist.css";
import styles from './messagelist.module.css';
import LoaderCheck from "./loadercheckbox";
import scrollToLastMessage from "../../../utils/scrollToLastMessage";

function MessageList({ messages, loadInstructions, setloadInstructions }) {
  const [smoothScroll, setSmoothScroll] = useState(false);
  const chatListRef = useRef(null);
  let chatMessageCount = chatListRef.current?.children?.length;
  let lastMessageContent = messages[messages.length - 1]?.message
  useEffect(() => {
    scrollToLastMessage(chatListRef, smoothScroll, setSmoothScroll);
  }, [chatMessageCount, smoothScroll, lastMessageContent]);

  let normalMessages = messages?.filter(
    (message) => message?.messageType !== "Instructions"
  )
  /** for multiple consecutive AI message show avatar/chat head
   * for only the first message.
   */
  normalMessages = normalMessages.map((msg, idx) => {
    if(msg.sender === "AI" && idx!==0&& normalMessages[idx-1].sender === "AI") {
      return {...msg, hideAvatar: true};
    }
    return msg;
  });

  let instructions = [];
  let isLastMessageInstruction =
    messages[messages?.length - 1]?.messageType === "Instructions";
  if (messages) {
    for (let i = messages?.length - 1; i >= 0; i--) {
      if (messages[i].messageType === "Instructions") {
        setloadInstructions(false);
        instructions.push(messages[i]);
      } else {
        break;
      }
    }
  }

  return messages?.length > 0 ? (
    <ListGroup
      ref={chatListRef}
      className="chat-list"
      style={{
        display: "flex",
      }}
    >
      <ListGroup.Item className="d-flex justify-content-center">
        <div className="text-center"
        style={{width: "95%"}}
        >
          <p
            className={styles.chatIntro}
          >
            Hi, I am your AI assistant. Ask me anything about your project.
            Please note that as an AI, I can get things wrong.
          </p>
        </div>
      </ListGroup.Item>
      {normalMessages.map((message, index) => (
        <Message key={message.createdAt + index} message={message} />
      ))}

      {(isLastMessageInstruction || loadInstructions) && (
        <div
          style={{
            width: "100%",
            margin: '20px',
          }}
        >
          <div
            className={styles.instructionMessage}
          >
            {
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <LoaderCheck loadInstructions={loadInstructions} />
                <span
                  style={{
                    color: "var(--grey10)",
                    fontSize: "0.9rem",
                    fontWeight: "500",
                  }}
                >
                  Understanding your question
                </span>
              </div>
            }
            {instructions.length > 0 &&
              instructions.map((message, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  <LoaderCheck
                    loadInstructions={true}
                    setloadInstructions={setloadInstructions}
                  />
                  <span
                    style={{
                      color: "var(--grey10)",
                      fontSize: "0.9rem",
                    }}
                  >
                    {message.message}
                  </span>
                </div>
              ))}
          </div>
        </div>
      )}

      {/* making a last element for scrolling    */}

      <div
        className="message-box"
        style={{
          marginTop: "auto",
          height: "12rem",
          width: "100%",
          flexShrink: "0",
        }}
      ></div>
    </ListGroup>
  ) : (
    <EmptyState />
  );
}

export default MessageList;
