import React from "react";
import TopNav from "../../topNav";
import styles from "./index.module.css";
function TopBar({
  selectedInsightTab,
  selectedVideo,
  assetId,
  projectId,
  setSelectedInsightTab,
}) {
  const tabs = selectedVideo?.insights?.map((insight) => {
    return {
      name: insight?.insightType,
      value: insight?.insightType.replace(/\s/g, ""),
    };
  });
  return (
    <div className={styles.topbarContainer}>
      <TopNav
        setActiveTab={setSelectedInsightTab}
        tabs={tabs}
        activeTab={selectedInsightTab}
        projectId={projectId}
        assetId={assetId}
        type={"insights"}
      />
    </div>
  );
}

export default TopBar;
