import { toastError, toastSuccess } from "../utils/toast"

/** create toast for response data */
export default function showAPIToast(data){
    if(!data) {
        return ;
    }

    if(Array.isArray(data.toast)){
        data.toast.forEach(toast => {
            if(toast.type === "success") {
                toastSuccess(toast.message)
            } else if(toast.type === "error") {
                toastError(toast.message)
            } else {
                console.error("Unkown toast type", toast)
            }
        })
    }
}