export const fileTypeWhitelist = {
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    "application/msword": [".doc"],
    "application/pdf": [".pdf"],
    "text/*" : [".txt", '.md'],
    "audio/*": [
      ".3ga",
      ".8svx",
      ".aac",
      ".ac3",
      ".aif",
      ".aiff",
      ".alac",
      ".amr",
      ".ape",
      ".au",
      ".dss",
      ".flac",
      ".m4a",
      ".m4b",
      ".m4p",
      ".m4r",
      ".mp3",
      ".mpga",
      ".ogg",
      ".oga",
      ".mogg",
      ".opus",
      ".qcp",
      ".tta",
      ".voc",
      ".wav",
      ".wma",
      ".wv",
      ".webm"
    ],
    "video/*": [
      ".flv",
      ".MTS",
      ".M2TS",
      ".TS",
      ".mov",
      ".mp2",
      ".mp4",
      ".m4v",
      ".mxf",
      ".mp4",
      ".webm"
    ],
  }