import React from "react";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import { PageLayoutWaitlist } from "../../components/page-layout-waitlist";
import Confetti from "react-confetti";
import { useHistory } from "react-router-dom";

export const Invitation = (props) => {
  const history = useHistory();
  const handleButtonClick = async () => {
    const invitationId = props.match.params.id;

    history.push({
      pathname: "/signup",
      search: "?invite=" + invitationId,
    });
  };
  const handleSignInButtonClick = async () => {
    const invitationId = props.match.params.id;

    history.push({
      pathname: "/login",
      search: "?invite=" + invitationId,
    });
  };

  return (
    <PageLayoutWaitlist>
      <Container>
        <Confetti />
        <Row className="justify-content-center align-items-center mt-5">
          <Col xs={8} sm={6} md={6}>
            <Card className="text-center" style={{ zIndex: 9999, padding: 20 }}>
              <Card.Header as="h4">Welcome to User Evaluation!</Card.Header>
              <Card.Body>
                <Card.Text>
                  Congratulations on being invited! Ready to jump in and make
                  some magic happen with the team? Click the button below to
                  accept the invite and start your adventure with us. Let&apos;s
                  do this!
                </Card.Text>
                <Button variant="primary" onClick={handleButtonClick}>
                  Accept Invite and Sign Up
                </Button>
              </Card.Body>
              <Button variant="link" onClick={handleSignInButtonClick} >
                Already have an account? Log in here.
              </Button>
            </Card>
          </Col>
        </Row>
      </Container>
    </PageLayoutWaitlist>
  );
};
