import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Spinner, Container } from "react-bootstrap";
import { loadPresentations } from "../../../../store/actions/currentProjectActions";
import { toastError } from "../../../../utils/toast";
import lookoutWhite from "../../../../images/lookout-white.svg";
import lookout from "../../../../images/lookout.svg";
import chartImg from "../../../../images/people-evaluation.svg";
import chartImgBlack from "../../../../images/people-evaluation-black.svg";
import useSocketContext from "../../../../utils/SocketContext";
import { modalActions } from "../../../../store/slices/modalSlice";
import PresentationGen from "./presentationGenModal";
import PreviewComponent from "./previewComponent";
import EmptyState from "../emptyStates/emptyPresentation";

export const PreparingData = ({ darkMode }) => {
  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <div className="text-center">
        <img
          src={darkMode ? chartImg : chartImgBlack}
          alt="processing"
          className="processing-img"
        />
        <br />
        <h1 className="processing-heading">Your data is being processed</h1>
        <p className="processing-paragraph">
          Please give our AI a few minutes before generating your presentation.
        </p>
        <Spinner animation="border" variant="outline-primary" size="sm" />
        <br />
      </div>
    </div>
  );
};

export default function ProjectPptx() {
  const { project } = useSelector((state) => state.currentProject);
  let presentations = project?.presentations;
  const dispatch = useDispatch();
  const socketHandlers = useCallback(
    {
      pptxUpdate: (payload) => {
        switch (payload.status) {
          case "Error":
            console.error(payload.message);
            toastError(payload.message);
            setProgressPercent(0);
            dispatch(modalActions.hideGenPresentationModal());
            break;
          case "Processing":
            setProgressPercent(payload.progress);
            setProgressMessage(payload.message);
            break;
          case "Finished":
            loadPresentations(projectData._id);
            setProgressPercent(0);
            dispatch(modalActions.hideGenPresentationModal());
            break;
          default:
            console.warn("Unknown pptxUpdate status:", payload.status);
        }
      },
    },
    []
  );

  // const socket = useSocketIO(socketHandlers);
  const socket = useSocketContext();

  useEffect(() => {
    if (socket) {
      socket.attachHandlers(socketHandlers);
    }
    return () => socket.detachHandlers(socketHandlers);
  }, [socket, socketHandlers]);

  const projectData = useSelector((state) => state.currentProject.project);
  const { darkMode } = useSelector((state) => state.theme);
  const { genPresentation } = useSelector((state) => state.modal);
  const [progressMessage, setProgressMessage] = useState("");
  const [progressPercent, setProgressPercent] = useState(0);
  const [selectedVideos, setSelectedVideos] = useState([]);
  // state for loading presentations
  const [loadedProjectId, setLoadedProjectId] = useState(null);
  useEffect(() => {
    if (projectData?._id && projectData._id !== loadedProjectId) {
      loadPresentations(projectData._id);
      setLoadedProjectId(projectData._id);
    }
  }, [projectData, loadedProjectId]);

  const handleSubmit = async (prompt) => {
    if (!prompt) {
      toastError(
        "Please provide some instructions to the AI to create a presentation."
      );
      return;
    }

    // prompt must be less than 500 words
    if (prompt.split(" ").length > 500) {
      toastError("You can only provide up to 500 words of instructions.");
      return;
    }

    socket.createPptx(projectData._id, prompt, selectedVideos);
  };
  const emptyProject = (
    <Container className="d-flex align-items-center justify-content-center h-100">
      <div className="text-center">
        <img
          src={darkMode ? lookoutWhite : lookout}
          alt="processing"
          className="processing-img"
          style={{ maxWidth: "400px" }}
        />
        <br />
        <h1 className="processing-heading">Your project looks empty</h1>
        <p className="processing-paragraph">
          You have not uploaded files to your project yet. Upload files to get
          started.
        </p>

        <Link to={"/project/" + projectData._id}>
          <Button variant="outline-primary">Upload Files</Button>
        </Link>
      </div>
    </Container>
  );
  return (
    <>
      <PresentationGen
        setSelectedVideos={setSelectedVideos}
        selectedVideos={selectedVideos}
        handleClose={() => {
          dispatch(modalActions.hideGenPresentationModal());
        }}
        progressPercent={progressPercent}
        progressMessage={progressMessage}
        handleSubmit={handleSubmit}
        show={genPresentation}
      />
      {projectData?._id && projectData?.status === "Empty" && emptyProject}
      {projectData?.status !== "Ready" &&
        !(projectData?._id && projectData?.status === "Empty") && (
          <PreparingData darkMode={darkMode} />
        )}
      {presentations?.length === 0 &&
        progressPercent === 0 &&
        projectData?.status === "Ready" && <EmptyState />}
      {projectData?.status === "Ready" && (
        <>
          <PreviewComponent presentations={presentations} />
        </>
      )}
    </>
  );
}
