import React, { useMemo, useEffect, useState, useCallback } from "react";
import { Slate, Editable, withReact,
 } from "slate-react";
import {
  createEditor,
} from "slate";
import isHotkey from 'is-hotkey';
import { withHistory } from "slate-history";
import { Container, Form } from "react-bootstrap";
import EmojiPicker, { Emoji } from "emoji-picker-react";
import { useDebounce } from 'usehooks-ts'

import { getNote, updateNote } from "../../../../store/actions/noteActions";

import { HOTKEYS } from "./components/constants";
import EditorToolbar, { toggleMark } from "./components/EditorToolbar";
import { Element, Leaf } from "./components/content-renderers";
import useSocketContext from "../../../../utils/SocketContext";


const initialValue = [{
  "type": "paragraph",
  "children": [
    {
      "text": ""
    }
  ]
}];

const withLinks = (editor) => {

  const { isInline } = editor;
  editor.isInline = (element) => 
      element.type === 'link' ? true :isInline(element);
  return editor;
};


export const ProjectNote = ({ currentNotePage, setPageUpdated }) => {
  const socket  = useSocketContext();
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(withLinks(createEditor()))), []);

  const [title, setTitle] = useState("Untitled");
  const debouncedTitle = useDebounce(title, 1500);
  const [selectedEmoji, setSelectedEmoji] = useState("1f30f");
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [loadingComplete, setLoadingComplete] = useState(false);

  useEffect(() => {
    const getNoteData = async () => {
      const { data, error } = await getNote(currentNotePage);
  
      if (data) {
        setTitle(data.title);
        setSelectedEmoji(data.emoji);
        setValue(data.content);
        setLoadingComplete(true);
      }
  
      if (error) {
        console.error(error);
      }
    };
  
    getNoteData();
  }, [currentNotePage]);

  const handleEmoji = (emojiObject, event) => {
    setSelectedEmoji(emojiObject.unified);
    setEmojiPickerOpen(false);
    saveEmojiToAPIdb(emojiObject.unified);
  };

  const saveToAPIdb = async (newValue) => {
    var dataToUpdate = {
      content: newValue
    };

    socket.updateNote(currentNotePage, dataToUpdate)
  };

  const saveEmojiToAPIdb = async (newEmoji) => {
    var dataToUpdate = {
      emoji: newEmoji
    };

    const { data, error } = await updateNote(currentNotePage, dataToUpdate);

    if (data) {
      setPageUpdated(currentNotePage);
    }

    if (error) {
      console.error(error);
    }
  };

  const handleTitleChange = async (e) => {
    e.preventDefault();
    const newTitle = e.target.value;
    setTitle(newTitle);
  };

  useEffect(() => {
    const saveDebouncedTitle = async () => {
        try {
            await updateNote(currentNotePage, { title: debouncedTitle });
        } catch (error) {
            console.error(error);
        }
    }

    saveDebouncedTitle();
}, [debouncedTitle, currentNotePage]);


  return (
    <Container className="mt-5">
      <Container style={{ maxWidth: "700px" }} className="mx-auto">
        <div>
          <div onClick={() => setEmojiPickerOpen(!emojiPickerOpen)}>
            <Emoji unified={selectedEmoji} size="40" />
          </div>
          {emojiPickerOpen ? <EmojiPicker onEmojiClick={handleEmoji} theme={"dark"} /> : null}
        </div>

        <Form.Control
          size="lg"
          type="text"
          style={{ fontSize: "2rem", padding: 0, border: 0 }}
          className="mb-3"
          value={title}
          onChange={handleTitleChange}
          placeholder="Enter title"
        />

        {loadingComplete && <Slate editor={editor} value={value} 
          onChange={(newValue) => {
            setValue(newValue);
            const isAstChange = editor.operations.some((op) => 'set_selection' !== op.type);
            if (isAstChange) {
              saveToAPIdb(newValue);
            }
          }}
          >
        
          <EditorToolbar currentNotePage={currentNotePage} />
      
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder="Type anything here..."
            spellCheck
            autoFocus
            onKeyDown={event => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey];
                  toggleMark(editor, mark);
                }
              }
            }}
          />
        </Slate>}
      </Container>
    </Container>
  );
};
