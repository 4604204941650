import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  Badge,
  Button,
  Alert,
} from "react-bootstrap";
import {
  loadProjectsAction,
  updateProjectNameOrDesc,
} from "../../../../store/actions/projectActions";
import getTimeSince from "../../../../utils/getTimeSince";
import { toastSuccess } from "../../../../utils/toast";
import CardContainer from "./cardContainer";
import { hashProjectId } from "../../../participants/profile/components/cover-image";

import { darkImages, lightImages } from "../../../../images/project-images";

const supportedLanguages = [
  { code: "en_us", name: "English (US)" },
  { code: "en_uk", name: "English (UK)" },
  { code: "en_au", name: "English (AU)" },
  { code: "en", name: "English (Global)" },
  { code: "af", name: "Afrikaans" },
  { code: "ar", name: "Arabic" },
  { code: "hy", name: "Armenian" },
  { code: "az", name: "Azerbaijani" },
  { code: "be", name: "Belarusian" },
  { code: "bs", name: "Bosnian" },
  { code: "bg", name: "Bulgarian" },
  { code: "ca", name: "Catalan" },
  { code: "zh", name: "Chinese" },
  { code: "hr", name: "Croatian" },
  { code: "cs", name: "Czech" },
  { code: "da", name: "Danish" },
  { code: "nl", name: "Dutch" },
  { code: "et", name: "Estonian" },
  { code: "fi", name: "Finnish" },
  { code: "fr", name: "French" },
  { code: "gl", name: "Galician" },
  { code: "de", name: "German" },
  { code: "el", name: "Greek" },
  { code: "he", name: "Hebrew" },
  { code: "hi", name: "Hindi" },
  { code: "hu", name: "Hungarian" },
  { code: "is", name: "Icelandic" },
  { code: "id", name: "Indonesian" },
  { code: "it", name: "Italian" },
  { code: "ja", name: "Japanese" },
  { code: "kn", name: "Kannada" },
  { code: "kk", name: "Kazakh" },
  { code: "ko", name: "Korean" },
  { code: "lv", name: "Latvian" },
  { code: "lt", name: "Lithuanian" },
  { code: "mk", name: "Macedonian" },
  { code: "ms", name: "Malay" },
  { code: "mr", name: "Marathi" },
  { code: "mi", name: "Maori" },
  { code: "ne", name: "Nepali" },
  { code: "no", name: "Norwegian" },
  { code: "fa", name: "Persian" },
  { code: "pl", name: "Polish" },
  { code: "pt", name: "Portuguese" },
  { code: "ro", name: "Romanian" },
  { code: "ru", name: "Russian" },
  { code: "sr", name: "Serbian" },
  { code: "sk", name: "Slovak" },
  { code: "sl", name: "Slovenian" },
  { code: "es", name: "Spanish" },
  { code: "sw", name: "Swahili" },
  { code: "sv", name: "Swedish" },
  { code: "tl", name: "Tagalog" },
  { code: "ta", name: "Tamil" },
  { code: "th", name: "Thai" },
  { code: "tr", name: "Turkish" },
  { code: "uk", name: "Ukrainian" },
  { code: "ur", name: "Urdu" },
  { code: "vi", name: "Vietnamese" },
  { code: "cy", name: "Welsh" },
];

export const OverviewTab = ({
  projectData,
  setProjectData,
  userAccount,
  runGetProjectData,
}) => {
  const { loading: authLoading } = useSelector((state) => state.auth);
  const [newDescription, setNewDescription] = useState(projectData.description);
  const [newName, setNewName] = useState(projectData.name);
  const [editingName, setEditingName] = useState(false);
  const [editingLanguage, setEditingLanguage] = useState(false);
  const [newLanguage, setNewLanguage] = useState(projectData.language);
  const { darkMode } = useSelector((state) => state.theme);

  const handleUpdate = async (field, value) => {
    const payload = {
      _id: projectData._id,
      [field]: value,
    };

    let { data, error } = await updateProjectNameOrDesc(payload);
    if (error) {
      console.error(`error updating ${field}`, error);
    }
    if (data) {
      toastSuccess(`${field[0].toUpperCase() + field.slice(1)} updated`);
      setProjectData({ ...projectData, [field]: value });
      setEditingName(false);
      setEditingLanguage(false);
      (await authLoading) && loadProjectsAction(userAccount.email);
    }
  };
  return (
    <Container fluid>
      {(projectData?.status === "Ready" ||
        projectData?.status === "Preparing") && (
        <>
          <Row>
            <Col>
              <div
                style={{
                  width: "100%",
                  minHeight: "200px",
                  backgroundImage: `url(${
                    darkMode
                      ? darkImages[hashProjectId(projectData._id)]
                      : lightImages[hashProjectId(projectData._id)]
                  })`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "12px",
                  marginTop: "12px",
                }}
              ></div>
            </Col>
          </Row>
          <CardContainer projectData={projectData} />
        </>
      )}
      <Container style={{ maxWidth: "800px", margin: "0 auto" }}>
        <Row className="mt-4">
          <Col>
            {projectData.status === "Empty" && (
              <Alert
                variant="success"
                style={{
                  background: darkMode
                    ? "linear-gradient(-135deg, #141414, #222222)"
                    : "linear-gradient(-135deg, #f2f2f2, #f5f5f5)",
                  color: darkMode ? "#fff" : "#000",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 8,
                  border: "2px solid #0085FF",
                }}
              >
                <p style={{ fontSize: 14, margin: 0 }}>
                  <strong>Your project looks empty.</strong> Upload an audio,
                  video, text or CSV file to get started. Click on{" "}
                  <strong>&quot;Add New.&quot;</strong>
                </p>
              </Alert>
            )}
            <Row className="project-overview-row">
              <Col className="project-overview-label">Project name</Col>
              <Col
                className="project-overview-value po-name-row"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="project-overview-name">
                  {editingName ? (
                    <>
                      <Form.Control
                        type="text"
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        style={{
                          display: "inline",
                          width: "auto",
                        }}
                      />
                      <Button
                        variant="transparent"
                        onClick={() => handleUpdate("name", newName)}
                        style={{ marginLeft: "10px", display: "inline-block" }}
                      >
                        Save
                      </Button>
                      <Button
                        variant="transparent"
                        style={{ marginLeft: "10px", display: "inline-block" }}
                        onClick={() => setEditingName(!editingName)}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    projectData.name
                  )}
                </div>
                {!editingName && (
                  <div
                    className="project-overview-edit"
                    onClick={() => setEditingName(!editingName)}
                  >
                    Edit
                  </div>
                )}
              </Col>
            </Row>
            {/* if language exists */}
            {projectData.language && (
              <Row className="project-overview-row">
                <Col className="project-overview-label">Language</Col>
                <Col
                  className="project-overview-value po-name-row"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="project-overview-name">
                    {editingLanguage ? (
                      <>
                        <Form.Select
                          aria-label="Default select example"
                          value={newLanguage}
                          onChange={(e) => setNewLanguage(e.target.value)}
                          style={{
                            display: "inline",
                            width: "auto",
                          }}
                        >
                          <option value="detect">Detect Language</option>
                          {supportedLanguages.map((lang, i) => (
                            <option key={i} value={lang.code}>
                              {lang.name}
                            </option>
                          ))}
                        </Form.Select>
                        <Button
                          variant="transparent"
                          onClick={() => handleUpdate("language", newLanguage)}
                          style={{
                            marginLeft: "10px",
                            display: "inline-block",
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          variant="transparent"
                          style={{
                            marginLeft: "10px",
                            display: "inline-block",
                          }}
                          onClick={() => setEditingLanguage(!editingLanguage)}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        {projectData.language === "detect"
                          ? "Detect Language"
                          : supportedLanguages.filter(
                              (lang) => lang.code === projectData.language
                            )[0]?.name}
                      </>
                    )}
                  </div>
                  {!editingLanguage && (
                    <div
                      className="project-overview-edit"
                      onClick={() => setEditingLanguage(!editingLanguage)}
                    >
                      Edit
                    </div>
                  )}
                </Col>
              </Row>
            )}

            <Row className="project-overview-row">
              <Col className="project-overview-label">Status</Col>
              <Col className="project-overview-value">
                <Badge bg="dark">
                  {projectData.status === "Preparing"
                    ? "Processing..."
                    : projectData.status}
                </Badge>
              </Col>
            </Row>
            <Row className="project-overview-row">
              <Col className="project-overview-label">Date created</Col>
              <Col className="project-overview-value">
                {getTimeSince(projectData.createdAt)}
              </Col>
            </Row>
            <Row className="project-overview-row">
              <Col className="project-overview-label">Created by</Col>
              <Col className="project-overview-value">
                {projectData.createdBy?.name}
              </Col>
            </Row>

            {projectData?.types && projectData?.types.length > 0 && (
              <Row className="project-overview-row">
                <Col className="project-overview-label">Exported from</Col>
                <Col className="project-overview-value">
                  {projectData?.types.includes("LiveChat") ? "Live Chat" : ""}
                </Col>
              </Row>
            )}
            <Row className="mt-4">
              <Col>
                {/* {projectData.description} in a textarea */}
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label className="project-overview-label">
                    Description
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={newDescription}
                    placeholder="Describe your project (Optional)"
                    onChange={(e) => setNewDescription(e.target.value)}
                  />
                  {newDescription !== projectData.description && (
                    <Button
                      variant="primary"
                      className="mt-2"
                      onClick={() =>
                        handleUpdate("description", newDescription)
                      }
                    >
                      Update
                    </Button>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
