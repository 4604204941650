import React from "react";
import { Route } from "react-router-dom";
import { TestProgress } from "./test-progress/index.js";
import { TestAnalyze } from "./test-analyze/index.js";
import { TestParticipants } from "./test-participants/index.js";
import { TestDetails } from "./test-details/index.js";
import {
  getTestById,
  getTestProgress,
} from "../../store/actions/testActions.js";
import { matchPath } from "react-router-dom/cjs/react-router-dom.min.js";
import { testActions } from "../../store/slices/testSlice.js";
import { useDispatch } from "react-redux";

const TestOverviewRoutes = (props) => {
  const dispatch = useDispatch();

  const match = matchPath(props.location.pathname, {
    path: "/interview/:id/:page",
  });

  React.useEffect(() => {
    if (match?.params?.id) {
      getTestProgress(match.params.id);
      getTestById(match.params.id);
    }
  }, [match?.params?.id]);

  React.useEffect(() => {
    return () => {
      dispatch(testActions.clearCurrentTest());
    };
  }, []);

  return (
    <>
      <Route path="/interview/:id/progress" component={TestProgress} />
      <Route path="/interview/:id/analyze" component={TestAnalyze} />
      <Route path="/interview/:id/details" component={TestDetails} />
      <Route path="/interview/:id/participants" component={TestParticipants} />
    </>
  );
};

export default TestOverviewRoutes;
