import React, { useEffect,useState } from "react";
import "./assets.css";
import { FiFileText } from "react-icons/fi";
import truncateString from "../../utils/truncateString";

// import { selectProjectAction } from "../../store/actions/selectedProjectActions";
// import { selectAssetAction } from "../../store/actions/selectedProjectActions";

function AssetsWindow({ showAssetWindow, selectedProject }) {
  let projectAssets = [];
  if (selectedProject.videos) {
    projectAssets = selectedProject.videos;
  }
  // const [projectAssets, setProjectAssets] = useState([]);
  // const [selectedAssets, setSelectedAssets] = useState([]);
  // selectProjectAction(selectedProject);

  // selecting ids from checked selected assets
  // const selectedIds = selectedAssets
  //   .filter((asset) => asset.checked)
  //   .map((asset) => asset.id);
  // updating selected assets
  // selectAssetAction(selectedIds);

  // handling checkbox change
  // const handleChange = (id) => {
  //   const updatedSelectedAssets = selectedAssets.map((asset) => {
  //     if (asset.id === id) {
  //       return {
  //         ...asset,
  //         checked: !asset.checked,
  //       };
  //     }
  //     return asset;
  //   });
  // updating selected assets
  //   setSelectedAssets(updatedSelectedAssets);
  // };

  // useEffect(async () => {
  //   // checking if project assets are array or object
  //   if (Array.isArray(selectedProject.videos)) {
  //     // if array
  //     // get project assets from server and set project assets
  //     const id = selectedProject._id;
  //     const { data, error } = await getProject({ id });
  //     if (error) {
  //       console.error(error);
  //     }
  //     setProjectAssets(data.videos);
  //   } else {
  //     // if object set project assets
  //     setProjectAssets(selectedProject.videos);
  //   }
  // }, []);
  const [videos, setVideos] = useState([]);

  useEffect(() => {

    // adding checked property to project assets
    // initially all assets are checked
    if (projectAssets.length > 0) {
      
      const updatedSelectedAssets = projectAssets.map((asset) => {
        return {
          id: asset._id,
          name: asset.name,
          type: "video",
          checked: true,
        };
      });
      setVideos(updatedSelectedAssets);
    }
  }, [projectAssets]);

  // variable to store selected assets

  return (
    <div
      className={`${showAssetWindow ? "assets-window show-assets" : "hide-assets"}`}
      style={{
        
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          color: "grey",
        }}
      >
        <span> Response based on:</span>
        <span>
          {" "}
          {projectAssets?.length} of {projectAssets?.length}{" "}
        </span>
      </div>
      <div className="assets-container" style={{}}>
        {videos.map((video, index) => {
          return (
            <div className="asset-item" key={video.id+index}>
              {/* <input
                type="checkbox"
                className="asset-checkbox"
                checked={video.checked}
                onChange={() => handleChange(video.id)}
              /> */}
              <button>
                <FiFileText color="  #0085FF" />
              </button>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  color: "var(--white)",
                }}
              >
                <span> {truncateString(video.name, 20)} </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AssetsWindow;
