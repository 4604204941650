import React from "react";
import { Button, Modal } from "react-bootstrap";
import { GoCheckCircleFill } from "react-icons/go";

function CollectedBtn({collectInsight, insight, singleInsight, name}) {
  const [show, setShow] = React.useState(false);
  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        style={{ zIndex: 9999 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Collect Insight</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
          You have already added this insight to the Collection. Would you like to add it again?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShow(false);
              collectInsight(insight, singleInsight, name);
            }}
          >
            Yes, add it
          </Button>
        </Modal.Footer>
      </Modal>
      <Button
        style={{
          backgroundColor: "var(--ue-sidebar-project-initials-bg-new)",
          border: "none",
        }}
        size="sm"
        onClick={() => {
          setShow(true);
        }}
      >
        <GoCheckCircleFill className="project-insight-icons" />
        <span
          style={{
            color: " var(--pink)",
            fontWeight: "500",
          }}
        >
          Collected
        </span>
      </Button>
    </>
  );
}

export default CollectedBtn;
