import React from "react";
import { Container, Button } from "react-bootstrap";
import styles from "./index.module.css";
import { HiOutlinePresentationChartLine } from "react-icons/hi";
export default function EmptyState() {
  return (
    <Container className="d-flex align-items-center justify-content-center h-100">
      <div className="text-center">
        <div className={styles.icon}>
          <HiOutlinePresentationChartLine />
        </div>
        <br />
        <h1 className="processing-heading">No Presentations Found</h1>
        <p
          className="processing-paragraph"
          style={{ maxWidth: "60%", margin: "auto", lineHeight: "1.3rem" }}
        >
          You have not created any presentation yet. Click New Presentation in
          the sidebar to create a presentation using AI.
          <br />
          <br />
          <Button
            variant="outline-primary"
            className="px-4"
            href="https://docs.userevaluation.com/docs/guides/creating-an-ai-presentation"
            target="_blank"
          >
            Learn how to create an AI Presentation
          </Button>
        </p>
      </div>
    </Container>
  );
}
