/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import style from "./index.module.css";
import { PageLayout } from "../../../components/page-layout";
import CoverImage from "../components/cover-image";
import AudioPlayer from "../../../components/AudioPlayer/index";
// import audio from "../assets/audio.mp3";
import TopBar from "../components/topbar";
import { Button } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
// import { MdOutlinePlayCircle, MdTv } from "react-icons/md";
// import { FiActivity, FiFileText } from "react-icons/fi";
import { BiSolidVideos } from "react-icons/bi";
import { FaChevronDown, FaChevronUp, FaCheck } from "react-icons/fa6";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  addVideoToProject,
  createBlankProjectAction,
} from "../../../store/actions/projectActions";
import {
  addSessionToProject,
  changeProjectId,
} from "../../../store/actions/testActions";
import { toastError, toastSuccess } from "../../../utils/toast";
import { useHistory } from "react-router-dom";
dayjs.extend(relativeTime);

// const SESSION = [
//   {
//     id: 1,
//     title: "Session # 1 – Jason D.",
//     time: "62 minutes",
//     addedToProject: true,
//   },
//   {
//     id: 2,
//     title: "Session # 2 – Samantha A.",
//     time: "45 minutes",
//     addedToProject: false,
//   },
// ];

export const TestAnalyze = ({ match }) => {
  const history = useHistory();
  const { testProgress, currentTest } = useSelector((state) => state.test);
  const { account } = useSelector((state) => state.auth);

  const addToProject = async (videoId) => {
    if (!videoId) return toastError("Invalid session.");

    let projectId = currentTest.projectId;
    if (!projectId) {
      const { data } = await createBlankProjectAction(
        account.email,
        false,
        currentTest.title
      );
      projectId = data._id;
      await changeProjectId(currentTest._id, projectId);
    }

    const { error } = await addVideoToProject({ projectId, videos: [videoId] });

    if (error)
      return toastError(
        "There was an error adding this session to the project. Please try again later."
      );
    else toastSuccess("Session added to project successfully.");
  };

  const viewAnalysis = () => {
    if (!currentTest.projectId)
      return toastError("Please add at least one session to the project.");

    history.push(`/project/${currentTest.projectId}/`);
  };

  return (
    <PageLayout>
      <div className={style.parentWrapper}>
        <div className={style.wrapper}>
          <TopBar
            currentTab="Analyze"
            testId={match.params.id || "some random value"}
          />
          <div className={style.main}>
            <CoverImage
              projectDataId={currentTest._id || "some random value"}
            />
            <div className={style.content}>
              <div className={`${style.aside} ${style.section}`}>
                <div className={style.asideTitle}>{currentTest.title}</div>
                <div className={style.asideDescription}>
                  Created {dayjs(currentTest.createdAt).fromNow()}
                </div>
                {/* <div className={style.asideInfo}>
                  <MdOutlinePlayCircle className={style.asideIcon} />
                  <span>3</span>
                  <FiActivity className={style.asideIcon} />
                  <span>125</span>
                  <FiFileText className={style.asideIcon} />
                  <span>1</span>
                  <MdTv className={style.asideIcon} />
                  <span>1</span>
                </div> */}
                <Button
                  className={style.asideButton}
                  onClick={viewAnalysis}
                  disabled={!testProgress.length}
                >
                  View Analysis
                </Button>
              </div>
              <div className={style.sessions}>
                <div className={style.heading}>Sessions</div>
                <div>
                  {testProgress.length
                    ? "The following sessions have already been added to your project. Every new session will be added to your analysis project automatically."
                    : "There are no sessions available yet. As soon as someone takes the interview, we will show it here."}
                </div>
                {testProgress?.map(
                  (
                    {
                      recording,
                      transcript,
                      summary,
                      time,
                      addedToProject,
                      participantId,
                      videoId,
                    },
                    i
                  ) => (
                    <CustomAccordionItem
                      key={videoId}
                      sessionTitle={`Session # ${i + 1} - ${
                        participantId?.name
                      }`}
                      sessionTime={time}
                      addedToProject={addedToProject}
                      addToProject={() => addToProject(videoId)}
                    >
                      <AudioPlayer url={recording} />
                      <Tabs
                        defaultActiveKey="summary"
                        variant="pills"
                        className={style.tabs}
                      >
                        <Tab eventKey="summary" title="Call Summary">
                          {summary}
                        </Tab>
                        <Tab eventKey="transcript" title="Transcript">
                          {transcript?.map(
                            ({ role, content, chat, timestamp }, i) => {
                              let text = content || chat;

                              if (
                                role !== "assistant" &&
                                text.toLowerCase().includes("hello")
                              )
                                return null;

                              return (
                                <p key={timestamp}>
                                  <span className="fw-bold">
                                    {role === "assistant"
                                      ? "Agent"
                                      : "Participant"}
                                    :
                                  </span>
                                  &nbsp;
                                  {text}
                                </p>
                              );
                            }
                          )}
                        </Tab>
                      </Tabs>
                    </CustomAccordionItem>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

function CustomAccordionItem({
  sessionTitle,
  sessionTime,
  addedToProject,
  children,
  addToProject,
}) {
  const [isExpanded, setExpanded] = useState(false);

  const toggleAccordion = () => {
    setExpanded(!isExpanded);
  };

  return (
    <div className={`${style.session} ${style.section}`}>
      <div className={style.sessionHeader}>
        <div className={style.thumbnail}>
          <BiSolidVideos />
        </div>
        <div className={style.sessionInfo}>
          <div className={style.sessionTitle}>{sessionTitle}</div>
          <div className={style.sessionTime}>{sessionTime}</div>
        </div>
        {addedToProject ? (
          <Button variant="success" className={style.sessionButtonSuccess}>
            <FaCheck />
            &nbsp; Added to Project
          </Button>
        ) : (
          <Button
            variant="outline-primary"
            className={style.sessionButton}
            onClick={addToProject}
          >
            Add to Project
          </Button>
        )}
      </div>
      <Collapse in={isExpanded}>
        <div className={style.sessionContent}>{children}</div>
      </Collapse>
      {isExpanded ? (
        <FaChevronUp className={style.chevron} onClick={toggleAccordion} />
      ) : (
        <FaChevronDown className={style.chevron} onClick={toggleAccordion} />
      )}
    </div>
  );
}
