import React, { useEffect, useState } from "react";
import { FormControl } from "react-bootstrap";
import styles from "./sidebar.module.css";
import { useSelector } from "react-redux";
import truncateString from "../../../../utils/truncateString";
import { FiSearch, FiX } from "react-icons/fi";
import uniq from "lodash/uniq";
import flatMap from "lodash/flatMap";
import intersection from "lodash/intersection";

const filterClipsBasedOnSearchText = (clips, setSelectedClips, searchText) => {
  let newSelectedClips = clips.filter((clip) => {
    return (
      clip.clipNotes?.toLowerCase().includes(searchText?.toLowerCase()) ||
      intersection(clip.clipTags, searchText.split(" ")).length > 0
    );
  });
  setSelectedClips(newSelectedClips);
};
const filterClipsBasedOnTagsAndSession = (
  clips,
  setSelectedClips,
  selectedTags,
  selectedSessions
) => {
  let newSelectedClips = clips.filter((clip) => {
    if (selectedTags.length === 0 && selectedSessions.length === 0) return true;

    if (selectedTags.length === 0 && selectedSessions.length !== 0) {
      return selectedSessions.includes(clip.assetId);
    }
    if (selectedTags.length !== 0 && selectedSessions.length === 0) {
      return intersection(clip.clipTags, selectedTags).length > 0;
    }

    return (
      intersection(clip.clipTags, selectedTags).length > 0 &&
      selectedSessions.includes(clip.assetId)
    );
  });
  setSelectedClips(newSelectedClips);
};

function SideBar({ setSelectedClips, video, active, setActive }) {
  const { clips } = useSelector((state) => state.clip);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [searchText, setSearchText] = useState("");
  let uniqueTags = uniq(flatMap(clips?.map((clip) => clip.clipTags)));

  useEffect(() => {
    filterClipsBasedOnTagsAndSession(
      clips,
      setSelectedClips,
      selectedTags,
      selectedSessions
    );
  }, [selectedTags, selectedSessions, clips, setSelectedClips]);
  useEffect(() => {
    filterClipsBasedOnSearchText(clips, setSelectedClips, searchText);
  }, [searchText]);
  useEffect(() => {
    setSelectedSessions(video.map((vid) => vid._id));
  }, []);
  useEffect(() => {
    setSelectedTags(uniqueTags);
  }, [uniqueTags.length]);

  return (
    <div
      className={`${styles.sidebarContainer} ${
        active && styles.smallScreenActive
      }`}
    >
      <div className={styles.searchBox}>
        <FormControl
          type="text"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          placeholder="Search Clips.."
          className="mr-sm-2"
          style={{ width: "100%", paddingLeft: "2rem" }}
        />
        <div
          className={styles.searchicon}
          style={{ width: "2rem", textAlign: "center", color: "var(--grey6)" }}
        >
          <FiSearch />
        </div>
        <div className={styles.closeBtn}>
          <button
            onClick={() => {
              setActive(false);
            }}
          >
            <FiX />
          </button>
        </div>
      </div>
      <div className={styles.filterClipsSection}>
        <h5>Filter Clips</h5>
        <div>
          <h6>Sessions ({selectedSessions?.length})</h6>
          <div className={styles.tags}>
            {video.map((vid, i) => {
              return (
                <div
                  onClick={() => {
                    if (selectedSessions.includes(vid._id)) {
                      setSelectedSessions(
                        selectedSessions.filter(
                          (session) => session !== vid._id
                        )
                      );
                    } else {
                      setSelectedSessions([...selectedSessions, vid._id]);
                    }
                  }}
                  key={vid._id}
                  className={`${styles.filterClipsItem} ${
                    selectedSessions.includes(vid._id) ? styles.active : ""
                  } `}
                >
                  {truncateString(vid.name, 20)}
                </div>
              );
            })}
            <button
              className={styles.resetButton}
              onClick={() => {
                setSelectedSessions(video.map((vid) => vid._id));
              }}
            >
              Reset
            </button>
          </div>
        </div>
        <div>
          <h6 style={{ marginBottom: "0.75rem" }}>
            Tags ({selectedTags?.length})
          </h6>
          <div className={styles.tags}>
            {uniqueTags &&
              uniqueTags.map((tag, i) => {
                return (
                  <button
                    onClick={() => {
                      setSelectedTags((prevSelectedTags) => {
                        if (prevSelectedTags.includes(tag)) {
                          return prevSelectedTags.filter(
                            (tagg) => tagg !== tag
                          );
                        } else {
                          return [...prevSelectedTags, tag];
                        }
                      });
                    }}
                    key={i}
                    className={`${styles.filterClipsItem} ${
                      selectedTags.includes(tag) ? styles.active : ""
                    }`}
                  >
                    {tag}
                  </button>
                );
              })}
            <button
              className={styles.resetButton}
              onClick={() => {
                setSelectedTags(uniqueTags);
              }}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
