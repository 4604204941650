import React, { useEffect, useState } from "react";
import { getAllClips } from "../../../store/actions/clipActions";
import { useSelector } from "react-redux";
import styles from "./index.module.css";
import SideBar from "./components/sidebar";
import ClipCards from "./components/clipCards";
import { Spinner } from "react-bootstrap";
import EmptyState from "./components/emptyState";
function ClipsPage() {
  const { clips, loading } = useSelector((state) => state.clip);
  const [selectedClips, setSelectedClips] = useState([]);
  const [active, setActive] = useState(false);
  const projectData = useSelector((state) => state.currentProject.project);
  const video = projectData.videos.filter((video) => {
    return video.assetType === "VIDEO" || video.assetType === "AUDIO";
  });
  useEffect(() => {
    if (projectData._id) {
      getAllClips(projectData._id);
    }
  }, [projectData._id]);
  useEffect(() => {
    setSelectedClips(clips);
  }, [clips]);
  return (
    <>
      <div className={styles.showOnSmallScreen}>
        <button
          onClick={() => {
            setActive(!active);
          }}
        >
          Search & filter
        </button>
      </div>
      <div className={styles.parent}>
        <SideBar
          setSelectedClips={setSelectedClips}
          video={video}
          active={active}
          setActive={setActive}
        />
        {loading ? (
          <div className={styles.loader}>
            <Spinner animation="border" variant="primary" />
          </div>
        ) : clips.length === 0 ? (
          <EmptyState />
        ) : (
          <ClipCards clips={selectedClips} />
        )}
      </div>
    </>
  );
}

export default ClipsPage;
