import { createSlice } from "@reduxjs/toolkit";

const mediaLibrarySlice = createSlice({
  name: "zoomIntegration",
  initialState: {
    assets: [],
    googleToken: undefined,
    zoom: {
      meetings: [],
    },
    loading: false,
    assetLoading: false,
  },
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },

    startAssetLoading(state) {
      state.assetLoading = true;
    },
    stopAssetLoading(state) {
      state.assetLoading = false;
    },
    setTranscript(state, action) {
      const { assetId, transcript } = action.payload;
      const asset = state.assets.find((asset) => asset._id === assetId);
      asset.transcript = transcript;
    },
    setAssets(state, action) {
      state.assets = action.payload.assets;
    },
    setZoom(state, action) {
      state.zoom = action.payload;
    },
    setGoogleToken(state, action) {
      state.googleToken = action.payload;
    },
    addTagToVideo(state, action) {
      const { tag, assetId } = action.payload;
      const asset = state.assets.find((asset) => asset._id === assetId);
      asset.tags.push(tag);
    },
    removeTagsFromVideo(state, action) {
      const { tagIds } = action.payload;
      state.assets.forEach((asset) => {
        asset.tags = asset.tags.filter((tag) => !tagIds.includes(tag._id));
      });
    },
    removeTagFromVideo(state, action) {
      const { tagId, assetId } = action.payload;
      state.assets.forEach((asset) => {
        if (asset._id === assetId) {
          asset.tags = asset.tags.filter((tag) => tag._id !== tagId);
        }
      });
    },
    setAssetTitle(state, action) {
      const { assetId, title } = action.payload;
      state.assets.forEach((asset) => {
        if (asset._id === assetId) {
          asset.name = title;
        }
      });
    },
  },
});

export default mediaLibrarySlice;
export const mediaLibraryActions = mediaLibrarySlice.actions;
