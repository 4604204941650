import React from "react";
import { Container } from "react-bootstrap";
import lookout from "../../../images/lookout.svg";
import lookoutWhite from "../../../images/lookout-white.svg";
import { useSelector } from "react-redux";
export default function EmptyState() {
  const { darkMode } = useSelector((state) => state.theme);

  return (
    <Container className="d-flex align-items-center justify-content-center h-100">
      <div className="text-center">
        <img
          src={darkMode ? lookoutWhite : lookout}
          alt="processing"
          className="processing-img"
          style={{ maxWidth: "60%" }}
        />
        <br />
        <h1 className="processing-heading">No tags added yet</h1>
        <p
          className="processing-paragraph"
          style={{ maxWidth: "60%", margin: "auto", lineHeight: "1.3rem" }}
        >
          It seems like you haven&apos;t made any tags yet. To get started, visit the
          file content or transcript section. Simply select a portion of the
          text you want to tag, and a toolbar will appear to let you add tags. Alternatively, click &quot;Tag with AI&quot;.
          <br />
          <br />.
        </p>
      </div>
    </Container>
  );
}
