export const LOCATION = [
  { value: "AX", name: "Å,LAND ISLANDS (AX)" },
  { value: "AF", name: "AFGHANISTAN (AF)" },
  { value: "AL", name: "ALBANIA (AL)" },
  { value: "DZ", name: "ALGERIA (DZ)" },
  { value: "AS", name: "AMERICAN SAMOA (AS)" },
  { value: "AD", name: "ANDORRA (AD)" },
  { value: "AO", name: "ANGOLA (AO)" },
  { value: "AI", name: "ANGUILLA (AI)" },
  { value: "AQ", name: "ANTARCTICA (AQ)" },
  { value: "AG", name: "ANTIGUA AND BARBUDA (AG)" },
  { value: "AR", name: "ARGENTINA (AR)" },
  { value: "AM", name: "ARMENIA (AM)" },
  { value: "AW", name: "ARUBA (AW)" },
  { value: "AU", name: "AUSTRALIA (AU)" },
  { value: "AT", name: "AUSTRIA (AT)" },
  { value: "AZ", name: "AZERBAIJAN (AZ)" },
  { value: "BS", name: "BAHAMAS (BS)" },
  { value: "BH", name: "BAHRAIN (BH)" },
  { value: "BD", name: "BANGLADESH (BD)" },
  { value: "BB", name: "BARBADOS (BB)" },
  { value: "BY", name: "BELARUS (BY)" },
  { value: "BE", name: "BELGIUM (BE)" },
  { value: "BZ", name: "BELIZE (BZ)" },
  { value: "BJ", name: "BENIN (BJ)" },
  { value: "BM", name: "BERMUDA (BM)" },
  { value: "BT", name: "BHUTAN (BT)" },
  { value: "BO", name: "BOLIVIA (BO)" },
  { value: "BA", name: "BOSNIA AND HERZEGOVINA (BA)" },
  { value: "BW", name: "BOTSWANA (BW)" },
  { value: "BV", name: "BOUVET ISLAND (BV)" },
  { value: "BR", name: "BRAZIL (BR)" },
  { value: "IO", name: "BRITISH INDIAN OCEAN TERRITORY (IO)" },
  { value: "BN", name: "BRUNEI DARUSSALAM (BN)" },
  { value: "BG", name: "BULGARIA (BG)" },
  { value: "BF", name: "BURKINA FASO (BF)" },
  { value: "BI", name: "BURUNDI (BI)" },
  { value: "CI", name: "CÔ,TE D'IVOIRE (CI)" },
  { value: "KH", name: "CAMBODIA (KH)" },
  { value: "CM", name: "CAMEROON (CM)" },
  { value: "CA", name: "CANADA (CA)" },
  { value: "CV", name: "CAPE VERDE (CV)" },
  { value: "KY", name: "CAYMAN ISLANDS (KY)" },
  { value: "CF", name: "CENTRAL AFRICAN REPUBLIC (CF)" },
  { value: "TD", name: "CHAD (TD)" },
  { value: "CL", name: "CHILE (CL)" },
  { value: "CN", name: "CHINA (CN)" },
  { value: "CX", name: "CHRISTMAS ISLAND (CX)" },
  { value: "CC", name: "COCOS (KEELING) ISLANDS (CC)" },
  { value: "CO", name: "COLOMBIA (CO)" },
  { value: "KM", name: "COMOROS (KM)" },
  { value: "CG", name: "CONGO (CG)" },
  { value: "CD", name: "CONGO, THE DEMOCRATIC REPUBLIC OF THE (CD)" },
  { value: "CK", name: "COOK ISLANDS (CK)" },
  { value: "CR", name: "COSTA RICA (CR)" },
  { value: "HR", name: "CROATIA (HR)" },
  { value: "CU", name: "CUBA (CU)" },
  { value: "CY", name: "CYPRUS (CY)" },
  { value: "CZ", name: "CZECH REPUBLIC (CZ)" },
  { value: "DK", name: "DENMARK (DK)" },
  { value: "DJ", name: "DJIBOUTI (DJ)" },
  { value: "DM", name: "DOMINICA (DM)" },
  { value: "DO", name: "DOMINICAN REPUBLIC (DO)" },
  { value: "EC", name: "ECUADOR (EC)" },
  { value: "EG", name: "EGYPT (EG)" },
  { value: "SV", name: "EL SALVADOR (SV)" },
  { value: "GQ", name: "EQUATORIAL GUINEA (GQ)" },
  { value: "ER", name: "ERITREA (ER)" },
  { value: "EE", name: "ESTONIA (EE)" },
  { value: "ET", name: "ETHIOPIA (ET)" },
  { value: "FK", name: "FALKLAND ISLANDS (MALVINAS) (FK)" },
  { value: "FO", name: "FAROE ISLANDS (FO)" },
  { value: "FJ", name: "FIJI (FJ)" },
  { value: "FI", name: "FINLAND (FI)" },
  { value: "FR", name: "FRANCE (FR)" },
  { value: "GF", name: "FRENCH GUIANA (GF)" },
  { value: "PF", name: "FRENCH POLYNESIA (PF)" },
  { value: "TF", name: "FRENCH SOUTHERN TERRITORIES (TF)" },
  { value: "GA", name: "GABON (GA)" },
  { value: "GM", name: "GAMBIA (GM)" },
  { value: "GE", name: "GEORGIA (GE)" },
  { value: "DE", name: "GERMANY (DE)" },
  { value: "GH", name: "GHANA (GH)" },
  { value: "GI", name: "GIBRALTAR (GI)" },
  { value: "GR", name: "GREECE (GR)" },
  { value: "GL", name: "GREENLAND (GL)" },
  { value: "GD", name: "GRENADA (GD)" },
  { value: "GP", name: "GUADELOUPE (GP)" },
  { value: "GT", name: "GUATEMALA (GT)" },
  { value: "GG", name: "GUERNSEY (GG)" },
  { value: "GN", name: "GUINEA (GN)" },
  { value: "GW", name: "GUINEA-BISSAU (GW)" },
  { value: "GY", name: "GUYANA (GY)" },
  { value: "HT", name: "HAITI (HT)" },
  { value: "HM", name: "HEARD ISLAND AND MCDONALD ISLANDS (HM)" },
  { value: "VA", name: "HOLY SEE (VATICAN CITY STATE) (VA)" },
  { value: "HN", name: "HONDURAS (HN)" },
  { value: "HK", name: "HONG KONG (HK)" },
  { value: "HU", name: "HUNGARY (HU)" },
  { value: "IS", name: "ICELAND (IS)" },
  { value: "IN", name: "INDIA (IN)" },
  { value: "ID", name: "INDONESIA (ID)" },
  { value: "IR", name: "IRAN, ISLAMIC REPUBLIC OF (IR)" },
  { value: "IQ", name: "IRAQ (IQ)" },
  { value: "IE", name: "IRELAND (IE)" },
  { value: "IL", name: "ISRAEL (IL)" },
  { value: "IT", name: "ITALY (IT)" },
  { value: "JM", name: "JAMAICA (JM)" },
  { value: "JP", name: "JAPAN (JP)" },
  { value: "JO", name: "JORDAN (JO)" },
  { value: "KZ", name: "KAZAKHSTAN (KZ)" },
  { value: "KE", name: "KENYA (KE)" },
  { value: "KI", name: "KIRIBATI (KI)" },
  { value: "KP", name: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF (KP)" },
  { value: "KR", name: "KOREA, REPUBLIC OF (KR)" },
  { value: "KW", name: "KUWAIT (KW)" },
  { value: "KG", name: "KYRGYZSTAN (KG)" },
  { value: "LA", name: "LAO PEOPLE'S DEMOCRATIC REPUBLIC (LA)" },
  { value: "LV", name: "LATVIA (LV)" },
  { value: "LB", name: "LEBANON (LB)" },
  { value: "LS", name: "LESOTHO (LS)" },
  { value: "LR", name: "LIBERIA (LR)" },
  { value: "LY", name: "LIBYAN ARAB JAMAHIRIYA (LY)" },
  { value: "LI", name: "LIECHTENSTEIN (LI)" },
  { value: "LT", name: "LITHUANIA (LT)" },
  { value: "LU", name: "LUXEMBOURG (LU)" },
  { value: "MO", name: "MACAO (MO)" },
  { value: "MK", name: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF (MK)" },
  { value: "MG", name: "MADAGASCAR (MG)" },
  { value: "MW", name: "MALAWI (MW)" },
  { value: "MY", name: "MALAYSIA (MY)" },
  { value: "MV", name: "MALDIVES (MV)" },
  { value: "ML", name: "MALI (ML)" },
  { value: "MT", name: "MALTA (MT)" },
  { value: "MH", name: "MARSHALL ISLANDS (MH)" },
  { value: "MQ", name: "MARTINIQUE (MQ)" },
  { value: "MR", name: "MAURITANIA (MR)" },
  { value: "MU", name: "MAURITIUS (MU)" },
  { value: "YT", name: "MAYOTTE (YT)" },
  { value: "MX", name: "MEXICO (MX)" },
  { value: "FM", name: "MICRONESIA, FEDERATED STATES OF (FM)" },
  { value: "MD", name: "MOLDOVA, REPUBLIC OF (MD)" },
  { value: "MC", name: "MONACO (MC)" },
  { value: "MN", name: "MONGOLIA (MN)" },
  { value: "MS", name: "MONTSERRAT (MS)" },
  { value: "MA", name: "MOROCCO (MA)" },
  { value: "MZ", name: "MOZAMBIQUE (MZ)" },
  { value: "MM", name: "MYANMAR (MM)" },
  { value: "NA", name: "NAMIBIA (NA)" },
  { value: "NR", name: "NAURU (NR)" },
  { value: "NP", name: "NEPAL (NP)" },
  { value: "NL", name: "NETHERLANDS (NL)" },
  { value: "AN", name: "NETHERLANDS ANTILLES (AN)" },
  { value: "NC", name: "NEW CALEDONIA (NC)" },
  { value: "NZ", name: "NEW ZEALAND (NZ)" },
  { value: "NI", name: "NICARAGUA (NI)" },
  { value: "NE", name: "NIGER (NE)" },
  { value: "NG", name: "NIGERIA (NG)" },
  { value: "NU", name: "NIUE (NU)" },
  { value: "NF", name: "NORFOLK ISLAND (NF)" },
  { value: "MP", name: "NORTHERN MARIANA ISLANDS (MP)" },
  { value: "NO", name: "NORWAY (NO)" },
  { value: "OM", name: "OMAN (OM)" },
  { value: "PK", name: "PAKISTAN (PK)" },
  { value: "PW", name: "PALAU (PW)" },
  { value: "PS", name: "PALESTINIAN TERRITORY, OCCUPIED (PS)" },
  { value: "PA", name: "PANAMA (PA)" },
  { value: "PG", name: "PAPUA NEW GUINEA (PG)" },
  { value: "PY", name: "PARAGUAY (PY)" },
  { value: "PE", name: "PERU (PE)" },
  { value: "PH", name: "PHILIPPINES (PH)" },
  { value: "PN", name: "PITCAIRN (PN)" },
  { value: "PL", name: "POLAND (PL)" },
  { value: "PT", name: "PORTUGAL (PT)" },
  { value: "PR", name: "PUERTO RICO (PR)" },
  { value: "QA", name: "QATAR (QA)" },
  { value: "RE", name: "RÉ,UNION (RE)" },
  { value: "RO", name: "ROMANIA (RO)" },
  { value: "RU", name: "RUSSIAN FEDERATION (RU)" },
  { value: "RW", name: "RWANDA (RW)" },
  { value: "SH", name: "SAINT HELENA (SH)" },
  { value: "KN", name: "SAINT KITTS AND NEVIS (KN)" },
  { value: "LC", name: "SAINT LUCIA (LC)" },
  { value: "PM", name: "SAINT PIERRE AND MIQUELON (PM)" },
  { value: "VC", name: "SAINT VINCENT AND THE GRENADINES (VC)" },
  { value: "WS", name: "SAMOA (WS)" },
  { value: "SM", name: "SAN MARINO (SM)" },
  { value: "ST", name: "SAO TOME AND PRINCIPE (ST)" },
  { value: "SA", name: "SAUDI ARABIA (SA)" },
  { value: "SN", name: "SENEGAL (SN)" },
  { value: "CS", name: "SERBIA AND MONTENEGRO (CS)" },
  { value: "SC", name: "SEYCHELLES (SC)" },
  { value: "SL", name: "SIERRA LEONE (SL)" },
  { value: "SG", name: "SINGAPORE (SG)" },
  { value: "SK", name: "SLOVAKIA (SK)" },
  { value: "SI", name: "SLOVENIA (SI)" },
  { value: "SB", name: "SOLOMON ISLANDS (SB)" },
  { value: "SO", name: "SOMALIA (SO)" },
  { value: "ZA", name: "SOUTH AFRICA (ZA)" },
  { value: "GS", name: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS (GS)" },
  { value: "ES", name: "SPAIN (ES)" },
  { value: "LK", name: "SRI LANKA (LK)" },
  { value: "SD", name: "SUDAN (SD)" },
  { value: "SR", name: "SURINAME (SR)" },
  { value: "SJ", name: "SVALBARD AND JAN MAYEN (SJ)" },
  { value: "SZ", name: "SWAZILAND (SZ)" },
  { value: "SE", name: "SWEDEN (SE)" },
  { value: "CH", name: "SWITZERLAND (CH)" },
  { value: "SY", name: "SYRIAN ARAB REPUBLIC (SY)" },
  { value: "TW", name: "TAIWAN, PROVINCE OF CHINA (TW)" },
  { value: "TJ", name: "TAJIKISTAN (TJ)" },
  { value: "TZ", name: "TANZANIA, UNITED REPUBLIC OF (TZ)" },
  { value: "TH", name: "THAILAND (TH)" },
  { value: "TL", name: "TIMOR-LESTE (TL)" },
  { value: "TG", name: "TOGO (TG)" },
  { value: "TK", name: "TOKELAU (TK)" },
  { value: "TO", name: "TONGA (TO)" },
  { value: "TT", name: "TRINIDAD AND TOBAGO (TT)" },
  { value: "TN", name: "TUNISIA (TN)" },
  { value: "TR", name: "TURKEY (TR)" },
  { value: "TM", name: "TURKMENISTAN (TM)" },
  { value: "TC", name: "TURKS AND CAICOS ISLANDS (TC)" },
  { value: "TV", name: "TUVALU (TV)" },
  { value: "UG", name: "UGANDA (UG)" },
  { value: "UA", name: "UKRAINE (UA)" },
  { value: "AE", name: "UNITED ARAB EMIRATES (AE)" },
  { value: "GB", name: "UNITED KINGDOM (GB)" },
  { value: "US", name: "UNITED STATES (US)" },
  { value: "UM", name: "UNITED STATES MINOR OUTLYING ISLANDS (UM)" },
  { value: "US-AL", name: "ALABAMA (US-AL)" },
  { value: "US-AK", name: "ALASKA (US-AK)" },
  { value: "US-AZ", name: "ARIZONA (US-AZ)" },
  { value: "US-AR", name: "ARKANSAS (US-AR)" },
  { value: "US-CA", name: "CALIFORNIA (US-CA)" },
  { value: "US-CO", name: "COLORADO (US-CO)" },
  { value: "US-CT", name: "CONNECTICUT (US-CT)" },
  { value: "US-DE", name: "DELAWARE (US-DE)" },
  { value: "US-DC", name: "DISTRICT OF COLUMBIA (US-DC)" },
  { value: "US-FL", name: "FLORIDA (US-FL)" },
  { value: "US-GA", name: "GEORGIA (US-GA)" },
  { value: "US-HI", name: "HAWAII (US-HI)" },
  { value: "US-ID", name: "IDAHO (US-ID)" },
  { value: "US-IL", name: "ILLINOIS (US-IL)" },
  { value: "US-IN", name: "INDIANA (US-IN)" },
  { value: "US-IA", name: "IOWA (US-IA)" },
  { value: "US-KS", name: "KANSAS (US-KS)" },
  { value: "US-KY", name: "KENTUCKY (US-KY)" },
  { value: "US-LA", name: "LOUISIANA (US-LA)" },
  { value: "US-ME", name: "MAINE (US-ME)" },
  { value: "US-MD", name: "MARYLAND (US-MD)" },
  { value: "US-MA", name: "MASSACHUSETTS (US-MA)" },
  { value: "US-MI", name: "MICHIGAN (US-MI)" },
  { value: "US-MN", name: "MINNESOTA (US-MN)" },
  { value: "US-MS", name: "MISSISSIPPI (US-MS)" },
  { value: "US-MO", name: "MISSOURI (US-MO)" },
  { value: "US-MT", name: "MONTANA (US-MT)" },
  { value: "US-NE", name: "NEBRASKA (US-NE)" },
  { value: "US-NV", name: "NEVADA (US-NV)" },
  { value: "US-NH", name: "NEW HAMPSHIRE (US-NH)" },
  { value: "US-NJ", name: "NEW JERSEY (US-NJ)" },
  { value: "US-NM", name: "NEW MEXICO (US-NM)" },
  { value: "US-NY", name: "NEW YORK (US-NY)" },
  { value: "US-NC", name: "NORTH CAROLINA (US-NC)" },
  { value: "US-ND", name: "NORTH DAKOTA (US-ND)" },
  { value: "US-OH", name: "OHIO (US-OH)" },
  { value: "US-OK", name: "OKLAHOMA (US-OK)" },
  { value: "US-OR", name: "OREGON (US-OR)" },
  { value: "US-PA", name: "PENNSYLVANIA (US-PA)" },
  { value: "US-RI", name: "RHODE ISLAND (US-RI)" },
  { value: "US-SC", name: "SOUTH CAROLINA (US-SC)" },
  { value: "US-SD", name: "SOUTH DAKOTA (US-SD)" },
  { value: "US-TN", name: "TENNESSEE (US-TN)" },
  { value: "US-TX", name: "TEXAS (US-TX)" },
  { value: "US-UT", name: "UTAH (US-UT)" },
  { value: "US-VT", name: "VERMONT (US-VT)" },
  { value: "US-VA", name: "VIRGINIA (US-VA)" },
  { value: "US-WA", name: "WASHINGTON (US-WA)" },
  { value: "US-WV", name: "WEST VIRGINIA (US-WV)" },
  { value: "US-WI", name: "WISCONSIN (US-WI)" },
  { value: "US-WY", name: "WYOMING (US-WY)" },
  { value: "UY", name: "URUGUAY (UY)" },
  { value: "UZ", name: "UZBEKISTAN (UZ)" },
  { value: "VU", name: "VANUATU (VU)" },
  { value: "VE", name: "VENEZUELA (VE)" },
  { value: "VN", name: "VIET NAM (VN)" },
  { value: "VG", name: "VIRGIN ISLANDS, BRITISH (VG)" },
  { value: "VI", name: "VIRGIN ISLANDS, U.S. (VI)" },
  { value: "WF", name: "WALLIS AND FUTUNA (WF)" },
  { value: "EH", name: "WESTERN SAHARA (EH)" },
  { value: "YE", name: "YEMEN (YE)" },
  { value: "ZM", name: "ZAMBIA (ZM)" },
  { value: "ZW", name: "ZIMBABWE (ZW)" },
];

// eslint-disable-next-line no-unused-vars
const QUALIFICATION_PROD = [
  { value: "3DWHSFAI6V6LLE6N73LD41NC328HA9", name: "Age - 18-25" },
  { value: "3KAU8F4DS29KIEY0ZKYNZG93O0HPN6", name: "Age - 25-30" },
  { value: "362T88CWSBZ89UNF27OEYG2KXGMSD7", name: "Age - 30-35" },
  { value: "3LLOUF5SOO5RS1LW45IHW772RRZMUZ", name: "Age - 35-45" },
  { value: "3YVJFZ3OL3WO7MYJTQ5K0WEIOULEIU", name: "Age - 45-55" },
  { value: "3A52214HXRPE0WD0NJJNM1K3JA7PM6", name: "Age - 55 or older" },
  { value: "3TZRB59MJX9EZ3CJ289HU3GCYFFPZF", name: "Borrower - Auto Loans" },
  { value: "30WE6L5BIE9XY0JQQFGVLZAAYXVC5K", name: "Borrower - Business Loan" },
  { value: "3N2JCLSAPVSJ3M45SDHFA9TDUKFXYF", name: "Borrower - Credit Cards" },
  { value: "3D0GLTVX4T0NNPQSP9L19L2DEAFL3G", name: "Borrower - Home Mortgage" },
  { value: "33IG9TQV30U24JSQUAFE2EMAQWQQ1K", name: "Borrower - Personal Loan" },
  { value: "3CNI31T0B4UBAG4GYWW7VI682GZ3B9", name: "Borrower - Student Loan" },
  {
    value: "39YXZTLR4QPV5E3FX3NBA3H5ZVWCZI",
    name: "Current Residence - Owned",
  },
  {
    value: "3LKGEFGLB5GRI2HNAZ1N97O9AEW7EE",
    name: "Current Residence - Rented",
  },
  {
    value: "3N60G9VU1ZLD7G2LLJKE8XXW7S6E1Y",
    name: "Daily Internet Usage - 1 to 4 hours",
  },
  {
    value: "31UNFWDGB9IC0EALTXUCE613CRCGTQ",
    name: "Daily Internet Usage - 5 to 7 hours",
  },
  {
    value: "3FPQM8KMWPO5BZAAIASSM73E5R0MDL",
    name: "Daily Internet Usage - 7+ hours",
  },
  {
    value: "3NRTOFVPEPMYQNL5UTIXVXBCK2GR3O",
    name: "Employment Industry - Banking & Financial Services",
  },
  {
    value: "3Y5W7PXQK1CVDVSABGG3OAKDFPOOA8",
    name: "Employment Industry - Education",
  },
  {
    value: "3CJL7HHWEWBJ8J49U7NG523IY2CR49",
    name: "Employment Industry - Food & Beverage",
  },
  {
    value: "37U44YZ6NLP71XCREIOLWTX5IT5G3H",
    name: "Employment Industry - Government & Non-Profit",
  },
  {
    value: "3YCLS7T9O1Q0W5X2HUGZJL4JX79OB3",
    name: "Employment Industry - Healthcare",
  },
  {
    value: "3IUNJINV144Y83SHSUDOPSJ2A7DLJ4",
    name: "Employment Industry - Manufacturing",
  },
  {
    value: "3516A3CDOFVJ6AP8VUCUU5A1FS9OCA",
    name: "Employment Industry - Media & Entertainment",
  },
  {
    value: "33JQ0O0WIQ78X00L82YU09NR6G8TWA",
    name: "Employment Industry - Retail, Wholesale & Distribution",
  },
  {
    value: "3WZ6TNF1F40TJNYZAFD0Q7JFPWDLK3",
    name: "Employment Industry - Software & IT Services",
  },
  {
    value: "38N62D8ZZITEJF95RGO3TB2QXECUC8",
    name: "Employment Sector - Non-Profit",
  },
  {
    value: "3CN779IP5ARANI0OHMQV5W7HSJ2OH5",
    name: "Employment Status - Full time (35+ hours per week)",
  },
  {
    value: "33PZ965V93XK5PALB2J7MAEYKTER9R",
    name: "Employment Status - Part time (1-34 hours per week)",
  },
  {
    value: "38K25CGMOFSUWCZ7BJZJCFJZTGUU1P",
    name: "Employment Status - Unemployed",
  },
  { value: "3516A3CDOFVJ6AP8VUCUU5A1FS3COS", name: "Exercise - Every Day" },
  {
    value: "3CSEDZSBFSFSRC2T2S28OIM5GEW704",
    name: "Exercise - Four Plus Times a Week",
  },
  { value: "381E5BGASO1DQEPDVYHGEL8D8AI484", name: "Exercise - Not at All" },
  { value: "3090W3GIDVP1OPPWUFCHFGAWPIYCND", name: "Exercise - Once a Week" },
  {
    value: "352JK99ZK9Q5DU2HGU4KQFIWJUL1F5",
    name: "Exercise - Two to Three Times a Week",
  },
  {
    value: "3OFC1SZPZBGYILXQLIAALYSKP2K9KI",
    name: "Financial Asset Owned - Certificate of Deposit (CD)",
  },
  {
    value: "3XQ9NVMYZF1MNFFC46BIDSICXKHMHK",
    name: "Financial Asset Owned - Checking Account",
  },
  {
    value: "3U6957PSHU11P81WN7TSION9K16KO7",
    name: "Financial Asset Owned - Common Share Stocks",
  },
  {
    value: "3MCN53K310JJA8DX27677OSZU62KPO",
    name: "Financial Asset Owned - Exchange-Traded Fund (ETF)",
  },
  {
    value: "37W5KW7BZVI2Y3RK7TUYYF9JGW1SQ4",
    name: "Financial Asset Owned - Money Market Account",
  },
  {
    value: "3P251L9XP7U9T4AMDEFS7XWFI22Q2Z",
    name: "Financial Asset Owned - Mutual Funds",
  },
  {
    value: "3KAW48Y220TUQTG7L3ZR85IRJ6P6OR",
    name: "Financial Asset Owned - Real Estate Investment Trusts (REITs)",
  },
  {
    value: "3JNQNCT8EX31RTFB185PGFFLNYPJUF",
    name: "Financial Asset Owned - Savings Account",
  },
  {
    value: "36WA3USMNR67GVWIFX7D86CSXLJ9HH",
    name: "Financial Asset Owned - Stock Options",
  },
  {
    value: "3GR2JZHT4D1GD0YFO1PQIEZI0WQFI5",
    name: "Financial Asset Owned - U.S. Treasury Bills/Government Debt",
  },
  { value: "3ZA6VN4RCNNAUKDJ130LXNQBVABXQN", name: "Gender - Female" },
  { value: "3L339059XKHPK185F1OKFVBZUGV0H0", name: "Gender - Male" },
  { value: "3HN7AXFHKYFFQJJCOXOT3KK602PST5", name: "Handedness - Left" },
  { value: "3CYRGFX2KAGUQWY1O2E38G68JGWSUW", name: "Handedness - Right" },
  {
    value: "3QQSTXYW62LN5NO84HIPXJWWTG676R",
    name: "Household Income - $100,000 or more",
  },
  {
    value: "3HWVXYKNY1KLH9H7DL3Y4DOR3F7IAN",
    name: "Household Income - $25,000 - $49,999",
  },
  {
    value: "37RZ1IA9BMTRDMCD257ILSNGGPVL1G",
    name: "Household Income - $50,000 - $74,999",
  },
  {
    value: "35Y2D2K0DCUZJ7FAFXNAD9RABU01OD",
    name: "Household Income - $75,000 - $99,999",
  },
  {
    value: "3GR2JZHT4D1GD0YFO1PQIEZI0VKFIX",
    name: "Household Income - Less than $25,000",
  },
  {
    value: "3TW6ZST7655PU8C41QLWXI01QFCXPL",
    name: "Insurance Policyholder - Auto Insurance",
  },
  {
    value: "31QNSEW3RLFMJAQWAOT3D4JRXXXMIE",
    name: "Insurance Policyholder - Healthcare Insurance",
  },
  {
    value: "3ZT0H6K5ONSI316L94M3AURAH0KFLJ",
    name: "Insurance Policyholder - Home Owners Insurance",
  },
  {
    value: "3UV5AGTJTXIOBRNZN8NZ0KD3OIUEHR",
    name: "Insurance Policyholder - Life Insurance",
  },
  {
    value: "3AWO8D2RFXK7CQ72LFPY8ID7XYY9IA",
    name: "Insurance Policyholder - Renters Insurance",
  },
  {
    value: "3WU6VAO3A6EXN68NDVGV20NWUTIR6U",
    name: "Job Function - Accounting & Finance",
  },
  {
    value: "3USHEAR81307LIBCNX3VJ1BBO3XAG7",
    name: "Job Function - Administrative",
  },
  {
    value: "3V2NCA4P7579S86DRL2J8RSKTT2DBV",
    name: "Job Function - Arts & Design",
  },
  {
    value: "36YI3I1DYLN87YE90ZGSDPACW367UD",
    name: "Job Function - Education & Training",
  },
  {
    value: "3HIOL0MDAM28KD395PQX9BCVAAF7V2",
    name: "Job Function - Engineering",
  },
  {
    value: "3X03TQUWQDTQ9M9IMW2V2BV4C51OFL",
    name: "Job Function - Information Technology",
  },
  {
    value: "33LB8PNHHTOBOBWQY1ILADQ251GU4G",
    name: "Job Function - Management",
  },
  {
    value: "32R8U6NT7JCS2DYJ5LYEJBY2BE2DC2",
    name: "Job Function - Marketing, Sales & Business Development",
  },
  {
    value: "3VDUABUHOI9TKU0QC61VF5WG5XBU5L",
    name: "Job Function - Operations",
  },
  {
    value: "3KSLQLZFBV6O9BY2VRQG5KBJE18LM8",
    name: "Language Fluency (Basic) - Brazilian Portuguese",
  },
  {
    value: "3KSRVTP5A9P9ET3LRHH4AWD036IHC2",
    name: "Language Fluency (Basic) - Chinese Mandarin",
  },
  {
    value: "35ZCVQVYQ96BLEX64XPM415HA9RSG8",
    name: "Language Fluency (Basic) - French",
  },
  {
    value: "39G8VCQFG4E57E1BTDJ4MG559URSF9",
    name: "Language Fluency (Basic) - German",
  },
  {
    value: "3O3C9VE8V1CPLASHO374P1HIP94SH7",
    name: "Language Fluency (Basic) - Spanish",
  },
  {
    value: "3SVBP8NUKC741C30HH6WT5VDV9BF5K",
    name: "Marital Status - Divorced",
  },
  { value: "38TZCON5HMNS4DN924LVU8DM20XPIY", name: "Marital Status - Married" },
  { value: "3C8RYQJ3V2MT89XT6UNQ0BKOE669MC", name: "Marital Status - Single" },
  {
    value: "33IG9TQV30U24JSQUAFE2EMAQWOQ1I",
    name: "Online Purchase - Automotive Products",
  },
  {
    value: "34TL75D0PG9S8ICPWM3AUWC72PWPHN",
    name: "Online Purchase - Baby & Kids",
  },
  { value: "3VM8BVD4MFUH6ECNJ2O6C4NAD2IKBY", name: "Online Purchase - Books" },
  {
    value: "3LQVAWME7LMA7ZRYHYU52LISC7XB6J",
    name: "Online Purchase - Clothing & Shoes",
  },
  {
    value: "347ZKNUE36WD5H8MN16V04ESK09S5M",
    name: "Online Purchase - Electronics & Computers",
  },
  {
    value: "33I37607V28KFJCBTVOTHO9I42PQ8X",
    name: "Online Purchase - Groceries & Food",
  },
  {
    value: "3EG4VP8Y3KQ2HXXR4UA3DF3NXFXHLJ",
    name: "Online Purchase - Handmade Products",
  },
  {
    value: "35BVSWWGO1PCYWR2KMOHN73150MV9L",
    name: "Online Purchase - Health & Beauty",
  },
  {
    value: "35BVSWWGO1PCYWR2KMOHN73150NV9M",
    name: "Online Purchase - Home & Garden",
  },
  {
    value: "3I33WYIJ9CJ32C8P009TUALM2VQH78",
    name: "Online Purchase - Jewelry",
  },
  { value: "32O4X5VGWGB8FAOLPO9U2FFB7HV1DK", name: "Online Purchase - Movies" },
  { value: "3JC3LXC4BIZUKLWMCWS262CQK1Y3CF", name: "Online Purchase - Music" },
  {
    value: "3F81WX5QE0K4XVWTSIZZXZ1Q83HEFT",
    name: "Online Purchase - Sports & Outdoor Equipment",
  },
  { value: "3NNOZZ17WAMAR2LI9II2L7G864K3S9", name: "Online Purchase - Toys" },
  {
    value: "38XLHGG4PNI4PD20CSNDQ8U851A3DF",
    name: "Online Purchase - Videogames",
  },
  {
    value: "34FD3JAXAUPRR8KFJU8B493B6V8ZUK",
    name: "Primary Internet Device - Desktop",
  },
  {
    value: "3AZSL89P122CVY34M6MQBNADJ875E3",
    name: "Primary Internet Device - Laptop",
  },
  {
    value: "36YI3I1DYLN87YE90ZGSDPACW2VU7N",
    name: "Primary Internet Device - Smartphone or Tablet",
  },
  {
    value: "3LPLT0UQKO3E8MHECGLS9W06NN0GPS",
    name: "Primary Mobile Device - Android",
  },
  {
    value: "3GYFJBAR5GJ55GZO1DG3G2QLE0NDY9",
    name: "Primary Mobile Device - iPhone",
  },
  {
    value: "3J9L42OG8J35WIV5JQKP4E0LAJYUIC",
    name: "Primary News Source - Online News (News Websites, News Apps)",
  },
  {
    value: "3FIQQ827RGNC88GVKOZETGIMTWK6NC",
    name: "Primary News Source - Podcasts",
  },
  {
    value: "3BR5WBA9O5CABVSO325HFK129IHEGS",
    name: "Primary News Source - Print (Newspapers & Periodicals)",
  },
  {
    value: "3RKPRCCAXU9D101FY2ZY4YK5XT9SEO",
    name: "Primary News Source - Radio (AM/FM, Internet, Satellite)",
  },
  {
    value: "3GJ2YWF1397Z08EFNWYCM6ADO7EMQH",
    name: "Primary News Source - Social Media",
  },
  {
    value: "31I5O1WRTD0HYRQ57GF9YAJ9B0FSPD",
    name: "Primary News Source - TV (Late Night Comedy, Other)",
  },
  {
    value: "3ENYA26IMHKFPAZ0FV59TEEN5BD0TZ",
    name: "Primary News Source - TV (Local/Cable News Broadcast)",
  },
  {
    value: "34CSR6UIX2L2MDK0ICKQ7ON0YW5XUI",
    name: "Primary News Source - Word of Mouth",
  },
  {
    value: "33YW7GZLPAFL6KPZ5I4X2XBG0O5S24",
    name: "US Political Affiliation - Conservative",
  },
  {
    value: "33JPNCO5K9MZ3NX69YSPG1RHL3YXL6",
    name: "US Political Affiliation - Liberal",
  },
  {
    value: "3XYNUJ3W3LMZNM57HF6M76FKDCH72P",
    name: "Vacation Frequency - Every Few Years",
  },
  {
    value: "3OP13B7XRC2PNL47H2A5TCVOZME1IX",
    name: "Vacation Frequency - Every Month",
  },
  {
    value: "3D7IO5DB211EXB1N746VICF8JW2NM6",
    name: "Vacation Frequency - Every Quarter",
  },
  {
    value: "3PUFX7K0N27ED168PNEYZME163PW2F",
    name: "Vacation Frequency - Every Year",
  },
  {
    value: "3HNFM25IALTO841QLFKRQGKCJQH730",
    name: "Vacation Frequency - Never",
  },
  {
    value: "31I5O1WRTD0HYRQ57GF9YAJ9BZSSPO",
    name: "Social Media - Facebook Account Holder",
  },
  {
    value: "32ZCPHM80CAMDRFATRHE7Z6Y66VYIY",
    name: "Social Media - Google Account Holder",
  },
  {
    value: "3XS9XHLESXZW2DLTJT51HUFY2M9KUX",
    name: "Social Media - LinkedIn Account Holder",
  },
  {
    value: "3W17X9IGO7LEWI06MTWPQEBHY03ZNI",
    name: "Social Media - Myspace Account Holder",
  },
  {
    value: "32DH8RR06QAI82ZGB92EJ0LYJLKYJA",
    name: "Social Media - Pinterest Account Holder",
  },
  {
    value: "32HPRZ3TSLYH2064JOFRCYTWJ82KT6",
    name: "Social Media - Reddit Account Holder",
  },
  {
    value: "38CFFZ6E4YEWFSATOJF6DGHHGTIKVM",
    name: "Social Media - Tumblr Account Holder",
  },
  {
    value: "3KSRVTP5A9P9ET3LRHH4AWD035YCHB",
    name: "Social Media - Twitter Account Holder",
  },
  {
    value: "3GI0IWJ6KQ6DJM5YTU2QKFLBYAKYK9",
    name: "Social Media - YouTube Account Holder",
  },
  {
    value: "3PDP75MATNEQJU2M3DN7WU0F5763V4",
    name: "US Education - US Bachelor's Degree",
  },
  {
    value: "3FWY9OFI9302ZS90N2UV3APJC2AVXZ",
    name: "US Education - US Graduate Degree",
  },
  {
    value: "3D95X78DG55X7Z43AZIOMTK5O7D8TE",
    name: "US Education - US High School Graduate",
  },
  {
    value: "38SUGEXZPORO1M4JW2TJ93MRWJRVDO",
    name: "US Election - Voted in 2012 US Presidential Election",
  },
  {
    value: "37U3YJ2MMPUB0PMBI2W22O67KNZCLC",
    name: "US Election - Voted in 2016 US Presidential Election",
  },
  { value: "35NJOM7A2AHJ8CEAOZJYOG2F0DRZXE", name: "Other - Blogger" },
  { value: "3386W7HUQQSXQJ791BKE9X3GA3WPV0", name: "Other - Car Owner" },

  {
    value: "3Q1RR1YLZJITU4EJ12HC0FPDU01M8D",
    name: "Other - Military experience",
  },
  {
    value: "3J4VL7BYSILCKR8SFUI3NB8QLSUV8I",
    name: "Other - Parenthood Status",
  },

  {
    value: "3EG4VP8Y3KQ2HXXR4UA3DF3NXEVLHJ",
    name: "Other - Single Family Home Resident",
  },
  { value: "3RESSZVHR4L0V1E1D9V5LNAGGXAHHW", name: "Other - Smoker" },
  { value: "3TKFE7XEDHKIEKXQGFGM4W4E7VDNBS", name: "Other - Tablet Owner" },
];

const QUALIFICATION_DEV = [
  { name: "Age - 18-25", value: "3UAQCEERORX7JVO3O49VXC7OQZ2FK1" },
  { name: "Age - 25-30", value: "33PZ965V93XK5PALB2I0OS7RIT19RW" },
  { name: "Age - 30-35", value: "39VCNG5CRYL60J30WLYJF0UNPVPCXC" },
  { name: "Age - 35-45", value: "33LUKQBDROXW64AYQRI5E2CN4VK9SK" },
  { name: "Age - 45-55", value: "3RDXNS6RRB0V0OTA1HKLYE1WR1JCYI" },
  { name: "Age - 55 or older", value: "37RZ1IA9BMTRDMCD256BNAG9EPOL19" },
  { name: "Borrower - Auto Loans", value: "39VCNG5CRYL60J30WLYJF0UNPW4XCE" },
  { name: "Borrower - Business Loan", value: "3VG0IABS94XN0BZR0CCIGSSBH74OQ9" },
  { name: "Borrower - Credit Cards", value: "3F540QR0WZ78IJAPGO2L1HBT0AZ65Z" },
  { name: "Borrower - Home Mortgage", value: "3RDXNS6RRB0V0OTA1HKLYE1WR2ZCY0" },
  { name: "Borrower - Personal Loan", value: "3S7V3UIV5MQDGQ3OFWOD1L6U1K7IR9" },
  { name: "Borrower - Student Loan", value: "3N6NEM56T1ZVIGM6K4SMPPDXJZV8EX" },
  {
    name: "Current Residence - Owned",
    value: "38CFFZ6E4YEWFSATOJEZFYAAES4KV6",
  },
  {
    name: "Current Residence - Rented",
    value: "3OS50CZA2F39AOE5XZYICQ781HZICU",
  },
  {
    name: "Daily Internet Usage - 1 to 4 hours",
    value: "3YVJFZ3OL3WO7MYJTQ4D2E7BMTSIE3",
  },
  {
    name: "Daily Internet Usage - 5 to 7 hours",
    value: "3UKR6HO4BPAQ14DW3S8NQ0TQQ4NL5J",
  },
  {
    name: "Daily Internet Usage - 7+ hours",
    value: "32S84VH440P85L9WPNPDL8NCQ8SL4E",
  },
  {
    name: "Employment Industry - Banking & Financial Services",
    value: "3ZG54WTVCO9XL4NDFRNVO7896FRT9O",
  },
  {
    name: "Employment Industry - Education",
    value: "381E5BGASO1DQEPDVYG9G3166AT48F",
  },
  {
    name: "Employment Industry - Food & Beverage",
    value: "36GCTHBRVS9BQNXI7X1VK2JTCX9TA3",
  },
  {
    name: "Employment Industry - Government & Non-Profit",
    value: "3IMKWLF0QJ62V2P9CPPURHO6K81W66",
  },
  {
    name: "Employment Industry - Healthcare",
    value: "3VG0IABS94XN0BZR0CCIGSSBH7EQOL",
  },
  {
    name: "Employment Industry - Manufacturing",
    value: "3U8EC01SO75D4J8N0QMNGZWHQGB494",
  },
  {
    name: "Employment Industry - Media & Entertainment",
    value: "318L1LJO7B5R92ISSW0NCU71WYT4AJ",
  },
  {
    name: "Employment Industry - Retail, Wholesale & Distribution",
    value: "3X9X6Q117EWFUQWUNKWQOBOHM29QL4",
  },
  {
    name: "Employment Industry - Software & IT Services",
    value: "3VSNQAPCUINZ6QITJEAGN90IR3FYR5",
  },
  {
    name: "Employment Sector - Non-Profit",
    value: "3YJFVCPRIV85A69TXZHFWRSGIVLZDI",
  },
  {
    name: "Employment Status - Full time (35+ hours per week)",
    value: "37O6MXR7LKP41NA3L7CQTNPTW4Z662",
  },
  {
    name: "Employment Status - Part time (1-34 hours per week)",
    value: "3WALDZ9X0OB7T78DPLL9BGAVCDC3M3",
  },
  {
    name: "Employment Status - Unemployed",
    value: "36FDJD9TVZVDBPTD1M09OVZVH3M3N3",
  },
  { name: "Exercise - Every Day", value: "3HIOL0MDAM28KD395PPQBT5O8ADV7O" },
  {
    name: "Exercise - Four Plus Times a Week",
    value: "3XDG87E9EDCIDR3KWGJSAX8OBJ3PT9",
  },
  { name: "Exercise - Not at All", value: "3YKCX89WCYJVC8U03Y3Z1CBBM7BN7Z" },
  { name: "Exercise - Once a Week", value: "3SO0EPWUEPDXD4I9WL92FTUQUXAPU0" },
  {
    name: "Exercise - Two to Three Times a Week",
    value: "31I5O1WRTD0HYRQ57GE20SC290PSPN",
  },
  {
    name: "Financial Asset Owned - Certificate of Deposit (CD)",
    value: "3XQ9NVMYZF1MNFFC46ABFAB5VKPMHS",
  },
  {
    name: "Financial Asset Owned - Checking Account",
    value: "3CYRGFX2KAGUQWY1O2DWAYZ1HGKUSM",
  },
  {
    name: "Financial Asset Owned - Common Share Stocks",
    value: "3KX8IXT6XLTGSPSUVK1U7H2AVG9P5A",
  },
  {
    name: "Financial Asset Owned - Exchange-Traded Fund (ETF)",
    value: "30IMET1JUJVFS468HLWNQTP0KS6UOG",
  },
  {
    name: "Financial Asset Owned - Money Market Account",
    value: "31QNSEW3RLFMJAQWAOSWFMCKVX4MIL",
  },
  {
    name: "Financial Asset Owned - Mutual Funds",
    value: "3CFGI1NEWHBV9Y4M9WMEXU17I4LS4O",
  },
  {
    name: "Financial Asset Owned - Real Estate Investment Trusts (REITs)",
    value: "3KSLQLZFBV6O9BY2VRP9724CC12LM2",
  },
  {
    name: "Financial Asset Owned - Savings Account",
    value: "32X4SEUEDBB9OU0UR7SXA3RZXZ4TD1",
  },
  {
    name: "Financial Asset Owned - Stock Options",
    value: "3KIOY293YU0U223PKQ4HWEV10W3V0K",
  },
  {
    name: "Financial Asset Owned - U.S. Treasury Bills/Government Debt",
    value: "33GZIFUQUFLJOGHJ5AKMT722KGIF7D",
  },
  { name: "Gender - Female", value: "3ZA6VN4RCNNAUKDJ130LXNQBVABXQN" },
  { name: "Gender - Male", value: "3EDENFEKRDIEJX4GW8JJ0LTB6CQJW0" },
  { name: "Handedness - Left", value: "3AWO8D2RFXK7CQ72LFORA060VWNI94" },
  { name: "Handedness - Right", value: "37RZ1IA9BMTRDMCD256BNAG9EOTL1C" },
  {
    name: "Household Income - $100,000 or more",
    value: "3RAGO4OQ8ADTOL3XK54GWS5D19B8YJ",
  },
  {
    name: "Household Income - $25,000 - $49,999",
    value: "33AD3SBATXLPZG8X54MSBVKXQ1B8WK",
  },
  {
    name: "Household Income - $50,000 - $74,999",
    value: "3NRTOFVPEPMYQNL5UTHQXF45I043RK",
  },
  {
    name: "Household Income - $75,000 - $99,999",
    value: "39SVOSNB8XY4OGDNF9IEDEY4Z3H8XD",
  },
  {
    name: "Household Income - Less than $25,000",
    value: "3J4VL7BYSILCKR8SFUHWPT1JJS98VA",
  },
  {
    name: "Insurance Policyholder - Auto Insurance",
    value: "3NRTOFVPEPMYQNL5UTHQXF45I1L3R3",
  },
  {
    name: "Insurance Policyholder - Healthcare Insurance",
    value: "3IZO2596DL7WFW1Z8J51ZJ8675FOMS",
  },
  {
    name: "Insurance Policyholder - Home Owners Insurance",
    value: "3DHGVT984THT2QM7PXAUWO0OLHW9YG",
  },
  {
    name: "Insurance Policyholder - Life Insurance",
    value: "3LLOUF5SOO5RS1LW45HAYP0VPSDUMN",
  },
  {
    name: "Insurance Policyholder - Renters Insurance",
    value: "3S3QEEODN7QPH531ULOIRVBQNMQISX",
  },
  {
    name: "Job Function - Accounting & Finance",
    value: "34B493OWLLW142898C5JCC2PWYBR5I",
  },
  {
    name: "Job Function - Administrative",
    value: "3CFGI1NEWHBV9Y4M9WMEXU17I404SF",
  },
  {
    name: "Job Function - Arts & Design",
    value: "3L4WO517FMRS1L81B3OKDXR50W522E",
  },
  {
    name: "Job Function - Education & Training",
    value: "39VG053QLP2TTBD2GR6ZAZEF75VZ8L",
  },
  {
    name: "Job Function - Engineering",
    value: "38AR275WHHNWOYH1YWR47ZXTKNE4T4",
  },
  {
    name: "Job Function - Information Technology",
    value: "3V2G7UO8H86T7GWCLJCDAV9QRBDZ9A",
  },
  {
    name: "Job Function - Management",
    value: "37OFGIIEXQV7I0N6J2SXSMKY2XYWSS",
  },
  {
    name: "Job Function - Marketing, Sales & Business Development",
    value: "3WU6VAO3A6EXN68NDVFO4IGPSTMR6Y",
  },
  {
    name: "Job Function - Operations",
    value: "33LB8PNHHTOBOBWQY1HECVJV31L4UV",
  },
  {
    name: "Language Fluency (Basic) - Brazilian Portuguese",
    value: "3X4GDYFBXICH47RBUXZI4RPTJ0J1PJ",
  },
  {
    name: "Language Fluency (Basic) - Chinese Mandarin",
    value: "3GYFJBAR5GJ55GZO1DFWIKJEC2ZDYP",
  },
  {
    name: "Language Fluency (Basic) - French",
    value: "37AYPMB2R96GO8LN6LUXBB8N89XPE0",
  },
  {
    name: "Language Fluency (Basic) - German",
    value: "3C8RYQJ3V2MT89XT6UMJ2TDHC76M9R",
  },
  {
    name: "Language Fluency (Basic) - Spanish",
    value: "3MS26IBOMQWBW27NAQJD5TQ28XWPD7",
  },
  {
    name: "Marital Status - Divorced",
    value: "3NZO41202V5GP4GHF5O23SXFVZ15NJ",
  },
  { name: "Marital Status - Married", value: "3YG2ZEI8VYM6QSK9IRW2Y9OY33LBF1" },
  { name: "Marital Status - Single", value: "3VQG0T5OJZPXAJ6WG6WAB4PVUGTUNP" },
  {
    name: "Online Purchase - Automotive Products",
    value: "3SO0EPWUEPDXD4I9WL92FTUQUX2UPX",
  },
  {
    name: "Online Purchase - Baby & Kids",
    value: "36GZVHHGINJ565HZKBZDESSFC2Y0FK",
  },
  { name: "Online Purchase - Books", value: "3D3M1MRGIBSYAII7PSDCKRQMKG4C1E" },
  {
    name: "Online Purchase - Clothing & Shoes",
    value: "3LRU1FDZH09315T0WC3ZNKZJ61B290",
  },
  {
    name: "Online Purchase - Electronics & Computers",
    value: "3NF0BIUSIULEG0EUN23HGL9KXB5TEU",
  },
  {
    name: "Online Purchase - Groceries & Food",
    value: "38K25CGMOFSUWCZ7BJYCEXCSRGD1UF",
  },
  {
    name: "Online Purchase - Handmade Products",
    value: "3YGUJZ9C534G5B9EWHTUZ6C5AWGXDE",
  },
  {
    name: "Online Purchase - Health & Beauty",
    value: "3J9MM9U3SHCS8I69VKBQ6Y90S9Y38S",
  },
  {
    name: "Online Purchase - Home & Garden",
    value: "39J7PQMMU2E3YJ2RTNRWKECMAH63WE",
  },
  {
    name: "Online Purchase - Jewelry",
    value: "31QSZWYWMZN79803MQ7AA0O4EA9XOX",
  },
  { name: "Online Purchase - Movies", value: "3FQW154NSKK6JPQ6Y2AFTGU59SL4HU" },
  { name: "Online Purchase - Music", value: "30LLKV2ET72W5VQMHPZL4W6494009P" },
  {
    name: "Online Purchase - Sports & Outdoor Equipment",
    value: "32Z3VVMZSSBBK5DUVV5VWDSRDHY0GJ",
  },
  { name: "Online Purchase - Toys", value: "3JO55QYDJPCOTS97D1ZW9AZYB632P7" },
  {
    name: "Online Purchase - Videogames",
    value: "35TOGO3TMMYHM34KF32PW7WX6AA32V",
  },
  {
    name: "Primary Internet Device - Desktop",
    value: "3ZZ3ITZ94KDP2GDEGUQ4BS2ELTP7G7",
  },
  {
    name: "Primary Internet Device - Laptop",
    value: "3LRU1FDZH09315T0WC3ZNKZJ60829V",
  },
  {
    name: "Primary Internet Device - Smartphone or Tablet",
    value: "3PP3EVLPRLCKTOVLYNT0J2XMM6M9QG",
  },
  {
    name: "Primary Mobile Device - Android",
    value: "3N5CCFXKGLIIGFG8AI99F1L6ACYG2T",
  },
  {
    name: "Primary Mobile Device - iPhone",
    value: "3OP13B7XRC2PNL47H29YVUOHXJC1IP",
  },
  {
    name: "Primary News Source - Online News (News Websites, News Apps)",
    value: "3J9ZOWKR0FYAXIMOWZ2BROMSE3N313",
  },
  {
    name: "Primary News Source - Podcasts",
    value: "3321WWT37PF955PMQT3LE5ZDCOAR27",
  },
  {
    name: "Primary News Source - Print (Newspapers & Periodicals)",
    value: "3L339059XKHPK185F1NDHD4SSHB0HI",
  },
  {
    name: "Primary News Source - Radio (AM/FM, Internet, Satellite)",
    value: "3PDP75MATNEQJU2M3DM0YCT83843V4",
  },
  {
    name: "Primary News Source - Social Media",
    value: "3RAGO4OQ8ADTOL3XK54GWS5D1ARY8R",
  },
  {
    name: "Primary News Source - TV (Late Night Comedy, Other)",
    value: "3XS9XHLESXZW2DLTJT4UJC8R0NWUKW",
  },
  {
    name: "Primary News Source - TV (Local/Cable News Broadcast)",
    value: "34O6GN9FZ907HR3UA09DIGEF74GW04",
  },
  {
    name: "Primary News Source - Word of Mouth",
    value: "3EJZ0T8ZKH8RJ6C1JEF2DXGVM7M96Z",
  },
  {
    name: "US Political Affiliation - Conservative",
    value: "35KYC9RVPIDU3HEVNHU6TDFXQB0XXI",
  },
  {
    name: "US Political Affiliation - Liberal",
    value: "3N2JCLSAPVSJ3M45SDG8CRM6SHUXYO",
  },
  {
    name: "Vacation Frequency - Every Few Years",
    value: "3XEJ90GX9GFRZXZASYFQO6PLC9MYVS",
  },
  {
    name: "Vacation Frequency - Every Month",
    value: "3U8EC01SO75D4J8N0QMNGZWHQG549Y",
  },
  {
    name: "Vacation Frequency - Every Quarter",
    value: "3O6AJM428T355ZYBFHJIORM8EYYCJE",
  },
  {
    name: "Vacation Frequency - Every Year",
    value: "3FIQQ827RGNC88GVKOY7VYBFRWS6NK",
  },
  {
    name: "Vacation Frequency - Never",
    value: "3KAW48Y220TUQTG7L3YKANBKH6X6OZ",
  },
  {
    name: "Social Media - Facebook Account Holder",
    value: "3DUWYN247KLA7MVH3492QD8FQANM5Y",
  },
  {
    name: "Social Media - Google Account Holder",
    value: "347ZKNUE36WD5H8MN15O2M7LIZ8S5J",
  },
  {
    name: "Social Media - LinkedIn Account Holder",
    value: "3S4G8J928WR2XEMZKKK1CYX6CV5NO7",
  },
  {
    name: "Social Media - Myspace Account Holder",
    value: "3NWWWIUOD3RP3Y04HPAT9T39ZRH6U6",
  },
  {
    name: "Social Media - Pinterest Account Holder",
    value: "33LK6UI8E3VMRM2OA73RLSYUSS9QC0",
  },
  {
    name: "Social Media - Reddit Account Holder",
    value: "3E3LVQS9GBGA13KVM7XDK8VMZK4Y53",
  },
  {
    name: "Social Media - Tumblr Account Holder",
    value: "3VKHPH0RG1TTUYMY19TSTYJXP51QFB",
  },
  {
    name: "Social Media - Twitter Account Holder",
    value: "3KSRVTP5A9P9ET3LRHH4AWD035YCHB",
  },
  {
    name: "Social Media - YouTube Account Holder",
    value: "3D9IZUY1O3RFWZKIBE8291QQ82HT1R",
  },
  {
    name: "US Education - US Bachelor's Degree",
    value: "3QTUCSJAYWPDXVKYZOFDFX70RD5GK2",
  },
  {
    name: "US Education - US Graduate Degree",
    value: "3Q1RR1YLZJITU4EJ12G52XI6SZU8MQ",
  },
  {
    name: "US Education - US High School Graduate",
    value: "3BB04KUNDGX1NXGW3YHWGQQMFB6F3C",
  },
  {
    name: "US Election - Voted in 2012 US Presidential Election",
    value: "31XUIG3A6ON28QNPO5C5KRV1U6AZFY",
  },
  { name: "Other - Blogger", value: "37V1T9MUUQLS5X0CG7PBSP0508ANH0" },
  { name: "Other - Car Owner", value: "38ASEUC7MCFMV5JQT3CG25R8ZWZGHW" },
  {
    name: "Other - Military experience",
    value: "3S8XFQY1FTN8R196LH1BP4OSKTYNF3",
  },
  {
    name: "Other - Parenthood Status",
    value: "3J4VL7BYSILCKR8SFUI3NB8QLSUV8I",
  },
  {
    name: "Other - Single Family Home Resident",
    value: "376OOLYQQ1F0O28454Q8ZE29OJF7XT",
  },
  { name: "Other - Smoker", value: "3SHVXOT5TLQLCGN7C39I3WQQ0ZVSJV" },
  { name: "Other - Tablet Owner", value: "33AQ5F1Y1V77OGOC6JDDWLXPCUPW16" },
];

export const QUALIFICATION = QUALIFICATION_DEV;
