import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Row, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import GoogleDrive from "../../images/app-icon-03.png";
import Zapier from "../../images/app-icon-01.png";
import Zoom from "../../images/app-icon-02.png";
import { getIntegrationApps } from "../../store/actions/integrationActions";
import { PageLayout } from "../../components/page-layout";
import "./style.css";
import style from "./integration.module.css";

const Integration = ({ image, title, desc, redirect }) => {
  const { account: userAccount } = useSelector((state) => state.auth);
  let isFree = userAccount?.plan?.type === "Free";

  const history = useHistory();
  return (
    <div className="integration">
      <img
        src={image}
        alt=""
        style={{ width: "100%", height: "auto", borderRadius: "8px" }}
      />
      <h4 className="mt-4">{title}</h4>
      <p>{desc}</p>
      {isFree && (
        <Button
          variant="outline-primary"
          onClick={() => history.push("/projects")}
        >
          Upgrade
        </Button>
      )}
      {!isFree &&
        (redirect ? (
          <Button
            variant="outline-primary"
            onClick={() => {
              console.log(redirect)
              history.push(redirect)}}
          >
            View Integration
          </Button>
        ) : (
          <Button variant="primary" disabled>
            Coming soon
          </Button>
        ))}
    </div>
  );
};

export const IntegrationsPage = () => {
  const apps = useSelector((state) => state.integrations.apps);
  useEffect(() => {
    if (apps.length === 0) {
      getIntegrationApps();
    }
  }, []);

  return (
    <PageLayout>
      <div className={style.wrapper}>
        <Container className={style.container}>
          <Row style={{ maxWidth: "800px", margin: "0 auto" }}>
            <Col md={12} className="mb-4">
              <h4>Integrations</h4>
            </Col>
            <Col md={4} className="mb-4">
              <Integration
                image={GoogleDrive}
                title={"Google Drive"}
                desc="Import all your recordings and files from Drive"
                redirect={"/integration/google"}
              />
            </Col>
            <Col md={4} className="mb-4">
              <Integration
                image={Zapier}
                title={"Zapier"}
                desc="Connect with over 5000 apps to import or export data"
                redirect={"/integration/zapier"}
              />
            </Col>
            <Col md={4} className="mb-4">
              <Integration
                image={Zoom}
                title={"Zoom Cloud Recording"}
                desc="Fetch all your cloud recordings from Zoom"
                redirect={""}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </PageLayout>
  );
};
