import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../../../store/slices/modalSlice";
import { Checkbox, Modal } from "@mantine/core";
import { Button } from "react-bootstrap";
import styles from "../index.module.css";
import { deleteTags } from "../../../store/actions/tagsActions";
import { FiX } from "react-icons/fi";
function DeleteTagModal() {
  const dispatch = useDispatch();
  const [selectedTags, setSelectedTags] = useState([]);
  const { deleteTag: open } = useSelector((state) => state.modal);
  const { tags } = useSelector((state) => state.tags);
  const [confirm, setConfirm] = useState(false);
  const uniqueTagIds = useMemo(() => {
    let allTagIds = tags?.map((tag) => tag.tagId);
    let tagIds = [];
    allTagIds.forEach((tag) => {
      if (!tagIds?.some((id) => id.title === tag.title)) {
        tagIds.push(tag);
      }
    });
    return tagIds;
  }, [tags]);

  return (
    <Modal
      title="Bulk Delete"
      opened={open}
      onClose={() => dispatch(modalActions.hideDeleteTagModal())}
    >
      <Checkbox
        className="mb-2"
        style={{ paddingLeft: "4px" }}
        label="Select All"
        checked={selectedTags.length === uniqueTagIds.length}
        onChange={(event) => {
          if (event.target.checked) {
            setSelectedTags([...uniqueTagIds]);
          } else {
            setSelectedTags([]);
          }
        }}
      />

      <div className={styles.tagIdContainer}>
        {uniqueTagIds?.map((tag) => {
          return (
            <Checkbox
              key={tag._id}
              label={
                <span
                  style={{
                    backgroundColor: tag.color,
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                >
                  {tag.title}
                </span>
              }
              checked={selectedTags.includes(tag)}
              onChange={(event) => {
                if (event.target.checked) {
                  setSelectedTags([...selectedTags, tag]);
                } else {
                  setSelectedTags(
                    selectedTags.filter((selectedTag) => selectedTag !== tag)
                  );
                }
              }}
            />
          );
        })}
      </div>
      {confirm ? (
        <div className={styles.confirmation}>
        <Button
          variant="transparent"
          style={{ fontSize: "1.5rem", color: "var(--grey10)" }}
          onClick={() => {
            setConfirm(false);
            setSelectedTags([]);
          }}
        >
          <FiX />
        </Button>
          <Button
            variant="danger mt-2 "
            onClick={() => {
              deleteTags(selectedTags.map((tag) => tag._id));
              dispatch(modalActions.hideDeleteTagModal());
            }}
          >
            Sure?
          </Button>
        </div>
      ) : (
        <div className={styles.actionButtons}>
          <Button
            variant="light"
            onClick={() => {
              dispatch(modalActions.hideDeleteTagModal());
            }}
          >
            {" "}
            Cancel{" "}
          </Button>
          <Button
            variant="danger mt-2"
            disabled={selectedTags.length === 0}
            onClick={() => {
              setConfirm(true);
            }}
          >
            Delete
          </Button>
        </div>
      )}
    </Modal>
  );
}

export default DeleteTagModal;
