import axios from "axios";

const accessTokenMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.type === "auth/getAccount") {
      //    get access token from the action payload
      const { accessToken } = action.payload;
      axios.defaults.headers.Authorization = "Bearer " + accessToken;
    } else if (action.type === "auth/setAccessToken") {
      axios.defaults.headers.Authorization = "Bearer " + action.payload;
    }
    return next(action);
  };

export default accessTokenMiddleware;
