function checkProjectStatuses(projects) {
  if (projects.length === 0) return true
  const statuses = projects.map(project => project.status);

  const allReady = statuses.every(status => status === "Ready");
  const allEmpty = statuses.every(status => status === "Empty");

  return allReady || allEmpty;
}

export default checkProjectStatuses
