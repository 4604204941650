import React, { useEffect, useState } from "react";
import { FaUsers } from "react-icons/fa";
import { BsCurrencyDollar } from "react-icons/bs";
import { TbFreeRights } from "react-icons/tb";
import ActivityCard from "./activitycard";
import styles from "./index.module.css";
import { Bar } from "react-chartjs-2";
import { callExternalApi } from "../../../../services/external-api.service";
import StatCard from "./statCard";
const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

function Overview() {
  const [data, setData] = useState([]);
  const [countByPlan, setCountByPlan] = useState([]);
  const [thirtyDaysData, setThirtyDaysData] = useState([]);
  const [engageCountByPlan, setEngageCountByPlan] = useState([]);
  useEffect(() => {
    (async () => {
      const config = {
        url: `${apiServerUrl}/api/admin/usersdata`,
        method: "GET",
      };
      const { data, error } = await callExternalApi({ config });
      if (error) {
        console.error("Failed to sign in as user:", error);
      }
      if (data) {
        setThirtyDaysData(data.thirtyDaysData);
        setData(
          data.overviewData.map((item) => {
            return {
              name: item.title,
              value: item.absoluteValue,
              changeInValue: item.change,
              icon:
                item.title === "Users" || item.title === "Engaged Users" ? (
                  <FaUsers />
                ) : item.title === "Paid Users" ? (
                  <BsCurrencyDollar />
                ) : item.title === "Free" ? (
                  <TbFreeRights />
                ) : null,
            };
          })
        );
        setCountByPlan(data.countByPlan);
        setEngageCountByPlan(data.engageCountByPlan);
      }
    })();
  }, []);

  return (
    <div>
      <h1>Overview</h1>
      <div className={styles.activities}>
        {data?.map((item, index) => (
          <ActivityCard
            key={index}
            title={item.name}
            absolutevalue={item.value}
            icon={item.icon}
            change={item.changeInValue}
          />
        ))}
      </div>
      <div className={styles.activities}>
        {countByPlan?.map((item, index) => (
          <React.Fragment key={index}>
            <StatCard data={item} />
          </React.Fragment>
        ))}
        {engageCountByPlan?.map((item, index) => {
          let data = { ...item, _id: `engage ${item._id}` };
          return (
            <React.Fragment key={index}>
              <StatCard data={data} />
            </React.Fragment>
          );
        })}
      </div>
      <div className={styles.charts}>
        <Bar
          data={{
            labels: thirtyDaysData?.map((item) => item.date).reverse(),
            datasets: [
              {
                label: "Users",
                data: thirtyDaysData?.map((item) => item.count).reverse(),
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                borderColor: "rgba(255, 99, 132, 1)",
                borderWidth: 1,
                barThickness: 20,
              },
            ],
          }}
          height={400}
          width={600}
          options={{
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          }}
        />
      </div>
    </div>
  );
}

export default Overview;
