/* eslint-disable no-unused-vars */
import React from "react";
import { useHistory } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { FormControl } from "react-bootstrap";
import style from "./invite-saved-participants.module.css";
import { useSelector } from "react-redux";

function InviteSavedParticipants({ selectedEmails, setSelectedEmails }) {
  const [searchTerm, setSearchTerm] = React.useState("");
  const history = useHistory();

  const { pastParticipants = [] } = useSelector((state) => state.test);

  const filteredParticipants = pastParticipants.filter(({ participant }) =>
    Object.values(participant)
      .join(" ")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  return (
    <div className={style.main}>
      <div className={style.inputContainer}>
        <FormControl
          type="text"
          placeholder="Search participants..."
          className={style.input}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className={style.searchIcon}>
          <FiSearch />
        </div>
      </div>
      <div className={style.table}>
        <Table hover>
          <thead className={style.tableHeader}>
            <tr className={style.row}>
              <th className={style.checkbox}>
                <Form.Check
                  type="checkbox"
                  checked={
                    pastParticipants.length &&
                    selectedEmails.length === pastParticipants.length
                  }
                  onChange={(e) => {
                    if (e.target.checked && pastParticipants.length) {
                      setSelectedEmails(
                        pastParticipants.map(
                          (participant) => participant.participant?.email
                        )
                      );
                    } else {
                      setSelectedEmails([]);
                    }
                  }}
                />
              </th>
              <th>Name</th>
              <th>Job Title</th>
              <th>Email</th>
              <th>Location</th>
            </tr>
          </thead>
          <tbody>
            {filteredParticipants.map(({ participant }, index) => (
              <tr key={index} className={style.row}>
                <td className={style.checkbox}>
                  <Form.Check
                    type="checkbox"
                    checked={selectedEmails.includes(participant.email)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedEmails([
                          ...selectedEmails,
                          participant.email,
                        ]);
                      } else {
                        setSelectedEmails(
                          selectedEmails.filter(
                            (email) => email !== participant.email
                          )
                        );
                      }
                    }}
                  />
                </td>
                <td>{participant.name}</td>
                <td>{participant.occupation}</td>
                <td>{participant.email}</td>
                <td>{`${participant.city || ""} ${participant.state || ""} ${
                  participant.country || ""
                }`}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="px-3">
          {pastParticipants.length === 0 && (
            <div className={style.info}>
              You do not have any saved participants. Please use the link to
              invite or invite via email.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default InviteSavedParticipants;
