import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
import { saveVideoThumbnail } from "../../../../store/actions/projectActions";
import styles from "./player.module.css";
export default function Player({
  file,
  videoRef,
  onTimeUpdate,
  isVideo,
  className,
  gotoTime,
  endTime,
  currentPlayerTime
}) {
  const [thumbnail, setThumbnail] = useState(null);
  const [justSavedThumbnail, setJustSavedThumbnail] = useState(false);
  const captureThumbnail = async () => {
    if (file.thumbnail || justSavedThumbnail) return;
    const videoWidth = videoRef.current.videoWidth;
    const videoHeight = videoRef.current.videoHeight;
    const aspectRatio = videoWidth / videoHeight;

    const thumbnailWidth = 600;
    const thumbnailHeight = thumbnailWidth / aspectRatio;
    const canvas = document.createElement("canvas");
    canvas.width = thumbnailWidth;
    canvas.height = thumbnailHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(videoRef.current, 0, 0, thumbnailWidth, thumbnailHeight);
    const thumbnailDataUrl = canvas.toDataURL("image/jpeg");
    setThumbnail(thumbnailDataUrl);
    const { error } = await saveVideoThumbnail({
      thumbnail: thumbnailDataUrl,
      videoId: file._id,
    });
    setJustSavedThumbnail(true);
    if (error) console.error(error);
  };
  useEffect(() => {
    if (gotoTime) {
      videoRef.current.currentTime = gotoTime;
    }
  }, [gotoTime]);
 
  useEffect(() => {
    if (currentPlayerTime >= endTime) {
      videoRef.current.pause();
    }
  }, [endTime,currentPlayerTime]);

  useEffect(() => {
    if (!videoRef.current || !isVideo) return;

    const handleTimeUpdate = () => {
      if (videoRef.current?.currentTime >= 3) {
        captureThumbnail();
        videoRef.current.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };

    videoRef.current.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, [videoRef, isVideo]);

  useEffect(() => {
    if (!videoRef.current || !isVideo) return;

    const videoElement = videoRef.current;
    videoElement.srcObject = null;
    videoElement.load();
    videoElement.src = file.link;
  }, [file, isVideo, videoRef]);

  return (
    <>
      <div className={styles.player}>
        {isVideo ? (
          <video
            crossOrigin="anonymous"
            ref={videoRef}
            onTimeUpdate={onTimeUpdate}
            className={` ${styles.video_player || ""}`}
            controls
            style={{
              objectFit: "cover",
              width: "100%",
            }}
          >
            <source src={file.link} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <audio ref={videoRef} className={`audio-player `} controls>
            <source src={file.link} type="audio/ogg" />
            Your browser does not support the audio element.
          </audio>
        )}
      </div>
      {thumbnail && (
        <img
          src={thumbnail}
          alt="Video thumbnail"
          style={{ display: "none" }}
        />
      )}
    </>
  );
}
