import store from "..";
import { callExternalApi } from "../../services/external-api.service";
import { mediaLibraryActions } from "../slices/mediaLibrarySlice";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

const { dispatch } = store;

export async function loadAssets() {
  dispatch(mediaLibraryActions.startLoading());
  const config = {
    url: `${apiServerUrl}/api/media-library/assets`,
    method: "GET",
  };
  const { data } = await callExternalApi({ config });
  if (data?.assets?.length > 0) {
    dispatch(mediaLibraryActions.setAssets(data));
  }
  dispatch(mediaLibraryActions.stopLoading());
}

export async function loadAsset(assetId) {
  dispatch(mediaLibraryActions.startAssetLoading());

  const config = {
    url: `${apiServerUrl}/api/assets/${assetId}`,
    method: "GET",
  };
  const { data } = await callExternalApi({ config });
  dispatch(
    mediaLibraryActions.setTranscript({ assetId, transcript: data.transcript })
  );
  dispatch(mediaLibraryActions.stopAssetLoading());
  return data;
}

export const loadZoomRecordings = async (params) => {
  dispatch(mediaLibraryActions.startLoading());

  const config = {
    url: `${apiServerUrl}/api/integrations/zoom/recordings`,
    method: "GET",
    params: params || {},
  };
  const { data } = await callExternalApi({ config });
  dispatch(mediaLibraryActions.setZoom(data));
  // @todo modify state with recording list, with auto page increment
  dispatch(mediaLibraryActions.stopLoading());
};

export async function importRecordings(meetingIds) {
  dispatch(mediaLibraryActions.startLoading());
  const config = {
    url: `${apiServerUrl}/api/integrations/zoom/import`,
    method: "POST",
    data: { meetingIds },
  };
  // @todo handle and show failed count to the users
  await callExternalApi({ config });
  await loadAssets();
  dispatch(mediaLibraryActions.stopLoading());
  return;
}

export async function importDriveFiles(files) {
  dispatch(mediaLibraryActions.startLoading());
  const config = {
    url: `${apiServerUrl}/api/integrations/google/import`,
    method: "POST",
    data: { files },
  };
  await callExternalApi({ config });
  await loadAssets();
  dispatch(mediaLibraryActions.stopLoading());
}

export async function getGoogleToken() {
  dispatch(mediaLibraryActions.startLoading());
  const config = {
    url: `${apiServerUrl}/api/integrations/google/token`,
    method: "GET",
  };
  const { data } = await callExternalApi({ config });
  dispatch(mediaLibraryActions.stopLoading());
  dispatch(mediaLibraryActions.setGoogleToken(data));
}

export const addTagToVideo = async (title, assetId, organizationId) => {
  const config = {
    url: `${apiServerUrl}/api/assets/tags`,
    method: "POST",
    data: { title, videoId: assetId, organizationId },
  };
  const { data, error } = await callExternalApi({ config });

  if (data) {
    dispatch(
      mediaLibraryActions.addTagToVideo({ assetId: assetId, tag: data })
    );
  }

  return {
    data: data || null,
    error,
  };
};
export const removeTagFromVideo = async (tagId, assetId) => {
  const config = {
    url: `${apiServerUrl}/api/assets/tags/${tagId}`,
    method: "DELETE",
    data: { assetId },
  };
  const { data, error } = await callExternalApi({ config });
  if (data) {
    dispatch(mediaLibraryActions.removeTagFromVideo({ assetId, tagId }));
  }
  return {
    data: data || null,
    error,
  };
};
export const removeTagsFromVideo = async (tagIds) => {
  const config = {
    url: `${apiServerUrl}/api/assets/tags/bulk`,
    method: "DELETE",
    data: { tagIds },
  };
  const { data, error } = await callExternalApi({ config });
  if (data) {
    dispatch(mediaLibraryActions.removeTagsFromVideo({ tagIds }));
  }
  return {
    data: data || null,
    error,
  };
};

export const getVideoTagsByOrganization = async (organizationId) => {
  const config = {
    url: `${apiServerUrl}/api/assets/tags/organization/${organizationId}`,
    method: "GET",
  };
  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};
