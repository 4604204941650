/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import style from "./edit.module.css";
import { Button, Form } from "react-bootstrap";
import { getLinkedInProfile } from "../../../../../store/actions/participantActions";

export default function EditLinkedin({ onClose }) {
  const [validated, setValidated] = useState(false);
  const [linkedin, setLinkedin] = useState("");
  const [disabled, setDisabled] = useState(false);

  const handleInputChange = (e) => {
    setLinkedin(e.target.value);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      // Set participantPromptViewed true so that intitial prompt never get shown again
      localStorage.setItem("participantPromptViewed", "true");
      setDisabled(true);

      const { error } = await getLinkedInProfile(linkedin);
      if (!error) {
        onClose();
      } else {
        setDisabled(false);
      }
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSave}>
      <Form.Group controlId="formHeadline" className="mb-2">
        <Form.Label className={style.formLabel}>
          Enter your LinkedIn URL
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g., https://linkedin.com/in/john"
          name="linkedin"
          value={linkedin}
          onChange={handleInputChange}
          required
        />
      </Form.Group>
      <Form.Group
        controlId="formButtons"
        className="d-flex justify-content-between mt-3"
      >
        <Button
          variant="secondary"
          className={style.closeButton}
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          type="submit"
          variant="primary"
          className={style.saveButton}
          disabled={disabled}
        >
          Save
        </Button>
      </Form.Group>
    </Form>
  );
}
