import React from "react";
import style from "./share-link.module.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { sendInviteMail } from "../../../store/actions/testActions";
import { toastError } from "../../../utils/toast";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import TagsInput from "react-tagsinput";
import { MdOutlineContentCopy } from "react-icons/md";
import { InputGroup } from "react-bootstrap";

export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;

export const sendMail = async (
  testId,
  savedEmails,
  emaisFromInput,
  callback
) => {
  const mails = [...new Set([...savedEmails, ...emaisFromInput])];
  const { data, error } = await sendInviteMail(testId, mails).then(callback);

  if (error) {
    toastError(error.message);
  }

  return { data, error };
};

function ShareLink({ testId, emails, setEmails }) {
  const [copied, setCopied] = React.useState(false);
  const timerRef = React.useRef(null);

  React.useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return (
    <div className={style.main}>
      <div>
        <div className={style.linkTitle}>Your shareable link</div>
        <div className={style.linkWrapper}>
          <InputGroup>
            <Form.Control
              type="text"
              value={`${window.location.origin}/participant/welcome?tId=${testId}`}
              className={`${style.input} ${style.link}`}
              readOnly
            />
            <Button
              variant="primary"
              className="d-flex align-items-center gap-1"
              onClick={() => {
                setCopied(true);

                timerRef.current = setTimeout(() => {
                  setCopied(false);
                }, 5000);

                navigator.clipboard.writeText(
                  `${window.location.origin}/participant/welcome?tId=${testId}`
                );
              }}
            >
              {copied ? (
                <IoCheckmarkDoneSharp />
              ) : (
                <>
                  <MdOutlineContentCopy /> Copy
                </>
              )}
            </Button>
          </InputGroup>
        </div>
        <div className={style.linkDescription}>
          Everyone with the link will be able to participate in the test until
          we&apos;ve reached the maximum allowed participants.
        </div>
      </div>
      <div>
        <div>
          <span className={style.inputTitle}>or share link via email </span>
          (separate with comma, press enter/tab to add)
        </div>

        <TagsInput
          type="text"
          inputProps={{
            placeholder: "Add Email to Invite",
          }}
          className={style.input}
          value={emails}
          onChange={(emails) => setEmails(emails)}
          onlyUnique
          addKeys={[13, 9, 188]}
          validationRegex={EMAIL_REGEX}
          onValidationReject={(emails) =>
            toastError(`Invalid email address: ${emails.join(", ")}`)
          }
        />
      </div>
    </div>
  );
}

export default ShareLink;
