import React, { useState, useRef } from "react";
import { Popover, UnstyledButton } from "@mantine/core";
import { FiTag, FiMoreVertical, FiTrash, FiEdit, FiX } from "react-icons/fi";
import styles from "./fileCard.module.css";
import { TagPopUp } from "./addFileTag";
import { IoChevronBack } from "react-icons/io5";
import { TbTagOff } from "react-icons/tb";
import {
  loadAssets,
  removeTagFromVideo,
} from "../../store/actions/mediaLibraryActions";
import { deleteVideo } from "../../store/actions/projectActions";
import { toastSuccess } from "../../utils/toast";
import EditVideoTitleModal from "../project/components/contentArea/EditVideoTitleModal";
const Actions = ({ file }) => {
  const [open, setOpen] = useState(false);
  const [opened, setOpened] = useState(false);
  const [mode, setMode] = useState("options");
  const [editName, setEditName] = useState(false);
  const parentBtnRef = useRef(null);
  const assetId = file?._id;
  const tags = file?.tags || [];
  return (
    <>
      <div
        className={`${styles.actionsContainer} `}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <EditVideoTitleModal
          show={editName}
          onClose={() => {
            setEditName(false);
          }}
          videoId={assetId}
          videoTitle={file?.name}
        />
        <div className={styles.tagBtnContainer}>
          <TagPopUp
            isOpen={open}
            setIsOpen={setOpen}
            assetId={assetId}
            parentBtnRef={parentBtnRef}
          />
          <UnstyledButton
            ref={parentBtnRef}
            className={`${open ? styles.active : ""} ${styles.actionButton}`}
            onClick={(e) => {
              e.stopPropagation();
              setOpen((prev) => !prev);
            }}
          >
            <span style={{ fontSize: "1.08rem" }}>
              <FiTag />
            </span>
            <span style={{ fontWeight: "600" }}>Tag</span>
          </UnstyledButton>
        </div>

        <Popover
          width={200}
          position="bottom"
          withArrow
          shadow="md"
          opened={opened}
          onChange={setOpened}
        >
          <Popover.Target
            onClick={(e) => {
              e.stopPropagation();
              setOpened((o) => !o);
              setMode("options");
            }}
          >
            <UnstyledButton
              className={`${opened ? styles.active : ""} ${
                styles.actionButton
              }`}
            >
              <span style={{ fontWeight: "600" }}>More</span>
              <span style={{ fontSize: "1.08rem" }}>
                <FiMoreVertical />
              </span>
            </UnstyledButton>
          </Popover.Target>
          <Popover.Dropdown className="menu-dropdown">
            {mode === "options" ? (
              <div className={styles.cardActions}>
                <UnstyledButton
                  onClick={() => {
                    setEditName(true);
                  }}
                >
                  <FiEdit />
                  &nbsp;&nbsp;Rename
                </UnstyledButton>
                <UnstyledButton
                  onClick={() => {
                    setMode("remove");
                  }}
                >
                  <TbTagOff />
                  &nbsp;&nbsp;Remove Tags
                </UnstyledButton>

                <DeleteBtn assetId={file?._id} />
              </div>
            ) : mode === "remove" ? (
              <div>
                <div>
                  <UnstyledButton
                    onClick={() => {
                      setMode("options");
                    }}
                  >
                    <IoChevronBack />
                  </UnstyledButton>
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "0.9rem",
                      marginLeft: "0.25rem",
                    }}
                  >
                    Remove Tags{" "}
                  </span>
                </div>
                <div className={styles.tagsShower}>
                  {
                    <>
                      {tags.length > 0 ? (
                        tags.map((tag, index) => {
                          return (
                            <div key={index} className={styles.tag}>
                              <div>{tag.title}</div>
                              <UnstyledButton
                                onClick={() => {
                                  removeTagFromVideo(tag._id, assetId);
                                }}
                              >
                                <FiX />
                              </UnstyledButton>
                            </div>
                          );
                        })
                      ) : (
                        <p style={{ textAlign: "center" }}>No tags created</p>
                      )}
                    </>
                  }
                </div>
              </div>
            ) : null}
          </Popover.Dropdown>
        </Popover>
      </div>
    </>
  );
};
const DeleteBtn = ({ assetId }) => {
  const [confirm, setConfirm] = useState(false);
  return (
    <>
      {confirm ? (
        <UnstyledButton
        
          style={{
            color: "var(--danger)",
            fontWeight: "500",
            fontSize: "0.9rem",
          }}
          onClick={async () => {
            deleteVideo(assetId).then(() => {
              toastSuccess("Your file has been deleted");
              loadAssets();
            });
          }}
        >
          <FiTrash />
          &nbsp;&nbsp;Sure?
        </UnstyledButton>
      ) : (
        <UnstyledButton
          className={styles.confirmationButton}
          style={{
            color: "var(--danger)",
            fontWeight: "500",
            fontSize: "0.9rem",
          }}
          onClick={() => {
            setConfirm(true);
          }}
        >
          <FiTrash />
          &nbsp;&nbsp;Delete
        </UnstyledButton>
      )}
    </>
  );
};
export default Actions;
