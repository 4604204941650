import React from "react";
import { Button } from "react-bootstrap";
import { Tooltip } from "@mantine/core";
import { FiEye } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { highlightActions } from "../../../../../store/slices/highlightSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
function HighlightIcon({
  index,
  normalTime,
  assetId,
  disabled,
  showSources,
  fake,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { project, isPublic, id } = useSelector(
    (state) => state.currentProject
  );
  let { highlights } = useSelector((state) => state.highlight);
  let flag = false;
  highlights.forEach((ele, index) => {
    if (ele.start === normalTime.start && ele.end === normalTime.end) {
      flag = true;
    }
  });
  if (fake) {
    return (
      <button
        className="project-insight-btn"
        style={{
          backgroundColor: "transparent",
          border: "none",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          fontSize: "0.9rem",
          padding: "0px",
          color: "var(--grey5)" 
        }}
      >
        <FiEye
          className="project-insight-icons"
          style={{ color: "var(--grey5)" }}
        />
        {showSources && "Highlight Context"}
      </button>
    );
  }

  if (disabled) {
    return (
      <Tooltip label="Contains multiple/adjusted sources">
        <button
          className="project-insight-btn"
          style={{
            backgroundColor: "transparent",
            border: "none",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            fontSize: "0.9rem",
            padding: "0px",
          }}
          disabled={disabled}
        >
          <FiEye className="project-insight-icons" style={{ opacity: "0.7" }} />
          {showSources && "Highlight Context"}
        </button>
      </Tooltip>
    );
  }
  if (!showSources) {
    return (
      <>
        <Tooltip label="Highlight Context">
          <Button
            disabled={disabled}
            className="project-insight-btn"
            variant="transparent"
            style={{ padding: "0px" }}
            size="sm"
            onClick={() => {
              dispatch(highlightActions.setHighlights(normalTime));
              if (!flag && !isPublic) {
                history.push(`/project/${project?._id}/data/${assetId}`);
              }
              if (isPublic) {
                history.push(`/public/${id}/data/${assetId}`);
              }
            }}
          >
            <FiEye className="project-insight-icons" />
          </Button>
        </Tooltip>
      </>
    );
  }
  return (
    <>
      <Button
        disabled={disabled}
        className="project-insight-btn"
        variant="transparent"
        size="sm"
        onClick={() => {
          dispatch(highlightActions.setHighlights(normalTime));
          if (!flag && !isPublic) {
            history.push(`/project/${project?._id}/data/${assetId}`);
          }
          if (isPublic) {
            history.push(`/public/${id}/data/${assetId}`);
          }
        }}
      >
        <FiEye className="project-insight-icons" />
        {showSources && "Highlight Context"}
      </Button>
    </>
  );
}

export default HighlightIcon;
