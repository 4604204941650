import { createSlice } from "@reduxjs/toolkit";

const emailSlice = createSlice({
  name: "email",
  initialState: {
    loading: null,
    emailPrefs: null,
  },
  reducers: {
    requestStart(state) {
      state.loading = true;
    },
    requestEnd(state) {
      state.loading = false;
    },
    setEmailPrefs(state, action) {
      state.emailPrefs = action.payload.groups;
    },
  },
});

export default emailSlice;
export const emailActions = emailSlice.actions;
