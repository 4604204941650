/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import style from "./edit.module.css";
import { Button, Form } from "react-bootstrap";
import { updateParticipant } from "../../../../../store/actions/participantActions";

export default function EditSummary({ summary, onClose }) {
  const [validated, setValidated] = useState(false);
  const [characterCount, setCharacterCount] = useState(summary.length);
  const maxLength = 2000;

  const handleSummaryChange = (e) => {
    const currentValue = e.target.value;
    const trimmedValue = currentValue.substring(0, maxLength); // Trim to max length
    setCharacterCount(trimmedValue.length);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);

      const summary = e.target.summary.value;
      const { error } = await updateParticipant("summary", summary);
      if (!error) onClose();
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSave}>
      <Form.Group controlId="formSummary" className="mb-2">
        <Form.Label className={style.formLabel}>Profile Summary</Form.Label>
        <Form.Control
          as="textarea"
          name="summary"
          rows={12}
          defaultValue={summary}
          onChange={handleSummaryChange}
          maxLength={maxLength}
          placeholder="Enter your profile summary (max 2000 characters)"
          required
        />
        <Form.Text className="text-muted">
          {characterCount}/{maxLength}
        </Form.Text>
      </Form.Group>
      <Form.Group
        controlId="formSummary"
        className="d-flex justify-content-between"
      >
        <Button
          variant="secondary"
          className={style.closeButton}
          onClick={onClose}
        >
          Close
        </Button>
        <Button type="submit" variant="primary" className={style.saveButton}>
          Save
        </Button>
      </Form.Group>
    </Form>
  );
}
