/* eslint-disable no-unused-vars */
import React from "react";
import { useSelector } from "react-redux";
import style from "./index.module.css";
import { PageLayout } from "../../../components/page-layout";
import { Button, Form } from "react-bootstrap";
import handleDarkModeChange from "../../../utils/handleDarkModeChange";
import {
  getParticipant,
  updateParticipantSettings,
} from "../../../store/actions/participantActions";
import { logout } from "../../../store/actions/authActions";

export const ParticipantSettings = () => {
  const { darkMode } = useSelector((state) => state.theme);
  const { participant = {} } = useSelector((state) => state.participant);

  const postSettings = (e) => {
    e.preventDefault();
    const { name, email, paypalMail } = e.target;
    const data = {
      name: name.value,
      email: email.value,
      paypalMail: paypalMail.value,
    };

    updateParticipantSettings(data);
  };

  React.useEffect(() => {
    getParticipant();
  }, []);

  return (
    <PageLayout>
      <div className={style.parentWrapper}>
        <div className={style.wrapper}>
          <div className={style.content}>
            <div className={style.heading}>Settings</div>
            <Form className={style.form} onSubmit={postSettings}>
              <div className={style.formGroup}>
                <Form.Label className={style.label} htmlFor="name">
                  Name
                </Form.Label>
                <Form.Control
                  key={participant?.name}
                  id="name"
                  type="text"
                  name="name"
                  placeholder="Enter name"
                  defaultValue={participant?.name}
                />
              </div>
              <div className={style.formGroup}>
                <Form.Label className={style.label} htmlFor="email">
                  Email address
                </Form.Label>
                <Form.Control
                  id="email"
                  type="email"
                  name="email"
                  defaultValue={participant?.email}
                  readOnly
                />
              </div>
              <div className={style.formGroup}>
                <Form.Label className={style.label} htmlFor="darkMode">
                  Dark mode
                </Form.Label>
                <Form.Check
                  id="darkMode"
                  type="switch"
                  checked={darkMode}
                  onChange={handleDarkModeChange}
                />
              </div>
              <div className={style.formGroup}>
                <Form.Label className={style.label} htmlFor="paypalMail">
                  PayPal Email (for Payouts)
                </Form.Label>
                <Form.Text className={style.formText}>
                  Note that we only pay out at the end of the month and will use
                  your PayPal email address to send you your compensation.
                </Form.Text>
                <Form.Control
                  id="paypalMail"
                  type="email"
                  name="paypalMail"
                  defaultValue={participant?.paypalMail}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <Button type="submit" variant="primary" className={style.btn}>
                  Save
                </Button>
                <div className={style.logout} onClick={logout}>
                  Logout
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
