import React, { useState } from "react";
import { FiColumns, FiFileText, FiPlus } from "react-icons/fi";
import styles from "./fileCard.module.css";
import { PiVideoBold, PiWaveformBold } from "react-icons/pi";
import { RiChat4Line } from "react-icons/ri";
import { FaPlay } from "react-icons/fa";
const iconsForAssetType = {
  VIDEO: <PiVideoBold />,
  AUDIO: <PiWaveformBold />,
  TEXT: <FiFileText />,
  CSV: <FiColumns />,
  PDF: <FiFileText />,
  DOC: <FiFileText />,
  LIVECHAT: <RiChat4Line />,
  Plus: <FiPlus />,
  OTHER: <FiFileText />,
};
function ThumbnailHandler({ thumbnail, assetType, name }) {
  const [imgError, setImgError] = useState(false);

  const handleError = () => {
    setImgError(true);
  };

  return (
    <div className={styles.fileCardImage}>
      {!imgError && thumbnail ? (
        <>
          <div className={styles.playIcon}>
            <FaPlay />
          </div>
          <img src={thumbnail} alt={name} onError={handleError} />
        </>
      ) : (
        iconsForAssetType[assetType] || <FiFileText />
      )}
    </div>
  );
}

export default ThumbnailHandler;
