import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import React, { useEffect, useMemo, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FiX } from 'react-icons/fi';
import { deleteTag } from '../../../../store/actions/tagsActions';

export default function DataTable ({ inputData, tags, showTag }){
    const [tableData, setTableData] = useState({ nodes: [], COLUMNS: [] });
  
    useEffect(() => {
      const { nodes, COLUMNS } = convertDataToNodesAndColumns(
        inputData,
        tags,
        showTag
      );
      setTableData({ nodes, COLUMNS });
    }, [inputData, tags, showTag]);
  
    const columns = useMemo(() => tableData.COLUMNS, [tableData.COLUMNS]);
  
    const table = useMantineReactTable({
      columns,
      data: tableData.nodes,
      enablePinning: true,
      initialState: {
        columnPinning: {
          right: showTag ? ["tags"] : [],
        },
      }
    });
  
    return <MantineReactTable table={table} />;
  }

  function convertDataToNodesAndColumns(inputData, tags, showTag) {
    if (inputData.length === 0) return { nodes: [], COLUMNS: [] };
  
    // Extract column labels from the first data object's keys
    const columnLabels = Object.keys(inputData[0]);
  
    // Create a function to highlight text with tags
    function highlightTextWithTags(text, tagsForRow) {
      // Create a container element to hold the processed text
      const container = document.createElement("div");
      let lastIndex = 0;
  
      tagsForRow.forEach((tag) => {
        // Find the index of the tag text in the original text
        const index = text.indexOf(tag.text, lastIndex);
        if (index > -1) {
          // Add text before the tag text
          container.appendChild(
            document.createTextNode(text?.substring(lastIndex, index))
          );
  
          // Create the mark element for the tag text
          const mark = document.createElement("mark");
          mark.style.backgroundColor = tag.tagId.highlightColor;
          mark.appendChild(document.createTextNode(tag.text));
          container.appendChild(mark);
  
          // Update the last index to the end of the current tag text
          lastIndex = index + tag.text.length;
        }
      });
  
      // Add any remaining text after the last tag text
      container.appendChild(document.createTextNode(text?.substring(lastIndex)));
  
      return container.innerHTML;
    }
  
    // Map over inputData to create nodes
    const nodes = inputData.map((item, rowIndex) => {
      // Find tags for the row
      const tagsForRow = tags.filter((tag) => parseInt(tag.start) === rowIndex);
  
      // Reduce the item into a new object that has all original properties plus tags
      const node = columnLabels.reduce((acc, key) => {
        acc[key] = item[key];
        return acc;
      }, {});
  
      // Add a property for the highlighted text for each column
      columnLabels.forEach((label) => {
        node[label] = highlightTextWithTags(node[label], tagsForRow);
      });
  
      // Add tags information for the row
      node.tags = tagsForRow.map((tag) => ({
        name: `${tag.tagId.title}${
          tag.frequency !== undefined ? ` (${tag.frequency})` : ""
        }`,
        frequency: tag.frequency,
        color: tag.tagId.color,
        id: tag._id,
      }));
  
      return node;
    });
  
    // Create the column definitions, including the Tags column if showTag is true
    let COLUMNS = columnLabels.map((label) => ({
      accessorKey: label,
      header: label.charAt(0).toUpperCase() + label.slice(1), // Capitalize the first letter
      Cell: ({ cell, row }) => (
        <div dangerouslySetInnerHTML={{ __html: cell.getValue() }} />
      ),
    }));
  
    if (showTag) {
      COLUMNS.push({
        id: "tags",
        header: "Tags",
        Cell: ({ row }) => (
          <div
            style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}
          >
            {row.original.tags.map((tag, idx) => (
              <OverlayTrigger
              key={idx}
              placement="top"
              overlay={
                tag?.frequency ? <Tooltip id={`tooltip-${idx}`}>
                  Appears {tag?.frequency || 0}{" "}
                  {tag?.frequency === 1 ? "time" : "times"}
                </Tooltip> : 
                <Tooltip id={`tooltip-${idx}`}>
                  Recently added
                </Tooltip>
              }
            >
              <span
                key={idx}
                style={{
                  backgroundColor: tag.color,
                  padding: "2px 4px",
                  borderRadius: "4px",
                }}
              >
                {tag.name}
                <FiX
                  style={{ marginLeft: "5px", cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteTag(tag.id);
                  }}
                />
              </span>
            </OverlayTrigger>
            ))}
          </div>
        ),
      });
    }
  
    return { nodes, COLUMNS };
  }

