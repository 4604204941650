import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Modal } from "@mantine/core";
import IconBtn from "../buttons/icon-button";
import { useSelector } from "react-redux";
import { getIconForAssetType } from "../../utils/iconsForAssetType";
import styles from "./index.module.css";

function ReportGen({
  handleClose,
  show,
  setSelectedVideos,
  selectedVideos,
  handleSubmit,
}) {
  const projectData = useSelector((state) => state.currentProject.project);
  const [prompt, setPrompt] = useState("");
  useEffect(() => {
    if (projectData?.videos?.length > 0) {
      setSelectedVideos(projectData?.videos?.map((video) => video._id));
    }
  }, [projectData]);
  return (
    <Modal
      size="xl"
      opened={show}
      onClose={handleClose}
      title="Create Report with AI"
    >
      <p>
        AI Report combines insights from all your selected files. It can create
        reports with tables, charts etc. Please provide detailed instructions on
        what the report should include.
      </p>
      <Form.Control
        style={{ resize: "none" }}
        as="textarea"
        rows={3}
        placeholder="Provide instructions to the AI to generate your presentation. You can ask to include questions, charts, etc."
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
      />
      <p className="mt-3">Select files to include in this report</p>
      <div className={styles.assetsContainer}>
        {projectData?.videos?.length > 0 &&
          projectData?.videos?.map((asset, index) => {
            return (
              <div key={index}>
                <IconBtn
                  isActive={selectedVideos.includes(asset?._id)}
                  type={asset?.assetType}
                  thumbnail={asset?.thumbnail}
                  icon={getIconForAssetType(asset?.assetType)}
                  handleClick={() =>
                    setSelectedVideos((prevState) => {
                      if (prevState.includes(asset?._id)) {
                        return prevState.filter((id) => id !== asset?._id);
                      } else {
                        return [...prevState, asset?._id];
                      }
                    })
                  }
                />
                <p className={styles.assetName}>
                  {asset?.name && asset.name.length > 10
                    ? `${asset.name.slice(0, 10)}...`
                    : asset.name}
                </p>
              </div>
            );
          })}
      </div>

      <Button
        variant="primary"
        className="mt-3"
        onClick={() => handleSubmit(prompt)}
        disabled={prompt.length === 0 || selectedVideos.length === 0}
      >
        Generate Report
      </Button>
    </Modal>
  );
}

export default ReportGen;
