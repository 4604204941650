import { createSelector } from "@reduxjs/toolkit";
import { laneActions } from "../slices/laneSlice";
import { callExternalApi } from "../../services/external-api.service";
import { toastError, toastSuccess } from "../../utils/toast";
import { tagsActions } from "../slices/tagsSlice";
import { Link } from "react-router-dom";
import React from "react";
const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

// create a new lane
export const createLaneAction = (title, projectId) => {
  return async (dispatch) => {
    dispatch(laneActions.requestStart());

    const config = {
      url: `${apiServerUrl}/api/board/lane/create`,
      method: "POST",
      data: { title, projectId },
    };

    const { data, error } = await callExternalApi({ config });

    if (error) {
      dispatch(laneActions.requestFail(error.message));
      toastError(error.message);
    } else {
      dispatch(laneActions.createLane(data));
      return data;
    }
  };
};

// load lanes for a project
export const loadLanesAction = (projectId) => {
  return async (dispatch) => {
    dispatch(laneActions.requestStart());
    const config = {
      url: `${apiServerUrl}/api/board/lane/project/${projectId}`,
      method: "GET",
    };

    const { data, error } = await callExternalApi({ config });

    if (error) {
      dispatch(laneActions.requestFail(error.message));
      toastError(error.message);
    }
    if (data) {
      dispatch(laneActions.loadLanes({ lanes: data }));
    }
  };
};

// load lanes for a project
export const loadPublicLanesAction = (projectId) => {
  return async (dispatch) => {
    dispatch(laneActions.requestStart());
    const config = {
      url: `${apiServerUrl}/api/board/lane/public/project/${projectId}`,
      method: "GET",
    };

    const { data, error } = await callExternalApi({ config });

    if (error) {
      dispatch(laneActions.requestFail(error.message));
      toastError(error.message);
    }
    if (data) {
      dispatch(laneActions.loadLanes({ lanes: data }));
    }
  };
};

// create a new card
export const createCardAction = (payload) => {
  return async (dispatch) => {
    const { laneId, insight, source, notes, type, projectId } = payload;

    dispatch(laneActions.requestStart());

    const config = {
      url: `${apiServerUrl}/api/board/card/create`,
      method: "POST",
      data: {
        laneId,
        insight,
        source,
        type,
        notes,
        projectId,
      },
    };

    const { data, error } = await callExternalApi({ config });

    if (error) {
      dispatch(laneActions.requestFail(error.message));
      toastError(error.message);
    } else {
      dispatch(laneActions.createCard({ data, laneId }));
      const CustomToastWithLink = () => (
        <>
          <p style={{ margin: "0px" }}>Card added successfully </p>
          <p style={{ margin: "0px" }}>
            <Link
              style={{ color: "#07bc0c" }}
              to={`/project/${projectId}/collection`}
            >
              View Collection
            </Link>
          </p>
        </>
      );
      toastSuccess(<CustomToastWithLink />);
    }
  };
};

// delete a card
export const deleteCardAction = (payload) => {
  return async (dispatch) => {
    const cardId = payload;
    dispatch(laneActions.requestStart());

    const config = {
      url: `${apiServerUrl}/api/board/card/${cardId}`,
      method: "DELETE",
    };

    const { data, error } = await callExternalApi({ config });

    if (error) {
      dispatch(laneActions.requestFail(error.message));
      toastError(error.message);
    }
    if (data) {
      dispatch(laneActions.deleteCard(cardId));
    }
  };
};

// delete a lane
export const deleteLaneAction = (laneId) => {
  return async (dispatch) => {
    dispatch(laneActions.requestStart());

    const config = {
      url: `${apiServerUrl}/api/board/lane/${laneId}`,
      method: "DELETE",
    };

    const { data, error } = await callExternalApi({ config });

    if (error) {
      dispatch(laneActions.requestFail(error.message));
      toastError(error.message);
    }

    if (data) {
      dispatch(laneActions.deleteLane(laneId));
    }
  };
};

// move card to another lane
export const moveCardAction = (payload) => {
  return async (dispatch) => {
    dispatch(laneActions.requestStart());
    const { cardId, targetLaneId, sourceLaneId } = payload;

    const config = {
      url: `${apiServerUrl}/api/board/lane/${cardId}/move`,
      method: "PUT",
      data: {
        targetLaneId,
        sourceLaneId,
      },
    };

    dispatch(laneActions.moveCard({ cardId, targetLaneId, sourceLaneId }));

    const { error } = await callExternalApi({ config });

    if (error) {
      dispatch(laneActions.requestFail(error.message));
      // move card back to original lane
      dispatch(
        laneActions.moveCard({
          cardId,
          targetLaneId: sourceLaneId,
          sourceLaneId: targetLaneId,
        })
      );
      toastError(error.message);
    }
  };
};

export const updateTagCardAction = (payload) => {
  return async (dispatch) => {
    const { tagId, cardId, updatedFields } = payload;
    const config = {
      url: `${apiServerUrl}/api/tags/${tagId}/card/${cardId}`,
      method: "PUT",
      data: {
        title: updatedFields.title,
      },
    };
    const { data, error } = await callExternalApi({ config });

    if (error) {
      toastError(error.message);
    }
    if (data) {
      dispatch(
        tagsActions.patchTagName({ tagId, title: updatedFields?.title })
      );
    }
  };
};

// update lane action
export const updateLaneAction = (payload) => {
  return async (dispatch) => {
    const { laneId, updatedFields } = payload;
    dispatch(laneActions.requestStart());

    const config = {
      url: `${apiServerUrl}/api/board/lane/${laneId}`,
      method: "PUT",
      data: {
        title: updatedFields.title,
      },
    };

    const { data, error } = await callExternalApi({ config });

    if (error) {
      dispatch(laneActions.requestFail(error.message));
      toastError(error.message);
    }
    if (data) {
      dispatch(laneActions.updateLane({ laneId, title: updatedFields?.title }));
    }
  };
};

// update card
export const updateCardAction = (payload) => {
  return async (dispatch) => {
    const { cardId, updatedFields } = payload;
    dispatch(laneActions.requestStart());

    const config = {
      url: `${apiServerUrl}/api/board/card/${cardId}`,
      method: "PUT",
      data: {
        insight: updatedFields.insight,
        source: updatedFields.source,
        type: updatedFields.type,
        notes: updatedFields.notes,
      },
    };

    const { data, error } = await callExternalApi({ config });

    if (error) {
      dispatch(laneActions.requestFail(error.message));
      toastError(error.message);
    }
    if (data) {
      dispatch(
        laneActions.updateCard({
          cardId,
          insight: updatedFields?.insight,
          source: updatedFields?.source,
          type: updatedFields?.type,
          notes: updatedFields?.notes,
        })
      );
    }
  };
};

// Add this new action for search
export const searchCardsAction = (searchTerm) => {
  return (dispatch, getState) => {
    dispatch(laneActions.searchCards(searchTerm));
  };
};

export const filteredCardsSelector = createSelector(
  (state) => state.lanes,
  (_, searchTerm) => searchTerm,
  (lanes, searchTerm) => {
    if (!searchTerm) return lanes;

    const filteredLanes = lanes.map((lane) => {
      const filteredCards = lane.cards.filter(
        (card) =>
          card.insight?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          card.source?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          card.notes?.toLowerCase().includes(searchTerm?.toLowerCase())
      );
      return { ...lane, cards: filteredCards };
    });

    return filteredLanes;
  }
);
