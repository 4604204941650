/* eslint-disable no-unused-vars */
import React from "react";
import { PageLayout } from "../../components/page-layout";
import { Container, Button, Row, Col } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { FiFileText, FiPlus, FiSearch, FiUsers } from "react-icons/fi";
import { TextInput, Button as MantineButton } from "@mantine/core";
import style from "./index.module.css";
import { useSelector } from "react-redux";
import { darkImages, lightImages } from "../../images/project-images";
import { getAllTests } from "../../store/actions/testActions";
import { hashProjectId } from "../participants/profile/components/cover-image";

const TEST_STATUS = {
  LIVE: "Live",
  DRAFT: "Draft",
  COMPLETED: "Completed",
};

export const Tests = () => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const history = useHistory();
  const { darkMode } = useSelector((state) => state.theme);
  const { allTests = [] } = useSelector((state) => state.test);

  React.useEffect(() => {
    getAllTests();
  }, []);

  return (
    <PageLayout>
      <div className={style.wrapper}>
        <Container className={style.container}>
          <Row>
            <Col>
              <div className={style["tests-header"]}>
                <div style={{ minWidth: 300, width: "33%", fontWeight: "600" }}>
                  User Interviews
                </div>

                <TextInput
                  placeholder="Search interviews..."
                  className={style.searchInput}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && searchTerm) {
                      history.push({
                        pathname: "/search",
                        search: `?q=${encodeURIComponent(searchTerm)}`,
                      });
                    }
                  }}
                  rightSection={
                    <div
                      className={style.searchIcon}
                      onClick={() => {
                        if (searchTerm) {
                          history.push({
                            pathname: "/search",
                            search: `?q=${encodeURIComponent(searchTerm)}`,
                          });
                        }
                      }}
                    >
                      <FiSearch />
                    </div>
                  }
                  styles={{ input: { paddingRight: "2rem" } }}
                />

                <div style={{ textAlign: "right", width: "33%" }}>
                  <MantineButton
                    leftIcon={<FiPlus style={{ marginRight: "0.625rem" }} />}
                    onClick={() =>
                      history.push({ pathname: "/interviews/new" })
                    }
                    variant="filled"
                    className={style.newInterviewButton}
                  >
                    New Interview
                  </MantineButton>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className={style["tests-container"]}>
              {allTests.length ? (
                [...allTests]
                  .reverse()
                  .filter((test) =>
                    test.title.toLowerCase().includes(searchTerm)
                  )
                  .map((test, index) => (
                    <TestCard
                      key={index}
                      darkMode={darkMode}
                      id={test._id}
                      type={test.type}
                      title={test.title}
                      status={test.status}
                      category="Unmoderated Interview"
                      nQuestions={test.nQuestions}
                      nParticipants={test.nParticipants}
                      interviewsCompleted={test.interviewsCompleted}
                      link={test.link}
                    />
                  ))
              ) : (
                <div className="position-absolute top-50 start-50 translate-middle text-center w-75 ms-4">
                  You have not created any interviews yet.
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </PageLayout>
  );
};

const TestCard = ({
  darkMode,
  id,
  type,
  title,
  status,
  category,
  nQuestions,
  interviewsCompleted,
  nParticipants,
  link,
}) => {
  const history = useHistory();

  return (
    <div
      role="button"
      className={style.test}
      onClick={() =>
        status === TEST_STATUS.DRAFT
          ? history.push(`/interviews/${type}/${id}/describe-test`)
          : history.push(`/interview/${id}/progress`)
      }
    >
      <div className={style["donut-container"]}>
        <img
          src={
            darkMode
              ? darkImages[hashProjectId(id)]
              : lightImages[hashProjectId(id)]
          }
          alt="test"
        />
        <div className={style.donut}>
          <svg width="100%" height="100%" viewBox="0 0 40 40" className="donut">
            <circle
              className="donut-ring"
              cx="20"
              cy="20"
              r="15.91549430918954"
              fill="transparent"
              strokeWidth="3.5"
            ></circle>
            <circle
              className="donut-segment donut-segment-2"
              cx="20"
              cy="20"
              r="15.91549430918954"
              fill="transparent"
              strokeWidth="3.5"
              strokeDasharray={`${
                interviewsCompleted
                  ? (interviewsCompleted / nParticipants) * 100
                  : 0
              } ${
                interviewsCompleted
                  ? 100 - (interviewsCompleted / nParticipants) * 100
                  : 100
              }`}
              strokeDashoffset={(interviewsCompleted / nParticipants) * 100}
              strokeLinecap="round"
            ></circle>
            <g className="donut-text donut-text-1">
              <text y="57%">
                <tspan x="50%" textAnchor="middle" className="donut-percent">
                  {interviewsCompleted}/{nParticipants}
                </tspan>
              </text>
            </g>
          </svg>
        </div>
        <p style={{ zIndex: 100 }}>Interviews Completed</p>
      </div>
      <div className={style["test-info"]}>
        <div>
          <div className={style.title}>
            {title}
            <span type={status} className={style.badge}>
              {status}
            </span>
          </div>
          <p className={style.category}>{category}</p>
        </div>
        <div className={style.stats}>
          <span>
            <FiFileText /> {nQuestions} questions
          </span>
          <span>
            <FiUsers /> {nParticipants} participants
          </span>
        </div>
      </div>
      <Button
        as={Link}
        to={link}
        variant="outline-primary"
        className={style.viewButton}
        onClick={() =>
          status === TEST_STATUS.DRAFT
            ? history.push(`/interviews/${type}/${id}/describe-test`)
            : history.push(`/interview/${id}/progress`)
        }
      >
        View
      </Button>
    </div>
  );
};
