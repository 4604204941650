import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  tags: [],
  loading: false,
  error: null,
};

const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    requestStart(state) {
      state.loading = true;
      state.error = null;
    },
    getTags(state, action) {
      state.tags = action.payload.tags;
    },
    deleteTag(state, action) {
      state.tags = state.tags.filter((tag) => tag._id !== action.payload);
    },
    deleteTagIds(state, action) {
      state.tags = state.tags.filter((tag) =>
        action.payload.every((id) => id !== tag.tagId._id)
      );
    },
    deleteEntireTag(state, action) {
      state.tags = state.tags.filter((tag) => tag.tagId._id !== action.payload);
    },
    addTag(state, action) {
      state.tags = [...state.tags, action.payload];
    },
    requestFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    patchTagName(state, action) {
      state.tags = state.tags.map((tag) => {
        if (tag.tagId._id === action.payload.tagId) {
          let tagId = {
            ...tag.tagId,
            title: action.payload.title,
          };
          return {
            ...tag,
            tagId: tagId,
          };
        } else {
          return tag;
        }
      });
    },
    moveTagCard(state, action) {
      let { cardId, data } = action.payload;
      state.tags = state.tags.filter((tag) => tag._id !== cardId);
      state.tags = [...state.tags, data];
    },
  },
});

export default tagsSlice;
export const tagsActions = tagsSlice.actions;
