import React from 'react';
import { useSelector } from 'react-redux';
import {ReportTab} from '../components/tabs/report';
import { EmptyProject } from '../components/empty-project';
import { loadProject } from '../../../store/actions/currentProjectActions';

export default function ProjectReport() {
    const projectData = useSelector(state => state.currentProject.project);
    const darkMode = useSelector(state => state.theme.darkMode);
    if(projectData?.status === "Empty"){
        return <EmptyProject darkMode={darkMode && darkMode} />
    }
    const runGetProjectData = () => {
        loadProject(projectData._id)
    }
    return <ReportTab 
        projectData={projectData} 
        runGetProjectData={runGetProjectData} 
    />
}