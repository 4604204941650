import React from "react";
import { Button } from "react-bootstrap";
import styles from "./integration.module.css";
import { PageLayout } from "../../components/page-layout";

export default function Integration({ app, image, details }) {
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1)?.toLowerCase();
    });
  };

  if (!app) {
    return <div></div>;
  }
  const onConnect = () => {
    window.location = app.appUrl;
  };

  let connectButton = (
    <Button onClick={onConnect} variant="outline-primary">
      Connect {toTitleCase(app.name)}
    </Button>
  );
  if (app.status === "CONNECTED") {
    connectButton = <Button variant={`success`}>✓ Connected</Button>;
  }

  if (app.name === "ZAPIER") {
    connectButton = (
      <Button
        variant={`primary`}
        href="https://zapier.com/developer/public-invite/181619/73201059ad5be3ab0d02c05afd78ada1/"
        target="_blank"
      >
        Connect Zapier
      </Button>
    );
  }

  return (
    <PageLayout>
      <div className={styles.wrapper}>
        <div
          className="zoom-integration-container"
          style={{
            backgroundColor: `var(--black)`,
          }}
        >
          <div className="integration-container-img">
            <img
              src={image}
              alt=""
              style={{ height: "auto", borderRadius: "8px" }}
            />
            {connectButton}
            <div>
              <h4>Support Contact</h4>
              <p>suport@userevaluation.com</p>
            </div>
          </div>
          <div>{details}</div>
        </div>
      </div>
    </PageLayout>
  );
}
