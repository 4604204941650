import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { Modal } from "@mantine/core";
import styles from "./createClipModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../../../../store/slices/modalSlice";
import { createClipAction } from "../../../../store/actions/clipActions";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import formatTime from "../../../../utils/formatTime";
import MiniPlayer from "../../../../components/mini-player";
import GetSpeakerData from "./speakerName";

function CreateClipModal({ selectionData, speakers, selectedVideo }) {
  const projectData = useSelector((state) => state.currentProject.project);
  const { createclip: show } = useSelector((state) => state.modal);
  const projectId = projectData._id;
  const dispatch = useDispatch();
  let assetId = selectedVideo?._id;
  const [clipNotes, setClipNotes] = useState("");
  const [tags, setTags] = useState([]);

  const videoRef = useRef(null);
  const [currentPlayerTime, setCurrentPlayerTime] = useState(0);
  const currentWord = currentPlayerTime * 1000;
  const handleSeek = (time) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time / 1000; // Convert to seconds
    }
  };

  return (
    <Modal
      opened={show}
      centered
      backdrop="static"
      size="lg"
      className="pay-modal-container"
      onClose={() => {
        setClipNotes("");
        setTags([]);
        dispatch(modalActions.hideCreateClipModal());
      }}
      title="Create a Clip"
    >
      <div>
        <div className={styles.modalSpeakerSection}>
          {Array.isArray(selectionData) &&
            selectionData?.map((item, index) => {
              return (
                <div key={index}>
                  <p>
                    <GetSpeakerData
                      speakers={speakers}
                      sentenceSpeaker={item.speaker}
                    />
                  </p>
                  <p>
                    {" "}
                    <span
                      className={styles.timeStamp}
                      onClick={() => handleSeek(item.start)}
                    >
                      {formatTime(item.start)} - {formatTime(item.end)}
                    </span>
                  </p>
                  <p>
                    {item.words.map((word) => {
                      const isCurrentWord =
                        currentWord >= word.start && currentWord <= word.end;
                      return (
                        <span key={word.start + 1}>
                          <span
                            key={word.start}
                            className={
                              isCurrentWord ? "transcript-highlight" : ""
                            }
                          >
                            {word.text}
                          </span>
                          {word !== item.words[item.words.length - 1] && " "}
                        </span>
                      );
                    })}
                  </p>
                </div>
              );
            })}
        </div>
        <div className={styles.clipInfo}>
          <div className={styles.player}>
            {selectedVideo && (
              <MiniPlayer
                setCurrentPlayerTime={setCurrentPlayerTime}
                file={selectedVideo}
                startTime={selectionData[0]?.start}
                endTime={selectionData[selectionData?.length - 1]?.end}
              />
            )}
          </div>
          <div className={styles.notesAndTagsContainer}>
            <div>
              <p>Clip Notes</p>
              <textarea
                className={styles.clipNotes}
                name="clipNotes"
                id="clipNotes"
                cols="30"
                rows="2"
                value={clipNotes}
                onChange={(e) => setClipNotes(e.target.value)}
              ></textarea>
            </div>
            <div>
              <p>Tags</p>
              <TagsInput
                name="tags"
                id="tags"
                className={styles.tags}
                value={tags}
                onChange={(value) => {
                  setTags(value);
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.clipButtonContainer}>
          <div className={styles.otherButton}>
            <button
              onClick={() => {
                setClipNotes("");
                setTags([]);
                dispatch(modalActions.hideCreateClipModal());
              }}
            >
              dismiss
            </button>
            <Button
              variant="primary"
              onClick={() => {
                createClipAction({
                  title: "test",
                  assetId: assetId,
                  projectId: projectId,
                  clipNotes: clipNotes,
                  clipTags: tags,
                  startTime: selectionData[0]?.start,
                  endTime: selectionData[selectionData?.length - 1]?.end,
                });
                setClipNotes("");
                setTags([]);
                dispatch(modalActions.hideCreateClipModal());
              }}
            >
              save clip
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CreateClipModal;
