import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Dropdown } from "react-bootstrap";
import { Menu, Button } from "@mantine/core";
import { FiEdit2, FiMoreHorizontal } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import formatDuration from "../../../../utils/formatDuration.js";
import EditVideoTitleModal from "./EditVideoTitleModal";
import RemoveFileModal from "./removeFileModal";
import { mapColortoSpeaker } from "./mapColorToSpeaker.js";
import { currentProjectActions } from "../../../../store/slices/currentProject.js";
import styles from "./index.module.css";
import { TbNotes } from "react-icons/tb";
import EditAssetNotesModal from "./editAssetNotesModal.js";

let timeFull = 40;
function AssetShower({ selectedVideo, videoRef, setCurrentPlayerTime }) {
  const { darkMode } = useSelector((state) => state.theme);
  const [showEditTitle, setShowEditTitle] = useState(false);
  const [showDeleteVideo, setShowDeleteVideo] = useState(false);
  const [notesModal, setNotesModal] = useState(false);
  const { isPublic, project } = useSelector((state) => state.currentProject);
  const dispatch = useDispatch();
  let speakers = createVideoSpeaker(
    selectedVideo,
    darkMode,
    selectedVideo?.speakers || []
  );
  const isNotesAvailable = project.videos.find(
    (video) => video._id === selectedVideo._id
  )?.notes
    ? true
    : false;
  const handleEdit = () => {
    setShowEditTitle(!showEditTitle);
  };

  const updateVideoTitle = (title) => {
    try {
      dispatch(
        currentProjectActions.setAssetTitle({
          asset: { _id: selectedVideo._id, title },
        })
      );
    } catch (err) {
      console.error(err);
    }
    // runGetProjectData(projectData._id, true);
    setShowEditTitle(false);
  };
  return (
    <div className={styles.assetInfo}>
      <EditVideoTitleModal
        show={showEditTitle}
        onClose={handleEdit}
        videoId={selectedVideo?._id}
        videoTitle={selectedVideo?.name}
        onChange={updateVideoTitle}
      />
      <RemoveFileModal
        showDeleteVideo={showDeleteVideo}
        setShowDeleteVideo={setShowDeleteVideo}
        selectedVideo={selectedVideo}
      />

      <EditAssetNotesModal
        opened={notesModal}
        onClose={() => setNotesModal(false)}
        name={selectedVideo?.name}
        assetId={selectedVideo?._id}
        alreadyAdded={true}
      />

      <div className={styles.assetName}>
        <div>
          {selectedVideo?.name}
          {!isPublic && (
            <Menu
              className="menu-container"
              shadow="md"
              transitionProps={{ transition: "pop-top-right", duration: 150 }}
              position="bottom-left"
            >
              <Menu.Target>
                <Button>
                  <FiMoreHorizontal />
                </Button>
              </Menu.Target>

              <Menu.Dropdown className="menu-dropdown">
                <Menu.Item
                  className={styles.notesMenuItem}
                  onClick={() => setShowEditTitle(true)}
                >
                  <FiEdit2 />
                  &nbsp;&nbsp;Rename
                </Menu.Item>
                <Menu.Item
                  className={styles.notesMenuItem}
                  onClick={() => {
                    setNotesModal(true);
                  }}
                >
                  <TbNotes />
                  &nbsp;&nbsp;{isNotesAvailable ? "Edit" : "Add"} Notes
                </Menu.Item>
                <Menu.Item
                  className={styles.notesMenuItem}
                  onClick={() => setShowDeleteVideo(true)}
                >
                  <RiDeleteBinLine />
                  &nbsp;&nbsp;Remove File
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}
        </div>
        <span>
          {formatDuration(selectedVideo.duration)}
          {" video"}
        </span>
      </div>

      <div className={`speakers-time-graph ${styles.timeGraph}`}>
        {speakers?.map((speaker, index) => {
          return (
            <div key={index}>
              <p className={styles.speakerName}>
                {speaker.name ? speaker.name : `Speaker ${speaker.speaker}`}
                {speaker.role?.trim().length > 0
                  ? " (" + speaker.role + ")"
                  : ""}
              </p>
              <div className="speaker-speaking-part">
                {speaker.parts.map((part, index) => {
                  return (
                    <div
                      onClick={() => {
                        videoRef.current.currentTime = part.start / 1000;
                        setCurrentPlayerTime(part.start / 1000);
                      }}
                      key={index}
                      style={{
                        backgroundColor: `${speaker.color}`,
                        width: `${((part.end - part.start) / timeFull) * 100}%`,
                        left: `${(part.start / timeFull) * 100}%`,
                      }}
                    ></div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
const createVideoSpeaker = (selectedVideo, darkMode, allSpeakers) => {
  if (
    selectedVideo &&
    selectedVideo.transcript &&
    selectedVideo.transcript[0] &&
    selectedVideo.transcript[0].words &&
    selectedVideo.transcript[0].words.length > 0
  ) {
    let start = selectedVideo.transcript[0]?.words[0]?.start;
    let end = selectedVideo.transcript[0]?.words?.slice(-1)[0].end;
    let duration = end - start;
    timeFull = duration;
    let speakerData = [];
    if (selectedVideo.transcript[0].utterances) {
      selectedVideo.transcript[0].utterances.forEach((utterance) => {
        let speaker = utterance.speaker;
        let start = utterance.start;
        let end = utterance.end;
        let flag = false;
        for (let i = 0; i < speakerData.length; i++) {
          if (speakerData[i].speaker === speaker) {
            flag = true;
            speakerData[i].parts.push({
              start: start,
              end: end,
            });
          }
        }
        if (!flag) {
          speakerData.push({
            speaker: speaker,
            color: mapColortoSpeaker(speaker, darkMode),
            parts: [
              {
                start: start,
                end: end,
              },
            ],
          });
        }
      });
    }

    // merge name of speaker into speakerData from allSpeakers
    if (allSpeakers && allSpeakers.length > 0) {
      speakerData.forEach((speaker) => {
        let spk = allSpeakers.find((spk) => {
          return spk.label === speaker.speaker;
        });
        if (spk) {
          speaker.name = spk.name;
          speaker.role = spk.role;
        }
      });
    }

    let newSpeakerData = [];
    speakerData.forEach((speaker) => {
      let spk = newSpeakerData.find((spk) => {
        if (speaker.name === undefined || speaker.role === undefined) {
          return false;
        }
        return spk.name === speaker.name && spk.role === speaker.role;
      });
      if (spk) {
        spk.parts = spk.parts.concat(speaker.parts);
      } else {
        newSpeakerData.push({
          name: speaker.name,
          role: speaker.role,
          speaker: speaker.speaker,
          color: speaker.color,
          parts: speaker.parts,
        });
      }
    });
    speakerData = newSpeakerData;
    return speakerData;
  }
};
export default AssetShower;
