import React, { useEffect, useState } from "react";

import TopBar from "./topbar.js";
import { RAIDisclaimer } from "./raid-disclaimer.js";
import Insights from "./insights.js";
import { useDispatch, useSelector } from "react-redux";
import {
  createLaneAction,
  createCardAction,
  loadLanesAction,
} from "../../../../../store/actions/laneActions.js";
import { useLocation } from "react-router-dom";
import AddAIInsightModal from "./addAiInsightModal.js";
import { ribbonActions } from "../../../../../store/slices/ribbonSlice.js";
import EmptyStatePDF from "../components/emptyState.js";

function InsightsTab({ selectedVideo, handleSeek }) {
  const [selectedInsightTab, setSelectedInsightTab] = useState("");
  const [insightsToShow, setInsightsToShow] = useState([]);
  const [morethan10, setMorethan10] = useState(false);
  const { lanes } = useSelector((state) => state.lanes);
  const { data } = useSelector((state) => state.ribbon);
  const { project: projectData, isPublic } = useSelector(
    (state) => state.currentProject
  );
  const { account } = useSelector((state) => state.auth);
  const isFree = account?.plan?.type === "Free";
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    let arr = [
      {
        name: "Delighters",
        value: "Delighters",
      },
      {
        name: "Pain Points",
        value: "PainPoints",
      },
      {
        name: "Key Insights",
        value: "KeyInsights",
      },
      {
        name: "Questions for Next Session",
        value: "QuestionsforNextSession",
      },
      {
        name: "Topics",
        value: "Topics",
      },
      {
        name: "Quotable Quotes",
        value: "QuotableQuotes",
      },
      {
        name: "Opposing Views",
        value: "OpposingViews",
      },
      {
        name: "Feature Requests",
        value: "FeatureRequests",
      },
      {
        name: "Bug Reports",
        value: "BugReports",
      },
      {
        name: "Jobs To Be Done",
        value: "JobsToBeDone",
      },
      {
        name: "Opportunity Areas",
        value: "OpportunityAreas",
      },
    ];
    let type = location.pathname.split("/")[5];

    let check = false;

    arr.forEach((el) => {
      if (el.value === type) {
        setSelectedInsightTab(el.name);
        check = true;
      }
    });
    if (!check) {
      const firstInsightType = selectedVideo?.insights?.[0]?.insightType;
      setSelectedInsightTab(firstInsightType);
    }
  }, [location.pathname, setSelectedInsightTab]);

  const collectInsight = async (insightType, singleInsight, videName) => {
    let uncategorizedLaneId;
    let uncategorizedLane =
      lanes && lanes.find((lane) => lane.title === insightType);
    if (uncategorizedLane === undefined) {
      const newLane = await dispatch(
        createLaneAction(insightType, projectData._id)
      );
      uncategorizedLaneId = newLane._id;
    } else {
      uncategorizedLaneId = uncategorizedLane._id;
    }

    // in type also add the name of the video

    const payload = {
      laneId: uncategorizedLaneId,
      insight: singleInsight.insightText,
      source: singleInsight.source,
      type: [selectedVideo.name],
      projectId: projectData._id,
      notes: "",
    };

    await dispatch(createCardAction(payload));
  };
  useEffect(() => {
    if (selectedVideo?.insights?.length > 0) {
      let insightsToShow = selectedVideo?.insights?.filter((insight) => {
        return insight?.insightType === selectedInsightTab;
      });
      let insights = [];

      if (isFree) {
        // only show first 10 insights
        if (insightsToShow[0]?.insights.length > 10) {
          insightsToShow[0]?.insights.slice(0, 10).forEach((insight) => {
            if (
              insight?.insightText
                ?.toLowerCase()
                .includes(data?.searchTerm?.toLowerCase()) ||
              isPublic
            ) {
              insights.push(insight);
            }
          });

          setMorethan10(true);
        } else {
          insightsToShow[0]?.insights.forEach((insight) => {
            if (
              insight?.insightText
                ?.toLowerCase()
                .includes(data?.searchTerm?.toLowerCase()) ||
              isPublic
            ) {
              insights.push(insight);
            }
          });
        }
      } else {
        insightsToShow[0]?.insights.forEach((insight) => {
          if (
            insight?.insightText
              ?.toLowerCase()
              .includes(data?.searchTerm?.toLowerCase()) ||
            isPublic
          ) {
            insights.push(insight);
          }
        });
      }

      setInsightsToShow(insights);
    }
  }, [selectedInsightTab, selectedVideo, data?.searchTerm, isPublic]);

  useEffect(() => {
    dispatch(
      ribbonActions.setRibbonData({
        name: "insightType",
        value: selectedInsightTab,
      })
    );
  }, [selectedInsightTab]);

  useEffect(() => {
    if (!isPublic && projectData?._id) {
      dispatch(loadLanesAction(projectData._id));
    }
  }, [dispatch, projectData, isPublic]);

  const isPreparing = projectData?.status === "Preparing";
  if (!isPreparing && selectedVideo?.insights?.length === 0) {
    return <EmptyStatePDF type={"insights"} />;
  }

  return (
    <>
      <AddAIInsightModal
        selectedVideo={selectedVideo}
        projectId={projectData?._id}
      />
      <TopBar
        selectedInsightTab={selectedInsightTab}
        setSelectedInsightTab={setSelectedInsightTab}
        selectedVideo={selectedVideo}
        assetId={selectedVideo?._id}
        projectId={projectData?._id}
      />
      <RAIDisclaimer />
      <Insights
        handleSeek={handleSeek}
        selectedInsightTab={selectedInsightTab}
        insightsToShow={insightsToShow}
        selectedVideo={selectedVideo}
        collectInsight={collectInsight}
        setInsightsToShow={setInsightsToShow}
        insightType={selectedInsightTab}
        isFree={isFree}
        morethan10={morethan10}
      />
    </>
  );
}

export default InsightsTab;
