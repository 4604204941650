import React from "react";
import { Button, Form } from "react-bootstrap";
import {
  loadEmailPrefs,
  updateEmailPrefs,
} from "../../../store/actions/emailActions";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

const PREFS = [
  {
    id: "auth",
    default: true,
    label: "Account related information and security notifications",
  },
  {
    id: "insights",
    default: true,
    label:
      "Insights emails, triggered when insights on uploaded assets are ready",
  },
  {
    id: "info",
    default: true,
    label: "Informational emails, including new features and product updates",
  },
  {
    id: "marketing",
    default: true,
    label: "Promotions and discounts and other marketing emails",
  },
];

const EmailPrefs = () => {
  const [prefs, setPrefs] = React.useState(
    PREFS.reduce((acc, pref) => ({ ...acc, [pref.id]: pref.default }), {})
  );

  const { emailPrefs, loading } = useSelector((state) => state.email);

  React.useEffect(() => {
    if (emailPrefs) {
      setPrefs(emailPrefs);
    }
  }, [emailPrefs]);

  React.useEffect(() => {
    loadEmailPrefs();
  }, []);

  const updatePrefs = (e) => {
    e.preventDefault();
    updateEmailPrefs(prefs);
  };

  return (
    <>
    <h2 className="mt-3 mb-3" style={{fontSize: 20}}>Email Preferences</h2>
      {loading ? (
        <Spinner
          animation="border"
          variant="primary"
          className="mx-auto d-block"
        />
      ) : (
        <Form onSubmit={updatePrefs}>
          <p>Choose which emails you want to receive:</p>
          {PREFS.map((pref) => (
            <Form.Check
              key={pref.id}
              type="checkbox"
              name={pref.id}
              id={pref.id}
              label={pref.label}
              checked={prefs[pref.id]}
              onChange={(e) =>
                setPrefs({ ...prefs, [pref.id]: e.target.checked })
              }
              className="mb-2 user-select-none"
            />
          ))}

          <Form.Check
            type="checkbox"
            name="none"
            id="none"
            label="Unsubscribe from all emails"
            checked={Object.values(prefs).every((pref) => !pref)}
            onChange={(e) => {
              if (e.target.checked) {
                setPrefs(
                  PREFS.reduce(
                    (acc, pref) => ({ ...acc, [pref.id]: false }),
                    {}
                  )
                );
              } else {
                setPrefs(
                  PREFS.reduce((acc, pref) => ({ ...acc, [pref.id]: true }), {})
                );
              }
            }}
            className="mb-3 user-select-none"
          />
          <Button variant="primary" type="submit">
            Update Email Preferences
          </Button>

          <hr style={{ margin: "2rem 0" }} />
        </Form>
      )}
    </>
  );
};

export default EmailPrefs;
