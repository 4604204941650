import { createSlice } from "@reduxjs/toolkit";

const participantSlice = createSlice({
  name: "participant",
  initialState: {
    participant: null,
    loading: null,
    error: null,
  },
  reducers: {
    requestStart(state) {
      state.loading = true;
      state.error = null;
    },
    setParticipant(state, action) {
      state.loading = false;
      state.participant = action.payload;
      state.error = null;
    },
    requestFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    requestSuccess(state) {
      state.loading = false;
      state.error = null;
    },
  },
});

export default participantSlice;
export const participantActions = participantSlice.actions;
