import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import csvDownload from "json-to-csv-export";
import { Button, Spinner, Container, Modal, Form } from "react-bootstrap";
import { getProject } from "../../store/actions/projectActions";
import { createLinkAction } from "../../store/actions/publicLinkActions";

import { modalActions } from "../../store/slices/modalSlice";
import chartImg from "../../images/people-evaluation.svg";
import chartImgBlack from "../../images/people-evaluation-black.svg";
import { toastSuccess } from "../../utils/toast";
import ProjectPageLayout from "./layout";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
import ProjectChat from "./chat";
import Overview from "./overview";
import ProjectReport from "./report";
import ProjectBoard from "./board";
import ProjectNotePage from "./notes";
import Tags from "./tags";
import ClipsPage from "./clips";
import ProjectPptx from "./pptx";
import AssetPage from "./asset";
import AIReport from "./ai-report";
import { ErrorPage } from "./error-page";
import { useGetCollectionCsvData } from "./components/getCollectionCsvData";

export const ProjectPage = ({ match }) => {
  const { darkMode } = useSelector((state) => state.theme);
  const {
    account: userAccount,
    loading,
    isAuthenticated,
  } = useSelector((state) => state.auth);
  const { link } = useSelector((state) => state.publicLink);
  const [errorPage, setErrorPage] = useState(false);
  const { projectId } = match.params;
  const dispatch = useDispatch();
  const [isProjectReady, setIsProjectReady] = useState(false);
  const { boardLinkSharePopup, reportLinkSharePopup, boardExportPopup } =
    useSelector((state) => state.modal);
  const [csvExportSettings, setCsvExportSettings] = useState({
    includeSource: true,
    includeNotes: true,
    includeTags: true,
  });
  const csvData = useGetCollectionCsvData(csvExportSettings);

  const [selectedVideo, setSelectedVideo] = useState(null);

  const { project: projectData, loading: loadingProjectData } = useSelector(
    (state) => state.currentProject
  );

  useEffect(() => {
    // const { page, } = getQueryParams(search);

    // if (page) {
    //   if (page === "chat") setActiveTab("chat");
    //   if (page === "report") setActiveTab("report");
    //   if (page === "overview") setActiveTab("overview");
    // }

    let isMounted = true;
    const getProjectData = async () => {
      runGetProjectData(projectId, isMounted);
    };

    if (isAuthenticated && projectData?._id !== projectId) getProjectData();

    //  I want to run getProjectData once when the page loads, and the authentication is done.
  }, [isAuthenticated, projectId, projectData]);

  const runGetProjectData = async (id, isMounted) => {
    let projectReady = false;
    while (!projectReady) {
      let { data, error } = await getProject({ id });

      if (!isMounted) {
        return;
      }

      if (data) {
        if (data.status === "Ready" || data.status === "Empty") {
          projectReady = true;
          setIsProjectReady(true);
          // setProjectData(data);
          if (selectedVideo) {
            let video = data.videos.find(
              (video) => video._id === selectedVideo._id
            );
            setSelectedVideo(video);
          }
        } else {
          // setProjectData(data);
          // if project is not Empty
          if (data.status !== "Empty");

          // What i want to do here is that if the status is not empty or ready then refetch the product
          await new Promise((resolve) => setTimeout(resolve, 10000));
        }
      }
      if (error) {
        setErrorPage(true);
        console.error(error);
        break;
      }
    }
  };

  const shareCollection = async (resourceId) => {
    const payload = {
      resourceId,
      resource: "project board",
      createdBy: userAccount._id,
    };

    await createLinkAction(payload);
  };

  const shareReport = async (resourceId) => {
    const payload = {
      resourceId,
      resource: "project report",
      createdBy: userAccount._id,
    };

    await createLinkAction(payload);
  };

  const copyLink = async (link) => {
    await navigator.clipboard.writeText(link);
    toastSuccess("Link copied to clipboard");
  };

  if (errorPage) {
    return <ErrorPage />;
  }

  return (
    <ProjectPageLayout>
      

      {!loading && !loadingProjectData && (
        <React.Fragment>
          <Modal
            show={boardLinkSharePopup}
            onHide={() => dispatch(modalActions.hideShareBoardLinkModal())}
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Share Collection</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Share this collection with people outisde your organization
                using the link below
              </p>
              <div className="d-flex">
                <input
                  type="text"
                  readOnly
                  value={
                    link &&
                    link._id !== undefined &&
                    link.resource === "project board"
                      ? "https://app.userevaluation.com/public/" + link?._id
                      : "Click Create Link to generate a link"
                  }
                  className="form-control me-2"
                />
                <Button
                  variant="outline-primary"
                  className="me-2"
                  style={{ minWidth: 150 }}
                  onClick={() => {
                    shareCollection(projectId);
                  }}
                >
                  {link &&
                  link._id !== undefined &&
                  link.resource === "project board"
                    ? "Update Link"
                    : "Create Link"}
                </Button>
                <Button
                  variant="primary"
                  style={{ minWidth: 80 }}
                  onClick={() => {
                    copyLink(
                      "https://app.userevaluation.com/public/" + link?._id
                    );
                  }}
                >
                  Copy
                </Button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={boardExportPopup}
            onHide={() => dispatch(modalActions.hideExportBoardModal())}
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Export Collection</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Export this collection as a CSV file and import it to your
                favorite tools like Miro, Mural, and more.
              </p>
              {/* checkbox to change csv export settings arrage vertically */}
              <div className="d-flex flex-column mb-3">
                <Form.Check
                  type="checkbox"
                  label="Include source"
                  checked={csvExportSettings.includeSource}
                  onChange={(e) => {
                    setCsvExportSettings({
                      ...csvExportSettings,
                      includeSource: e.target.checked,
                    });
                  }}
                />
                <Form.Check
                  type="checkbox"
                  label="Include notes"
                  checked={csvExportSettings.includeNotes}
                  onChange={(e) => {
                    setCsvExportSettings({
                      ...csvExportSettings,
                      includeNotes: e.target.checked,
                    });
                  }}
                />
                <Form.Check
                  type="checkbox"
                  label="Include tags"
                  checked={csvExportSettings.includeTags}
                  onChange={(e) => {
                    setCsvExportSettings({
                      ...csvExportSettings,
                      includeTags: e.target.checked,
                    });
                  }}
                />
              </div>

              <div className="d-flex mt-4">
                <Button
                  variant="primary"
                  className="me-2"
                  style={{ minWidth: 150 }}
                  onClick={() => {
                    const dataToConvert = {
                      data: csvData,
                      filename: projectData.name + ".csv",
                      delimiter: ",",
                    };

                    csvDownload(dataToConvert);
                  }}
                >
                  Export CSV
                </Button>
                <Button
                  variant="outline-primary"
                  style={{ minWidth: 80 }}
                  onClick={() => {
                    window.open(
                      "https://docs.userevaluation.com/docs/guides/export-collection-to-miro-or-mural/",
                      "_blank"
                    );
                  }}
                >
                  Learn how to import to Miro or Mural
                </Button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={reportLinkSharePopup}
            onHide={() => dispatch(modalActions.hideShareReportLinkModal())}
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Share Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Share this report with people outisde your organization using
                the link below
              </p>
              <div className="d-flex">
                <input
                  type="text"
                  readOnly
                  value={
                    link &&
                    link._id !== undefined &&
                    link.resource === "project report"
                      ? "https://app.userevaluation.com/public/" + link?._id
                      : "Click Create Link to generate a link"
                  }
                  className="form-control me-2"
                />
                <Button
                  variant="outline-primary"
                  className="me-2"
                  style={{ minWidth: 150 }}
                  onClick={() => {
                    shareReport(projectId);
                  }}
                >
                  {link &&
                  link._id !== undefined &&
                  link.resource === "project report"
                    ? "Update Link"
                    : "Create Link"}
                </Button>
                <Button
                  variant="primary"
                  style={{ minWidth: 80 }}
                  onClick={() => {
                    copyLink(
                      "https://app.userevaluation.com/public/" + link?._id
                    );
                  }}
                >
                  Copy
                </Button>
              </div>
            </Modal.Body>
          </Modal>

          {projectData && Object.keys(projectData).length !== 0 ? (
            <React.Fragment>
              <Switch>
                <Route path="/p/:projectId/clips" component={ClipsPage} />
                <Route path="/p/:projectId/chat" component={ProjectChat} />
                <Route path="/p/:projectId/report" component={ProjectReport} />
                <Route path="/p/:projectId/ai-report" component={AIReport} />
                <Route path="/p/:projectId/pptx" component={ProjectPptx} />
                <Route path="/p/:projectId/board" component={ProjectBoard} />
                <Route path="/p/:projectId/tags" component={Tags} />
                <Route
                  path="/p/:projectId/notes/:noteId"
                  component={ProjectNotePage}
                />
                <Route
                  path="/p/:projectId/asset/:assetId/:insightType"
                  component={AssetPage}
                />
                <Route
                  path="/p/:projectId/asset/:assetId"
                  component={AssetPage}
                />
                <Route path="/p/:projectId" component={Overview} />
              </Switch>
            </React.Fragment>
          ) : (
            <ProcessingProject
              isProjectReady={isProjectReady}
              darkMode={darkMode}
            />
          )}
        </React.Fragment>
      )}
    </ProjectPageLayout>
  );
};

const ProcessingProject = ({ isProjectReady, darkMode }) => {
  const _charImg = darkMode ? chartImg : chartImgBlack;
  let messageBlock = <p className="processing-paragraph">Loading project</p>;
  if (isProjectReady) {
    messageBlock = (
      <React.Fragment>
        {" "}
        <img src={_charImg} alt="processing" className="processing-img" />{" "}
        <br />{" "}
        <h1 className="processing-heading">Your report is being processed</h1>{" "}
        <p className="processing-paragraph">
          {" "}
          Our AI is working hard to bring those juicy insights to you.Please
          wait until the process finishes.{" "}
        </p>
      </React.Fragment>
    );
  }
  return (
    <Container className="d-flex align-items-center justify-content-center h-100">
      <div className="text-center">
        <Spinner animation="border" variant="primary" />
        <br />
        <br />
        {messageBlock}
      </div>
    </Container>
  );
};
