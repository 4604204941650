import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import styles from "./index.module.css";
// import insightsSvg from '../../images/insights.svg';
import upgradeDarkImage from "../../images/upgrade-dark.svg";
import upgradeLightImage from "../../images/upgrade-light.svg";
import { FiCheck } from "react-icons/fi";
import { createBlankProjectAction } from "../../store/actions/projectActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const OnboardingCard = ({ onClick }) => {
  const { account } = useSelector((state) => state.auth);
  const { darkMode } = useSelector((state) => state.theme);
  const history = useHistory();

  const createProject = () => {
    if (account?.plan?.subscribed || account?.phone_verified)
      createBlankProjectAction(account.email);
    else
      history.push({
        pathname: "/phone-verify",
      });
  };

  return (
    <div className={styles.card} onClick={onClick}>
      <div>
        <div
          className={`${styles.flexContentColumn} ${styles.contentContainer}`}
          style={{
            background: `${
              darkMode
                ? ""
                : "linear-gradient(133deg, #edf6ff 0%, #eafef9 100%)"
            }`,
          }}
        >
          <div className={styles.contentHead}>
            <div className={styles.flexContentColumn} style={{ width: "80%" }}>
              <Card.Title className={styles.title + " mb-4"}>
                Get started with User Evaluation
              </Card.Title>
              <Card.Subtitle className={styles.subtitle + " mb-4"}>
                User Evaluation allows you to analyze all your customer
                conversations (audio, video, text or CSV data) using AI. You can
                start for free by creating a new project.
              </Card.Subtitle>
              <div className={styles.progressbar}></div>
            </div>
            <img
              style={{ width: "12rem" }}
              src={darkMode ? upgradeDarkImage : upgradeLightImage}
              alt="upgrade"
            ></img>
          </div>
        </div>
        <div className={styles.flexContentRow}>
          <div className={styles.circle}>
            <FiCheck />
          </div>
          <div className={`${styles.flexContentColumn} ${styles.actionText}`}>
            <h3>Create a free account</h3>
            <p>
              Great work! Note that you&apos;re capped at one project with two
              files. Upgrade to add more files and unlock all AI features.
            </p>
          </div>
          <Link to="/plans">
            <Button variant="primary" className={styles.button}>
              Upgrade
            </Button>
          </Link>
        </div>
        <div className={styles.flexContentRow}>
          <div className={styles.circleBorder}></div>
          <div className={`${styles.flexContentColumn} ${styles.actionText}`}>
            <h3>Create a new project</h3>
            <p>
              Kickstart by initiating a new project. User Evaluation organizes
              everything in projects. Each set of customer conversations
              requires its distinct project.
            </p>
          </div>
          <Button
            variant="primary"
            className={styles.button}
            onClick={createProject}
          >
            New Project
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OnboardingCard;
