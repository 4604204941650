import React from "react";
// import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { InsightsTab } from "../components/tabs/insights";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { loadAsset, loadProject } from "../../../store/actions/currentProjectActions";

export default function AssetPage({ match }) {
    const { assetId, projectId, insightType } = match.params;
    const [selectedInsightType, setSelectedInsightTab] = React.useState(insightType || 'Transcript');
    const [selectedTimestamps, setSelectedTimestamps] = React.useState([]);
    const history = useHistory();
    const loadingCurrentProject = useSelector(state => state.currentProject.loading)
    const setSelectedVideo = (assetId) => history.push(`/p/${projectId}/asset/${assetId}`)
    const projectData = useSelector(state => state.currentProject.project);
    const selectedVideo = (projectData?.videos || []).find(vid => vid._id === assetId);

    React.useEffect(() => {
        if (!selectedVideo.transcript && !loadingCurrentProject) {
            loadAsset(assetId);
        }
    }, [selectedVideo, assetId, loadingCurrentProject])

    const runGetProjectData = () => {
        loadProject(projectId).then(() => {
            // loadAsset(assetId)
        })

    }

    return <InsightsTab
        selectedTimestamps={selectedTimestamps}
        setSelectedTimestamps={setSelectedTimestamps}
        selectedVideo={selectedVideo}
        setSelectedVideo={setSelectedVideo}
        projectData={projectData}
        runGetProjectData={runGetProjectData}
        selectedInsightTab={selectedInsightType}
        setSelectedInsightTab={setSelectedInsightTab}
    />
}