import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Modal } from "@mantine/core";
import { useSelector } from "react-redux";
import CsvTable from "../project-vnext/components/tabs/insights/CsvTable";
import { loadAsset } from "../../store/actions/mediaLibraryActions";

import styles from "./previewModal.module.css";

const videoStyle = {
  maxWidth: "700px",
  width: "100%",
  height: "auto",
};

export default function PreviewModal({ asset, onClose }) {
  const name = asset?.name;
  const link = asset?.link;
  const assetId = asset?._id;
  const [updatedAsset, setUpdatedAsset] = useState(asset);
  const assetLoading = useSelector((state) => state.mediaLibrary.assetLoading);
  const [shouldRenderBody, setShouldRenderBody] = useState(false);

  useEffect(() => {
    return () => {
      setUpdatedAsset(null); // Reset the state when the component unmounts
    };
  }, []);
  useEffect(() => {
    async function fetchAssetWithTranscript() {
      if (assetId) {
        try {
          const fetchedAssetData = await loadAsset(assetId);
          setUpdatedAsset(fetchedAssetData);
          setShouldRenderBody(true);
        } catch (error) {
          console.error("Error fetching asset:", error);
        }
      }
    }

    fetchAssetWithTranscript();
  }, [assetId]);
  return (
    <Modal size="lg" opened={!!asset} onClose={onClose} title={name}>
      <div>
        {shouldRenderBody && (
          <>
            {assetLoading ? (
              <div className={styles.spinnerContainer}>
                <Spinner animation="border" variant="primary" />
              </div>
            ) : updatedAsset?.transcript?.length === 0 ? (
              <p>
                This file has not been processed yet. Please add it to a project
                to process the content.
              </p>
            ) : (
              <>
                {["TEXT", "PDF", "DOC", "LIVECHAT"].includes(
                  asset?.assetType
                ) ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        updatedAsset?.transcript &&
                        updatedAsset.transcript[0]?.text.replace(/\n/g, "<br>"),
                    }}
                  ></p>
                ) : (
                  <>
                    {asset?.assetType === "CSV" ? (
                      <CsvTable
                        rawData={updatedAsset?.transcript[0]?.text || ""}
                        hideTagging={true}
                      />
                    ) : (
                      <video style={videoStyle} controls={true} src={link} />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Modal>
  );
}
