import React, { useEffect, useRef, useState } from "react";
import styles from "./addTagBtn.module.css";
import Button from "./button";
import { useSelector } from "react-redux";
import colorsFromJson from "../../../../../utils/colors.json";
import { createTagAction } from "../../../../../store/actions/tagsActions";
let colors = colorsFromJson.colors;
function AddTagBtn({ icon, assetId, hideName }) {
  const { data } = useSelector((state) => state.ribbon);
  const [isOpen, setIsOpen] = useState(false);
  //   const dispatch = useDispatch();
  const { project } = useSelector((state) => state.currentProject);
  const asset = project?.videos?.find((item) => item._id === assetId);
  const projectId = project?._id;
  const isCSV = asset?.assetType === "CSV";
  const isDocument =
    asset?.assetType === "PDF" ||
    asset?.assetType === "DOC" ||
    asset?.assetType === "TEXT";
  return (
    <div className={styles.container}>
      <Button
        tooltipText={!data?.selectionData && "Select text to add a tag"}
        disabled={!data?.selectionData}
        ele={{ name: "Add a Tag", icon: icon }}
        hideName={hideName}
        handleClick={() => {
          setIsOpen(!isOpen);
        }}
      />
      <TagPopUp
        projectId={projectId}
        data={data?.selectionData}
        assetId={assetId}
        isDocument={isDocument}
        isCSV={isCSV}
        selectedUtterance={data?.selectedUtterance}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <span className={styles.toolbar__span}>|</span>
    </div>
  );
}
export function TagPopUp({
  isOpen,
  data,
  selectedUtterance,
  assetId,
  setIsOpen,
  projectId,
  isDocument,
  isCSV,
}) {
  const [tag, setTag] = useState("");
  const { tags } = useSelector((state) => state.tags);
  const [randomColor, setRandomColor] = useState({});
  const tagRef = useRef(null);
  // now from colorsToSelectFrom we will select a random color but do it once
  // so that it does not change on every render
  const colorsToSelectFrom = colors.filter((color) => {
    return !tags.some((tag) => tag?.tagId.color === color?.color);
  });
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (tagRef.current && !tagRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (colorsToSelectFrom.length > 0) {
      const randomColor =
        colorsToSelectFrom[
          Math.floor(Math.random() * colorsToSelectFrom.length)
        ];
      setRandomColor(randomColor);
    }
  }, [tags.length]);

  let allTagsTitle = Array.from(
    tags
      ?.reduce((uniqueMap, tag) => {
        uniqueMap.set(tag?.tagId.title, {
          title: tag?.tagId.title,
          color: tag?.tagId.color,
          highlightColor: tag?.tagId.highlightColor,
        });
        return uniqueMap;
      }, new Map())
      .values()
  );

  let start = isDocument ? data?.start : data && data[0]?.words[0].start;
  let end = isDocument
    ? data?.end
    : data && data[data.length - 1]?.words.at(-1).end;

  let convertedData;
  if (isCSV) {
    convertedData = data?.data;
    start = data?.start;
    end = data?.end;
  } else if (isDocument) {
    convertedData = data?.text;
  } else {
    if (Array.isArray(data)) {
      convertedData = data
        .map((item) => {
          return item?.words
            .map((word) => {
              return word.text;
            })
            .join(" ");
        })
        .join(" ");
    }
  }

  useEffect(() => {
    setTag("");
  }, [isOpen]);
  if (!isOpen) return null;

  const filteredTags = allTagsTitle.filter((item) =>
    item?.title?.toLowerCase()?.includes(tag.toLowerCase())
  );

  return (
    <div className={styles.tagsContainer} ref={tagRef}>
      <input
        type="text"
        value={tag}
        placeholder="Search tags or type to create a new..."
        onChange={(e) => {
          setTag(e.target.value);
        }}
      />
      <div className={styles.tagsShower}>
        <div style={{ width: "100%" }}>
          {
            <>
              {tag !== "" ? (
                filteredTags.length > 0 ? (
                  filteredTags.map((item, index) => {
                    return (
                      <span
                        onClick={() => {
                          createTagAction(
                            item.title,
                            projectId,
                            assetId,
                            start,
                            end,
                            selectedUtterance || 0,
                            convertedData,
                            item?.color,
                            item?.highlightColor
                          );
                          setIsOpen(false);
                        }}
                        key={index}
                        className={styles.tag}
                        style={{
                          background: item?.color,
                          padding: "2px 10px",
                          marginBottom: "12px",
                        }}
                      >
                        {item.title}
                      </span>
                    );
                  })
                ) : (
                  <div
                    style={{ display: "flex" }}
                    onClick={() => {
                      createTagAction(
                        tag,
                        projectId,
                        assetId,
                        start,
                        end,
                        selectedUtterance || 0,
                        convertedData,
                        randomColor?.color,
                        randomColor?.highlightColor
                      );
                      setIsOpen(false);
                    }}
                  >
                    <button className={styles.createBtn}>Create</button>
                    <span
                      className={styles.tag}
                      style={{
                        background: randomColor?.color,
                        padding: "2px 10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {tag}
                    </span>
                  </div>
                )
              ) : (
                allTagsTitle.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={styles.tagsOuter}
                      onClick={() => {
                        createTagAction(
                          item.title,
                          projectId,
                          assetId,
                          start,
                          end,
                          selectedUtterance || 0,
                          convertedData,
                          item?.color,
                          item?.highlightColor
                        );
                        setIsOpen(false);
                      }}
                    >
                      <span
                        className={styles.tag}
                        style={{
                          background: item?.color,
                          padding: "2px 10px",
                          border: "none",
                        }}
                      >
                        {item.title}
                      </span>
                    </div>
                  );
                })
              )}
            </>
          }
        </div>
      </div>
    </div>
  );
}
export default AddTagBtn;
