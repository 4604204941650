import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    account: null,
    loading: null,
    isAuthenticated: false,
    accessToken: null,
    error: null,
    celloToken: null,
    user: null,
    isParticipant: false,
    isAdmin: false,
  },
  reducers: {
    requestStart(state, action) {
      state.loading = true;
      state.error = null;
    },
    setCelloToken(state, action) {
      state.celloToken = action.payload.token;
    },
    getAccount(state, action) {
      state.loading = false;
      state.account = action.payload.account;
      state.isAuthenticated = true;
      state.error = null;
      state.user = action.payload.account;
      state.isParticipant = action.payload.account.role === "participant";
      state.isAdmin = action.payload.account?.roles?.includes("admin") || false;
    },
    updateAccountName(state, action) {
      state.account.name = action.payload;
    },
    requestFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    logout(state) {
      state.account = null;
      state.isAuthenticated = false;
      state.accessToken = null;
      state.error = null;
      state.celloToken = null;
      state.user = null;
      state.isAdmin = false;
    },
    setAccessToken(state, action) {
      state.accessToken = action.payload;
      state.isAuthenticated = !!action.payload;
    },
    requestSuccess(state) {
      state.loading = false;
      state.error = null;
    },
    setIsParticipant(state, action) {
      state.isParticipant = action.payload;
    },
    setPhoneNumber(state, action) {
      state.account.phoneNumber = action.payload;
      state.account.phone_verified = true;
      state.user.phoneNumber = action.payload;
      state.user.phone_verified = true;
    },
  },
});

export default authSlice;
export const authActions = authSlice.actions;
