import { createSlice } from "@reduxjs/toolkit";

const themeSlice = createSlice({
  name: "theme",
  initialState: { darkMode:  false },
  reducers: {
    changeMode(state, action) {
      if (action.payload === "dark")
        state.darkMode = true;
      if (action.payload === "light")
        state.darkMode = false;
    }
  }
});

export default themeSlice;
export const themeActions = themeSlice.actions;
