/* eslint-disable no-unused-vars */
import React from "react";
import { PageLayout } from "../../../../components/page-layout";
import style from "./test-layout.module.css";
import Aside from "./aside";

export const TestLayout = ({ stepNumber, children, testId }) => {
  return (
    <PageLayout>
      <div className={style.parentWrapper}>
        {stepNumber !== 1 && stepNumber !== 7 && (
          <Aside stepNumber={stepNumber} testId={testId} />
        )}
        <div className={style.main}>{children}</div>
      </div>
    </PageLayout>
  );
};
