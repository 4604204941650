import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, ProgressBar } from "react-bootstrap";
import { Modal } from "@mantine/core";
import { BsStars } from "react-icons/bs";
import { BiSolidVideos } from "react-icons/bi";
import {
  FiColumns,
  FiFileText,
  FiMessageSquare,
  FiMusic,
  FiPlus,
} from "react-icons/fi";
import { toastError } from "../../../../utils/toast";
import { modalActions } from "../../../../store/slices/modalSlice";
import { getTagsByProjectId } from "../../../../store/actions/tagsActions";
import useSocketContext from "../../../../utils/SocketContext";
import aiTagsImage from "../../../../images/aitagsImg.svg";
import IconBtn from "../buttons/icon-button";

import styles from "./index.module.css";

function AITagsModal(props) {
  const { project } = useSelector((state) => state.currentProject);
  const [instruction, setInstruction] = useState("");
  const dispatch = useDispatch();
  const { AITag: show } = useSelector((state) => state.modal);
  const [progressPercent, setProgressPercent] = useState(0);
  const [progressMessage, setProgressMessage] = useState("");
  const socketHandlers = useCallback(
    {
      aiTagsUpdate: (payload) => {
        switch (payload.status) {
          case "Error":
            console.error(payload.message);
            toastError(payload.message);
            setProgressPercent(0);
            setProgressMessage("");
            dispatch(modalActions.hideAITagModal());
            break;
          case "Processing":
            setProgressPercent(payload.progress);
            setProgressMessage(payload.message);
            break;
          case "Success":
            getTagsByProjectId(projectId);
            setProgressPercent(0);
            setProgressMessage("");
            dispatch(modalActions.hideAITagModal());
            break;
          default:
            console.warn("Unknown reportUpdate status:", payload.status);
        }
      },
    },
    []
  );
  const projectId = project?._id;
  const [assetId, setAssetId] = useState("");
  const socket = useSocketContext();
  useEffect(() => {
    if (props?.assetId) {
      setAssetId(props?.assetId);
    }
  }, [props?.assetId]);
  useEffect(() => {
    if (socket) {
      socket.attachHandlers(socketHandlers);
    }
    return () => socket.detachHandlers(socketHandlers);
  }, [socket, socketHandlers]);

  return (
    <Modal
      size="xl"
      opened={show}
      onClose={() => {
        dispatch(modalActions.hideAITagModal());
      }}
      title="Create Tags with AI"
    >
      {progressPercent === 0 && progressMessage === "" && (
        <div>
          <div className={styles.instructionContainer}>
            <div className={styles.aiTagImgContainer}>
              <img src={aiTagsImage} alt="aiTagsImage" />
            </div>
            <div className={styles.aiTagTextContainer}>
              <p className="my-2">
                AI tagging is an automated way to add tags to your transcript.
              </p>
              <h4 className="mt-2">Instructions</h4>
              <Form.Control
                className="my-2 p-3"
                as="textarea"
                rows={5}
                placeholder="Type instructions for the AI to generate your tags. Your instructions may also include the exact tags you want included."
                style={{
                  resize: "none",
                }}
                onChange={(e) => {
                  setInstruction(e.target.value);
                }}
                value={instruction}
              />

              <div className={`my-4 ${styles.videoArray}`} >
                <p className=" font-weight-bold ">Select the file to tag</p>
                <div className={styles.assetsContainer}>
                  {project?.videos?.length > 0 &&
                    project?.videos?.map((asset, index) => {
                      return (
                        <div key={index}>
                          <IconBtn
                            isActive={assetId === asset._id}
                            type={asset?.assetType}
                            thumbnail={asset?.thumbnail}
                            icon={
                              iconsForAssetType.find(
                                (icon) => icon.type === asset.assetType
                              ).icon
                            }
                            handleClick={() => setAssetId(asset._id)}
                          />

                          <p className={styles.assetName}>
                            {asset?.name && asset.name.length > 10
                              ? `${asset.name.slice(0, 10)}...`
                              : asset.name}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <button
              className={`btn btn-outline-primary mx-2 ${styles.withoutInstructionBtn}`}
              onClick={() => {
                socket.createAiTags(assetId, projectId, "");
                setInstruction("");
              }}
              disabled={!assetId}
            >
              <BsStars />
              Generate without instructions
            </button>
            <button
              className={`btn btn-primary`}
              onClick={() => {
                socket.createAiTags(assetId, projectId, instruction);
                setInstruction("");
              }}
              disabled={instruction === "" || !assetId}
            >
              Generate Tags
            </button>
          </div>
        </div>
      )}

      {progressPercent !== 0 && (
        <>
          <p className="mt-2">Please wait till the AI generates your tags</p>
          <ProgressBar
            striped
            animated
            variant="info"
            now={progressPercent}
            className="mt-3"
          />
        </>
      )}
    </Modal>
  );
}
const iconsForAssetType = [
  { type: "VIDEO", icon: <BiSolidVideos /> },
  { type: "AUDIO", icon: <FiMusic /> },
  { type: "TEXT", icon: <FiFileText /> },
  { type: "CSV", icon: <FiColumns /> },
  { type: "PDF", icon: <FiFileText /> },
  { type: "DOC", icon: <FiFileText /> },
  { name: "Plus", icon: <FiPlus /> },
  { type: "OTHER", icon: <FiFileText /> },
  { type: "LIVECHAT", icon: <FiMessageSquare /> },
];
export default AITagsModal;
