import { createSlice } from "@reduxjs/toolkit";

const clipSlice = createSlice({
  name: "clips",
  initialState: {
    clips: [],
    loading: true,
    error: null
  },
  reducers: {
    requestStart(state, action) {
      state.loading = true;
      state.error = null;
    },
    getClipsByProjectId(state, action) {
      state.loading = false;
      state.clips = action.payload.clips;
      state.error = null;
    },
    deleteClip(state, action) {
      state.clips = state.clips.filter((clip) => clip._id !== action.payload);
      state.loading = false;
      state.error = null;
    },
    getClipByClipId(state, action) {
      state.clips = state.clips.filter((clip) => clip._id === action.payload);
      state.loading = false;
      state.error = null;
    },
    createClip(state, action) {
      state.clips = [...state.clips, action.payload];
      state.loading = false;
      state.error = null;
    },
    requestFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updateClipLink(state, action) {
      const updatedClip = action.payload;
      state.clips = state.clips.map(clip => {
        if(clip._id === updatedClip._id){
          const { link, updatedAt } = updatedClip;
          return { ...clip, link, updatedAt }
        }
        return clip;
      })
    }
  }
});

export default clipSlice;
export const clipActions = clipSlice.actions;
