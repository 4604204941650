import React from "react";
import { Modal } from "@mantine/core";
import { useSelector } from "react-redux";
import style from "./responses.module.css";
import { QUES_MAP } from "../../../new-test/ai-curated-interview/screen";
import { LuCheck } from "react-icons/lu";

const Responses = ({ participantResponse, onClose }) => {
  const {
    currentTest: { questions },
  } = useSelector((state) => state.test);

  return (
    <Modal
      opened={!!participantResponse}
      onClose={onClose}
      title="Screener Response"
      size="lg"
    >
      {participantResponse &&
        questions?.map((question, index) => (
          <Question
            key={index}
            question={question}
            response={participantResponse[question._id]}
          />
        ))}
    </Modal>
  );
};

export default Responses;

const Question = ({ question, response }) => {
  return (
    <div className={style.item} type={QUES_MAP[question.type]}>
      <div className={style.info}>
        <div className={style.type}>{QUES_MAP[question.type]}</div>
        <div className={style.label}>{question.label}</div>
        {question.options.map((option, index) => (
          <div key={index} className={style.option}>
            {response === option.option ||
              (response instanceof Array && response.includes(option.option)) ? (
              <>
                {option.option}&nbsp;&nbsp;
                <span className={style.selected}>
                  <LuCheck size={18} /> Selected
                </span>
              </>
            ) : (
              option.option
            )}
          </div>
        ))}

        {[
          QUES_MAP["Text Input"],
          QUES_MAP["Number Input"],
          QUES_MAP["Multi-line Input"],
          QUES_MAP.Date,
        ].includes(question.type) && (
            <div className={style.option}>{response}</div>
          )}
      </div>
    </div>
  );
};
