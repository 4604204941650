import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col, ListGroup, Badge } from "react-bootstrap";
import { FiFile, FiArchive, FiZap } from "react-icons/fi";
import { useSelector } from "react-redux";
import { TextInput } from "@mantine/core";
import { PageLayout } from "../../components/page-layout";
import { searchProjectsAction } from "../../store/actions/searchResultsActions";

import style from "./index.module.css";

import AlgoliaLogoBlue from "../../images/Algolia-logo-blue.svg";
import { FiSearch } from "react-icons/fi";

export const SearchPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { results, noResults } = useSelector((state) => state.searchResults);

  const history = useHistory();
  const location = useLocation();

  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const term = searchParams.get("q") || "";
    setSearchTerm(term);

    if (term) {
      searchProjectsAction(term);
    }
  };

  useEffect(() => {
    getQueryParams();
  }, []); // Pass an empty dependency array to run this effect only on page load

  const handleSearch = async () => {
    if (searchTerm) {
      await searchProjectsAction(searchTerm);
      history.push({
        pathname: location.pathname,
        search: `?q=${encodeURIComponent(searchTerm)}`,
      });
    }
  };

  return (
    <PageLayout>
      <div className={style.wrapper}>
        <Container className={style.container}>
          {/* make top row sticky */}
          <Row className="mb-4">
            <Col>
              <div style={{ position: "relative", width: "100%" }}>
                <TextInput
                  placeholder="Search projects, files and insights..."
                  className={style.searchInput}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                  rightSection={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "8px",
                        cursor: "pointer",
                        justifyContent: "end",
                        width: "80px",
                      }}
                      onClick={() => {
                        if (searchTerm) {
                          history.push({
                            pathname: "/search",
                            search: `?q=${encodeURIComponent(searchTerm)}`,
                          });
                        }
                      }}
                    >
                      <FiSearch />
                    </div>
                  }
                  styles={{ input: { paddingRight: "2rem" } }}
                />
              </div>

              {/* Search by Algolia */}
              <a
                href="https://www.algolia.com?utm_source=userevaluation.com"
                target="_blank"
                rel="noreferrer"
              >
                <div className="d-flex align-items-center mt-2">
                  <p className="ml-2 mb-0">Search by </p>
                  <img
                    src={AlgoliaLogoBlue}
                    alt="Algolia"
                    style={{ width: 100, height: 20 }}
                  />
                </div>
              </a>
            </Col>
          </Row>
          <Row>
            <Col>
              <ListGroup className="search-results">
                {results.map((result, index) => (
                  <Link to={result.url} key={index}>
                    <ListGroup.Item
                      className="mb-3"
                      style={{
                        margin: "1rem !important",
                        borderRadius: "8px",
                      }}
                    >
                      <Row>
                        <Col
                          xs={3}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <div
                            style={{
                              width: 200,
                              height: 100,
                              backgroundColor: "var(--darkGray)",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 8,
                            }}
                          >
                            {result.objectType === "project" && (
                              <FiArchive size={20} />
                            )}
                            {result.objectType === "insight" && (
                              <FiZap size={20} />
                            )}
                            {result.objectType === "video" && (
                              <FiFile size={20} />
                            )}
                          </div>
                        </Col>
                        <Col>
                          <h5>
                            <a href={result.url}>{result.title}</a>
                          </h5>
                          <p>{result.description}</p>
                          <div>
                            {result.tags.map((tag, index) => (
                              <Badge
                                key={index}
                                className="mr-2"
                                variant="secondary"
                              >
                                {tag}
                              </Badge>
                            ))}
                          </div>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  </Link>
                ))}
              </ListGroup>
              {/* Empty state */}
              {!results.length && (
                <div className="text-center mt-5">
                  <h5>
                    {noResults
                      ? "Couldn't find any results that match your query"
                      : "No results to display."}
                  </h5>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </PageLayout>
  );
};
