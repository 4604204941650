import store from "..";
import { projectActions } from "../slices/projectSlice";
import {
  callExternalApi,
  callExternalApiWithoutToken,
} from "../../services/external-api.service";
import { toastError, toastSuccess } from "../../utils/toast";
import { modalActions } from "../slices/modalSlice";
import { currentProjectActions } from "../slices/currentProject";
import { mediaLibraryActions } from "../slices/mediaLibrarySlice";
const { dispatch } = store;

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

// load projects
export const loadProjectsAction = async (email) => {
  dispatch(projectActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/projects`,
    method: "GET",
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    dispatch(projectActions.requestFail(error.message));
    toastError("Something went wrong! Please try again later.");
  } else dispatch(projectActions.getProjects({ projects: data }));
};

// delete project
export const deleteProjectAction = async (projectId) => {
  dispatch(projectActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/project/delete/${projectId}`,
    method: "DELETE",
  };
  const { data, error } = await callExternalApi({ config });

  if (error) {
    dispatch(projectActions.requestFail(error.message));
    toastError("Something went wrong! Please try again later.");
  }
  if (data) {
    dispatch(projectActions.deleteProjectSlice({ _id: projectId }));
    toastSuccess("Your project has been deleted");
  }
};

// create blank project
export const createBlankProjectAction = async (
  email,
  redirect = true,
  title = ""
) => {
  dispatch(projectActions.requestStart());
  const config = {
    url: `${apiServerUrl}/api/project/create-blank`,
    method: "POST",
    data: {
      name: title,
      user: email,
    },
  };

  const { data, error } = await callExternalApi({ config });
  if (error) {
    dispatch(projectActions.requestFail(error.message));
    toastError(
      "Something went wrong! Please check your credits or try again later."
    );
    dispatch(modalActions.showPayWallModal(true));
  } else {
    dispatch(projectActions.createBlankProjectSlice(data));

    if (redirect && data._id) {
      // send user to project page
      window.location.href = `/project/${data._id}/upload`;
    }
  }

  return { data, error };
};

// startDate, endDate to liveChat
export const createLiveChatProject = async (startDate, endDate) => {
  dispatch(projectActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/project/livechat`,
    method: "POST",
    data: {
      startDate,
      endDate,
    },
  };

  const { data, error } = await callExternalApi({ config });
  if (error) {
    dispatch(projectActions.requestFail(error.message));
    toastError(
      "Something went wrong! Please check your credits or try again later."
    );
    dispatch(modalActions.showPayWallModal(true));
  } else {
    dispatch(projectActions.createBlankProjectSlice(data));
    // send user to project page
    window.location.href = `/project/${data.project}`;
  }
};

// All the functions that are not related to the store will not have action as a suffix.
export const getProject = async (payload) => {
  dispatch(currentProjectActions.setLoading());
  const config = {
    url: `${apiServerUrl}/api/projects/${payload.id}`,
    method: "GET",
  };
  const { data, error } = await callExternalApi({ config });
  if (data) {
    dispatch(currentProjectActions.setProject({ project: data }));
  }
  return {
    data: data || null,
    error,
  };
};

// All the functions that are not related to the store will not have action as a suffix.
export const getProjectForPublicResource = async (payload) => {
  const config = {
    url: `${apiServerUrl}/api/project/public`,
    method: "POST",
    data: payload,
  };
  const { data, error } = await callExternalApiWithoutToken({ config });
  return {
    data: data || null,
    error,
  };
};

export const getDataFromProject = async (payload) => {
  const config = {
    url: `${apiServerUrl}/api/project/get-data`,
    method: "POST",
    data: payload,
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const updateProjectAtOnce = async (video, projectData) => {
  var newProjectData = projectData;
  newProjectData.videos = [...projectData.videos, video];

  const config = {
    url: `${apiServerUrl}/api/project/update`,
    method: "POST",
    data: newProjectData,
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const updateProjectNameOrDesc = async (payload) => {
  dispatch(projectActions.requestStart());
  const config = {
    url: `${apiServerUrl}/api/project/update`,
    method: "POST",
    data: payload,
  };

  dispatch(projectActions.updateProjectNameOrDescription(payload));

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const regenerateReportSection = async (payload) => {
  const config = {
    url: `${apiServerUrl}/api/project/report/section/regenerate`,
    method: "POST",
    data: payload,
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const regenerateInsight = async (payload) => {
  const config = {
    url: `${apiServerUrl}/api/project/insight/regenerate`,
    method: "POST",
    data: payload,
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const regenerateAllInsights = async (payload) => {
  const config = {
    url: `${apiServerUrl}/api/project/insight/regenerate-all`,
    method: "POST",
    data: payload,
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const addVideoToProject = async (payload) => {
  const config = {
    url: `${apiServerUrl}/api/project/add/video`,
    method: "PUT",
    data: payload,
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const updateInsight = async (
  assetId,
  insightId,
  insight,
  insightType
) => {
  const config = {
    url: `${apiServerUrl}/api/insights/${assetId}`,
    method: "PATCH",
    data: { insightId, insight, insightType },
  };
  const { data, error } = await callExternalApi({ config });
  if (data) {
    dispatch(
      currentProjectActions.updateInsight({
        assetId,
        insightId,
        insightType,
        insight,
      })
    );
  }
  return {
    data: data || null,
    error,
  };
};

export const deleteInsights = async (insightName, videoId) => {
  const config = {
    url: `${apiServerUrl}/api/video/insight/delete`,
    method: "POST",
    data: { insightName, videoId },
  };
  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const deleteInsight = async (insightId, videoId, insightType) => {
  const config = {
    url: `${apiServerUrl}/api/insights/${videoId}`,
    method: "DELETE",
    data: { insightId, insightType },
  };
  const { data, error } = await callExternalApi({ config });
  if (data) {
    toastSuccess("Insight deleted");

    dispatch(
      currentProjectActions.deleteInsight({ videoId, insightId, insightType })
    );
  }
  return {
    data: data || null,
    error,
  };
};

export const addInsight = async (insightType, insight, assetId) => {
  const config = {
    url: `${apiServerUrl}/api/insights/${assetId}/insight`,
    method: "POST",
    data: { insightType, insight },
  };
  const { data, error } = await callExternalApi({ config });
  if (data) {
    toastSuccess("Insight added successfully");
    dispatch(
      currentProjectActions.addInsight({
        assetId,
        insight: data.data.insight,
        insightType: data.data.insightType,
      })
    );
  }
  if (error) {
    toastError("Something went wrong! Please try again later.");
  }
};

export const editVideoName = async (newName, videoId) => {
  const config = {
    url: `${apiServerUrl}/api/video/edit/name`,
    method: "POST",
    data: { newName, videoId },
  };
  const { data, error } = await callExternalApi({ config });
  if (data) {
    dispatch(
      mediaLibraryActions.setAssetTitle({
        assetId: videoId,
        title: newName,
      })
    );
  }
  return {
    data: data || null,
    error,
  };
};

export const deleteVideo = async (videoId) => {
  const config = {
    url: `${apiServerUrl}/api/video/delete`,
    method: "POST",
    data: { videoId },
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

// this one just removes the video from the project video array
export const deleteVideoById = async (videoId, projectId) => {
  const config = {
    url: `${apiServerUrl}/api/project/video/delete`,
    method: "POST",
    data: { videoId, projectId },
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const addNotesToVideo = async (notes, assetId, alreadyAdded) => {
  const config = {
    url: `${apiServerUrl}/api/assets/note/${assetId}`,
    method: "PUT",
    data: { notes },
  };
  const { data, error } = await callExternalApi({ config });
  if (data) {
    if (alreadyAdded) {
      dispatch(currentProjectActions.setAssetNotes({ assetId, notes }));
    } else {
      toastSuccess(data.message);
    }
  }
  return {
    data: data || null,
    error,
  };
};

export const getNotesByAssetId = async (assetId) => {
  const config = {
    url: `${apiServerUrl}/api/assets/note/${assetId}`,
    method: "GET",
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

// save video thumbnail
export const saveVideoThumbnail = async (payload) => {
  const config = {
    url: `${apiServerUrl}/api/video/thumbnail/save`,
    method: "POST",
    data: payload,
  };
  const { data, error } = await callExternalApi({ config });
  if (data) {
    dispatch(
      currentProjectActions.setAssetThumbnail({
        assetId: payload.videoId,
        thumbnail: data.thumbnail,
      })
    );
  }
  return {
    data: data || null,
    error,
  };
};

export const updateSpeakerName = async (assetId, patchdata) => {
  const config = {
    url: `${apiServerUrl}/api/assets/speakers/${assetId}`,
    method: "PATCH",
    data: patchdata,
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const updateTranscript = async (assetId, patchdata) => {
  const config = {
    url: `${apiServerUrl}/api/assets/transcript/${assetId}`,
    method: "PATCH",
    data: patchdata,
  };
  const { data, error } = await callExternalApi({ config });
  if (data) {
    dispatch(
      currentProjectActions.setUtteranceInTranscript({
        assetId,
        utterance: patchdata.sentence,
      })
    );
  }
  return {
    data: data || null,
    error,
  };
};

export const getAssetById = async (assetId) => {
  const config = {
    url: `${apiServerUrl}/api/assets/${assetId}`,
    method: "GET",
  };
  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const handleProjectStatusUpdate = (projectData) => {
  const state = store.getState();
  if (
    projectData.status === "Ready" &&
    state.auth.account._id === projectData.createdBy &&
    state.currentProject.project?._id !== projectData._id
  ) {
    toastSuccess(`Your project '${projectData.name}' is ready.`);
  }
  dispatch(projectActions.updateProject(projectData));
};


export const handleProjectNameUpdate = (projectData) => {
  dispatch(projectActions.updateProject(projectData));
};