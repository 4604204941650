import React from "react";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import AccountsSDK from '@livechat/accounts-sdk';
import { PageLayoutWaitlist } from "../../../components/page-layout-waitlist";
import LiveChatLogo from "../../../images/livechat-logo.png";

const REACT_APP_LIVECHAT_CLIENT_ID = process.env.REACT_APP_LIVECHAT_CLIENT_ID;
const REACT_APP_LIVECHAT_REDIRECT_URI = process.env.REACT_APP_LIVECHAT_REDIRECT_URI;
// const LIVECHAT_AUTH_URL = `https://accounts.livechat.com/authorize?response_type=code&client_id=${REACT_APP_LIVECHAT_CLIENT_ID}&redirect_uri=${REACT_APP_LIVECHAT_REDIRECT_URI}`;

export const AppLiveChatAuth = () => {

// javascript
const instance = new AccountsSDK({
  client_id: REACT_APP_LIVECHAT_CLIENT_ID,
  redirect_uri: REACT_APP_LIVECHAT_REDIRECT_URI,
  response_type: "code",
  pkce: {
    enabled: true
  }
});

const signInWithLiveChat = (e) => {
  if (e && e.preventDefault) {
    e.preventDefault();
  }

  instance.redirect().authorizeData().then((authorizeData) => {
    const transactionData = instance.verify(authorizeData);
    if (transactionData === null) {
      console.error("Failed to verify authorization data");
      return;
    }
    fetch(instance.options.server_url + "/v2/token", {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({
          grant_type: "authorization_code",
          code: authorizeData.code,
          client_id: transactionData.client_id,
          redirect_uri: transactionData.redirect_uri,
          code_verifier: transactionData.code_verifier,
      })
    })
    .then((res) => { res.json()}).then((data) => {console.log("User tokens: " + data)})
    .catch((e) => { console.error("Failed to exchange code: " + e) })
  }).catch(e => {
    // As we don't have any authorization data, then we can try o authorize user
    // const wasRedirected = sessionStorage.getItem('lc_accounts');
    // if (wasRedirected === "yes") {
    //   console.error("Couldn't authorize user: " + e);
    //   return
    // }
    // sessionStorage.setItem('lc_accounts', yes);
    instance.redirect().authorize(); // Initiate authorization redirect flow

  })
};
 
  
  return (
    <PageLayoutWaitlist>
      <Container>
        <Row className="justify-content-center align-items-center mt-5">
          <Col xs={12} sm={6} md={6}>
            <Card className="text-center" style={{ zIndex: 9999, padding: 20 }}>
              <Card.Body>
                <img
                  src={LiveChatLogo}
                  alt="LiveChat Logo"
                  style={{
                    width: "100px",
                    height: "100px",
                    border: "2px solid #fff",
                    borderRadius: "8px",
                    boxShadow: "0 0 10px #ccc",
                    marginBottom: "40px",
                  }}
                />
                <Card.Title>Integrate with LiveChat</Card.Title>
                <Card.Text>
                  User Evaluation&apos;s LiveChat integration allows you to
                  analyze your customer support conversations and improve your
                  customer experience. You will be able to get AI-powered
                  insights, chat with your data, generate reports, and more.
                  <br />
                  <br />
                  Click the button below to authenticate with LiveChat.
                </Card.Text>
                <Button variant="primary" onClick={signInWithLiveChat}>
                  Sign in with LiveChat
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </PageLayoutWaitlist>
  );
};
