import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { Modal } from "@mantine/core";
import { editVideoName } from "../../../../store/actions/projectActions";
import {  toastError } from "../../../../utils/toast";

export default function EditVideoTitleModal({
  show,
  onClose,
  videoId,
  onChange,
  ...props
}) {
  const [title, setTitle] = useState(props.videoTitle);

  useEffect(() => {
    setTitle(props.videoTitle);
  }, [props.videoTitle]);

  const handleSave = async () => {
    const { data, error } = await editVideoName(title, videoId);
    // if asset title is empty - toast error
    if (title === "") {
      toastError("File name cannot be empty");
      return;
    }
    if (data) {
      typeof onChange === "function" && onChange(title);
    } else {
      console.error(error);
    }
    onClose();
  };

  return (
    <Modal size="md" opened={show} onClose={onClose} title="Rename">
      <Form onSubmit={(e) => e.preventDefault()}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>File name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter file name"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSave();
              }
            }}
          />
        </Form.Group>
      </Form>
      <div className="d-flex justify-content-between mt-4">
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </div>
    </Modal>
  );
}
