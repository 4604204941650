import { Button, Menu, UnstyledButton } from "@mantine/core";
import React, { useState } from "react";
import { FiMoreVertical, FiTag } from "react-icons/fi";
import DeleteTagModal from "./bulkDeleteModal";
import { useSelector } from "react-redux";
import styles from "./fileCard.module.css";
const FilesMoreBtn = () => {
  const [open, setOpen] = useState(false);
  const { account } = useSelector((state) => state.auth);
  const organizationId = account.organization;
  return (
    <Menu>
      <DeleteTagModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        organizationId={organizationId}
      />
      <Menu.Target>
        <Button
          variant="outline"
          aria-label="Delete multiple file tags btn"
          style={{
            width: "36px",
            padding: "0",
            fontSize: "1rem",
            color: "var(--pink)",
            borderRadius: "0.5rem",
          }}
        >
          <FiMoreVertical />
        </Button>
      </Menu.Target>
      <Menu.Dropdown
        style={{ background: "var(--grey1)" }}
        className={styles.moreDropdown}
      >
        <Menu.Item className={styles.bulkDeleteBtn}>
          <UnstyledButton
            style={{ display: "flex", gap: "0.5rem" }}
            onClick={() => {
              setOpen(true);
            }}
          >
            <span>
              <FiTag />
            </span>
            <span style={{ fontWeight: "600", color: "var(--grey10)" }}>
              Bulk Delete Tags
            </span>
          </UnstyledButton>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default FilesMoreBtn;
