import { createSlice } from "@reduxjs/toolkit";

function isNodeComplete(node) {
  const hasAllRootKeys = [
    node["id"],
    node["data"],
    node["style"],
    node["position"],
  ].every((attr) => !!attr);
  return hasAllRootKeys;
}

function validateFlowJson(json) {
  if (!Array.isArray(json.nodes)) {
    throw Error("Invalid Flow JSON");
  }
  if (!json.nodes.every((nd) => isNodeComplete(nd))) {
    throw Error("Invalid Flow JSON");
  }
}

/** return partially parsed json, by counting opening brackets and quotes from the start of string and completing the structure. 
edge cases not handled, but still works for our use case
 - no : after property name
 - only key but no value
 @return Object - parsed json object
 @throws SyntaxError - when a edge case is met
**/
function partialJSONParse(dataStr) {
  const stack = [];
  let insideQuotes = false;
  Array.from(dataStr).forEach((ch, idx) => {
    if (ch === '"') {
      insideQuotes = !insideQuotes;
      return;
    }
    if (insideQuotes) {
      return; // ignore [ { b/w quotes
    }
    if (["[", "{"].includes(ch)) {
      stack.push(ch);
    } else if (["}", "]"].includes(ch)) {
      stack.pop();
    }
  });
  if (insideQuotes) {
    stack.push('"');
  }
  const jsonFixingPatch = stack
    .reverse()
    .join("")
    .replace(/\[/g, "]")
    .replace(/\{/g, "}");
  const fixedJson = dataStr + jsonFixingPatch;
  return JSON.parse(fixedJson.replace("```json", "").replace("```", ""));
}

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    messages: [],
    loading: false,
    error: null,
    typing: false, // Add this property
  },
  reducers: {
    requestStart(state, action) {
      state.loading = true;
      state.error = null;
    },
    addTypingMessage(state, action) {
      if (state.typing === false) {
        let messageType = "text";
        if (action.payload.type === "flow") {
          messageType = "Flow";
        } else if (action.payload.type === "graph") {
          messageType = "Graph";
        } else if (action.payload.type === "pie") {
          messageType = "Pie";
        }
        state.messages.push({
          message: "",
          rawMessage: "",
          messageType,
          sender: "AI",
          createdAt: new Date().toString(),
          typing: true,
        });
        state.typing = true;
      }
      const messages = state.messages;
      if (["json", "flow", "graph", "pie"].includes(action.payload.type)) {
        messages[messages.length - 1].rawMessage += action.payload.text;
        try {
          const jsonResponse = partialJSONParse(
            messages[messages.length - 1].rawMessage
          );
          if (action.payload.type === "json") {
            let t = jsonResponse.answers?.map((r) => r.answer || "").join(" ");
            if (!t) return;

            messages[messages.length - 1].message = t;
          } else if (action.payload.type === "flow") {
            validateFlowJson(jsonResponse);
            messages[messages.length - 1].metadata = jsonResponse;
          } else if (action.payload.type === "graph") {
            messages[messages.length - 1].metadata = jsonResponse;
          } else if (action.payload.type === "pie") {
            messages[messages.length - 1].metadata = jsonResponse;
          }
        } catch (err) {
          // acceptable edge cases
        }
      } else {
        messages[messages.length - 1].message += action.payload.text;
      }
    },
    requestFinish(state, action) {
      state.loading = false;
      state.error = null;
    },
    toggleTypingIndicator(state, action) {
      state.typing = action.payload;
    },
    getMessages(state, action) {
      state.loading = false;
      state.messages = action.payload.messages;
      state.error = null;
    },
    deleteMessagesByProjectId(state, action) {
      state.messages = [];
      state.loading = false;
      state.error = null;
    },
    sendMessage(state, action) {
      if (state.typing === true) {
        state.messages = [...state.messages.slice(0, -1), action.payload];
      } else {
        state.messages = [...state.messages, action.payload];
      }
      state.typing = false;
      state.error = null;
    },
    updateMessage(state, action) {
      const messageIndex = state.messages.findIndex(
        (message) => message.id === action.payload.id
      );
      if (messageIndex !== -1) {
        state.messages[messageIndex] = action.payload;
      }
      state.loading = false;
      state.error = null;
    },
    deleteMessage(state, action) {
      state.messages = state.messages.filter(
        (message) => message.id !== action.payload.id
      );
      state.loading = false;
      state.error = null;
    },
    setMessages(state, action) {
      state.messages = action.payload;
      state.loading = false;
      state.error = null;
    },
    requestFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default chatSlice;
export const chatActions = chatSlice.actions;
