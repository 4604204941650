import React from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import styles from "./index.module.css";
function ToggleBtn({ subName, handleChange }) {
  const { data } = useSelector((state) => state.ribbon);
  let mapToggle = {
    "Player": data?.videoPlayer !== undefined ? data.videoPlayer : true,
    "Files list": data?.filesList !== undefined ? data.filesList : true,
    "Sources": data?.sources !== undefined ? data.sources : true,
  };

  let value = mapToggle[subName];
  return (
    <div className={styles.toggle}>
      {subName}
      <Form.Check
        type="switch"
        label=""
        checked={value}
        onChange={() => handleChange(subName)}
      />
    </div>
  );
}

export default ToggleBtn;
