import React from 'react';
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import Confetti from 'react-confetti';
import { useHistory } from 'react-router-dom';
import { PageLayout } from '../../components/page-layout';

export const PaymentSuccess = () => {
  const history = useHistory();

  const handleButtonClick = () => {
    history.push('/projects');
  };

  return (
    <PageLayout>
    <Container>
      <Confetti />
      <Row className="justify-content-center align-items-center mt-5">
        <Col xs={8} sm={6} md={6}>
          <Card className="text-center" style={{ zIndex: 9999, padding: 20 }}>
            <Card.Header as="h4">Congratulations!</Card.Header>
            <Card.Body>
              <Card.Text>
                You have successfully subscribed to User Evaluation and can now use it to its full potential.
              </Card.Text>
              <Card.Text>
                Head over to the Projects page to create your first project.
              </Card.Text>
              <Button variant="primary" onClick={handleButtonClick}>
                Go to Projects
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    </PageLayout>
  );
};