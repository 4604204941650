import React from 'react'
import './prompts.css'
function Prompts({prompt,projectData,handleSendMessage,show,setShow}) {

  return (
    <div className='suggestions' onClick={()=>{
        setShow((show)=>!setShow);
        return handleSendMessage(prompt)}}>
        {prompt}
    </div>
  )
}

export default Prompts