import { Input } from "@mantine/core";
import React from "react";
import { FiSearch } from "react-icons/fi";
import styles from "./addFile.module.css";
const ControlledInput = ({ onUpdate }) => {
  const [value, setValue] = React.useState("");
  const handleChange = (e) => {
    setValue(e.target.value);
    onUpdate(e.target.value);
  };
  return (
    <Input
      className={styles.searchInput}
      placeholder="Search files..."
      value={value}
      onChange={(event) => handleChange(event)}
      rightSection={
        <FiSearch aria-label="Search Value" style={{ color: "var(--grey8)" }} />
      }
    />
  );
};

export default ControlledInput;
