import React from "react";
import style from "./style.module.css";
import { Topbar } from "./components/topbar";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Container, Form } from "react-bootstrap";
import { toastError } from "../../../utils/toast";
import { joinTest } from "../../../store/actions/testActions";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const SignDocument = () => {
  const params = new URLSearchParams(window.location.search);
  const testId = params.get("tId");
  const { currentTest } = useSelector((state) => state.test);
  const history = useHistory();
  const ndaLink = currentTest?.ndaLink;

  const [name, setName] = React.useState("");
  const [disableSign, setDisableSign] = React.useState(false);
  const [expanded, setExpanded] = React.useState(true);

  React.useEffect(() => {
    if (!ndaLink) return history.push(`/participant/welcome?tId=${testId}`);
  }, [ndaLink, history, testId]);

  const signDocument = async () => {
    if (!name.trim()) return toastError("Please enter your full name");

    setDisableSign(true);
    const { error } = await joinTest(testId);

    if (!error) return history.push(`/participant/redirect?tId=${testId}`);

    setDisableSign(false);
  };

  return (
    <div className={style.wrapper}>
      <Topbar />
      <Container className={style["iframe-container"]}>
        <iframe title="NDA" src={ndaLink} width="100%" height="100%"></iframe>
      </Container>

      <div
        className={`${style["sign-container"]} ${
          expanded ? style["expanded"] : ""
        }`}
      >
        <button
          onClick={() => setExpanded(!expanded)}
          className={style["pdf-expand-button"]}
        >
          {expanded ? <IoIosArrowDown /> : <IoIosArrowUp />}
        </button>
        <div>
          <p className="mb-2">
            To e-sign this document, please type your full name below as it
            appears in your ID card.
          </p>

          <p className="mb-1">
            <strong>Full name</strong>
          </p>

          <Form.Control
            name="name"
            type="text"
            placeholder="To sign this document, type your full name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <p className="d-flex gap-3 mt-2 mb-0">
            <Button
              variant="primary"
              type="submit"
              disabled={disableSign}
              onClick={signDocument}
            >
              Sign and Continue
            </Button>

            <Button
              variant="outline-secondary"
              onClick={() =>
                history.push(`/participant/start-test?tId=${testId}`)
              }
            >
              Back
            </Button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignDocument;
