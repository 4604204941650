import React, { useRef, useState } from "react";
import styles from "./index.module.css";
import CRPSidebar from "../components/CRPsidebar";
import { useLocation } from "react-router-dom";
import { ChatSocketTab } from "../components/chatSocket";
import { useDispatch, useSelector } from "react-redux";
import AIReport from "../components/aiReport";
import Ribbon from "../components/toolbar";
import { modalActions } from "../../../store/slices/modalSlice";
import ProjectPptx from "../components/presentations/pptx";
import ShareReportModal from "../components/modals/shareReportModal";
import { CRPLoadingState } from "../components/ProcessingStates";
import { Button, Col, Row } from "react-bootstrap";
import useScreenSize from "../utils/getWindowSizehook";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const mapActiveTabToTitle = {
  chat: "Chat",
  report: "Report",
  presentation: "Presentation",
};
function CRPTemplate({ activeTab, isProcessing }) {
  const { account } = useSelector((state) => state.auth);
  const { project } = useSelector((state) => state.currentProject);
  const [openChatHistory, setOpenChatHistory] = useState(false);
  const resizerX = useRef(null);
  const screenSize = useScreenSize();
  const isSmallScreen = screenSize?.width < 768;
  const { search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(search);
  const dispatch = useDispatch();
  const handleMouseDownX = () => {
    document.addEventListener("mousemove", handleMouseMoveX);
    document.addEventListener("mouseup", handleMouseUpX);
  };

  const handleMouseMoveX = (e) => {
    //logic remains the same but now referring to the resizer
    e.preventDefault();
    const clientX = e.clientX;
    const deltaX = clientX - (resizerX.current.clientX || clientX);
    resizerX.current.clientX = clientX;
    const l = resizerX.current.previousElementSibling;
    const r = resizerX.current.nextElementSibling;
    // LEFT
    if (deltaX < 0) {
      const w = Math.round(parseInt(getComputedStyle(l).width) + deltaX);
      l.style.flex = `0 ${w < 200 ? 200 : w}px`;
      r.style.flex = "1 0";
    }
    // RIGHT
    if (deltaX > 0) {
      const w = Math.round(parseInt(getComputedStyle(r).width) - deltaX);
      r.style.flex = `0 ${w < 200 ? 200 : w}px`;
      l.style.flex = "1 0";
    }
  };

  const handleMouseUpX = (e) => {
    e.preventDefault();
    document.removeEventListener("mousemove", handleMouseMoveX);
    document.removeEventListener("mouseup", handleMouseUpX);
    resizerX.current.clientX = null;
  };

  // touch might have problems , check it out

  const handleTouchStartX = () => {
    document.addEventListener("touchmove", handleTouchMoveX);
    document.addEventListener("touchend", handleTouchEndX);
  };

  const handleTouchMoveX = (e) => {
    e.preventDefault();
    const clientX = e.touches[0].clientX;
    const deltaX = clientX - (resizerX.current.clientX || clientX);
    resizerX.current.clientX = clientX;
    const l = resizerX.current.previousElementSibling;
    const r = resizerX.current.nextElementSibling;
    // LEFT
    if (deltaX < 0) {
      const w = Math.round(parseInt(getComputedStyle(l).width) + deltaX);
      l.style.flex = `0 ${w < 200 ? 200 : w}px`;
      r.style.flex = "1 0";
    }
    // RIGHT
    if (deltaX > 0) {
      const w = Math.round(parseInt(getComputedStyle(r).width) - deltaX);
      r.style.flex = `0 ${w < 200 ? 200 : w}px`;
      l.style.flex = "1 0";
    }
  };

  const handleTouchEndX = (e) => {
    e.preventDefault();
    document.removeEventListener("touchmove", handleTouchMoveX);
    document.removeEventListener("touchend", handleTouchEndX);
    resizerX.current.clientX = null;
  };

  return (
    <div>
      {activeTab !== "chat" && (
        <Ribbon type={activeTab} activeTab={activeTab} />
      )}
      {activeTab && (
        <Row className={styles.mobileThreadsStrip}>
          <Col>
            <Button
              variant="outline-primary"
              onClick={() => {
                setOpenChatHistory(false);
                if (activeTab === "chat") {
                  history.push(`/project/${project?._id}/${activeTab}`);
                }
                if (activeTab === "presentation") {
                  return dispatch(modalActions.showGenPresentationModal());
                } else if (activeTab === "report") {
                  return dispatch(modalActions.showGenReportModal());
                }
              }}
            >
              New {mapActiveTabToTitle[activeTab]}
            </Button>
          </Col>
          <Col className="text-end">
            <Button
              className={styles.chatHistory}
              onClick={() => setOpenChatHistory(!openChatHistory)}
            >
              {mapActiveTabToTitle[activeTab]} History
            </Button>
          </Col>
        </Row>
      )}

      {isSmallScreen ? (
        <>
          {openChatHistory ? (
            <div className={styles.historyMobileView}>
              <CRPSidebar
                isSmallScreen={isSmallScreen}
                onLinkClick={() => setOpenChatHistory(false)}
                tab={activeTab}
                handleOpen={() => {
                  if (activeTab === "presentation") {
                    return dispatch(modalActions.showGenPresentationModal());
                  } else if (activeTab === "report") {
                    return dispatch(modalActions.showGenReportModal());
                  }
                }}
              />
            </div>
          ) : (
            <>
              {activeTab === "chat" && (
                <ChatSocketTab
                  userAccount={account}
                  threadId={params.get("t")}
                />
              )}
              {activeTab === "report" && (
                <div className={styles.reportArea}>
                  <ShareReportModal />
                  <AIReport />
                </div>
              )}
              {activeTab === "presentation" && project?.presentations && (
                <ProjectPptx />
              )}
            </>
          )}
        </>
      ) : (
        <div
          className={`${styles.chatArea} ${
            activeTab === "chat" ? styles.marginTop : ""
          }`}
        >
          {isProcessing ? (
            <CRPLoadingState type={activeTab} />
          ) : (
            <>
              <div
                className={`${openChatHistory ? styles.mobChatHistory : ""} ${
                  styles.chatArea__main
                }`}
                style={{ borderRight: "1px solid var(--borderSecondary)" }}
              >
                <div className={styles.chatArea__main_sidebar}>
                  <CRPSidebar
                    onLinkClick={() => setOpenChatHistory(false)}
                    tab={activeTab}
                    handleOpen={() => {
                      if (activeTab === "presentation") {
                        return dispatch(
                          modalActions.showGenPresentationModal()
                        );
                      } else if (activeTab === "report") {
                        return dispatch(modalActions.showGenReportModal());
                      }
                    }}
                  />
                </div>
                <div
                  className={styles.resizer}
                  ref={resizerX}
                  onMouseDown={handleMouseDownX}
                  onTouchStart={handleTouchStartX}
                ></div>
                <div
                  className={
                    styles.chatArea_main_section +
                    " " +
                    (activeTab === "chat" ? styles.chat : "")
                  }
                >
                  {activeTab === "chat" && (
                    <ChatSocketTab
                      userAccount={account}
                      threadId={params.get("t")}
                    />
                  )}
                  {activeTab === "report" && (
                    <div className={styles.reportArea}>
                      <ShareReportModal />
                      <AIReport />
                    </div>
                  )}
                  {activeTab === "presentation" && project?.presentations && (
                    <ProjectPptx />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default CRPTemplate;
