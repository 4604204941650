import React from "react";
import { Spinner } from "react-bootstrap";
import { Button } from "@mantine/core";
import truncateString from "../../../../utils/truncateString";
import { FiTrash } from "react-icons/fi";
import { TbNotes } from "react-icons/tb";
import { LuFileVideo } from "react-icons/lu";
import EditAssetNotesModal from "../contentArea/editAssetNotesModal";
import styles from "./index.module.css";
const RenderFiles = ({ files, setFiles }) => {
  const [showEditNotes, setShowEditNotes] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const deleteFile = (file) => {
    // remove the file from the files state
    const index = files.findIndex(
      (fileData) => fileData.file.name === file.file.name
    );
    if (index !== -1) {
      files.splice(index, 1);
      setFiles([...files]);
    }
  };
  const addNotes = (file, notes) => {
    const index = files.findIndex(
      (fileData) => fileData.file.id === file.file.id
    );
    if (index !== -1) {
      files[index].notes = notes;
      setFiles([...files]);
    }
  };

  if (!files) {
    return null;
  }

  return (
    <>
      <EditAssetNotesModal
        onClose={() => setShowEditNotes(false)}
        opened={showEditNotes}
        alreadyAdded={false}
        file={selectedFile}
        addNotesRender={addNotes}
        name={selectedFile?.file.name}
      />
      {files?.length > 0 && (
        <div >
          {files.map((file, index) => {
            return (
              <div key={index} className="file-container mt-4">
                <div className="file-icon">
                  {/* display spinner if status is uploading or waiting */}
                  {file.status === "Uploading..." ||
                  file.status === "Waiting..." ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <div className="uploaded-file-new">
                      <LuFileVideo />
                    </div>
                  )}
                </div>
                <div className="file-details">
                  <div className="file-name-new">
                    {truncateString(file.file.name, 60)}
                  </div>
                  <div className="file-size-new">
                    {/* in mbs upto 2 decimal places*/}
                    {Math.round((file.file.size / 1000000) * 100) / 100} MB
                  </div>
                </div>
                <div
                  className={styles.fileActionsStyles}
                  style={{
                    marginLeft: "auto",
                    display: "flex",
                    gap: "0.25rem",
                  }}
                >
                  <Button
                    variant="default"
                    onClick={() => {
                      setShowEditNotes(true);
                      setSelectedFile(file);
                    }}
                  >
                    <TbNotes />
                    &nbsp;{file.notes ? "Edit" : "Add"} Notes
                  </Button>
                  <Button variant="default" onClick={() => deleteFile(file)}>
                    <FiTrash style={{ color: "red" }} />
                    &nbsp; Delete
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
export default RenderFiles;
