import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux"
import { Button, Modal } from "react-bootstrap"
import { BsCameraVideo, BsCheckCircleFill } from 'react-icons/bs'
import { toast } from 'react-toastify';
import { loadZoomRecordings, importRecordings } from '../../store/actions/mediaLibraryActions'
import styles from './ImportModal.module.css'
import { toastPromise } from '../../utils/toast';


const AssetCard = ({ duration, title, createdAt, selected, id, onClick }) => {
    const selectedIcon = selected && <BsCheckCircleFill className={styles.assetSelectedTick} />
    const clickHandler = () => onClick(id)
    let assetWrapperStyle = [styles.assetWrapper, selected&&styles.assetSelected].join(' ')
    
    return <div className={assetWrapperStyle} onClick={clickHandler}>
        <div className={styles.assetDurationRow}>
            <div className={styles.assetTime}><BsCameraVideo className={styles.assetCameraIcon} /> {duration}</div>
            <div>{selectedIcon}</div>
        </div>
        <div className={styles.assetTitle}>{title}</div>
        <div>{createdAt}</div>
    </div>
}

function calculateDuration(recordingFile) {
    const delta = (new Date(recordingFile.recording_end)) - (new Date(recordingFile.recording_start))
    const padStart = (num) => num.toString().padStart(2, '0')

    // Calculate the difference in hours, minutes, and seconds
    const hours = padStart(Math.floor(delta / (1000 * 60 * 60)));
    const minutes = padStart(Math.floor((delta % (1000 * 60 * 60)) / (1000 * 60)));
    const seconds = padStart(Math.floor((delta % (1000 * 60)) / 1000));
    return `${hours}:${minutes}:${seconds}`
}

function transformZoomRecordingData(data) {
    const { topic: title, uuid: id } = data;
    const createdAt = new Date(data.start_time)
        .toLocaleDateString(
            'en-US',
            { day: 'numeric', month: 'short', year: 'numeric' }
        ).replace(',', '');
    const recordingFile = data.recording_files[0]
    // @todo construct total recording time by checking all the overlaping time
    let duration = calculateDuration(recordingFile)
    return { title, createdAt, id, duration }
}

const ImportModal = ({ show, onClose }) => {
    /** Selected Assets */
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        if(show) { // only load when Modal is diplayed
            loadZoomRecordings()
        }
    }, [show])
    function selectHandler(id) {
        if (selected.includes(id)) {
            setSelected(selected.filter(x => x !== id))
        } else {
            setSelected([...selected, id])
        }
    }
    const importButtonDisabled = selected.length === 0
    const importButtonLabel = importButtonDisabled?'Import File':`Import ${selected.length} File(s)`

    const zoomData = useSelector(state => state.mediaLibrary.zoom)
    const assetCards = zoomData.meetings
        .map(transformZoomRecordingData)
        .map(m => <AssetCard {...m} key={m.id} selected={selected.includes(m.id)} onClick={selectHandler} />)

    const handleImport = async () => {
        toastPromise(
            importRecordings(selected),
            {
                pending: "Importing...",
                success: "Imported files",
                error: "Oops! Import failed. Contact support to get help."
            },
            {
                position: toast.POSITION.TOP_CENTER,
            }
        )
        onClose();
        // setSelected([]); // clear state
    }


    return <Modal dialogClassName={styles.modalContent} className={styles.modal} show={show} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Import from Zoom</Modal.Title>
        </Modal.Header>
        <Modal.Body>{assetCards}</Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>
                Cancel
            </Button>
            <Button disabled={importButtonDisabled} variant="primary" onClick={handleImport}>
                {importButtonLabel}
            </Button>
        </Modal.Footer>
    </Modal>
}

export default ImportModal