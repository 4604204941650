import { Text } from "@mantine/core";
import React from "react";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { signin } from "../../../../store/actions/authActions";
import { toastError } from "../../../../utils/toast";
import css from "./auth.module.css";

const Login = ({ toggleScreen, testId }) => {
  const history = useHistory();

  const handleLogin = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const values = Object.fromEntries(formData.entries());

    // no need to send testId here as frontend will
    // automatically redirect normal users to / projects
    const { data, error } = await signin(values.email, values.password, true);

    if (error)
      return toastError(
        error?.message ||
          "The credentials you entered are incorrect. Please try again."
      );

    if (data) {
      history.push(`/participant/start-test?tId=${testId}`);
    }
  };

  return (
    <>
      <Text size="xl" weight={700}>
        Login to your account
      </Text>
      <Text size="md" className="mb-4">
        Don&apos;t have an account?&nbsp;
        <button onClick={toggleScreen} className={css.btn}>
          Create one for free
        </button>
      </Text>

      <Form onSubmit={handleLogin}>
        <Form.Control
          type="email"
          placeholder="Email address"
          required
          name="email"
          className="mb-3"
        />
        <Form.Control
          type="password"
          placeholder="Password"
          required
          name="password"
          className="mb-3"
        />

        <Text size="md" align="right" className="mb-3">
          <a href="/forgot-password" target="_blank">
            Forgot password?
          </a>
        </Text>

        <Button variant="primary" type="submit" className="w-100">
          Login
        </Button>
      </Form>
    </>
  );
};

export default Login;
