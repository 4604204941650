import React, { useEffect, useRef } from "react";
import TopNavBtn, { AddInsightBtn } from "./topnav-button";
import styles from "../../index.module.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styles2 from "./index.module.css";
import { useSelector, useDispatch } from "react-redux";
import { modalActions } from "../../../../store/slices/modalSlice";
function TopNav({
  activeTab,
  tabs,
  projectId,
  type,
  assetId,
  toggle,
  id,
  setActiveTab,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const underLineRef = useRef(null);

  const isInsight = type === "insights" ? true : false;
  const isFile= type === "files" ? true : false;
  const { isPublic } = useSelector((state) => state.currentProject);

  const parentRef = useRef(null);
  const handleHoverAnimation = (e) => {
   
    const item = e.target;
    
    const underline = underLineRef.current;
    underline.style.width = item.offsetWidth + "px";
    underline.style.left = item.offsetLeft + "px";
    // make the opacity of non hovered items 0.25
    const btns = parentRef.current.children;
   
    for (let i = 0; i < btns.length; i++) {
      if (btns[i] !== item) {
        if (btns[i].classList.contains("underline")) continue;
        btns[i].style.opacity = "0.9";
      } else {
        btns[i].style.opacity = "1";
      }
    }
  };
  useEffect(() => {
    const underline = underLineRef.current;
    const at = parentRef.current.querySelector(".active");
    if (at) {
      underline.style.width = at?.offsetWidth + "px";
      underline.style.left = at?.offsetLeft + "px";
      at.style.opacity = "1";
      const btns = parentRef.current.children;
      for (let i = 0; i < btns.length; i++) {
        if (btns[i] !== at) {
          if (btns[i].classList.contains("underline")) continue;
          btns[i].style.opacity = "0.8";
        }
      }
    }
  }, [activeTab]);

  useEffect(() => {
    // if mousemoved outside the parent container
    // move the underline to the active tab
    const parent = parentRef.current;
    parent.addEventListener("mouseleave", () => {
      const underline = underLineRef.current;
      const at = parent.querySelector(".active");
   
      if (at) {
        underline.style.width = at?.offsetWidth + "px";
        underline.style.left = at?.offsetLeft + "px";
        at.style.opacity = "1";
        const btns = parent.children;
        for (let i = 0; i < btns.length; i++) {
          if (btns[i].classList.contains("underline")) continue;
          if (btns[i] !== at) {
            btns[i].style.opacity = "0.8";
          }
        }
      }
    });
  }, [parentRef]);

  return (
    <div
      ref={parentRef}
      className={`${styles.topNavContainer} ${
        isInsight ? "" : styles2.topNavContainer2
      } ${isFile?styles2.rowTopBar:""}`}
      style={{ position: "relative" }}
    >
      {tabs?.map((tab, i) => {
        let name = tab?.name;
        let value = tab?.value;
        let removedSpaceActiveTab = activeTab?.replace(/\s/g, "");

        return (
          <TopNavBtn
            key={i}
            name={name}
            index={i}
            isActive={value === activeTab || value === removedSpaceActiveTab}
            handleHoverAnimation={handleHoverAnimation}
            handleClick={(e) => {
              handleHoverAnimation(e);
              if (type === "files") {
                history.push(`/project/${projectId}/${value}`);
                setActiveTab(value);
                return;
              }

              if (type === "insights") {
                if (isPublic) {
                  setActiveTab(value);
                  return;
                }

                history.push(
                  `/project/${projectId}/${type}/${assetId}/${value}`
                );
              } else {
                if (assetId && (value === "data" || value === "insights")) {
                  history.push(`/project/${projectId}/${value}/${assetId}`);
                } else {
                  history.push(`/project/${projectId}/${value}`);
                }
                if (id && value) {
                  history.push(`/public/${id}/${value}`);
                }
                if (isPublic) {
                  setActiveTab(value);
                }

                if (toggle) {
                  toggle();
                }
              }
            }}
          />
        );
      })}
      {isInsight && !isPublic ? (
        <AddInsightBtn
          handleClick={() => {
            dispatch(modalActions.showAIInsightsModal());
          }}
        />
      ) : (
        <></>
      )}
      <div
        className={`${styles2.underline} underline`}
        ref={underLineRef}
      ></div>
    </div>
  );
}

export default TopNav;
