import dayjs from "dayjs";

function calcDuration(start_date, end_date) {
  const parsedStartDate = dayjs(start_date);
  const parsedEndDate = end_date ? dayjs(end_date) : dayjs(); // Use current date if end_date is not provided

  const duration = parsedEndDate.diff(parsedStartDate, "month"); // Calculate difference in months
  const years = Math.floor(duration / 12);
  const months = duration % 12;

  if (years === 0 && months === 0) {
    return "Less than a month";
  } else if (years === 0) {
    return `${months}mo${months > 1 ? "s" : ""}`;
  } else if (months === 0) {
    return `${years}yr${years > 1 ? "s" : ""}`;
  }

  return `${years}yr${years > 1 ? "s" : ""} ${months}mo${
    months > 1 ? "s" : ""
  }`;
}

export default calcDuration;
