import React from "react";
import { Route, useHistory } from "react-router-dom";
import { Tests } from "../../tests/index.js";
import LaunchTest from "../launch-test/index.js";

import useSocketContext from "../../../utils/SocketContext.js";
import store from "../../../store/index.js";
import { testActions } from "../../../store/slices/testSlice.js";
import ACIRoutes from "./ai-curated-interview.js";
// import ACSRoutes from "./ai-curated-survey.js";
// import AGSRoutes from "./ai-generated-survey.js";
import { getTestById } from "../../../store/actions/testActions.js";
import { matchPath } from "react-router-dom/cjs/react-router-dom.min.js";
import { toastError } from "../../../utils/toast.js";
import { handleStep } from "../ai-curated-interview/components/bottom-bar.js";
import { handleStepACS } from "../ai-curated-survey/components/bottom-bar.js";
import { handleStepAGS } from "../ai-generated-survey/components/bottom-bar.js";
// import { handleStepAGS } from "../ai-generated-survey/components/bottom-bar.js";
const { dispatch } = store;

export const EVENT_TYPES = {
  CREATE_TEST: "createTest",
  PARTICIPANTS_FILTER: "participantsFilter",
  CREATE_UPDATE_QUESTION: "createUpdateQuestion",
  CHANGE_QUESTION_ORDER: "changeQuestionsOrder",
  ADD_OPTION: "addOption",
  DELETE_QUESTION: "deleteQuestion",
  GENERATE_SCRIPT: "generateScript",
  UPDATE_SCRIPT: "updateScript",
  ADDITIONAL_DETAILS: "additionalDetails",
  SKIP_SCREENER: "skipScreener",
};

export const EMIT_TYPES = {
  ERROR: "error",
  CREATE_TEST: "createTestUpdate",
  PARTICIPANTS_FILTER: "participantsFilterUpdate",
  CREATE_UPDATE_QUESTION: "createUpdateQuestionUpdate",
  CHANGE_QUESTION_ORDER: "changeQuestionsOrderUpdate",
  ADD_OPTION: "addOptionUpdate",
  DELETE_QUESTION: "deleteQuestionUpdate",
  GENERATE_SCRIPT: "generateScriptUpdate",
  UPDATE_SCRIPT: "updateScriptUpdate",
  ADDITIONAL_DETAILS: "additionalDetailsUpdate",
  SKIP_SCREENER: "skipScreenerUpdate",
};

export const TEST_TYPES = {
  "ai-curated-interview": "AI-Curated Interview",
  "ai-curated-survey": "AI-Curated Survey",
  "ai-generated-survey": "AI-Generated Survey",
};

const TestsRoute = (props) => {
  const history = useHistory();

  const match = matchPath(props.location.pathname, {
    path: "/interviews/:testType/:id",
  });

  const testSocketHandlers = {
    [EMIT_TYPES.ERROR]: ({ message }) => {
      const msgs = message.split(":");
      toastError(msgs[msgs.length - 1]);
    },
    [EMIT_TYPES.CREATE_TEST]: ({ data, message }) => {
      const { type, _id } = data;
      history.push(`/interviews/${type}/${_id}/select-filter`);
      dispatch(testActions.updateCurrentTest(data));
    },
    [EMIT_TYPES.PARTICIPANTS_FILTER]: ({ data, message }) => {
      dispatch(testActions.updateCurrentTest(data));
      history.push(handleStep(4, match.params.id));
    },
    [EMIT_TYPES.CREATE_UPDATE_QUESTION]: ({ data, message }) => {
      dispatch(testActions.updateCurrentTest(data || []));
    },
    [EMIT_TYPES.CHANGE_QUESTION_ORDER]: ({ data, message }) => {},
    [EMIT_TYPES.DELETE_QUESTION]: ({ data, message }) => {
      dispatch(testActions.updateCurrentTest(data || []));
    },
    [EMIT_TYPES.GENERATE_SCRIPT]: ({ data, message }) => {
      dispatch(testActions.updateCurrentTest(data));
    },
    [EMIT_TYPES.UPDATE_SCRIPT]: ({ data, message }) => {
      const type = store.getState().test.currentTest.type;

      switch (type) {
        case "ai-curated-interview":
          history.push(handleStep(6, match.params.id));
          break;

        case "ai-curated-survey":
          history.push(handleStepACS(5, match.params.id));
          break;

        default:
          break;
      }

      dispatch(testActions.updateCurrentTest(data));
    },
    [EMIT_TYPES.ADDITIONAL_DETAILS]: ({ data, message }) => {
      dispatch(testActions.updateCurrentTest(data));

      const type = store.getState().test.currentTest.type;

      switch (type) {
        case "ai-curated-interview":
          history.push(handleStep(7, match.params.id));
          break;

        case "ai-curated-survey":
          history.push(handleStepACS(6, match.params.id));
          break;

        case "ai-generated-survey":
          history.push(handleStepAGS(6, match.params.id));
          break;

        default:
          break;
      }
    },
    [EMIT_TYPES.SKIP_SCREENER]: ({ data, message }) => {
      dispatch(testActions.updateCurrentTest(data));
      history.push(handleStep(5, match.params.id));
    },
  };

  const socket = useSocketContext();

  React.useEffect(() => {
    if (match?.params?.id && match?.params?.id !== "new") {
      getTestById(match.params.id);
    }

    if (socket) {
      socket.attachHandlers(testSocketHandlers);
    }
    return () => socket.detachHandlers(testSocketHandlers);
  }, [socket, match?.params?.id]);

  return (
    <>
      <Route path="/interviews/ai-curated-interview" component={ACIRoutes} />
      <Route path="/interviews/new" component={LaunchTest} />
      <Route exact path="/interviews/" component={Tests} />
      {/* <Route path="/tests/ai-curated-survey" component={ACSRoutes} />
      <Route path="/tests/ai-generated-survey" component={AGSRoutes} /> */}
    </>
  );
};

export default TestsRoute;
