/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import style from "./index.module.css";
import { PageLayout } from "../../../components/page-layout";
import TopBar from "../components/topbar";
import UserAvatar from "../../../components/userAvatar";
import { Table, Button } from "react-bootstrap";
import { getTestParticipants } from "../../../store/actions/testActions";
import dayjs from "dayjs";
import { LuListChecks } from "react-icons/lu";
import Ribbon from "./components/ribbon";
import Responses from "./components/responses";
import { toastError } from "../../../utils/toast";
import { Button as MButton } from "@mantine/core";

export const TestParticipants = ({ match }) => {
  const { account } = useSelector((state) => state.auth) || { picture: null };
  const [searchTerm, setSearchTerm] = useState("");
  const [participantResponse, setParticipantResponse] = useState(null);

  const { participants = [] } = useSelector((state) => state.test);

  React.useEffect(() => {
    if (match.params.id) {
      getTestParticipants(match.params.id);
    }
  }, [match]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredParticipants = participants.filter(({ participant }) =>
    Object.values(participant)
      .join(" ")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  return (
    <PageLayout>
      <div className={style.parentWrapper}>
        <div className={style.wrapper}>
          <TopBar currentTab="Participants" testId={match.params.id} />
          <div className={style.main}>
            <Ribbon
              searchTerm={searchTerm}
              handleSearchChange={handleSearchChange}
              testId={match.params.id}
            />
            {participants.length > 0 ? (
              <div className={style.table}>
                <Table hover>
                  <thead className={style.tableHeader}>
                    <tr className={style.row}>
                      <th></th>
                      <th>Name</th>
                      <th>Job Title</th>
                      <th>Email</th>
                      <th>Document Sign</th>
                      <th>Date Added</th>
                      <th>Location</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredParticipants.map(
                      ({
                        participant,
                        dateAdded,
                        documentSigned,
                        response,
                      }) => (
                        <tr key={participant._id} className={style.row}>
                          <td className={style.profilePicCell}>
                            <div className={style.profilePic}>
                              {participant?.picture ? (
                                <img
                                  src={participant.picture}
                                  alt="Profile"
                                  className={style.picture}
                                />
                              ) : (
                                  <UserAvatar
                                    user={{ name: participant.name }}
                                    style={{
                                      height: "40px",
                                      width: "40px",
                                      margin: "auto",
                                    }}
                                  />
                              )}
                            </div>
                          </td>
                          <td>{participant.name || "-"}</td>
                          <td>{participant.occupation || "-"}</td>
                          <td>{participant.email || "-"}</td>
                          <td>{documentSigned ? "Completed" : "Pending"}</td>
                          <td>
                            {dateAdded
                              ? dayjs(dateAdded).format("DD/MM/YYYY")
                              : "-"}
                          </td>
                          <td>
                            {`${participant.city || ""} ${participant.state || ""
                              } ${participant.country || ""}`.trim() || "-"}
                          </td>
                          <td>
                            <MButton
                              variant="subtle"
                              className={style.screenerResponseButton}
                              onClick={() => {
                                if (!response)
                                  return toastError(
                                    "No response available for this participant"
                                  );

                                setParticipantResponse(response);
                              }}
                              leftIcon={<LuListChecks />}
                            >
                              Screener Response
                            </MButton>
                          </td>
                          {/* <td>
                        <DropdownButton
                          className={style.dropdownButton}
                          variant="link"
                          drop="down"
                          title={
                            <span>
                              Options&nbsp;
                              <FaAngleDown />
                            </span>
                          }
                        >
                          <Dropdown.Item as="button">
                            Invite to a Test
                          </Dropdown.Item>
                          <Dropdown.Item as="button" className="text-danger">
                            Remove
                          </Dropdown.Item>
                        </DropdownButton>
                      </td> */}
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </div>
            ) : (
              <p className="flex-grow-1 d-flex align-items-center mb-5">
                There are no participants available. When the participants take
                the interviews, they will be displayed here.
              </p>
            )}
          </div>
        </div>
      </div>
      <Responses
        participantResponse={participantResponse}
        onClose={() => setParticipantResponse(null)}
      />
    </PageLayout>
  );
};
