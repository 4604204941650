import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import Confetti from 'react-confetti';
import { PageLayoutWaitlist } from '../../components/page-layout-waitlist';


export const WaitlistPage = () => {
  return (
    <PageLayoutWaitlist>
    <Container>
      <Confetti />
      <Row className="justify-content-center align-items-center mt-5">
        <Col xs={8} sm={6} md={6}>
          <Card className="text-center" style={{ zIndex: 9999, padding: 20 }}>
            <Card.Header as="h4">You&apos;re on the waitlist!</Card.Header>
            <Card.Body>
              <Card.Text>
                Thank you for joining the waitlist to evaluate your users with User Evaluation. To ensure responsible use and a great experience, we&apos;ll be sending invites gradually over time. We&apos;ll let you know when we&apos;re ready for you.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    </PageLayoutWaitlist>
  );
};