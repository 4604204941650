import React, { useEffect } from "react";
import { Button, Form, ProgressBar } from "react-bootstrap";
import { Modal } from "@mantine/core";
import { useState } from "react";
import IconBtn from "../buttons/icon-button";
import { useSelector } from "react-redux";
import styles from "./index.module.css";
import { getIconForAssetType } from "./../../utils/iconsForAssetType";
function PresentationGen({
  handleClose,
  show,
  handleSubmit,
  progressPercent,
  progressMessage,
  setSelectedVideos,
  selectedVideos,
}) {
  const projectData = useSelector((state) => state.currentProject.project);
  const [prompt, setPrompt] = useState("");
  useEffect(() => {
    if (progressPercent === 100) {
      handleClose();
    }
  }, [progressPercent]);
  useEffect(() => {
    if (projectData?.videos?.length > 0) {
      setSelectedVideos(projectData?.videos?.map((video) => video._id));
    }
  }, [projectData]);
  return (
    <Modal
      size="xl"
      opened={show}
      onClose={handleClose}
      title="Create Presentation with AI"
    >
      <p>
        Generate a PowerPoint presentation based on insights from all your
        selected files. Write detailed instructions on what the presentation
        should include.
      </p>
      <Form.Control
        style={{ resize: "none" }}
        as="textarea"
        rows={3}
        placeholder="Provide instructions to the AI to generate your presentation. You can ask to include questions, charts, etc."
        value={prompt}
        onChange={(e) => {
          setPrompt(e.target.value);
        }}
        disabled={progressPercent !== 0}
      />
      <p className="mt-3">Select files to include in this presentation</p>
      <div className={styles.assetsContainer}>
        {projectData?.videos?.length > 0 &&
          projectData?.videos?.map((asset, index) => {
            return (
              <div key={index}>
                <IconBtn
                  isActive={selectedVideos.includes(asset?._id)}
                  type={asset?.assetType}
                  thumbnail={asset?.thumbnail}
                  icon={getIconForAssetType(asset.assetType)}
                  handleClick={() =>
                    setSelectedVideos((prevState) => {
                      if (prevState.includes(asset?._id)) {
                        return prevState.filter((id) => id !== asset?._id);
                      } else {
                        return [...prevState, asset?._id];
                      }
                    })
                  }
                />
                <p className={styles.assetName}>
                  {asset?.name && asset.name.length > 10
                    ? `${asset.name.slice(0, 10)}...`
                    : asset.name}
                </p>
              </div>
            );
          })}
      </div>

      <div style={{ width: "100%" }}>
        {progressPercent !== 0 && (
          <>
            <ProgressBar
              striped
              animated
              variant="info"
              now={progressPercent}
              className="mt-3"
            />
            <p className="mt-2">{progressMessage}</p>
          </>
        )}
      </div>

      <Button
        className="mt-3"
        variant="primary"
        onClick={() => {
          handleSubmit(prompt);
        }}
        disabled={
          prompt.length === 0 ||
          progressPercent !== 0 ||
          selectedVideos.length === 0
        }
      >
        Generate Presentation
      </Button>
    </Modal>
  );
}

export default PresentationGen;
