import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styles from "./index.module.css";

function Footer({ isAddButtonDisabled, handleSubmit, children }) {
  let history = useHistory();
  return (
    <div className={styles.footer}>
      <Button
        className={styles.left}
        variant="light"
        onClick={() => {
          history.goBack();
        }}
      >
        Back
      </Button>
      {children}
    </div>
  );
}

export default Footer;
