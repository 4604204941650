import React, { useEffect, useMemo, useState } from "react";
import styles from "./index.module.css";
import UsersTable from "./usertable";

import { useSelector } from "react-redux";

import { debounce } from "lodash";
import { getUsers } from "../../../../store/actions/adminActions";

function Users() {
  const { users: userdata } = useSelector((state) => state.admin);
  const [showUsers, setShowUsers] = useState([]);
  const [filter, setFilter] = useState({ planType: "", subscribed: "" });
  const [search, setSearch] = useState("");

  const debouncedSearch = debounce((search) => {
    setShowUsers((prev) =>
      prev.filter(
        (user) =>
          user.email.includes(search) ||
          user.name.includes(search) ||
          user.plan?.type.includes(search)
      )
    );
  }, 200);

  useMemo(() => {
    debouncedSearch(search, userdata);
  }, [search, userdata]);

  useMemo(() => {
    let udata = [];
    if (filter.subscribed === "true") {
      udata = userdata.filter((user) => user.plan?.subscribed === true);
    } else if (filter.subscribed === "false") {
      udata = userdata.filter((user) => user.plan?.subscribed === false);
    } else if (filter.subscribed === "") {
      udata = userdata;
    }
    if (filter.planType) {
      udata = udata.filter((user) => user.plan?.type === filter.planType);
    }
    setShowUsers(udata);
  }, [filter, userdata, search]);

  useEffect(() => {
    getUsers();
  }, []);
  return (
    <div className={styles.userContainer}>
      <h2>Users</h2>
      <div className={styles.filterContainer}>
        <label htmlFor="filter">Filter by Subscription:</label>
        <select
          id="filter"
          onChange={(e) =>
            setFilter((prev) => ({ ...prev, subscribed: e.target.value }))
          }
          value={filter.subscribed}
        >
          <option value="">all</option>
          <option value="true">Subscribed</option>
          <option value="false">not Subscribed</option>
        </select>

        <label htmlFor="filter_by_plan">Filter by Plan:</label>
        <select
          id="filter_by_plan"
          onChange={(e) =>
            setFilter((prev) => ({ ...prev, planType: e.target.value }))
          }
          value={filter.planType}
        >
          <option value="">nothing</option>
          <option value="Free">Free</option>
          <option value="Plus Monthly">Plus Monthly</option>
          <option value="Plus Yearly">Plus Yearly</option>
          <option value="Business Yearly">Business Yearly</option>
          <option value="Business Monthly">Business Monthly</option>
          <option value="Personal Yearly">Personal Yearly</option>
          <option value="Personal Monthly">Personal Monthly</option>
          <option value="Basic Monthly">Basic Monthly</option>
          <option value="Basic Yearly">Basic Yearly</option>
          <option value="Standard Monthly">Standard Monthly</option>
          <option value="Standard Monthly">Standard Monthly</option>
        </select>

        <label htmlFor="search">Search:</label>
        <input
          type="text"
          id="search"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
      </div>

      {<UsersTable userdata={showUsers} />}
    </div>
  );
}

export default Users;
