import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import LogoBlackSmall from "../../images/logo-black-small.svg";
import LogoWhiteSmall from "../../images/logo-black-small.svg";
import UserAvatar from "../userAvatar";
import { useSelector } from "react-redux";
import { logout } from "../../store/actions/authActions";

export const PageNavbarWaitlist = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const { darkMode } = useSelector((state) => state.theme);
  

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    });
  };
  return (
    <Navbar
      bg="light"
      expand="lg"
      sticky="top"
      variant={darkMode !== "true" ? "light" : "dark"}
    >
      <Container fluid>
        <Navbar.Brand>
          <Link to="/projects">
            <img src={darkMode !== "true" ? LogoBlackSmall : LogoWhiteSmall} alt="User Evaluation" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="justify-content-end flex-grow-1 pe-3"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            {isAuthenticated && (
              <>
                <NavDropdown
                  title={<UserAvatar user={user} />}
                  id="navbarScrollingDropdown"
                  align="end"
                  className="dropdown-animation"
                >
                  <NavDropdown.Item href="mailto:support@userevaluation.com">
                    Help &amp; Support
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#" onClick={handleLogout}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
