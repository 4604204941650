import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const AuthenticatedTeamMember = (props) => {
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();

  useEffect(() => {
    const id = props.match.params.id;

    axios
      .post(apiServerUrl + "/api/teaminvitation/accept", {user, invitationId: id})
      .then((response) => {
        history.push("/projects");
      })
      .catch((error) => {
        history.push("/projects");
      });

  }, [user]);

  if (!user) {
    return null;
  }
  return null;
};