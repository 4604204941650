import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
const ProjectButton = ({
  label,
  color,
  backgroundColor,
  size,
  onClick,
  children,
  varient,
  disabled
}) => {
  const buttonStyle = {
    color: color || "white",
    backgroundColor: backgroundColor || "",
    minWidth: size === "large" && "190px",
    width: '100%',
    marginTop: '-6px',
    padding:
      size === "small"
        ? "8px 16px"
        : size === "large"
        ? "12px 30px"
        : "10px 30px",
  };

  return (
    <button
      style={buttonStyle}
      onClick={onClick}
      disabled={disabled}
      className={`${styles.topBarContainer__buttons__button} ${
        varient === "secondary" ? styles.secondary : ""
      } ${varient === "primary" ? styles.primary : ""}`}
    >
      {children}
    </button>
  );
};

ProjectButton.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  onClick: PropTypes.func,
};

export default ProjectButton;
