import React from "react";
import style from "./style.module.css";
import { Topbar } from "./components/topbar";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdContentCopy } from "react-icons/md";

const TestCompleted = () => {
  const params = new URLSearchParams(window.location.search);

  return (
    <div className={style.wrapper}>
      <Topbar />
      <div className={style.content}>
        <h3 className="fw-bold">Interview Completed</h3>

        {params.get("sessionId") ? (
          <div className="w-100">
            <InputGroup>
              <InputGroup.Text>Session ID</InputGroup.Text>
              <Form.Control value={params.get("sessionId")} readOnly />
              <InputGroup.Text
                onClick={() =>
                  navigator.clipboard.writeText(params.get("sessionId"))
                }
                className="cursor-pointer"
              >
                <MdContentCopy />
              </InputGroup.Text>
            </InputGroup>
            <Form.Text className="text-muted">
              Please keep this session ID for future reference.
            </Form.Text>
          </div>
        ) : null}
        <p>
          Your answers have been submitted successfully. Please wait while the
          researchers review your responses. Your reward will be available in
          your account soon.
        </p>
        <p>
          In the meanwhile, please review your profile and keep it updated to be
          selected for future studies.
        </p>

        <Button as={Link} to="/participant" className="w-100 py-3">
          Go to Dashboard
        </Button>
      </div>
    </div>
  );
};

export default TestCompleted;
