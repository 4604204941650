import React from "react";
import styles from "./fileCard.module.css";
import { useSelector } from "react-redux";
import ThumbnailHandler from "./thumbnailHandler";
import { AiOutlineNumber } from "react-icons/ai";
import { CiCalendar } from "react-icons/ci";
import { RiUser3Line } from "react-icons/ri";
import { LuClock9 } from "react-icons/lu";
import formatISODate from "../../utils/formatDate";
import formatDuration from "../../utils/formatDuration";
import truncateString from "../../utils/truncateString";
import Actions from "./Actions";

function FileCard({ file, setSelectedVideo }) {
  const projects = useSelector((state) => state.project.projects);

  const tags = file?.tags || [];
  const projectsForAsset = projects.filter((project) =>
    project.videos.map((video) => video._id).includes(file._id)
  );

  let projectNames = projectsForAsset.map((project, index) => {
    return (
      <span key={index}>
        <AiOutlineNumber /> {project.name}
      </span>
    );
  });

  if (!file) return null;

  return (
    <div className={styles.fileCard}  onClick={() => {
      setSelectedVideo(file);
    }}>
      <ThumbnailHandler
       
        thumbnail={file.thumbnail}
        assetType={file.assetType}
        name={file.name}
      />
      <div className={styles.fileCardDetails}>
        <h3>{truncateString(file.name, 40)}</h3>
        <Information file={file} projectNames={projectNames} />
        <TagsShower tags={tags} />
      </div>
      <div className={styles.fileActions}>
        <Actions assetId={file._id} file={file} />
      </div>
    </div>
  );
}

const Information = ({ file, projectNames }) => {
  const isAudioOrVideo =
    file.assetType === "AUDIO" || file.assetType === "VIDEO";
  return (
    <p className={styles.fileInformation}>
      <span>
        {" "}
        <RiUser3Line />
        {file?.createdBy?.name}
      </span>
      <span>
        <CiCalendar /> {formatISODate(file.createdAt)}
      </span>
      {isAudioOrVideo && (
        <span>
          <LuClock9 /> {formatDuration(file.duration)}
        </span>
      )}
      {projectNames}
    </p>
  );
};

const TagsShower = ({ tags }) => {
  return (
    <div className={styles.fileTagContainer}>
      {tags.map((tag) => (
        <span key={tag} className={styles.tag}>
          {tag.title}
        </span>
      ))}
    </div>
  );
};
export default FileCard;
