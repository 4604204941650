import { Button, Table } from "react-bootstrap";
import React, { useEffect } from "react";
import { toastSuccess } from "../../../utils/toast";
import {
  getApiKeysDataAction,
  handleDeleteAPIKeyAction,
  handleGenerateApiKeyAction,
  showApiKey,
  hideApiKey
} from "../../../store/actions/settingsActions";
import { useSelector } from "react-redux";

function ApiKeys({ darkMode }) {
  const { account, accessToken, loading: authLoading } = useSelector(state => state.auth);
  const { apiKeys } = useSelector(state => state.settings);

  useEffect(() => {
    if (!authLoading) {
      getApiKeysDataAction(account._id);
    }
  }, [authLoading]);


  const handleCopyAPIKey = (key) => {
    navigator.clipboard.writeText(key);
    toastSuccess("Your API key has been copied to clipboard!");
  };


  return (
    <div className="settings-table-container">
      {/* side by side flex */}
      <div className="d-flex justify-content-between">
    <h2 className="mt-3 mb-3" style={{fontSize: 20}}>API Keys</h2>
      {/* if account.role is admin or superadmin */}
      {(account?.role === "admin" || account?.role === "superadmin") && (
        <Button
          variant="primary"
          className="mb-3"
          onClick={() => handleGenerateApiKeyAction({ accessToken, userId: account._id })}
        >
          Generate API Key
        </Button>
      )}

      </div>

      {/* table with API keys */}
      <Table bordered hover variant={darkMode ? "dark" : "light"} >
        <thead>
          <tr>
            <th style={{ minWidth: 350 }}>API Key</th>
            <th>Last Used</th>
            <th>Generated On</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {apiKeys.length > 0 ? (
            apiKeys.map((key) => (
              <tr key={key._id}>
                <td>{key.isVisible ? key.key : "********"}</td>
                <td>
                  {key.lastUsedAt
                    ? new Date(key.createdAt).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric"
                    })
                    : "Never"}
                </td>
                <td>
                  {new Date(key.createdAt).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric"
                  })}
                </td>
                <td>
                  {key.isVisible ? (
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => hideApiKey(key._id)}
                      style={{ paddingTop: 8, marginLeft: 4 }}
                    >
                      {/* material icon for show */}
                      <i className="material-icons">visibility_off</i>
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => showApiKey(key._id)}
                      style={{ paddingTop: 8, marginLeft: 4 }}
                    >
                      {/* material icon for hide */}
                      <i className="material-icons">visibility</i>
                    </Button>
                  )}
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => handleCopyAPIKey(key.key)}
                    style={{ paddingTop: 8, marginLeft: 4 }}
                  >
                    {/* material icon for copy */}
                    <i className="material-icons">file_copy</i>
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDeleteAPIKeyAction({ id: key._id })}
                    style={{ paddingTop: 8, marginLeft: 4 }}
                  >
                    {/* material icon for delete */}
                    <i className="material-icons">delete</i>
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr className="mt-3">
              <td>You have not generated any API keys yet.</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          )}
        </tbody>
      </Table>
          <hr style={{ margin: "2rem 0" }} />
    </div>
  );
}

export default ApiKeys;
