import React, { useState, useEffect } from "react";
import { getProject } from "../../store/actions/projectActions";
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { useSelector } from "react-redux";

export const MyPDF = ({ projectData }) => {

  const styles = StyleSheet.create({
    title: {
      fontSize: 32,
      fontWeight: 'bold',
      marginBottom: 20,
    },
    description: {
      fontSize: 16,
    },
    header: {
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: 10,
      marginTop: 10,
    },
    listItem: {
      fontSize: 14,
    },
    page: {
      padding: 40,
      fontFamily: 'Helvetica',
    }
  });


  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%', marginTop: '-100px', marginLeft: '-20px' }}>
          {projectData && <Text style={styles.title}>{projectData.name}</Text>}
          {projectData && <Text style={styles.description}>{projectData.description}</Text>}
        </View>
      </Page>
      {projectData?.videos.map((video, videoIndex) => (
        <Page key={videoIndex} style={styles.page} size="A4">
          <Text style={styles.header}>Interview # {videoIndex + 1}</Text>
          {video.insights.map((insight, insightIndex) => (
            <View key={insightIndex}>
              <Text style={styles.header}>{insight.insightType}</Text>
              {insight && insight.insights.map((insightSingle, index) => (
                insightSingle.insightText !== "" && insightSingle.visible ? (
                  <View key={index}>
                    <Text style={styles.listItem}>{(insightSingle.insightText).trim()}</Text>
                  </View>
                ) : (
                  ""
                )
              ))}
            </View>
          ))}
        </Page>
      ))}
    </Document>
  )
};


export const DownloadPage = () => {
  const { isAuthenticated } = useSelector(state => state.auth)

  const [projectData, setProjectData] = useState(null);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    var m = window.location.href;
    var n = m.lastIndexOf('/');
    var id = m.substring(n + 1);
    let isMounted = true;
    const getProjectData = async () => {
      let { data, error } = await getProject({
        id,
      });

      if (!isMounted) {
        return;
      }

      if (data) {
        setProjectData(data);
        setReady(true);
      }

      if (error) {
        console.error(error);
      }
    }

    if (isAuthenticated)
      getProjectData();


  }, [isAuthenticated]);

  return (
    <>
      {(ready) &&
        <PDFViewer style={{ width: '100%', height: '100vh' }}>
          <MyPDF projectData={projectData} />
        </PDFViewer>}

    </>
  )
};