import { createSlice } from "@reduxjs/toolkit";

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    apiKeys: [],
    organisationUsers: []
  },
  reducers: {
    requestStart(state, action) {
      state.loading = true;
      state.error = null;
    },
    requestFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getApiKeys(state, action) {
      state.apiKeys = action.payload.apiKeys;
      state.loading = false;
      state.error = null;
    },
    getOrganisationUsers(state, action) {
      state.organisationUsers = action.payload.organisationUsers;
      state.loading = false;
      state.error = null;
    },
    deleteApiKey(state, action) {
      state.apiKeys = state.apiKeys.filter((key) => key._id !== action.payload.id);
      state.loading = false;
      state.error = null;
    },
    generateApiKey(state, action) {
      state.apiKeys = [...state.apiKeys, action.payload]
      state.loading = false;
      state.error = null;
    },
    showApiKey(state, action) {
      const updatedKeys = state.apiKeys.map((key) => {
        if (key._id === action.payload) {
          return { ...key, isVisible: true };
        }
        return key;
      });
      state.apiKeys = updatedKeys
    },
    hideApiKey(state, action) {
      state.apiKeys = state.apiKeys.map((key) => {
        if (key._id === action.payload) {
          return { ...key, isVisible: false };
        }
        return key;
      });
    }
  }
});

export default settingsSlice;
export const settingsActions = settingsSlice.actions;