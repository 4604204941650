import React, { useState } from "react";
import styles from "./tagPopUp.module.css";
import { HiChevronUpDown } from "react-icons/hi2";

function DropDown({ sectionTitle, differentLanes,handleMove}) {
  const [show, setShow] = useState(false);

  return (
    <div className={styles.tagsSelectionContainer}>
      <div
        onClick={() => {
          setShow(!show);
        }}
        className={styles.selectedTag}
      >
        <span>{sectionTitle}</span>
        <HiChevronUpDown />
      </div>
      {show && (
        <div className={styles.tagsSelection}>
          {differentLanes?.map((tag) => {
            return (
              <div
                className={styles.selectionItems}
                key={tag._id}
                onClick={() => {
                  handleMove(tag._id);
                  setShow(false);
                }}
              >
                <span>{tag?.title}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default DropDown;
