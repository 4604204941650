import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./index.module.css";
import IconBtn from "../../../../pages/project/components/buttons/icon-button";
import { FiFileText, FiFilm } from "react-icons/fi";
import truncateString from "../../../../utils/truncateString";
import Player from "../../../../pages/project/components/clips/player";
import RangeSlider from "react-range-slider-input";
import {
  TimeInput,
  iconsForAssetType,
} from "../../../../pages/project/components/clips/createTrimClipModal";
import { Button } from "@mantine/core";
import { createClipAction } from "../../../../store/actions/clipActions";
import { useSelector } from "react-redux";

const EditrClips = ({ onDataChange, discard }) => {
  const { project } = useSelector((state) => state.currentProject);
  const assets = useMemo(
    () =>
      project?.videos?.filter(
        (video) => video.assetType === "VIDEO" || video.assetType === "AUDIO"
      ),
    [project]
  );

  const [value, setValue] = useState([0, 0]);
  const [assetId, setAssetId] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [currentPlayerTime, setCurrentPlayerTime] = useState(0);

  const selectAsset = (asset) => {
    setAssetId(asset._id);
    setSelectedVideo(asset);
    setValue([0, asset.duration]);
  };

  useEffect(() => {
    if (assets?.length > 0) {
      selectAsset(assets[0]);
    }
  }, [assets]);

  const videoRef = useRef(null);
  const isVideo = selectedVideo?.assetType === "VIDEO";

  return (
    <div className={styles.container}>
      {assets?.length ? (
        <>
          <div className={styles.clipTrimContainer}>
            <div className={styles.sidebar}>
              {assets?.map((ele, i) => (
                <div className={styles.sidebar__btn} key={i}>
                  <IconBtn
                    handleClick={() => selectAsset(ele)}
                    type={ele?.assetType}
                    thumbnail={ele?.thumbnail}
                    icon={iconsForAssetType[ele.assetType] || <FiFileText />}
                    isActive={ele._id === assetId}
                  />
                  <span className={styles.sidebar__span}>
                    {truncateString(ele.name, 8)}
                  </span>
                </div>
              ))}
            </div>
            <div className={styles.content}>
              <div className={styles.player}>
                {selectedVideo && (
                  <Player
                    file={selectedVideo}
                    videoRef={videoRef}
                    currentPlayerTime={currentPlayerTime}
                    setCurrentPlayerTime={setCurrentPlayerTime}
                    onTimeUpdate={(e) => {
                      setCurrentPlayerTime(e.target?.currentTime);
                    }}
                    isVideo={isVideo}
                    gotoTime={value[0]}
                    endTime={value[1]}
                    style={{ width: "100%" }}
                  />
                )}
              </div>

              <div className={styles.trimmer}>
                <RangeSlider
                  id="range-slider-ab"
                  className="margin-lg"
                  step={1}
                  rangeSlideDisabled={false}
                  onInput={(value) => {
                    if (value[0] > value[1]) return;
                    setValue(value);
                  }}
                  value={value}
                  min={0}
                  max={selectedVideo?.duration}
                />
                <div className={styles.timings}>
                  <p>Clip Start/End Time:</p>
                  <div>
                    Start time
                    <TimeInput
                      currentTime={value[0]}
                      setValue={(val) => {
                        setValue([val, value[1]]);
                      }}
                      maxVal={value[1]}
                    />
                  </div>
                  <div>
                    End time
                    <TimeInput
                      currentTime={value[1]}
                      setValue={(val) => {
                        setValue([value[0], val]);
                      }}
                      maxVal={selectedVideo?.duration}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.actionButton}>
            <div className="d-flex gap-2">
              <Button
                style={{
                  borderRadius: "0.5rem",
                }}
                variant="default"
                onClick={discard}
              >
                Discard
              </Button>

              <Button
                style={{
                  borderRadius: "0.5rem",
                }}
                variant="default"
                onClick={() => {
                  createClipAction({
                    title: "Creating...",
                    assetId: assetId,
                    projectId: project._id,
                    startTime: value[0] * 1000,
                    endTime: value[1] * 1000,
                  });
                }}
              >
                Save to Clips Tab
              </Button>
            </div>
            <Button
              className={styles.createButton}
              variant="filled"
              style={{
                borderRadius: "0.5rem",
              }}
              onClick={() => {
                onDataChange({
                  start: value[0],
                  end: value[1],
                  url: selectedVideo?.link,
                  caption: "",
                });
              }}
            >
              Insert Clip
            </Button>
          </div>
        </>
      ) : (
        <div>
          <div className={styles["not-found-icon"]}>
            <FiFilm size={28} className="mx-auto d-block" />
          </div>
          <p className="mt-2 mb-0">
            You do not have any audio/video files in this project.
          </p>
        </div>
      )}
    </div>
  );
};

export default EditrClips;
