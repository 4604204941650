import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  useSlate,
  useSelected,
  useFocused
} from "slate-react";
import { Transforms, Range } from "slate";
import { Form, Modal, Button as BootstrapButton } from "react-bootstrap";
import { Button, Icon } from "./project-note-components";

const handleInsertLink = (editor, href) => {

  const { selection } = editor;
  if (editor && href) {
    if (selection) {
      wrapLink(editor, href);
    } else {
      insertLink(editor, href);
    }
  }
};
const insertLink = (editor, href) => {
  const text = { text: href };
  const link = { type: "link", href, children: [text] };
  Transforms.insertNodes(editor, link);
};
const wrapLink = (editor, href) => {
  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: "link",
    href,
    children: isCollapsed ? [{ text: href }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};
export const LinkButton = () => {
  const { darkMode } = useSelector(state => state.theme);
  const editor = useSlate();
  const [show, setShow] = useState(false);
  const [url, setUrl] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (url) {
      handleInsertLink(editor, url);
      handleClose();
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow} reversed={darkMode}>
        <Icon>link</Icon>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Insert Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicUrl">
              <Form.Label>URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the URL of the link"
                value={url}
                onChange={(event) => setUrl(event.target.value)} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <BootstrapButton variant="secondary" onClick={handleClose}>
            Cancel
          </BootstrapButton>
          <BootstrapButton variant="primary" onClick={handleSubmit}>
            Insert Link
          </BootstrapButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export const Link = ({ attributes, element, children }) => {
  const editor = useSlate();
  const selected = useSelected();
  const focused = useFocused();

  let href = element.href; // create a new variable to store the href value


  // if element.href does not have https:// or http://, add it
  if (href) {
    if (!href.includes("https://") && !href.includes("http://")) {
      href = "https://" + href;
    }
  }

  return (
    <div className='editor-link'>
      <a href={href} {...attributes} {...element.attr}>{children}</a>
      {selected && focused && (
        <div className='link-popup' contentEditable={false}>
          <a href={href} target="_blank" rel="noreferrer">{href}</a>
          <Button onClick={() => removeLink(editor)}>
            <Icon>link_off</Icon>
          </Button>
        </div>
      )}
    </div>
  );
};
const removeLink = (editor) => {
  Transforms.unwrapNodes(editor, { match: n => n.type === 'link' });
};
