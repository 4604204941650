export const checkPassword = (password, confirmPassword) => {
  if (password?.length < 8 || password?.length > 64)
    return "Password should be between 8 and 64 characters";
  if (!password?.match(/[a-z]/))
    return "Password should contain at least one lowercase letter";
  // if (!password?.match(/[A-Z]/))
  //   return "Password should contain at least one uppercase letter";
  if (!password?.match(/[0-9]/))
    return "Password should contain at least one number";
  if (!password?.match(/[^a-zA-Z0-9]/))
    return "Password should contain at least one special character";
  if (password !== confirmPassword) return "Passwords do not match";

  return null;
};
