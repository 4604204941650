import React, { useEffect } from "react";
import styles from "./index.module.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ribbonActions } from "../../../../store/slices/ribbonSlice";
import { useDispatch } from "react-redux";
function PreviewComponent({ presentations }) {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const dispatch = useDispatch();
  const presentation =
    presentations.find((presentation) => {
      return presentation._id === params.get("t");
    }) || presentations[0];

  useEffect(() => {
    if (presentation?.url) {
      let data = {
        url: presentation?.url,
        type: "presentation",
      };
      dispatch(
        ribbonActions.setRibbonData({ name: "presentation", value: data })
      );
    }
  }, [presentation]);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className={styles.previewSlide}>
        {presentation?.url && (
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${presentation?.url}`}
            width="100%"
            height="600px"
            frameBorder="0"
          ></iframe>
        )}
      </div>
    </div>
  );
}

export default PreviewComponent;
