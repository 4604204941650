import React, { useState, useEffect, useMemo } from "react";
import { FiX } from "react-icons/fi";
import styles from "./sentiment.module.css";
import { ribbonActions } from "../../../../store/slices/ribbonSlice";
import { useDispatch } from "react-redux";
import { Tooltip } from "@mantine/core";
function Sentiment({ assetSentiment, speakers }) {
  const [speakerSentiment, setSpeakerSentiment] = useState([]);
  const dispatch = useDispatch();

  const totalSentimentMap = useMemo(() => {
    if (Array.isArray(assetSentiment) && assetSentiment[0]) {
      let sentiment = assetSentiment[0]?.data;
      if (!sentiment) return 0;
      let allSentiments = sentiment.Positive.concat(
        sentiment.Negative,
        sentiment.Neutral
      );

      let positive = sentiment?.Positive.length;
      let negative = sentiment?.Negative.length;
      let neutral = sentiment?.Neutral.length;
      return {
        positive: Math.round((positive / allSentiments.length) * 100),
        negative: Math.round((negative / allSentiments.length) * 100),
        neutral: Math.round((neutral / allSentiments.length) * 100),
      };
    }
    return 0;
  }, [assetSentiment]);

  let mapToCorrectSpeaker = (speaker) => {
    if (speaker?.length > 0) {
      let speakerName = speakers.find((spk) => spk.label === speaker);
      if (speakerName) {
        return speakerName.name;
      }
    }
    return speaker;
  };

  useEffect(() => {
    if (Array.isArray(assetSentiment) && assetSentiment[0]) {
      let sentiment = assetSentiment[0].data;
      let speakerSentiment = {};
      let allSentiments = sentiment.Positive.concat(
        sentiment.Negative,
        sentiment.Neutral
      );
      allSentiments.forEach((sentiment, index) => {
        let speaker = mapToCorrectSpeaker(sentiment.speaker);
        let count = sentiment.text?.split(" ").length;
        if (speakerSentiment[speaker]) {
          if (speakerSentiment[speaker][sentiment.sentiment]) {
            speakerSentiment[speaker][sentiment.sentiment] += count;
          } else {
            speakerSentiment[speaker][sentiment.sentiment] = count;
          }
        } else {
          speakerSentiment[speaker] = {
            Positive: 0,
            Negative: 0,
            Neutral: 0,
          };
          speakerSentiment[speaker][sentiment.sentiment] = count;
        }
      });

      setSpeakerSentiment(speakerSentiment);
    }
  }, [assetSentiment, speakers]);

  // const sentiment_by_speakers = [];
  return (
    <div className={`w-100 p-2 ${styles.sentiment}`}>
      <div className="d-flex justify-content-between align-items-center">
        <p style={{ margin: 0 }}>Overall Sentiment</p>
        <FiX
          className={styles.closeBtn}
          onClick={() => {
            dispatch(ribbonActions.setSentiment(false));
          }}
        />
      </div>
      <ul className={`list-group ${styles.sentimentDiv} `}>
        <li className="list-group-item d-flex  align-items-center py-2">
          <span className="flex-grow-1">Positive</span>
          <span>{totalSentimentMap.positive}%</span>
        </li>
        <li className="list-group-item d-flex  align-items-center py-4">
          <span className="flex-grow-1">Negative</span>
          <span>{totalSentimentMap.negative}%</span>
        </li>
        <li className="list-group-item d-flex  align-items-center py-2">
          <span className="flex-grow-1">Neutral</span>
          <span>{totalSentimentMap.neutral}%</span>
        </li>
      </ul>
      <div className={styles.sentiment_speakers}>
        <p style={{ margin: 0 }}>Sentiment by Speaker</p>
        <div className={`speakers-time-graph ${styles.timeGraph}`}>
          {speakerSentiment &&
            Object.keys(speakerSentiment).map((speaker, index) => {
              let total = Object.values(speakerSentiment[speaker]).reduce(
                (a, b) => a + b
              );
              let posPercent = Math.round(
                (speakerSentiment[speaker].Positive / total) * 100
              );
              let negPercent = Math.round(
                (speakerSentiment[speaker].Negative / total) * 100
              );
              let neuPercent = Math.round(
                (speakerSentiment[speaker].Neutral / total) * 100
              );

              return (
                <div key={index}>
                  <p className="my-1 ">{speaker}</p>
                  <div className={styles.percentContainer}>
                    <Tooltip label="Positive">
                      <div
                        className={styles.positive}
                        style={{
                          width: `${posPercent}%`,
                        }}
                      >
                        {posPercent > 0 && (
                          <span className={styles.percent}>{posPercent}%</span>
                        )}
                      </div>
                    </Tooltip>
                    <Tooltip label="Negative">
                      <div
                        className={styles.negative}
                        style={{
                          width: `${negPercent}%`,
                        }}
                      >
                        {" "}
                        {negPercent > 0 && (
                          <span className={styles.percent}>{negPercent}%</span>
                        )}
                      </div>
                    </Tooltip>
                    <Tooltip label="Neutral">
                      <div
                        className={styles.neutral}
                        style={{
                          width: `${neuPercent}%`,
                        }}
                      >
                        {" "}
                        {neuPercent > 0 && (
                          <span className={styles.percent}>{neuPercent}%</span>
                        )}
                      </div>
                    </Tooltip>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Sentiment;
