import React from "react";
import css from "../index.module.css";
import { Button, Form } from "react-bootstrap";
import { GoogleLoginBtn } from "..";
import { useHistory } from "react-router-dom";
import { signin } from "../../../store/actions/authActions";
import { toast } from "react-toastify";
import { AuthLayout } from "..";

const Login = () => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const invitationId = params.get("invite");
  const handleLogin = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());

    signin(data.email, data.password, false, invitationId).then(
      ({ data, error }) => {
        if (error)
          return toast.error(
            error?.message ||
              "The credentials you entered are incorrect. Please try again."
          );

        if (data) {
          if (!data?.user?.plan?.subscribed && !data?.user?.phone_verified) {
            history.push("/phone-verify");
          }
        }
      }
    );
  };

  return (
    <AuthLayout>
      <div className={css.login}>
        <h2>Welcome back</h2>
        <p className="mb-4 mb-md-5">
          Don&apos;t have an account?&nbsp;
          <Button
            variant="link"
            className="p-0 border-0 text-decoration-none"
            onClick={() => history.push("/signup")}
          >
            Create one for free
          </Button>
        </p>
        <Form onSubmit={handleLogin}>
          <Form.Group className="mb-3">
            <Form.Control
              type="email"
              placeholder="Email address"
              required
              name="email"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              type="password"
              placeholder="Password"
              required
              name="password"
              minLength={8}
              maxLength={264}
            />
          </Form.Group>
          <Button
            variant="link"
            className="p-0 text-decoration-none ms-auto d-block"
            onClick={() => history.push("/forgot-password")}
          >
            Forgot password?
          </Button>
          <Button className="w-100" type="submit">
            Login
          </Button>
          <GoogleLoginBtn invitationId={invitationId} />
        </Form>
      </div>
    </AuthLayout>
  );
};

export default Login;
