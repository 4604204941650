import React, { useState } from "react";
import { FiX } from "react-icons/fi";
import { modalActions } from "../../../store/slices/modalSlice";
import { useDispatch } from "react-redux";
import { Form, Modal } from "react-bootstrap";
import styles from "./speakerName-modal.module.css";
import {
  updateSpeakerName,
  updateTranscript,
} from "../../../store/actions/projectActions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { useEffect } from "react";
function SpeakerNameChangeModal({
  show,
  speaker,
  speakers,
  setSpeakers,
  setSelectedSentence,
  selectedSentence,
}) {
  const dispatch = useDispatch();

  const [speakerName, setSpeakerName] = useState("");
  const [speakerRole, setSpeakerRole] = useState("");
  const [transcript, setTranscript] = useState("");

  useEffect(() => {
    if (selectedSentence) {
      setTranscript(
        selectedSentence?.words.map((sentenceObj) => sentenceObj.text).join(" ")
      );
    }
  }, [selectedSentence]);

  useEffect(() => {
    if ((speaker, speakers)) {
      let speakerObj = speakers.find(
        (speakerObj) => speakerObj.label === speaker
      );
      if (speakerObj) {
        setSpeakerName(speakerObj.name);
        setSpeakerRole(speakerObj.role);
      } else {
        setSpeakerName("");
        setSpeakerRole("");
      }
    }
  }, [speaker, speakers]);

  useEffect(() => {
    return () => {
      setSpeakerName("");
      setSpeakerRole("");
    };
  }, []);
  const { assetId } = useParams();
  const handleChange = async (e) => {
    let speakerObj = speakers.find(
      (speakerObj) => speakerObj.label === speaker
    );
    if (
      (speakerObj?.name !== speakerName || speakerObj?.role !== speakerRole) &&
      speakerName !== ""
    ) {
      // if speaker label already exist in speakers array, then update the speaker name and role
      // else add the speaker name and role to the speakers array
      let check = 0;
      let allSpeakersIncludingCurrentSpeaker = speakers.map((speakerObj) => {
        if (speakerObj.label === speaker) {
          check = 1;
          return {
            label: speaker,
            name: speakerName,
            role: speakerRole,
          };
        } else {
          return speakerObj;
        }
      });

      if (check === 0) {
        allSpeakersIncludingCurrentSpeaker.push({
          label: speaker,
          name: speakerName,
          role: speakerRole,
        });
      }
      const { data, error } = await updateSpeakerName(assetId, {
        speakers: allSpeakersIncludingCurrentSpeaker,
      });

      if (error) {
        toast.error("Error in updating speaker name");
      }
      if (data) {
        setSpeakers(allSpeakersIncludingCurrentSpeaker);
        toast.success("Speaker name updated successfully");
      }
    }
    if (
      selectedSentence?.words
        .map((sentenceObj) => sentenceObj.text)
        .join(" ") !== transcript
    ) {
      let wordsWithTime = addTimeToWords(transcript, selectedSentence);
      let sentence = {
        start: selectedSentence.start,
        end: selectedSentence.end,
        words: wordsWithTime,
        speaker: selectedSentence.speaker,
        text: transcript,
      };
      const { data, error } = await updateTranscript(assetId, {
        sentence,
      });
      if (error) {
        toast.error("Error in updating transcript");
      }
      if (data) {
        setSelectedSentence(sentence);
        toast.success("Transcript updated successfully");
      }
    }
  };

  return (
    <Modal
      show={show}
      centered
      backdrop="static"
      size="lg"
      className="pay-modal-container"
    >
      <Modal.Header style={{ paddingBottom: "0px", border: "none" }}>
        <h6 className={styles.title}>Edit Transcript</h6>
        <button
          style={{
            fontSize: "1.3rem",
            border: "none",
            background: "transparent",
          }}
          aria-label="Close"
        >
          <FiX
            aria-hidden="true"
            onClick={() => {
              dispatch(modalActions.hideSpeakerNameChangeModal());
            }}
          />
        </button>
      </Modal.Header>
      <Modal.Body>
        <p className={styles.instructions}>
          Add a speaker name, role and transcript for this speaker. Note that
          speaker labels help the AI understand your data better and
          regenerating insights can yield different results.
        </p>
        <div className={styles.modalSpeakerSection}>
          <p>
            <label htmlFor="speakername">Speaker {speaker}&apos;s Name: </label>
            <input
              type="text"
              name="firstname"
              placeholder="First name or full name"
              id="speakername"
              value={speakerName}
              onChange={(e) => {
                setSpeakerName(e.target.value);
              }}
            />
          </p>
          <p>
            <label htmlFor="role">
              Speaker {speaker}&apos;s Role (e.g., Researcher):{" "}
            </label>
            <input
              type="text"
              name="role"
              placeholder="Optional"
              id="role"
              value={speakerRole}
              onChange={(e) => {
                setSpeakerRole(e.target.value);
              }}
            />
          </p>
        </div>
        <div className={styles.modalTranscriptSection}>
          <p>
            <label htmlFor="transcript">Transcript: </label>
            <Form.Control
              as="textarea"
              style={{ resize: "none", fontSize: "14px" }}
              rows={3}
              value={transcript}
              onChange={(e) => {
                setTranscript(e.target.value);
              }}
              placeholder="Enter transcript here"
            />
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ border: "none",display:"flex",justifyContent:"flex-end" }}>
        <div className={styles.clipButtonContainer}>
          <button
            onClick={() => {
              dispatch(modalActions.hideSpeakerNameChangeModal());
            }}
          >
            Dismiss
          </button>
          <button
            onClick={() => {
              handleChange();
              dispatch(modalActions.hideSpeakerNameChangeModal());
            }}
          >
            Save
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

function addTimeToWords(transcript, sentenceObj) {
  let words = transcript.split(" ");
  let sentenceStartTime = sentenceObj.start;
  let sentenceEndTime = sentenceObj.end;
  // give each word a start time and end time based on the sentence start time and end time and the number of words in the sentence
  if(words.length > sentenceObj.words.length) {
    let wordsWithTime = words.map((word, index) => {
      let wordStartTime =
        sentenceStartTime +
        (index * (sentenceEndTime - sentenceStartTime)) / words.length;
      let wordEndTime =
        sentenceStartTime +
        ((index + 1) * (sentenceEndTime - sentenceStartTime)) / words.length;
      return {
        text: word,
        start: wordStartTime,
        end: wordEndTime,
        speaker: sentenceObj.speaker,
      };
    });
    return wordsWithTime;
  }

  let wordsWithTime = words.map((word, index) => {
    return {
      text: word,
      start: sentenceObj.words[index].start,
      end: sentenceObj.words[index].end,
      speaker: sentenceObj.speaker,
    };
  }
  );
  return wordsWithTime;
  

}

export default SpeakerNameChangeModal;
