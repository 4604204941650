import React from 'react'
import { useEffect } from 'react';
import { Navbar } from 'react-bootstrap'
import { AiFillFileImage } from 'react-icons/ai'

const logoImageStyle = { width: 'auto', height: '24px' }

function TopNav({ logoLink }) {
    const [error, setError] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    useEffect(() => {
        const img = new Image();
        img.src = logoLink;

        img.onload = () => {
            setLoaded(true);
        };

        img.onerror = () => {
            setError(false);
        };
    }, [logoLink]);

    let image = null;
    if (logoLink && !error && loaded) {
        image = (
            <img
                src={logoLink}
                style={logoImageStyle}
                className="d-inline-block align-center"
                alt="logo"
            />)
    }
    if (error) {
        image = (
            <ImagePlaceholder />
        )
    }

    return (
        <Navbar bg="light" expand="lg" sticky="top">
            <Navbar.Brand href="#" style={{ paddingLeft: "3%" }}>
                {image}
            </Navbar.Brand>
        </Navbar>
    )
}
function ImagePlaceholder() {
    return (
        <div className="image-placeholder">
            <AiFillFileImage className="image-icon" />
        </div>
    )
}

export default TopNav