import React, { useState, useEffect, useContext } from "react";
// import { useSelector } from "react-redux";
import { saveVideoThumbnail } from "../store/actions/projectActions";
import { FiXCircle } from "react-icons/fi";
import styles from "./player.module.css";
import ParentRefContext from "../pages/project-vnext/parentRefContext";

export default function Player({
  file,
  videoRef,
  onTimeUpdate,
  isVideo,
  className,
  gotoTime,
  containerClassName,
}) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [hover, setHover] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [justSavedThumbnail, setJustSavedThumbnail] = useState(false);
  const [hoverButtonClicked, setHoverButtonClicked] = useState(false);
  const parentRef = useContext(ParentRefContext);
  const handleParentScroll = () => {
    const position = parentRef.current.scrollTop;
    setScrollPosition(position);
    // Logic to run when the parent is scrolled
    if (parentRef?.current.scrollTop > 284 && !hoverButtonClicked) {
      setHover(true);
    } else {
      setHover(false);
    }
  };
  useEffect(() => {
    if (window.innerWidth < 768) {
      return;
    }
    const parentElement = parentRef?.current;
    parentElement?.addEventListener("scroll", handleParentScroll);
    return () => {
      parentElement?.removeEventListener("scroll", handleParentScroll);
    };
  }, [scrollPosition, hoverButtonClicked]);
  const captureThumbnail = async () => {
    if (file.thumbnail || justSavedThumbnail) return;
    const videoWidth = videoRef.current.videoWidth;
    const videoHeight = videoRef.current.videoHeight;
    const aspectRatio = videoWidth / videoHeight;

    const thumbnailWidth = 600;
    const thumbnailHeight = thumbnailWidth / aspectRatio;
    const canvas = document.createElement("canvas");
    canvas.width = thumbnailWidth;
    canvas.height = thumbnailHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(videoRef.current, 0, 0, thumbnailWidth, thumbnailHeight);
    const thumbnailDataUrl = canvas.toDataURL("image/jpeg");
    setThumbnail(thumbnailDataUrl);
    const { error } = await saveVideoThumbnail({
      thumbnail: thumbnailDataUrl,
      videoId: file._id,
    });
    if (error) console.error(error);
    else setJustSavedThumbnail(true);
  };
  useEffect(() => {
    if (gotoTime) {
      videoRef.current.currentTime = gotoTime;
    }
  }, [gotoTime]);
  useEffect(() => {
    if (!videoRef.current || !isVideo) return;

    const handleTimeUpdate = () => {
      if (videoRef.current?.currentTime >= 3) {
        captureThumbnail();
        videoRef.current.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };

    videoRef.current.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, [videoRef, isVideo]);

  useEffect(() => {
    if (!videoRef.current || !isVideo) return;

    const videoElement = videoRef.current;
    videoElement.srcObject = null;
    videoElement.load();
    videoElement.src = file.link;
  }, [file, isVideo, videoRef]);

  return (
    <>
      <div
        style={{
          margin: "auto",
          width: "100%",
          maxWidth: "450px",
          height: "auto",
        }}
        className={containerClassName}
      >
        {isVideo ? (
          <video
            crossOrigin="anonymous"
            ref={videoRef}
            onTimeUpdate={onTimeUpdate}
            className={`video-player ${
              (hover && isVideo && "hover-player") || ""
            }  ${className || ""}`}
            controls
            style={{
              objectFit: "cover",
              width: `${!hover ? "100%" : "320px"}`,
              height: `${!hover ? "100%" : "200px"}`,
            }}
          >
            <source src={file.link} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <audio
            ref={videoRef}
            className={`audio-player ${
              (hover && isVideo && "hover-player") || ""
            }`}
            controls
          >
            <source src={file.link} type="audio/ogg" />
            Your browser does not support the audio element.
          </audio>
        )}
        {
          // if hover is true, show the button
          hover && isVideo && (
            <div className={styles.hoverCrossBtnContainer}>
              <FiXCircle
                style={{
                  display: hover ? "block" : "none",
                  color: "black",
                  opacity: 1,
                }}
                onClick={() => {
                  setHover(false);
                  setHoverButtonClicked(true);
                }}
              />
            </div>
          )
        }
      </div>
      {thumbnail && (
        <img
          src={thumbnail}
          alt="Video thumbnail"
          style={{ display: "none" }}
        />
      )}
    </>
  );
}
