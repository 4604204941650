import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Spinner, Container } from "react-bootstrap";
import Editor from "../../components/editor";
import { getLinkByIdAction } from "../../store/actions/publicLinkActions";
import { getProjectForPublicResource } from "../../store/actions/projectActions";
import { BoardTab } from "../../components/board/board";
import TopNav from "./topnavbar";
import UELogo from "../../images/logo-blue-small.svg";
import PublicProjectPage from "./publicProjectPage";
import { useDispatch } from "react-redux";
import { currentProjectActions } from "../../store/slices/currentProject";
import { tagsActions } from "../../store/slices/tagsSlice";
export const PublicPage = () => {
  const [loading, setLoading] = useState(true);
  const [resourceNotFound, setResourceNotFound] = useState(false);
  const [resourceType, setResourceType] = useState(null); // ['project board', 'project report'
  const [dataOfProject, setDataOfProject] = useState(null);
  const [logoLink, setLogoLink] = useState("");
  const [showProject, setShowProject] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, error } = await getLinkByIdAction(id);

        if (error) {
          console.error(error);
          setResourceNotFound(true);
          setLoading(false);
          return;
        }

        if (data) {
          const linkData = data;
          setResourceType(linkData.resource);
          if (linkData.resource === "project board") {
            const { data, error } = await getProjectForPublicResource({
              id: linkData.resourceId,
              linkId: linkData._id,
              type: "board",
            });
            if (data) {
              setDataOfProject(data);
              if (data.organization.logo) {
                setLogoLink(data.organization.logo);
              } else {
                setLogoLink(UELogo);
              }
              setLoading(false);
            }
            if (error) {
              console.error(error);
              setResourceNotFound(true);
              setLoading(false);
              return;
            }
          } else if (linkData.resource === "project report") {
            const { data, error } = await getProjectForPublicResource({
              id: linkData.resourceId,
              linkId: linkData._id,
              type: "report",
            });
            if (data) {
              setDataOfProject(data);
              if (data?.organization?.logo) {
                setLogoLink(data.organization.logo);
              } else {
                setLogoLink(UELogo);
              }
              setLoading(false);
            }
            if (error) {
              setResourceNotFound(true);
              setLoading(false);
              return;
            }
          } else if (linkData.resource === "project") {
            const { data, error } = await getProjectForPublicResource({
              id: linkData.resourceId,
              linkId: linkData._id,
              type: "project",
            });
            if (data) {
              dispatch(tagsActions.getTags({ tags: data.tags }));
              dispatch(currentProjectActions.setPublic({isPublic:true,id:id}));
              setDataOfProject(data);
              setShowProject(true);
              setLoading(false);
              dispatch(currentProjectActions.setProject({ project: data }));
            }
            if (error) {
              console.error(error);
              setResourceNotFound(true);
              setLoading(false);
              return;
            }
          } else {
            setResourceNotFound(true);
            setLoading(false);
            return;
          }
        }

        // setLoading(false);
      } catch (error) {
        console.error(error);
        setResourceNotFound(true);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (resourceNotFound && !loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <h2>Sorry, we could not find this resource.</h2>
      </div>
    );
  }

  return (
    <>
      {showProject ? (
        <>
          {dataOfProject ? (
            loading ? (
              <div className="d-flex justify-content-center align-items-center vh-100">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <PublicProjectPage id={id} />
            )
          ) : (
            <div className="d-flex justify-content-center align-items-center vh-100">
              <h2>Sorry, we could not find this resource.</h2>
            </div>
          )}
        </>
      ) : (
        <>
          <TopNav logoLink={logoLink} />
          <Container fluid className="mt-4">
            {loading ? (
              <div className="d-flex justify-content-center align-items-center vh-100">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <>
                {resourceType === "project board" && (
                  <>
                    <div
                      style={{
                        paddingLeft: 20,
                      }}
                    >
                      <h1>{dataOfProject.name}</h1>
                      <p>{dataOfProject.description}</p>
                    </div>
                    {dataOfProject && (
                      <BoardTab
                        projectData={dataOfProject}
                        publicBoard={true}
                      />
                    )}
                  </>
                )}

                {resourceType === "project report" && (
                  <>
                    <div
                      style={{
                        margin: "0 auto",
                        padding: 40,
                        width: "fit-content",
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                        borderRadius: 10,
                      }}
                    >
                      {dataOfProject && (
                        <Editor
                          initialValue={dataOfProject.content}
                          readOnly={true}
                        />
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </Container>
        </>
      )}
    </>
  );
};
