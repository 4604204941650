// Prompt Input - react component
import React, { useState } from "react";
import { Card, Form, Button, ProgressBar, Row, Col } from 'react-bootstrap';
import { HiSparkles } from "react-icons/hi";

const PromptInput = ({ handleSubmit, progressPercent, progressMessage }) => {
    const [prompt, setPrompt] = useState(''); // prompt is the user input
    return  (
        <Row className='justify-content-center'>
                <Col xs={12} md={8} className='mt-5'>
                    <Card className='w-100'>
                <Card.Body>
                    <Card.Title>
                        <HiSparkles size={20} color='#007bff' />&nbsp;
                        AI Presentation
                    </Card.Title>
                    <Card.Text>
                        {/* {progressMessage} */}
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label className='mb-3 mt-2'>User Evaluation AI can generate a PowerPoint PPTX presentation for you based on the insights. Please write detailed instructions on what the presentation should include.</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder='Provide instructions to the AI to generate your presentation. You can ask to include questions, charts, etc.' value={prompt} onChange={(e) => setPrompt(e.target.value)} />
                            </Form.Group>
                            {/* flex right */}
                            {(progressPercent===0) && <div className='d-flex justify-content-end'>
                                <Button variant="primary" type="submit" className='mt-3' onClick={() => handleSubmit(prompt)} disabled={progressPercent !== 0}>
                                    Generate Presentation
                                </Button>
                            </div>}

                            {(progressPercent !== 0) && <><ProgressBar striped animated variant="info" now={progressPercent} className="mt-3" />
                            <p className='mt-2'>{progressMessage}</p></>}

                    </Card.Text>
                </Card.Body>
            </Card>
                </Col>
            </Row>
    )
}

export default PromptInput;