import store from "..";
import { participantActions } from "../slices/participantSlice";
import { callExternalApi } from "../../services/external-api.service";
import { toastError, toastSuccess } from "../../utils/toast";

const { dispatch } = store;
const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getParticipant = async (profile) => {
  dispatch(participantActions.requestStart());
  const config = {
    url: `${apiServerUrl}/api/participant`,
    method: "PUT",
    data: { profile },
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    dispatch(participantActions.requestFail(error.message));
    return toastError(error.message);
  }
  dispatch(participantActions.setParticipant(data));
  dispatch(participantActions.requestSuccess());
};

export const updateParticipantSettings = async ({
  name,
  email,
  paypalMail,
}) => {
  dispatch(participantActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/participant/settings`,
    method: "PUT",
    data: { name, email, paypalMail },
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    dispatch(participantActions.requestFail(error.message));
    return toastError(error.message);
  }

  toastSuccess("Settings updated");
  dispatch(participantActions.setParticipant(data));
  dispatch(participantActions.requestSuccess());
};

export const updateParticipant = async (key, prop) => {
  dispatch(participantActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/participant/update`,
    method: "PUT",
    data: {
      key: key,
      data: prop,
    },
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    dispatch(participantActions.requestFail(error.message));
    toastError(error.message);
  } else {
    toastSuccess("Profile updated");
    dispatch(participantActions.setParticipant(data));
    dispatch(participantActions.requestSuccess());
  }

  return { data, error };
};

export const getLinkedInProfile = async (url) => {
  dispatch(participantActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/participant/import-linkedin`,
    method: "PUT",
    data: { url },
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    dispatch(participantActions.requestFail(error.message));
    toastError(error.message);
  } else {
    toastSuccess("Profile successfully imported");
    dispatch(participantActions.setParticipant(data));
    dispatch(participantActions.requestSuccess());
  }

  return { data, error };
};
