import React, { useEffect, useRef } from "react";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import "./sendArea.css";
import { useSelector } from "react-redux";
import {
  FiArrowRight,
  FiFile,
  FiFileText,
  FiMusic,
  FiVideo,
  FiPaperclip,
} from "react-icons/fi";
import { MdExpandLess } from "react-icons/md";
import styles from "./sendArea.module.css";
import { useHistory } from "react-router-dom";
function SendArea({
  handleSendMessage,
  loading,
  selectedProject,
  setShowAssetWindow,
  showAssetWindow,
}) {
  const [message, setMessage] = React.useState("");
  const { project } = useSelector((state) => state.currentProject);
  const { darkMode } = useSelector((state) => state.theme);
  const textRef = useRef();
  const onChange = (event) => {
    setMessage(event.target.value);
  };
  const history = useHistory();
  const goToUpload = () => {
    history.push(`/project/${project?._id}/upload`);
  };
  
  const handleClick = () => {
    handleSendMessage(message);
    setMessage("");
  };

  useEffect(() => {
    if (textRef && textRef.current) {
      textRef.current.style.height = "0px";
      const taHeight = textRef.current.scrollHeight + 4;
      textRef.current.style.height = taHeight + "px";
    }

    return () => {
      if (textRef && textRef.current) {
        textRef.current.style.height = "0px";
      }
    };
  }, [message]);

  return (
    <div
      className="form-control"
      style={{
        marginTop: "0.25rem",
        display: "flex",
        width: "100%",
        justifyContent: "center",
        position: "relative",
        padding: "0.5rem 1rem  3rem 1rem",
      }}
    >
      <textarea
        className={styles.scrollbarTextarea}
        ref={textRef}
        onChange={onChange}
        style={{
          resize: "none",
          maxHeight: "100px",
          minHeight: "34px",
          width: "100%",
          height: "100px !important",
          scrollbarWidth: "none",
          background: "var(--backgroundPrimary)",
          borderRadius: "8px",
          color: "var(--primary1)",
          border: "none",
          outline: "none",
          lineHeight: "1rem",
        }}
        placeholder="Ask AI questions about all your files"
        value={message}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault()
            handleSendMessage(message);
            setMessage("");
          }
        }}
      ></textarea>
      <div
        style={{
          position: "absolute",
          left: "1rem",
          bottom: "0.5rem",
          display: "flex",
          alignItems: "center",
          gap: "0.1rem",
          color: `${darkMode ? "white" : "var(--gray)"}`,
          fontSize: "0.8rem",
        }}
      >
        <button
          onClick={() => {
            setShowAssetWindow(!showAssetWindow);
          }}
          style={{
            backgroundColor: "var(--grey3)",
            color: "var(--grey7)",
            display: "flex",
            border: "none",
            fontSize: "0.8rem",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.2rem",
            borderRadius: "0.25rem",
            padding: "0.25rem 0.35rem",
            width: "3.5rem",
            height: "1.5rem",
          }}
        >
          <FiFileText />
          <MdExpandLess
            style={{
              transform: "rotate(180deg)",
            }}
          />
        </button>
        |
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle
            style={{
              height: "1.5rem",
              display: "flex",
              alignItems: "center",
              borderRadius: "0.25rem",
              padding: "0.25rem 0.35rem",
              border: "none",
              fontSize: "0.8rem",
              backgroundColor: "var(--grey3)",
              color: "var(--grey7)",
            }}
            id="dropdown-split-basic"
          >
            <FiPaperclip />
          </Dropdown.Toggle>

          <Dropdown.Menu
            style={{
              border: "none",
              // add box shadow to the button
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              padding: "0.5rem",
            }}
          >
            <Dropdown.Item eventKey="1" onClick={goToUpload}>
              <FiFileText /> Upload Document
            </Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={goToUpload}>
              <FiVideo /> Upload Video
            </Dropdown.Item>
            <Dropdown.Item eventKey="3" onClick={goToUpload}>
              <FiMusic /> Upload Audio
            </Dropdown.Item>
            <Dropdown.Item eventKey="4" onClick={goToUpload}>
              <FiFile /> Upload CSV
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <Button
        type="submit"
        className="ml-2 button-textarea"
        style={{
          color: "white",
          width: "32px !important",
          height: "32px !important",
          position: "absolute",
          right: "0.3rem",
          bottom: "0.5rem",
          borderRadius: "50%",
          marginRight: "4px",
          border: "none",
          transition: "all 0.2s ease-in-out",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 8
        }}
        onClick={handleClick}
        disabled={message.length === 0 || loading}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FiArrowRight />
        </div>
      </Button>
    </div>
  );
}

export default SendArea;
