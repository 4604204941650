import React from "react";
import { ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import getTimeSince from "../../../../utils/getTimeSince";
import style from "./index.module.css";
import { modalActions } from "../../../../store/slices/modalSlice";
import { FiFilm } from "react-icons/fi";
import { BsPlayFill } from "react-icons/bs";
import truncateString from "../../../../utils/truncateString";

function ClipCard({ clip, setSelectedClipId }) {
  const dispatch = useDispatch();
  const projectData = useSelector((state) => state.currentProject.project);
  const asset = projectData.videos.find((asset) => {
    return asset?._id === clip.assetId;
  });

  const tooltip = (
    <Tooltip id="tooltip">
      {clip.clipTags.map((tag, index) => {
        if (index === clip.clipTags.length - 1) {
          return <span key={tag}>{tag}</span>;
        }
        return <span key={tag}>{tag}, </span>;
      })}
    </Tooltip>
  );

  const time = getTimeSince(clip.createdAt)?.toLowerCase();
  const formattedTime = `Created ${time} `;
  return (
    <>
      <div className={style.cardContainer}>
        <div className={style.smallCard}>
          <div
            className={style.imagePart}
            onClick={() => {
              setSelectedClipId(clip?._id);
              dispatch(modalActions.showClipModal());
            }}
            style={{
              backgroundSize: "cover",
            }}
          >
            {asset?.thumbnail ? (
              <img src={asset?.thumbnail} alt="thumbnail" />
            ) : (
              <div className={style.imagePlaceholder}>
                <FiFilm color="var(--grey8)" />
              </div>
            )}
            <div className={style.icon}>
              <BsPlayFill color="var(--grey10)" />
            </div>
          </div>
          <div className={style.textPart}>
            <div className={style.heading}>
              <h1 className={style.cardHeading}>
                {truncateString(clip.title, 15)}
              </h1>
            </div>
            <p>{formattedTime}</p>
            <div className={style.cardMeta}>
              <span className={style.assetAndTags}>
                {truncateString(asset?.name, 15)}
              </span>
              {clip.clipTags[0] && (
                <span className={style.assetAndTags}>
                  {truncateString(clip.clipTags[0], 15)}
                </span>
              )}
              <ButtonToolbar>
                <OverlayTrigger placement="top" overlay={tooltip}>
                  <span style={{ fontSize: "0.8rem" }}>
                    {clip.clipTags?.length > 1 &&
                      `+${clip.clipTags?.length - 1}`}
                  </span>
                </OverlayTrigger>
              </ButtonToolbar>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClipCard;
