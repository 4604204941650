import React from "react";
import { Skeleton } from "@mantine/core";
import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  skeleton: {
    position: "relative",
    overflow: "hidden",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "var(--grey4)", // background for ::before
      zIndex: 0,
    },
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "var(--grey2)", // background for ::after
      zIndex: 1,
    },
  },
}));

const ProcessingState = () => {
  const { classes } = useStyles();

  return (
    <div>
      <Skeleton height={12} radius="xl" className={classes.skeleton} />
      <Skeleton height={12} mt={6} radius="xl" className={classes.skeleton} />
      <Skeleton height={12} mt={6} width="70%" radius="xl" className={classes.skeleton} />

      <br />
      <Skeleton height={12} radius="xl" className={classes.skeleton} />
      <Skeleton height={12} mt={6} radius="xl" className={classes.skeleton} />
      <Skeleton height={12} mt={6} width="70%" radius="xl" className={classes.skeleton} />

      <br />
      <Skeleton height={12} radius="xl" className={classes.skeleton} />
      <Skeleton height={12} mt={6} radius="xl" className={classes.skeleton} />
      <Skeleton height={12} mt={6} width="70%" radius="xl" className={classes.skeleton} />
    </div>
  );
};

export default ProcessingState;
