import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Prompts from "./prompts";
import { MdExpandLess } from "react-icons/md";
import { IoSyncSharp } from "react-icons/io5";

import "./prompts.css";
const aiPromptsSuggestions = {
  flowcharts: [
    "Can you create a flowchart of the main themes from the sessions?",
    "Create a flowchart showing the most common topics in the sessions",
    "Can you draw a flowchart illustrating the key insights from the sessions?",
    "Create a flowchart to illustrate the commonalities across all sessions",
    "Can you create a workflow diagram for a typical user based on the sessions?",
  ],
  barGraphs: [
    "Can you make a bar graph to represent the frequency of key terms discussed?",
    "Create a bar graph to show the feature requests from the participants",
    "Can you create a bar graph demonstrating delighters?",
    "Visualize the pain points from the sessions",
    "Create a bar graph to display the distribution of challenges identified in the sessions",
    "Draw a graph to depict the sentiment trend across the sessions",
  ],
  textSearch: [
    "Search for any dialogue about new product development strategies",
    "Were there any notable conversations around innovative solutions in the text?",
    "Search for any mentions of competitive analysis",
    "Look for conversations about growth opportunities",
    "Can you find instances of the term related to user experience in the sessions?",
    "Can you identify references to customer satisfaction metrics in the sessions?",
    "Can you find instances where user feedback or consumer insights were discussed?",
    "Find any mentions of specific software tools or platforms used by the participants",
  ],
  documentSummaries: [
    "What are the key insights from all sessions?",
    "Make a table of commonalities in the sessions",
    "What were the pain points mentioned in the sessions?",
    "Can you highlight the most critical points from all sessions?",
    "What are the most discussed topics in these sessions?",
    "Could you extract the main ideas from all sessions?",
    "What were the recurring themes across all sessions?",
    "What issues were most frequently raised?",
    "Highlight the most controversial points discussed",
    "What were the users' suggestions for improvement?",
    "What were the common concerns across all sessions?",
    "What topics dominated the discussion?",
    "What areas need further exploration?",
    "What were the users' reactions to the new proposal?",
    "Summarize the main points of disagreement",
    "What were the major outcomes of the sessions?",
  ],

  tableQueries: [
    "Can you generate a table of the key topics discussed in each session?",
    "Create a table listing the major decisions made in each session.",
    "Can you compile a table of the main action items from all the sessions?",
    "Produce a table showing the frequency of specific terms mentioned in the sessions.",
    "Can you create a table of all the mentioned product features and their associated feedback?",
    "Generate a table illustrating the user pain points identified in the sessions.",
    "Can you create a table listing the user suggestions and their frequency of mention?",
    "Develop a table detailing the most controversial topics from the sessions.",
    "Can you create a table showing the distribution of discussion topics across sessions?",
    "Make a table of the most influential contributors in the sessions.",
    "Can you create a table of the common themes and their occurrence in each session?",
    "Develop a table of the common concerns across all sessions and their frequency.",
    "Can you make a table of the most discussed topics in these sessions?",
    "Create a table showing the agreed next steps from each session.",
    "Can you generate a table of the users' reactions to key proposals?",
    "Create a table showing the main points of disagreement and their frequency.",
    "Can you compile a table of the most frequently raised issues across all sessions?",
    "Generate a table listing the key insights from each session.",
    "Can you make a table of the areas identified for further exploration?",
    "Create a table showing the major outcomes of each session.",
  ],
};

function PromptSuggestions({ handleSendMessage, showPromptSuggestion,setShowPromptSuggestion }) {
  const { darkMode } = useSelector((state) => state.theme);

  const [prompts, setprompts] = useState([]);
 
  function getRandomPrompts() {
    const categories = Object.keys(aiPromptsSuggestions);
    const chosenCategories = [];

    while (chosenCategories.length < 3) {
      const randomIndex = Math.floor(Math.random() * categories.length);
      if (!chosenCategories.includes(categories[randomIndex])) {
        chosenCategories.push(categories[randomIndex]);
      }
    }

    const prompts = chosenCategories.map((category) => {
      const promptsArray = aiPromptsSuggestions[category];
      const randomPromptIndex = Math.floor(Math.random() * promptsArray.length);
      return promptsArray[randomPromptIndex];
    });

    return prompts;
  }
  useEffect(() => {
    setprompts(getRandomPrompts());
  }, []);

  return (
    <div
      className={`prompt-suggestion `}
      style={{
        flexDirection: "column",
        backgroundColor: darkMode ? "#161A1D" : "#fff",
      }}
    >
      <button
        className="hide-prompt-suggestion"
        onClick={() => {
          setShowPromptSuggestion((state) => !state);
        }}
      >
        {showPromptSuggestion ? "Hide suggestions" : "Show suggestions"}

        {showPromptSuggestion ? (
          <MdExpandLess
            style={{
              width: "1.2rem",
              height: "1rem",
              transform: "rotate(180deg)",
            }}
          />
        ) : (
          <MdExpandLess
            style={{
              width: "1.2rem",
              height: "1rem",
            }}
          />
        )}
      </button>

      <div
        className="suggestions-container "
        style={{
          display: showPromptSuggestion ? "flex" : "none",
          gap: "4px",
          overflowX: "scroll",
        }}
        ref={(node) => {
          if (node) {
            setTimeout(() => {
              node.classList.add("prompt-pop-up");
            }, 10);
          }
        }}
      >
        {prompts.map((prompt, index) => (
          <Prompts
            key={index}
            handleSendMessage={handleSendMessage}
            prompt={prompt}
            show={showPromptSuggestion}
            setShow={setShowPromptSuggestion}
          />
        ))}
        <button
          className="refresh-prompt-suggestion"
          onClick={() => {
            setprompts(getRandomPrompts());
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IoSyncSharp
              style={{
                width: "1rem",
                height: "1rem",
                color: `${darkMode ? "white" : "000"}`,
              }}
            />
          </div>
        </button>
      </div>
    </div>
  );
}

export default PromptSuggestions;
