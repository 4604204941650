import { toast } from "react-toastify";
import store from "..";
import { callExternalApi } from "../../services/external-api.service";
import { adminActions } from "../slices/adminSlice";
import { authActions } from "../slices/authSlice";
import { loadProjectsAction } from "./projectActions";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

const { dispatch } = store;

export const deleteUser = async (userId) => {
  const config = {
    url: `${apiServerUrl}/api/admin/user/${userId}`,
    method: "DELETE",
    withCredentials: true,
  };
  const { data, error } = await callExternalApi({ config });
  if (error) {
    dispatch(adminActions.requestFail(error.message));
    toast.error(error.message);
  }
  if (data) {
    toast.success("User deleted successfully");
    dispatch(adminActions.removeUser(userId));
  }
};
export const handleSignInAS = async (userId, onSignInSuccess) => {
  const config = {
    url: `${apiServerUrl}/api/admin/signInAsUser`,
    method: "POST",
    data: { userId },
    withCredentials: true,
  };
  const { data, error } = await callExternalApi({ config });
  if (error) {
    console.error("Failed to sign in as user:", error);
  }
  if (data) {
    const accessToken = data?.accessToken;
    dispatch(authActions.setAccessToken(accessToken));

    dispatch(
      authActions.getAccount({
        account: data?.user,
        accessToken,
      })
    );
    await loadProjectsAction();
    onSignInSuccess();
  }
};
export const getUsers = async () => {
  const config = {
    url: `${apiServerUrl}/api/admin/users`,
    method: "GET",
  };

  const { data, error } = await callExternalApi({ config });
  if (data) {
    dispatch(adminActions.setUsers(data));
  }
  if (error) {
    dispatch(adminActions.requestFail(error.message));
  }
};
export const degradeUser = async (email) => {
  const config = {
    url: `${apiServerUrl}/api/admin/degradeUser`,
    method: "PUT",
    data: { email },
  };
  const { data, error } = await callExternalApi({ config });
  if (error) {
    toast.error(error.message);
    dispatch(adminActions.requestFail(error.message));
  }
  if (data) {
    dispatch(adminActions.degradeUser(email));
  }
};

export const getParticipants = async () => {
  const config = {
    url: `${apiServerUrl}/api/admin/participants`,
    method: "GET",
  };

  const { data, error } = await callExternalApi({ config });
  if (data) {
    dispatch(adminActions.setParticipants(data));
  }
  if (error) {
    dispatch(adminActions.requestFail(error.message));
  }
};
export const verifyUser = async (userId, type) => {
  const config = {
    url: `${apiServerUrl}/api/admin/verifyUser`,
    method: "PUT",
    data: { userId, type },
  };
  const { data, error } = await callExternalApi({ config });
  if (error) {
    toast.error(error.message);
    dispatch(adminActions.requestFail(error.message));
  }
  if (data) {
    toast.success("User verified successfully");
    dispatch(adminActions.verifyUser({ userId, type }));
  }
};

export const changePlanUser = async (userId, planType, type) => {
  const config = {
    url: `${apiServerUrl}/api/admin/changePlan`,
    method: "PUT",
    data: { userId, planType, type },
  };
  const { data, error } = await callExternalApi({ config });
  if (error) {
    toast.error(error.message);
    dispatch(adminActions.requestFail(error.message));
  }
  if (data) {
    let plan = data.plan;
    toast.success("User plan changed successfully");
    dispatch(adminActions.changeUserPlan({ userId, plan }));
  }
};

export const makeAdmin = async (userId) => {
  const config = {
    url: `${apiServerUrl}/api/admin/makeAdmin`,
    method: "PUT",
    data: { userId },
  };
  const { data, error } = await callExternalApi({ config });
  if (error) {
    toast.error(error.message);
    dispatch(adminActions.requestFail(error.message));
  }
  if (data) {
    toast.success("User made admin successfully");
    dispatch(adminActions.makeUserAdmin({ userId }));
  }
};
export const removeAdmin = async (userId) => {
  const config = {
    url: `${apiServerUrl}/api/admin/removeAdmin`,
    method: "PUT",
    data: { userId },
  };
  const { data, error } = await callExternalApi({ config });
  if (error) {
    toast.error(error.message);
    dispatch(adminActions.requestFail(error.message));
  }
  if (data) {
    toast.success("Admin removed successfully");
    dispatch(adminActions.removeAdmin({ userId }));
  }
};
