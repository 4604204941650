// ChartTool.js
import { v4 as uuidv4 } from 'uuid';
import { Chart, PieController, ArcElement, CategoryScale, Tooltip, Legend } from 'chart.js';
import PieChartModel from './model';

Chart.register(PieController, ArcElement, CategoryScale, Tooltip, Legend);

export default class ChartTool {
  constructor({ data, config, api, readOnly }) {
    this.data = data;
    this.config = config;
    this.api = api;
    this.readOnly = config.readOnly || false;
  
    // If data, data.labels or data.data is not provided, initialize with empty arrays
    if (!this.data || !this.data.labels || !this.data.data) {
      this.data = {
        labels: [],
        data: [],
      };
    }
  
    this.chartModel = new PieChartModel(this.data);
  
    if (!this.chartModel.id) {
      this.chartModel.id = uuidv4();
    }
  
    this.container = document.createElement('div');
    this.container.id = this.chartModel.id;
    this.container.style.width = '80%';
    this.container.style.margin = '0 auto';
    
    // If the chart data and labels are empty, start in edit mode
    this.chartModel.isEditing = this.data.labels.length === 0 && this.data.data.length === 0;
  }

    /**
   * Allow to use this tool in read only mode
   */
    static get isReadOnlySupported() {
      return true;
    }

  static get toolbox() {
    return {
      icon: '<svg stroke="currentColor" style="padding: 1px;" fill="currentColor" stroke-width="0" viewBox="0 0 256 256" height="24px" width="24px" xmlns="http://www.w3.org/2000/svg"><path d="M128,26A102,102,0,1,0,230,128,102.12,102.12,0,0,0,128,26Zm74.74,51.92L134,117.61V38.2A90,90,0,0,1,202.74,77.92ZM122,38.2v86.34L47.24,167.7A90,90,0,0,1,122,38.2ZM128,218a90,90,0,0,1-74.74-39.92L208.76,88.3A90,90,0,0,1,128,218Z"></path></svg>',
      title: "Chart",
    };
  }

  render() {
    // clear the container
    this.container.innerHTML = '';

    if (!this.chartModel.isEditing) {
      // Render the Chart.js chart
      const canvas = document.createElement('canvas');
      this.container.appendChild(canvas);
      // timeout before rendering the chart to ensure the canvas is ready
      // setTimeout(() => {
      this.chartInstance = new Chart(canvas.getContext('2d'), {
        type: 'pie',
        data: {
          labels: this.chartModel.labels,
          datasets: [{
            data: this.chartModel.data,
            backgroundColor: [
              "rgba(255, 179, 186, 1)",
              "rgba(255, 209, 220, 1)",
              "rgba(255, 229, 217, 1)",
              "rgba(255, 253, 191, 1)",
              "rgba(209, 220, 255, 1)",
              "rgba(179, 186, 255, 1)",
              "rgba(186, 255, 201, 1)",
              "rgba(220, 255, 209, 1)",
              "rgba(217, 255, 229, 1)",
              "rgba(191, 253, 255, 1)",
              "rgba(209, 255, 220, 1)",
              "rgba(186, 255, 179, 1)",
              "rgba(255, 229, 217, 1)",
              "rgba(253, 255, 191, 1)",
              "rgba(220, 255, 209, 1)",
              "rgba(186, 255, 201, 1)",
              "rgba(255, 209, 220, 1)",
              "rgba(255, 179, 186, 1)",
              "rgba(229, 217, 255, 1)",
              "rgba(191, 253, 255, 1)"
            ],
          }]
        }
      });
      // }, 1000);
    } else {
      // Create input boxes for labels and data if not in readOnly mode
      const labelsInput = document.createElement('input');
      labelsInput.value = this.chartModel.labels.join(', ');
      // Placeholders for labels
      labelsInput.placeholder = 'Comma separated labels';
      labelsInput.style.border = '1px solid #ccc';
      labelsInput.style.borderRadius = '4px';
      labelsInput.style.padding = '4px';
      labelsInput.style.marginRight = '4px';
      labelsInput.style.minWidth = '200px';
      this.container.appendChild(labelsInput);

      const dataInput = document.createElement('input');
      dataInput.value = this.chartModel.data.join(', ');
      // Placeholders for data
      dataInput.placeholder = 'Comma separated numbers';
      dataInput.style.border = '1px solid #ccc';
      dataInput.style.borderRadius = '4px';
      dataInput.style.padding = '4px';
      dataInput.style.marginRight = '4px';
      dataInput.style.minWidth = '200px';
      this.container.appendChild(dataInput);

      // Create update button
      const updateButton = document.createElement('button');
      updateButton.innerText = 'Update Chart';
      // Add styles here
      updateButton.style.background = '#0085ff'; // background color
      updateButton.style.color = '#FFF'; // text color
      updateButton.style.border = 'none'; // remove border
      updateButton.style.padding = '4px 8px'; // padding
      updateButton.style.borderRadius = '8px'; // rounded corners
      updateButton.style.cursor = 'pointer'; // change cursor to pointer when hovering
      updateButton.style.fontFamily = 'Inter, Arial, sans-serif'; // font
      updateButton.style.fontSize = '14px'; // font size
      updateButton.style.marginTop = '4px'; // margin top
      updateButton.addEventListener('click', () => {
        const labels = labelsInput.value.split(',').map(label => label.trim());
        const data = dataInput.value.split(',').map(value => Number(value.trim()));

        this.chartModel.labels = labels;
        this.chartModel.data = data;
        this.chartModel.isEditing = false;
        this.render();
      });
      this.container.appendChild(updateButton);
    }

    return this.container;
  }

  save(blockContent) {
    return this.chartModel;
  }

  renderSettings() {
    const button = document.createElement('button');
    button.innerHTML = 'Chart Settings';
    
    // Add styles here
    button.style.background = '#0085ff'; // background color
    button.style.color = '#FFF'; // text color
    button.style.border = 'none'; // remove border
    button.style.padding = '8px 12px'; // padding
    button.style.borderRadius = '8px'; // rounded corners
    button.style.cursor = 'pointer'; // change cursor to pointer when hovering
    button.style.fontFamily = 'Inter, Arial, sans-serif'; // font
    button.style.fontSize = '14px'; // font size
    
    button.addEventListener('click', () => {
      this.chartModel.isEditing = !this.chartModel.isEditing;
      this.render();
    });

    if (!this.readOnly) {
      return button;
    } else {
      return null;
    }
  }
}
