import React, { useEffect, useState } from "react";
import TagsInput from "react-tagsinput";
import { toastError } from "../../../utils/toast";
import styles from "./custom.module.css";
import { sendKeyWords } from "../../../store/actions/authActions";
import { useSelector } from "react-redux";
import { getOrganizationById } from "../../../store/actions/organisationActions";
const CustomVocabulary = ({ account }) => {
  const [keywords, setKeywords] = useState([]);
  const { organization } = useSelector((state) => state.organization);
  useEffect(() => {
    if (account.organization) {
      const fetchOrganization = async (org) => {
        await getOrganizationById(org);
      };
      fetchOrganization(account.organization);
    }
  }, []);
  useEffect(() => {
    organization.length > 0 &&
      organization[0].customVocabulary &&
      setKeywords(organization[0].customVocabulary);
  }, [organization]);

  return (
    <section className={styles.container}>
      <h3>Account custom vocabulary</h3>
      <p>
        To increase transcription accuracy, add terms or phrases, such as
        company or industry jargon. You can add up to 100 terms.
      </p>
      <div className={styles.tagInputContainer}>
        <TagsInput
          type="text"
          inputProps={{
            placeholder: "Type to add more",
          }}
          value={keywords}
          onChange={(keywords) => {
            setKeywords(keywords);
            const send = async () => {
              await sendKeyWords({ keywords: keywords });
            };
            send();
          }}
          onlyUnique
          onValidationReject={(keywords) =>
            toastError(`Invalid keywords : ${keywords.join(", ")}`)
          }
        />
      </div>
      <hr></hr>
    </section>
  );
};

export default CustomVocabulary;
