/* eslint-disable no-unused-vars */
import React from "react";
import { MdModeEditOutline } from "react-icons/md";
import { TestLayout } from "../components/test-layout";
import style from "./index.module.css";
import BottomBar, { handleStep } from "../components/bottom-bar";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { createLimeLineMeeting } from "../../../../store/actions/testActions";
import { FaRegFileAlt } from "react-icons/fa";

function ACI_Review({ match }) {
  const { currentTest } = useSelector((state) => state.test);
  const {
    account: { engagePlan = {} },
  } = useSelector((state) => state.auth);
  const history = useHistory();
  const [disableLaunch, setDisableLaunch] = React.useState(false);

  const { total: aiInterviewsUETotal, used: aiInterviewsUEUsed } =
    engagePlan.aiInterviewsUEPool;
  const { total: aiInterviewsOwnTotal, used: aiInterviewsOwnUsed } =
    engagePlan.aiInterviewsOwnParticipants;

  const totalCredits =
    aiInterviewsOwnTotal -
    aiInterviewsOwnUsed +
    aiInterviewsUETotal -
    aiInterviewsUEUsed;

  return (
    <TestLayout stepNumber={7} testId={match.params.id}>
      <div className={style.wrapper}>
        <div className={style.title}>Review all Information</div>
        <div className={style.subtitle}>Review and Pay</div>
        <div className={style.main}>
          <div className={style.content}>
            <div className={style.reviewSection}>
              <div className={style.review}>
                <div className={style.reviewTitle}>Test Name</div>
                <div className={style.reviewValue}>
                  {currentTest.title || "Test Name"}
                </div>
              </div>
              <div className={style.review}>
                <div className={style.reviewTitle}>Type of test</div>
                <div className={style.reviewValue}>AI-Curated Interview</div>
              </div>
              <div className={style.review}>
                <div className={style.reviewTitle}>Time allocated</div>
                <div className={style.reviewValue}>
                  {currentTest.duration} minutes
                </div>
                <MdModeEditOutline
                  className={style.editIcon}
                  onClick={() => history.push(handleStep(6, match.params.id))}
                />
              </div>
              {currentTest.invitedParticipants?.length > 0 && (
                <div className={style.review}>
                  <div className={style.reviewTitle}>Audience</div>
                  <div className={style.reviewValue}>
                    {currentTest.invitedParticipants?.join(", ")}
                  </div>
                  <MdModeEditOutline
                    className={style.editIcon}
                    onClick={() => history.push(handleStep(3, match.params.id))}
                  />
                </div>
              )}
              <div className={style.review}>
                <div className={style.reviewTitle}>Screener Survey</div>
                <div className={style.reviewValue}>
                  <strong>
                    {currentTest.questions?.length || 0} questions
                  </strong>
                  {currentTest.questions?.length ? (
                    currentTest.questions?.map((question, index) => (
                      <li key={index}>{question?.label}</li>
                    ))
                  ) : (
                    <li>No questions added</li>
                  )}
                </div>
                <MdModeEditOutline
                  className={style.editIcon}
                  onClick={() => history.push(handleStep(4, match.params.id))}
                />
              </div>
              <div className={style.review}>
                <div className={style.reviewTitle}>Participants</div>
                <div className={style.reviewValue}>
                  <strong>{currentTest.nParticipants} Participants</strong>
                  {/* <li>${currentTest.reward} incentive per participant</li> */}
                </div>
                <MdModeEditOutline
                  className={style.editIcon}
                  onClick={() => history.push(handleStep(6, match.params.id))}
                />
              </div>
              {/* <div className={style.review}>
                <div className={style.reviewTitle}>AI Interviewer</div>
                <div className={style.reviewValue}>
                  {currentTest.accent},{" "}
                  {currentTest.voice === "m" ? "Male" : "Female"}
                </div>
                <MdModeEditOutline
                  className={style.editIcon}
                  onClick={() => history.push(handleStep(6, match.params.id))}
                />
              </div> */}
              <div className={style.review}>
                <div className={style.reviewTitle}>Documents for Signature</div>
                <div className={style.reviewValue}>
                  {currentTest.ndaLink ? (
                    <a
                      href={currentTest.ndaLink}
                      target="_blank"
                      rel="noreferrer"
                      className={style.link}
                    >
                      <FaRegFileAlt />
                      <span>View document</span>
                    </a>
                  ) : (
                    "-"
                  )}
                </div>
                <MdModeEditOutline
                  className={style.editIcon}
                  onClick={() => history.push(handleStep(6, match.params.id))}
                />
              </div>
              <div className={style.review}>
                <div className={style.reviewTitle}>Deadline</div>
                <div className={style.reviewValue}>
                  {dayjs(currentTest.deadline).format("MMMM DD, YYYY")}
                </div>
                <MdModeEditOutline
                  className={style.editIcon}
                  onClick={() => history.push(handleStep(6, match.params.id))}
                />
              </div>
              <div className={style.review}>
                <div className={style.reviewTitle}>Intro & Outro</div>
                <div className={style.reviewValue}>
                  <strong>Intro</strong>
                  <li>{currentTest.instructions}</li>
                  <strong>Outro</strong>
                  <li>{currentTest.endNote}</li>
                </div>
                <MdModeEditOutline
                  className={style.editIcon}
                  onClick={() => history.push(handleStep(6, match.params.id))}
                />
              </div>
            </div>
            <div className={style.paySection}>
              <div className={style.payCalc}>
                <strong>Pricing Breakdown</strong>
                <div className={style.payDetails}>
                  <div className={style.payTitle}>
                    Interviews Credits Available
                  </div>
                  <div className={style.payValue}>{totalCredits}</div>
                </div>
                <div className={style.payDetails}>
                  <div className={style.payTitle}>
                    Interviews Credits Deducted
                  </div>
                  <div className={style.payValue}>
                    {currentTest.nParticipants}
                  </div>
                </div>
                {/* <div className={style.payDetails}>
                  <div className={style.payTitle}>
                    User Evaluation&apos;s Cost
                  </div>
                  <div className={style.payValue}>$ 2.5</div>
                </div> */}
                <div className={style.payDetails}>
                  <div className={style.payTitle}>
                    <strong>
                      Remaining Credits after Launching this Project
                    </strong>
                  </div>
                  <div className={style.payValue}>
                    <strong>{totalCredits - currentTest.nParticipants}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BottomBar
          currentStep={7}
          primaryButton="Pay & Launch"
          testId={match.params.id}
          onPrimaryButtonClick={() => {
            setDisableLaunch(true);

            createLimeLineMeeting(match.params.id).then(({ error }) => {
              if (error) {
                setDisableLaunch(false);
                return;
              }

              history.push(`/interview/${match.params.id}/progress`);
            });
          }}
          primaryButtonLoading={disableLaunch}
        />
      </div>
    </TestLayout>
  );
}

export default ACI_Review;
