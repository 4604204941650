import React, { useEffect } from "react";

import { BoardTab } from "../../../../components/board/board";
import { useDispatch, useSelector } from "react-redux";
import {
  getTagNames,
  getTagsByProjectId,
} from "../../../../store/actions/tagsActions";
import { Spinner } from "react-bootstrap";
import { useState } from "react";
import EmptyState from "./emptyState";
import { tagsActions } from "../../../../store/slices/tagsSlice";
import { getProjectForPublicResource } from "../../../../store/actions/projectActions";

function Tags({ newProject, id }) {
  const { tags } = useSelector((state) => state.tags);
  const [loading, setLoading] = useState(true);
  const projectData = useSelector((state) => state.currentProject.project);
  const [tagNames, setTagNames] = useState([]);
  const { isPublic } = useSelector((state) => state.currentProject);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isPublic) {
      getTagsByProjectId(projectData?._id);
      setLoading(false);
    }
    if (id && isPublic && projectData?._id) {
      
      const fetchData = async () => {
        const { data, error } = await getProjectForPublicResource({
          linkId: id,
          id: projectData?._id,
          type: "tags",
        });
        if (data) {
          setTagNames(data?.tagNames);
          dispatch(tagsActions.getTags({ tags: data.tags }));

          setLoading(false);
        }
        if (error) {
          console.error(error);
        }
      };
      fetchData();
    }
  }, [isPublic, projectData?._id]);
  useEffect(() => {
    const fetchData = async () => {
      const newTagNames = await getTagNames(projectData?._id);
      setTagNames(newTagNames);
    };
    if (!isPublic) {
      fetchData();
    }
  }, [projectData?._id, tags, isPublic]);

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          {tags?.length === 0 ? (
            <>
              <EmptyState />
            </>
          ) : (
            <BoardTab
              newProject={newProject}
              projectData={projectData}
              isTag={true}
              tags={tags}
              tagNames={tagNames}
            />
          )}
        </>
      )}
    </>
  );
}

export default Tags;
