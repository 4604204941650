import { Form, Modal, ProgressBar } from "react-bootstrap";
import { useSelector } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import { BsStars } from "react-icons/bs";
import styles from "./index.module.css";
import { useDispatch } from "react-redux";
import { toastError } from "../../../../utils/toast";
import { modalActions } from "../../../../store/slices/modalSlice";
import { getTagsByProjectId } from "../../../../store/actions/tagsActions";
import useSocketContext from "../../../../utils/SocketContext";
import aiTagsImage from "../../../../images/aitagsImg.svg";
function AITagsModal({ assetId, projectId }) {
  const [instruction, setInstruction] = useState("");
  const dispatch = useDispatch();
  const { AITag: show } = useSelector((state) => state.modal);
  const [progressPercent, setProgressPercent] = useState(0);
  const [progressMessage, setProgressMessage] = useState("");
  const socketHandlers = useCallback(
    {
      aiTagsUpdate: (payload) => {
        switch (payload.status) {
          case "Error":
            console.error(payload.message);
            toastError(payload.message);
            setProgressPercent(0);
            setProgressMessage("");
            dispatch(modalActions.hideAITagModal());
            break;
          case "Processing":
            setProgressPercent(payload.progress);
            setProgressMessage(payload.message);
            break;
          case "Success":
            getTagsByProjectId(projectId);
            setProgressPercent(0);
            setProgressMessage("");
            dispatch(modalActions.hideAITagModal());
            break;
          default:
            console.warn("Unknown reportUpdate status:", payload.status);
        }
      },
    },
    []
  );
  const socket = useSocketContext();
  useEffect(() => {
    if (socket) {
      socket.attachHandlers(socketHandlers);
    }

    return () => socket.detachHandlers(socketHandlers);
  }, [socket, socketHandlers]);

  return (
    <Modal
      show={show}
      centered
      backdrop="static"
      size="lg"
      className="pay-modal-container"
      onHide={() => {
        dispatch(modalActions.hideAITagModal());
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>AI Tags</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {progressPercent === 0 && progressMessage === "" && (
          <div>
            <div className={styles.instructionContainer}>
              <div className={styles.aiTagImgContainer}>
                <img src={aiTagsImage} alt="aiTagsImage" />
              </div>
              <div className={styles.aiTagTextContainer}>
                <p className="my-2">
                  AI tagging is an automated way to add tags to your transcript.
                </p>
                <h4 className="mt-2">Instructions</h4>
                <Form.Control
                  className="my-2 p-3"
                  as="textarea"
                  rows={5}
                  placeholder="Type instructions for the AI to generate your tags. Your instructions may also include the exact tags you want included."
                  style={{
                    resize: "none",
                  }}
                  onChange={(e) => {
                    setInstruction(e.target.value);
                  }}
                  value={instruction}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                className={`btn btn-outline-primary mx-2 ${styles.withoutInstructionBtn}`}
                onClick={() => {
                  socket.createAiTags(assetId, projectId, "");
                  setInstruction("");
                }}
              >
                <BsStars />
                Generate without instructions
              </button>
              <button
                className={`btn btn-primary`}
                onClick={() => {
                  socket.createAiTags(assetId, projectId, instruction);
                  setInstruction("");
                }}
                disabled={instruction === ""}
              >
                Generate Tags
              </button>
            </div>
          </div>
        )}

        {progressPercent !== 0 && (
          <>
            <p className="mt-2">Please wait till the AI generates your tags</p>
            <ProgressBar
              striped
              animated
              variant="info"
              now={progressPercent}
              className="mt-3"
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default AITagsModal;
