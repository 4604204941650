import React from "react";
import { Container } from "react-bootstrap";
import styles from "./style.module.css";
import { LuFile } from "react-icons/lu";
export default function EmptyState() {
  return (
    <Container className="d-flex align-items-center justify-content-center h-100">
      <div className="text-center">
        <div className={styles.icon}>
          <LuFile />
        </div>
        <br />
        <h1 className="processing-heading">No files added yet</h1>
        <p
          className="processing-paragraph"
          style={{ maxWidth: "60%", margin: "auto", lineHeight: "1.3rem" }}
        >
          You have not uploaded any files yet. Add files here or to a project to
          get started.
        </p>
      </div>
    </Container>
  );
}
