import React from "react";
import styles from "./index.module.css";
function PaginationDotted({ number }) {
  const dots = [0, 1, 2];
  return (
    <div>
      <div className={styles.pagination_dotted}>
        {dots.map((dot, index) => (
          <div
            key={index}
            className={`${styles.pagination_dot} ${
              index === number ? styles.active : ""
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default PaginationDotted;
