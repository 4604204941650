import store from "..";
import { testActions } from "../slices/testSlice";
import { callExternalApi } from "../../services/external-api.service";
import { toastError, toastSuccess } from "../../utils/toast";

const { dispatch } = store;

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getAllTests = async () => {
  dispatch(testActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/tests/all`,
    method: "GET",
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    console.log(error);
    dispatch(testActions.requestFail(error.message));
    toastError(error.message);
  } else {
    dispatch(testActions.setAllTests({ allTests: data }));
    dispatch(testActions.requestSuccess());
  }
};

export const getTestById = async (id) => {
  dispatch(testActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/tests/${id}`,
    method: "GET",
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    console.log(error);
    dispatch(testActions.requestFail(error.message));
    toastError(error.message);
  } else {
    dispatch(testActions.updateCurrentTest(data));
    dispatch(testActions.requestSuccess());
  }
};

export const uploadNDA = async (id, formData) => {
  dispatch(testActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/tests/${id}/upload-nda`,
    method: "PUT",
    data: formData,
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    console.log(error);
    dispatch(testActions.requestFail(error.message));
    toastError(error.message);
  } else {
    toastSuccess("Document uploaded successfully.");
    dispatch(testActions.updateCurrentTest(data.data));
    dispatch(testActions.requestSuccess());
  }
};

// this will only update the invitedParticipants array in db,
// mails will be sent after the test is live
export const sendInviteMail = async (testId, emails) => {
  dispatch(testActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/tests/send-invite`,
    method: "PUT",
    data: { id: testId, emails },
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    console.log(error);
    dispatch(testActions.requestFail(error.message));
  } else {
    dispatch(testActions.requestSuccess());
    dispatch(testActions.updateCurrentTest(data.data));
  }

  return { data, error };
};

export const getBasicTestDetails = async (id) => {
  dispatch(testActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/tests/basics/${id}`,
    method: "GET",
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    dispatch(testActions.requestFail(error.message));
  } else {
    dispatch(testActions.updateCurrentTest(data));
    dispatch(testActions.requestSuccess());
  }

  return { data, error };
};

export const getTestProgress = async (id) => {
  dispatch(testActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/tests/progress/${id}`,
    method: "GET",
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    console.log(error);
    dispatch(testActions.requestFail(error.message));
    toastError(error.message);
  } else {
    dispatch(testActions.setCurrentTestProgress(data));
    dispatch(testActions.requestSuccess());
  }
};

export const getTestParticipants = async (id) => {
  dispatch(testActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/tests/participants/${id}`,
    method: "GET",
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    console.log(error);
    dispatch(testActions.requestFail(error.message));
    toastError(error.message);
  } else {
    dispatch(testActions.setCurrentTestParticipants(data));
    dispatch(testActions.requestSuccess());
  }
};

export const getPastParticipants = async () => {
  dispatch(testActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/tests/past-participants`,
    method: "GET",
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    console.log(error);
    dispatch(testActions.requestFail(error.message));
    toastError(error.message);
  } else {
    dispatch(testActions.setPastParticipants(data.pastParticipants));
    dispatch(testActions.requestSuccess());
  }
};

export const createLimeLineMeeting = async (testId) => {
  dispatch(testActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/tests/create-meeting`,
    method: "POST",
    data: { testId },
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    console.log(error);
    dispatch(testActions.requestFail(error.message));
    toastError(error.message);
  } else {
    toastSuccess("Test created successfully!");
    dispatch(testActions.requestSuccess());
  }

  return { data, error };
};

export const joinTest = async (testId) => {
  dispatch(testActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/tests/join-test/${testId}`,
    method: "PUT",
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    toastError(error.message);
    dispatch(testActions.requestFail(error.message));
  } else {
    console.log(data);
    dispatch(testActions.updateCurrentTest(data.data));
    dispatch(testActions.requestSuccess());
  }

  return { data, error };
};

export const removeParticipantFromOrganization = async (participantId) => {
  dispatch(testActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/tests/remove-from-organization`,
    method: "DELETE",
    data: { id: participantId },
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    toastError(error.message);
    dispatch(testActions.requestFail(error.message));
  } else {
    toastSuccess("Participant removed successfully.");
    dispatch(testActions.requestSuccess());
    dispatch(testActions.removeParticipantFromOrg(participantId));
  }

  return { data, error };
};

export const checkAnswers = async (testId, answers) => {
  dispatch(testActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/tests/check-answers/${testId}`,
    method: "POST",
    data: { answers },
  };

  return await callExternalApi({ config });
};

export const changeTitle = async (testId, title) => {
  dispatch(testActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/tests/change-name`,
    method: "PUT",
    data: { testId, title },
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    toastError(error.message);
    dispatch(testActions.requestFail(error.message));
  } else {
    dispatch(testActions.updateCurrentTest(data.data));
    dispatch(testActions.requestSuccess());
  }

  return { data, error };
};

export const changeDeadline = async (testId, deadline) => {
  dispatch(testActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/tests/change-deadline`,
    method: "PUT",
    data: { testId, deadline },
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    toastError(error.message);
    dispatch(testActions.requestFail(error.message));
  } else {
    dispatch(testActions.updateCurrentTest(data.data));
    dispatch(testActions.requestSuccess());
  }

  return { data, error };
};

export const changeProjectId = async (testId, projectId) => {
  dispatch(testActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/tests/change-project`,
    method: "PUT",
    data: { testId, projectId },
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    toastError(error.message);
    dispatch(testActions.requestFail(error.message));
  } else {
    dispatch(testActions.updateCurrentTest(data.data));
    dispatch(testActions.requestSuccess());
  }

  return { data, error };
};
