import React, { useState, useContext, createContext, useEffect } from "react";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Menu, Modal } from "@mantine/core";
import { Button, Form, Row, Col, Container, Spinner } from "react-bootstrap";
import { FiMoreHorizontal } from "react-icons/fi";
import { MdOutlineViewKanban } from "react-icons/md";
import TagsInput from "react-tagsinput";
import { useSelector, useDispatch } from "react-redux";
import { debounce } from "lodash";
import {
  loadLanesAction,
  loadPublicLanesAction,
  deleteCardAction,
  deleteLaneAction,
  createLaneAction,
  createCardAction,
  moveCardAction,
  updateLaneAction,
  updateCardAction,
} from "../../store/actions/laneActions";
import "react-tagsinput/react-tagsinput.css";
import {
  deleteTagAction,
  moveTagCard,
  updateTagTitleAction,
} from "../../store/actions/tagsActions";
import { ribbonActions } from "../../store/slices/ribbonSlice";
import TagsChart from "./components/tagsChart";
import Toggle from "./components/toggle";
import EditableLaneTitle from "./components/editableLaneTitle";
import DeleteTagModal from "./components/deleteTagModal";
import "./board.styles.css";
import TagPopUp from "./components/tagPopUp";
import DropDown from "./components/collectionCardSectionDropDown";
const BoardContext = createContext();

const useBoard = () => {
  return useContext(BoardContext);
};

const CardPopup = ({
  show,
  onHide,
  insight,
  source,
  notes,
  type,
  onUpdate,
  onDelete,
  projectName,
  laneName,
  publicBoard,
  filteredLanes,
  handleMove,
}) => {
  const [inputType, setInputType] = useState(type);

  const handleChange = (value) => {
    setInputType(value);
    onUpdate("type", value);
  };

  return (
    <Modal
      size="xl"
      opened={show}
      onClose={(e) => {
        e?.stopPropagation();
        onHide(false);
      }}
      title="Insight"
    >
      <Row className="project-board-row">
        <Col xs={3} className="project-overview-label">
          Project
        </Col>
        <Col className="project-overview-value">{projectName}</Col>
      </Row>

      <Row className="project-board-row" style={{ flexWrap: "no-wrap" }}>
        <Col xs={3} className="project-overview-label">
          Section
        </Col>

        <Col className="project-overview-value" style={{width:"75%"}}>
          {publicBoard ? (
            laneName
          ) : (
            <DropDown
              differentLanes={filteredLanes}
              sectionTitle={laneName}
              handleMove={handleMove}
            />
          )}
        </Col>
      </Row>

      <Row
        className="project-board-row"
        style={{
          padding: "2px 0px",
          marginTop: "6px",
        }}
      >
        <Col
          xs={3}
          className="project-overview-label"
          style={{
            lineHeight: "42px",
          }}
        >
          Tags
        </Col>
        <Col className="project-overview-value">
          {!publicBoard ? (
            <TagsInput value={inputType} onChange={handleChange} />
          ) : (
            <span style={{ paddingTop: "10px", display: "block" }}>
              {inputType.join(", ")}
            </span>
          )}
        </Col>
      </Row>

      <Form.Label className="mt-2">Insight</Form.Label>
      {!publicBoard ? (
        <Form.Control
          as="textarea"
          defaultValue={insight}
          rows={6}
          onBlur={(e) => {
            onUpdate("insight", e.target.value);
          }}
        />
      ) : (
        <p>{insight}</p>
      )}
      <Form.Label className="mt-2">Source</Form.Label>
      {!publicBoard ? (
        <Form.Control
          as="textarea"
          defaultValue={source}
          onBlur={(e) => onUpdate("source", e.target.value)}
          placeholder="Add source of this insight (Optional)"
        />
      ) : (
        <p>{source ? source : "No source added"}</p>
      )}
      <Form.Label className="mt-2">Notes</Form.Label>
      {!publicBoard ? (
        <Form.Control
          as="textarea"
          defaultValue={notes}
          placeholder="Add any additional notes here (Optional)"
          onBlur={(e) => onUpdate("notes", e.target.value)}
        />
      ) : (
        <p>{notes ? notes : "No notes added"}</p>
      )}

      {!publicBoard && (
        <div className="d-flex justify-content-end mt-4">
          <Button variant="danger" onClick={onDelete}>
            Delete Card
          </Button>
        </div>
      )}
    </Modal>
  );
};
const TagCard = ({
  _id,
  tagText,
  tagId,
  source,
  title,
  projectName,
  differentTags,
  publicBoard,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "card",
    item: { _id, sourceLaneId: tagId },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const handleShow = (e) => {
    e.stopPropagation();
    setShowPopup(true);
  };
  const handleHide = (updatedShow) => {
    setShowPopup(updatedShow);
  };
  return (
    <div
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className="card"
      onClick={handleShow}
    >
      <div className="card-text">{tagText}</div>
      <div className="tags">
        <span>{source}</span>
      </div>
      {showPopup && (
        <TagPopUp
          publicBoard={publicBoard}
          differentTags={differentTags}
          sectionTitle={title}
          show={showPopup}
          handleHide={handleHide}
          tagId={tagId}
          tagText={tagText}
          source={source}
          id={_id}
        />
      )}
    </div>
  );
};

const Card = ({
  _id,
  insight,
  source,
  notes,
  type,
  laneId,
  projectName,
  laneName,
  publicBoard,
  filteredLanes,
}) => {
  const { updateCard, deleteCard, moveCard } = useBoard();
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "card",
    item: { _id, sourceLaneId: laneId },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [showPopup, setShowPopup] = useState(false);

  const handleUpdate = (field, value) => {
    updateCard(_id, { [field]: value });
  };
  const handleMove = (targetLaneId) => {
    moveCard(_id, targetLaneId, laneId);
  };

  const handleDelete = () => {
    deleteCard(_id);
  };

  const handleShow = (e) => {
    e.stopPropagation();
    setShowPopup(true);
  };
  const handleHide = (updatedShow) => {
    setShowPopup(updatedShow);
  };

  return (
    <div
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className="card"
      onClick={handleShow}
    >
      <div className="card-text">{insight}</div>
      <div className="tags">
        {type.map((tag, index) => (
          <span key={index}>{tag}</span>
        ))}
      </div>
      <CardPopup
        show={showPopup}
        onHide={handleHide}
        insight={insight}
        source={source}
        notes={notes}
        type={type}
        handleMove={handleMove}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        projectName={projectName}
        laneName={laneName}
        publicBoard={publicBoard}
        filteredLanes={filteredLanes}
      />
    </div>
  );
};

const Lane = ({
  id,
  title,
  cards,
  projectName,
  publicBoard,
  color,
  isPublic,
  isTag,
  differentLanes,
  differentTags,
}) => {
  const { moveCard, updateLane, deleteLane } = useBoard();
  const [isOver, setIsOver] = useState(false);
  const [, drop] = useDrop(() => ({
    accept: "card",
    hover: () => {
      setIsOver(true);
    },
    drop: (item) => {
      setIsOver(false);
      moveCard(item._id, id, item.sourceLaneId);
    },
  }));

  const handleBlur = (field, value) => {
    updateLane(id, { [field]: value });
  };

  const handleDelete = () => {
    deleteLane(id);
  };

  return (
    <div
      ref={drop}
      className={`lane`}
      style={{
        opacity: isOver ? 0.9 : 1,
      }}
      onMouseLeave={() => setIsOver(false)}
    >
      <div className="lane-header">
        <EditableLaneTitle
          initialName={title}
          handleUpdate={handleBlur}
          color={color}
          isTag={isTag}
        />

        {!publicBoard && !isPublic && (
          <Menu className="menu-container" shadow="md">
            <Menu.Target>
              <Button
                variant="white"
                style={{
                  height: "40px",
                }}
              >
                <FiMoreHorizontal />
              </Button>
            </Menu.Target>

            <Menu.Dropdown className="menu-dropdown">
              <Menu.Item onClick={handleDelete}>Delete Section</Menu.Item>
            </Menu.Dropdown>
          </Menu>
        )}
      </div>
      <div className="cards">
        {isTag ? (
          <>
            {cards?.map((card) => (
              <TagCard
                publicBoard={publicBoard}
                differentTags={differentTags}
                key={card._id}
                _id={card._id}
                tagText={card.text}
                tagId={id}
                source={card?.asset?.name}
                title={title}
                projectName={projectName}
              />
            ))}
          </>
        ) : (
          cards?.map((card) => (
            <Card
              filteredLanes={differentLanes}
              key={card._id}
              _id={card._id}
              insight={card.insight}
              source={card.source}
              notes={card.notes}
              type={card.type}
              laneId={id}
              projectName={projectName}
              laneName={title}
              publicBoard={publicBoard}
            />
          ))
        )}
      </div>
    </div>
  );
};

export const BoardTab = ({
  projectData,
  publicBoard,
  isTag,
  tags,
  tagNames,
  newProject,
}) => {
  const dispatch = useDispatch();
  const { lanes } = useSelector((state) => state.lanes);
  const { data } = useSelector((state) => state.ribbon);
  const [filteredLanes, setFilteredLanes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [viewingOption, setViewingOption] = useState("Board");
  const { isPublic } = useSelector((state) => state.currentProject);

  const differentTags = isTag
    ? filteredLanes?.map((tag) => {
        return {
          _id: tag._id,
          title: tag.title,
          color: tag.color,
          highlightColor: tag.highlightColor,
        };
      })
    : [];

  useEffect(() => {
    if (data?.chart) {
      setViewingOption("Chart");
    } else if (data?.chart === false) {
      setViewingOption("Board");
    }
  }, [data?.chart]);

  useEffect(() => {
    setSearchTerm(data?.searchTerm);
  }, [data?.searchTerm]);
  useEffect(() => {
    if (!isPublic) {
      if (!publicBoard) {
        dispatch(loadLanesAction(projectData._id));
        setLoading(false);
      } else {
        dispatch(loadPublicLanesAction(projectData._id));
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [dispatch, projectData, isPublic]);

  const debouncedFilterTags = debounce((uniqueTagIds, searchTerm) => {
    const filtered = uniqueTagIds.reduce((acc, tag) => {
      let lower = searchTerm?.toLowerCase();
      if (tag.title.toLowerCase().includes(lower)) {
        acc.push(tag);
        return acc;
      }
      if (tag.tags.some((tag) => tag.text.toLowerCase().includes(lower))) {
        acc.push({
          ...tag,
          tags: tag.tags.filter((tag) =>
            tag.text.toLowerCase().includes(lower)
          ),
        });
        return acc;
      }
      return acc;
    }, []);
    setFilteredLanes(filtered);
  }, 600);

  const debouncedFilterLanes = debounce((lanes, searchTerm) => {
    const filtered = lanes.reduce((acc, lane) => {
      let lower = searchTerm?.toLowerCase();
      if (lane.title.toLowerCase().includes(lower)) {
        acc.push(lane);
        return acc;
      }
      if (
        lane.cards.some((card) => card.insight.toLowerCase().includes(lower))
      ) {
        acc.push({
          ...lane,
          cards: lane.cards.filter((card) =>
            card.insight.toLowerCase().includes(lower)
          ),
        });
        return acc;
      }
      return acc;
    }, []);
    setFilteredLanes(filtered);
  }, 600);

  useEffect(() => {
    if (isTag && tags.length > 0 && tagNames.length > 0) {
      if (tags.length > 0) {
        // make tags array on basis of tag.tagId and then add tag with that tagId
        let uniqueTagIds = tagNames?.map((tag) => {
          return { ...tag, tags: [] };
        });
        tags.forEach((tag) => {
          // add tag to correct tag.tagId.title
          let index = uniqueTagIds.findIndex(
            (tagId) => tagId.title === tag.tagId.title
          );
          if (index >= 0) {
            uniqueTagIds[index]?.tags.push({
              ...tag,
              asset: projectData.videos.find(
                (asset) => asset._id === tag.assetId
              ),
            });
          }
        });

        if (searchTerm?.length > 0) {
          debouncedFilterTags(uniqueTagIds, searchTerm);
        } else {
          setFilteredLanes(uniqueTagIds);
        }

        dispatch(
          ribbonActions.setRibbonData({ name: "tags", value: uniqueTagIds })
        );
      }
    } else {
      if (lanes?.length > 0) {
        if (searchTerm?.length > 0) {
          debouncedFilterLanes(lanes, searchTerm);
        } else {
          setFilteredLanes(lanes);
        }
      }
    }
  }, [lanes, isTag, tags, tagNames, searchTerm]);

  const addLane = () => {
    dispatch(createLaneAction("Untitled Section", projectData._id));
  };

  const addCard = (laneId) => {
    const payload = {
      laneId,
      insight: "Untitled",
      source: "",
      type: [],
      projectId: projectData._id,
      notes: "",
    };
    if (!publicBoard) dispatch(createCardAction(payload));
  };

  const moveCard = (cardId, targetLaneId, sourceLaneId) => {
    if (!publicBoard)
      dispatch(moveCardAction({ cardId, targetLaneId, sourceLaneId }));
  };

  const moveTag = (cardId, targetLaneId, sourceLaneId) => {
    if (!publicBoard && !isPublic)
      dispatch(moveTagCard({ cardId, targetLaneId, sourceLaneId }));
  };

  const updateCard = (cardId, updatedFields) => {
    if (!publicBoard && !isPublic)
      dispatch(updateCardAction({ cardId, updatedFields }));
  };

  const deleteCard = (cardId) => {
    if (!publicBoard && !isPublic) dispatch(deleteCardAction(cardId));
  };

  const updateLane = (laneId, updatedFields) => {
    if (!publicBoard && !isPublic)
      dispatch(updateLaneAction({ laneId, updatedFields }));
  };

  const updateTag = (tagId, updatedFields) => {
    if (!publicBoard && !isPublic)
      dispatch(updateTagTitleAction({ tagId, updatedFields }));
  };

  const deleteLane = (laneId) => {
    if (!publicBoard && !isPublic) dispatch(deleteLaneAction(laneId));
  };

  const deleteTag = (tagId) => {
    if (!publicBoard && !isPublic) dispatch(deleteTagAction(tagId));
  };

  const boardValue = isTag
    ? {
        moveCard: moveTag,
        updateCard: updateCard,
        deleteCard: deleteCard,
        updateLane: updateTag,
        deleteLane: deleteTag,
      }
    : {
        moveCard,
        updateCard,
        deleteCard,
        updateLane,
        deleteLane,
      };

  return (
    <BoardContext.Provider value={boardValue}>
      {/* if no lanes */}

      {isTag && <DeleteTagModal />}
      {loading ? (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          {!publicBoard && lanes.length === 0 && !isTag ? (
            <Container className="no-lanes remove-left-padding-smallscreen">
              <div
                style={{
                  width: "5.8rem",
                  height: "5.8rem",
                  alignItems: "center",
                  margin: "0 auto",
                  fontSize: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "50%",
                  background: "rgba(97, 121, 161, 0.1)",
                  color: "var(--grey5)",
                  marginBottom: "1rem",
                }}
              >
                <MdOutlineViewKanban />
              </div>
              <p
                style={{
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "19px",
                  textAlign: "center",
                  color: "var(--white)",
                }}
              >
                No Sections
              </p>
              <p
                style={{
                  fontSize: "0.9rem",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textAlign: "center",
                  color: "var(--primary2)",
                }}
              >
                Click the button below to add a section where you will be able
                to collect all your insights.
              </p>
              <Button
                variant="outline-primary"
                onClick={addLane}
                style={{ height: 44, minWidth: "200px" }}
              >
                Add Section
              </Button>
            </Container>
          ) : (
            <>
              {!newProject && (
                <Container className="mt-4 remove-left-padding-smallscreen">
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <Form.Control
                      type="text"
                      className="board-search-box" // Added a margin-right class if necessary
                      placeholder={`${
                        isTag ? "Search tagged snippets " : "Search insights"
                      }`}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />

                    {isTag && (
                      <Toggle
                        viewingOption={viewingOption}
                        setViewingOption={setViewingOption}
                      />
                    )}
                  </div>
                </Container>
              )}

              {tags && viewingOption === "Chart" ? (
                <TagsChart data={tags} searchTerm={searchTerm} />
              ) : (
                <div className={`board ${isPublic ? "publicBoard" : ""}`}>
                  <DndProvider backend={HTML5Backend}>
                    {filteredLanes?.length > 0 &&
                      filteredLanes?.map((lane, index) => (
                        <div key={lane._id} className="lane-wrapper">
                          <Lane
                            differentLanes={filteredLanes}
                            differentTags={differentTags}
                            isPublic={isPublic}
                            key={index}
                            color={lane.color}
                            frequency={lane.frequency}
                            id={lane._id}
                            title={lane.title}
                            searchTerm={searchTerm}
                            cards={isTag ? lane.tags : lane.cards}
                            projectName={projectData.name}
                            publicBoard={publicBoard}
                            isTag={isTag}
                          />

                          {!publicBoard && !isPublic && !isTag && (
                            <Button
                              variant="outline-primary"
                              className="mt-2"
                              onClick={() => addCard(lane._id)}
                            >
                              Add Insight
                            </Button>
                          )}
                        </div>
                      ))}
                  </DndProvider>
                  {!publicBoard && !isPublic && !isTag && (
                    <Button
                      variant="outline-primary"
                      onClick={addLane}
                      style={{ height: 44, minWidth: "200px" }}
                    >
                      Add Section
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
    </BoardContext.Provider>
  );
};
