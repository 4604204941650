import React from "react";
import styles from "./index.module.css";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Container } from "react-bootstrap";
export const ErrorPage = () => {
  const history = useHistory();
  return (
    <Container>
      <div className={styles.container}>
        <h4 id="page-title" className={styles.heading}>
          We couldn’t find this project, please review your permissions or
          contact support if you need help.
        </h4>
        <div
          className={`mt-4 ` + styles.backButton}
          onClick={() => {
            history.goBack();
          }}
        >
          <IoChevronBackCircleSharp size={30} />
        </div>
      </div>
    </Container>
  );
};
