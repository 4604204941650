import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FiCheck } from "react-icons/fi";
import { AiFillCloseCircle } from "react-icons/ai";
import darkModeImage from "../../images/upgrade-dark.svg";
import lightModeImage from "../../images/upgrade-light.svg";
import "./paywallModal.css";
import { modalActions } from "../../store/slices/modalSlice";

function PayWallModal() {
  const { darkMode } = useSelector((state) => state.theme);
  const { paywall: show } = useSelector((state) => state.modal);
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <Modal
      show={show}
      centered
      backdrop="static"
      size="lg"
      className="pay-modal-container"
    >
      <Modal.Body
        style={{
          display: "flex",
        }}
      >
        <div className="p-4 pay-modal-content">
          <h2
            className={
              darkMode
                ? "gradient-heading mb-4 bold"
                : "gradient-heading mb-4 bold  "
            }
          >
            Upgrade to get unlimited access
          </h2>
          <p className="gradient-paragraph mb-4">
            Get better understanding of your customer conversations by upgrading
            your plan. When you upgrade your you get
          </p>
          <ul
            className="mb-4"
            style={{
              listStyleType: "none",
              padding: "0",
            }}
          >
            <li>
              <FiCheck className="list-icon" />
              GPT-4o, Claude 3 and other advanced AI models
            </li>
            <li>
              <FiCheck className="list-icon" />
              Unlimited access to AI Chat
            </li>
            <li>
              <FiCheck className="list-icon" />
              Unlimited projects and files
            </li>
            <li>
              <FiCheck className="list-icon" />
              More transcription hours
            </li>
            <li>
              <FiCheck className="list-icon" />
              Team collaboration features
            </li>
          </ul>

          <AiFillCloseCircle
            className="close-btn"
            onClick={() => {
              dispatch(modalActions.hidePayWallModal());
            }}
          />

          <Button
            className="modal-button p-2 px-4"
            variant="success"
            onClick={() => {
              dispatch(modalActions.hidePayWallModal());
              history.push("/plans");
            }}
          >
            Discover Plans
          </Button>
        </div>
        <img
          className="pay-image"
          src={darkMode ? darkModeImage : lightModeImage}
        />
      </Modal.Body>
    </Modal>
  );
}

export default PayWallModal;
