import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import ReactFullpage from "@fullpage/react-fullpage";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { getProject } from "../../store/actions/projectActions";
import { useSelector } from "react-redux";

export const PresentPage = () => {
  const { isAuthenticated } = useSelector(state => state.auth)

  const [projectData, setProjectData] = useState({});
  const handle = useFullScreenHandle();
  const [fullscreenTriggered, setFullscreenTriggered] = useState(false);


  useEffect(() => {
    var m = window.location.href;
    var n = m.lastIndexOf('/');
    var id = m.substring(n + 1);
    let isMounted = true;
    const getProjectData = async () => {
      let { data, error } = await getProject({
        id,
      });

      if (!isMounted) {
        return;
      }

      if (data) {
        setProjectData(data);
      }

      if (error) {
        console.error(error);
      }
    }

    if (isAuthenticated)
      getProjectData();


  }, [isAuthenticated]);




  const handleFullScreen = () => {

    if (fullscreenTriggered) {
      handle.exit();
      setFullscreenTriggered(false);
    } else {
      handle.enter();
      setFullscreenTriggered(true);
    }

  }

  return (<ReactFullpage
    scrollingSpeed={1000} /* Options here */

    render={({ state, fullpageApi }) => {
      return (
        <>
          <FullScreen handle={handle}>
            <ReactFullpage.Wrapper>
              <div className="section present-title-section-black">
                <h1>{projectData?.name}</h1>
                <p>{projectData?.description}</p>
                <button onClick={() => fullpageApi.moveSectionDown()} className="btn">
                  Scroll down using mouse wheel
                </button>

                {/* if full sceeen is not triggered */}
                <button onClick={() => handleFullScreen()} className="btn-secondary" style={{ position: "absolute", top: "10px", right: "10px" }}>
                  <i className="material-icons">
                    {fullscreenTriggered ? "fullscreen_exit" : "fullscreen"}
                  </i>
                </button>


                {/* if full sceeen is triggered
        {fullscreenTriggered &&
        <button onClick={() => handle.exit() && setFullscreenTriggered(false)} className="btn-secondary" style={{position: "absolute", top: "10px", right: "10px"}}>
          <i className="material-icons">fullscreen_exit</i>
        </button>
        } */}


              </div>


              {projectData.videos && projectData.videos.map((video, videoIndex) => (
                <>
                  <div className="section present-insights-slide">
                    <h1>Interview # {videoIndex + 1}</h1>
                  </div>

                  {video.insights.map((insight, insightIndex) => (
                    <>
                      {[...Array(Math.ceil(insight.insights.length / 7))].map((_, divIndex) => (
                        <div className="section present-insights-slide" key={`${insightIndex}-${divIndex}`}>
                          <Container>
                            <h4>{insight.insightType}</h4>
                            <ol className="gradient-list">
                              {insight.insights
                                .slice(divIndex * 7, (divIndex + 1) * 7)
                                .map((insightSingle, index) => (
                                  insightSingle.insightText !== "" && insightSingle.visible ? (
                                    <li key={index}>
                                      <span>{insightSingle.insightText}</span>
                                    </li>
                                  ) : (
                                    ""
                                  )
                                ))}
                            </ol>
                          </Container>
                        </div>
                      ))}
                    </>
                  ))}
                </>
              ))}


            </ReactFullpage.Wrapper>
          </FullScreen>
        </>
      );
    }}
  />
  )
};