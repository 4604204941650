import React from "react";
import {
  LuHeading,
  LuHeading1,
  LuHeading2,
  LuHeading3,
  LuHeading4,
  LuHeading5,
  LuHeading6,
  LuTable2,
} from "react-icons/lu";
import styles from "../index.module.css";
import { FaListUl, FaQuoteRight } from "react-icons/fa";
import { FiChevronUp, FiPieChart, FiX } from "react-icons/fi";
import ToggleBtn from "./toggleBtn";
import { Popover } from "@mantine/core";
import { FiChevronDown, FiType } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { ribbonActions } from "../../../../../store/slices/ribbonSlice";

function PopOverDropDown({
  ele,
  pressed,
  handleClick,
  hideName,
  activeTab,
  assetType,
}) {
  const { data } = useSelector((state) => state.ribbon);
  const dispatch = useDispatch();
  let handleChange = (subName) => {
    if (subName === "Sources") {
      dispatch(
        ribbonActions.setRibbonData({
          name: "sources",
          value: data?.sources !== undefined ? !data.sources : false,
        })
      );
    }
    if (subName === "Player") {
      dispatch(
        ribbonActions.setRibbonData({
          name: "videoPlayer",
          value: data?.videoPlayer !== undefined ? !data.videoPlayer : false,
        })
      );
    }
    if (subName === "Files list") {
      dispatch(
        ribbonActions.setRibbonData({
          name: "filesList",
          value: data?.filesList !== undefined ? !data.filesList : false,
        })
      );
    }
  };

  const isVideoOrAudio = assetType === "VIDEO" || assetType === "AUDIO";
  return (
    <Popover width={200} position="bottom" withArrow shadow="md">
      <Popover.Target>
        <button
          onClick={handleClick}
          className={`${styles.toolbar__button} ${
            pressed ? styles.pressed : ""
          }`}
        >
          {ele.icon}
          <span className={hideName ? styles.hide : ""}>{ele.name}</span>
          <FiChevronDown className={styles.toolbar__span} />
        </button>
      </Popover.Target>
      <Popover.Dropdown>
        <div>
          {activeTab === "insights" ? (
            mapTabWithSubtabs2[ele.name][
              isVideoOrAudio ? "v/a" : "c/t/d/p"
            ]?.map((sub, index) => {
              if (sub?.type === "toggle") {
                return (
                  <div
                    className={styles.toolbar__button__subbutton__toggle}
                    key={index}
                  >
                    <ToggleBtn
                      handleChange={handleChange}
                      subName={sub?.name}
                    />
                  </div>
                );
              }
            })
          ) : (
            <>
              {mapTabWithSubtabs[ele.name]?.map((sub, index) => {
                if (sub?.type === "toggle") {
                  return (
                    <div
                      className={styles.toolbar__button__subbutton__toggle}
                      key={index}
                    >
                      <ToggleBtn
                        handleChange={handleChange}
                        subName={sub?.name}
                      />
                    </div>
                  );
                }
                return (
                  <div
                    className={styles.toolbar__button__subbutton__toggle}
                    key={index}
                  >
                    <button
                      className={`${styles.toolbar__button} ${styles.toolbar__button__subbutton}`}
                    >
                      <span className={`${styles.iconContainer}`}>
                        {sub.icon}
                      </span>
                      <span className={hideName ? styles.hide : ""}>
                        {sub?.name}
                      </span>
                    </button>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </Popover.Dropdown>
    </Popover>
  );
}

const mapTabWithSubtabs = {
  "Viewing Options": [
    { name: "Player", type: "toggle" },
    { name: "Files list", type: "toggle" },
  ],
  Insert: [
    { name: "Text", type: "button", icon: <FiType /> },
    { name: "Heading", type: "button", icon: <LuHeading /> },
    { name: "List", type: "button", icon: <FaListUl /> },
    { name: "Table", type: "button", icon: <LuTable2 /> },
    { name: "Quote", type: "button", icon: <FaQuoteRight /> },
    { name: "Delimiter", type: "button" },
    { name: "Chart", type: "button", icon: <FiPieChart /> },
  ],
  Move: [
    { name: "Move up", type: "button", icon: <FiChevronUp /> },
    { name: "Delete", type: "button", icon: <FiX /> },
    { name: "Move down", type: "button", icon: <FiChevronDown /> },
  ],
  Heading: [
    { name: "Heading 1", type: "button", icon: <LuHeading1 /> },
    { name: "Heading 2", type: "button", icon: <LuHeading2 /> },
    { name: "Heading 3", type: "button", icon: <LuHeading3 /> },
    { name: "Heading 4", type: "button", icon: <LuHeading4 /> },
    { name: "Heading 5", type: "button", icon: <LuHeading5 /> },
    { name: "Heading 6", type: "button", icon: <LuHeading6 /> },
  ],
};
const mapTabWithSubtabs2 = {
  "Viewing Options": {
    "v/a": [
      { name: "Player", type: "toggle" },
      { name: "Files list", type: "toggle" },
      { name: "Sources", type: "toggle" },
    ],
    "c/t/d/p": [
      { name: "Files list", type: "toggle" },
      { name: "Sources", type: "toggle" },
    ],
  },
};

export default PopOverDropDown;
