import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Sidebar from "./components/sidebar";
import Users from "./components/users";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import Dashboard from "./components/dashboard";
import CustomerRequests from "./components/customer-requests";
import Participants from "./components/participants";
import { ParticipantProfile } from "../participants/profile";
function Admin() {
  const { isAdmin } = useSelector((state) => state.auth);
  const [activeRoute, setActiveRoute] = useState(window.location.pathname);
  useEffect(() => {
    setActiveRoute(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <div className={styles.adminContainer}>
      {!isAdmin && <Redirect to="/" />}
      <Sidebar activeRoute={activeRoute} setActiveRoute />
      <main className={styles.mainContainer}>
        <Switch>
          <Route path="/admin/users" component={Users} />
          <Route path="/admin/customer-requests" component={CustomerRequests} />
          <Route path="/admin/participants/profile" component={ParticipantProfile} />
          <Route path="/admin/participants" component={Participants} />
          <Route path="/admin" component={Dashboard} />
        </Switch>
      </main>
    </div>
  );
}

export default Admin;
