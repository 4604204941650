/* eslint-disable no-unused-vars */
import React from "react";
import style from "./index.module.css";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { Text, Modal } from "@mantine/core";
import { PageLayout } from "../../../components/page-layout";
import { FiUser } from "react-icons/fi";
import { FaLinkedin } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import EditLinkedin from "../profile/sections/components/edit-linkedin";

export const ParticipantPrompt = () => {
  const history = useHistory();
  const [opened, { open, close }] = useDisclosure(false);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const hasPromptBeenViewed = localStorage.getItem("participantPromptViewed");

  React.useEffect(() => {
    if (hasPromptBeenViewed === "true") {
      history.push("/participant");
    }
  }, [hasPromptBeenViewed, history]);

  const handleCreateFromScratch = () => {
    localStorage.setItem("participantPromptViewed", "true");
    history.push("/participant");
  };

  return (
    <PageLayout>
      <div className={style.parentWrapper}>
        <div className={style.wrapper}>
          <div className={style.content}>
            <div className={style.heading}>
              How would you like to create your profile?
            </div>
            <div className={style.description}>
              Your profile details are kept confidential and are solely used for
              the purposes of providing you with research opportunities.
            </div>
            <div className={style.options}>
              <div className={style.option} onClick={handleCreateFromScratch}>
                <div className={style.optionIcon}>
                  <FiUser />
                </div>
                <div>Create from Scratch</div>
              </div>
              <div className={style.option} onClick={open}>
                <div className={style.optionIcon}>
                  <FaLinkedin />
                </div>
                <div>Import from LinkedIn</div>
              </div>
              <Modal
                opened={opened}
                onClose={close}
                title={<Text weight={600}>Import from Linkedin</Text>}
                size={isMobile ? "xs" : "md"}
                centered
              >
                <EditLinkedin onClose={close} />
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
