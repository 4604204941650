import React from 'react';
import { OverviewTab } from '../components/tabs/overview';
import { useDispatch, useSelector } from 'react-redux';
import currentProjectSlice from '../../../store/slices/currentProject';
import { loadProject } from '../../../store/actions/currentProjectActions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export default function Overview(){
    const projectData = useSelector(state => state.currentProject.project)
    const { account: userAccount } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const { projectId } = useParams()
    if(!projectData || !userAccount){
        return null;
    }

    function setProjectData(data){
        dispatch(currentProjectSlice.actions.setProject({project: data}));
    }

    function runGetProjectData() {
        loadProject(projectId);
    }
    
    return <OverviewTab
                projectData={projectData}
                userAccount={userAccount}
                setProjectData={setProjectData}
                runGetProjectData={runGetProjectData}
              />
}