import { createSlice } from "@reduxjs/toolkit";

const customerRequestsSlice = createSlice({
  name: "customerRequests",
  initialState: {
    requests: [],
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    addRequest: (state, action) => {
      state.requests.push(action.payload);
    },
    setRequests: (state, action) => {
      state.requests = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    updateRequest: (state, action) => {
      const index = state.requests.findIndex(
        (request) => request._id === action.payload._id
      );
      if (index !== -1) {
        state.requests[index] = { ...state.requests[index], ...action.payload };
      }
    },
  },
});

export const customerRequestsActions = customerRequestsSlice.actions;
export default customerRequestsSlice;
