export const mapTabWithLastWords = (lastWord, setSelectedInsightTab) => {
  switch (lastWord) {
    case "Pain Points":
      setSelectedInsightTab("Pain Points");
      break;
    case "Opportunity Areas":
      setSelectedInsightTab("Opportunity Areas");
      break;
    case "Opposing Views":
      setSelectedInsightTab("Opposing Views");
      break;
    case "Quotable Quotes":
      setSelectedInsightTab("Quotable Quotes");
      break;
    case "Topics":
      setSelectedInsightTab("Topics");
      break;
    case "Jobs To Be Done":
      setSelectedInsightTab("Jobs To Be Done");
      break;
    case "Key Insights":
      setSelectedInsightTab("Key Insights");
      break;
    case "Delighters":
      setSelectedInsightTab("Delighters");
      break;
    case "Questions for Next Session":
      setSelectedInsightTab("Questions for Next Session");
      break;
    case "Bug Reports":
      setSelectedInsightTab("Bug Reports");
      break;
    case "Feature Requests":
      setSelectedInsightTab("Feature Requests");
      break;
    case "Session Summary":
      setSelectedInsightTab("Session Summary");
      break;
    default:
      setSelectedInsightTab("Transcript");
  }
};
