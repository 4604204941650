import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { Tooltip as MantineTooltip } from "@mantine/core";
import { PageLayout } from "../../components/page-layout";
import { createSession } from "../../store/actions/paymentActions";
import { useSelector } from "react-redux";

import { FaCheckCircle } from "react-icons/fa";
import styles from "./index.module.css";
import PlanCard from "./planCard";
import PlansHeader from "./plansHeader";
import {
  engagePlusPlan,
  engageStandardPlan,
  engageBasicPlan,
  basicPlan,
  freeplan,
  standardPlan,
  plusPlan,
} from "./plans";

export const PlansPage = () => {
  const { account } = useSelector((state) => state.auth);
  const [billing, setBilling] = useState("monthly");
  const [myPlan, setMyPlan] = useState("");
  const [myEngagePlan, setMyEngagePlan] = useState("");
  const [currentTab, setCurrentTab] = useState("analyze");
  const planLinks = [
    {
      plan: "Basic",
      monthly: process.env.REACT_APP_PLAN_BASIC_MONTHLY,
      yearly: process.env.REACT_APP_PLAN_BASIC_YEARLY,
    },
    {
      plan: "Standard",
      monthly: process.env.REACT_APP_PLAN_STANDARD_MONTHLY,
      yearly: process.env.REACT_APP_PLAN_STANDARD_YEARLY,
    },
    {
      plan: "Plus",
      monthly: process.env.REACT_APP_PLAN_PLUS_MONTHLY,
      yearly: process.env.REACT_APP_PLAN_PLUS_YEARLY,
    },
  ];
  const engagePlanLinks = [
    {
      plan: "Basic",
      monthly: process.env.REACT_APP_ENGAGE_BASIC_MONTHLY,
      yearly: process.env.REACT_APP_ENGAGE_BASIC_YEARLY,
    },
    {
      plan: "Standard",
      monthly: process.env.REACT_APP_ENGAGE_STANDARD_MONTHLY,
      yearly: process.env.REACT_APP_ENGAGE_STANDARD_YEARLY,
    },
    {
      plan: "Plus",
      monthly: process.env.REACT_APP_ENGAGE_PLUS_MONTHLY,
      yearly: process.env.REACT_APP_ENGAGE_PLUS_YEARLY,
    },
  ];
  useEffect(() => {
    if (account) {
      setMyPlan(account?.plan?.type);
      setMyEngagePlan(account?.engagePlan?.type);
    }
  }, [account]);

  const changeTab = (tab) => {
    setCurrentTab(tab);
  };

  const newEngageLink = (priceId) => {
    // request create session
    const payload = {
      user: account,
      priceId,
    };

    createSession(payload).then((response) => {
      if (response.data) {
        // redirect to checkout
        window.location.href = response.data;
      }
    });
  };

  const newLink = (priceId) => {
    // request create session
    const payload = {
      user: account,
      priceId,
    };

    createSession(payload).then((response) => {
      if (response.data) {
        // redirect to checkout
        window.location.href = response.data;
      }
    });
  };

  const handleChange = (val) => {
    if (val !== null) {
      setBilling(val);
    }
  };
  const engagePlanData = [
    {
      title: "Basic",
      moto: "Best for trying it out",
      includeText: "Basic includes:",
      billing: billing,
      plan: engageBasicPlan,
      handleLink: newEngageLink,
      planLinks: [engagePlanLinks[0].monthly, engagePlanLinks[0].yearly],
      monthlyCost: 169,
      yearlyCost: 140,
      planName: myEngagePlan,
    },
    {
      title: "Standard",
      moto: "Best for individuals",
      includeText: "Standard includes:",
      billing: billing,
      plan: engageStandardPlan,
      handleLink: newEngageLink,
      planLinks: [engagePlanLinks[1].monthly, engagePlanLinks[1].yearly],
      monthlyCost: 339,
      yearlyCost: 282,
      planName: myEngagePlan,
    },
    {
      title: "Plus",
      moto: "Best for longterm research",
      includeText: "Plus includes:",
      billing: billing,
      plan: engagePlusPlan,
      handleLink: newEngageLink,
      planLinks: [engagePlanLinks[2].monthly, engagePlanLinks[2].yearly],
      monthlyCost: 539,
      yearlyCost: 449,
      planName: myEngagePlan,
    },
  ];
  const planData = [
    {
      title: "Free",
      moto: "Test drive it",
      includeText: "Free includes:",
      billing: billing,
      plan: freeplan,
      handleLink: newLink,
      planLinks: [],
      monthlyCost: 0,
      yearlyCost: 0,
      planName: myPlan,
    },
    {
      title: "Basic",
      moto: "Best for individuals",
      includeText: "Everything in Free, plus:",
      billing: billing,
      plan: basicPlan,
      handleLink: newLink,
      planLinks: [planLinks[0].monthly, planLinks[0].yearly],
      monthlyCost: 19,
      yearlyCost: 15,
      planName: myPlan,
    },
    {
      title: "Standard",
      moto: "Best for frequent users",
      includeText: "Everything in Basic, plus:",
      billing: billing,
      plan: standardPlan,
      handleLink: newLink,
      planLinks: [planLinks[1].monthly, planLinks[1].yearly],
      monthlyCost: 49,
      yearlyCost: 41,
      planName: myPlan,
    },
    {
      title: "Plus",
      moto: "Best for teams",
      includeText: "Everything in Personal, plus:",
      billing: billing,
      plan: plusPlan,
      handleLink: newLink,
      planLinks: [planLinks[2].monthly, planLinks[2].yearly],
      monthlyCost: 99,
      yearlyCost: 83,
      planName: myPlan,
    },
  ];

  return (
    <PageLayout>
      <PlansHeader
        account={account}
        currentTab={currentTab}
        changeTab={changeTab}
      />
      <Container className="justify-content-center align-items-center mt-5">
        <Row className="justify-content-between align-items-center mb-4">
          <Col>
            <MantineTooltip
              label="2 months free"
              visible={true}
              opened
              style={{ zIndex: 9999, color: "black" }}
              color="#FFC700"
              arrowOffset={30}
              arrowSize={6}
              withArrow
              position="top-end"
            >
              <ToggleButtonGroup
                type="radio"
                name="options"
                value={billing}
                onChange={handleChange}
                style={{ borderRadius: "1.375rem" }}
                className={styles.togglebuttongroup}
              >
                <ToggleButton
                  variant={billing === "monthly" ? "primary" : "light"}
                  id="monthly"
                  value="monthly"
                  style={{
                    borderRadius: "1.375rem",
                    backgroundColor:
                      billing === "monthly" ? "#0085ff" : "#FFFFFF",
                    borderColor: billing === "monthly" ? "#0085ff" : "#FFFFFF",
                    transition: "all 0.3s",
                  }}
                  className={billing === "monthly" ? `${styles.selected}` : ""}
                >
                  Monthly
                </ToggleButton>
                <ToggleButton
                  variant={billing === "yearly" ? "primary" : "light"}
                  id="yearly"
                  value="yearly"
                  style={{
                    borderRadius: "1.375rem",
                    backgroundColor: billing === "yearly" ? "#0085ff" : "",
                    borderColor: billing === "yearly" ? "#0085ff" : "",
                  }}
                >
                  Annual
                </ToggleButton>
              </ToggleButtonGroup>
            </MantineTooltip>
          </Col>
          <Col>
            <span
              className={
                "justify-content-end plan-feature " + styles.plansDisclaimer
              }
            >
              <FaCheckCircle style={{ marginRight: "6px" }} />
              {"  "}
              GDPR Compliant{"  "}
              <FaCheckCircle
                style={{ marginRight: "6px", marginLeft: "10px" }}
              />
              CCPA Compliant
            </span>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            {account?.celloReferralCode && (
              <Alert variant="success">
                <strong>
                  You are eligible for a 50% discount on all plans.
                </strong>{" "}
                Thank you for subscribing using a referral link!
              </Alert>
            )}
          </Col>
        </Row>
        {currentTab === "analyze" ? (
          <Row>
            {planData.map((planData, index) => {
              return (
                <PlanCard
                  key={index}
                  title={planData.title}
                  moto={planData.moto}
                  includeText={planData.includeText}
                  billing={planData.billing}
                  plan={planData.plan}
                  handleLink={planData.handleLink}
                  planLinks={planData.planLinks}
                  monthlyCost={planData.monthlyCost}
                  yearlyCost={planData.yearlyCost}
                  planName={planData.planName}
                  isEngage={false}
                />
              );
            })}
          </Row>
        ) : (
          <Row>
            {engagePlanData.map((planData, index) => {
              return (
                <PlanCard
                  key={index}
                  title={planData.title}
                  moto={planData.moto}
                  includeText={planData.includeText}
                  billing={planData.billing}
                  plan={planData.plan}
                  handleLink={planData.handleLink}
                  planLinks={planData.planLinks}
                  monthlyCost={planData.monthlyCost}
                  yearlyCost={planData.yearlyCost}
                  planName={planData.planName}
                  isEngage={true}
                />
              );
            })}
          </Row>
        )}
      </Container>
    </PageLayout>
  );
};
