import React from "react";
import { Button } from "react-bootstrap";
import { Modal } from "@mantine/core";
import { deleteVideoById } from "../../../../store/actions/projectActions";
import { loadProject } from "../../../../store/actions/currentProjectActions";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

function RemoveFileModal({
  showDeleteVideo,
  setShowDeleteVideo,
  selectedVideo,
}) {
  const { project } = useSelector((state) => state.currentProject);
  const history = useHistory();
  let projectId = project?._id;
  const handleDeleteVideo = async (videoId) => {
    let { data, error } = await deleteVideoById(videoId, projectId);
    if (data) {
      // Go back to project main page
      history.push(`/project/${projectId}`);
      loadProject(projectId);
    }

    if (error) {
      console.error(error);
    }
  };
  return (
    <Modal
      size="md"
      opened={showDeleteVideo}
      onClose={() => setShowDeleteVideo(false)}
      title="Remove File from Project"
    >
      <p>
        Are you sure you want to remove this file from this project?
        <br />
        <br />
        You will lose all insights and relevant data connected to this file in
        this project.
      </p>
      <div className="d-flex justify-content-between mt-4">
        <Button variant="secondary" onClick={() => setShowDeleteVideo(false)}>
          Close
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            handleDeleteVideo(selectedVideo?._id);
            setShowDeleteVideo(false);
          }}
        >
          Yes, Remove it
        </Button>
      </div>
    </Modal>
  );
}

export default RemoveFileModal;
