import React from "react";
import { useSelector } from "react-redux";
import style from "./cover-image.module.css";
import { darkImages, lightImages } from "../../../../images/project-images";

export const hashProjectId = (projectId) => {
  let hash = 0;
  for (let i = 0; i < projectId.length; i++) {
    hash = projectId.charCodeAt(i) + ((hash << 5) - hash);
  }
  return Math.abs(hash % 10);
};

export default function CoverImage({ projectDataId }) {
  const { darkMode } = useSelector((state) => state.theme);

  // hash function to take project id as input and return index of image to be used , index should be between 0 and 6

  return (
    <div
      className={style.coverImage}
      style={{
        backgroundImage: `url(${
          darkMode
            ? darkImages[hashProjectId(projectDataId)]
            : lightImages[hashProjectId(projectDataId)]
        })`,
      }}
    ></div>
  );
}
