import { createSlice } from "@reduxjs/toolkit";

const lanesSlice = createSlice({
  name: "lanes",
  initialState: {
    lanes: [],
    loading: false,
    error: null
  },
  reducers: {
    requestStart(state, action) {
      state.loading = true;
      state.error = null;
    },
    loadLanes(state, action) {
      state.lanes = action.payload.lanes;
      state.loading = false;
      state.error = null;
    },
    createLane(state, action) {
      state.lanes = [...state.lanes, action.payload];
      state.loading = false;
      state.error = null;
    },
    searchCards(state, action) {
      const searchTerm = action.payload;
      state.searchTerm = searchTerm;
    },
    updateLane(state, action) {
      const { laneId, updatedFields } = action.payload;
      const laneIndex = state.lanes.findIndex((lane) => lane.id === laneId);
      if (laneIndex !== -1) {
        state.lanes[laneIndex] = { ...state.lanes[laneIndex], ...updatedFields };
      }
    },
    deleteLane(state, action) {
      state.lanes = state.lanes.filter((lane) => lane._id !== action.payload);
    },
    createCard(state, action) {
      const { data, laneId } = action.payload;
      const lane = state.lanes.find((lane) => lane._id === laneId);
      if (lane) {
        lane.cards.push(data);
      }
    },
    moveCard(state, action) {
      const { cardId, targetLaneId, sourceLaneId } = action.payload;
      let sourceLane = null;
      let foundCard = null;
      
      // find the source lane by id
      if (sourceLaneId) {
        sourceLane = state.lanes.find((lane) => lane._id === sourceLaneId);
      }

      // find the card by id

      if (sourceLane) {
        foundCard = sourceLane.cards.find((card) => card._id === cardId);
        
        // remove the card from the source lane
        if (foundCard) {
          sourceLane.cards = sourceLane.cards.filter((card) => card._id !== cardId);
        }
        

        // add the card to the target lane
        const targetLane = state.lanes.find((lane) => lane._id === targetLaneId);
        if (targetLane) {
          targetLane.cards.push(foundCard);
        }


      }
    },

      // dispatch(laneActions.updateCard({cardId, insight: updatedFields?.insight, source: updatedFields?.source, type: updatedFields?.type, notes: updatedFields?.notes}));
    updateCard(state, action) {
      const {
        cardId,
        insight,
        source,
        type,
        notes
       } = action.payload;

      // find the card by id in the lanes and then update the fields that are not undefined
      for (const lane of state.lanes) {
        const cardIndex = lane.cards.findIndex((card) => card._id === cardId);
        if (cardIndex !== -1) {
          if(insight !== undefined) {
            lane.cards[cardIndex].insight = insight;
          }
          if(source !== undefined) {
            lane.cards[cardIndex].source = source;
          }
          if(type !== undefined) {
            lane.cards[cardIndex].type = type;
          }
          if(notes !== undefined) {
            lane.cards[cardIndex].notes = notes;
          }

          break;
        }
      }
      
      
    },
    deleteCard(state, action) {
      const cardId = action.payload;

      for (const lane of state.lanes) {
        const cardIndex = lane.cards.findIndex((card) => card._id === cardId);
        if (cardIndex !== -1) {
          lane.cards.splice(cardIndex, 1);
          break;
        }
      }
    },
    requestFail (state, action) {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export default lanesSlice;
export const laneActions = lanesSlice.actions;