import React, { useEffect, useState } from "react";
import CollectedBtn, { CollectBtn } from "./collectBtn";
import { useSelector } from "react-redux";
import DeleteBtn from "./deleteBtn";
import HighlightIcon from "./highlightIcon";
import { useVirtualizer } from "@tanstack/react-virtual";
import debounce from "lodash/debounce";
import PlayContext from "./playContextBtn";
import { ShowMore } from "./showmore-disclaimer";
function Insights({
  selectedInsightTab,
  selectedVideo,
  insightsToShow,
  collectInsight,
  insightType,
  handleSeek,
  isFree,
  morethan10,
}) {
  const [showSources, setShowSources] = useState(false);
  const { data } = useSelector((state) => state.ribbon);
  const { isPublic } = useSelector((state) => state.currentProject);
  const [timeStamps, setTimeStamps] = useState([]);
  // The scrollable element for your list
  const parentRef = React.useRef();
  // The virtualizer

  const rowVirtualizer = useVirtualizer({
    count: insightsToShow?.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 240,
  });

  const items = rowVirtualizer.getVirtualItems();
  const { lanes } = useSelector((state) => state.lanes);
  let laneCards = lanes?.map((lane) => lane.cards).flat();
  const isPDF_DOC_TEXT = ["TEXT", "PDF", "DOC", "LIVECHAT"].includes(
    selectedVideo.assetType
  );
  useEffect(() => {
    if (data?.sources !== undefined) {
      setShowSources(data?.sources);
    } else {
      setShowSources(true);
    }
  }, [data?.sources]);
  useEffect(() => {
    if (selectedVideo?.transcript) {
      let timestamps = [];
      getTimeStampForInsight(
        selectedVideo.insights,
        selectedVideo.transcript,
        timestamps,
        isPDF_DOC_TEXT
      );
      setTimeStamps(timestamps);
    }
  }, [selectedVideo.insights?.length,selectedVideo?.transcript]);
  if (selectedVideo === null) return "";
  const debouncedMeasureElement = debounce(rowVirtualizer.measureElement, 50);
  return (
    <>
      <div
        ref={parentRef}
        style={{
          height: "calc(100vh - 200px)",
          width: "100%",
          overflowY: "auto",
          contain: "strict",
        }}
      >
        {isFree ? (
          <div>
            <div>
              {insightsToShow.map((singleInsight, index) => {
                let time = null;
                let normalTime = null;
                let endTime = null;
                let isCollected = laneCards.some((card) => {
                  return (
                    card.insight === singleInsight.insightText &&
                    card.source === singleInsight.source
                  );
                });
                if (timeStamps[0]) {
                  timeStamps[0][insightType]?.forEach((stamp) => {
                    if (stamp["index"] == index) {
                      if (stamp["startTime"]) {
                        time = stamp["startTime"];
                        normalTime = time;
                        endTime = stamp["endTime"];
                      }
                    }
                  });
                }
                return (
                  <div
                    data-index={index}
                    ref={debouncedMeasureElement}
                    key={index}
                    className={`session-insight-row` + ` sources-shown-card`}
                  >
                    <div style={{ padding: "5px", fontSize: "0.85rem" }}>
                      <div>
                        <p
                          className={
                            singleInsight.visible
                              ? "insight-text"
                              : "insight-text-hidden"
                          }
                        >
                          {singleInsight.insightText}&nbsp;
                        </p>
                        {/* New source text div */}
                        {showSources && singleInsight.source && (
                          <div
                            className="source-text"
                            style={{ fontSize: "0.75rem" }}
                          >
                            Source:{" "}
                            {singleInsight.source.length > 800
                              ? singleInsight.source.slice(0, 800) + "..."
                              : singleInsight.source}
                          </div>
                        )}
                      </div>
                    </div>
                    <div style={{ padding: "5px" }}>
                      <div
                        className="source-tools"
                        style={{
                          justifyContent: showSources
                            ? "flex-start"
                            : "flex-end",
                        }}
                      >
                        {/* OverlayTrigger with tooltip saying 'Collect Insight' */}
                        {!isPublic &&
                          (isCollected ? (
                            <CollectedBtn
                              showSources={showSources}
                              collectInsight={collectInsight}
                              insight={selectedInsightTab}
                              singleInsight={singleInsight}
                              name={selectedVideo?.name}
                            />
                          ) : (
                            <CollectBtn
                              selectedInsightTab={selectedInsightTab}
                              singleInsight={singleInsight}
                              collectInsight={collectInsight}
                              name={selectedVideo?.name}
                              showSources={showSources}
                            />
                          ))}
                        <HighlightIcon
                          showSources={showSources}
                          index={index}
                          normalTime={{
                            start: normalTime,
                            end: endTime,
                          }}
                          disabled={!normalTime}
                          assetId={selectedVideo?._id}
                        />
                        <PlayContext
                          showSources={showSources}
                          handleSeek={handleSeek}
                          isPDF_DOC_TEXT={isPDF_DOC_TEXT}
                          normalTime={normalTime}
                        />
                        {!isPublic && (
                          <DeleteBtn
                            showSources={showSources}
                            singleInsight={singleInsight}
                            videoId={selectedVideo?._id}
                            insightType={selectedInsightTab}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
              {morethan10 && (
                <>
                  {" "}
                  <ShowMore />
                  {insightsToShow.slice(0, 3).map((singleInsight, index) => {
                    let time = null;
                    let normalTime = null;
                    let endTime = null;
                    let isCollected = laneCards.some((card) => {
                      return (
                        card.insight === singleInsight.insightText &&
                        card.source === singleInsight.source
                      );
                    });
                    if (timeStamps[0]) {
                      timeStamps[0][insightType]?.forEach((stamp) => {
                        if (stamp["index"] == index) {
                          if (stamp["startTime"]) {
                            time = stamp["startTime"];
                            normalTime = time;
                            endTime = stamp["endTime"];
                          }
                        }
                      });
                    }
                    return (
                      <div
                        data-index={index}
                        ref={debouncedMeasureElement}
                        key={index}
                        className={
                          `session-insight-row` + ` sources-shown-card`
                        }
                      >
                        <div style={{ padding: "5px", fontSize: "0.85rem" }}>
                          <div>
                            <p
                              style={{
                                color: "transparent",
                                textShadow: " 0 0 5px rgba(0,0,0,0.5)",
                              }}
                              className={
                                singleInsight.visible
                                  ? "insight-text"
                                  : "insight-text-hidden"
                              }
                            >
                              {singleInsight.insightText}&nbsp;
                            </p>
                            {/* New source text div */}
                            {showSources && singleInsight.source && (
                              <div
                                className="source-text"
                                style={{
                                  fontSize: "0.75rem",
                                  color: "transparent",
                                  textShadow: " 0 0 5px rgba(0,0,0,0.5)",
                                }}
                              >
                                Source:{" "}
                                {singleInsight.source.length > 800
                                  ? singleInsight.source.slice(0, 800) + "..."
                                  : singleInsight.source}
                              </div>
                            )}
                          </div>
                        </div>
                        <div style={{ padding: "5px" }}>
                          <div
                            className="source-tools"
                            style={{
                              justifyContent: showSources
                                ? "flex-start"
                                : "flex-end",
                            }}
                          >
                            {/* OverlayTrigger with tooltip saying 'Collect Insight' */}
                            {!isPublic &&
                              (isCollected ? (
                                <CollectedBtn
                                  fake={true}
                                  showSources={showSources}
                                  collectInsight={collectInsight}
                                  insight={selectedInsightTab}
                                  singleInsight={singleInsight}
                                  name={selectedVideo?.name}
                                />
                              ) : (
                                <CollectBtn
                                  fake={true}
                                  selectedInsightTab={selectedInsightTab}
                                  singleInsight={singleInsight}
                                  collectInsight={collectInsight}
                                  name={selectedVideo?.name}
                                  showSources={showSources}
                                />
                              ))}
                            <HighlightIcon
                              fake={true}
                              showSources={showSources}
                              index={index}
                              normalTime={{
                                start: normalTime,
                                end: endTime,
                              }}
                              disabled={!normalTime}
                              assetId={selectedVideo?._id}
                            />
                            <PlayContext
                              fake={true}
                              showSources={showSources}
                              handleSeek={handleSeek}
                              isPDF_DOC_TEXT={isPDF_DOC_TEXT}
                              normalTime={normalTime}
                            />
                            {!isPublic && (
                              <DeleteBtn
                                fake={true}
                                showSources={showSources}
                                singleInsight={singleInsight}
                                videoId={selectedVideo?._id}
                                insightType={selectedInsightTab}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        ) : (
          <div
            style={{
              height: rowVirtualizer.getTotalSize(),
              width: "100%",
              position: "relative",
              paddingTop: `10px`,
              paddingBottom: `10px`,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                transform: `translateY(${items[0]?.start ?? 0}px)`,
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualItem, index) => {
                const singleInsight = insightsToShow[virtualItem.index];
                let time = null;
                let normalTime = null;
                let endTime = null;
                let isCollected = laneCards.some((card) => {
                  return (
                    card.insight === singleInsight.insightText &&
                    card.source === singleInsight.source
                  );
                });
                if (timeStamps[0]) {
                  timeStamps[0][insightType]?.forEach((stamp) => {
                    if (stamp["index"] == virtualItem.index) {
                      if (stamp["startTime"]) {
                        time = stamp["startTime"];
                        normalTime = time;
                        endTime = stamp["endTime"];
                      }
                    }
                  });
                }
                return (
                  <div
                    data-index={virtualItem.index}
                    ref={debouncedMeasureElement}
                    key={virtualItem.key}
                    className={`session-insight-row` + ` sources-shown-card`}
                  >
                    <div style={{ padding: "5px", fontSize: "0.85rem" }}>
                      <div>
                        <p
                          className={
                            singleInsight.visible
                              ? "insight-text"
                              : "insight-text-hidden"
                          }
                        >
                          {singleInsight.insightText}&nbsp;
                        </p>
                        {/* New source text div */}
                        {showSources && singleInsight.source && (
                          <div
                            className="source-text"
                            style={{ fontSize: "0.75rem" }}
                          >
                            Source:{" "}
                            {singleInsight.source.length > 800
                              ? singleInsight.source.slice(0, 800) + "..."
                              : singleInsight.source}
                          </div>
                        )}
                      </div>
                    </div>
                    <div style={{ padding: "5px" }}>
                      <div
                        className="source-tools"
                        style={{
                          justifyContent: showSources
                            ? "flex-start"
                            : "flex-end",
                        }}
                      >
                        {/* OverlayTrigger with tooltip saying 'Collect Insight' */}
                        {!isPublic &&
                          (isCollected ? (
                            <CollectedBtn
                              showSources={showSources}
                              collectInsight={collectInsight}
                              insight={selectedInsightTab}
                              singleInsight={singleInsight}
                              name={selectedVideo?.name}
                            />
                          ) : (
                            <>
                              <CollectBtn
                                selectedInsightTab={selectedInsightTab}
                                singleInsight={singleInsight}
                                collectInsight={collectInsight}
                                name={selectedVideo?.name}
                                showSources={showSources}
                              />
                            </>
                          ))}

                        <HighlightIcon
                          showSources={showSources}
                          index={virtualItem.index}
                          normalTime={{
                            start: normalTime,
                            end: endTime,
                          }}
                          disabled={!normalTime}
                          assetId={selectedVideo?._id}
                        />

                        <PlayContext
                          showSources={showSources}
                          handleSeek={handleSeek}
                          isPDF_DOC_TEXT={isPDF_DOC_TEXT}
                          normalTime={normalTime}
                        />

                        {!isPublic && (
                          <DeleteBtn
                            showSources={showSources}
                            singleInsight={singleInsight}
                            videoId={selectedVideo?._id}
                            insightType={selectedInsightTab}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Insights;
export const getTimeStampForInsight = async (
  insight,
  transcript,
  timestamps,
  isPDF_DOC_TEXT
) => {
  if (isPDF_DOC_TEXT && insight && transcript && transcript[0]) {
    let sources = [];

    insight.forEach((insight) => {
      let insightToAdd = {};
      insightToAdd[insight.insightType] = [];
      insight.insights.forEach((singleInsight) => {
        if (singleInsight.source) {
          insightToAdd[insight.insightType].push(singleInsight.source);
        }
      });
      sources.push(insightToAdd);
    });

    if (transcript[0]?.text) {
      let allWords = transcript[0]?.text
        ?.replace(/\n/g, " ")
        .toLowerCase()
        .split(" ");
      let timeStampObject = {};
      sources.forEach((source) => {
        Object.keys(source).forEach((key) => {
          timeStampObject[key] = [];
          let sentences = source[key];
          sentences.forEach((sentence, i) => {
            let words = sentence
              .split(" ")
              .map((word) => word.replace(/["\n]/g, "").toLowerCase());
            let wordIndex = matchStringAndGiveWordIndexForDoc(allWords, words);

            if (wordIndex.paraIndex !== -1) {
              timeStampObject[key].push({
                index: i,
                paraIndex: wordIndex.paraIndex,
                startTime: wordIndex.paraIndex,
                endTime: wordIndex.ind,
              });
            }
          });
        });
      });
      timestamps.push(timeStampObject);
    }
  }
  if (insight && transcript && transcript[0]) {
    let sources = [];
    insight.forEach((insight) => {
      let insightToAdd = {};
      insightToAdd[insight.insightType] = [];
      insight.insights.forEach((singleInsight) => {
        if (singleInsight.source) {
          insightToAdd[insight.insightType].push(singleInsight.source);
        }
      });
      sources.push(insightToAdd);
    });
    if (transcript[0].words) {
      let allWords = transcript[0].words.map((word) => {
        return word.text?.toLowerCase();
      });
      let timeStampObject = {};
      sources.forEach((source) => {
        Object.keys(source).forEach((key) => {
          timeStampObject[key] = [];
          let sentences = source[key];
          sentences.forEach((sentence, i) => {
            // remove quotation mark from sentence
            if (
              sentence.length >= 2 &&
              sentence.startsWith("'") &&
              sentence.endsWith("'")
            ) {
              sentence = sentence.slice(1, -1);
            } else if (
              sentence.length >= 2 &&
              sentence.startsWith('"') &&
              sentence.endsWith('"')
            ) {
              sentence = sentence.slice(1, -1);
            }
            let words = sentence?.toLowerCase().split(" ");
            let wordIndex = matchStringAndGiveWordIndex(allWords, words);

            if (wordIndex !== -1) {
              timeStampObject[key].push({
                index: i,
                startTime: transcript[0]?.words[wordIndex]?.start,
                endTime:
                  transcript[0]?.words[wordIndex + words.length - 1]?.end,
              });
            }
          });
        });
      });
      timestamps.push(timeStampObject);
    } else {
      if (transcript[0].text) {
        let allWords = transcript[0].text?.toLowerCase().split(" ");
        let timeStampObject = {};
        sources.forEach((source) => {
          Object.keys(source).forEach((key) => {
            timeStampObject[key] = [];
            let sentences = source[key];
            sentences.forEach((sentence, i) => {
              // remove quotation mark from sentence
              if (
                sentence.length >= 2 &&
                sentence.startsWith("'") &&
                sentence.endsWith("'")
              ) {
                sentence = sentence.slice(1, -1);
              } else if (
                sentence.length >= 2 &&
                sentence.startsWith('"') &&
                sentence.endsWith('"')
              ) {
                sentence = sentence.slice(1, -1);
              }
              let words = sentence.split(" ");
              let wordIndex = matchStringAndGiveWordIndex(allWords, words);
              if (wordIndex !== -1) {
                timeStampObject[key].push({
                  index: i,
                });
              }
            });
          });
        });
        timestamps.push(timeStampObject);
      }
    }
  }
};
const arraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
};
const matchStringAndGiveWordIndexForDoc = (mainWords, subWords) => {
  for (let index = 0; index <= mainWords.length - subWords.length; index++) {
    if (
      arraysEqual(mainWords.slice(index, index + subWords.length), subWords)
    ) {
      return {
        paraIndex: index,
        ind: index + subWords.length,
      };
    }
  }

  return {
    paraIndex: -1,
    ind: -1,
  };
};
const matchStringAndGiveWordIndex = (mainWords, subWords) => {
  if (subWords.length >= 6) {
    const startPattern = subWords.slice(0, 3);
    const endPattern = subWords.slice(-3);

    for (
      let index = 0;
      index <= mainWords.length - startPattern.length;
      index++
    ) {
      if (arraysEqual(mainWords.slice(index, index + 3), startPattern)) {
        for (
          let endIndex = index;
          endIndex < mainWords.length - endPattern.length + 1;
          endIndex++
        ) {
          if (
            arraysEqual(mainWords.slice(endIndex, endIndex + 3), endPattern)
          ) {
            // Potential match found. This might be the beginning of your subWords in mainWords
            return index;
          }
        }
      }
    }
  } else {
    // If there are less than 6 words, perform the exact match
    for (let index = 0; index <= mainWords.length - subWords.length; index++) {
      if (
        arraysEqual(mainWords.slice(index, index + subWords.length), subWords)
      ) {
        return index;
      }
    }
  }

  return -1;
};
