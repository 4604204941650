import React from "react";
import { Spinner } from "react-bootstrap";
import style from "./style.module.css";
import { Topbar } from "./components/topbar";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const RedirectTest = () => {
  const params = new URLSearchParams(window.location.search);
  const testId = params.get("tId");
  const history = useHistory();
  const { currentTest } = useSelector((state) => state.test);
  const { account: { participant } = {} } = useSelector((state) => state.auth);

  const meetingLink =
    currentTest.meetingLink + "?pId=" + (participant?._id || participant);

  React.useEffect(() => {
    if (!testId) return history.push("/participant");

    if (!currentTest.meetingLink)
      return history.push(`/participant/welcome?tId=${testId}`);

    const timer = setTimeout(() => {
      window.location.href = meetingLink;
    }, 1000 * 2.5);

    return () => {
      clearTimeout(timer);
    };
  }, [currentTest.meetingLink, history, meetingLink, testId]);

  return (
    <div className={style.wrapper}>
      <Topbar />
      <div className={style.content}>
        <Spinner
          animation="border"
          variant="primary"
          className={style.spinner}
        />

        <p className="text-center">
          Redirecting you to our partner, Limeline, to conduct the interview.
          Please&nbsp;
          <button
            onClick={() => {
              window.open(meetingLink, "_blank");
            }}
            className={style.redirect}
          >
            click here
          </button>
          &nbsp;if you are not redirected in 30 seconds.
        </p>
      </div>
    </div>
  );
};
export default RedirectTest;
