/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import {
  MdDragIndicator,
  MdOutlineLibraryAdd,
  MdDelete,
  MdExpandMore,
  MdExpandLess,
  MdModeEdit,
} from "react-icons/md";
import style from "./draggable-item.module.css";
import Tooltiped from "../../../../../helpers/Tooltipped";

function DraggableItem({
  id,
  index,
  moveItem,
  item,
  handleEdit,
  handleDuplicate,
  handleDelete,
}) {
  const [, ref] = useDrag({
    type: "item",
    item: { id, index },
  });
  const [, drop] = useDrop({
    accept: "item",
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });
  const [displayMore, setDisplayMore] = useState(false);

  return (
    <div
      ref={(node) => ref(drop(node))}
      className={style.item}
      type={item.type}
      onDoubleClick={() => setDisplayMore(!displayMore)}
    >
      <MdDragIndicator className={style.icon} />
      <div className={style.info}>
        <div className={style.label}>{item.label}</div>
        {displayMore && item.placeholder && (
          <div className={style.placeholder}>{item.placeholder}</div>
        )}
        {displayMore && item.selectionConditionAI && (
          <div className={style.selectionConditionAI}>
            {item.selectionConditionAI}{" "}
            <span className={style.qualify}>(Qualify)</span>
          </div>
        )}
        {displayMore &&
          item.options.map((option, index) => (
            <div key={index} className={style.option}>
              {option.option}{" "}
              <span className={style.qualify}>
                {option.qualifyIfChosen ? "(Qualify)" : ""}
              </span>
            </div>
          ))}
        <div className={style.type}>{item.type}</div>
      </div>
      <div className={style.actions}>
        <div className={style.duplicateDelete}>
          <Tooltiped label="Duplicate">
            <MdOutlineLibraryAdd
              className={style.icon}
              onClick={() => handleDuplicate(index)}
            />
          </Tooltiped>
          <Tooltiped label="Edit">
            <MdModeEdit
              className={style.icon}
              onClick={() => handleEdit(index)}
            />
          </Tooltiped>
          <Tooltiped label="Delete">
            <MdDelete
              className={style.icon}
              onClick={() => handleDelete(index)}
            />
          </Tooltiped>
        </div>
        {displayMore ? (
          <MdExpandLess
            className={style.icon}
            onClick={() => setDisplayMore(!displayMore)}
          />
        ) : (
          <MdExpandMore
            className={style.icon}
            onClick={() => setDisplayMore(!displayMore)}
          />
        )}
      </div>
    </div>
  );
}

export default DraggableItem;
