import React from "react";
import {
  FiSearch,
  FiTag,
  FiDownload,
  FiSmile,
  FiTrash,
} from "react-icons/fi";
import { BsStars, BsStarFill } from "react-icons/bs";
import { PiSlidersFill } from "react-icons/pi";
import { RiShareForward2Line } from "react-icons/ri";
import { RxShare2 } from "react-icons/rx";
import { BiSolidVideos } from "react-icons/bi";
import { IoSync } from "react-icons/io5";
import { TbPhotoVideo } from "react-icons/tb";
// tools contain name, icon and file types for which tool would be shown
export const tools = {
  "Video/Audio": [
    {
      name: "Add a Tag",
      icon: <FiTag />,
    },
    {
      name: "Tag with AI",
      icon: <BsStars />,
    },
    {
      name: "Create a Clip",
      icon: <BiSolidVideos />,
    },
    {
      name: "Add an Insight",
      icon: <BsStarFill />,
    },
    {
      name: "Sentiment",
      icon: <FiSmile />,
    },
    {
      name: "Export Transcript",
      icon: <RxShare2 />,
    },
    {
      name: "Viewing Options",
      icon: <PiSlidersFill style={{ transform: "rotate(90deg)" }} />,
      type: "drop-down",
      subtabs: [
        [
          { name: "Player", type: "toggle" },
          { name: "Files list", type: "toggle" },
        ],
      ],
    },
  ],
  "Text/CSV/Chat": [
    {
      name: "Add a Tag",
      icon: <FiTag />,
    },
    {
      name: "Tag with AI",
      icon: <BsStars />,
    },
  ],
  insights: [
    {
      name: "Search",
      icon: <FiSearch />,
    },
    {
      name: "Add an Insight",
      icon: <BsStarFill />,
    },
    {
      name: "Get Insights with AI",
      icon: <BsStars />,
    },
    {
      name: "Regenerate Insights",
      icon: <IoSync />,
    },
    {
      name: "Delete Insight Type",
      icon: <FiTrash />,
    },
    {
      name: "Viewing Options",
      icon: <PiSlidersFill style={{ transform: "rotate(90deg)" }} />,
      type: "drop-down",
    },
  ],
  collection: [
    {
      name: "Search",
      icon: <FiSearch />,
    },
    {
      name: "Add an Section",
      icon: <BsStarFill />,
    },
    {
      name: "Export",
      icon: <RxShare2 />,
    },
    {
      name: "Share",
      icon: <RiShareForward2Line />,
    },
  ],
  tags: [
    { name: "Search", icon: <FiSearch /> },
    {
      name: "Add a New Tag",
      icon: <FiTag />,
    },
    {
      name: "Tag with AI",
      icon: <BsStars />,
    },
    {
      name: "Bulk Delete",
      icon: <FiTrash />,
    },
    {
      name: "Export CSV",
      icon: <RxShare2 />,
    },
  ],
  clips: [
    {
      name: "Search",
      icon: <FiSearch />,
    },
    {
      name: "Create a Clip",
      icon: <TbPhotoVideo />,
    },
    {
      name: "Filter by Tags",
      icon: <FiTag />,
    },
    {
      name: "Filter by Sessions",
      icon: <PiSlidersFill style={{ transform: "rotate(90deg)" }} />,
    },
  ],
  report: [
    {
      name: "Create with AI",
      icon: <BsStars />,
    },
    {
      name: "Share Report",
    },
    {
      name: "Export PDF",
    },
    {
      name: "Save",
    },
    // {
    //   name: "Insert",
    //   icon: <MdAddBox />,
    //   type: "drop-down",
    // },
    // {
    //   name: "Move",
    //   icon: <ImMoveUp />,
    //   type: "drop-down",
    // },
    // {
    //   name: "",
    //   icon: <FiType />,
    // },
    // {
    //   name: "",
    //   icon: <FiItalic />,
    // },
    // {
    //   name: "",
    //   icon: <FiBold />,
    // },
    // {
    //   name: "",
    //   icon: <FiLink />,
    // },
  ],
  presentation: [
    {
      name: "Create with AI",
      icon: <BsStars />,
    },
    // {
    //   name: "Previous Slide",
    //   icon: <FiChevronLeft style={{ fontSize: "1.25rem" }} />,
    // },
    // {
    //   name: "Next Slice",
    //   icon: <FiChevronRight style={{ fontSize: "1.25rem" }} />,
    // },
    {
      name: "Export PPTX",
      icon: <FiDownload />,
    },
    // {
    //   name: "Export",
    //   icon: <TfiExport />,
    // },
    // {
    //   name: "Delete",
    //   icon: <FiTrash/>,
    // },
  ],
};
