import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const SidebarProjectIcon = ({ initials, projectName, projectId }) => {
  return (
    <OverlayTrigger
      style={{ display: "inline" }}
      placement="right"
      key={"ot-"+projectId}
      delay={{ show: 80, hide: 200 }}
      overlay={(
        <Tooltip id={"button-tooltip-"+projectId} style={{ zIndex: 999999}}>
          {projectName}
        </Tooltip>)}
      popperConfig={{
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              altAxis: true,
              boundary: "viewport",
            },
          },
        ],
      }}
    >
      <div className="ue-project-initials" key={projectId}>{initials}</div>
    </OverlayTrigger>
  );
};

export default SidebarProjectIcon;