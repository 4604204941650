export default function formatTranscript(transcript, speakers) {
  if (!transcript || transcript.length === 0) {
    return "";
  }
  let utterances = transcript[0]?.utterances;
  let result = "";

  utterances.forEach((utterance) => {
    let speaker = getSpeakerNameAndRole(speakers, utterance.speaker);
    let words = utterance.words;
    let text = words.map((word) => word.text).join(" ");
    result += `${speaker?.name} ${
      speaker.role.trim().length > 0 ? `(${speaker.role})`: ""
    }: ${text}\n\n`;
  });
  return result;
}

const getSpeakerNameAndRole = (speakers, sentenceSpeaker) => {
  if (speakers && speakers.length > 0) {
    let spk = speakers.find((speaker) => {
      return speaker.label === sentenceSpeaker;
    });
    if (spk) {
      return spk;
    } else {
      return {
        name: "Speaker " + sentenceSpeaker,
        role: "",
      };
    }
  } else {
    return {
      name: "Speaker " + sentenceSpeaker,
      role: "",
    };
  }
};
