import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Switch from "./components/switch";
import Button from "./components/button";
import { FormControl } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { tools } from "./components/tools";
import {
  AddAIInsightBtn,
  AddAiTagBtn,
  AddAnInsightBtn,
  AddNewTag,
  AddSection,
  CreateClipBtn,
  CreateWithAIBtn,
  DeleteInsightBtn,
  ExportCollectionBtn,
  ExportTagsButton,
  ExportPDF,
  ExportTranscriptBtn,
  FilterBySessionBtn,
  FiterByTagBtn,
  RegenerateInsightsBtn,
  ShareCollectionBtn,
  ShareReportBtn,
  TagWithAIBtn,
  SentimentAnalysisBtn,
  DeleteTagBtn,
  CreateClipTrimBtn,
  AutoSave,
} from "./components/actionButtons";
import { ribbonActions } from "../../../../store/slices/ribbonSlice";
import { modalActions } from "../../../../store/slices/modalSlice";

function Ribbon({ type, activeTab, assetId, toolbarRef, assetType }) {
  const [toolbarWidth, setToolbarWidth] = useState(0);
  const [pressedBtn, setPressedBtn] = useState(null);

  const {
    data,
    pressedBtn: btn,
    showInsights,
  } = useSelector((state) => state.ribbon);
  const { account } = useSelector((state) => state.auth);
  const { sentiment } = useSelector((state) => state.sentiment);
  const { showSentiment } = useSelector((state) => state.ribbon);
  let isSentimentAvailable = sentiment
    .map((ele) => ele.assetId)
    .includes(assetId);

  const isFreeOrBasic =
    account?.plan?.type === "Free" ||
    account?.plan?.type === "Basic Monthly" ||
    account?.plan?.type === "Basic Yearly";
  const dispatch = useDispatch();
  useEffect(() => {
    // Update toolbar width when the component mounts or when type changes
    if (toolbarRef?.current) {
      setToolbarWidth(toolbarRef.current.clientWidth);
    }
    if (type) {
      dispatch(ribbonActions.setRibbonData({ name: "searchTerm", value: "" }));
    }
  }, [type, dispatch]);

  return (
    <div ref={toolbarRef} className={styles.toolbar}>
      <div className={styles.toolbar__section}>
        {type &&
          tools[type]?.map((ele, index) => {
            switch (ele.name) {
              case "Export PPTX":
                return (
                  <React.Fragment key={index}>
                    <Button
                      index={index}
                      ele={ele}
                      handleClick={() => {
                        if (isFreeOrBasic) {
                          dispatch(modalActions.showPayWallModal());
                        } else {
                          setPressedBtn(ele.name);
                          handleUrl(data?.presentation?.url);
                        }
                      }}
                      disabled={!data?.presentation?.url}
                      type={ele.type}
                      hideName={toolbarWidth < 1100}
                      data={data?.presentation}
                      btn={btn}
                    />
                  </React.Fragment>
                );
              case "Search":
                return (
                  <React.Fragment key={index}>
                    <div style={{ position: "relative" }}>
                      <FormControl
                        type="text"
                        placeholder="Search "
                        className={`mr-sm-2 ${styles.searchbar}`}
                        value={data?.searchTerm}
                        onChange={(e) =>
                          dispatch(
                            ribbonActions.setRibbonData({
                              name: "searchTerm",
                              value: e.target.value,
                            })
                          )
                        }
                      />
                      <div className={styles.searchicon}>{ele.icon}</div>
                    </div>
                    <span className={styles.toolbar__span}>|</span>
                  </React.Fragment>
                );
              case "Create with AI":
                return (
                  <React.Fragment key={index}>
                    {" "}
                    <CreateWithAIBtn
                      tab={activeTab}
                      hideName={toolbarWidth < 1100}
                    />
                  </React.Fragment>
                );
              case "Export PDF":
                return (
                  <React.Fragment key={index}>
                    <ExportPDF tab={activeTab} hideName={toolbarWidth < 1100} />
                  </React.Fragment>
                );
              case "Share Report":
                return (
                  <React.Fragment key={index}>
                    <ShareReportBtn
                      index={index}
                      ele={ele}
                      type={ele.type}
                      hideName={toolbarWidth < 1100}
                    />
                  </React.Fragment>
                );
              case "Add an Section":
                return (
                  <React.Fragment key={index}>
                    <AddSection hideName={toolbarWidth < 1100} />
                  </React.Fragment>
                );
              case "Share":
                return (
                  <React.Fragment key={index}>
                    <ShareCollectionBtn hideName={toolbarWidth < 1100} />{" "}
                  </React.Fragment>
                );
              case "Export":
                return (
                  <React.Fragment key={index}>
                    <ExportCollectionBtn hideName={toolbarWidth < 1100} />{" "}
                  </React.Fragment>
                );
              case "Export CSV":
                return (
                  <React.Fragment key={index}>
                    <ExportTagsButton hideName={toolbarWidth < 1100} />
                  </React.Fragment>
                );
              case "Save":
                return (
                  <React.Fragment key={index}>
                    <div className="ms-auto" />
                    <AutoSave />
                  </React.Fragment>
                );
              case "Bulk Delete":
                return (
                  <React.Fragment key={index}>
                    <DeleteTagBtn
                      hideName={toolbarWidth < 1100}
                      icon={ele?.icon}
                    />
                    <span className={styles.toolbar__span}>|</span>
                  </React.Fragment>
                );

              case "Tag with AI":
                if (activeTab === "tags") {
                  return (
                    <React.Fragment key={index}>
                      <AddAiTagBtn
                        assetId={assetId}
                        islast={index === tools[type].length - 1}
                        hideName={toolbarWidth < 1100}
                        icon={ele?.icon}
                      />{" "}
                    </React.Fragment>
                  );
                } else {
                  return (
                    <React.Fragment key={index}>
                      <TagWithAIBtn
                        hideName={toolbarWidth < 1100}
                        icon={ele?.icon}
                        islast={index === tools[type].length - 1}
                      />
                    </React.Fragment>
                  );
                }
              case "Add a Tag":
                return (
                  <React.Fragment key={index}>
                    <AddNewTag
                      hideName={toolbarWidth < 1100}
                      icon={ele?.icon}
                      activeTab={activeTab}
                      assetId={assetId}
                    />{" "}
                  </React.Fragment>
                );
              case "Add a New Tag":
                return (
                  <React.Fragment key={index}>
                    <AddNewTag
                      hideName={toolbarWidth < 1100}
                      icon={ele?.icon}
                      activeTab={activeTab}
                    />{" "}
                  </React.Fragment>
                );
              case "Filter by Tags":
                return (
                  <React.Fragment key={index}>
                    <FiterByTagBtn
                      hideName={toolbarWidth < 1100}
                      icon={ele?.icon}
                    />
                    <span className={styles.toolbar__span}>|</span>
                  </React.Fragment>
                );
              case "Filter by Sessions":
                return (
                  <React.Fragment key={index}>
                    <FilterBySessionBtn
                      hideName={toolbarWidth < 1100}
                      icon={ele?.icon}
                    />
                  </React.Fragment>
                );
              case "Get Insights with AI":
                return (
                  <React.Fragment key={index}>
                    <AddAIInsightBtn
                      hideName={toolbarWidth < 1100}
                      icon={ele?.icon}
                    />
                  </React.Fragment>
                );
              case "Regenerate Insights":
                return (
                  <React.Fragment key={index}>
                    <RegenerateInsightsBtn
                      hideName={toolbarWidth < 1100}
                      icon={ele?.icon}
                      videoId={assetId}
                    />{" "}
                  </React.Fragment>
                );
              case "Delete Insight Type":
                return (
                  <React.Fragment key={index}>
                    <DeleteInsightBtn
                      hideName={toolbarWidth < 1100}
                      icon={ele?.icon}
                      videoId={assetId}
                    />{" "}
                  </React.Fragment>
                );
              case "Add an Insight":
                return (
                  <React.Fragment key={index}>
                    <AddAnInsightBtn
                      hideName={toolbarWidth < 1100}
                      icon={ele?.icon}
                      activeTab={activeTab}
                    />
                    <span className={styles.toolbar__span}>|</span>
                  </React.Fragment>
                );
              case "Sentiment":
                return (
                  <React.Fragment key={index}>
                    <SentimentAnalysisBtn
                      hideName={toolbarWidth < 1100}
                      icon={ele?.icon}
                      assetId={assetId}
                      name={ele.name}
                    />
                  </React.Fragment>
                );
              case "Create a Clip":
                return (
                  <React.Fragment key={index}>
                    {activeTab === "clips" ? (
                      <CreateClipTrimBtn
                        hideName={toolbarWidth < 1100}
                        icon={ele?.icon}
                        assetId={assetId}
                      />
                    ) : (
                      <CreateClipBtn
                        hideName={toolbarWidth < 1100}
                        icon={ele?.icon}
                        assetId={assetId}
                      />
                    )}

                    <span className={styles.toolbar__span}>|</span>
                  </React.Fragment>
                );
              case "Export Transcript":
                return (
                  <React.Fragment key={index}>
                    <ExportTranscriptBtn
                      hideName={toolbarWidth < 1100}
                      icon={ele?.icon}
                      assetId={assetId}
                    />
                  </React.Fragment>
                );
              default:
                return index === tools[type].length - 1 ? (
                  <React.Fragment key={index}>
                    {ele?.icon ? (
                      <Button
                        assetType={assetType}
                        index={index}
                        ele={ele}
                        pressed={pressedBtn === ele.name}
                        handleClick={() => {
                          setPressedBtn(ele.name);
                        }}
                        activeTab={activeTab}
                        type={ele.type}
                        hideName={toolbarWidth < 1100}
                      />
                    ) : (
                      ele?.component
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment key={index}>
                    <Button
                      index={index}
                      ele={ele}
                      pressed={pressedBtn === ele.name}
                      handleClick={() => {
                        setPressedBtn(ele.name);
                      }}
                      type={ele.type}
                      hideName={toolbarWidth < 1100}
                      activeTab={activeTab}
                    />
                    <span className={styles.toolbar__span}>|</span>
                  </React.Fragment>
                );
            }
          })}
      </div>
      {type === "tags" && (
        <Switch
          leftlabel={"Board"}
          rightlabel={"Chart"}
          isRight={data?.chart === undefined ? false : data?.chart}
          handleRight={() => {
            dispatch(
              ribbonActions.setRibbonData({
                name: "chart",
                value: !data?.chart === undefined ? true : !data?.chart,
              })
            );
          }}
        />
      )}
      {type === "Video/Audio" && (
        <Switch
          isSentimentAvailable={isSentimentAvailable && showSentiment}
          leftlabel={"Tags"}
          rightlabel={"Insights"}
          isRight={!showInsights}
          handleRight={() =>
            dispatch(ribbonActions.setInsights({ showInsights: !showInsights }))
          }
        />
      )}
    </div>
  );
}
const handleUrl = (url) => {
  window.open(url, "_blank");
};
Ribbon.defaultProps = {
  type: "Video/Audio",
};
export default Ribbon;
