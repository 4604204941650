import React, { useEffect } from "react";
import styles from "./index.module.css";
import { useRef } from "react";
import { loadAsset } from "../../../../store/actions/currentProjectActions";
import TranscriptPrinter from "./transcriptPrinter";
import { useState } from "react";
import AssetPlayer from "./assetPlayer";
import AssetShower from "./assetShower";
import InsightsTab from "./insights";
import AITagsModal from "../modals/addAiTagsModal";
import {
  createCardAction,
  createLaneAction,
} from "../../../../store/actions/laneActions";
import { useDispatch, useSelector } from "react-redux";
import CsvTable from "./csvTable";
import TranscriptLoadingState from "../ProcessingStates";
import Sentiment from "./sentiment";
import { ribbonActions } from "../../../../store/slices/ribbonSlice";

function ContentArea({
  selectedVideo,
  activeTab,
  assetType,
  toolbarRef,
  activeView,
  isSmallScreen,
}) {
  const [currentPlayerTime, setCurrentPlayerTime] = useState(0);
  const { isPublic } = useSelector((state) => state.currentProject);
  const { project: projectData } = useSelector((state) => state.currentProject);
  const { data, assetSpeakers } = useSelector((state) => state.ribbon);
  const resizerX = useRef(null);
  const videoRef = useRef(null);
  const { lanes } = useSelector((state) => state.lanes);
  const dispatch = useDispatch();
  const { sentiment } = useSelector((state) => state.sentiment);
  const { showSentiment } = useSelector((state) => state.ribbon);

  const assetSentiment = sentiment?.filter(
    (a) => a.assetId === selectedVideo?._id
  );
  let canShowSentiment = showSentiment && assetSentiment.length > 0;

  useEffect(() => {
    async function getSpeakers() {
      const { data } = await loadAsset(selectedVideo._id);
      if (data) {
        dispatch(ribbonActions.setSpeakers(data.speakers));
      }
    }

    getSpeakers();
  }, [selectedVideo?._id, assetType]);

  const isCSV = assetType === "CSV";
  let transcript = selectedVideo?.transcript;
  let isProcessingTranscript = projectData?.status === "Preparing";
  let isProcessingInsights = projectData?.status === "Preparing";

  const handleSeek = (time) => {
    videoRef.current.pause();
    if (videoRef.current) {
      videoRef.current.currentTime = time / 1000; // Convert to seconds
    }
    var timer = setInterval(function () {
      if (
        (videoRef.current.paused && videoRef.current.readyState === 4) ||
        !videoRef.current.paused
      ) {
        videoRef.current.play();
        clearInterval(timer);
      }
    }, 50);
  };

  const handleMouseDownX = () => {
    document.addEventListener("mousemove", handleMouseMoveX);
    document.addEventListener("mouseup", handleMouseUpX);
  };

  const handleMouseMoveX = (e) => {
    //logic remains the same but now referring to the resizer
    e.preventDefault();
    const clientX = e.clientX;
    const deltaX = clientX - (resizerX.current.clientX || clientX);
    resizerX.current.clientX = clientX;
    const l = resizerX.current.previousElementSibling;
    const r = resizerX.current.nextElementSibling;
    // LEFT
    if (deltaX < 0) {
      const w = Math.round(parseInt(getComputedStyle(l).width) + deltaX);
      l.style.flex = `0 ${w < 200 ? 200 : w}px`;
      r.style.flex = "1 0";
    }
    // RIGHT
    if (deltaX > 0) {
      const w = Math.round(parseInt(getComputedStyle(r).width) - deltaX);
      r.style.flex = `0 ${w < 200 ? 200 : w}px`;
      l.style.flex = "1 0";
    }
  };

  const handleMouseUpX = (e) => {
    e.preventDefault();
    document.removeEventListener("mousemove", handleMouseMoveX);
    document.removeEventListener("mouseup", handleMouseUpX);
    resizerX.current.clientX = null;
  };

  // touch might have problems , check it out
  const handleTouchStartX = () => {
    document.addEventListener("touchmove", handleTouchMoveX);
    document.addEventListener("touchend", handleTouchEndX);
  };

  const handleTouchMoveX = (e) => {
    e.preventDefault();
    const clientX = e.touches[0].clientX;
    const deltaX = clientX - (resizerX.current.clientX || clientX);
    resizerX.current.clientX = clientX;
    const l = resizerX.current.previousElementSibling;
    const r = resizerX.current.nextElementSibling;
    // LEFT
    if (deltaX < 0) {
      const w = Math.round(parseInt(getComputedStyle(l).width) + deltaX);
      l.style.flex = `0 ${w < 200 ? 200 : w}px`;
      r.style.flex = "1 0";
    }
    // RIGHT
    if (deltaX > 0) {
      const w = Math.round(parseInt(getComputedStyle(r).width) - deltaX);
      r.style.flex = `0 ${w < 200 ? 200 : w}px`;
      l.style.flex = "1 0";
    }
  };

  const handleTouchEndX = (e) => {
    e.preventDefault();
    document.removeEventListener("touchmove", handleTouchMoveX);
    document.removeEventListener("touchend", handleTouchEndX);
    resizerX.current.clientX = null;
  };

  const collectInsight = async (insight, singleInsight, videoId) => {
    let uncategorizedLaneId;

    let uncategorizedLane =
      lanes && lanes.find((lane) => lane.title === insight?.insightType);

    if (uncategorizedLane === undefined) {
      const newLane = await dispatch(
        createLaneAction(insight?.insightType, projectData._id)
      );
      uncategorizedLaneId = newLane._id;
    } else {
      uncategorizedLaneId = uncategorizedLane._id;
    }

    // in type also add the name of the video

    const payload = {
      laneId: uncategorizedLaneId,
      insight: singleInsight.insightText,
      source: singleInsight.source,
      type: [selectedVideo.name],
      projectId: projectData._id,
      notes: "",
    };

    await dispatch(createCardAction(payload));
  };
  let showVideoPlayer =
    data?.videoPlayer !== undefined ? data.videoPlayer : true;

  if (isSmallScreen) {
    if (activeView === "Files") {
      return null;
    }
    if (activeView === "Transcript") {
      showVideoPlayer = false;
    }
    if (activeView === "Video") {
      showVideoPlayer = true;
      return (
        <div className={styles.videoArea}>
          <AssetPlayer
            isVideo={assetType === "VIDEO"}
            selectedVideo={selectedVideo}
            videoRef={videoRef}
            currentPlayerTime={currentPlayerTime}
            setCurrentPlayerTime={setCurrentPlayerTime}
          />
          <AssetShower
            selectedVideo={selectedVideo}
            videoRef={videoRef}
            setCurrentPlayerTime={setCurrentPlayerTime}
          />
        </div>
      );
    }
  }
  return (
    <div className={styles.contentContainer}>
      {assetType === "VIDEO" || assetType === "AUDIO" ? (
        <>
          <div
            className={`${styles.textArea} ${
              activeTab === "insights" ? styles.insightsArea : ""
            }`}
            style={{ flex: "70%" }}
          >
            {activeTab === "data" ? (
              <>
                {!isPublic && (
                  <AITagsModal
                    assetId={selectedVideo._id}
                    projectId={selectedVideo.projectId}
                  />
                )}

                {isProcessingTranscript ? (
                  <TranscriptLoadingState />
                ) : (
                  <TranscriptPrinter
                    toolbarRef={toolbarRef}
                    handleSeek={handleSeek}
                    transcript={transcript}
                    assetType={assetType}
                    allSpeakers={assetSpeakers}
                    selectedVideo={selectedVideo}
                    currentWord={currentPlayerTime * 1000}
                    collectInsight={collectInsight}
                  />
                )}
              </>
            ) : (
              <>
                {isProcessingInsights ? (
                  <div style={{ padding: "1rem" }}>
                    <TranscriptLoadingState />
                  </div>
                ) : (
                  <InsightsTab
                    selectedVideo={selectedVideo}
                    handleSeek={handleSeek}
                  />
                )}
              </>
            )}
          </div>
          {showVideoPlayer && (
            <>
              <div
                className={styles.resizer}
                ref={resizerX}
                onMouseDown={handleMouseDownX}
                onTouchStart={handleTouchStartX}
              ></div>
              <div className={styles.videoArea} style={{ flex: "30%" }}>
                {canShowSentiment && (
                  <Sentiment
                    assetSentiment={assetSentiment}
                    speakers={assetSpeakers}
                  />
                )}
                <AssetPlayer
                  isVideo={assetType === "VIDEO"}
                  selectedVideo={selectedVideo}
                  videoRef={videoRef}
                  currentPlayerTime={currentPlayerTime}
                  setCurrentPlayerTime={setCurrentPlayerTime}
                />
                <AssetShower
                  selectedVideo={selectedVideo}
                  videoRef={videoRef}
                  setCurrentPlayerTime={setCurrentPlayerTime}
                />
              </div>
            </>
          )}
        </>
      ) : (
        <div
          className={`${styles.textArea}  ${
            activeTab === "insights" ? styles.insightsArea : ""
          }`}
        >
          {activeTab === "data" ? (
            isCSV ? (
              <CsvTable
                projectId={projectData._id}
                assetId={selectedVideo._id}
                rawData={selectedVideo?.transcript?.[0]?.text || ""}
              />
            ) : (
              <>
                {!isPublic && (
                  <AITagsModal
                    assetId={selectedVideo._id}
                    projectId={selectedVideo.projectId}
                  />
                )}

                {isProcessingTranscript ? (
                  <TranscriptLoadingState />
                ) : (
                  <TranscriptPrinter
                    transcript={transcript}
                    assetType={assetType}
                    toolbarRef={toolbarRef}
                    selectedVideo={selectedVideo}
                  />
                )}
              </>
            )
          ) : (
            <>
              {isProcessingInsights ? (
                <div style={{ padding: "1rem" }}>
                  <TranscriptLoadingState />
                </div>
              ) : (
                <InsightsTab selectedVideo={selectedVideo} />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default ContentArea;
