import React from "react";
import styles from "./index.module.css";
function StatCard({ data }) {
  return (
    <div>
      <div className={styles.statCard}>
        <div className={styles.statCardInfo}>
          <h3>{data.count}</h3>
          <p>{data._id}</p>
        </div>
      </div>
    </div>
  );
}

export default StatCard;
