import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { editVideoName } from "../../../../../store/actions/projectActions";
import { toastSuccess, toastError } from "../../../../../utils/toast";

export default function EditVideoTitleModal({ show, onClose, videoId, onChange, ...props }) {
    const [title, setTitle] = useState(props.videoTitle);

    useEffect(() => {
        setTitle(props.videoTitle);
    }, [props.videoTitle]);
    
    const handleSave = async () => {
        const { data, error } = await editVideoName(
            title, videoId
        );
        // if asset title is empty - toast error
        if (title === "") {
            toastError("File name cannot be empty");
            return;
        }
        if (data) {
            toastSuccess("File name updated successfully");
            typeof onChange === "function" && onChange(title);
        } else {
            console.error(error)
        }
        onClose();
    }

    return <Modal
        show={show}
        onHide={onClose}
        size="md">
        <Modal.Header closeButton>
            <Modal.Title>Rename</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {/* prevent form enter */}
            <Form onSubmit={(e) => e.preventDefault()}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>File name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter file name"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                handleSave();
                            }
                        }}
                    />
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button
                variant="secondary"
                onClick={onClose}>
                Close
            </Button>
            <Button
                variant="primary"
                onClick={handleSave}>
                Save Changes
            </Button>
        </Modal.Footer>
    </Modal>
}