import React, { useEffect, useRef, useState } from "react";
import {
  addTagToVideo,
  getVideoTagsByOrganization,
} from "../../store/actions/mediaLibraryActions";
import styles from "./addFile.module.css";
import { useSelector } from "react-redux";
export function TagPopUp({ isOpen, assetId, setIsOpen, parentBtnRef }) {
  const [tag, setTag] = useState("");
  const [tags, setTags] = useState([]);
  const tagRef = useRef(null);
  const { account } = useSelector((state) => state.auth);
  const organizationId = account?.organization;

  useEffect(() => {
    if (organizationId) {
      const fetchTags = async () => {
        let ts = await getVideoTagsByOrganization(organizationId);
        setTags(ts.data);
      };
      fetchTags();
    }
  }, [organizationId, isOpen]);
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (parentBtnRef) {
        if (
          (tagRef.current && !tagRef.current.contains(e.target)) &&
          (parentBtnRef.current && !parentBtnRef.current?.contains(e.target))
        ) {
          setIsOpen(false);
        }
      } else {
        if (tagRef.current && !tagRef.current.contains(e.target)) {
          setIsOpen(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  let allTagsTitle = Array.from(
    tags
      ?.reduce((uniqueMap, tag) => {
        uniqueMap.set(tag?.title, {
          title: tag?.title,
          organizationId: tag?.organizationId,
        });
        return uniqueMap;
      }, new Map())
      .values()
  );

  useEffect(() => {
    setTag("");
  }, [isOpen]);

  if (!isOpen) return null;

  const filteredTags = allTagsTitle.filter((item) =>
    item?.title?.toLowerCase()?.includes(tag.toLowerCase())
  );

  return (
    <div className={styles.tagsContainer} ref={tagRef}>
      <input
        type="text"
        value={tag}
        placeholder="Search tags or type to create a new..."
        onChange={(e) => {
          setTag(e.target.value);
        }}
      />
      <div className={styles.tagsShower}>
        <div style={{ width: "100%" }}>
          {
            <>
              {tag !== "" ? (
                filteredTags.length > 0 ? (
                  filteredTags.map((item, index) => {
                    return (
                      <span
                        onClick={() => {
                          addTagToVideo(
                            item.title,
                            assetId,
                            item.organizationId
                          );
                          setIsOpen(false);
                        }}
                        key={index}
                        className={styles.tag}
                        style={{
                          background: "var(--grey3)",
                          padding: "2px 10px",
                          marginBottom: "12px",
                        }}
                      >
                        {item.title}
                      </span>
                    );
                  })
                ) : (
                  <div
                    style={{ display: "flex" }}
                    onClick={() => {
                      addTagToVideo(tag, assetId, organizationId);
                      setIsOpen(false);
                    }}
                  >
                    <button className={styles.createBtn}>Create</button>
                    <span
                      className={styles.tag}
                      style={{
                        background: "var(--grey3)",
                        padding: "2px 10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {tag}
                    </span>
                  </div>
                )
              ) : allTagsTitle?.length > 0 ? (
                allTagsTitle.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={styles.tagsOuter}
                      onClick={() => {
                        addTagToVideo(item.title, assetId, organizationId);
                        setIsOpen(false);
                      }}
                    >
                      <span
                        className={styles.tag}
                        style={{
                          background: item?.color,
                          padding: "2px 10px",
                          border: "none",
                        }}
                      >
                        {item.title}
                      </span>
                    </div>
                  );
                })
              ) : (
                <p style={{ fontSize: "0.9rem" }}>
                  No tags added yet. Type to add the first tag.
                </p>
              )}
            </>
          }
        </div>
      </div>
    </div>
  );
}
