import store from "..";
import { callExternalApi } from "../../services/external-api.service";
import { organizationActions } from "../slices/organizationSlice";
const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
const { dispatch } = store;

export const getOrganizationById = async (organizationId) => {
  dispatch(organizationActions.requestStart());
  const config = {
    url: `${apiServerUrl}/api/account/organization/${organizationId}`,
    method: "GET",
  };

  const { data, error } = await callExternalApi({ config });
  if (error) dispatch(organizationActions.requestFail(error.message));
  else dispatch(organizationActions.setOrganization({ organization: data }));
};

export const uploadOrganisationLogo = async ({organizationId, logo}) => {
  const config = {
      url: `${apiServerUrl}/api/account/organization/${organizationId}/logo`,
      method: "POST",
      data:logo
    };
    const { data, error } = await callExternalApi({ config });
    if(data){
      dispatch(organizationActions.addLogo({ logo: data?.logo }));
    }
    if(error){
      console.error(error);
    }
};

export const updateOrganizationName = async (organizationId, name) => {
  const config = {
    url: `${apiServerUrl}/api/account/organization/${organizationId}/name`,
    method: "PATCH",
    data: { name },
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};
