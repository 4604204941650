import React from 'react';
import {
    Container,
} from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { ProjectNote } from "../components/project-note";


export default function ProjectNotePage(){
    const params = useParams()
    const history = useHistory()
    const setPageUpdated = (pageId) => {
        history.push(`/p/${params.projectId}/notes/${pageId}`)
    };
    return <Container className="mt-3">
    <ProjectNote currentNotePage={params.noteId} setPageUpdated={setPageUpdated} />
  </Container>
}