import { createSlice } from "@reduxjs/toolkit";

const highlightSlice = createSlice({
    name: "highlight",
    initialState: {
        highlights: [],
    },
    reducers: {
        setHighlights(state, action) {
            let flag = false;
            state.highlights.forEach((ele, index) => {
                if (ele.start === action.payload.start && ele.end === action.payload.end) {
                    state.highlights.splice(index, 1)
                    flag = true;
                }
            })
            if (!flag) {
                state.highlights.push(action.payload)
            }
        },
        resetHighlights(state) {
            state.highlights = [];
        }
    }
});

export default highlightSlice;
export const highlightActions = highlightSlice.actions;
