import React from "react";
import { Alert, Button, Spinner } from "react-bootstrap";
import styles from "./index.module.css";
import { Skeleton } from "@mantine/core";
import { FiActivity, FiMessageCircle, FiFilm, FiTag } from "react-icons/fi";
import { HiOutlinePresentationChartLine } from "react-icons/hi";

function TagsLoadingState() {
  return (
    <div className={styles.tcProcessingContainer}>
      <Alert
        variant="primary"
        className={styles.ueAlert}
        style={{ width: "100%" }}
      >
        <Spinner animation="border" variant="primary" />
        <p style={{ margin: 0 }}>
          Processing your file. It may take a few minutes to complete...
        </p>
      </Alert>
      <ProcessingState tab={"tags"} />
    </div>
  );
}
export function CRPLoadingState({ type }) {
  if (type === "chat") {
    return <ChatLoadingState />;
  } else if (type === "report") {
    return <ReportLoadingState />;
  } else if (type === "presentation") {
    return <PresentationLoadingState />;
  }
}
export function TCLoadingState({ type }) {
  if (type === "tags") {
    return <TagsLoadingState />;
  }
  return null;
}

export function SignUpState({ type }) {
  const getIcon = (tab) => {
    switch (tab) {
      case "collection":
        return <FiTag />;
      case "chat":
        return <FiMessageCircle />;
      case "presentation":
        return <HiOutlinePresentationChartLine />;
      case "report":
        return <FiActivity />;
      case "clips":
        return <FiFilm />;
      

      default:
        return null;
    }
  };
  const publicGetContent = (tab) => {
    switch (tab) {
      case "collection":
        return (
          <>
            <p>AI Insight Collection</p>
            <p>
              Sign up for free to be able to collect AI insights in a kanban
              collection.
            </p>
            <Button
              variant="outline-primary"
              href="https://app.userevaluation.com/signup"
              target="_blank"
              className={styles.createClipBtn}
            >
              Sign up for free
            </Button>
          </>
        );
      case "chat":
        return (
          <>
            <p>Chat with AI about your data</p>
            <p>
              Sign up for free to be able to chat with AI about all your data.
            </p>
            <Button
              variant="outline-primary"
              href="https://app.userevaluation.com/signup"
              target="_blank"
              className={styles.createClipBtn}
            >
              Sign up for free
            </Button>
          </>
        );
      case "presentation":
        return (
          <>
            <p>Generate AI Presentations</p>
            <p>
              Sign up for free to be able to generate AI presentations in PPTX
              format.
            </p>
            <Button
              variant="outline-primary"
              href="https://app.userevaluation.com/signup"
              target="_blank"
              className={styles.createClipBtn}
            >
              Sign up for free
            </Button>
          </>
        );
      case "report":
        return (
          <>
            <p>Generate AI Reports</p>
            <p>Sign up for free to be able to generate AI reports.</p>
            <Button
              variant="outline-primary"
              href="https://app.userevaluation.com/signup"
              target="_blank"
              className={styles.createClipBtn}
            >
              Sign up for free
            </Button>
          </>
        );
      case "clips":
        return (
          <>
            <p>Create Clips</p>
            <p>
              Sign up for free to be able to create clips from your audio and
              video files.
            </p>
            <Button
              variant="outline-primary"
              href="https://app.userevaluation.com/signup"
              target="_blank"
              className={styles.createClipBtn}
            >
              Sign up for free
            </Button>
          </>
        );
      default:
        return null;
    }
  };
  return (
    <div className={styles.processingContainer}>
      <div className={styles.processingState}>
        <div className={styles.icon}>{getIcon(type)}</div>
        <div className={styles.text}>{publicGetContent(type)}</div>
      </div>
    </div>
  );
}
export function ClipsLoadingState() {
  return (
    <div className={styles.clipsProcessingContainer}>
      <ProcessingState tab={"clips"} />
    </div>
  );
}

function ChatLoadingState() {
  return (
    <div className={styles.crpProcessingContainer}>
      <Alert
        variant="primary"
        className={styles.ueAlert}
        style={{ width: "100%" }}
      >
        <Spinner animation="border" variant="primary" />
        <p style={{ margin: 0 }}>
          One or more files are being processed. Please allow a few minutes to
          complete...
        </p>
      </Alert>
      <ProcessingState tab={"chat"} />
    </div>
  );
}

function ReportLoadingState() {
  return (
    <div className={styles.crpProcessingContainer}>
      <Alert
        variant="primary"
        className={styles.ueAlert}
        style={{ width: "100%" }}
      >
        <Spinner animation="border" variant="primary" />
        <p style={{ margin: 0 }}>
          One or more files are being processed. Please allow a few minutes to
          complete...
        </p>
      </Alert>
      <ProcessingState tab={"report"} />
    </div>
  );
}
function PresentationLoadingState() {
  return (
    <div className={styles.crpProcessingContainer}>
      <Alert
        variant="primary"
        className={styles.ueAlert}
        style={{ width: "100%" }}
      >
        <Spinner animation="border" variant="primary" />
        <p style={{ margin: 0 }}>
          Processing your file. It may take a few minutes to complete...
        </p>
      </Alert>
      <ProcessingState tab={"presentation"} />
    </div>
  );
}

function TranscriptLoadingState() {
  return (
    <div className={styles.processingContainer}>
      <Alert variant="primary" className={styles.ueAlert}>
        <Spinner animation="border" variant="primary" />
        <p style={{ margin: 0 }}>
          Processing your file. It may take a few minutes to complete...
        </p>
      </Alert>
      <SkeletonDesign />
    </div>
  );
}
const ProcessingState = ({ tab }) => {
  const getIcon = (tab) => {
    switch (tab) {
      case "tags":
        return <FiTag />;
      case "chat":
        return <FiMessageCircle />;
      case "presentation":
        return <HiOutlinePresentationChartLine />;
      case "report":
        return <FiActivity />;
      case "clips":
        return <FiFilm />;

      default:
        return null;
    }
  };

  const getContent = (tab) => {
    switch (tab) {
      case "tags":
        return (
          <>
            <p>No tags added yet</p>
            <p>
              You can use AI to tag your file content or add tags manually by
              selecting text.
            </p>
          </>
        );
      case "chat":
        return (
          <>
            <p>Waiting for files to finish processing</p>
            <p>
              AI Chat will activate when all your files have completed
              processing.
            </p>
          </>
        );
      case "presentation":
        return (
          <>
            <p>No presentations found</p>
            <p>
              You have not created any presentation yet. Click Create with AI in
              the toolbar to create a presentation using AI.
            </p>
            <Button
              variant="outline-primary"
              href="https://docs.userevaluation.com/docs/guides/creating-an-ai-presentation"
              target="_blank"
              className={styles.createClipBtn}
            >
              How to create an AI Presentation?
            </Button>
          </>
        );
      case "report":
        return (
          <>
            <p>No reports found</p>
            <p>
              You have not created any report yet. Click Create with AI in the
              toolbar to create a report using AI.
            </p>
            <Button
              variant="outline-primary"
              href="https://docs.userevaluation.com/docs/guides/creating-an-ai-report"
              target="_blank"
              className={styles.createClipBtn}
            >
              How to create an AI Report?
            </Button>
          </>
        );
      case "clips":
        return (
          <>
            <p>No clips found</p>
            <p>
              You have not created any clips yet. Head over to a video or audio
              file and select text in transcript to create a clip.
            </p>
            <Button
              variant="outline-primary"
              className={styles.createClipBtn}
              href="https://docs.userevaluation.com/docs/guides/create-and-download-clips"
              target="_blank"
            >
              How to create a clip?
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.processingContainer}>
      <div className={styles.processingState}>
        <div className={styles.icon}>{getIcon(tab)}</div>
        <div className={styles.text}>{getContent(tab)}</div>
      </div>
    </div>
  );
};

const SkeletonDesign = () => {
  return (
    <div>
      <Skeleton height={20} width="40%" radius="xl" />
      <Skeleton height={20} mt={12} width="80%" radius="xl" />
      <Skeleton height={20} mt={12} width="80%" radius="xl" />
      <Skeleton height={20} mt={12} width="60%" radius="xl" />

      <br />
      <br />
      <Skeleton height={20} width="40%" radius="xl" />
      <Skeleton height={20} mt={12} width="80%" radius="xl" />
      <Skeleton height={20} mt={12} width="80%" radius="xl" />
      <Skeleton height={20} mt={12} width="60%" radius="xl" />

      <br />
      <br />
      <Skeleton height={20} width="40%" radius="xl" />
      <Skeleton height={20} mt={12} width="80%" radius="xl" />
      <Skeleton height={20} mt={12} width="80%" radius="xl" />
      <Skeleton height={20} mt={12} width="60%" radius="xl" />
    </div>
  );
};

export default TranscriptLoadingState;
