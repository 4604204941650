import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as PropTypes from "prop-types";
import { ListGroup, Table, Modal, Badge } from "react-bootstrap";
import ReactFlow, {
  MiniMap,
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
} from "reactflow";
import UserAvatar from "../userAvatar";
import LogoWhiteSmall from "../../images/logo-white-small.svg";
import LogoBlackSmall from "../../images/logo-black-small.svg";
import { FiCopy, FiCheck } from "react-icons/fi";
// import "./message.css";
import styles from "./message.module.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import "reactflow/dist/style.css";
// import getTimeSince from "../../utils/getTimeSince";
import { ErrorBoundary } from "react-error-boundary";
import Markdown from "react-markdown";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const components = {
  p: ({ node, children, ...props }) => {
    return (
      <p {...props}>
        {children}&nbsp;
        <span className="blink-cursor" />
      </p>
    );
  },
  div: ({ node, children, ...props }) => {
    return (
      <div {...props}>
        {children}&nbsp;
        <span className="blink-cursor" />
      </div>
    );
  },
  li: ({ node, children, ...props }) => {
    return (
      <li {...props}>
        {children}&nbsp;
        <span className="blink-cursor" />
      </li>
    );
  },
};

const Graph = ({ options, data, description, typing }) => {
  if (!data || !data.datasets || !data.labels) return null;

  return (
    <div>
      <div
        style={{
          width: "600px",
          maxWidth: "50vw",
        }}
      >
        <Bar options={options} data={data} />
      </div>
      <UnstyledMessage message={{ message: description, typing }} />
    </div>
  );
};

const PieChart = ({ data, description, typing }) => {
  if (!data || !data.datasets || !data.labels) return null;

  return (
    <div>
      <div
        style={{
          width: "600px",
          maxWidth: "50vw",
        }}
      >
        <Pie data={data} />
      </div>
      <UnstyledMessage message={{ message: description, typing }} />
    </div>
  );
};
let id = 0;
const getId = () => `dndnode_${id++}`;
function Flow({ nodes: n, edges: e, description, typing }) {
  const [nodes, setNodes, onNodesChange] = useNodesState(n);
  const [edges, setEdges, onEdgesChange] = useEdgesState(e);
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );
  useEffect(() => {
    if (n && e) {
      setNodes(n);
      setEdges(e);
    }
  }, [n, e]);

  const reactFlowWrapper = useRef(null);

  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);
  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const type = event.dataTransfer.getData("application/reactflow");

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const newNode = {
        id: getId(),
        type,
        position,
        data: { label: `${type} node` },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance]
  );
  if (nodes && edges) {
    return (
      <>
        <ReactFlowProvider>
          {" "}
          <div
            className="reactflow-wrapper"
            ref={reactFlowWrapper}
            style={{
              width: "100%",

              height: "500px",
            }}
          >
            <ReactFlow
              style={{
                width: "100%",
                height: "100%",
              }}
              onConnect={onConnect}
              onInit={setReactFlowInstance}
              onDrop={onDrop}
              onDragOver={onDragOver}
              fitView
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              nodes={nodes || []}
              edges={edges || []}
            >
              <MiniMap />
              <Controls />
              <Background />
            </ReactFlow>
          </div>
        </ReactFlowProvider>

        <UnstyledMessage message={{ message: description, typing }} />
      </>
    );
  } else return null;
}

// a badge which opens a modal on click
const CitationPopup = ({ citation, index }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div
      style={{
        display: "inline-block",
        marginRight: 4,
        cursor: "pointer",
      }}
    >
      <Badge bg="primary" onClick={handleShow}>
        {index + 1}
      </Badge>

      <Modal
        show={show}
        onHide={handleClose}
        style={{ zIndex: 9999 }}
        id={`citation-modal-` + index}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Source</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <b>{citation?.assetName}</b>
          </p>
          <p>&quot;{citation?.excerpt}&quot;</p>
        </Modal.Body>
      </Modal>
    </div>
  );
};
function Message({ message }) {
  const [copied, setCopied] = useState(false);

  // function to change copied icon to tick after 1 second
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  // Function to parse table from the message
  const parseTable = (messageText) => {
    const tableLines = messageText
      .split("\n")
      .filter((line) => {
        if (!line.startsWith("|")) {
          return false;
        }

        // Exclude separator line
        if (/^\|[:-\s]+\|$/.test(line)) {
          return false;
        }

        return true;
      })
      .map((line) => line.split("|").slice(1, -1));

    // Separate the first row as header
    const headerRow = tableLines.shift();

    const header = (
      <thead>
        <tr>
          {headerRow?.map((cell, cellIndex) => (
            <th key={cellIndex}>{cell.trim()}</th>
          ))}
        </tr>
      </thead>
    );

    const body = (
      <tbody>
        {tableLines.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td key={cellIndex}>{cell.trim()}</td>
            ))}
          </tr>
        ))}
      </tbody>
    );

    return (
      <Table bordered hover className="chat-table">
        {header}
        {body}
      </Table>
    );
  };

  function getMessageAvatar() {
    if (message.hideAvatar) {
      return null;
    }
    return message.sender === "AI" ? (
      message.messageType !== "Instructions" ? (
        <div
          className="rounded-circle"
          style={{
            backgroundColor: "var(--grey10)",
            height: "40px",
            aspectRatio: "1/1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={darkMode ? LogoBlackSmall : LogoWhiteSmall}
            alt="AI"
            className="rounded-circle"
            width="30"
            height="30"
          />
        </div>
      ) : (
        <div
          style={{
            fontSize: "1.5rem",
            color: "var(--pink)",
          }}
        >
          <FiCheck />
        </div>
      )
    ) : (
      <UserAvatar user={account} />
    );
  }
  const { account } = useSelector((state) => state.auth);
  const { darkMode } = useSelector((state) => state.theme);
  const tableRegex = /(\|.*\|\n)+/g;
  const containsTable = tableRegex.test(message.message);

  return (
    <ErrorBoundary
      fallbackRender={(x) => {
        return <div>Something went wrong</div>;
      }}
    >
      <ListGroup.Item
        className={`d-flex justify-content-center ${
          message.sender === "AI"
            ? styles.messageColorAI
            : styles.messageColorUser
        } `}
      >
        <div
          className={`
        ${message.messageType !== "Instructions" && styles.messageBox}  ${
            message.sender === "AI" &&
            message.messageType !== "Instructions" &&
            styles.messageBoxAI
          }
          ${
            message.messageType === "Instructions" &&
            `${styles.messageBoxAI} ${styles.messageBox}`
          }
          `}
          style={{
            color: message.messageType === "Instructions" && "var(--gray)",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              gap: "2rem",
              display: message.messageType === "Flow" ? "block" : "flex",
              flexDirection: "row",
              alignItems: message.messageType === "Instructions" && "center",
            }}
          >
            {
              /* if message send by ai get logo image and if message send by user then user profile image */
              getMessageAvatar()
            }
            {message.messageType === "Pie" ? (
              <PieChart
                data={message.metadata?.data || {}}
                description={message.metadata?.metaData?.description || ""}
                typing={message.typing}
              />
            ) : message.messageType === "Graph" ? (
              <Graph
                options={message.metadata?.options || {}}
                data={message.metadata?.data || {}}
                description={message.metadata?.metaData?.description || ""}
                typing={message.typing}
              />
            ) : (
              <>
                {message.messageType === "Flow" ? (
                  <Flow
                    nodes={message.metadata?.nodes || []}
                    edges={message.metadata?.edges || []}
                    description={message.metadata?.metaData?.description || ""}
                    typing={message.typing}
                  />
                ) : (
                  <div>
                    {containsTable ? (
                      parseTable(message.message)
                    ) : (
                      <>
                        {message.message.split("\n").map((line, i, arr) => {
                          let citationCounter = 0;
                          const segments = line.split(/\[(.*?)\]/g);

                          if (message?.metadata?.citations?.length > 0) {
                            for (let j = 1; j < segments.length; j += 2) {
                              let citationIndex =
                                message?.metadata?.citations?.findIndex(
                                  (citation) => citation.assetId === segments[j]
                                );
                              if (citationIndex !== -1) {
                                segments[j] = (
                                  <CitationPopup
                                    key={citationCounter}
                                    citation={
                                      message.metadata.citations[
                                        citationCounter
                                      ]
                                    }
                                    index={citationCounter}
                                  />
                                );
                                citationCounter++;
                              }
                            }
                          }

                          return (
                            <div
                              key={i}
                              style={{ display: "block" }}
                              className={
                                citationCounter > 0
                                  ? styles.searchMsgResponse
                                  : ""
                              }
                            >
                              {segments.map((seg, idx, segArr) => {
                                if (
                                  message.typing &&
                                  i === arr.length - 1 &&
                                  idx === segArr.length - 1
                                ) {
                                  return typeof seg === "string" ? (
                                    <Markdown key={idx} components={components}>
                                      {seg}
                                    </Markdown>
                                  ) : (
                                    <span key={idx}>{seg}</span>
                                  );
                                }

                                return typeof seg === "string" ? (
                                  <Markdown key={idx}>{seg}</Markdown>
                                ) : (
                                  <span key={idx}>{seg}</span>
                                );
                              })}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                )}
                <div
                  className={styles.copyIcon}
                  style={{
                    display: `${
                      message.sender === "AI" &&
                      message.messageType !== "Instructions"
                        ? "block"
                        : "none"
                    }`,
                  }}
                >
                  {copied && (
                    <FiCheck
                      title="Copied"
                      style={{
                        height: "2rem",
                        width: "2rem",
                        padding: "0.5rem",
                        border: "1px solid #DDDFE3",
                        borderRadius: "0.25rem",
                        cursor: "pointer",
                      }}
                    />
                  )}
                  {!copied && (
                    <FiCopy
                      onClick={() => {
                        navigator.clipboard.writeText(message.message);
                        setCopied(true);
                      }}
                      title="Copy to clipboard"
                      style={{
                        height: "2rem",
                        width: "2rem",
                        padding: "0.5rem",
                        border: "1px solid #DDDFE3",
                        borderRadius: "0.25rem",
                        cursor: "pointer",
                        marginRight: "-1rem",
                      }}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </ListGroup.Item>
    </ErrorBoundary>
  );
}

const UnstyledMessage = ({ message }) => {
  return message.message.split("\n").map((line, i, arr) => {
    const segments = line.split(/\[(.*?)\]/g);

    return (
      <div key={i} style={{ display: "block", marginTop: "1rem" }}>
        {segments.map((seg, idx, segArr) => {
          if (
            message.typing &&
            i === arr.length - 1 &&
            idx === segArr.length - 1
          ) {
            return typeof seg === "string" ? (
              <Markdown key={idx} components={components}>
                {seg}
              </Markdown>
            ) : (
              <span key={idx}>{seg}</span>
            );
          }

          return typeof seg === "string" ? (
            <Markdown key={idx}>{seg}</Markdown>
          ) : (
            <span key={idx}>{seg}</span>
          );
        })}
      </div>
    );
  });
};

Message.propTypes = { message: PropTypes.any };

export default Message;
