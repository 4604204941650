import React from "react";
import { Button } from "react-bootstrap";
import styles from "./signup.module.css";
import { useDispatch, useSelector } from "react-redux";
import peopleImg from "../../../../images/project-images/people.png";
import { FiX } from "react-icons/fi";
import { modalActions } from "../../../../store/slices/modalSlice";
function SignUpReminder() {
  const { signUpReminder } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  if (!signUpReminder) {
    return null;
  }
  return (
    <div className={styles.reminderContainer}>
      <button
        className={styles.closeBtn}
        onClick={() => {
          dispatch(modalActions.hideSignUpReminderModal());
        }}
      >
        <FiX className={styles.closeIcon} />
      </button>
      <img src={peopleImg} alt="people" className={styles.peopleImg} />
      <p className={styles.heading}>Don’t have a User Evaluation account?</p>
      <p className={styles.para}>
        Create a free one now to be able to transcribe, get AI insights, chat
        with AI about your data, and a lot more
      </p>
      <Button
        variant="primary"
        href="https://app.userevaluation.com/signup"
        target="_blank"
      >
        Sign up for free
      </Button>
    </div>
  );
}

export default SignUpReminder;
