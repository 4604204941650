import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  FiGrid,
  FiFolder,
  FiLayers,
  FiSearch,
  // FiVideo,
  // FiUsers,
  FiUser,
  FiSettings,
  FiVideo,
  FiUsers,
} from "react-icons/fi";
import getCharacters from "../../utils/getCharacters";
import SidebarIcon from "./SidebarIcon";
import SidebarProjectIcon from "./SidebarProjectIcon";
import SidebarNewIcon from "./SidebarNewIcon";
import SidebarUpgradeIcon from "./SidebarUpgradeIcon";
import UserAvatar from "../userAvatar";
import LogoBlueSmall from "../../images/logo-blue-small.svg";
import "./sidebar.css";
import PayWallModal from "../paywallModal/paywallModal";

export const Sidebar = () => {
  const { account } = useSelector((state) => state.auth) || { picture: null };
  const { projects } = useSelector((state) => state.project);
  const [activeRoute, setActiveRoute] = useState(window.location.pathname);
  useEffect(() => {
    setActiveRoute(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <div className="ue-sidebar">
      <PayWallModal />
      <div>
        <div className="ue-logo">
          <Link to="/projects">
            <img src={LogoBlueSmall} alt="User Evaluation" />
          </Link>
        </div>
        <div className="ue-icon-group mt-2">
          <Link to="/projects">
            <SidebarIcon
              IconComponent={FiGrid}
              tooltipText="Projects"
              className={
                activeRoute === "/projects"
                  ? "ue-icon ue-icon-active"
                  : "ue-icon"
              }
            />
          </Link>
          {account.engagePlan?.subscribed && (
            <>
              <Link to="/interviews">
                <SidebarIcon
                  IconComponent={FiVideo}
                  tooltipText="Interviews"
                  className={
                    activeRoute === "/interviews"
                      ? "ue-icon ue-icon-active"
                      : "ue-icon"
                  }
                />
              </Link>
              <Link to="/past-participants">
                <SidebarIcon
                  IconComponent={FiUsers}
                  tooltipText="Participants"
                  className={
                    activeRoute === "/past-participants"
                      ? "ue-icon ue-icon-active"
                      : "ue-icon"
                  }
                />
              </Link>
            </>
          )}
          <Link to="/media-library">
            <SidebarIcon
              IconComponent={FiFolder}
              tooltipText="Files"
              className={
                activeRoute === "/media-library"
                  ? "ue-icon ue-icon-active"
                  : "ue-icon"
              }
            />
          </Link>
          <Link to="/integrations">
            <SidebarIcon
              IconComponent={FiLayers}
              tooltipText="Integrations"
              className={
                activeRoute === "/integrations"
                  ? "ue-icon ue-icon-active"
                  : "ue-icon"
              }
            />
          </Link>
          <Link to="/search">
            <SidebarIcon
              IconComponent={FiSearch}
              tooltipText="Search"
              className={
                activeRoute === "/search" ? "ue-icon ue-icon-active" : "ue-icon"
              }
            />
          </Link>
        </div>
        <hr style={{ color: "var(--grey5)" }} />
        <div className="ue-recent-projects">
          {projects
            .slice(-3)
            .reverse()
            .map((project, index) => (
              <Link to={`/project/${project._id}`} key={index}>
                <SidebarProjectIcon
                  initials={getCharacters(project.name)}
                  projectName={project.name}
                  projectId={project._id}
                />
              </Link>
            ))}

          <SidebarNewIcon />
        </div>
      </div>
      <div>
        {account && account.plan?.type === "Free" && <SidebarUpgradeIcon />}
        <div className="ue-profile-photo">
          <Link to="/settings">
            {account?.picture ? (
              <img src={account.picture} alt="Profile" />
            ) : (
              <UserAvatar user={account} />
            )}
          </Link>
        </div>
      </div>
    </div>
  );
};

export const SidebarParticipants = () => {
  const { account } = useSelector((state) => state.auth) || { picture: null };
  const [activeRoute, setActiveRoute] = useState(window.location.pathname);
  useEffect(() => {
    setActiveRoute(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <div className="ue-sidebar">
      <div>
        <div className="ue-logo">
          <Link to="/projects">
            <img src={LogoBlueSmall} alt="User Evaluation" />
          </Link>
        </div>
        <div className="ue-icon-group mt-2">
          <Link to="/participant">
            <SidebarIcon
              IconComponent={FiUser}
              tooltipText="Profile"
              className={
                activeRoute === "/participant"
                  ? "ue-icon ue-icon-active"
                  : "ue-icon"
              }
            />
          </Link>
          <Link to="/participant/settings">
            <SidebarIcon
              IconComponent={FiSettings}
              tooltipText="Settings"
              className={
                activeRoute === "/participant/settings"
                  ? "ue-icon ue-icon-active"
                  : "ue-icon"
              }
            />
          </Link>
        </div>
      </div>
      <div>
        <div className="ue-profile-photo">
          <Link to="/participant/settings">
            {account?.picture ? (
              <img src={account.picture} alt="Profile" />
            ) : (
              <UserAvatar user={account} />
            )}
          </Link>
        </div>
      </div>
    </div>
  );
};
