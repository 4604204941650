import React, { useState, useRef, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import formatTime from "../../../utils/formatTime";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../store/slices/modalSlice";
import styles from "./transcript-printer.module.css";
import CreateClipModal from "./create-clip-modal";
import SpeakerNameChangeModal from "./speakerName-modal";
import GetSpeakerData from "./speakerName";
import PopUpBtn from "./popup-clips-tags-btn";
import CreateInsightsModal from "./create-insights-modal";
import {
  deleteTag,
  getTagsByProjectId,
} from "../../../store/actions/tagsActions";
import TranscriptUtterance from "./transcriptUtterance";
import AITagsModal from "./aiTagsModal";
import { highlightActions } from "../../../store/slices/highlightSlice";
import TagsAndInsightToggle from "./tagsAndInsightToggle";
import TagPopUpBtn from "./tagPopUpBtn";
import { FiX } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import AITagsButton from "./atTagsButton";

// ChatEvent.js

const ChatEvent = ({ event, users, tags, setSelectionData, setTagPopUp }) => {
  const [loading, setLoading] = useState(true);
  const [highlightedText, setHighlightedText] = useState("");
  const [tagLabels, setTagLabels] = useState([]);

  useEffect(() => {
    // Function to highlight text and collect tag labels based on tags
    const processText = (text, tags) => {
      let modifiedText = text;
      const collectedTags = [];

      tags.forEach((tag) => {
        if (text && tag.text && text.includes(tag.text)) {
          modifiedText = modifiedText.replace(
            tag.text,
            `<span style="background-color: ${tag.tagId.highlightColor};">${tag.text}</span>`
          );

          const tagWithId = {
            ...tag.tagId,
            __id: tag._id, // Adding __id field
          };

          // Check if this tag has already been added
          if (!collectedTags.some((t) => t.__id === tagWithId.__id)) {
            collectedTags.push(tagWithId);
          }
        }
      });

      return { modifiedText, collectedTags };
    };

    if (event?.text && tags) {
      const { modifiedText, collectedTags } = processText(event.text, tags);
      setHighlightedText(modifiedText);
      setTagLabels(collectedTags);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [event, tags]);

  if (loading) {
    return null; // or a more subtle loading indicator
  }

  const user = users.find((user) => user.id === event.author_id);
  const isAgent = user?.type === "agent";

  const chatEventStyle = {
    padding: "8px 12px",
    borderRadius: "15px",
    maxWidth: "85%",
    margin: "4px 0",
    backgroundColor: isAgent ? "#007bff" : "#e9ecef",
    color: isAgent ? "white" : "black",
    alignSelf: isAgent ? "flex-end" : "flex-start",
    wordBreak: "break-word",
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: isAgent ? "flex-end" : "flex-start",
      }}
      onMouseUp={() =>
        handleMouseUpDocumentLiveChat(
          highlightedText,
          setSelectionData,
          setTagPopUp
        )
      }
    >
      <div style={chatEventStyle}>
        <strong>{user?.name || "Unknown"}: </strong>
        <span style={{ marginRight: "10px" }}>
          {new Date(event.created_at).toLocaleTimeString()}
        </span>
        <span dangerouslySetInnerHTML={{ __html: highlightedText }}></span>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "5px",
          marginBottom: "10px",
        }}
      >
        {tagLabels.map((tag) => (
          <span
            key={tag._id}
            style={{
              backgroundColor: tag.color,
              color: "black",
              marginLeft: "10px",
              padding: "2px 5px",
              borderRadius: "5px",
              fontSize: "14px",
            }}
          >
            {tag.title}

            <FiX
              style={{ marginLeft: "5px", fontSize: "1rem", cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                deleteTag(tag?.__id);
              }}
            />
          </span>
        ))}
      </div>
    </div>
  );
};

//
// setSelectionData={setSelectionData}
// setTagPopUp={setTagPopUp}
// ChatEventsList.js
const ChatEventsList = ({ chats, tags, setSelectionData, setTagPopUp }) => {
  return (
    <div style={{ padding: "10px" }}>
      {chats.map((chat, i) => (
        <div key={chat.id + i} style={{ marginBottom: "20px" }}>
          <div
            style={{
              padding: "10px",
              color: "var(--grey5)",
              borderRadius: "8px",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            <strong>Chat ID:</strong> {chat.id} - <strong>Started at:</strong>{" "}
            {new Date(chat.thread.created_at).toLocaleString()}
          </div>
          {chat.thread.events.map((event) => (
            <ChatEvent
              key={event.id}
              event={event}
              users={chat.users}
              tags={tags}
              setSelectionData={setSelectionData}
              setTagPopUp={setTagPopUp}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export const TranscriptPrinter = ({
  transcriptObj,
  currentWord,
  isDocument,
  handleSeek,
  speakers,
  setSpeakers,
  assetId,
  collectInsight,
  showTag,
  setShowTag,
  showInsight,
  setShowInsight,
  selectedVideo,
}) => {
  const { createclip: showClip } = useSelector((state) => state.modal);
  const projectData = useSelector((state) => state.currentProject.project);
  let { speakernamechange: showSpeakerNameChange } = useSelector(
    (state) => state.modal
  );
  const { highlights: timeStamps } = useSelector((state) => state.highlight);
  let { tags } = useSelector((state) => state.tags);
  const dispatch = useDispatch();
  const parentRef = useRef(null);
  const spanRefs = useRef([]);
  const docContainerRef = useRef(null);
  const [speaker, setSpeaker] = useState("");
  const [popUp, setPopUp] = useState(false);
  const [selectionData, setSelectionData] = useState([]);
  const [selectedUtterance, setSelectedUtterance] = useState(null);
  const [selectedSentence, setSelectedSentence] = useState(null);
  const [tagPopUp, setTagPopUp] = useState(false);
  const asset = projectData?.videos?.find((vid) => vid._id === assetId);
  tags = tags?.filter((tag) => tag.assetId === assetId);
  const [hoverIndex, setHoverIndex] = useState("");
  const [heights, setHeights] = useState([]);
  const scrollSpanRef = useRef({});
  const handleMouseEnter = (id) => {
    setHoverIndex(id);
  };
  const handleMouseLeave = (id) => {
    setHoverIndex("");
  };
  useEffect(() => {
    if (projectData?._id) {
      getTagsByProjectId(projectData?._id);
    }
  }, [projectData?._id]);
  useEffect(() => {
    if (spanRefs.current.length > 0) {
      spanRefs.current.forEach((span) => {
        if (span) {
          tags.forEach((tag) => {
            if (
              span.getAttribute("data") >= tag.start &&
              span.getAttribute("data") <= tag.end
            ) {
              const spanRect = span.getBoundingClientRect();
              let relativeHeight =
                spanRect.top -
                docContainerRef?.current?.getBoundingClientRect().top + window.scrollY;
              setHeights((prev) => {
                // check if tag is already present in  heights array don't modify it
                if (prev?.map((el) => el?.id).includes(tag?._id)) {
                  return prev;
                }
                let newObj = {
                  id: tag?._id,
                  height: relativeHeight,
                };
                return [...prev, newObj];
              });
            }
          });
        }
      });
    }
  }, [tags]);
  //  useeffect to look for click in the document and call highlightaction to reset the highlights
  useEffect(() => {
    document.addEventListener("mouseup", () => {
      dispatch(highlightActions.resetHighlights());
    });
    return () => {
      document.removeEventListener("mouseup", () => {
        dispatch(highlightActions.resetHighlights());
      });
    };
  }, []);

  if (transcriptObj === undefined || transcriptObj === null) {
    return (
      <>
        <p style={{ textAlign: "center" }}>
          Your {isDocument ? "content" : "transcipt"} is being processed.
        </p>
        <div style={{ textAlign: "center" }}>
          <Spinner animation="border" variant="primary" />
        </div>
      </>
    );
  }

  if (transcriptObj?.utterances?.length === 0) {
    return (
      <>
        <p style={{ textAlign: "center" }}>
          No transcript available for this file.
        </p>
      </>
    );
  }

  let transcript = transcriptObj?.utterances;
  if (
    transcriptObj.utterances === undefined ||
    transcriptObj.utterances === null
  ) {
    return (
      <>
        <Form.Group
          controlId="formDarkMode"
          className="mt-sm-4 mt-2 mb-3 d-flex"
        >
          <Form.Label className="d-inline">Show Tags</Form.Label>
          <Form.Check
            type="switch"
            style={{ display: "inline-block", marginLeft: "10px" }}
            label=""
            checked={showTag}
            onChange={() => setShowTag(!showTag)}
          />

          <AITagsButton />
        </Form.Group>
        <AITagsModal assetId={assetId} projectId={projectData?._id} />

        <TagPopUpBtn
          isOpen={tagPopUp}
          setIsOpen={setTagPopUp}
          isDocument={true}
          data={selectionData}
          assetId={assetId}
          projectId={projectData?._id}
          selectedUtterance={selectionData?.utteranceNo}
        />
        {selectedVideo.assetType === "LIVECHAT" ? (
          <div>
            <ChatEventsList
              chats={selectedVideo?.metaData?.originalData?.chats}
              tags={tags}
              setSelectionData={setSelectionData}
              setTagPopUp={setTagPopUp}
            />
          </div>
        ) : (
          <div className={styles.docTranscriptContainer} ref={docContainerRef}>
            {transcriptObj?.text.split("\n").map((item, index) => {
              let splittedItem = item.split(" ");
              let indexTillNow = transcriptObj?.text
                .split("\n")
                .slice(0, index)
                .join(" ")
                .split(" ").length;
              return (
                <p
                  key={index}
                  style={{
                    overflowWrap: "break-word",
                    width: "80%",
                  }}
                  onMouseUp={() =>
                    handleMouseUpDocument(
                      transcriptObj?.text,
                      setSelectionData,
                      setTagPopUp
                    )
                  }
                >
                  {splittedItem.map((word, i) => {
                    let select = false;
                    let color = "";
                    let createSpan = false;
                    let flag = false;
                    if (showTag) {
                      for (let ind = 0; ind < timeStamps.length; ind++) {
                        if (
                          timeStamps[ind].start <= i &&
                          timeStamps[ind].end >= i
                        ) {
                          flag = true;
                        }
                      }
                      for (let inx = 0; inx < tags.length; inx++) {
                        if (
                          parseInt(tags[inx].start) <= i + indexTillNow &&
                          parseInt(tags[inx].end) >= i + indexTillNow
                        ) {
                          select = true;
                          color = tags[inx]?.tagId?.highlightColor;
                          let firstWordIndex = parseInt(tags[inx].start);
                          if (firstWordIndex === i + indexTillNow) {
                            createSpan = true;
                          }
                          if (color && hoverIndex === tags[inx]?._id) {
                            color = color
                              ?.substring(5, color.length - 1)
                              .split(",")
                              .map(parseFloat);
                            color[3] = 0.5;
                            color = `rgba(${color?.join(",")})`;
                          }
                        }
                      }
                    }

                    return (
                      <span
                        ref={
                          createSpan
                            ? (el) => {
                                if (el && !spanRefs.current.includes(el)) {
                                  spanRefs.current.push(el);
                                }
                                if (showTag)
                                  return (scrollSpanRef.current[i] = el);
                              }
                            : null
                        }
                        key={i}
                        data={indexTillNow + i}
                        // eslint-disable-next-line react/no-unknown-property
                        sentence={index}
                        style={{
                          background: flag
                            ? "rgba(0, 133, 255, 0.4)"
                            : select
                            ? color
                            : "transparent",
                          color: "var(--grey10)",
                        }}
                      >
                        {word}{" "}
                      </span>
                    );
                  })}
                </p>
              );
            })}
            {showTag && (
              <>
                {tags?.map((tag, index) => {
                  let height = heights.find((h) => h.id === tag._id);
                  return (
                    <div
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        scrollSpanRef.current[tag?.start]?.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });
                      }}
                      onMouseEnter={() => handleMouseEnter(tag?._id)}
                      onMouseLeave={() => handleMouseLeave(tag?._id)}
                      className={styles.spanTag}
                      style={{
                        top: `${height?.height}px`,
                        background: tag?.tagId?.color,
                        left: `80%`,
                        fontSize: "0.85rem",
                      }}
                    >
                      <span>{tag?.tagId?.title} </span>
                      <span style={{ opacity: 0.5, marginLeft: "5px",width:"10%" }}>
                        {tag?.frequency > 0 ? `${tag?.frequency}` : ""}
                      </span>
                      <FiX
                        style={{ fontSize: "1rem",width:"10%"}}
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteTag(tag?._id);
                          heights.splice(index, 1);
                        }}
                      />
                    </div>
                  );
                })}
              </>
            )}
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        <div
          className="table-responsive"
          style={{
            position: "relative",
            overflowX: isDocument ? "auto" : "unset",
          }}
          ref={parentRef}
        >
          <SpeakerNameChangeModal
            show={showSpeakerNameChange}
            transcript={transcript}
            speaker={speaker}
            speakers={speakers}
            setSpeakers={setSpeakers}
            setSelectedSentence={setSelectedUtterance}
            selectedSentence={selectedSentence}
          />
          <CreateClipModal
            speakers={speakers}
            show={showClip}
            selectionData={selectionData}
            handleSeek={handleSeek}
            currentWord={currentWord}
          />
          <CreateInsightsModal
            data={selectionData}
            assetId={assetId}
            collectInsight={collectInsight}
          />
          <PopUpBtn
            isOpen={popUp}
            setIsOpen={setPopUp}
            data={selectionData}
            selectedUtterance={selectedUtterance}
            assetId={assetId}
            projectId={projectData?._id}
          />

          {transcript && (
            <AITagsModal assetId={assetId} projectId={projectData?._id} />
          )}
          {transcript && (
            <>
              <TagsAndInsightToggle
                showTag={showTag}
                setShowTag={setShowTag}
                showInsight={showInsight}
                setShowInsight={setShowInsight}
              />
            </>
          )}

          <div className="transcript-container">
            {transcript &&
              transcript.map((sentence, index) => {
                return (
                  <div
                    key={index}
                    className={styles.transcriptEntry}
                    onMouseUp={(e) => {
                      handleMouseUp(
                        e,
                        parentRef,
                        setSelectionData,
                        transcript,
                        setPopUp,
                        setSelectedUtterance
                      );
                    }}
                  >
                    <div className={styles.transcriptHeader}>
                      <button className={styles.speakerBtn} type="button">
                        <span data={JSON.stringify(index)}>
                          <GetSpeakerData
                            speakers={speakers}
                            sentenceSpeaker={sentence.speaker}
                          />
                        </span>
                      </button>
                      <span
                        data={JSON.stringify(index)}
                        className={styles.transcriptTime}
                        onClick={() => handleSeek(sentence.start)}
                      >
                        {formatTime(sentence.start)} -{" "}
                        {formatTime(sentence.end)}
                      </span>
                      <MdEdit
                        className={styles.editIcon}
                        onClick={() => {
                          setSpeaker(sentence.speaker);
                          setSelectedSentence(sentence);
                          dispatch(modalActions.showSpeakerNameChangeModal());
                        }}
                      />
                    </div>
                    <div className={styles.transcriptSentence}>
                      <TranscriptUtterance
                        collectInsight={collectInsight}
                        handleSeek={handleSeek}
                        sentence={sentence}
                        index={index}
                        currentWord={currentWord}
                        timeStamps={timeStamps}
                        tags={tags}
                        showTag={showTag}
                        showInsight={showInsight}
                        asset={asset}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </>
    );
  }
};

const handleMouseUp = (
  e,
  parentRef,
  setSelectionData,
  transcipt,
  setPopUp,
  setSelectedUtterance
) => {
  e.preventDefault();
  // get the selection data
  const selectionLength = window.getSelection().toString().length;
  const selection = window.getSelection();
  const rangeCount = selection.rangeCount;
  // if there is a selection then calculate the start and end word index with time and set the state
  if (selectionLength > 0) {
    // get the sentence index
    const startIndex =
      selection.getRangeAt(0).startContainer.parentNode.getAttribute("data") ||
      selection
        .getRangeAt(rangeCount - 1)
        .startContainer?.parentNode?.children[0]?.getAttribute("data") ||
      0;
    setSelectedUtterance(startIndex);
    const endIndex =
      selection
        .getRangeAt(rangeCount - 1)
        .endContainer.parentNode.getAttribute("data") ||
      selection
        .getRangeAt(rangeCount - 1)
        .endContainer?.parentNode?.children[0]?.getAttribute("data") ||
      transcipt.length - 1;

    let selected = transcipt.slice(
      parseInt(startIndex),
      parseInt(endIndex) + 1
    );

    const startWordIndex =
      selection
        .getRangeAt(0)
        .startContainer.parentNode?.getAttribute("word_index") ||
      selection
        .getRangeAt(rangeCount - 1)
        .startContainer?.parentNode?.children[0]?.getAttribute("word_index") ||
      0;

    const endWordIndex =
      selection
        .getRangeAt(rangeCount - 1)
        .endContainer.parentNode?.getAttribute("word_index") ||
      selection
        .getRangeAt(rangeCount - 1)
        .endContainer?.parentNode?.children[0]?.getAttribute("word_index") ||
      selected[selected.length - 1].words.length - 1;

    // only send the selected words taking startWordIndex and endWordIndex into account
    if (selected.length === 1) {
      // if only one sentence is selected
      selected[0] = {
        ...selected[0],
        start: selected[0].words[parseInt(startWordIndex)].start,
        end: selected[0].words[parseInt(endWordIndex)].end,
        words: selected[0].words.slice(
          parseInt(startWordIndex),
          parseInt(endWordIndex) + 1
        ),
      };
    } else {
      selected[0] = {
        ...selected[0],
        start: selected[0].words[parseInt(startWordIndex)].start,
        words: selected[0].words.slice(
          parseInt(startWordIndex),
          selected[0].words.length
        ),
      };
      selected[selected.length - 1] = {
        ...selected[selected.length - 1],
        end: selected[selected.length - 1].words[parseInt(endWordIndex)].end,
        words: selected[selected.length - 1].words.slice(
          0,
          parseInt(endWordIndex) + 1
        ),
      };
    }
    setSelectionData(selected);
    setPopUp(true);
  }
};

function handleMouseUpDocument(text, setSelectionData, setTagPopUp) {
  const selectionLength = window.getSelection().toString().length;
  const selection = window.getSelection();
  const rangeCount = selection.rangeCount;
  if (selectionLength === 0) return;
  let start =
    selection.getRangeAt(0).startContainer.parentNode.getAttribute("data") ||
    selection
      .getRangeAt(rangeCount - 1)
      .startContainer?.parentNode?.children[0]?.getAttribute("data") ||
    0;

  text = text.split("\n").join(" ").split(" ");
  let end = selection
    .getRangeAt(rangeCount - 1)
    .endContainer.parentNode.getAttribute("data");
  start = parseInt(start);
  end = parseInt(end);
  if (selectionLength > 0) {
    let selected = text.slice(start, end + 1);

    setSelectionData({
      text: selected.join(" "),
      start: start,
      end: end,
      utteranceNo: 0,
    });
    setTagPopUp(true);
  }
}

function handleMouseUpDocumentLiveChat(text, setSelectionData, setTagPopUp) {
  // Get the currently selected text from the window.
  const selectedText = window.getSelection().toString();

  // Check if there is any selected text.
  if (selectedText.length === 0) return;

  // Set start, end, and utteranceNo to 0.
  const start = 0;
  const end = 0;
  const utteranceNo = 0;

  if (selectedText.length > 0) {
    // Call the 'setSelectionData' function with the details of the selection.
    setSelectionData({
      text: selectedText,
      start: start,
      end: end,
      utteranceNo: utteranceNo,
    });

    // Trigger the tag popup to show up.
    setTagPopUp(true);
  }
}
