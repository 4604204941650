import React from "react";
import Ribbon from "../components/toolbar";
import styles from "../index.module.css";
import { TCLoadingState } from "../components/ProcessingStates";
function TCTemplate({ children, activeTab, isProcessing }) {
  return (
    <div>
      <Ribbon type={activeTab} activeTab={activeTab} />
      <div className={styles.tagArea}>
        {isProcessing && activeTab === "tags" ? (
          <TCLoadingState type={activeTab} />
        ) : (
          children
        )}
      </div>
    </div>
  );
}

export default TCTemplate;
