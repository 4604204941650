import React from "react";

const Rejected = () => {
  return (
    <p
      style={{
        fontSize: "28px",
        fontWeight: "500",
        marginTop: "5rem",
      }}
    >
      Thank you for completing the screening questions. Based on your responses,
      it appears that you are not a fit for this interview at this time. We
      appreciate your interest.
    </p>
  );
};

export default Rejected;
