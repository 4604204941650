import React from "react";
import { PageLayout } from "../../../components/page-layout";
import { Container, Row, Col, Button } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import CustomerRequestsModal from "../../../components/customer-requests-modal";
import "react-phone-input-2/lib/style.css";
import css from "./index.module.css";
import {
  sendPhoneNumber,
  verifyPhoneNumber,
} from "../../../store/actions/authActions";
import { toast } from "react-toastify";
import { authActions } from "../../../store/slices/authSlice";
import { modalActions } from "../../../store/slices/modalSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";

export const ParticipantPhoneVerification = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isCurrentScreenPhoneInput, setIsCurrentScreenPhoneInput] =
    React.useState(true);
  const [phone, setPhone] = React.useState({
    value: "",
    country: {},
  });
  const [otp, setOtp] = React.useState("");
  const timerRef = React.useRef({
    element: null,
    timer: null,
  });

  React.useEffect(() => {
    // clear timer on unmount
    const t = timerRef.current.timer;
    return () => {
      clearInterval(t);
    };
  }, []);

  const santizePhone = () => {
    const dialcode = phone?.country?.dialCode;
    const numberWithoutDialCode = phone?.value?.replace(dialcode, "");
    const sanitizedPhone = `+${dialcode}${+numberWithoutDialCode}`;
    return sanitizedPhone;
  };

  const startTimer = () => {
    let timeLeft = 45;
    timerRef.current?.element?.classList?.add("disabled");
    const timer = setInterval(() => {
      if (timerRef.current.element === null) return;
      timerRef.current.element.innerText = `Resend code in ${timeLeft} seconds`;
      if (timeLeft === 0) {
        clearInterval(timer);
        timerRef.current.element.innerText = "Resend code";
        timerRef.current.element.classList.remove("disabled");
      }
      timeLeft -= 1;
    }, 1000);
  };

  const getOtp = () => {
    const phone = santizePhone();

    if (phone?.length < 7 || phone?.length > 15) {
      toast.error("Please enter a valid phone number");
      return;
    }

    sendPhoneNumber(phone).then(({ data, error }) => {
      if (data) {
        if (data?.message === "Your phone number has been verified.") {
          toast.success(data.message);
          dispatch(authActions.setPhoneNumber(phone));
          history.push("/participant");
          return;
        }
        setIsCurrentScreenPhoneInput(false);
        startTimer();
        return toast.success(data.message || "OTP sent successfully");
      }

      if (error) {
        toast.error(
          error.message ||
            "Something went wrong. Please try again or contact support to get help."
        );
      }
    });
  };

  const verifyOtp = () => {
    const phone = santizePhone();

    if (otp?.length < 4 || otp?.length > 8) {
      toast.error("Please enter a valid OTP");
      return;
    }

    verifyPhoneNumber(santizePhone(), otp).then((res) => {
      if (!res.error) {
        toast.success(
          res?.data?.message ||
            "Your phone number has been verified successfully."
        );
        dispatch(authActions.setPhoneNumber(phone));
        history.push("/participant");
      } else {
        toast.error(
          "Something went wrong. Please try again or contact support to get help."
        );
      }
    });
  };

  const resetPhone = () => {
    setIsCurrentScreenPhoneInput(true);
    setPhone({
      value: "",
      country: {},
    });
    clearInterval(timerRef.current.timer);
  };

  const openCustomerRequestsModal = () => {
    dispatch(modalActions.showCustomerRequestsModal());
  };

  return (
    <PageLayout>
      <CustomerRequestsModal type="manual_phone_verification" />
      <Container>
        <Row className="vh-100 justify-content-center align-items-center">
          <Col className={css.container}>
            {isCurrentScreenPhoneInput ? (
              <>
                <h3>Verify your phone number</h3>
                <p>
                  A verified phone number is required for all the participants
                  on User Evaluation. You will receive a SMS to verify your
                  account and use it.
                </p>
                <p className="fw-semi">Phone number</p>
                <PhoneInput
                  country={"us"}
                  placeholder="Enter phone number"
                  value={phone.value}
                  onChange={(phone, country) =>
                    setPhone({ value: phone, country })
                  }
                  inputClass={css.input}
                  buttonClass={css.button}
                />
                <Button
                  variant="primary"
                  className="mt-3 px-3 py-2 "
                  onClick={getOtp}
                >
                  Send Verification Code
                </Button>
              </>
            ) : (
              <>
                <h3>Enter verification code</h3>
                <p>
                  User Evaluation sent a verification code to&nbsp;
                  <span className="fw-semi">{santizePhone()}</span>
                </p>
                <label htmlFor="otp" className="fw-semi mb-2">
                  Verification code
                </label>
                <input
                  type="number"
                  id="otp"
                  className={css.otp}
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="Enter verification code"
                />
                <Button
                  variant="primary"
                  className="mt-2 mb-3 px-3 py-2 d-block"
                  onClick={verifyOtp}
                >
                  Submit
                </Button>
                <Button
                  variant="link"
                  className="mt-2 d-block p-0 my-1 text-decoration-none disabled"
                  ref={(ref) => (timerRef.current.element = ref)}
                  onClick={getOtp}
                >
                  Resend code in 45 seconds
                </Button>
                <Button
                  variant="link"
                  className="d-block p-0 text-decoration-none"
                  onClick={resetPhone}
                >
                  Verify with a different phone number
                </Button>
              </>
            )}
            <div
              role="button"
              className="mt-3 text-primary d-flex gap-1"
              onClick={openCustomerRequestsModal}
            >
              <span>send manual verification request</span>
              <span>
                <FaArrowRightLong />
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </PageLayout>
  );
};
