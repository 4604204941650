import React, { useRef, useState, useEffect } from "react";
import ProcessingState from "../skeletonProcessingState/index.js";
import styles from "./index.module.css";
import formatTime from "../../../../utils/formatTime.js";
import GetSpeakerData from "../../../project-vnext/components/speakerName.js";
import DataTable from "../datatable/index.js";
import Papa from "papaparse";
import Heading from "./heading.js";
import { useDispatch, useSelector } from "react-redux";
// import ChatEvent from "./chatEvent.js";
import {
  deleteTag,
  getTagsByProjectId,
} from "../../../../store/actions/tagsActions.js";
import { FiX } from "react-icons/fi";
import { highlightActions } from "../../../../store/slices/highlightSlice";
import SpeakerNameChangeModal from "./speakerNameChange.js";
import CreateClipModal from "./createClipModal.js";
import CreateInsightsModal from "./createInsightsModal.js";
import { MdEdit } from "react-icons/md";
import { modalActions } from "../../../../store/slices/modalSlice.js";
import TranscriptUtterance from "./transcriptUtterance.js";
import { ribbonActions } from "../../../../store/slices/ribbonSlice.js";
import EmptyStatePDF from "./components/emptyState.js";
function TranscriptPrinter({
  transcript,
  assetType,
  allSpeakers,
  handleSeek,
  selectedVideo,
  currentWord,
  collectInsight,
  toolbarRef,
}) {
  const { isPublic } = useSelector((state) => state.currentProject);
  const { showInsights } = useSelector((state) => state.ribbon);
  const projectData = useSelector((state) => state.currentProject.project);
  let { tags } = useSelector((state) => state.tags);
  let assetId = selectedVideo._id;
  let { speakernamechange: showSpeakerNameChange } = useSelector(
    (state) => state.modal
  );
  const parentRef = useRef(null);
  const { highlights: timeStamps } = useSelector((state) => state.highlight);

  const dispatch = useDispatch();
  // const parentRef = useRef(null);
  const spanRefs = useRef([]);
  const docContainerRef = useRef(null);
  const [speaker, setSpeaker] = useState("");
  // const [popUp, setPopUp] = useState(false);
  const [selectionData, setSelectionData] = useState([]);
  const [selectedSentence, setSelectedSentence] = useState(null);
  const asset = projectData?.videos?.find((vid) => vid._id === assetId);
  tags = tags?.filter((tag) => tag.assetId === assetId);
  const { sentiment } = useSelector((state) => state.sentiment);
  const { showSentiment } = useSelector((state) => state.ribbon);

  // const [hoverIndex, setHoverIndex] = useState("");
  const [heights, setHeights] = useState([]);
  const scrollSpanRef = useRef({});
  let transcriptObj = transcript && transcript[0];
  const [hoverIndex, setHoverIndex] = useState("");
  const handleMouseEnter = (id) => {
    setHoverIndex(id);
  };
  const handleMouseLeave = (id) => {
    setHoverIndex("");
  };

  useEffect(() => {
    if (projectData?._id && tags?.length === 0 && !isPublic) {
      getTagsByProjectId(projectData?._id);
    }
  }, [projectData?._id, tags?.length, isPublic]);

  const assetSentiment = sentiment.filter((ele) => ele.assetId === assetId);

  let showSentimentForAsset = showSentiment && assetSentiment.length > 0;
  let showTag = showInsights && !(showSentiment && showSentimentForAsset);
  let showInsight = !showInsights && !(showSentiment && showSentimentForAsset);
  useEffect(() => {
    if (spanRefs.current.length > 0) {
      spanRefs.current.forEach((span) => {
        if (span) {
          tags.forEach((tag) => {
            if (
              span.getAttribute("data") >= tag.start &&
              span.getAttribute("data") <= tag.end
            ) {
              const spanRect = span.getBoundingClientRect();
              let relativeHeight =
                spanRect.top -
                docContainerRef?.current?.getBoundingClientRect().top +
                window.scrollY;
              setHeights((prev) => {
                // check if tag is already present in  heights array don't modify it
                if (prev?.map((el) => el?.id).includes(tag?._id)) {
                  return prev;
                }
                let newObj = {
                  id: tag?._id,
                  height: relativeHeight,
                };
                return [...prev, newObj];
              });
            }
          });
        }
      });
    }
  }, [tags]);
  useEffect(() => {
    // if clicked outside parentRef then close the tag pop up
    const handleClickOutside = (event) => {
      if (
        parentRef?.current &&
        !parentRef?.current.contains(event.target) &&
        toolbarRef?.current &&
        !toolbarRef?.current.contains(event.target)
      ) {
        dispatch(
          ribbonActions.setRibbonData({ name: "selectionData", value: false })
        );
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  //  useeffect to look for click in the document and call highlightaction to reset the highlights
  useEffect(() => {
    document.addEventListener("mouseup", () => {
      dispatch(highlightActions.resetHighlights());
    });
    return () => {
      document.removeEventListener("mouseup", () => {
        dispatch(highlightActions.resetHighlights());
      });
    };
  }, []);

  if (transcriptObj === undefined || transcriptObj === null) {
    return <ProcessingState />;
  }
  if (transcriptObj?.utterances?.length === 0) {
    return <EmptyState />;
  }
  if (assetType === "CSV") {
    return (
      <>
        <Heading heading={selectedVideo.name} selectedVideo={selectedVideo} />
        <DataTable
          inputData={Papa.parse(transcriptObj?.text)?.data}
          tags={[]}
          showTag={false}
        />
      </>
    );
  }
  if (transcriptObj?.text === "") {
    return <EmptyStatePDF />;
  }

  if (
    (transcriptObj.utterances === undefined ||
      transcriptObj.utterances === null) &&
    assetType !== "CSV"
  ) {
    return selectedVideo.assetType === "LIVECHAT" ? (
      <div>
        <ChatEventsList
          chats={selectedVideo?.metaData?.originalData?.chats}
          tags={tags}
          setSelectionData={setSelectionData}
        />
      </div>
    ) : (
      <>
        <div
          style={{
            width:
              assetType === "PDF" || assetType === "TEXT" || assetType === "DOC"
                ? "100%"
                : "75%",
          }}
        >
          <Heading heading={selectedVideo.name} selectedVideo={selectedVideo} />
        </div>
        <div className={styles.docTranscriptContainer} ref={docContainerRef}>
          <div ref={parentRef}>
            {transcriptObj?.text.split("\n").map((item, index) => {
              let splittedItem = item.split(" ");
              let indexTillNow = transcriptObj?.text
                .split("\n")
                .slice(0, index)
                .join(" ")
                .split(" ").length;
              return (
                <p
                  key={index}
                  style={{
                    overflowWrap: "break-word",
                    width: "80%",
                  }}
                  onMouseUp={() =>
                    handleMouseUpDocument(
                      transcriptObj?.text,
                      setSelectionData,
                      dispatch
                    )
                  }
                >
                  {splittedItem.map((word, i) => {
                    let select = false;
                    let color = "";
                    let createSpan = false;
                    let flag = false;
                    if (showTag) {
                      for (let ind = 0; ind < timeStamps.length; ind++) {
                        if (
                          timeStamps[ind].start <= i &&
                          timeStamps[ind].end >= i
                        ) {
                          flag = true;
                        }
                      }
                      for (let inx = 0; inx < tags.length; inx++) {
                        if (
                          parseInt(tags[inx].start) <= i + indexTillNow &&
                          parseInt(tags[inx].end) >= i + indexTillNow
                        ) {
                          select = true;
                          color = tags[inx]?.tagId?.highlightColor;
                          let firstWordIndex = parseInt(tags[inx].start);
                          if (firstWordIndex === i + indexTillNow) {
                            createSpan = true;
                          }
                          if (color && hoverIndex === tags[inx]?._id) {
                            color = color
                              ?.substring(5, color.length - 1)
                              .split(",")
                              .map(parseFloat);
                            color[3] = 0.5;
                            color = `rgba(${color?.join(",")})`;
                          }
                        }
                      }
                    }

                    return (
                      <span
                        ref={
                          createSpan
                            ? (el) => {
                                if (el && !spanRefs.current.includes(el)) {
                                  spanRefs.current.push(el);
                                }
                                if (showTag)
                                  return (scrollSpanRef.current[i] = el);
                              }
                            : null
                        }
                        key={i}
                        data={indexTillNow + i}
                        // eslint-disable-next-line react/no-unknown-property
                        sentence={index}
                        style={{
                          background: flag
                            ? "rgba(0, 133, 255, 0.4)"
                            : select
                            ? color
                            : "transparent",
                          color: "var(--grey10)",
                        }}
                      >
                        {word}{" "}
                      </span>
                    );
                  })}
                </p>
              );
            })}
          </div>
          {showTag && (
            <>
              {tags?.map((tag, index) => {
                let height = heights.find((h) => h.id === tag._id);
                return (
                  <div
                    key={index}
                    onClick={(e) => {
                      e.stopPropagation();
                      scrollSpanRef.current[tag?.start]?.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });
                    }}
                    onMouseEnter={() => handleMouseEnter(tag?._id)}
                    onMouseLeave={() => handleMouseLeave(tag?._id)}
                    className={styles.spanTag}
                    style={{
                      top: `${height?.height}px`,
                      background: tag?.tagId?.color,
                      left: `80%`,
                      fontSize: "0.85rem",
                    }}
                  >
                    <span>{tag?.tagId?.title} </span>
                    <span
                      style={{ opacity: 0.5, marginLeft: "5px", width: "10%" }}
                    >
                      {tag?.frequency > 0 ? `${tag?.frequency}` : ""}
                    </span>
                    {!isPublic && (
                      <FiX
                        style={{ fontSize: "1rem", width: "10%" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteTag(tag?._id);
                          heights.splice(index, 1);
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </>
          )}
        </div>
      </>
    );
  }
  let transcriptVal = transcriptObj?.utterances;
  return (
    <div>
      <SpeakerNameChangeModal
        show={showSpeakerNameChange}
        transcript={transcript}
        speaker={speaker}
        speakers={allSpeakers}
        selectedSentence={selectedSentence}
        assetId={assetId}
      />
      <CreateClipModal
        selectedVideo={selectedVideo}
        speakers={allSpeakers}
        selectionData={selectionData}
        handleSeek={handleSeek}
        currentWord={currentWord}
        assetId={selectedVideo?._id}
      />
      <CreateInsightsModal
        data={selectionData}
        assetId={assetId}
        collectInsight={collectInsight}
      />
      <div className="transcript-container">
        {transcriptVal &&
          transcriptVal.map((sentence, index) => {
            return (
              <div
                key={index}
                className={styles.transcriptEntry}
                onMouseUp={(e) => {
                  handleMouseUp(
                    e,
                    parentRef,
                    setSelectionData,
                    transcriptObj?.utterances,
                    dispatch
                  );
                }}
              >
                <div className={styles.transcriptHeader}>
                  {isPublic ? (
                    <>
                      <span data={JSON.stringify(index)}>
                        <GetSpeakerData
                          speakers={allSpeakers}
                          sentenceSpeaker={sentence.speaker}
                        />
                      </span>
                    </>
                  ) : (
                    <>
                      {" "}
                      <button
                        className={styles.speakerBtn}
                        onClick={() => {
                          setSpeaker(sentence.speaker);
                          setSelectedSentence(sentence);
                          dispatch(modalActions.showSpeakerNameChangeModal());
                        }}
                      >
                        <span data={JSON.stringify(index)}>
                          <GetSpeakerData
                            speakers={allSpeakers}
                            sentenceSpeaker={sentence.speaker}
                          />
                        </span>
                        <MdEdit className={styles.editIcon} />
                      </button>
                      <span
                        data={JSON.stringify(index)}
                        className={styles.transcriptTime}
                        onClick={() => handleSeek(sentence.start)}
                      >
                        {formatTime(sentence.start)}
                        {/* :{" "} {formatTime(sentence.end)} (NOTE: THIS HAS BEEN REMOVED AS AN EXPERIMENT) */}
                      </span>
                    </>
                  )}
                </div>
                <div className={styles.transcriptSentence}>
                  <TranscriptUtterance
                    assetSentiment={
                      Array.isArray(assetSentiment) && assetSentiment[0]
                    }
                    showSentiment={showSentimentForAsset}
                    parentRef={parentRef}
                    collectInsight={collectInsight}
                    handleSeek={handleSeek}
                    sentence={sentence}
                    index={index}
                    currentWord={currentWord}
                    timeStamps={timeStamps}
                    tags={tags}
                    showTag={showTag}
                    showInsight={showInsight}
                    asset={asset}
                    isPublic={isPublic}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
const ChatEvent = ({ event, users, tags, setSelectionData }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [highlightedText, setHighlightedText] = useState("");
  const [tagLabels, setTagLabels] = useState([]);
  const { isPublic } = useSelector((state) => state.currentProject);
  useEffect(() => {
    // Function to highlight text and collect tag labels based on tags
    const processText = (text, tags) => {
      let modifiedText = text;
      const collectedTags = [];

      tags.forEach((tag) => {
        if (text && tag.text && text.includes(tag.text)) {
          modifiedText = modifiedText.replace(
            tag.text,
            `<span style="background-color: ${tag.tagId.highlightColor};">${tag.text}</span>`
          );

          const tagWithId = {
            ...tag.tagId,
            __id: tag._id, // Adding __id field
          };

          // Check if this tag has already been added
          if (!collectedTags.some((t) => t.__id === tagWithId.__id)) {
            collectedTags.push(tagWithId);
          }
        }
      });

      return { modifiedText, collectedTags };
    };

    if (event?.text && tags) {
      const { modifiedText, collectedTags } = processText(event.text, tags);
      setHighlightedText(modifiedText);
      setTagLabels(collectedTags);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [event, tags]);

  if (loading) {
    return null; // or a more subtle loading indicator
  }

  const user = users.find((user) => user.id === event.author_id);
  const isAgent = user?.type === "agent";

  const chatEventStyle = {
    fontSize: "clamp(0.75rem, 1vw, 0.85rem)",
    padding: "0.4rem 0.6rem",
    borderRadius: "1rem",
    maxWidth: "85%",
    backgroundColor: isAgent ? "#007bff" : "#e9ecef",
    color: isAgent ? "white" : "black",
    alignSelf: isAgent ? "flex-end" : "flex-start",
    wordBreak: "break-word",
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: isAgent ? "flex-end" : "flex-start",
      }}
      onMouseUp={() =>
        handleMouseUpDocumentLiveChat(
          highlightedText,
          setSelectionData,
          dispatch
        )
      }
    >
      <div style={chatEventStyle}>
        <strong>{user?.name || "Unknown"}: </strong>
        <span style={{ marginRight: "10px" }}>
          {new Date(event.created_at).toLocaleTimeString()}
        </span>
        <span dangerouslySetInnerHTML={{ __html: highlightedText }}></span>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "0.2rem",
          marginBottom: "0.6rem",
        }}
      >
        {tagLabels.map((tag) => (
          <span
            key={tag._id}
            style={{
              backgroundColor: tag.color,
              color: "black",
              marginLeft: "10px",
              padding: "2px 5px",
              borderRadius: "5px",
              fontSize: "clamp(0.75rem, 1vw, 0.85rem)",
            }}
          >
            {tag.title}
            {!isPublic && (
              <FiX
                style={{
                  marginLeft: "5px",
                  fontSize: "1rem",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  deleteTag(tag?.__id);
                }}
              />
            )}
          </span>
        ))}
      </div>
    </div>
  );
};

//
// setSelectionData={setSelectionData}
// setTagPopUp={setTagPopUp}
// ChatEventsList.js
const ChatEventsList = ({ chats, tags, setSelectionData }) => {
  return (
    <div style={{ maxWidth: "800px", margin: "0 auto" }}>
      {chats.map((chat, i) => (
        <div key={chat.id + i} style={{ marginBottom: "20px" }}>
          <div
            style={{
              color: "var(--grey5)",
              textAlign: "center",
              marginBottom: "10px",
              fontSize: "clamp(0.75rem, 1vw, 0.85rem)",
            }}
          >
            <strong>Chat ID:</strong> {chat.id} - <strong>Started at:</strong>{" "}
            {new Date(chat.thread.created_at).toLocaleString()}
          </div>
          {chat.thread.events.map((event) => (
            <ChatEvent
              key={event.id}
              event={event}
              users={chat.users}
              tags={tags}
              setSelectionData={setSelectionData}
            />
          ))}
        </div>
      ))}
    </div>
  );
};
// import GetSpeakerData from "../../../speakerName";

const EmptyState = () => {
  return (
    <div className={styles.processingContainer}>
      <p style={{ textAlign: "center" }}>
        No transcript available for this asset.
      </p>
    </div>
  );
};

const handleMouseUp = (e, parentRef, setSelectionData, transcipt, dispatch) => {
  e.preventDefault();
  // get the selection data
  const selectionLength = window.getSelection().toString().length;
  const selection = window.getSelection();
  const rangeCount = selection.rangeCount;
  // if there is a selection then calculate the start and end word index with time and set the state
  if (selectionLength > 0) {
    // get the sentence index
    const startIndex =
      selection.getRangeAt(0).startContainer.parentNode.getAttribute("data") ||
      selection
        .getRangeAt(rangeCount - 1)
        .startContainer?.parentNode?.children[0]?.getAttribute("data") ||
      0;
    const endIndex =
      selection
        .getRangeAt(rangeCount - 1)
        .endContainer.parentNode.getAttribute("data") ||
      selection
        .getRangeAt(rangeCount - 1)
        .endContainer?.parentNode?.children[0]?.getAttribute("data") ||
      transcipt.length - 1;

    let selected = transcipt.slice(
      parseInt(startIndex),
      parseInt(endIndex) + 1
    );

    const startWordIndex =
      selection
        .getRangeAt(0)
        .startContainer.parentNode?.getAttribute("word_index") ||
      selection
        .getRangeAt(rangeCount - 1)
        .startContainer?.parentNode?.children[0]?.getAttribute("word_index");

    const endWordIndex =
      selection
        .getRangeAt(rangeCount - 1)
        .endContainer.parentNode?.getAttribute("word_index") ||
      selection
        .getRangeAt(rangeCount - 1)
        .endContainer?.parentNode?.children[0]?.getAttribute("word_index");
    // only send the selected words taking startWordIndex and endWordIndex into account

    if (startWordIndex && endWordIndex) {
      if (selected.length === 1) {
        // if only one sentence is selected
        selected[0] = {
          ...selected[0],
          start: selected[0].words[parseInt(startWordIndex)].start,
          end: selected[0].words[parseInt(endWordIndex)].end,
          words: selected[0].words.slice(
            parseInt(startWordIndex),
            parseInt(endWordIndex) + 1
          ),
        };
      } else {
        selected[0] = {
          ...selected[0],
          start: selected[0].words[parseInt(startWordIndex)].start,
          words: selected[0].words.slice(
            parseInt(startWordIndex),
            selected[0].words.length
          ),
        };
        selected[selected.length - 1] = {
          ...selected[selected.length - 1],
          end: selected[selected.length - 1].words[parseInt(endWordIndex)].end,
          words: selected[selected.length - 1].words.slice(
            0,
            parseInt(endWordIndex) + 1
          ),
        };
      }
      setSelectionData(selected);
      dispatch(
        ribbonActions.setRibbonData({
          name: "selectionData",
          value: selected,
        })
      );
      dispatch(
        ribbonActions.setRibbonData({
          name: "selectedUtterance",
          value: startIndex,
        })
      );
    }
  }
};

function handleMouseUpDocument(text, setSelectionData, dispatch) {
  const selectionLength = window.getSelection().toString().length;
  const selection = window.getSelection();
  const rangeCount = selection.rangeCount;
  if (selectionLength === 0) return;
  let start =
    selection.getRangeAt(0).startContainer.parentNode.getAttribute("data") ||
    selection
      .getRangeAt(rangeCount - 1)
      .startContainer?.parentNode?.children[0]?.getAttribute("data") ||
    0;

  text = text.split("\n").join(" ").split(" ");
  let end = selection
    .getRangeAt(rangeCount - 1)
    .endContainer.parentNode.getAttribute("data");
  start = parseInt(start);
  end = parseInt(end);
  if (selectionLength > 0) {
    let selected = text.slice(start, end + 1);

    setSelectionData({
      text: selected.join(" "),
      start: start,
      end: end,
      utteranceNo: 0,
    });

    dispatch(
      ribbonActions.setRibbonData({
        name: "selectionData",
        value: {
          text: selected.join(" "),
          start: start,
          end: end,
          utteranceNo: 0,
        },
      })
    );
    dispatch(
      ribbonActions.setRibbonData({
        name: "selectedUtterance",
        value: 0,
      })
    );
  }
}

function handleMouseUpDocumentLiveChat(text, setSelectionData, dispatch) {
  // Get the currently selected text from the window.
  const selectedText = window.getSelection().toString();

  // Check if there is any selected text.
  if (selectedText.length === 0) return;

  // Set start, end, and utteranceNo to 0.
  const start = 0;
  const end = 0;

  if (selectedText.length > 0) {
    // Call the 'setSelectionData' function with the details of the selection.

    dispatch(
      ribbonActions.setRibbonData({
        name: "selectionData",
        value: {
          text: selectedText,
          start: start,
          end: end,
          utteranceNo: 0,
        },
      })
    );
    dispatch(
      ribbonActions.setRibbonData({
        name: "selectedUtterance",
        value: 0,
      })
    );
  }
}

export default TranscriptPrinter;
