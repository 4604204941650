import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import ZoomImage from "../../../images/app-icon-02.png";
import { getIntegrationApps } from "../../../store/actions/integrationActions";
import Integration from "../Integration";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const ZoomIntegrationPage = () => {
  const apps = useSelector((state) => state.integrations.apps);
  const zoomApp = (apps || []).find((app) => app.name === "ZOOM");

  const history = useHistory();
  const { account: userAccount } = useSelector((state) => state.auth);
  const isNotFree = userAccount?.plan?.type !== "Free";

  useEffect(() => {
    if (!isNotFree) {
      history.push("/plans");
    }

    if (apps.length === 0) {
      getIntegrationApps();
    }
  }, [apps.length, isNotFree]);

  const details = (
    <React.Fragment>
      <h4>Zoom Cloud Recording</h4>
      <p>
        The Zoom Cloud Recording app allows you to integrate with a paid zoom
        account and import your cloud recordings with a single click. The
        integration makes it easy for you to import customer conversations into
        User Evaluation without the need to download and then upload the same
        file.
      </p>
      <h4>Additional Requirements</h4>
      <ul>
        <li>You must have a paid zoom account.</li>
      </ul>
    </React.Fragment>
  );
  return <Integration app={zoomApp} image={ZoomImage} details={details} />;
};
