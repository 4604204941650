import React from "react";
import {
  FiMusic,
  FiFileText,
  FiPlus,
  FiMessageSquare,
  FiColumns,
} from "react-icons/fi";
import { BiSolidVideos } from "react-icons/bi";

const iconsForAssetType = [
  { type: "VIDEO", icon: <BiSolidVideos /> },
  { type: "AUDIO", icon: <FiMusic /> },
  { type: "TEXT", icon: <FiFileText /> },
  { type: "CSV", icon: <FiColumns /> },
  { type: "PDF", icon: <FiFileText /> },
  { type: "DOC", icon: <FiFileText /> },
  { name: "Plus", icon: <FiPlus /> },
  { type: "OTHER", icon: <FiFileText /> },
  { type: "LIVECHAT", icon: <FiMessageSquare /> },
];

export const getIconForAssetType = (assetType) => {
  const iconObject = iconsForAssetType.find((item) => item.type === assetType);
  return iconObject ? iconObject.icon : null;
};
