import { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import { clipActions } from "../store/slices/clipSlice";
import {
  handleCurrentProjectNameUpdate,
  handleCurrentProjectSpeakersUpdate,
  handleCurrentProjectStatusUpdate,
} from "../store/actions/currentProjectActions";
import {
  handleProjectNameUpdate,
  handleProjectStatusUpdate,
} from "../store/actions/projectActions";
import store from "../store";
import { toastSuccess } from "./toast";

/** attach callbacks to socket, messageHandlers have format {eventName: callbackFn} */
function attachHandlersToSocket(messageHandlers, socket) {
  Object.entries(messageHandlers || {}).forEach((entry) => {
    socket.on(entry[0], entry[1]);
  });
}

/** attach callbacks to socket, messageHandlers have format {eventName: callbackFn} */
function detachHandlersToSocket(messageHandlers, socket) {
  try {
    Object.entries(messageHandlers || {}).forEach((entry) => {
      socket.off(entry[0], entry[1]);
    });
  } catch (e) {
    console.log(e);
  }
}

export default function useSocketIO(messageHandlers) {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const socketRef = useRef();
  useEffect(() => {
    if (!accessToken) {
      return;
    }
    // console.log(`[Connecting] ${process.env.REACT_APP_API_SERVER_URL}`)
    const socket = io(process.env.REACT_APP_API_SERVER_URL, {
      query: {
        token: accessToken,
      },
    });
    socket.on("connect", (x) => {
      socketRef.current = socket;
    });
    socket.on("projectStatusUpdate", (data) => {
      handleCurrentProjectStatusUpdate(data);
      handleProjectStatusUpdate(data);
    });
    socket.on("projectNameUpdate", (data) => {
      handleCurrentProjectNameUpdate(data);
      handleProjectNameUpdate(data);
    });
    socket.on("projectAssetSpeakerUpdate", (data) => {
      handleCurrentProjectSpeakersUpdate(data);
    });
    socket.on("clipUpdate", (data) => {
      store.dispatch(clipActions.updateClipLink(data));
      toastSuccess(`Clip ready for download`);
    });
    socket.on("noteUpdated", (x) => {
      // console.log('noteUpdated', x)
    });
    socket.on("message", (args) => {
      // console.log("args", args)
    });
    attachHandlersToSocket(messageHandlers, socket);

    let reconnectTimeout;
    socket.on("disconnect", () => {
      clearTimeout(reconnectTimeout); // can't double reconnect
      // console.log('Socket disconnected. Attempting to reconnect...');
      reconnectTimeout = setTimeout(() => {
        socket.connect();
      }, 4000); // retry every 4 seconds
    });

    return () => {
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
      }
      socket.disconnect();
    };
  }, [accessToken, messageHandlers]);

  /** @param messageHandlers - mapping of eventName to handlerFunc */
  const attachHandlers = (messageHandlers) => {
    if (!socketRef.current) {
      setTimeout(() => attachHandlers(messageHandlers), 1000);
      return;
    }
    attachHandlersToSocket(messageHandlers, socketRef.current);
  };
  /** @param messageHandlers - mapping of eventName to handlerFunc */
  const detachHandlers = (messageHandlers) => {
    detachHandlersToSocket(messageHandlers, socketRef.current);
  };

  const updateNote = (id, note) => {
    if (socketRef.current) {
      socketRef.current.emit("updateNote", { noteId: id, note });
    }
    // @todo add retry logic with a mutex, to give preference to last request only
  };

  const sendMessage = (message, projectId, threadId) => {
    if (socketRef.current) {
      socketRef.current.emit("sendMessage", { message, projectId, threadId });
    }
  };

  const createPptx = useCallback(
    (projectId, prompt,videoIds) => {
      if (socketRef.current) {
        socketRef.current.emit("createPptx", { projectId, prompt, videoIds});
      }
    },
    [socketRef]
  );

  const createReport = useCallback(
    (projectId, prompt, videoIds) => {
      if (socketRef.current) {
        socketRef.current.emit("createReport", { projectId, prompt, videoIds });
      }
    },
    [socketRef]
  );

  const testUpdates = useCallback(
    (eventType, data) => {
      if (socketRef.current) {
        socketRef.current.emit("testUpdates", { eventType, data });
      }
    },
    [socketRef]
  );

  const createAiTags = useCallback(
    (assetId, projectId, instruction) => {
      if (socketRef.current) {
        socketRef.current.emit("createAiTags", {
          assetId,
          projectId,
          instruction,
        });
      }
    },
    [socketRef]
  );

  const emit = (event, payload) => {
    if (socketRef.current) {
      socketRef.current.emit(event, payload);
    }
  };

  return {
    updateNote,
    sendMessage,
    createPptx,
    createReport,
    attachHandlers,
    detachHandlers,
    emit,
    createAiTags,
    testUpdates,
  };
}
