/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import DraggableItem from "./draggable-item";

const ListContainer = ({
  items,
  setItems,
  handleEdit,
  handleDuplicate,
  handleDelete,
}) => {
  const moveItem = (fromIndex, toIndex) => {
    const newItems = [...items];
    const [movedItem] = newItems.splice(fromIndex, 1);
    newItems.splice(toIndex, 0, movedItem);
    setItems(newItems);
  };

  // console.log("ListContainer ~ items:", items);

  return (
    <div>
      {items.length === 0 && (
        <p>
          No questions inserted yet. Insert a question to create a sequence.
        </p>
      )}
      {items.map((item, index) => (
        <DraggableItem
          key={index}
          id={index}
          index={index}
          moveItem={moveItem}
          item={item}
          handleEdit={handleEdit}
          handleDuplicate={handleDuplicate}
          handleDelete={handleDelete}
        />
      ))}
    </div>
  );
};

export default ListContainer;
