import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Menu } from "@mantine/core";
import { PiVideoBold, PiWaveformBold } from "react-icons/pi";
import { TbNotes } from "react-icons/tb";

import { FiColumns, FiFileText, FiPlus, FiEdit2 } from "react-icons/fi";
import { RiChat4Line, RiDeleteBinLine } from "react-icons/ri";
import IconBtn from "../buttons/icon-button";
import truncateString from "../../../../utils/truncateString";
import RemoveFileModal from "../contentArea/removeFileModal";
import EditVideoTitleModal from "../contentArea/EditVideoTitleModal";
import { currentProjectActions } from "../../../../store/slices/currentProject";
import styles from "../../index.module.css";
import EditAssetNotesModal from "../contentArea/editAssetNotesModal";

const iconsForAssetType = {
  VIDEO: <PiVideoBold />,
  AUDIO: <PiWaveformBold />,
  TEXT: <FiFileText />,
  CSV: <FiColumns />,
  PDF: <FiFileText />,
  DOC: <FiFileText />,
  LIVECHAT: <RiChat4Line />,
  Plus: <FiPlus />,
  OTHER: <FiFileText />,
};

function Sidebar({
  assets,
  assetId,
  projectId,
  setAssetId,
  tab,
  isSmallScreen,
  activeView,
  id,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openedMenu, setOpenedMenu] = useState(null);
  const [openedAsset, setOpenedAsset] = useState(null);
  const [showEditTitle, setShowEditTitle] = useState(false);
  const [showDeleteVideo, setShowDeleteVideo] = useState(false);
  const [showEditNotes, setShowEditNotes] = useState(false);
  const isNotesAvailable = assets?.find(
    (ele) => ele._id === openedAsset?._id
  )?.notes;
  const updateVideoTitle = (title) => {
    dispatch(
      currentProjectActions.setAssetTitle({
        asset: { _id: openedAsset?._id, title },
      })
    );
    setShowEditTitle(false);
  };
  const handleRightClick = (event, asset) => {
    event.preventDefault();
    setOpenedAsset(asset);
    setOpenedMenu(asset._id);
  };

  if (isSmallScreen) {
    if (activeView === "Transcript" || activeView === "Video") {
      return null;
    }
  }
  return (
    <div className={styles.sidebar}>
      <EditVideoTitleModal
        show={showEditTitle}
        onClose={() => setShowEditTitle(false)}
        videoId={openedAsset?._id}
        videoTitle={openedAsset?.name}
        onChange={updateVideoTitle}
      />
      <RemoveFileModal
        showDeleteVideo={showDeleteVideo}
        setShowDeleteVideo={setShowDeleteVideo}
        selectedVideo={openedAsset}
      />
      <EditAssetNotesModal
        opened={showEditNotes}
        onClose={() => setShowEditNotes(false)}
        name={openedAsset?.name}
        assetId={openedAsset?._id}
        alreadyAdded={true}
      />
      {assets?.map((ele, i) => (
        <Menu
          shadow="md"
          transitionProps={{ transition: "pop-top-left", duration: 150 }}
          key={i}
          opened={openedMenu === ele._id}
          onClose={() => setOpenedMenu(null)}
          onContextMenu={(e) => handleRightClick(e, ele)}
          position="right-start"
          classNames={{
            dropdown: styles.sidebar__menu,
          }}
        >
          <Menu.Target>
            <div
              className={styles.sidebar__btn}
              onClick={() => {
                if (id) {
                  history.push(`/public/${id}/${tab}/${ele._id}`);
                  return;
                } else {
                  history.push(`/project/${projectId}/${tab}/${ele._id}`);
                }
              }}
            >
              <IconBtn
                handleClick={() => setAssetId(ele._id)}
                type={ele?.assetType}
                thumbnail={ele?.thumbnail}
                icon={iconsForAssetType[ele.assetType] || <FiFileText />}
                isActive={ele._id === assetId}
              />
              <span className={styles.sidebar__span}>
                {truncateString(ele.name, 8)}
              </span>
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>{ele.name}</Menu.Label>
            <Menu.Item
              className={styles.menuItem}
              onClick={() => setShowEditTitle(true)}
            >
              <FiEdit2 />
              &nbsp;Rename
            </Menu.Item>
            <Menu.Item
              className={styles.menuItem}
              onClick={() => setShowEditNotes(true)}
            >
              <TbNotes />
              &nbsp;{isNotesAvailable ? "Edit" : "Add"} Notes
            </Menu.Item>
            <Menu.Item
              className={styles.menuItem}
              onClick={() => setShowDeleteVideo(true)}
            >
              <RiDeleteBinLine />
              &nbsp;Remove File
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      ))}
      {!id && (
        <div
          className={styles.sidebar__btn}
          style={{ justifyContent: "flex-start" }}
        >
          <IconBtn type="Plus" icon={iconsForAssetType.Plus} isActive={false} />
        </div>
      )}
    </div>
  );
}

export default Sidebar;
