import React from "react";
import styles from "./index.module.css";
function ActivityCard({ icon, title, absolutevalue, change }) {
  return (
    <div className={`${styles.activityCard} ${title==="Paid Users"?styles.paid:title==="Users"?styles.users:styles.free}`}>
      <div className={styles.activityCardIcon}>
        <span> {icon}</span> <span>{title}</span>
      </div>
      <div className={styles.activityCardDetails}>
        <span>{absolutevalue}</span>
        <span>{change > 0 ? `+${change}` : change}</span>
      </div>
    </div>
  );
}

export default ActivityCard;
