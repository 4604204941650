import React, { useEffect, useState } from "react";
import { Modal, Button } from "@mantine/core";
import {
  addNotesToVideo,
  getNotesByAssetId,
} from "../../../../store/actions/projectActions";
import { useSelector } from "react-redux";

function EditAssetNotesModal({
  name,
  alreadyAdded,
  file,
  addNotesRender,
  opened,
  onClose,
  assetId,
  note = "",
}) {
  const [notes, setNotes] = useState(note);
  const { project } = useSelector((state) => state.currentProject);
  const asset = project?.videos.find((vid) => vid._id === assetId);

  useEffect(() => {
    if (asset && asset?.notes) {
      setNotes(asset.notes);
    } else if (file && file?.notes) {
      setNotes(file.notes);
    } else {
      if (assetId) {
        getNotesByAssetId(assetId)
          .then((res) => {
            if (res.data) {
              setNotes(res.data);
            }
            if (res.error) {
              setNotes("");
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, [assetId, asset, file]);
  useEffect(() => {
    return () => {
      setNotes("");
    };
  }, []);
  return (
    <Modal
      size="lg"
      opened={opened}
      onClose={() => {
        onClose();
      }}
      title={`Add Notes to ${name}`}
    >
      <p>
        You can add additional notes or context to this file. These notes will
        be used by our AI to generate better insights, answers and artefacts.
      </p>
      <textarea
        className="form-control"
        rows="5"
        placeholder="Type your notes here..."
        value={notes}
        style={{
          resize: "none",
        }}
        onChange={(e) => setNotes(e.target.value)}
      />
      <div className="d-flex justify-content-between mt-4">
        <Button
          variant="default"
          style={{ borderRadius: "0.5rem" }}
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="filled"
          style={{ borderRadius: "0.5rem", color: "#fff" }}
          onClick={() => {
            if (assetId) {
              addNotesToVideo(notes, assetId, alreadyAdded);
            }
            if (
              addNotesRender &&
              typeof addNotesRender === "function" &&
              file &&
              notes
            ) {
              addNotesToVideo(notes, file.id, alreadyAdded);
              addNotesRender(file, notes);
            }
            onClose();
          }}
        >
          Save Notes
        </Button>
      </div>
    </Modal>
  );
}

export default EditAssetNotesModal;
