import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Customize the bar appearance with rounded corners
class RoundedBars extends BarElement {
  draw() {
    const ctx = this._chart.ctx;
    const vm = this._view;
    const { top, bottom, left, right } = vm;
    const radius = this.options.borderRadius || 14;

    ctx.beginPath();
    ctx.fillStyle = vm.backgroundColor;
    ctx.strokeStyle = vm.borderColor || "#000"; // Ensure there is a default border color
    ctx.lineWidth = vm.borderWidth || 1;

    // Top left corner
    ctx.moveTo(left + radius, top);
    // Top right corner
    ctx.lineTo(right - radius, top);
    ctx.quadraticCurveTo(right, top, right, top + radius);
    // Bottom right corner
    ctx.lineTo(right, bottom - radius);
    ctx.quadraticCurveTo(right, bottom, right - radius, bottom);
    // Bottom left corner
    ctx.lineTo(left + radius, bottom);
    ctx.quadraticCurveTo(left, bottom, left, bottom - radius);
    // Top left corner
    ctx.lineTo(left, top + radius);
    ctx.quadraticCurveTo(left, top, left + radius, top);

    ctx.fill();
    if (vm.borderWidth) {
      ctx.stroke(); // Only stroke if borderWidth is defined and non-zero
    }
  }
}

ChartJS.register(RoundedBars);

// Function to darken RGB colors
const darkenRGBColor = (color, amount) => {
  let [r, g, b] = color.match(/\d+/g).map(Number);
  r = Math.max(r - amount, 0);
  g = Math.max(g - amount, 0);
  b = Math.max(b - amount, 0);
  return `rgb(${r}, ${g}, ${b})`;
};

const TagsChart = ({ data, searchTerm }) => {
  // State to hold filtered tags
  const [filteredTags, setFilteredTags] = useState([]);

  // Effect to filter data when searchTerm changes
  useEffect(() => {
    if (searchTerm) {
      const searchTermLower = searchTerm?.toLowerCase();
      const uniqueTags = new Set(); // This will store unique tag titles
      const filtered = data.filter((item) => {
        const titleLower = item.tagId.title?.toLowerCase();
        if (
          !uniqueTags.has(titleLower) &&
          titleLower.includes(searchTermLower)
        ) {
          uniqueTags.add(titleLower);
          return true;
        }
        return false;
      });
      setFilteredTags(filtered);
    } else {
      // No search term, filter out duplicates and set all tags
      const uniqueTags = new Set();
      const uniqueData = data.filter((item) => {
        const titleLower = item.tagId.title?.toLowerCase();
        if (!uniqueTags.has(titleLower)) {
          uniqueTags.add(titleLower);
          return true;
        }
        return false;
      });
      setFilteredTags(uniqueData);
    }
  }, [searchTerm, data]);

  // Generate chart data from filtered tags
  const chartData = {
    labels: filteredTags.map((tag) => tag.tagId.title),
    datasets: [
      {
        label: "Frequency",
        data: filteredTags.map((tag) => tag.frequency),
        backgroundColor: filteredTags.map((tag) =>
          darkenRGBColor(tag.tagId.color, 30)
        ),
        barPercentage: 0.5,
        barThickness: 256,
        maxBarThickness: 26,
        minBarLength: 2,
        categoryPercentage: 0.5,
        borderRadius: 8, // Set the border radius here
        bar: RoundedBars, // Specify your custom bar element here
      },
    ],
  };

  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
        borderRadius: 4,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          font: {
            size: 14, // Increased font size for legend labels
            family: "Inter, Helvetica, sans-serif",
          },
        },
      },
      title: {
        display: true,
        text: "Frequency by Tags",
        color: "#333",
        font: {
          size: 18, // Increased font size for title
          family: "Inter, Helvetica, sans-serif",
        },
      },
      tooltip: {
        titleFont: {
          size: 16, // Increased font size for tooltip title
          family: "Inter, Helvetica, sans-serif",
        },
        bodyFont: {
          size: 14, // Increased font size for tooltip body
          family: "Inter, Helvetica, sans-serif",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#333",
          font: {
            size: 14, // Increased font size for x-axis ticks
            family: "Inter, Helvetica, sans-serif",
          },
        },
      },
      y: {
        ticks: {
          color: "#333",
          font: {
            size: 14, // Increased font size for y-axis ticks
            family: "Inter, Helvetica, sans-serif",
          },
        },
      },
    },
  };

  return (
    <div
      style={{
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
      }}
    >
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default TagsChart;
