import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { PageLoader } from "./page-loader";
import useAuth, { AUTH_SOURCES } from "../hooks/useAuth";

export const ProtectedRoute = ({ component, ...args }) => {
  const { isAuthenticated, isParticipant, loading } = useSelector(
    (state) => state.auth
  );
  const { authLoading } = useAuth(AUTH_SOURCES.PROTECTED);

  const isRouteForParticipants = args.path.startsWith("/participant");
  const isUserOnCorrectRoute = isParticipant
    ? isRouteForParticipants
    : !isRouteForParticipants;

  if (!isAuthenticated || authLoading || loading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  if (!isUserOnCorrectRoute)
    return <Redirect to={isParticipant ? "/participant" : "/projects"} />;

  return <Route component={component} {...args}></Route>;
};
