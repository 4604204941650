import React from "react";
import useAuth, { AUTH_SOURCES } from "../../hooks/useAuth";
import { PageLoader } from "../../components/page-loader";

export const WelcomePage = () => {
  useAuth(AUTH_SOURCES.ROOT);

  return (
    <div className="page-layout">
      <PageLoader />
    </div>
  );
};
