import React from "react";
import styles from "./index.module.css";

function Toggle({ viewingOption, setViewingOption }) {

  const handleTabClick = (option) => {
    setViewingOption(option);
  };

  return (
    <div className={styles.buttonsContainer}  style={{
        marginTop: "16px",
        marginLeft: "8px",
      }}>
      <div className={styles.TagsAndInsightToggle}>
        <button
          className={viewingOption === "Board" ? styles.active : ""}
          onClick={() => handleTabClick("Board")}
        >
          Board
        </button>
        <button
          className={viewingOption === "Chart" ? styles.active : ""}
          onClick={() => handleTabClick("Chart")}
        >
          Chart
        </button>
      </div>
    </div>
  );
}

export default Toggle;
