import React from "react";
// import Avatar, { genConfig } from "react-nice-avatar";

const Avatar = ({ name = "User Evaluation", style }) => {
  const initials = name.match(/\b\w/g) || [];
  const shortInitials = initials.slice(0, 2).join("").toUpperCase();

  // Hash function to generate a unique number based on the name
  const hashCode = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  };

  // Generate a unique color based on the name
  const color = `#${(hashCode(name) & 0xffffff).toString(16).padStart(6, "0")}`;

  return (
    <div className="user-avatar" style={{ backgroundColor: color, ...style }}>
      <p
        className="user-avatar-text"
        style={style && style.fontSize ? { fontSize: style.fontSize } : {}}
      >
        {shortInitials}
      </p>
    </div>
  );
};

export default function UserAvatar({ user, style }) {
  const userPhoto = user?.picture ? user?.picture : null;
  const [hasAavatar, setHasAvatar] = React.useState(true);

  return (
    <>
      {userPhoto && hasAavatar ? (
        <img
          src={userPhoto}
          alt={user.name}
          className="img-rounded"
          style={{ width: 40, height: 40, borderRadius: "100px", ...style }}
          referrerPolicy="no-referrer"
          onError={() => setHasAvatar(false)}
        />
      ) : (
        <Avatar name={user?.name} style={style} />
      )}
    </>
  );
}
