export const supportedLanguages = [
    { code: "en_us", country_code: "US", language: "American English" },
    { code: "en_uk", country_code: "GB", language: "British English" },
    { code: "en_au", country_code: "AU", language: "Australian English" },
    { code: "en", country_code: "US", language: "English" },
    { code: "af", country_code: "ZA", language: "Afrikaans" },
    { code: "ar", country_code: "SA", language: "Arabic" },
    { code: "hy", country_code: "AM", language: "Armenian" },
    { code: "az", country_code: "AZ", language: "Azerbaijani" },
    { code: "be", country_code: "BY", language: "Belarusian" },
    { code: "bs", country_code: "BA", language: "Bosnian" },
    { code: "bg", country_code: "BG", language: "Bulgarian" },
    { code: "ca", country_code: "CA", language: "Catalan" },
    { code: "zh", country_code: "CN", language: "Chinese" },
    { code: "hr", country_code: "HR", language: "Croatian" },
    { code: "cs", country_code: "CZ", language: "Czech" },
    { code: "da", country_code: "DK", language: "Danish" },
    { code: "nl", country_code: "NL", language: "Dutch" },
    { code: "et", country_code: "EE", language: "Estonian" },
    { code: "fi", country_code: "FI", language: "Finnish" },
    { code: "fr", country_code: "FR", language: "French" },
    { code: "gl", country_code: "ES", language: "Galician" },
    { code: "de", country_code: "DE", language: "German" },
    { code: "el", country_code: "GR", language: "Greek" },
    { code: "he", country_code: "IL", language: "Hebrew" },
    { code: "hi", country_code: "IN", language: "Hindi" },
    { code: "hu", country_code: "HU", language: "Hungarian" },
    { code: "is", country_code: "IS", language: "Icelandic" },
    { code: "id", country_code: "ID", language: "Indonesian" },
    { code: "it", country_code: "IT", language: "Italian" },
    { code: "ja", country_code: "JP", language: "Japanese" },
    { code: "kn", country_code: "IN", language: "Kannada" },
    { code: "kk", country_code: "KZ", language: "Kazakh" },
    { code: "ko", country_code: "KR", language: "Korean" },
    { code: "lv", country_code: "LV", language: "Latvian" },
    { code: "lt", country_code: "LT", language: "Lithuanian" },
    { code: "mk", country_code: "MK", language: "Macedonian" },
    { code: "ms", country_code: "MY", language: "Malay" },
    { code: "mr", country_code: "IN", language: "Marathi" },
    { code: "mi", country_code: "NZ", language: "Maori" },
    { code: "ne", country_code: "NP", language: "Nepali" },
    { code: "no", country_code: "NO", language: "Norwegian" },
    { code: "fa", country_code: "IR", language: "Persian" },
    { code: "pl", country_code: "PL", language: "Polish" },
    { code: "pt", country_code: "PT", language: "Portuguese" },
    { code: "ro", country_code: "RO", language: "Romanian" },
    { code: "ru", country_code: "RU", language: "Russian" },
    { code: "sr", country_code: "RS", language: "Serbian" },
    { code: "sk", country_code: "SK", language: "Slovak" },
    { code: "sl", country_code: "SI", language: "Slovenian" },
    { code: "es", country_code: "ES", language: "Spanish" },
    { code: "sw", country_code: "TZ", language: "Swahili" },
    { code: "sv", country_code: "SE", language: "Swedish" },
    { code: "tl", country_code: "PH", language: "Tagalog" },
    { code: "ta", country_code: "IN", language: "Tamil" },
    { code: "th", country_code: "TH", language: "Thai" },
    { code: "tr", country_code: "TR", language: "Turkish" },
    { code: "uk", country_code: "UA", language: "Ukrainian" },
    { code: "ur", country_code: "PK", language: "Urdu" },
    { code: "vi", country_code: "VN", language: "Vietnamese" },
    { code: "cy", country_code: "GB", language: "Welsh" },
  ];