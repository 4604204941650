import React from "react";
import { Container } from "react-bootstrap";
import styles from "./index.module.css";
import { FiTag } from "react-icons/fi";
export default function EmptyState() {
  return (
    <Container className="d-flex align-items-center justify-content-center h-100">
      <div className="text-center">
        <div className={styles.icon}>
          <FiTag />
        </div>
        <br />
        <h1 className="processing-heading">No tags added yet</h1>
        <p
          className="processing-paragraph"
          style={{ maxWidth: "60%", margin: "auto", lineHeight: "1.3rem" }}
        >
          It seems like you haven&apos;t made any tags yet. To get started,
          visit the file content or transcript section. Simply select a portion
          of the text you want to tag, and a toolbar will appear to let you add
          tags. Alternatively, click &quot;Tag with AI&quot;.
          <br />
          <br />.
        </p>
      </div>
    </Container>
  );
}
