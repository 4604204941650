import React, { useState, useMemo } from "react";
import { Checkbox, Modal } from "@mantine/core";
import { Button } from "react-bootstrap";
import styles from "./fileCard.module.css";
import { FiX } from "react-icons/fi";
import { removeTagsFromVideo } from "../../store/actions/mediaLibraryActions";
import { useSelector } from "react-redux";
import { flatMap } from "lodash";
function DeleteTagModal({ open, onClose, organizationId }) {
  const { assets } = useSelector((state) => state.mediaLibrary);
  const [selectedTags, setSelectedTags] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const videoTags = useMemo(() => {
    if (!assets) return [];

    let flatTags = flatMap(assets, (asset) => asset.tags);
    let values = flatTags.reduce((acc, tag) => {
      if (!acc.some((t) => t.title === tag.title)) {
        acc.push(tag);
      }
      return acc;
    }, []);

    return values;
  }, [assets]);

  return (
    <Modal title="Bulk Delete" opened={open} onClose={onClose}>
      <Checkbox

        className={`mb-2 ${styles.selectAll}`}
        label="Select All"
        checked={selectedTags.length === videoTags.length}
        onChange={(event) => {
          if (event.target.checked) {
            setSelectedTags([...videoTags]);
          } else {
            setSelectedTags([]);
          }
        }}
      />

      <div className={styles.tagIdContainer}>
        {videoTags?.map((tag) => {
          return (
            <Checkbox
              key={tag._id}
              label={
                <span
                  style={{
                    backgroundColor: "var(--grey3)",
                    color: "var(--grey10)",
                    padding: "5px",
                    borderRadius: "1rem",
                  }}
                >
                  {tag.title}
                </span>
              }
              checked={selectedTags.includes(tag)}
              onChange={(event) => {
                if (event.target.checked) {
                  setSelectedTags([...selectedTags, tag]);
                } else {
                  setSelectedTags(
                    selectedTags.filter((selectedTag) => selectedTag !== tag)
                  );
                }
              }}
            />
          );
        })}
      </div>
      {confirm ? (
        <div className={styles.confirmation}>
          <Button
            variant="transparent"
            style={{ fontSize: "1.5rem", color: "var(--grey10)" }}
            onClick={() => {
              setConfirm(false);
              setSelectedTags([]);
            }}
          >
            <FiX />
          </Button>
          <Button
            variant="danger mt-2 "
            onClick={() => {
              removeTagsFromVideo(selectedTags.map((tag) => tag._id));
              onClose();
            }}
          >
            Sure?
          </Button>
        </div>
      ) : (
        <div className={styles.actionButtons}>
          <Button
            variant="light"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="danger "
            disabled={selectedTags.length === 0}
            onClick={() => {
              setConfirm(true);
            }}
          >
            Delete
          </Button>
        </div>
      )}
    </Modal>
  );
}

export default DeleteTagModal;
