/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import style from "./edit.module.css";
import { Button, Form } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { updateParticipant } from "../../../../../store/actions/participantActions";
import dayjs from "dayjs";

export default function EditVolunteeringExperience({ data, onClose }) {
  const [validated, setValidated] = useState(false);
  const [volunteeringExperience, setVolunteeringExperience] = useState(() => {
    return data?.length > 0
      ? data
      : [
          {
            organization: "",
            role: "",
            cause: "",
            start_date: "",
            end_date: "",
            description: "",
          },
        ];
  });

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setVolunteeringExperience((prevVolunteeringExperience) => {
      const updatedVolunteeringExperience = [...prevVolunteeringExperience];
      updatedVolunteeringExperience[index] = {
        ...updatedVolunteeringExperience[index],
        [name]: value,
      };
      return updatedVolunteeringExperience;
    });
  };

  const handleAddVolunteer = () => {
    setVolunteeringExperience((prevVolunteeringExperience) => [
      ...prevVolunteeringExperience,
      {
        organization: "",
        role: "",
        cause: "",
        start_date: "",
        end_date: "",
        description: "",
      },
    ]);
  };

  const handleRemoveVolunteer = (index) => {
    if (volunteeringExperience.length === 1) return;
    setVolunteeringExperience((prevVolunteeringExperience) => {
      const updatedVolunteeringExperience = [...prevVolunteeringExperience];
      updatedVolunteeringExperience.splice(index, 1);
      return updatedVolunteeringExperience;
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      const { error } = await updateParticipant(
        "volunteer",
        volunteeringExperience
      );
      if (!error) onClose();
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSave}>
      {volunteeringExperience.map((volunteering, index) => (
        <Form.Group
          controlId={`formVolunteer${index}`}
          className="mb-2"
          key={index}
        >
          <Form.Label className={style.formLabel}>
            <span>
              Volunteer Experience {index + 1} {index === 0 && " (Latest)"}
            </span>
            <span
              className={style.deleteIcon}
              onClick={() => handleRemoveVolunteer(index)}
            >
              <MdDelete />
            </span>
          </Form.Label>
          <div className={style.formInput}>
            <Form.Control
              type="text"
              placeholder="Organization"
              name="organization"
              value={volunteering.organization}
              onChange={(e) => handleInputChange(e, index)}
              required
            />

            <Form.Control
              type="text"
              placeholder="Role"
              name="role"
              value={volunteering.role}
              onChange={(e) => handleInputChange(e, index)}
              required
            />

            <Form.Control
              type="text"
              placeholder="Cause"
              name="cause"
              value={volunteering.cause}
              onChange={(e) => handleInputChange(e, index)}
              required
            />

            <Form.Control
              type="date"
              placeholder="Start Date"
              name="start_date"
              value={dayjs(volunteering.start_date).format("YYYY-MM-DD")}
              onChange={(e) => handleInputChange(e, index)}
              required
            />

            <Form.Control
              type="date"
              placeholder="End Date"
              name="end_date"
              value={dayjs(volunteering.end_date).format("YYYY-MM-DD")}
              onChange={(e) => handleInputChange(e, index)}
              required
            />

            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Description"
              name="description"
              value={volunteering.description}
              onChange={(e) => handleInputChange(e, index)}
            />
          </div>
        </Form.Group>
      ))}
      <div className={style.addEntry} onClick={handleAddVolunteer}>
        + Add another entry
      </div>

      <Form.Group
        controlId="formButtons"
        className="d-flex justify-content-between mt-3"
      >
        <Button
          variant="secondary"
          className={style.closeButton}
          onClick={onClose}
        >
          Close
        </Button>
        <Button type="submit" variant="primary" className={style.saveButton}>
          Save
        </Button>
      </Form.Group>
    </Form>
  );
}
