import { uniqueId } from "lodash";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Tooltiped = ({ children, label }) => {
  const id = uniqueId();

  return (
    <OverlayTrigger
      placement="bottom"
      key={id}
      overlay={
        <Tooltip id={id} style={{ zIndex: 999999 }}>
          {label}
        </Tooltip>
      }
      popperConfig={{
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              altAxis: true,
              boundary: "viewport",
            },
          },
        ],
      }}
    >
      <div>{children}</div>
    </OverlayTrigger>
  );
};

export default Tooltiped;
