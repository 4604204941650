import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import DriveImage from "../../../images/app-icon-03.png";
import { getIntegrationApps } from "../../../store/actions/integrationActions";
import Integration from "../Integration";
import { useHistory } from "react-router-dom";

export default function DriveIntegrationPage() {
  const apps = useSelector((state) => state.integrations.apps);
  const driveApp = (apps || []).find((app) => app.name === "DRIVE");

  const history = useHistory();
  const { account: userAccount } = useSelector((state) => state.auth);
  const isNotFree = userAccount?.plan?.type !== "Free";

  useEffect(() => {
    if (apps.length === 0) {
      getIntegrationApps();
    }
    if (!isNotFree) {
      history.push("/plans");
    }
  }, [apps.length, isNotFree]);

  const details = (
    <React.Fragment>
      <h4>Google Drive</h4>
      <p>
        The Google Drive integration enables seamless connection with your
        Google Drive account, allowing you to import documents and media files
        into User Evaluation with just a few clicks. This integration
        streamlines the process of importing customer interactions and other
        relevant data into User Evaluation, eliminating the need to download and
        re-upload files.
      </p>
      <h4>Additional Requirements</h4>
      <ul>
        <li>You must have an active Google Drive account.</li>
      </ul>
    </React.Fragment>
  );
  return <Integration app={driveApp} image={DriveImage} details={details} />;
}
