import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  meetings: [],
  loading: false,
  error: null,
};

const virtualParticipantMeetingSlice = createSlice({
  name: "virtualParticipantMeeting",
  initialState,
  reducers: {
    fetchMeetingsStart: (state) => {
      state.loading = true;
    },
    fetchMeetingsSuccess: (state, action) => {
      state.loading = false;
      state.meetings = action.payload;
    },
    fetchMeetingsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    addMeetingStart(state) {
      state.loading = true;
      state.error = null;
    },
    addMeetingSuccess(state, action) {
      state.loading = false;
      state.meetings.push(action.payload);
    },
    addMeetingFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    deleteMeetingStart(state) {
      state.loading = true;
      state.error = null;
    },
    deleteMeetingSuccess(state, action) {
      state.meetings = state.meetings.filter(
        (meeting) => meeting._id !== action.payload
      );
    },
    deleteMeetingFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchMeetingsStart,
  fetchMeetingsSuccess,
  fetchMeetingsFailure,
  addMeetingStart,
  addMeetingSuccess,
  addMeetingFailure,
  deleteMeetingStart,
  deleteMeetingSuccess,
  deleteMeetingFailure,
} = virtualParticipantMeetingSlice.actions;

export default virtualParticipantMeetingSlice;
