
export default function formatISODate(isoString) {
  // Parse the ISO date string into a Date object
  const date = new Date(isoString);
  
  // Define options for the desired format
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  
  // Format the date to "MonthName Day, Year"
  return date.toLocaleDateString('en-US', options);
}