/* eslint-disable no-unused-vars */
import React from "react";
import style from "./index.module.css";
import { PageLayout } from "../../../components/page-layout";
import CoverImage from "../components/cover-image";
import TopBar from "../components/topbar";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FaArrowRightLong, FaChartLine, FaPeopleGroup } from "react-icons/fa6";
import { BsFillRocketFill, BsCheckCircleFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export const TestProgress = ({ match }) => {
  const { testProgress, currentTest } = useSelector((state) => state.test);
  const history = useHistory();

  return (
    <PageLayout>
      <div className={style.parentWrapper}>
        <div className={style.wrapper}>
          <TopBar currentTab="Progress" testId={match.params.id} />
          <div className={style.main}>
            <CoverImage
              projectDataId={currentTest._id || "some random value"}
            />
            <div className={style.content}>
              <div className={`${style.section} ${style.progressSection}`}>
                <div className={style.progress}>
                  <div
                    className={`${style.progressIcon} ${style.progressIconDone}`}
                  >
                    <BsFillRocketFill />
                    <BsCheckCircleFill className={style.checkMark} />
                  </div>
                  <div className={style.progressTitle}>Launch Test</div>
                  <div className={style.progressDescription}>
                    You add all the details of the test, with the AI&apos;s help
                    and launch it.
                  </div>
                </div>
                <div className={style.arrow}>
                  <FaArrowRightLong />
                </div>
                <div className={style.progress}>
                  <div className={style.progressIcon}>
                    <FaPeopleGroup />
                  </div>
                  <div className={style.progressTitle}>Recruit & Conduct</div>
                  <div className={style.progressDescription}>
                    User Evaluation&apos;s AI will recruit participant and
                    conduct the test.
                  </div>
                </div>
                <div className={style.arrow}>
                  <FaArrowRightLong />
                </div>
                <div className={style.progress}>
                  <div className={style.progressIcon}>
                    <FaChartLine />
                  </div>
                  <div className={style.progressTitle}>Analyze</div>
                  <div className={style.progressDescription}>
                    As the session recordings trickle in, you can start
                    analyzing.
                  </div>
                </div>
              </div>
              <div className={style.section}>
                <div className={style.top}>
                  <div className={style.infoTitle}>Launch Test</div>
                  <div className={style.infoDescription}>
                    Based on your instructions, User Evaluation has launched a
                    test. Here are the details of the launch:
                  </div>
                </div>
                <table>
                  <tr className={style.tableRow}>
                    <td className={style.property}>Number of Participants</td>
                    <td>{currentTest.nParticipants} participants</td>
                  </tr>
                  {/* <tr className={style.tableRow}>
                    <td className={style.property}>Location</td>
                    <td>United States</td>
                  </tr>
                  <tr className={style.tableRow}>
                    <td className={style.property}>Ages</td>
                    <td>20-30</td>
                  </tr>
                  <tr className={style.tableRow}>
                    <td className={style.property}>Gender</td>
                    <td>Female</td>
                  </tr> */}
                </table>
                <div
                  className={style.bottom}
                  role="button"
                  onClick={() =>
                    history.push(`/interview/${match.params.id}/details`)
                  }
                >
                  View All Test Details
                </div>
              </div>
              <div className={style.section}>
                <div className={style.top}>
                  <div className={style.infoTitle}>Recruit & Conduct</div>
                  <div className={style.infoDescription}>
                    Here is the summary of recruitment:
                  </div>
                </div>
                <div className={style.middle}>
                  <ProgressBar
                    now={
                      ((currentTest.interviewsCompleted || 0) * 100) /
                      currentTest.nParticipants
                    }
                  />
                  <div>
                    <span className={style.bold}>
                      {currentTest.interviewsCompleted || 0} of&nbsp;
                      {currentTest.nParticipants} participants
                    </span>
                    &nbsp;have taken your test so far
                  </div>
                </div>
                <div
                  className={style.bottom}
                  role="button"
                  onClick={() =>
                    history.push(`/interview/${match.params.id}/analyze`)
                  }
                >
                  View All Session Details
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
