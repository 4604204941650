import React, { useEffect, useRef, useState } from "react";
import { FaTag } from "react-icons/fa";
import { AiFillStar } from "react-icons/ai";
import { BiSolidVideos } from "react-icons/bi";
import styles from "./popup.module.css";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../../../store/slices/modalSlice";
import { createTagAction } from "../../../store/actions/tagsActions";
import colorsFromJson from "../../../utils/colors.json";
let colors = colorsFromJson.colors;

function PopUpBtn({
  isOpen,
  setIsOpen,
  data,
  selectedUtterance,
  assetId,
  projectId,
}) {
  const dispatch = useDispatch();
  const [tagPopUpOpen, setTagPopUpOpen] = useState(false);
  const wrapperRef = useRef(null);

  if (typeof selectedUtterance === "number") {
    setIsOpen(false);
  }

  useEffect(() => {
    function handleClick(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setTagPopUpOpen(false);
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [setIsOpen]);

  return (
    <div
      ref={wrapperRef}
      className={`${styles.container} ${isOpen ? styles.open : ""} `}
    >
      <button
        onClick={() => {
          dispatch(modalActions.showCreateClipModal());
        }}
      >
        <BiSolidVideos />
        Create a Clip
      </button>
      <span>|</span>
      <button
        className={tagPopUpOpen ? styles.active : ""}
        onClick={() => setTagPopUpOpen(true)}
      >
        <FaTag />
        <TagPopUp
          isOpen={tagPopUpOpen}
          data={data}
          selectedUtterance={selectedUtterance}
          assetId={assetId}
          setIsOpen={setIsOpen}
          projectId={projectId}
        />
        Add a Tag
      </button>
      <span>|</span>
      <button
        onClick={() => {
          dispatch(modalActions.showInsightsModal());
        }}
        className={tagPopUpOpen ? styles.active : ""}
      >
        <AiFillStar />
        Add an Insight
      </button>
    </div>
  );
}

export function TagPopUp({
  isOpen,
  data,
  selectedUtterance,
  assetId,
  setIsOpen,
  projectId,
  isDocument,
  isCSV,
}) {
  const [tag, setTag] = useState("");
  const { tags } = useSelector((state) => state.tags);
  const [randomColor, setRandomColor] = useState({});
  // now from colorsToSelectFrom we will select a random color but do it once
  // so that it does not change on every render
  const colorsToSelectFrom = colors.filter((color) => {
    return !tags.some((tag) => tag?.tagId.color === color?.color);
  });
  useEffect(() => {
    if (colorsToSelectFrom.length > 0) {
      const randomColor =
        colorsToSelectFrom[
          Math.floor(Math.random() * colorsToSelectFrom.length)
        ];
      setRandomColor(randomColor);
    }
  }, [tags.length]);

  let allTagsTitle = Array.from(
    tags
      ?.reduce((uniqueMap, tag) => {
        uniqueMap.set(tag?.tagId.title, {
          title: tag?.tagId.title,
          color: tag?.tagId.color,
          highlightColor: tag?.tagId.highlightColor,
        });
        return uniqueMap;
      }, new Map())
      .values()
  );

  let start = isDocument ? data?.start : data[0]?.words[0].start;
  let end = isDocument ? data?.end : data[data.length - 1]?.words.at(-1).end;

  let convertedData;
  if (isCSV) {
    convertedData = data?.data;
  } else if (isDocument) {
    convertedData = data?.text;
  } else {
    if (Array.isArray(data)) {
      convertedData = data
        .map((item) => {
          return item?.words
            .map((word) => {
              return word.text;
            })
            .join(" ");
        })
        .join(" ");
    }
  }

  useEffect(() => {
    setTag("");
  }, [isOpen]);
  if (!isOpen) return null;

  const filteredTags = allTagsTitle.filter((item) =>
    item?.title?.includes(tag)
  );

  return (
    <div className={styles.tagsContainer}>
      <input
        type="text"
        value={tag}
        placeholder="Search tags or type to create a new..."
        onChange={(e) => {
          setTag(e.target.value);
        }}
      />
      <div className={styles.tagsShower}>
        <div style={{ width: "100%" }}>
          {
            <>
              {tag !== "" ? (
                filteredTags.length > 0 ? (
                  filteredTags.map((item, index) => {
                    return (
                      <span
                        key={index}
                        className={styles.tag}
                        style={{
                          background: item?.color,
                          padding: "2px 10px",
                          marginBottom: "12px",
                        }}
                      >
                        {item.title}
                      </span>
                    );
                  })
                ) : (
                  <div
                    style={{ display: "flex" }}
                    onClick={() => {
                      createTagAction(
                        tag,
                        projectId,
                        assetId,
                        start,
                        end,
                        selectedUtterance,
                        convertedData,
                        randomColor?.color,
                        randomColor?.highlightColor
                      );
                      setIsOpen(false);
                    }}
                  >
                    <button
                      style={{ display: "inline", color: "var(--grey10)" }}
                    >
                      Create
                    </button>
                    <span
                      className={styles.tag}
                      style={{
                        background: randomColor?.color,
                        padding: "2px 10px",
                        display: "inline",
                      }}
                    >
                      {tag}
                    </span>
                  </div>
                )
              ) : (
                allTagsTitle.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={styles.tagsOuter}
                      onClick={() => {
                        createTagAction(
                          item.title,
                          projectId,
                          assetId,
                          start,
                          end,
                          selectedUtterance,
                          convertedData,
                          item?.color,
                          item?.highlightColor
                        );
                        setIsOpen(false);
                      }}
                    >
                      <span
                        className={styles.tag}
                        style={{
                          background: item?.color,
                          padding: "2px 10px",
                          border: "none",
                        }}
                      >
                        {item.title}
                      </span>
                    </div>
                  );
                })
              )}
            </>
          }
        </div>
      </div>
    </div>
  );
}

export default PopUpBtn;
