import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { testActions } from "../../../../../store/slices/testSlice";
import { TestLayout } from "../../components/test-layout";
import style from "./index.module.css";
import InviteSavedParticipants from "./invite-saved-participants";
import ShareLink, { sendMail } from "../../../commons/share-link";
import BottomBar, { handleStep } from "../../components/bottom-bar";
import { useHistory } from "react-router-dom";
import { getPastParticipants } from "../../../../../store/actions/testActions";

function AddParticipants({ match }) {
  const [tab, setTab] = useState(1);
  const { stepThreeChoice, currentTest } = useSelector((state) => state.test);
  const { account: { organization } = {} } = useSelector((state) => state.auth);
  const [selectedEmails, setSelectedEmails] = useState([]); // for saved participants
  const [emails, setEmails] = useState([]); // for share link
  const [buttonLoading, setButtonLoading] = React.useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getPastParticipants(organization);
  }, [organization]);

  useEffect(() => {
    if (currentTest.invitedParticipants)
      setEmails(currentTest.invitedParticipants);
  }, [currentTest]);

  useEffect(() => {
    if (stepThreeChoice === "invite-saved-participants") {
      setTab(1);
    } else if (stepThreeChoice === "share-link") {
      setTab(2);
    }
  }, [stepThreeChoice]);

  const testId = match.params.id;

  return (
    <TestLayout stepNumber={3} testId={testId}>
      <div className={style.wrapper}>
        <div className={style.title}>Add Participants to Your Interview</div>
        <div className={style.subtitle}>
          Select participants or share a link
        </div>
        <div className={style.tabs}>
          <div
            className={
              tab === 1 ? `${style.tab} ${style.activeTab}` : style.tab
            }
            onClick={() => {
              dispatch(
                testActions.stepThreeChoice("invite-saved-participants")
              );
              setTab(1);
            }}
          >
            Invite Saved Participants
          </div>
          <div
            className={
              tab === 2 ? `${style.tab} ${style.activeTab}` : style.tab
            }
            onClick={() => {
              dispatch(testActions.stepThreeChoice("share-link"));
              setTab(2);
            }}
          >
            Share Public Link
          </div>
        </div>
        {tab === 1 && (
          <InviteSavedParticipants
            selectedEmails={selectedEmails}
            setSelectedEmails={setSelectedEmails}
          />
        )}
        {tab === 2 && (
          <ShareLink testId={testId} emails={emails} setEmails={setEmails} />
        )}
        <BottomBar
          currentStep={3}
          testId={testId}
          primaryButtonLoading={buttonLoading}
          onPrimaryButtonClick={async () => {
            setButtonLoading(true);
            const { error } = await sendMail(
              testId,
              selectedEmails,
              emails
            );

            if (error) {
              setButtonLoading(false);
            } else {
              history.push(handleStep(4, testId));
            }
          }}
        />
      </div>
    </TestLayout>
  );
}

export default AddParticipants;
