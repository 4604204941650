import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { FaPlay, FaPause } from "react-icons/fa";
import { HiOutlineVolumeUp } from "react-icons/hi";
import styles from "./index.module.css";

export default function AudioPlayer({ url }) {
  const formWaveSurferOptions = (ref) => ({
    container: ref,
    waveColor: "#99ceff",
    progressColor: "#0085ff",
    cursorColor: "#2165a5",
    cursorWidth: 4,
    barWidth: 4,
    barGap: 4,
    barRadius: 4,
    responsive: true,
    height: 72,
    // If true, normalize by the maximum peak instead of 1.0.
    normalize: true,
    // Use the PeakCache to improve rendering speed of large waveforms.
    partialRender: true,
  });

  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [playing, setPlay] = useState(false);
  const [volume, setVolume] = useState(0.5);

  const [showVolume, setShowVolume] = useState(false);

  const toggleVolume = () => {
    setShowVolume(!showVolume);
  };

  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf("safari") > -1 && ua.indexOf("chrome") === -1;
  };

  const isMacOrIOS = () => /Mac|iPod|iPhone|iPad/.test(navigator.platform);

  useEffect(() => {
    if (isSafari() && isMacOrIOS()) {
      setVolume(0); // start with 0 volume on Safari
      // Don't initialize wavesurfer if on Safari on Mac or iOS
      return () => {};
    }

    setPlay(false);

    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

    wavesurfer.current.load(url);

    wavesurfer.current.on("ready", () => {
      if (wavesurfer.current) {
        wavesurfer.current.setVolume(volume);
        setVolume(0.5);
      }
    });

    return () => {
      if (wavesurfer.current) {
        wavesurfer.current.destroy();
      }
    };
  }, [url]);

  const handlePlayPause = () => {
    setPlay(!playing);
    if (wavesurfer.current) {
      wavesurfer.current.playPause();
    }
  };

  const onVolumeChange = (e) => {
    const { target } = e;
    const newVolume = +target.value;

    if (newVolume) {
      setVolume(newVolume);
      if (wavesurfer.current) {
        wavesurfer.current.setVolume(newVolume || 1);
      }
    }
  };

  if (isSafari() && isMacOrIOS()) {
    return (
      <div>
        {/* eslint-disable */}
        <audio controls>
          <source src={url} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
        {/* eslint-enable */}
      </div>
    );
  }

  return (
    <div className={styles.audioPlayer}>
      <button
        type="button"
        onClick={handlePlayPause}
        className={styles.playPauseButton}
      >
        {!playing ? <FaPlay /> : <FaPause />}
      </button>

      <button
        type="button"
        onClick={toggleVolume}
        className={styles.volumeButton}
      >
        <HiOutlineVolumeUp />
      </button>

      {showVolume && (
        <div className={styles.volumeSlider}>
          <input
            type="range"
            id="volume"
            name="volume"
            min="0.01"
            max="1"
            step=".025"
            onChange={onVolumeChange}
            value={volume}
          />
        </div>
      )}
      <div id="waveform" ref={waveformRef} className={styles.waveform} />
    </div>
  );
}
