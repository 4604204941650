import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Input, Button as MantineButton } from "@mantine/core";
import { Container, Spinner } from "react-bootstrap";
import { PageLayout } from "../../components/page-layout";
import ProjectCard from "./components/project-card";
import OnboardingCard from "../../components/onboarding-card";
import {
  createBlankProjectAction,
  loadProjectsAction,
} from "../../store/actions/projectActions";
import checkProjectStatuses from "../../utils/checkProjectStatuses";
import { FiSearch, FiPlus } from "react-icons/fi";
import "./index.css";
import style from "./index.module.css";
import { darkImages, lightImages } from "../../images/project-images";
import { hashProjectId } from "../participants/profile/components/cover-image";
import PayWallModal from "../../components/paywallModal/paywallModal";

export const ProjectsPage = () => {
  const history = useHistory();
  const { account, celloToken } = useSelector((state) => state.auth); //account
  const { darkMode } = useSelector((state) => state.theme);
  const isFreePlan = account?.plan?.type === "Free";

  let {
    projects,
    loading: projectsLoading,
    loaded,
  } = useSelector((state) => state.project);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (account) {
      loadProjectsAction(account.email);
    }
  }, [account]);

  useEffect(() => {
    if (projectsLoading) return;
    const allProjectsReady = checkProjectStatuses(projects);
    setTimeout(() => {
      // Here i want to call the function again
      if (!allProjectsReady) loadProjectsAction(account.email);
    }, 10000);
  }, [projectsLoading]);

  useEffect(() => {
    // Check if account and celloToken are available
    if (!account || !celloToken) return;

    window.cello = window.cello || { cmd: [] };
    window.cello.cmd.push((cello) =>
      cello.boot({
        productId: process.env.REACT_APP_CELLO_PRODUCT_ID,
        token: celloToken,
        language: "en",
        productUserDetails: {
          firstName: account.name.split(" ")[0] || "",
          lastName: account.name.split(" ")[1] || "",
          fullName: account.name || "",
          email: account.email,
        },
      })
    );

    // Cleanup function
    return () => {
      window.cello.cmd.push(function (cello) {
        cello.shutdown();
      });
    };
  }, [account, celloToken]);
  useEffect(() => {
    if (!account?.phone_verified && !account?.plan?.subscribed) {
      history.push({
        pathname: "/phone-verify",
      });
    }
  }, [account?.plan?.subscribed, account?.phone_verified]);
  const createProject = () => {
    if (account?.plan?.subscribed || account?.phone_verified)
      createBlankProjectAction(account.email);
    else
      history.push({
        pathname: "/phone-verify",
      });
  };

  return (
    <PageLayout>
      <div className={style.wrapper}>
        <Container className={style.Container}>
          <div className={style.projectsHeader}>
            <div style={{ minWidth: 300, width: "33%", fontWeight: "600" }}>
              Projects you&apos;re working on
            </div>
            <Input
              className={style.searchInput}
              placeholder="Search projects, files and insights..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter" && searchTerm) {
                  history.push({
                    pathname: "/search",
                    search: `?q=${encodeURIComponent(searchTerm)}`,
                  });
                }
              }}
              rightSection={
                <div
                  className={style.searchIcon}
                  onClick={() => {
                    if (searchTerm) {
                      history.push({
                        pathname: "/search",
                        search: `?q=${encodeURIComponent(searchTerm)}`,
                      });
                    }
                  }}
                >
                  <FiSearch />
                </div>
              }
            />

            <div style={{ textAlign: "right", width: "33%" }}>
              <MantineButton
                leftIcon={<FiPlus style={{ marginRight: "0.625rem" }} />}
                onClick={createProject}
                variant="filled"
                className={style.newProjectButton}
              >
                New Project
              </MantineButton>
            </div>
          </div>
          {projects.length > 0 ? (
            <>
              <PayWallModal />
              <div className={style.projectContainer}>
                {[...projects].reverse().map((project, index) => {
                  return (
                    <ProjectCard
                      key={project._id}
                      project={project}
                      darkMode={darkMode}
                      disable={
                        isFreePlan &&
                        (index === projects.length - 1 ? false : true)
                      }
                      image={
                        darkMode
                          ? darkImages[hashProjectId(project._id)]
                          : lightImages[hashProjectId(project._id)]
                      }
                    />
                  );
                })}
              </div>
            </>
          ) : loaded ? (
            <OnboardingCard />
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "50vh" }}
            >
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </Container>
      </div>
    </PageLayout>
  );
};
