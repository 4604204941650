import React from "react";
import styles from "./index.module.css";


let videoView=["Files", "Transcript", "Video"]
let textView=["Files", "Transcript"]
function Viewbar({ activeView, setActiveView, activeTab,assetType }) {
  let views=[]
  if(assetType==="VIDEO"||assetType==="AUDIO"){
    views=videoView
  }else{
    views=textView
  }

  return (
    <div className={styles.viewBarContainer}>
      {views.map((view, index) => {
        let newView = "";
        if (activeTab === "insights" && view === "Transcript") {
          newView = "Insights";
        }
        return (
          <div
            className={`${activeView === view ? styles.activeView : ""}`}
            key={index}
            onClick={() => setActiveView(view)}
          >
            {newView?newView:view}
          </div>
        );
      })}
    </div>
  );
}

export default Viewbar;
