import React from "react";
import styles from "./index.module.css";
import Profile from "./profile";
import Overview from "./overview";
function Dashboard() {
  return (
    <div className={styles.dashboard}>
      <Profile />
      <Overview/>
      
    </div>
  );
}

export default Dashboard;
