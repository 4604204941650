import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import projectSlice from "./slices/projectSlice";
import themeSlice from "./slices/themeSlice";
import settingsSlice from "./slices/settingsSlice";
import noteSlice from "./slices/noteSlice";
import chatSlice from "./slices/chatSlice";
import accessTokenMiddleware from "./middleware/accessTokenMiddleware";
import integrationsSlice from "./slices/integrationSlice";
import mediaLibrarySlice from "./slices/mediaLibrarySlice";
import lanesSlice from "./slices/laneSlice";
import publicLinkSlice from "./slices/publicLinkSlice";
import searchResultsSlice from "./slices/searchResultsSlice";
import clipSlice from "./slices/clipSlice";
import modalSlice from "./slices/modalSlice";
import currentProjectSlice from "./slices/currentProject";
import highlightSlice from "./slices/highlightSlice";
import tagsSlice from "./slices/tagsSlice";
import organizationSlice from "./slices/organizationSlice";
import emailSlice from "./slices/emailSlice";
import testSlice from "./slices/testSlice";
import participantSlice from "./slices/participantSlice";
import ribbonSlice from "./slices/ribbonSlice";
import virtualParticipantMeetingSlice from "./slices/virtualParticipantSlice";
import sentimentSlice from "./slices/sentiment";
import adminSlice from "./slices/adminSlice";
import customerRequestsSlice from "./slices/customerRequestsSlice";
const defaultMiddleware = getDefaultMiddleware();

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    theme: themeSlice.reducer,
    project: projectSlice.reducer,
    settings: settingsSlice.reducer,
    note: noteSlice.reducer,
    chat: chatSlice.reducer,
    integrations: integrationsSlice.reducer,
    mediaLibrary: mediaLibrarySlice.reducer,
    lanes: lanesSlice.reducer,
    publicLink: publicLinkSlice.reducer,
    searchResults: searchResultsSlice.reducer,
    modal: modalSlice.reducer,
    currentProject: currentProjectSlice.reducer,
    clip: clipSlice.reducer,
    highlight: highlightSlice.reducer,
    organization: organizationSlice.reducer,
    tags: tagsSlice.reducer,
    email: emailSlice.reducer,
    test: testSlice.reducer,
    participant: participantSlice.reducer,
    ribbon: ribbonSlice.reducer,
    virtualParticipantMeeting: virtualParticipantMeetingSlice.reducer,
    sentiment: sentimentSlice.reducer,
    admin: adminSlice.reducer,
    customerRequests: customerRequestsSlice.reducer,
  },
  middleware: [...defaultMiddleware, accessTokenMiddleware],
});

export default store;
