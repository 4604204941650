import axios from "axios";
import { toastError } from "../utils/toast";
import showAPIToast from "./api-toast";
import store from "../store";
import { modalActions } from "../store/slices/modalSlice";
import { refreshToken } from "../store/actions/authActions";
import jwtDecode from "jwt-decode";
const { dispatch } = store;

export const callExternalApi = async (options) => {
  try {
    if (!options?.refreshBypass) {
      const token = store.getState().auth.accessToken;
      const { exp } = jwtDecode(token);
      if (exp * 1000 < Date.now() + 60 * 1000) {
        await refreshToken();
      }
    }
  } catch (error) {
    console.error("error", error);
  }

  try {
    const response = await axios(options.config);
    const { data } = response;
    showAPIToast(data);
    return {
      data,
      error: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error;

      const { response } = axiosError;

      let message = "http request failed";

      if (response && response.statusText) {
        message = response.statusText;
      }

      if (axiosError.message) {
        message = axiosError.message;
      }
      if (response.data) {
        showAPIToast(response.data);
      }
      if (response && response.data && response.data.message) {
        message = response.data.message;
      }

      const errorCode = response && response.status;

      if (errorCode === 0) {
        toastError(
          "There seems to be an issue with the server. Please try again later or contact support if the problem persists."
        );
      } else if (errorCode === 403) {
        dispatch(modalActions.showPayWallModal(true));
      }

      return {
        data: null,
        error: {
          message,
        },
      };
    }

    return {
      data: null,
      error: {
        message: error.message,
      },
    };
  }
};

export const callExternalApiWithoutToken = async (options) => {
  try {
    const response = await axios(options.config);
    const { data } = response;
    showAPIToast(data);
    return {
      data,
      error: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error;

      const { response } = axiosError;

      let message = "http request failed";

      if (response && response.statusText) {
        message = response.statusText;
      }

      if (axiosError.message) {
        message = axiosError.message;
      }
      if (response.data) {
        showAPIToast(response.data);
      }
      if (response && response.data && response.data.message) {
        message = response.data.message;
      }

      const errorCode = response && response.status;

      if (errorCode === 0) {
        toastError(
          "There seems to be an issue with the server. Please try again later or contact support if the problem persists."
        );
      } else if (errorCode === 403) {
        dispatch(modalActions.showPayWallModal(true));
      }

      return {
        data: null,
        error: {
          message,
        },
      };
    }

    return {
      data: null,
      error: {
        message: error.message,
      },
    };
  }
};
