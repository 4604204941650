import store from "..";
import { callExternalApi } from "../../services/external-api.service";
import { sentimentActions } from "../slices/sentiment";
const { dispatch } = store;
const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;


export const getSentimentByAssetId = async (assetId) => {
    dispatch(sentimentActions.requestStart());
    const config = {
        url: `${apiServerUrl}/api/sentiment/${assetId}`,
        method: "GET",
    };
    const { data, error } = await callExternalApi({ config });
    if (data) return dispatch(sentimentActions.setSentiment({assetId:assetId,data:data}));
    if (error) return dispatch(sentimentActions.requestFail(error));
};