import React from "react";
import { Container, Button } from "react-bootstrap";
import lookout from "../../../images/lookout.svg";
import lookoutWhite from "../../../images/lookout-white.svg";

export const EmptyProject = ({darkMode, setActiveTab}) => {
    return (
      <Container className="d-flex align-items-center justify-content-center h-100">
              <div className="text-center">
                <img src={darkMode ? lookoutWhite : lookout} alt="processing" className="processing-img" style={{maxWidth: '400px'}}/>
                <br/>
                <h1 className="processing-heading">
                  Your project looks empty
                </h1>
                <p className="processing-paragraph">
                  You have not uploaded files to your project yet. Upload your first file to get started.
                </p>
                
                  <Button variant="outline-primary" onClick={() => setActiveTab('overview')}>
                    Upload Files
                  </Button>
                  
              </div>
            </Container>
    )
  };