import React from "react";
import styles from "./index.module.css";
import { OverviewTab } from "./overview";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import currentProjectSlice from "../../../../store/slices/currentProject";
import { loadProject } from "../../../../store/actions/currentProjectActions";
function Overview() {
  const projectData = useSelector((state) => state.currentProject.project);
  const { account: userAccount } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { projectId } = useParams();
  if (!projectData || !userAccount) {
    return null;
  }

  function setProjectData(data) {
    dispatch(currentProjectSlice.actions.setProject({ project: data }));
  }

  function runGetProjectData() {
    loadProject(projectId);
  }
  return (
    <div className={styles.settingArea}>
      <OverviewTab
        setProjectData={setProjectData}
        runGetProjectData={runGetProjectData}
        projectData={projectData}
        userAccount={userAccount}
      />
    </div>
  );
}

export default Overview;
