import React, { useRef, useState } from "react";
import Sidebar from "../components/sidebar";
import Ribbon from "../components/toolbar";
import styles from "../index.module.css";
import ContentArea from "../components/contentArea";
import PublicContentArea from "../components/contentArea/public";
import { useSelector } from "react-redux";
import Viewbar from "../components/viewbar";
import useScreenSize from "../utils/getWindowSizehook";

function DataInsightsTemplate({
  activeTab,
  assets,
  projectId,
  isProcessing,
  setSelectedVideoId,
  selectedVideoId,
  id,
}) {
  const screenSize = useScreenSize();
  const { isPublic } = useSelector((state) => state.currentProject);
  const [activeView, setActiveView] = useState("Files");
  const { data } = useSelector((state) => state.ribbon);
  const selectedVideo = assets?.find((video) => video._id === selectedVideoId);
  let toolbarRef = useRef(null);
  let mappedAssetType = mapAsset[selectedVideo?.assetType];
  let showList = data?.filesList !== undefined ? data.filesList : true;
  const isSmallScreen = screenSize.width < 768;
  return (
    <div>
      <div className={`${id ? styles.publicPage : ""}`}>
        {selectedVideoId && !id && (
          <Ribbon
            assetType={selectedVideo?.assetType}
            toolbarRef={toolbarRef}
            type={activeTab === "insights" ? "insights" : mappedAssetType}
            activeTab={activeTab}
            assetId={selectedVideoId}
          />
        )}

        <Viewbar
        assetType={selectedVideo?.assetType}
          activeView={activeView}
          setActiveView={setActiveView}
          activeTab={activeTab}
        />
        <div
          className={
            isPublic
              ? `${styles.sidebar_contentArea} + ${styles.public_contentArea}`
              : styles.sidebar_contentArea
          }
        >
          {showList && (
            <Sidebar
              id={id}
              activeView={activeView}
              isSmallScreen={isSmallScreen}
              assets={assets}
              setAssetId={setSelectedVideoId}
              assetId={selectedVideoId}
              projectId={projectId}
              tab={activeTab}
            />
          )}
          {selectedVideo &&
            (id ? (
              <PublicContentArea
                id={id}
                isSmallScreen={isSmallScreen}
                activeView={activeView}
                isProcessing={isProcessing}
                toolbarRef={toolbarRef}
                selectedVideo={selectedVideo}
                activeTab={activeTab}
                assetType={selectedVideo?.assetType}
              />
            ) : (
              <ContentArea
                isSmallScreen={isSmallScreen}
                activeView={activeView}
                isProcessing={isProcessing}
                toolbarRef={toolbarRef}
                selectedVideo={selectedVideo}
                activeTab={activeTab}
                assetType={selectedVideo?.assetType}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

const mapAsset = {
  DOC: "Text/CSV/Chat",
  PDF: "Text/CSV/Chat",
  VIDEO: "Video/Audio",
  AUDIO: "Video/Audio",
  CSV: "Text/CSV/Chat",
  TEXT: "Text/CSV/Chat",
};
export default DataInsightsTemplate;
