import React from "react";
import style from "./style.module.css";
import { Button, Spinner } from "react-bootstrap";
import { MdOutlineCheckCircle } from "react-icons/md";
import { Topbar } from "./components/topbar";
import {
  getBasicTestDetails,
  joinTest,
} from "../../../store/actions/testActions";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { toastError } from "../../../utils/toast";
import LimelineTooltip from "../../new-test/launch-test/components/limeline-tooltip";

export const StartTest = () => {
  const history = useHistory();
  const { currentTest, loading } = useSelector((state) => state.test);
  const params = new URLSearchParams(window.location.search);
  const testId = params.get("tId");

  const [disableJoin, setDisableJoin] = React.useState(false);
  const [expand, setExpand] = React.useState(false);

  React.useEffect(() => {
    if (testId) {
      getBasicTestDetails(testId).then(({ error }) => {
        if (error.message.includes("no longer accepting participants"))
          return history.replace("/participant/test-concluded");
      });
    } else {
      history.push("/participants");
    }
  }, []);

  const startTest = async () => {
    if (currentTest.alreadyAttempted)
      return toastError("You have already attempted this test");

    if (currentTest.questions?.length > 0)
      return history.push(`/participant/screener?tId=${testId}`);

    if (currentTest.ndaLink)
      return history.push(`/participant/sign-document?tId=${testId}`);

    setDisableJoin(true);

    const { error } = await joinTest(testId);
    if (!error) return history.push(`/participant/redirect?tId=${testId}`);

    setDisableJoin(false);
  };

  const instructions = currentTest.instructions || "";
  const instructionsLength = instructions.length;

  return (
    <div className={style.wrapper}>
      <Topbar />

      {loading ? (
        <div className="position-fixed top-50 start-50 translate-middle">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className={style.content}>
          <div className={style.heading}>
            Welcome to User Evaluation AI Interview
          </div>
          <div className={style.testDetails}>
            <div className={style.grey}>Test Details</div>
            <div className={style.bold}>{currentTest?.title || ""}</div>
            {currentTest.instructions && (
              <div>
                {instructionsLength > 260 && expand ? (
                  <>
                    {instructions}
                    <button
                      className={style.expandBtn}
                      onClick={() => setExpand(false)}
                    >
                      &nbsp;See less
                    </button>
                  </>
                ) : (
                  <>
                    {instructions.slice(0, 260)}
                    {instructionsLength > 260 && (
                      <button
                        className={style.expandBtn}
                        onClick={() => setExpand(true)}
                      >
                        &nbsp;See more
                      </button>
                    )}
                  </>
                )}
              </div>
            )}
            <div>
              Expected session length: {currentTest?.duration || "-"} minutes
            </div>
            <div>Compensation: {currentTest?.reward || "-"} USD</div>
          </div>
          <div className={style.note}>
            <div className={style.noteHeading}>Before we begin</div>
            <li className={style.noteList}>
              <span>
                <MdOutlineCheckCircle className={style.noteListIcon} />
              </span>
              Confirm browser compatibility (Chrome, Edge, Firefox or Safari)
            </li>
            <li className={style.noteList}>
              <span>
                <MdOutlineCheckCircle className={style.noteListIcon} />
              </span>
              Check your microphone
            </li>
            <li className={style.noteList}>
              <span>
                <MdOutlineCheckCircle className={style.noteListIcon} />
              </span>
              Check your internet connection
            </li>
          </div>
          <Button
            className={style.button}
            disabled={disableJoin}
            onClick={startTest}
          >
            Start the test
          </Button>
          <div className={style.limelineBadge}>
            Interview experience powered by
            <LimelineTooltip className="ps-0" />
          </div>
        </div>
      )}
    </div>
  );
};