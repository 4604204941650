const formatDuration = (duration) => {
  // remove decimals from duration
  duration = Math.floor(duration);
  let durationString = "";
  if (duration < 60) {
    durationString = `${duration} seconds`;
  } else if (duration < 3600) {
    durationString = `${Math.floor(duration / 60)} minutes`;
  } else {
    durationString = `${Math.floor(duration / 3600)} hours`;
  }
  return durationString;
};

export default formatDuration;