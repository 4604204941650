import React from "react";
import { useSelector } from "react-redux";
import style from "./profile.module.css";
import UserAvatar from "../../../../components/userAvatar";
import { MdVerified } from "react-icons/md";
import { Tooltip } from "@mantine/core";

export default function Profile() {
  const { participant } = useSelector((state) => state.participant);
  const { user } = useSelector((state) => state.auth);
  const [hasImage, setHasImage] = React.useState(false);

  // check state.participant.picture
  React.useEffect(() => {
    if (participant?.picture) {
      setHasImage(true);
    }
  }, [participant]);

  return (
    <div className={style.profile}>
      {hasImage ? (
        <img
          src={participant?.picture}
          alt="Profile"
          className={style.picture}
          onError={(e) => {
            e.target.onerror = null;
            setHasImage(false);
          }}
        />
      ) : (
        <UserAvatar
          user={{
            name: participant?.name,
            picture: user?.picture,
          }}
          style={{ width: "8rem", height: "8rem", fontSize: "3rem" }}
        />
      )}
      <div className={style.name}>
        {participant?.name}{" "}
        {user.phone_verified && (
          <Tooltip
            label="Verified Account"
            color="var(--pink)"
            position="right"
            withArrow
            arrowPosition="center"
          >
            <span>
              <MdVerified color="var(--pink)" />
            </span>
          </Tooltip>
        )}
      </div>
      <div className={style.location}>
        {participant?.city} {participant?.state} {participant?.country}
      </div>
      {participant?.headline && <div>{participant?.headline}</div>}
      <div className="d-md-flex align-items-center gap-2">
        {participant?.profile_identifier && (
          <div className={style.username}>
            @{participant?.profile_identifier}
          </div>
        )}
        {participant?.profile_identifier && participant?.description && (
          <div className={style.dot}>•</div>
        )}
        <div className={style.description}>{participant?.description}</div>
      </div>
    </div>
  );
}
