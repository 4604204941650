import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FiX, FiDownload } from "react-icons/fi";
import { modalActions } from "../../../../store/slices/modalSlice";
import { deleteClip } from "../../../../store/actions/clipActions";
import "react-tagsinput/react-tagsinput.css";
import { useDispatch, useSelector } from "react-redux";
import { loadAsset } from "../../../../store/actions/currentProjectActions";
import formatTime from "../../../../utils/formatTime";
import styles from "./showClipModal.module.css";
import MiniPlayer from "../../../../components/mini-player";
import GetSpeakerData from "../../components/speakerName";
import { Spinner } from "react-bootstrap";
import { getAssetById } from "../../../../store/actions/projectActions";
function ShowClipModal({ show, selectedClip }) {
  const projectData = useSelector((state) => state.currentProject.project);
  const [transcript, setTranscript] = useState([]);
  const [data, setData] = useState([]);
  const [video, setVideo] = useState(null);
  const [speakers, setSpeakers] = useState([]);
  const [loading, setLoading] = useState(true);
  const assetId = selectedClip?.assetId;

  useEffect(() => {
    async function fetchData() {
      const { data, error } = await getAssetById(assetId);
      if (data && data.speakers) {
        setSpeakers(data.speakers);
      }
      if (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [assetId]);

  useEffect(() => {
    if (transcript.length > 0) {
      let data = [];
      transcript.forEach((word) => {
        if (
          word.start >= selectedClip.startTime &&
          word.end <= selectedClip.endTime
        ) {
          data.push(word);
        }
      });
      let speakerData = [];
      let speaker = data[0].speaker;
      let speakerObj = {
        speaker: speaker,
        words: [],
        start: data[0].start,
      };
      data.forEach((word) => {
        if (word.speaker === speaker) {
          speakerObj.words.push(word);
          speakerObj["end"] = word.end;
        } else {
          speakerData.push(speakerObj);
          speaker = word.speaker;
          speakerObj = {
            speaker: speaker,
            words: [],
            start: word.start,
          };
          speakerObj.words.push(word);
        }
      });
      speakerData.push(speakerObj);

      setData(speakerData);
    }
  }, [transcript]);

  useEffect(() => {
    if (video && video.transcript && video.transcript[0].words) {
      setTranscript(video.transcript[0].words);
    }
  }, [video]);

  useEffect(() => {
    if (selectedClip) {
      let newvideo = projectData.videos.find((video) => {
        return video._id === selectedClip.assetId;
      });
      setVideo(newvideo);

    }
  }, [projectData.videos]);

  useEffect(() => {
    async function loadSelectedAsset() {
      if (selectedClip) {
        setLoading(true);
        await loadAsset(selectedClip.assetId);
        setLoading(false);
      }
    }

    loadSelectedAsset();
  }, [selectedClip]);

  const dispatch = useDispatch();
  return (
    <>
      {selectedClip && (
        <Modal
          show={show}
          centered
          backdrop="static"
          size="lg"
          className="pay-modal-container"
        >
          <Modal.Header style={{ paddingBottom: "0px", border: "none" }}>
            <h6>Clip</h6>
            <button
              style={{
                fontSize: "1.3rem",
                border: "none",
                background: "transparent",
              }}
              aria-label="Close"
            >
              <FiX
                style={{ color: "var(--grey10)" }}
                aria-hidden="true"
                onClick={() => {
                  setData([]);
                  dispatch(modalActions.hideClipModal());
                }}
              />
            </button>
          </Modal.Header>


          {loading && (
            <div className={styles.spinnerContainer}>
              <Spinner animation="border" variant="primary" />
            </div>
          )}
          {!loading && (
            <Modal.Body>
              <div className={styles.clipInfo}>
                <div className={styles.player}>
                  {data?.length > 0 && (
                    <MiniPlayer
                      file={video}
                      startTime={data[0].start}
                      endTime={data[data.length - 1].end}
                    />
                  )}
                </div>
                <div className={styles.notesAndTagsContainer}>
                  <div>
                    <p>Notes</p>
                    <div className={styles.notes}>
                      {selectedClip.clipNotes?.trim()?.length > 0
                        ? selectedClip.clipNotes
                        : "No notes provided"}
                    </div>
                  </div>
                  <div>
                    <p>Tags</p>
                    <div className={styles.tags}>
                      {selectedClip.clipTags?.length > 0
                        ? selectedClip.clipTags.map((tag, index) => {
                          return (
                            <span key={index} className={styles.tag}>
                              {tag}
                            </span>
                          );
                        })
                        : "No tags provided"}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.modalSpeakerSection}>
                {data &&
                  data.map((item, index) => {
                    return (
                      <div key={index}>
                        <p>
                          <GetSpeakerData
                            speakers={speakers}
                            sentenceSpeaker={item.speaker}
                          />
                        </p>
                        <p>
                          {" "}
                          <span className={styles.timeStamp}>
                            {formatTime(item.start)} - {formatTime(item.end)}
                          </span>
                        </p>
                        <p>
                          {item.words.map((word) => {
                            // const isCurrentWord =
                            //   currentWord >= word.start && currentWord <= word.end;
                            return (
                              <span key={word.start + 1}>
                                <span key={word.start}>{word.text}</span>
                                {word !== item.words[item.words.length - 1] ? (
                                  <span> </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            );
                          })}
                        </p>
                      </div>
                    );
                  })}
              </div>
              <div className={styles.clipButtonContainer}>
                <button
                  className={styles.deleteClipButton}
                  onClick={() => {
                    deleteClip(projectData._id, selectedClip._id);
                    setData([]);
                    dispatch(modalActions.hideClipModal());
                  }}
                >
                  Delete
                </button>
                <Button
                  className={styles.downloadClipButton}
                  variant="outline-primary"
                  onClick={() => {
                    window.open(selectedClip.link, "_blank");
                  }}
                  disabled={!selectedClip.link}
                >
                  {selectedClip.link && <FiDownload />}
                  {selectedClip.link ? "Download Clip" : "Processing clip..."}
                </Button>
              </div>
            </Modal.Body>)}
        </Modal>
      )}
    </>
  );
}

export default ShowClipModal;
