import React from "react";
import { Tooltip } from "@mantine/core";
import { FiPlayCircle } from "react-icons/fi";
import { Button } from "react-bootstrap";

function PlayContext({
  isPDF_DOC_TEXT,
  normalTime,
  handleSeek,
  showSources,
  fake,
}) {

  
  if (fake) {
    return (
      <button
        style={{
          backgroundColor: "transparent",
          border: "none",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          fontSize: "0.9rem",
          color: "var(--grey5)"
        }}
        className="project-insight-btn"
      >
        <FiPlayCircle
          className="project-insight-icons"
          style={{ color: "var(--grey5)" }}
        />{" "}
        Play Context
      </button>
    );
  }

  if (!showSources) {
    return !normalTime ? (
      <>
        <Tooltip label="Contains multiple/adjusted sources">
          <button
            className="project-insight-btn"
            disabled={true}
            style={{
              backgroundColor: "transparent",
              border: "none",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              fontSize: "0.9rem",
              padding: "0px",
            }}
            onClick={() => {
              if (normalTime) {
                handleSeek(normalTime);
              }
            }}
          >
            <FiPlayCircle
              style={{ opacity: "0.7" }}
              className="project-insight-icons"
            />
          </button>
        </Tooltip>
      </>
    ) : (
      <>
        <Tooltip label="Play Context">
          <Button
            className="project-insight-btn"
            disabled={!normalTime}
            style={{ padding: "0px" }}
            onClick={() => {
              if (normalTime) {
                handleSeek(normalTime);
              }
            }}
            variant="transparent"
            size="sm"
          >
            <FiPlayCircle className="project-insight-icons" />
          </Button>
        </Tooltip>
      </>
    );
  }
  return (
    <>
      {!isPDF_DOC_TEXT &&
        (!normalTime ? (
          <>
            <Tooltip label="Contains multiple/adjusted sources">
              <button
                className="project-insight-btn"
                disabled={true}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  fontSize: "0.9rem",
                }}
                onClick={() => {
                  if (normalTime) {
                    handleSeek(normalTime);
                  }
                }}
              >
                <FiPlayCircle
                  style={{ opacity: "0.7" }}
                  className="project-insight-icons"
                />
                Play Context
              </button>
            </Tooltip>
          </>
        ) : (
          <Button
            className="project-insight-btn"
            disabled={!normalTime}
            onClick={() => {
              if (normalTime) {
                handleSeek(normalTime);
              }
            }}
            variant="transparent"
            size="sm"
          >
            <FiPlayCircle className="project-insight-icons" />
            Play Context
          </Button>
        ))}
    </>
  );
}

export default PlayContext;
