import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col, Nav, Card } from "react-bootstrap";
import { PageLayout } from "../../components/page-layout";
import Account from "./components/account";
import PlanAndUsage from "./components/plan-and-usage";
import Team from "./components/team";
import ApiKeys from "./components/api-keys";
import Organization from "./components/organization";
import EmailPrefs from "./components/email-prefs";
import Other from "./components/other";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { useHistory } from "react-router-dom";
import CustomVocabulary from "./components/CustomVocabulary";

const TABS = {
  1: "account",
  2: "organization",
  3: "plan-and-usage",
  4: "team",
  5: "other",
};

/**
 * @todo
 * 1. Refactor code to use an array of objects for tabs
 */

export const SettingsPage = () => {
  const { account } = useSelector((state) => state.auth);
  const [activeTab, setActiveTab] = useState(1);
  const { darkMode } = useSelector((state) => state.theme);
  const isAdmin = account?.role;
  const history = useHistory();

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("tab")) {
      setActiveTab(
        +Object.keys(TABS).find((key) => TABS[key] === params.get("tab")) || 1
      );
    }
  }, []);

  return (
    <PageLayout>
      {/* small center-aligned container */}
      <Container className="d-sm-flex justify-content-center align-items-center mt-5">
        <Card className="setting-card">
          <Card.Body>
            <Row>
              <Col className="mt-2">
                <h4 className="mb-sm-4 ">Settings</h4>
                <Nav
                  pills
                  activeKey={activeTab}
                  className="category-pills mb-4"
                >
                  <Nav.Item>
                    <Nav.Link
                      className={activeTab === 1 ? "item-active" : ""}
                      onClick={() => {
                        history.push(`/settings?tab=${TABS[1]}`);
                        setActiveTab(1);
                      }}
                    >
                      Account
                    </Nav.Link>
                  </Nav.Item>
                  {isAdmin && account?.plan?.subscribed === true && (
                    <Nav.Item>
                      <Nav.Link
                        className={activeTab === 2 ? "item-active" : ""}
                        onClick={() => {
                          history.push(`/settings?tab=${TABS[2]}`);
                          setActiveTab(2);
                        }}
                      >
                        Organization
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  <Nav.Item>
                    <Nav.Link
                      className={activeTab === 3 ? "item-active" : ""}
                      onClick={() => {
                        history.push(`/settings?tab=${TABS[3]}`);
                        setActiveTab(3);
                      }}
                    >
                      Plan &amp; Usage
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className={activeTab === 4 ? "item-active" : ""}
                      onClick={() => {
                        history.push(`/settings?tab=${TABS[4]}`);
                        setActiveTab(4);
                      }}
                    >
                      Team
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className={activeTab === 5 ? "item-active" : ""}
                      onClick={() => {
                        history.push(`/settings?tab=${TABS[5]}`);
                        setActiveTab(5);
                      }}
                    >
                      Other
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                {activeTab === 1 && <Account />}
                {activeTab === 2 && isAdmin && (
                  <Organization account={account} />
                )}
                {activeTab === 3 && <PlanAndUsage />}
                {activeTab === 4 && (
                  <Team account={account} darkMode={darkMode} />
                )}
                {activeTab === 5 && (
                  <>
                    <CustomVocabulary account={account}/>
                    <EmailPrefs account={account} darkMode={darkMode} />
                    <ApiKeys account={account} darkMode={darkMode} />
                    <Other />
                  </>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </PageLayout>
  );
};
