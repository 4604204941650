import React from "react";
import { Link } from "react-router-dom";
import backIconWhite from "../../../images/icons/arrow-left-white.svg";
import Badge from "react-bootstrap/Badge";
import style from "./topbar.module.css";
import { useSelector } from "react-redux";
import EditableTitle from "../../project/components/topbar/components/EditableTitle";
import { changeTitle } from "../../../store/actions/testActions";
import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { TfiShare } from "react-icons/tfi";
import { MdOutlineContentCopy } from "react-icons/md";
import { Form, InputGroup, Button } from "react-bootstrap";

const TEST_STATUS = {
  Live: "Conducting",
  Draft: "Draft",
  Completed: "Completed",
};

const TopBar = ({ currentTab, testId }) => {
  const { currentTest } = useSelector((state) => state.test);
  const [opened, { open, close }] = useDisclosure();

  return (
    <div className={style.wrapper}>
      <div className={style.info}>
        <Link to="/interviews">
          <img src={backIconWhite} alt="back" />
        </Link>
        <EditableTitle
          initialName={currentTest.title}
          handleUpdate={(_, newTitle) => changeTitle(testId, newTitle)}
        />
        <Badge bg="success">{TEST_STATUS[currentTest.status]}</Badge>
        <Button
          onClick={open}
          className="ms-auto d-flex align-items-center gap-1"
        >
          <TfiShare /> Share
        </Button>
      </div>
      <div className={style.nav}>
        <Link
          to={`/interview/${testId}/progress`}
          className={currentTab === "Progress" ? style.active : null}
        >
          Progress
        </Link>
        <Link
          to={`/interview/${testId}/analyze`}
          className={currentTab === "Analyze" ? style.active : null}
        >
          Analyze
        </Link>
        <Link
          to={`/interview/${testId}/participants`}
          className={currentTab === "Participants" ? style.active : null}
        >
          Participants
        </Link>
        <Link
          to={`/interview/${testId}/details`}
          className={currentTab === "Test Details" ? style.active : null}
        >
          Test Details
        </Link>
      </div>
      <Modal
        opened={opened}
        onClose={close}
        title="Invite participants to this interview"
        size="lg"
      >
        <p>Share this interview with anyone using the link below</p>

        <InputGroup className="mb-2">
          <Form.Control
            readOnly
            value={`${window.location.origin}/participant/welcome?tId=${testId}`}
          />
          <Button
            className="d-flex align-items-center gap-1"
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.origin}/participant/welcome?tId=${testId}`
              );
            }}
          >
            <MdOutlineContentCopy /> Copy
          </Button>
        </InputGroup>
      </Modal>
    </div>
  );
};

export default TopBar;
