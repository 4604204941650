/* eslint-disable react/no-unknown-property */
/* eslint-disable react/react-in-jsx-scope */
import axios from "axios";
import "./index.css";
/**
 * SimpleVideo Tool for the Editor.js
 * Works only with pasted video URLs and requires no server-side uploader.
 *
 * @typedef {object} SimpleVideoData
 * @description Tool's input and output data format
 * @property {string} url — video URL
 * @property {string} caption — video caption
 * @property {boolean} autoplay - video autoplay enabled
 * @property {boolean} controls - video controls enabled
 * @property {boolean} muted - video muted enabled
 * @property {boolean} stretched - should video be stretched to full width of container
 */
class VideoTool {
  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {{data: SimpleVideoData, config: object, api: object}}
   *   data — previously saved data
   *   config - user config for Tool
   *   api - Editor.js API
   */
  constructor({ data, config, api, readOnly }) {
    /**
     * Editor.js API
     */
    this.api = api;

    /**
     * When block is only constructing,
     * current block points to previous block.
     * So real block index will be +1 after rendering
     * @todo place it at the `rendered` event hook to get real block index without +1;
     * @type {number}
     */
    this.blockIndex = this.api.blocks.getCurrentBlockIndex() + 1;

    /**
     * Styles
     */
    this.CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      input: this.api.styles.input,
      settingsButton: [
        this.api.styles.settingsButton,
        "ce-popover-item",
        "custom-video-settings"
    
      ],
      settingIcon: "ce-popover-item__icon",
      settingLabel: "ce-popover-item__title",
      settingActive: "ce-popover-item--active",

      /**
       * Tool's classes
       */
      wrapper: "cdx-simple-video",
      videoHolder: "cdx-simple-video__picture",
      caption: "cdx-simple-video__caption",
    };

    /**
     * Nodes cache
     */
    this.nodes = {
      wrapper: null,
      videoHolder: null,
      video: null,
      caption: null,
      label: null,
      loader: null,
    };

    /**
     * Tool's initial data
     */
    this.data = {
      url: data.url || "",
      caption: data.caption || "",
      autoplay: data.autoplay !== undefined ? data.autoplay : false,
      controls: data.controls !== undefined ? data.controls : true,
      muted: data.muted !== undefined ? data.muted : false,
    };

    /**
     * Available Video settings
     */
    this.settings = [
      {
        name: "autoplay",
        label: "Autoplay",
        icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="24px" width="24px"><path fill="none" stroke-miterlimit="10" stroke-width="32" d="M112 111v290c0 17.44 17 28.52 31 20.16l247.9-148.37c12.12-7.25 12.12-26.33 0-33.58L143 90.84c-14-8.36-31 2.72-31 20.16z"></path></svg>`,
      },
      {
        name: "muted",
        label: "Muted",
        icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 5h2.79l3.86-3.83.85.35v13l-.85.33L4.29 11H1.5l-.5-.5v-5l.5-.5zm3.35 5.17L8 13.31V2.73L4.85 5.85 4.5 6H2v4h2.5l.35.17zm9.381-4.108l.707.707L13.207 8.5l1.731 1.732-.707.707L12.5 9.207l-1.732 1.732-.707-.707L11.793 8.5 10.06 6.77l.707-.707 1.733 1.73 1.731-1.731z"></path></svg>`,
      },
      {
        name: "controls",
        label: "Show controls",
        icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" height="24px" width="24px"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M5.54 8.46 2 12l3.54 3.54 1.76-1.77L5.54 12l1.76-1.77zm6.46 10-1.77-1.76-1.77 1.76L12 22l3.54-3.54-1.77-1.76zm6.46-10-1.76 1.77L18.46 12l-1.76 1.77 1.76 1.77L22 12zm-10-2.92 1.77 1.76L12 5.54l1.77 1.76 1.77-1.76L12 2z"></path><circle cx="12" cy="12" r="3"></circle></svg>`,
      },
    ];
  }

  static get isReadOnlySupported() {
    return true;
  }

  render() {
    let wrapper = this._make("div", [
        this.CSS.baseClass,
        this.CSS.wrapper,
        "this-is-a-video",
      ]),
      loader = this._make("div", this.CSS.loading),
      videoHolder = this._make("div", this.CSS.videoHolder),
      video = this._make("video"),
      caption = this._make("div", [this.CSS.input, this.CSS.caption], {
        contentEditable: "true",
        innerHTML: this.data.caption || "Set a caption",
        placeholder: "Enter a caption",
      }),
      input = this._make("input", ["visually-hidden"], {
        type: "file",
        accept: "video/*",
        onchange: (event) => {
          const [file] = event.target.files;
          if (file) {
            this._uploadVideo(file);
          }
        },
      }),
      label = this._make(
        "label",
        ["video-upload-label", "cdx-button", "w-100"],
        {
          innerHTML: "Select a video",
          onclick: () => {
            input.click();
          },
        }
      );

    if (this.data.url) {
      video.src = this.data.url;
      video.controls = this.data.controls;
      video.autoplay = this.data.autoplay;
      video.muted = this.data.muted;
      wrapper.appendChild(loader);
    } else {
      label.appendChild(input);
      wrapper.appendChild(label);

      this.nodes.label = label;
    }

    video.onloadstart = () => {
      wrapper.classList.remove(this.CSS.loading);
      videoHolder.appendChild(video);
      wrapper.appendChild(videoHolder);
      wrapper.appendChild(caption);
      loader.remove();
      this._acceptTuneView();
    };

    this.nodes.videoHolder = videoHolder;
    this.nodes.wrapper = wrapper;
    this.nodes.video = video;
    this.nodes.caption = caption;
    this.nodes.loader = loader;

    return wrapper;
  }

  /**
   * @public
   * Saving method
   * @param {Element} blockContent - Tool's wrapper
   * @return {SimpleVideoData}
   */
  save(blockContent) {
    let video = blockContent.querySelector("video"),
      caption = blockContent.querySelector("." + this.CSS.input);

    if (!video) {
      return this.data;
    }

    return Object.assign(this.data, {
      url: video.src,
      caption: caption.innerHTML,
      controls: video.controls,
      autoplay: video.autoplay,
      muted: video.muted,
    });
  }

  /**
   * @param {SimpleVideoData} savedData
   */
  validate(savedData) {
    if (!savedData.url.trim()) {
      return false;
    }

    return true;
  }

  static get sanitize() {
    return {
      url: {},
      stretched: {},
      controls: {},
      autoplay: {},
      muted: {},
      caption: {
        br: true,
      },
    };
  }

  static get toolbox() {
    return {
      title: "Video",
      icon: `<svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" height="200px" width="200px" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"></path></svg>`,
    };
  }

  /**
   * @return {SimpleVideoData}
   */
  get data() {
    return this._data;
  }

  /**
   * @param {SimpleVideoData} data
   */
  set data(data) {
    this._data = Object.assign({}, this.data, data);

    if (this.nodes.video) {
      this.nodes.video.autoplay = this.data.autoplay;
      this.nodes.video.controls = this.data.controls;
      this.nodes.video.muted = this.data.muted;
      this.nodes.video.src = this.data.url;
    }

    if (this.nodes.caption) {
      this.nodes.caption.innerHTML = this.data.caption;
    }
  }

  /**
   * Makes buttons with tunes: add background, add border, stretch video
   * @return {HTMLDivElement}
   */
  renderSettings() {
    let wrapper = document.createElement("div");

    this.settings.forEach((tune) => {
      let el = document.createElement("div");
      el.className = this.CSS.settingsButton.join(" ");
      const icon = document.createElement("span");
      icon.classList.add(this.CSS.settingIcon);
      const label = document.createElement("span");
      label.classList.add(this.CSS.settingLabel);

      icon.innerHTML = tune.icon;
      label.innerHTML = tune.label;
      el.title = tune.label;
      el.appendChild(icon);
      el.appendChild(label);

      el.addEventListener("click", () => {
        this._toggleTune(tune.name);
        el.classList.toggle(this.CSS.settingActive);
      });

      el.classList.toggle(this.CSS.settingActive, this.data[tune.name]);

      wrapper.appendChild(el);
    });
    return wrapper;
  }

  /**
   * Helper for making Elements with attributes
   *
   * @param  {string} tagName           - new Element tag name
   * @param  {array|string} classNames  - list or name of CSS classname(s)
   * @param  {Object} attributes        - any attributes
   * @return {Element}
   */
  _make(tagName, classNames = null, attributes = {}) {
    let el = document.createElement(tagName);

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }

    for (let attrName in attributes) {
      el[attrName] = attributes[attrName];
    }

    return el;
  }

  /**
   * Click on the Settings Button
   * @private
   */
  _toggleTune(tune) {
    this.data[tune] = !this.data[tune];
    this._acceptTuneView();
  }

  /**
   * Add specified class corresponds with activated tunes
   * @private
   */
  _acceptTuneView() {
    this.settings.forEach((tune) => {
      this.nodes.videoHolder.classList.toggle(
        this.CSS.videoHolder +
          "--" +
          tune.name.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`),
        !!this.data[tune.name]
      );

      if (tune.name === "stretched") {
        this.api.blocks.stretchBlock(this.blockIndex, !!this.data.stretched);
      }

      if (tune.name === "controls") {
        this.nodes.video.controls = this.data.controls;
      }

      if (tune.name === "autoplay") {
        this.nodes.video.autoplay = this.data.autoplay;
      }

      if (tune.name === "muted") {
        this.nodes.video.muted = this.data.muted;
      }
    });
  }

  async _uploadVideo(file) {
    const formData = new FormData();
    formData.append("file", file);

    this.nodes.wrapper.appendChild(this.nodes.loader);
    this.nodes.label.classList.add("visually-hidden");

    try {
      const { data } = await axios.post(
        process.env.REACT_APP_API_SERVER_URL + "/api/video/upload/asset",
        formData
      );

      this.nodes.video.controls = this.data.controls;
      this.nodes.video.autoplay = this.data.autoplay;
      this.nodes.video.muted = this.data.muted;
      this.nodes.video.src = data.link;
      this.nodes.wrapper.removeChild(this.nodes.label);
    } catch (error) {
      console.error("Failed to upload video", error);
      this.nodes.wrapper.removeChild(this.nodes.loader);
      this.nodes.label.classList.remove("visually-hidden");
    }
  }
}

export default VideoTool;
