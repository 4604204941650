import React, { useState } from "react";
import { Emoji } from "emoji-picker-react";
import truncateString from "../../../../utils/truncateString";
import { NewAsset } from "../new-asset";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const emojiUnicodes = [
  {
    unicode: "1f41b",
    type: "Bug Reports",
  },
  {
    unicode: "1f680",
    type: "Feature Requests",
  },
  {
    unicode: "1f62c",
    type: "Pain Points",
  },
  {
    unicode: "1f389",
    type: "Delighters",
  },
  {
    unicode: "1f4d8",
    type: "Key Insights",
  },
  {
    unicode: "1f914",
    type: "Questions for Next Session",
  },
  {
    unicode: "1f4cb",
    type: "Jobs To Be Done",
  },
  {
    unicode: "1f4ac",
    type: "Quotable Quotes",
  },
  {
    unicode: "1f4c8",
    type: "Topics",
  },
  {
    unicode: "1f4a1",
    type: "Opportunity Areas",
  },
  {
    unicode: "1f4a2",
    type: "Opposing Views",
  },
  {
    unicode: "1f4a3",
    type: "Session Summary",
  },
];

const AssetInsights = ({ asset }) => {
  const history = useHistory();
  const params = useParams();
  const handleInsightClick = (asset, insightType) => {
    history.push(`/p/${params.projectId}/asset/${asset._id}/${insightType}`);
  };
  if (!Array.isArray(asset?.insights)) {
    return null;
  }
  return asset.insights.map((insight) => (
    <li
      key={insight.insightType}
      onClick={() => handleInsightClick(asset, insight.insightType)}
      className="insight-item"
    >
      <span>
        {/* emoji if it exists, otherwise use the insight type */}
        <Emoji
          unified={
            emojiUnicodes.find((emoji) => emoji.type === insight.insightType)
              .unicode || "1f4ac"
          }
          size="20"
        />{" "}
        {truncateString(insight.insightType, 26)}
      </span>
    </li>
  ));
};

const getAssetContentTitle = (video) =>
  video.assetType === "TEXT" ||
  video.assetType === "PDF" ||
  video.assetType === "DOC" ||
  video.assetType === "CSV"
    ? "Content"
    : "Transcript";

const AssetEmoji = ({ video }) => {
  return (
    <React.Fragment>
      {video.assetType === "VIDEO" && <Emoji unified="1f3a5" size="20" />}
      {video.assetType === "TEXT" && <Emoji unified="1f4d2" size="20" />}
      {video.assetType === "PDF" && <Emoji unified="1f4d2" size="20" />}
      {video.assetType === "DOC" && <Emoji unified="1f4d2" size="20" />}
      {video.assetType === "CSV" && <Emoji unified="1f4ca" size="20" />}
      {video.assetType === "LIVECHAT" && <Emoji unified="1f4ac" size="20" />}
      {video.assetType !== "VIDEO" &&
        video.assetType !== "TEXT" &&
        video.assetType !== "PDF" &&
        video.assetType !== "DOC" &&
        video.assetType !== "LIVECHAT" &&
        video.assetType !== "CSV" && <Emoji unified="1f4d2" size="20" />}
    </React.Fragment>
  );
};

const Asset = ({ asset, handleInsightClick, index, params }) => {
  const [expanded, setExpanded] = useState(true);

  const handleExpand = () => {
    setExpanded((expanded) => !expanded);
  };

  const onInsightClick = (event, asset, type) => {
    event.stopPropagation();
    handleInsightClick(asset, type);
  };

  return (
    <>
      <span onClick={handleExpand}>
        <span
          style={{
            display: "inline-block",
            marginRight: "0.25rem",
            transform: `rotate(${expanded ? "90" : "0"}deg)`,
          }}
        >
          &#x276F;
        </span>
        &nbsp;&nbsp;
        <span onClick={(event) => onInsightClick(event, asset, "Transcript")}>
          <AssetEmoji video={asset} />
          &nbsp;{asset.name}
        </span>
      </span>
      {expanded && (
        <ul style={{ listStyleType: "none", paddingLeft: "32px" }}>
          <li
            key={"transcript-" + index}
            onClick={(event) => onInsightClick(event, asset, "Transcript")}
            className="insight-item"
          >
            <Emoji unified="1f4ac" size="20" /> {getAssetContentTitle(asset)}
          </li>
          <AssetInsights asset={asset} />
        </ul>
      )}
    </>
  );
};

const AssetList = ({ assets }) => {
  const history = useHistory();
  const params = useParams();
  const handleInsightClick = (asset) => {
    history.push(`/p/${params.projectId}/asset/${asset._id}`);
  };
  if (assets?.length === 0) {
    return <li>No files added</li>;
  }
  return assets.map((asset, index) => (
    <li key={index}>
      <Asset
        params={params}
        asset={asset}
        handleInsightClick={handleInsightClick}
        index={index}
      />
    </li>
  ));
};

export const InsightsExpandable = ({
  projectData,
  userAccount,
  runGetProjectData,
}) => {
  const [expanded, setExpanded] = useState(true);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="pages-link-group">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span onClick={handleExpand}>
          <span
            style={{
              display: "inline-block",
              transform: `rotate(${expanded ? "90" : "0"}deg)`,
            }}
          >
            &#x276F;
          </span>
          &nbsp;&nbsp;
          <span
            style={{
              fontSize: 12,
              fontWeight: 600,
              textTransform: "uppercase",
              letterSpacing: 1,
            }}
          >
            Insights
          </span>
        </span>
        <NewAsset
          projectId={projectData._id}
          account={userAccount}
          runGetProjectData={runGetProjectData}
          buttonType="icon"
        />
      </div>
      <ul className="pages-link-list">
        {expanded && <AssetList assets={projectData.videos} />}
      </ul>
    </div>
  );
};
