import React from "react";
import { Button } from "react-bootstrap";
import { Modal } from "@mantine/core";
import { modalActions } from "../../../../store/slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { createLinkAction } from "../../../../store/actions/publicLinkActions";
import { toastSuccess } from "../../../../utils/toast";

function ShareReportModal() {
  const dispatch = useDispatch();
  const { reportLinkSharePopup } = useSelector((state) => state.modal);
  const { account: userAccount } = useSelector((state) => state.auth);
  const { link } = useSelector((state) => state.publicLink);
  const { project } = useSelector((state) => state.currentProject);
  const projectId = project?._id;
  const shareReport = async (resourceId) => {
    const payload = {
      resourceId,
      resource: "project report",
      createdBy: userAccount._id,
    };

    await createLinkAction(payload);
  };
  const copyLink = async (link) => {
    await navigator.clipboard.writeText(link);
    toastSuccess("Link copied to clipboard");
  };
  return (
    <Modal
      size="xl"
      opened={reportLinkSharePopup}
      onClose={() => dispatch(modalActions.hideShareReportLinkModal())}
      title="Share Report"
    >
      <p>
        Share this report with people outisde your organization using the link
        below
      </p>
      <div className="d-flex">
        <input
          type="text"
          readOnly
          value={
            link && link._id !== undefined && link.resource === "project report"
              ? "https://app.userevaluation.com/public/" + link?._id
              : "Click Create Link to generate a link"
          }
          className="form-control me-2"
        />
        <Button
          variant="outline-primary"
          className="me-2"
          style={{ minWidth: 150 }}
          onClick={() => {
            shareReport(projectId);
          }}
        >
          {link && link._id !== undefined && link.resource === "project report"
            ? "Update Link"
            : "Create Link"}
        </Button>
        <Button
          variant="primary"
          style={{ minWidth: 80 }}
          onClick={() => {
            copyLink("https://app.userevaluation.com/public/" + link?._id);
          }}
        >
          Copy
        </Button>
      </div>
    </Modal>
  );
}

export default ShareReportModal;
