import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner, Row, Col } from "react-bootstrap";
import { Modal } from "@mantine/core";
import { Emoji } from "emoji-picker-react";
import { toastSuccess } from "../../../../../utils/toast";
import { modalActions } from "../../../../../store/slices/modalSlice";
import { regenerateInsight } from "../../../../../store/actions/projectActions";
import { loadProject } from "../../../../../store/actions/currentProjectActions";

function AddAIInsightModal({ selectedVideo, projectId }) {
  const { AIInsights } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const [insightGenerationLoading, setInsightGenerationLoading] =
    useState(false);
  const [insightLoadingStates, setInsightLoadingStates] = useState({});
  const handleAddAIInsight = async (insightType) => {
    setInsightLoadingStates((prevState) => ({
      ...prevState,
      [insightType]: true,
    }));
    setInsightGenerationLoading(true);

    let payload = {
      projectId: projectId,
      insightType,
      videoId: selectedVideo._id,
    };

    let { data, error } = await regenerateInsight(payload);

    if (data) {
      toastSuccess("AI Insight added successfully");
      await loadProject(projectId);
      handleClose();
      setInsightLoadingStates((prevState) => ({
        ...prevState,
        [insightType]: false,
      }));
      setInsightGenerationLoading(false);
    }

    if (error) {
      console.error(error);
      setInsightLoadingStates((prevState) => ({
        ...prevState,
        [insightType]: false,
      }));
      setInsightGenerationLoading(false);
    }
  };
  const handleClose = () => {
    dispatch(modalActions?.hideAIInsightsModal());
  };
  return (
    <Modal
      size="xl"
      opened={AIInsights}
      onClose={handleClose}
      title="AI Insights"
    >
      <p>
        Choose an AI template from the list below and get insights for that
        section.
      </p>
      <Row>
        {promptLibrary.map((prompt, index) => (
          <Col xs={12} sm={6} md={4} className="ai-insight-box-container" key={index}>
            <div className="ai-insight-box">
              <div className="ai-insight-box-emoji">
                <Emoji unified={prompt.emojiUnified} size="20" />
              </div>
              <p className="ai-insight-box-title">{prompt.title}</p>
              <p className="ai-insight-box-description">{prompt.description}</p>
              <Button
                variant="outline-primary"
                disabled={
                  selectedVideo.insights.some(
                    (insight) => insight.insightType === prompt.title
                  ) || insightGenerationLoading
                }
                onClick={() => handleAddAIInsight(prompt.title)}
              >
                {insightLoadingStates[prompt.title] ? (
                  <Spinner animation="border" variant="primary" size="sm" />
                ) : selectedVideo.insights.some(
                    (insight) => insight.insightType === prompt.title
                  ) ? (
                  "Already in use"
                ) : (
                  "Use this template"
                )}
              </Button>
            </div>
          </Col>
        ))}
      </Row>
    </Modal>
  );
}
const promptLibrary = [
  {
    title: "Opportunity Areas",
    emojiUnified: "1f4a1",
    description: "Understand what people are looking for",
  },
  {
    title: "Jobs To Be Done",
    emojiUnified: "1f4cb",
    description: "Quickly identify the jobs to be done",
  },
  {
    title: "Bug Reports",
    emojiUnified: "1f41b",
    description: "Identify bugs reported in the sessions",
  },
  {
    title: "Feature Requests",
    emojiUnified: "1f680",
    description: "Identify features requested in the sessions",
  },
  {
    title: "Opposing Views",
    emojiUnified: "1f4a2",
    description: "Understand the opposing views in the session",
  },
  {
    title: "Quotable Quotes",
    emojiUnified: "1f4ac",
    description: "Get a list of quotes from the session",
  },
  {
    title: "Topics",
    emojiUnified: "1f4c8",
    description: "Understand the topics discussed in the session",
  },
  {
    title: "Questions for Next Session",
    emojiUnified: "1f914",
    description: "This will tell you what questions you can ask next",
  },
  {
    title: "Key Insights",
    emojiUnified: "1f4d8",
    description: "Get a clear summary of the session",
  },
  {
    title: "Pain Points",
    emojiUnified: "1f62c",
    description: "Understand the pain points of session participants",
  },
  {
    title: "Delighters",
    emojiUnified: "1f389",
    description: "Understand what caught participants eyes",
  },
];
export default AddAIInsightModal;
