/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import style from "./edit.module.css";
import { Button, Form } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { pick } from "lodash";
import { updateParticipant } from "../../../../../store/actions/participantActions";
import dayjs from "dayjs";

export default function EditMoreDetails({ data, onClose }) {
  const pickedData = pick(data, [
    "languages",
    "skills",
    "industry",
    "gender",
    "birth_date",
    "salary",
    "interests",
    "personal_emails",
    "personal_numbers",
  ]);

  pickedData.salaryStart = pickedData.salary?.start;
  pickedData.salaryEnd = pickedData.salary?.end;

  const [validated, setValidated] = useState(false);
  const [moreDetails, setMoreDetails] = useState(
    pickedData || {
      languages: [],
      skills: [],
      industry: "",
      gender: "",
      birth_date: "",
      salaryStart: "",
      salaryEnd: "",
      interests: [],
      personal_emails: [],
      personal_numbers: [],
    }
  );

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    setMoreDetails((prevMoreDetails) => {
      const updatedMoreDetails = structuredClone(prevMoreDetails);

      if (name === "personal_emails" || name === "personal_numbers") {
        updatedMoreDetails[name][index] = value;
      } else if (Array.isArray(updatedMoreDetails[name])) {
        updatedMoreDetails[name] = value
          .split(",")
          .map((entry) => entry.trim());
      } else {
        updatedMoreDetails[name] = value;
      }
      return updatedMoreDetails;
    });
  };

  const handleAddEntry = (name) => {
    setMoreDetails((prevMoreDetails) => ({
      ...prevMoreDetails,
      [name]: [...prevMoreDetails[name], ""],
    }));
  };

  const handleRemoveEntry = (name, index) => {
    if (moreDetails[name].length === 1 || index === 0) return;

    setMoreDetails((prevMoreDetails) => ({
      ...prevMoreDetails,
      [name]: prevMoreDetails[name].filter((_, i) => i !== index),
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      const moreDetailsCopy = structuredClone(moreDetails);
      moreDetailsCopy.salary = {
        start: moreDetailsCopy.salaryStart,
        end: moreDetailsCopy.salaryEnd,
      };
      moreDetailsCopy.languages = moreDetailsCopy.languages.filter(
        (language) => language !== ""
      );
      moreDetailsCopy.skills = moreDetailsCopy.skills.filter(
        (skill) => skill !== ""
      );
      moreDetailsCopy.interests = moreDetailsCopy.interests.filter(
        (interest) => interest !== ""
      );
      moreDetailsCopy.industry = moreDetailsCopy.industry?.trim();

      delete moreDetailsCopy.salaryStart;
      delete moreDetailsCopy.salaryEnd;
      const { error } = await updateParticipant("etc", moreDetailsCopy);
      if (!error) onClose();
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSave}>
      {/* Languages */}
      <Form.Group controlId="formLanguages" className="mb-2">
        <Form.Label className={style.formLabel}>Languages</Form.Label>
        <Form.Control
          type="text"
          placeholder="You can add multiple entries by using commas"
          name="languages"
          value={moreDetails.languages.join(", ")}
          onChange={handleInputChange}
        />
      </Form.Group>

      {/* Skills */}
      <Form.Group controlId="formSkills" className="mb-2">
        <Form.Label className={style.formLabel}>Skills</Form.Label>
        <Form.Control
          type="text"
          placeholder="You can add multiple entries by using commas"
          name="skills"
          value={moreDetails.skills.join(", ")}
          onChange={handleInputChange}
        />
      </Form.Group>

      {/* Industry */}
      <Form.Group controlId="formIndustry" className="mb-2">
        <Form.Label className={style.formLabel}>Industry</Form.Label>
        <Form.Control
          type="text"
          placeholder="Choose an industry"
          name="industry"
          value={moreDetails.industry}
          onChange={(e) => handleInputChange(e)}
        />
      </Form.Group>

      {/* Gender */}
      <Form.Group controlId="formGender" className="mb-2">
        <Form.Label className={style.formLabel}>Gender</Form.Label>
        <Form.Control
          as="select"
          name="gender"
          value={moreDetails.gender}
          onChange={(e) => handleInputChange(e)}
        >
          <option value="">Select Gender</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Other">Other</option>
        </Form.Control>
      </Form.Group>

      {/* Birthdate */}
      <Form.Group controlId="formBirthdate" className="mb-2">
        <Form.Label className={style.formLabel}>Birthdate</Form.Label>
        <Form.Control
          type="date"
          placeholder="Choose a date"
          name="birth_date"
          value={dayjs(moreDetails.birth_date).format("YYYY-MM-DD")}
          onChange={(e) => handleInputChange(e)}
        />
      </Form.Group>

      {/* Salary Range */}
      <Form.Group controlId="formSalaryRange" className="mb-2">
        <Form.Label className={style.formLabel}>Salary Range</Form.Label>
        <div className={style.formInput}>
          <Form.Control
            type="number"
            placeholder="Start"
            name="salaryStart"
            value={moreDetails.salaryStart}
            onChange={(e) => handleInputChange(e)}
          />
          <Form.Control
            type="number"
            placeholder="End"
            name="salaryEnd"
            value={moreDetails.salaryEnd}
            onChange={(e) => handleInputChange(e)}
          />
        </div>
      </Form.Group>

      {/* Interests */}
      <Form.Group controlId="formInterests" className="mb-2">
        <Form.Label className={style.formLabel}>Interests</Form.Label>
        <Form.Control
          type="text"
          placeholder="You can add multiple entries by using commas"
          name="interests"
          value={moreDetails.interests.join(", ")}
          onChange={handleInputChange}
        />
      </Form.Group>

      {/* Email */}
      <Form.Group controlId="formEmail" className="mb-2">
        <Form.Label className={style.formLabel}>
          <span>
            Email addresses{" "}
            <span className={style.muted}>(first one is primary)</span>
          </span>
        </Form.Label>
        <div className={style.formInput}>
          {moreDetails.personal_emails.map((email, index) => (
            <div key={index} className={style.emailInput}>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="personal_emails"
                value={email}
                onChange={(e) => handleInputChange(e, index)}
                required
              />
              <span
                className={style.deleteIcon}
                onClick={() => handleRemoveEntry("personal_emails", index)}
              >
                <MdDelete />
              </span>
            </div>
          ))}
        </div>
        <div
          className={style.addEntry + " mt-2"}
          onClick={() => {
            handleAddEntry("personal_emails");
          }}
        >
          + Add another email
        </div>
      </Form.Group>

      {/* Phone */}
      <Form.Group controlId="formPhone" className="mb-2">
        <Form.Label className={style.formLabel}>
          <span>
            Phone numbers{" "}
            <span className={style.muted}>(first one is primary)</span>
          </span>
        </Form.Label>
        <div className={style.formInput}>
          {moreDetails.personal_numbers.length === 0 && (
            <div className={style.phoneInput}>
              <Form.Control
                type="tel"
                placeholder="Enter phone number"
                name="personal_numbers"
                value={moreDetails.personal_numbers[0]}
                onChange={(e) => handleInputChange(e, 0)}
                required
              />
              <span className={style.deleteIcon}>
                <MdDelete />
              </span>
            </div>
          )}
          {moreDetails.personal_numbers.map((phone, index) => (
            <div key={index} className={style.phoneInput}>
              <Form.Control
                type="tel"
                placeholder="Enter phone number"
                name="personal_numbers"
                value={phone}
                onChange={(e) => handleInputChange(e, index)}
                required
              />
              <span
                className={style.deleteIcon}
                onClick={() => handleRemoveEntry("personal_numbers", index)}
              >
                <MdDelete />
              </span>
            </div>
          ))}
        </div>
        <div
          className={style.addEntry + " mt-2"}
          onClick={() => {
            handleAddEntry("personal_numbers");
          }}
        >
          + Add another phone
        </div>
      </Form.Group>

      <Form.Group
        controlId="formButtons"
        className="d-flex justify-content-between mt-3"
      >
        <Button
          variant="secondary"
          className={style.closeButton}
          onClick={onClose}
        >
          Close
        </Button>
        <Button type="submit" variant="primary" className={style.saveButton}>
          Save
        </Button>
      </Form.Group>
    </Form>
  );
}
