/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import style from "./edit.module.css";
import { Button, Form } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { updateParticipant } from "../../../../../store/actions/participantActions";
import dayjs from "dayjs";

export default function EditCertifications({ data, onClose }) {
  const [validated, setValidated] = useState(false);
  const [certifications, setCertifications] = useState(() => {
    return data?.length > 0
      ? data
      : [
          {
            name: "",
            authority: "",
            issue_date: "",
          },
        ];
  });

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setCertifications((prevCertifications) => {
      const updatedCertifications = [...prevCertifications];
      updatedCertifications[index] = {
        ...updatedCertifications[index],
        [name]: value,
      };
      return updatedCertifications;
    });
  };

  const handleAddCertification = () => {
    setCertifications((prevCertifications) => [
      ...prevCertifications,
      {
        name: "",
        authority: "",
        issue_date: "",
      },
    ]);
  };

  const handleRemoveCertification = (index) => {
    if (certifications.length === 1) return;
    setCertifications((prevCertifications) => {
      const updatedCertifications = [...prevCertifications];
      updatedCertifications.splice(index, 1);
      return updatedCertifications;
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      const { error } = await updateParticipant(
        "certification",
        certifications
      );
      if (!error) onClose();
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSave}>
      {certifications.map((certification, index) => (
        <Form.Group
          controlId={`formCertification${index}`}
          className="mb-2"
          key={index}
        >
          <Form.Label className={style.formLabel}>
            <span>
              License or Certification {index + 1} {index === 0 && " (Latest)"}
            </span>
            <span
              className={style.deleteIcon}
              onClick={() => handleRemoveCertification(index)}
            >
              <MdDelete />
            </span>
          </Form.Label>
          <div className={style.formInput}>
            <Form.Control
              type="text"
              placeholder="Name"
              name="name"
              value={certification.name}
              onChange={(e) => handleInputChange(e, index)}
              required
            />
            <Form.Control
              type="text"
              placeholder="Issuing Authority"
              name="authority"
              value={certification.authority}
              onChange={(e) => handleInputChange(e, index)}
              required
            />
            <Form.Control
              type="date"
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
              placeholder="Issue date"
              name="issue_date"
              value={dayjs(certification.issue_date).format("YYYY-MM-DD")}
              onChange={(e) => handleInputChange(e, index)}
              required
            />
          </div>
        </Form.Group>
      ))}
      <div className={style.addEntry} onClick={handleAddCertification}>
        + Add another entry
      </div>

      <Form.Group
        controlId="formButtons"
        className="d-flex justify-content-between mt-3"
      >
        <Button
          variant="secondary"
          className={style.closeButton}
          onClick={onClose}
        >
          Close
        </Button>
        <Button type="submit" variant="primary" className={style.saveButton}>
          Save
        </Button>
      </Form.Group>
    </Form>
  );
}
