import React, { useState } from "react"; //, { useState }
import { Card, Image, Text, Group, Menu, ActionIcon } from "@mantine/core";
import moreIcon from "../../../images/icons/more-horizontal-white.svg";
import moreIconLightMode from "../../../images/icons/more-horizontal.svg";
import truncateString from "../../../utils/truncateString";
import uniq from "lodash/uniq";
import { useHistory } from "react-router-dom";
import { LuFileVideo, LuFileAudio } from "react-icons/lu";
import { FiFileText, FiLayout } from "react-icons/fi";
import { deleteProjectAction } from "../../../store/actions/projectActions";
import style from "./projectcard.module.css";
import getTimeSince from "../../../utils/getTimeSince";
import { modalActions } from "../../../store/slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { supportedLanguages } from "../../project/components/topbar/languages.js";
import DeleteConfirmationModal from "./DeleteConfirmationModal.js";
function ProjectCard({ project, darkMode, image, disable }) {
  const [openedMenu, setOpenedMenu] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state.auth);
  const [freeDeletePaywall, setFreeDeletePaywall] = useState(false);
  const isFree = account?.plan?.type === "Free";
  // create now set of all assettype in the project
  let assetsType = uniq(
    project?.videos?.map((asset) => {
      return asset.assetType;
    })
  );
  const time = getTimeSince(project.createdAt)?.toLowerCase();
  const formattedTime = `Created ${time} `;
  // flag to only show the text icon once
  let flag = 0;

  return (
    <>
      <DeleteConfirmationModal
        show={freeDeletePaywall}
        setShow={setFreeDeletePaywall}
        projectId={project._id}
      />
      <Card
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        key={project._id}
        className={`${style.card}  ${disable ? style.disable : ""}`}
      >
        <Card.Section
          onClick={() => {
            if (disable) {
              dispatch(modalActions.showPayWallModal());
            } else {
              if (project?.status === "Empty") {
                history.push("/project/" + project._id + "/upload");
              } else {
                history.push("/project/" + project._id);
              }
            }
          }}
        >
          <Image src={image} height={160} alt="ue" />
          <div className={style.icons}>
            {assetsType?.map((assettype, index) => {
              if (assettype === "VIDEO") {
                return (
                  <div className={style.icon} key={index}>
                    <LuFileVideo color="white" />
                  </div>
                );
              }
              if (assettype === "CSV") {
                return (
                  <div className={style.icon} key={index}>
                    <FiLayout color="white" />
                  </div>
                );
              }
              if (assettype === "AUDIO") {
                return (
                  <div className={style.icon} key={index}>
                    <LuFileAudio color="white" />
                  </div>
                );
              }
              if (flag === 0) {
                flag++;
                return (
                  <div className={style.icon} key={index}>
                    <FiFileText color="white" />
                  </div>
                );
              }
            })}
          </div>
        </Card.Section>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* flexbox side by side */}

          {/* fill the space  */}
          <div
            style={{ flex: 1 }}
            onClick={() => {
              if (disable) {
                dispatch(modalActions.showPayWallModal());
              } else {
                if (project?.status === "Empty") {
                  history.push("/project/" + project._id + "/upload");
                } else {
                  history.push("/project/" + project._id);
                }
              }
            }}
          >
            <Group justify="space-between" mt="md" mb="xs">
              <Text fw={500}>{truncateString(project.name, 18)}</Text>
            </Group>
            {/* make text sentence case */}
            <Text size="sm" c="dimmed" style={{ textTransform: "none" }}>
              {formattedTime}
            </Text>

            {project.status === "Preparing" ||
            project.status === "Analyzing" ? (
              <div className="card-meta">
                {project.status === "Preparing" && <>Processing...</>}
                {project.status === "Analyzing" && <>Analyzing...</>}
              </div>
            ) : (
              <div className={style.cardMeta}>
                <div>
                  {project.language?.toLocaleLowerCase() === "detect" ? (
                    `🌐 `
                  ) : (
                    <span
                      className={`fi fi-${
                        supportedLanguages
                          ?.find(
                            (lang) =>
                              lang.code.toLocaleLowerCase() ===
                              project.language?.toLocaleLowerCase()
                          )
                          ?.country_code?.toLowerCase() || "globe"
                      }`}
                    ></span>
                  )}

                  {(() => {
                    const foundLanguage = supportedLanguages?.find(
                      (lan) =>
                        lan.code.toLocaleLowerCase() ===
                        project.language?.toLocaleLowerCase()
                    );

                    if (!foundLanguage) return "Auto-detect";

                    return foundLanguage.language === "American English" ||
                      foundLanguage.language === "British English"
                      ? "English"
                      : foundLanguage.language;
                  })()}
                </div>
                <div>
                  <FiFileText className={style.fileIcon} />
                  {project?.videos.length}
                </div>
              </div>
            )}
          </div>

          {/* hug contents */}

          <div style={{ flex: 0, padding: 16 }}>
            <Menu
              withinPortal
              position="bottom-end"
              shadow="sm"
              opened={openedMenu}
            >
              <Menu.Target onClick={() => setOpenedMenu(!openedMenu)}>
                <ActionIcon variant="subtle" color="gray">
                  <img
                    alt="more"
                    src={darkMode ? moreIcon : moreIconLightMode}
                    style={{
                      transform: "rotate(90deg)",
                      padding: "0px",
                    }}
                  />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  onClick={() => {
                    setOpenedMenu(false);
                    if (disable) {
                      dispatch(modalActions.showPayWallModal());
                    } else {
                      if (project?.status === "Empty") {
                        history.push("/project/" + project._id + "/upload");
                      } else {
                        history.push("/project/" + project._id);
                      }
                    }
                  }}
                >
                  View Project
                </Menu.Item>
                <Menu.Item
                  color="red"
                  onClick={(event) => {
                    event.stopPropagation(); // Prevent the click from propagating and affecting other elements
                    if (deleteConfirmation[project._id]) {
                      setOpenedMenu(false); // Close only if deletion is confirmed
                      deleteProjectAction(project._id);
                    } else {
                      if (isFree) {
                        setFreeDeletePaywall(true);
                        setOpenedMenu(false);
                      } else {
                        setDeleteConfirmation({
                          ...deleteConfirmation,
                          [project._id]: true,
                        });
                        setOpenedMenu(true); // Ensure menu stays open if not yet confirmed
                      }
                    }
                  }}
                >
                  {deleteConfirmation[project._id] ? (
                    <span style={{ color: "#ff7878" }}>Sure?</span>
                  ) : (
                    <span>Delete</span>
                  )}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        </div>
      </Card>
    </>
  );
}

export default ProjectCard;
