export const mapColortoSpeaker = (speaker, darkMode) => {
  let colors = [];
  if (darkMode) {
    colors = [
      "#004080",
      "#864B86",
      "#403D75",
      "#21662B",
      "#005859",
      "#C74320",
      "#724747",
      "#C0463A",
      "#C06060",
      "#466B47",
      "#FF808B",
      "#853333",
      "#B3A566",
      "#404000",
      "#335A54",
      "#59808E",
      "#559E18",
      "#B3A000",
      "#B33973",
      "#86634B",
      "#105958",
      "#C03221",
      "#216633",
      "#3FCFAF",
      "#C02600",
      "#007D4D",
    ];
  } else {
    colors = [
      "#0085FF",
      "#DDA0DD",
      "#7B68EE",
      "#32CD32",
      "#00CED1",
      "#FF7F50",
      "#BC8F8F",
      "#FA8072",
      "#F08080",
      "#8FBC8F",
      "#FFC0CB",
      "#CD5C5C",
      "#F0E68C",
      "#808000",
      "#66CDAA",
      "#B0E0E6",
      "#ADFF2F",
      "#FFD700",
      "#FF69B4",
      "#D2B48C",
      "#20B2AA",
      "#FF6347",
      "#3CB371",
      "#7FFFD4",
      "#FF4500",
      "#00FA9A",
    ];
  }
  return colors[(speaker.charCodeAt(0) - 65) % colors.length];
};


