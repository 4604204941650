import React, { useState } from "react";
import style from "./edit-profile-buttons.module.css";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { Text, Modal } from "@mantine/core";
import { Button } from "react-bootstrap";
import { FaLinkedin } from "react-icons/fa";
import EditBio from "./components/edit-bio";
import EditLinkedin from "./components/edit-linkedin";
import { useSelector } from "react-redux";

export default function EditProfileButtons() {
  const { isAdmin } = useSelector((state) => state.auth);
  const [opened, { open, close }] = useDisclosure(false);
  const [clickedButton, setClickedButton] = useState("bio");
  const isMobile = useMediaQuery("(max-width: 768px)");

  if (isAdmin) {
    return <div className={style.profileEdit}></div>;
  }
  return (
    <div className={style.profileEdit}>
      <Button
        variant="outline-primary"
        onClick={() => {
          setClickedButton("bio");
          open();
        }}
      >
        Edit Profile
      </Button>
      <Button
        variant="outline-primary"
        onClick={() => {
          setClickedButton("linkedin");
          open();
        }}
      >
        <span>
          <FaLinkedin className={style.linkedinIcon} />
        </span>
        &nbsp;&nbsp;Import from LinkedIn
      </Button>
      <Modal
        opened={opened}
        onClose={close}
        title={
          <Text weight={600}>
            {clickedButton === "bio" ? "Profile" : "Import from Linkedin"}
          </Text>
        }
        size={isMobile ? "xs" : "md"}
        centered
      >
        {clickedButton === "bio" && <EditBio onClose={close} />}
        {clickedButton === "linkedin" && <EditLinkedin onClose={close} />}
      </Modal>
    </div>
  );
}
