import React from 'react'
import { Button } from 'react-bootstrap'
import { FiEye } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { highlightActions } from '../../../../../store/slices/highlightSlice'
function HighlightIcon({ index, normalTime ,setSelectedInsightTab}) {
    const dispatch = useDispatch()
    let { highlights } = useSelector(state => state.highlight)
    let flag = false;
    highlights.forEach((ele, index) => {
        if (ele.start === normalTime.start && ele.end === normalTime.end) {
            flag = true;
        }
    })

    return (
       
            <Button
                variant="transparent"
                size="sm"
                onClick={() => {
                    dispatch(highlightActions.setHighlights(normalTime))
                    if(!flag){
                        setSelectedInsightTab("Transcript");
                    }
                }
                }
            >
                    <FiEye className="project-insight-icons" />
                    Highlight Context
            </Button>
    )
}

export default HighlightIcon