import React, { useRef, useState, useCallback, useEffect } from "react";
import { Tooltip, OverlayTrigger, Dropdown } from "react-bootstrap";
import styles from "./transcriptPrinter.module.css";
import { FiChevronDown, FiX } from "react-icons/fi";
import { deleteTag } from "../../../../store/actions/tagsActions";
import { FiPlayCircle, FiPlusCircle } from "react-icons/fi";
import {
  deleteInsight,
  updateInsight,
} from "../../../../store/actions/projectActions";
import { RiDeleteBinLine } from "react-icons/ri";
const typeColors = {
  "Pain Points": {
    normal: "#E54C23", // 🥬 Adjusted Normal Shade
    lighter: "#FFDED9", // 🥬 Adjusted Lighter Shade
    lighterLighter: "#FFF3E8", // 🥬 Adjusted Lighter Lighter Shade
  },
  "Bug Reports": {
    normal: "#CC0000", // 🐛 Adjusted Normal Shade
    lighter: "#FFCCCC", // 🐛 Adjusted Lighter Shade
    lighterLighter: "#FFE5E5", // 🐛 Adjusted Lighter Lighter Shade
  },
  "Feature Requests": {
    normal: "#336699", // 🚀 Adjusted Normal Shade
    lighter: "#99CCFF", // 🚀 Adjusted Lighter Shade
    lighterLighter: "#E6F0FF", // 🚀 Adjusted Lighter Lighter Shade
  },
  Delighters: {
    normal: "#3A8F40", // 💡 Adjusted Normal Shade
    lighter: "#D8FAD6", // 💡 Adjusted Lighter Shade
    lighterLighter: "#EBFFEC", // 💡 Adjusted Lighter Lighter Shade
  },
  "Key Insights": {
    normal: "#2F689A", // 📘 Adjusted Normal Shade
    lighter: "#C8D7EB", // 📘 Adjusted Lighter Shade
    lighterLighter: "#DDEBF7", // 📘 Adjusted Lighter Lighter Shade
  },
  "Questions for Next Session": {
    normal: "#791BD6", // 🤔 Adjusted Normal Shade
    lighter: "#EDEDFC", // 🤔 Adjusted Lighter Shade
    lighterLighter: "#F5F5FD", // 🤔 Adjusted Lighter Lighter Shade
  },
  "Jobs To Be Done": {
    normal: "#E5C600", // 🎉 Adjusted Normal Shade
    lighter: "#FFFBE6", // 🎉 Adjusted Lighter Shade
    lighterLighter: "#FFFEF5", // 🎉 Adjusted Lighter Lighter Shade
  },
  "Quotable Quotes": {
    normal: "#E55237", // 📌 Adjusted Normal Shade
    lighter: "#FFE0E0", // 📌 Adjusted Lighter Shade
    lighterLighter: "#FFF2F2", // 📌 Adjusted Lighter Lighter Shade
  },
  Topics: {
    normal: "#E59400", // 📈 Adjusted Normal Shade
    lighter: "#FFF5D7", // 📈 Adjusted Lighter Shade
    lighterLighter: "#FFFDEB", // 📈 Adjusted Lighter Lighter Shade
  },
  "Opportunity Areas": {
    normal: "#5949B2", // 🌐 Adjusted Normal Shade
    lighter: "#DAC4FF", // 🌐 Adjusted Lighter Shade
    lighterLighter: "#E6DFFF", // 🌐 Adjusted Lighter Lighter Shade
  },
  "Opposing Views": {
    normal: "#E53900", // 🔄 Adjusted Normal Shade
    lighter: "#FFA8A8", // 🔄 Adjusted Lighter Shade
    lighterLighter: "#FFC5C5", // 🔄 Adjusted Lighter Lighter Shade
  },
  "Session Summary": {
    normal: "#0B78E6", // 📝 Adjusted Normal Shade
    lighter: "#A4D4FA", // 📝 Adjusted Lighter Shade
    lighterLighter: "#CAE8F4", // 📝 Adjusted Lighter Lighter Shade
  },
};

function TranscriptUtterance({
  sentence,
  index,
  currentWord,
  timeStamps,
  tags,
  showTag,
  showInsight,
  handleSeek,
  collectInsight,
  asset,
  parentRef,
  isPublic,
  showSentiment,
  assetSentiment,
}) {
  const insights = asset?.insights;
  const [sentenceState, setSentenceState] = useState(sentence);
  const insightsToShow = insights
    .map((insight) => {
      let newinsights = [];
      newinsights = insight.insights.filter((ins) => ins.index === index);
      newinsights = newinsights.map((ins) => {
        return { ...ins, insightType: insight.insightType };
      });
      return newinsights;
    })
    .flat()
    .sort((a, b) => a.start - b.start);
  const selectedHighlightRef = useRef(null);
  const tagTextRef = useRef({});
  const insightTextRef = useRef({});
  const [hoverIndex, setHoverIndex] = useState("");
  const [hoverIndexInsight, setHoverIndexInsight] = useState("");
  const handleMouseEnter = (id) => {
    setHoverIndex(id);
  };
  let filteredTags = useCallback(
    tags?.filter((tag) => tag.utteranceNo === index),
    [tags, index]
  );
  const handleMouseEnterInsight = (id) => {
    setHoverIndexInsight(id);
  };
  const handleMouseLeaveInsight = () => {
    setHoverIndexInsight("");
  };
  const handleMouseLeave = (id) => {
    setHoverIndex("");
  };

  useEffect(() => {
    // update the sentence
    if (showSentiment && assetSentiment) {
      setSentenceState((prev) => {
        let newWords = prev.words.map((word) => {
          let newWord = { ...word };
          let sentiments = assetSentiment?.data;
          let positives = sentiments?.Positive;
          let negatives = sentiments?.Negative;

          let flag = false;
          for (let i = 0; i < positives.length; i++) {
            if (
              word.start >= positives[i].start &&
              word.end <= positives[i].end
            ) {
              flag = true;
              newWord.sentiment = "Positive";
            }
          }
          if(!flag){
            for (let i = 0; i < negatives.length; i++) {
              if (
                negatives[i].start <= word.start &&
                negatives[i].end >= word.end
              ) {
                newWord.sentiment = "Negative";
                flag = true;
              }
            }
          }
         
          if (!flag) {
            newWord.sentiment = "Neutral";
          }

          return newWord;
        });
        return { ...prev, words: newWords };
      });
    }
  }, [showSentiment, assetSentiment]);

  useEffect(() => {
    selectedHighlightRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [selectedHighlightRef.current]);

  return (
    <>
      <div style={{ width: "70%" }} ref={parentRef}>
        {sentenceState?.words.map((word, i) => {
          let sentiment = word.sentiment;
          let flag = false;
          let key = 0;
          let select = false;
          let color = "";
          for (let i = 0; i < timeStamps.length; i++) {
            if (
              timeStamps[i].start <= word.start &&
              timeStamps[i].end >= word.end
            ) {
              flag = true;
            }
          }
          if (showTag && !showInsight) {
            for (let i = 0; i < tags?.length; i++) {
              if (
                tags[i] &&
                tags[i].start <= word.start &&
                tags[i].end >= word.end
              ) {
                select = true;
                color = tags[i]?.tagId?.highlightColor;
                if (color && hoverIndex === tags[i]?._id) {
                  color = color
                    ?.substring(5, color.length - 1)
                    .split(",")
                    .map(parseFloat);
                  color[3] = 0.5;
                  color = `rgba(${color?.join(",")})`;
                }
              }
            }
          }
          if (!showTag && showInsight) {
            for (let i = 0; i < insightsToShow?.length; i++) {
              if (
                insightsToShow &&
                insightsToShow[i].start <= word.start &&
                insightsToShow[i].end >= word.end
              ) {
                select = true;
                color =
                  typeColors[insightsToShow[i]?.insightType].lighterLighter;
                if (color && hoverIndexInsight === insightsToShow[i]?._id) {
                  color = typeColors[insightsToShow[i]?.insightType].lighter;
                }
              }
            }
          }
          const isCurrentWord =
            currentWord >= word.start && currentWord <= word.end;

          return (
            <span
              ref={(el) => {
                if (flag && key === 0) {
                  key = 1;
                  return (selectedHighlightRef.current = el);
                }
                if (showTag) return (tagTextRef.current[word?.start] = el);
                if (showInsight)
                  return (insightTextRef.current[word?.start] = el);
              }}
              key={word.start + 1}
              style={{
                background: showTag || showInsight ? color : sentiment==="Positive"?"#d5f2c4":sentiment==="Negative"?"#ffd9d9":"transparent",
              }}
              className={`${select ? styles.select : ""}  ${
                flag ? styles.highlightWord : ""
              }
              
                ${
                  sentiment === "Positive"
                    ? styles.positive
                    : sentiment === "Negative"
                    ? styles.negative
                    : ""
                }
              
              `}
            >
              <span
                // eslint-disable-next-line react/no-unknown-property
                word_index={i}
                value={word.i}
                data={JSON.stringify(index)}
                className={isCurrentWord ? "transcript-highlight" : ""}
              >
                {word.text}
              </span>
              {word !== sentence.words[sentence.words.length - 1] && " "}
            </span>
          );
        })}
      </div>
      <div className={styles.tagsContainer}>
        {showTag &&
          !showInsight &&
          filteredTags.map((tag, i) => {
            return (
              <OverlayTrigger
                key={i}
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-${i}`}>
                    Appears {tag?.frequency || 0}{" "}
                    {tag?.frequency === 1 ? "time" : "times"}
                  </Tooltip>
                }
              >
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    tagTextRef.current[tag?.start]?.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }}
                  onMouseEnter={() => handleMouseEnter(tag?._id)}
                  onMouseLeave={() => handleMouseLeave(tag?._id)}
                  className={styles.tag}
                  style={{
                    background: tag?.tagId?.color,
                    padding: "2px 10px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  {tag?.tagId?.title}{" "}
                  <span style={{ opacity: 0.5, marginLeft: "5px" }}>
                    {tag?.frequency > 0 ? `${tag?.frequency}` : ""}
                  </span>
                  {!isPublic && (
                    <FiX
                      style={{ marginLeft: "5px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteTag(tag?._id);
                      }}
                    />
                  )}
                </span>
              </OverlayTrigger>
            );
          })}
        {showInsight && !showTag && (
          <>
            {insightsToShow?.map((insight, i) => {
              return (
                <KeyInsightBox
                  asset={asset}
                  data={insight}
                  key={i}
                  handleSeek={handleSeek}
                  collectInsight={collectInsight}
                  handleMouseEnter={handleMouseEnterInsight}
                  handleMouseLeave={handleMouseLeaveInsight}
                  insightTextRef={insightTextRef}
                />
              );
            })}
          </>
        )}
      </div>
    </>
  );
}

function KeyInsightBox({
  asset,
  data,
  handleSeek,
  collectInsight,
  handleMouseEnter,
  handleMouseLeave,
  insightTextRef,
}) {
  const [show, setShow] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);
  if (!data) return null;
  const otherTypes = asset?.insights
    ?.map((insight) => insight.insightType)
    .filter((type) => type !== data?.insightType);

  Object.keys(typeColors).filter((type) => type !== data?.insightType);
  const truncatedText =
    data?.insightText.length >= 200
      ? data?.insightText.slice(0, 200) + "..."
      : data?.insightText;
  return (
    <div
      onClick={() => {
        insightTextRef.current[data?.start]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }}
      className={styles.insightBox}
      onMouseEnter={() => handleMouseEnter(data?._id)}
      onMouseLeave={() => handleMouseLeave()}
    >
      <div className={styles.headContainer}>
        <div
          className={styles.insightBoxHeader}
          style={{
            color: typeColors[data?.insightType].normal,
            background: typeColors[data?.insightType].lighter,
          }}
        >
          <div className={styles.insightBoxTitle}>
            <span>{data?.insightType}</span>
            <Dropdown className={styles.dropDownInsights}>
              <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                <FiChevronDown
                  color={typeColors[data?.insightType].normal}
                  size={"1rem"}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {otherTypes.map((type, i) => {
                  return (
                    <Dropdown.Item
                      key={i}
                      style={{ fontSize: "0.8rem" }}
                      onClick={() =>
                        updateInsight(asset._id, data?._id, data, type)
                      }
                    >
                      {type}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className={styles.insightBoxContent}>
        <p className={styles.insightBoxText}>
          {show ? data?.insightText : truncatedText}
          {!show && data?.insightText.length >= 201 && (
            <span
              className={styles.readMore}
              onClick={(e) => {
                e.stopPropagation();
                setShow(!show);
              }}
            >
              Show more
            </span>
          )}
        </p>
      </div>
      <div className={styles.functionIcons}>
        <span
          onClick={() => {
            collectInsight(data, data);
          }}
        >
          <FiPlusCircle />
          Collect
        </span>
        <span
          onClick={(e) => {
            e.stopPropagation();
            handleSeek(data.start);
          }}
        >
          <FiPlayCircle />
          Play
        </span>
        <span
          onClick={(e) => {
            e.stopPropagation();
            deleteInsight(data?._id, asset?._id, data?.insightType);
          }}
        >
          <RiDeleteBinLine style={{ color: "red" }} />
          Delete
        </span>
      </div>
    </div>
  );
}

export default TranscriptUtterance;
