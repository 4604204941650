import store from "..";
import { tagsActions } from "../slices/tagsSlice";
import { callExternalApi } from "../../services/external-api.service";
import { toastError } from "../../utils/toast";
import { toast } from "react-toastify";
const { dispatch } = store;
const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

// get all tags from project
export const getTagsByProjectId = async (projectId) => {
  dispatch(tagsActions.requestStart());
  const config = {
    url: `${apiServerUrl}/api/tags/${projectId}`,
    method: "GET",
  };

  const { data, error } = await callExternalApi({ config });
  if (error) dispatch(tagsActions.requestFail(error.message));
  else dispatch(tagsActions.getTags({ tags: data }));
};

export const deleteTagAction = (tagId) => {
  return async (dispatch) => {
    const config = {
      url: `${apiServerUrl}/api/tags/tag/${tagId}`,
      method: "DELETE",
    };
    const { error } = await callExternalApi({ config });
    if (error) {
      dispatch(tagsActions.requestFail(error.message));
      toastError(
        "Something went wrong! Please check your credits or try again later."
      );
    } else {
      dispatch(tagsActions.deleteEntireTag(tagId));
    }
  };
};
// get all tag names from project
export const getTagNames = async (projectId) => {
  const config = {
    url: `${apiServerUrl}/api/tags/names/${projectId}`,
    method: "GET",
  };

  const { data, error } = await callExternalApi({ config });
  if (error) dispatch(tagsActions.requestFail(error.message));
  else return data;
};
// create tag action
export const createTagAction = async (
  title,
  projectId,
  assetId,
  start,
  end,
  utteranceNo,
  text,
  color,
  highlightColor
) => {
  dispatch(tagsActions.requestStart());

  const config = {
    url: `${apiServerUrl}/api/tags/${projectId}`,
    method: "POST",
    data: {
      title,
      projectId,
      assetId,
      start,
      end,
      utteranceNo,
      text,
      color,
      highlightColor,
    },
  };
  const { data, error } = await callExternalApi({ config });

  if (error) {
    dispatch(tagsActions.requestFail(error.message));
    toastError(
      "Something went wrong! Please check your credits or try again later."
    );
  } else {
    toast.success("Tag created successfully ");
    dispatch(tagsActions.addTag(data));
  }
};
export const moveTagCard = (payload) => {
  return async (dispatch) => {
    const { cardId, targetLaneId, sourceLaneId } = payload;
    const config = {
      url: `${apiServerUrl}/api/tags/move`,
      method: "PUT",
      data: {
        tagId: cardId,
        targetTagId: targetLaneId,
        sourceLaneId: sourceLaneId,
      },
    };
    const { data, error } = await callExternalApi({ config });
    if (error) {
      dispatch(tagsActions.requestFail(error.message));
      toastError(
        "Something went wrong! Please check your credits or try again later."
      );
    } else {
      dispatch(tagsActions.moveTagCard({ cardId, data }));
      return {...data,success:true};
    }
  };
};

export const deleteTag = async (tagId) => {
  const config = {
    url: `${apiServerUrl}/api/tags/${tagId}`,
    method: "DELETE",
  };
  const { error } = await callExternalApi({ config });
  if (error) {
    dispatch(tagsActions.requestFail(error.message));
    toastError(
      "Something went wrong! Please check your credits or try again later."
    );
  } else {
    dispatch(tagsActions.deleteTag(tagId));
  }
};
export const deleteTags = async (tagIds) => {
  const config = {
    url: `${apiServerUrl}/api/tags`,
    method: "DELETE",
    data: { tagIds },
  };
  const { error } = await callExternalApi({ config });
  if (error) {
    dispatch(tagsActions.requestFail(error.message));
    toastError(
      "Something went wrong! Please check your credits or try again later."
    );
  } else {
    dispatch(tagsActions.deleteTagIds(tagIds));
  }
};

export const updateTagTitleAction = (payload) => {
  return async (dispatch) => {
    const { tagId, updatedFields } = payload;
    const config = {
      url: `${apiServerUrl}/api/tags/title/${tagId}`,
      method: "PUT",
      data: {
        title: updatedFields.title,
      },
    };
    const { data, error } = await callExternalApi({ config });

    if (error) {
      toastError(error.message);
    }
    if (data) {
      dispatch(
        tagsActions.patchTagName({ tagId, title: updatedFields?.title })
      );
    }
  };
};
