/* eslint-disable no-unused-vars */
import React from "react";
import style from "./index.module.css";
import { PageLayout } from "../../../components/page-layout";
import CoverImage from "../components/cover-image";
import TopBar from "../components/topbar";
import {
  MdExpandLess,
  MdExpandMore,
  MdModeEditOutline,
  MdOutlineContentCopy,
} from "react-icons/md";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { FaRegFileAlt } from "react-icons/fa";
import {
  changeDeadline,
  changeTitle,
} from "../../../store/actions/testActions";

export const TEST_TYPES = {
  "ai-curated-interview": "AI-Curated Interview",
  "ai-curated-survey": "AI-Curated Survey",
  "ai-generated-survey": "AI-Generated Survey",
};

export const TestDetails = ({ match }) => {
  const { currentTest } = useSelector((state) => state.test);
  const [displayMore, setDisplayMore] = React.useState(-1);

  return (
    <PageLayout>
      <div className={style.parentWrapper}>
        <div className={style.wrapper}>
          <TopBar currentTab="Test Details" testId={match.params.id} />
          <div className={style.main}>
            <CoverImage
              projectDataId={currentTest._id || "some random value"}
            />
            <div className={style.content}>
              <div className={style.reviewSection}>
                <div className={style.review}>
                  <div className={style.reviewTitle}>Test Name</div>
                  <EditForm
                    name="title"
                    defaultValue={currentTest.title}
                    onSave={async (v) => await changeTitle(match.params.id, v)}
                  />
                </div>
                <div className={style.review}>
                  <div className={style.reviewTitle}>Type of test</div>
                  <div className={style.reviewValue}>
                    {TEST_TYPES[currentTest.type]}
                  </div>
                </div>
                <div className={style.review}>
                  <div className={style.reviewTitle}>Invite link</div>
                  <div className="d-flex flex-grow-1 gap-2">
                    <InputGroup>
                      <Form.Control
                        value={`${window.location.origin}/participant/welcome?tId=${match.params.id}`}
                        readOnly
                        className={style.shareLink}
                      />
                      <Button
                        variant="primary"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${window.location.origin}/participant/welcome?tId=${match.params.id}`
                          );
                        }}
                        className="d-flex align-items-center gap-1"
                      >
                        <MdOutlineContentCopy /> Copy
                      </Button>
                    </InputGroup>
                  </div>
                </div>
                {/* <div className={style.review}>
                  <div className={style.reviewTitle}>Audience</div>
                  <div className={style.reviewValue}>
                    <span className={style.bold}>User Evaluation Panel</span>
                    <li>United States</li>
                    <li>Ages 20-30</li>
                    <li>Female</li>
                  </div>
                  <MdModeEditOutline className={style.editIcon} />
                </div> */}
                <div className={style.review}>
                  <div className={style.reviewTitle}>Screener Survey</div>
                  <div className={style.reviewValue}>
                    <span className={style.bold}>
                      {currentTest.questions?.length || 0} questions&nbsp;
                      {!currentTest.questions?.length && "(skipped)"}
                    </span>
                    {currentTest.questions?.length > 0 && (
                      <ul className="p-0 m-0">
                        {currentTest.questions?.map((question, i) => {
                          return (
                            <li key={question._id}>
                              <p
                                className={style.label}
                                onClick={() => {
                                  if (displayMore === i) {
                                    setDisplayMore(-1);
                                  } else {
                                    setDisplayMore(i);
                                  }
                                }}
                              >
                                {question.label}
                                {displayMore === i ? (
                                  <MdExpandLess />
                                ) : (
                                  <MdExpandMore />
                                )}
                              </p>

                              {displayMore === i && (
                                <ul className={style.options}>
                                  {question.placeholder && (
                                    <div>{question.placeholder}</div>
                                  )}
                                  {question.selectionConditionAI && (
                                    <div>
                                      {question.selectionConditionAI}{" "}
                                      <span>(Qualify)</span>
                                    </div>
                                  )}
                                  {question.options.map((option, index) => (
                                    <li key={index}>
                                      {option.option}{" "}
                                      <span>
                                        {option.qualifyIfChosen
                                          ? "(Qualify)"
                                          : ""}
                                      </span>
                                    </li>
                                  ))}
                                  {/* <div>
                                  {question.type}
                                </div> */}
                                </ul>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                  {/* <MdModeEditOutline className={style.editIcon} /> */}
                </div>
                <div className={style.review}>
                  <div className={style.reviewTitle}>Participants</div>
                  <div className={style.reviewValue}>
                    <span className={style.bold}>
                      {currentTest.nParticipants} participants
                    </span>
                    {/* <li>${currentTest.reward} incentive per participant</li> */}
                  </div>
                  {/* <MdModeEditOutline className={style.editIcon} /> */}
                </div>
                {/* <div className={style.review}>
                  <div className={style.reviewTitle}>AI Interviewer</div>
                  <div className={style.reviewValue}>American, Female</div>
                  <MdModeEditOutline className={style.editIcon} />
                </div> */}
                <div className={style.review}>
                  <div className={style.reviewTitle}>
                    Documents for Signature
                  </div>
                  <div className={style.reviewValue}>
                    {currentTest.ndaLink ? (
                      <a
                        href={currentTest.ndaLink}
                        target="_blank"
                        rel="noreferrer"
                        className={style.link}
                      >
                        <FaRegFileAlt />
                        <span>View document</span>
                      </a>
                    ) : (
                      "-"
                    )}
                  </div>
                  {/* <MdModeEditOutline className={style.editIcon} /> */}
                </div>
                <div className={style.review}>
                  <div className={style.reviewTitle}>Deadline</div>

                  <EditForm
                    name="deadline"
                    type="date"
                    defaultValue={currentTest.deadline?.split("T")[0]}
                    onSave={async (v) =>
                      await changeDeadline(match.params.id, v)
                    }
                    inputParams={{
                      min: dayjs().format("YYYY-MM-DD"),
                    }}
                  />
                </div>
                <div className={style.review}>
                  <div className={style.reviewTitle}>Intro & Outro</div>
                  <div className={style.reviewValue}>
                    <span className={style.bold}>Intro</span>
                    <li>{currentTest.instructions}</li>
                    <span className={style.bold}>Outro</span>
                    <li>{currentTest.endNote}</li>
                  </div>
                  {/* <MdModeEditOutline className={style.editIcon} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

const EditForm = ({
  defaultValue,
  onSave,
  name,
  type = "text",
  inputParams = {},
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <div className={style.reviewValue}>
        {isEditing ? (
          <div className="d-flex flex-grow-1 gap-2">
            <InputGroup>
              <Form.Control
                name={name}
                type={type}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                {...inputParams}
              />

              <Button
                variant="primary"
                disabled={loading}
                onClick={() => {
                  setLoading(true);
                  onSave(value).then(() => {
                    setLoading(false);
                    setIsEditing(false);
                  });
                }}
                className="d-flex align-items-center gap-1"
              >
                {loading && <Spinner animation="border" size="sm" />}
                Save
              </Button>
            </InputGroup>
          </div>
        ) : type === "date" ? (
          dayjs(defaultValue).format("MMM DD YYYY")
        ) : (
          defaultValue
        )}
      </div>
      {!isEditing && (
        <MdModeEditOutline
          className={style.editIcon}
          onClick={() => setIsEditing(true)}
        />
      )}
    </>
  );
};
