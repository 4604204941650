import React, { useState } from "react";
import styles from "./index.module.css";
import { FiEdit2, FiMoreHorizontal } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import EditVideoTitleModal from "../../../project-vnext/components/tabs/insights/EditVideoTitleModal";
import RemoveFileModal from "./removeFileModal";
import { useDispatch, useSelector } from "react-redux";
import { currentProjectActions } from "../../../../store/slices/currentProject";
import { Menu, Button } from "@mantine/core";
import { TbNotes } from "react-icons/tb";
import EditAssetNotesModal from "./editAssetNotesModal";
function Heading({ heading, selectedVideo }) {
  const [showEditTitle, setShowEditTitle] = useState(false);
  const [showDeleteVideo, setShowDeleteVideo] = useState(false);
  const { isPublic, project } = useSelector((state) => state.currentProject);
  const [notesModal, setNotesModal] = useState(false);
  const dispatch = useDispatch();
  const notes = project.videos.find(
    (video) => video._id === selectedVideo._id
  )?.notes;
  const isNotesAvailable = notes ? true : false;
  const handleEdit = () => {
    setShowEditTitle(!showEditTitle);
  };
  const updateVideoTitle = (title) => {
    try {
      dispatch(
        currentProjectActions.setAssetTitle({
          asset: { _id: selectedVideo._id, title },
        })
      );
    } catch (err) {
      console.error(err);
    }
    // runGetProjectData(projectData._id, true);
    setShowEditTitle(false);
  };

  return (
    <>
      <EditVideoTitleModal
        show={showEditTitle}
        onClose={handleEdit}
        videoId={selectedVideo?._id}
        videoTitle={selectedVideo?.name}
        onChange={updateVideoTitle}
      />
      <RemoveFileModal
        showDeleteVideo={showDeleteVideo}
        setShowDeleteVideo={setShowDeleteVideo}
        selectedVideo={selectedVideo}
      />
      <EditAssetNotesModal
        opened={notesModal}
        onClose={() => setNotesModal(false)}
        name={selectedVideo?.name}
        assetId={selectedVideo?._id}
        alreadyAdded={true}
      />

      <div className={styles.headingContainer}>
        <div>
          <h4>{heading}</h4>
        </div>
        <div>
          {!isPublic && (
            <Menu
              className="menu-container"
              shadow="md"
              transitionProps={{ transition: "pop-top-right", duration: 150 }}
              position="bottom-left"
            >
              <Menu.Target>
                <Button variant="white">
                  <FiMoreHorizontal />
                </Button>
              </Menu.Target>

              <Menu.Dropdown className="menu-dropdown">
                <Menu.Item
                  className={styles.notesMenuItem}
                  onClick={() => setShowEditTitle(true)}
                >
                  <FiEdit2 />
                  &nbsp;&nbsp;Rename
                </Menu.Item>
                <Menu.Item
                  className={styles.notesMenuItem}
                  onClick={() => setNotesModal(true)}
                >
                  <TbNotes />
                  &nbsp;&nbsp;{isNotesAvailable ? "Edit" : "Add"} Notes
                </Menu.Item>
                <Menu.Item
                  className={styles.notesMenuItem}
                  onClick={() => setShowDeleteVideo(true)}
                >
                  <RiDeleteBinLine />
                  &nbsp;&nbsp;Remove File
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}
        </div>
      </div>
    </>
  );
}

export default Heading;
