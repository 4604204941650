
import React, { useState, useEffect } from 'react';
import { Button, Alert } from 'react-bootstrap';
export const RAIDisclaimer = () => {
  const [showAlert, setShowAlert] = useState(true);

  const handleClose = () => {
    setShowAlert(false);
    localStorage.setItem('hideRAIAlert', 'true');
  };

  useEffect(() => {
    const hideAlert = localStorage.getItem('hideRAIAlert');
    if (hideAlert) {
      setShowAlert(false);
    }
  }, []);

  return showAlert ? (<Alert
    variant="success"
    className="ue-alert-1"
    style={{margin:"0.5rem 1rem"}}
  >
    <p style={{fontSize:14, margin:0}}>
      Always fact-check the information you find here. AI can produce false or misleading information.
    </p>
    <Button variant="outline-primary" onClick={handleClose} size="sm">
      Okay
    </Button>
  </Alert>  
  ) :null  ;
};