import React from "react";
import { Route, Switch } from "react-router-dom";
import { ProtectedRoute } from "./components/protected-route";
import { AuthenticatedTeamMember } from "./pages/authenticatedteammember";
import { ProjectsPage } from "./pages/projects";
import ProPage from "./pages/project";
import { ProjectPage as PPage } from "./pages/project-vnext";
import { PresentPage } from "./pages/present";
import { DownloadPage } from "./pages/download";
import { SettingsPage } from "./pages/settings";
import { PlansPage } from "./pages/plans";
import { PaymentSuccess } from "./pages/paymentsuccess";
import { Invitation } from "./pages/invitation";
import { NotFoundPage } from "./pages/not-found-page";
import { WelcomePage } from "./pages/welcome";
import { WaitlistPage } from "./pages/waitlist";
import { IntegrationsPage } from "./pages/integrations";
import { ZoomIntegrationPage } from "./pages/integrations/zoom";
import DriveIntegrationPage from "./pages/integrations/drive";
import ZoomOauthPage from "./pages/integrations/zoom/oauth";
import ZapierIntegrationPage from "./pages/integrations/zapier";
import { MediaLibraryPage } from "./pages/media-library";
import { PublicPage } from "./pages/public";
import { SearchPage } from "./pages/search";
import { PhoneVerify } from "./pages/phone-verify";
import { AppLiveChatAuth } from "./pages/apps/livechat";
import { AppLiveChatCallback } from "./pages/apps/livechat/callback.js";
import { AppLiveChatImport } from "./pages/apps/livechat/import.js";
import {
  switchToDarkModeAction,
  switchToLightModeAction,
} from "./store/actions/themeActions";
import { SocketContext } from "./utils/SocketContext";
import useSocketIO from "./utils/useSocket";
import { Auth, Login, ForgotPassword } from "./pages/auth";
import { Participants } from "./pages/participants";
import TestsRoute from "./pages/new-test/routes/test-route.js";
import { ParticipantProfile } from "./pages/participants/profile/index.js";
import { ParticipantSettings } from "./pages/participants/settings/index.js";
import { ParticipantPhoneVerification } from "./pages/participants/phone-verification/index.js";
import { ParticipantPrompt } from "./pages/participants/prompt/index.js";
import { WelcomeToTest } from "./pages/participants/test-participation/welcome-to-test.js";
import { StartTest } from "./pages/participants/test-participation/start-test.js";
import TestOverviewRoutes from "./pages/tests/test-overview-routes.js";
import TestCompleted from "./pages/participants/test-participation/test-completed.js";
import SignDocument from "./pages/participants/test-participation/sign-document.js";
import RedirectTest from "./pages/participants/test-participation/redirect-test.js";
import { Form } from "./pages/form";
import Admin from "./pages/admin/index.js";
import TestConcluded from "./pages/participants/test-participation/test-concluded.js";
// import { ServerErrorPage } from "./pages/server-error-page/server-error-page";

const Routes = () => {
  const socket = useSocketIO();
  return (
    <SocketContext.Provider value={socket}>
      <Switch>
        <Route path="/" exact component={WelcomePage} />
        <Route path="/invitation/:id" component={Invitation} />
        <Route path="/public/:id/:tab" component={PublicPage} />
        <Route path="/public/:id" component={PublicPage} />
        <Route path="/waitlist" component={WaitlistPage} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Auth} />
        <ProtectedRoute path="/phone-verify" component={PhoneVerify} />
        <ProtectedRoute
          path="/authenticatedteammember/:id"
          component={AuthenticatedTeamMember}
        />
        <ProtectedRoute path="/projects" component={ProjectsPage} />
        {/* <ProtectedRoute path="/new-project" component={ProjectNewPage} /> */}
        <ProtectedRoute path="/settings" component={SettingsPage} />
        <ProtectedRoute path="/plans" component={PlansPage} />
        <ProtectedRoute path="/paymentsuccess" component={PaymentSuccess} />
        <ProtectedRoute path="/p/:projectId/notes/:noteId" component={PPage} />
        {/* <ProtectedRoute path="/project/:projectId" component={NewProjectPage} /> */}
        <ProtectedRoute path="/project/:projectId" component={ProPage} />
        <ProtectedRoute path="/p/:projectId/asset/:assetId" component={PPage} />
        <ProtectedRoute path="/p/:projectId" component={PPage} />
        <ProtectedRoute path="/present/:id" component={PresentPage} />
        <ProtectedRoute path="/download/:id" component={DownloadPage} />
        <ProtectedRoute path="/integrations" component={IntegrationsPage} />
        <ProtectedRoute
          path="/integration/:app/oauth"
          component={ZoomOauthPage}
        />
        <ProtectedRoute
          path="/integration/zoom"
          component={ZoomIntegrationPage}
        />
        <ProtectedRoute
          path="/integration/google"
          component={DriveIntegrationPage}
        />
        <ProtectedRoute
          path="/integration/zapier"
          component={ZapierIntegrationPage}
        />
        <Route path="/apps/livechat/authenticate" component={AppLiveChatAuth} />
        <Route path="/apps/livechat/callback" component={AppLiveChatCallback} />
        <ProtectedRoute
          path="/apps/livechat/import"
          component={AppLiveChatImport}
        />
        <ProtectedRoute path="/media-library" component={MediaLibraryPage} />
        <ProtectedRoute path="/search" component={SearchPage} />

        <ProtectedRoute path="/interviews" component={TestsRoute} />

        <ProtectedRoute path="/past-participants" component={Participants} />
        <ProtectedRoute
          path="/participant/settings"
          component={ParticipantSettings}
        />
        <ProtectedRoute
          path="/participant/phone-verification"
          component={ParticipantPhoneVerification}
        />
        <ProtectedRoute
          path="/participant/prompt"
          component={ParticipantPrompt}
        />
        <ProtectedRoute path="/participant/start-test" component={StartTest} />
        <ProtectedRoute
          path="/participant/sign-document"
          component={SignDocument}
        />
        <ProtectedRoute path="/participant/screener" component={Form} />
        <ProtectedRoute path="/participant/redirect" component={RedirectTest} />
        <Route path="/participant/welcome" component={WelcomeToTest} />
        <Route path="/participant/test-completed" component={TestCompleted} />
        <Route path="/participant/test-concluded" component={TestConcluded} />
        <ProtectedRoute path="/participant" component={ParticipantProfile} />
        <ProtectedRoute path="/interview" component={TestOverviewRoutes} />
        <ProtectedRoute path="/admin" component={Admin} />
        {/* <Route path="/500" component={ServerErrorPage} /> */}
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </SocketContext.Provider>
  );
};

export const App = () => {
  // if authLoading is true and url is not /authenticated
  // check darkmode from localstorage
  const darkMode = localStorage.getItem("darkMode") === "true" ? true : false;
  // document.documentElement.setAttribute('data-theme', e.target.checked ? 'dark' : 'light')

  if (darkMode) {
    document.documentElement.setAttribute("data-theme", "dark");
    switchToDarkModeAction();
  } else {
    document.documentElement.setAttribute("data-theme", "light");
    switchToLightModeAction();
  }

  return <Routes />;
};
