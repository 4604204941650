import { createSlice } from "@reduxjs/toolkit";



const integrationsSlice = createSlice({
    name: 'zoomIntegration',
    initialState: {
        apps: [],
        loading: false,
    },
    reducers: {
        startLoading(state){
            state.loading = true;
        },
        stopLoading(state){
            state.loading = false;
        },
        setApps(state, action){
            state.apps = action.payload.apps;
        }
    },
})


export default integrationsSlice;
export const integrationActions = integrationsSlice.actions