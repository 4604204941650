import { Button, Form, Modal, Table } from "react-bootstrap";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { toastError, toastSuccess } from "../../../utils/toast";
import {
  getUsersByOrganization,
  removeUserFromOrganization,
} from "../../../store/actions/authActions";
import { sendInvitation } from "../../../store/actions/teamInvitationActions";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../../../store/slices/modalSlice";

function Team() {
  const {
    loading,
    account,
    loading: authLoading,
  } = useSelector((state) => state.auth);
  const { darkMode } = useSelector((state) => state.theme);
  const [emails, setEmails] = useState([""]);
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const isPlus =
    account?.plan?.type === "Plus Yearly" ||
    account?.plan?.type === "Plus Monthly";
  useEffect(() => {
    if (authLoading) return;

    const getOrganizationUsers = async (organizationId) => {
      const { data, error } = await getUsersByOrganization(organizationId);

      if (data) {
        setOrganizationUsers(data);
      }

      if (error) {
        console.error(error);
      }
    };

    getOrganizationUsers(account.organization);
  }, [authLoading]);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleAddEmail = () => {
    setEmails([...emails, ""]);
  };

  const handleEmailChange = (index, event) => {
    const newEmails = [...emails];
    newEmails[index] = event.target.value;
    setEmails(newEmails);
  };

  const handleRemoveEmail = (index) => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    setEmails(newEmails);
  };

  const handleInvite = async () => {
    // Send invite emails to the provided addresses
    if (!isPlus) {
      return dispatch(modalActions.showPayWallModal());
    }
    const payload = {
      emails: emails,
      userId: account._id,
      organizationId: account.organization,
    };

    const { data, error } = await sendInvitation(payload);

    if (data) {
      console.log(data);
      toastSuccess("Invites sent!");
      setEmails([""]);
      handleCloseModal();
    }

    if (error) {
      console.error(error);
      toastError(error.message);
    }
  };

  if (loading) return "Loading...";
  return (
    <div className="settings-table-container">
      {/* if account.role is admin or superadmin */}
      {(account?.role === "admin" || account?.role === "superadmin") && (
        <Button variant="primary" className="mb-3" onClick={handleShowModal}>
          Invite Team Member
        </Button>
      )}

      {/* Modal for inviting team members */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Invite Team Members</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {emails.map((email, index) => (
            <div key={index} className="d-flex align-items-center">
              <Form.Control
                className="mb-2"
                placeholder="Email"
                type="email"
                value={email}
                onChange={(event) => handleEmailChange(index, event)}
              />
              <Button
                variant="transparent"
                className="pl-2 m-0 ml-2"
                onClick={() => handleRemoveEmail(index)}
              >
                {/* material icon */}
                <i className="material-icons">delete</i>
              </Button>
            </div>
          ))}
          <Button variant="secondary" onClick={handleAddEmail}>
            Add Another Email
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleInvite}>
            Invite All
          </Button>
        </Modal.Footer>
      </Modal>

      {/* table with team members and roles */}

      <Table bordered hover variant={darkMode ? "dark" : "light"}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {organizationUsers &&
            organizationUsers.map((user) => (
              <tr key={user._id}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.role}</td>
                {user.role === "user" && account.role === "admin" && (
                  <td>
                    <Button
                      variant="danger"
                      onClick={async () => {
                        const { data } = await removeUserFromOrganization(
                          user._id,
                          account.organization
                        );
                        if (data) {
                          toastSuccess("User removed successfully");
                          setOrganizationUsers(
                            organizationUsers.filter((u) => u._id !== user._id)
                          );
                        }
                      }}
                    >
                      Remove
                    </Button>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
}

Team.propTypes = {
  account: PropTypes.shape({}),
  darkMode: PropTypes.bool,
};

export default Team;
