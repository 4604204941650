import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FiX } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { deleteInsight } from "../../../../../store/actions/projectActions";
import { Tooltip } from "@mantine/core";

function DeleteBtn({ singleInsight, videoId, insightType, showSources, fake }) {
  const [showReminder, setShowReminder] = useState(false);
  const handleDelete = async () => {
    deleteInsight(singleInsight?._id, videoId, insightType);
  };

  if (fake) {
    return (
      <button
        style={{
          backgroundColor: "transparent",
          border: "none",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          fontSize: "0.9rem",
          color: "var(--grey5)" 
        }}
        className="project-insight-btn"
      >
        <RiDeleteBinLine
          className="project-insight-icons"
          style={{ color: "var(--grey5)" }}
        />
        Delete
      </button>
    );
  }

  if (!showSources) {
    return (
      <>
        {showReminder ? (
          <Button
            className="project-insight-btn"
            size="sm"
            style={{
              backgroundColor: "var(--ue-sidebar-project-initials-bg-new)",
              border: "none",
            }}
          >
            <FiX
              className="project-insight-icons-red"
              onClick={(e) => {
                e.stopPropagation();
                setShowReminder(false);
              }}
            />
            <span
              style={{
                color: " var(--pink)",
                fontWeight: "500",
              }}
              onClick={() => handleDelete()}
            >
              Sure ?
            </span>
          </Button>
        ) : (
          <Tooltip label="Delete">
            <Button
              className="project-insight-btn"
              variant="transparent"
              size="sm"
              onClick={() => {
                setShowReminder(true);
              }}
            >
              <RiDeleteBinLine className="project-insight-icons-red" />
            </Button>
          </Tooltip>
        )}
      </>
    );
  }

  return (
    <>
      {showReminder ? (
        <Button
          className="project-insight-btn"
          size="sm"
          style={{
            backgroundColor: "var(--ue-sidebar-project-initials-bg-new)",
            border: "none",
          }}
        >
          <FiX
            className="project-insight-icons-red"
            onClick={(e) => {
              e.stopPropagation();
              setShowReminder(false);
            }}
          />
          <span
            style={{
              color: " var(--pink)",
              fontWeight: "500",
            }}
            onClick={() => handleDelete()}
          >
            Sure ?
          </span>
        </Button>
      ) : (
        <Button
          className="project-insight-btn"
          variant="transparent"
          size="sm"
          onClick={() => {
            setShowReminder(true);
          }}
        >
          <RiDeleteBinLine className="project-insight-icons-red" />
          Delete
        </Button>
      )}
    </>
  );
}

export default DeleteBtn;
