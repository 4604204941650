/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import style from "./sections.module.css";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { Text, Modal } from "@mantine/core";
import { MdModeEditOutline } from "react-icons/md";
import dayjs from "dayjs";
import calcDuration from "../../../../utils/calcDuration";
import EditSummary from "./components/edit-summary";
import EditCertifications from "./components/edit-certifications";
import EditExperiences from "./components/edit-experience";
import EditEducation from "./components/edit-education";
import EditVolunteeringExperience from "./components/edit-volunteer";
import EditMoreDetails from "./components/edit-more-details";
import { useSelector } from "react-redux";

export function Summary() {
  const { participant } = useSelector((state) => state.participant);
  const { user } = useSelector((state) => state.auth);
  const { summary = "" } = participant || {};

  const [opened, { open, close }] = useDisclosure(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const MAX_LENGTH = 500;

  const toggleSummary = () => {
    setIsExpanded(!isExpanded);
  };

  const truncatedSummary = (
    <span>
      {summary?.substring(0, MAX_LENGTH)}
      {summary?.length > MAX_LENGTH && (
        <span className={style.viewMore} onClick={toggleSummary}>
          ...[see more]
        </span>
      )}
    </span>
  );

  const fullSummary = (
    <span>
      {summary}
      {summary?.length > MAX_LENGTH && (
        <span className={style.viewMore} onClick={toggleSummary}>
          &nbsp;[see less]
        </span>
      )}
    </span>
  );

  return (
    <section className={style.detail}>
      <div className={style.detailTitle}>
        Summary
        {user?.email === participant?.email && (
          <MdModeEditOutline className={style.editIcon} onClick={open} />
        )}
      </div>
      <div className={style.detailValue} style={{ whiteSpace: "pre-wrap" }}>
        {!summary ? (
          <span className={style.noData}>
            You have not added a summary yet.
          </span>
        ) : isExpanded ? (
          fullSummary
        ) : (
          truncatedSummary
        )}
      </div>
      <Modal
        opened={opened}
        onClose={close}
        title={<Text weight={600}>Summary</Text>}
        size={isMobile ? "xs" : "lg"}
        centered
      >
        <EditSummary summary={summary} onClose={close} />
      </Modal>
    </section>
  );
}

export function Experience() {
  const { participant } = useSelector((state) => state.participant);
  const { experiences = [] } = participant || {};

  const [showAllItems, setShowAllItems] = useState(false);
  const maxVisibleExperience = 3;

  const visibleExperience = showAllItems
    ? experiences
    : experiences.slice(0, maxVisibleExperience);

  return (
    <DetailSection
      title="Experience"
      data={experiences}
      maxVisibleItems={maxVisibleExperience}
      showAllItems={showAllItems}
      setShowAllItems={setShowAllItems}
      editComponent={EditExperiences}
    >
      {visibleExperience &&
      visibleExperience.length > 0 &&
      visibleExperience[0].position !== "" ? (
        visibleExperience.map((exp, index) => (
          <div key={index} className={style.experienceItem}>
            <div>
              <div className={style.bold}>{exp.title}</div>
              <div>
                {exp.company_name}
                {exp.employment_type && ` • ${exp.employment_type}`}
              </div>
            </div>
            <div className={style.grey}>
              {exp.start_date && (
                <div>
                  {dayjs(exp.start_date).format("MMM YYYY")} -{" "}
                  {exp.end_date
                    ? dayjs(exp.end_date).format("MMM YYYY")
                    : "Present"}
                  {" • "}
                  {calcDuration(exp.start_date, exp.end_date)}
                </div>
              )}
              <div>{exp.location}</div>
            </div>
            <div>{exp.description}</div>
          </div>
        ))
      ) : (
        <span className={style.noData}>
          You have not added any experiences yet.
        </span>
      )}
    </DetailSection>
  );
}

export function Education() {
  const { participant } = useSelector((state) => state.participant);
  const { educations = [] } = participant || {};
  const { user } = useSelector((state) => state.auth);
  const [showAllItems, setShowAllItems] = useState(false);
  const maxVisibleEducation = 2;

  const visibleEducation = showAllItems
    ? educations
    : educations.slice(0, maxVisibleEducation);

  return (
    <DetailSection
      title="Education"
      data={educations}
      maxVisibleItems={maxVisibleEducation}
      showAllItems={showAllItems}
      setShowAllItems={setShowAllItems}
      editComponent={EditEducation}
      
    >
      {visibleEducation &&
      visibleEducation.length > 0 &&
      visibleEducation[0].school !== "" ? (
        visibleEducation.map((edu, index) => (
          <div key={index} className={style.educationItem}>
            <div>
              <div className={style.bold}>{edu.school}</div>
              <div>
                {edu.degree}
                {edu.field && ` - ${edu.field}`}
              </div>
            </div>
            {edu.start_date && (
              <div className={style.grey}>
                {dayjs(edu.start_date).format("YYYY")} -{" "}
                {edu.end_date ? dayjs(edu.end_date).format("YYYY") : "Present"}
              </div>
            )}
            <div>{edu.description}</div>
          </div>
        ))
      ) : (
        <span className={style.noData}>
          You have not added any education yet.
        </span>
      )}
    </DetailSection>
  );
}

export function MoreDetails() {
  const [opened, { open, close }] = useDisclosure(false);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { participant } = useSelector((state) => state.participant);
  const { user } = useSelector((state) => state.auth);
  return (
    <section className={style.detail}>
      <div className={style.detailTitle}>
        Skills, Languages, and More
        {user?.email === participant?.email && (
          <MdModeEditOutline className={style.editIcon} onClick={open} />
        )}
      </div>
      <div className={style.detailValue}>
        <div className={style.moreDetailsItem}>
          {participant?.languages && participant?.languages.length > 0 && (
            <div>
              <div className={style.bold}>Languages</div>
              <div className={style.pills}>
                {participant?.languages.map((lang, index) => (
                  <span key={index} className={style.pill}>
                    {lang}
                  </span>
                ))}
              </div>
            </div>
          )}
          {participant?.skills && participant?.skills.length > 0 && (
            <div>
              <div className={style.bold}>Skills</div>
              <div className={style.pills}>
                {participant?.skills.map((skill, index) => (
                  <span key={index} className={style.pill}>
                    {skill}
                  </span>
                ))}
              </div>
            </div>
          )}
          {participant?.industry && (
            <div>
              <div className={style.bold}>Industry</div>
              <div>{participant?.industry}</div>
            </div>
          )}
          {participant?.gender && (
            <div>
              <div className={style.bold}>Gender</div>
              <div>{participant?.gender}</div>
            </div>
          )}
          {participant?.birth_date && (
            <div>
              <div className={style.bold}>Birthdate</div>
              <div>{dayjs(participant?.birth_date).format("D MMMM, YYYY")}</div>
            </div>
          )}
          {participant?.salary?.start && (
            <div>
              <div className={style.bold}>Salary Range</div>
              <div>
                {"$" + participant?.salary?.start}
                {participant?.salary?.end
                  ? " - $" + participant?.salary?.end
                  : ""}
              </div>
            </div>
          )}
          {participant?.interests && participant?.interests.length > 0 && (
            <div>
              <div className={style.bold}>Interests</div>
              <div className={style.pills}>
                {participant?.interests.map((skill, index) => (
                  <span key={index} className={style.pill}>
                    {skill}
                  </span>
                ))}
              </div>
            </div>
          )}
          {participant?.personal_emails &&
            participant?.personal_emails.length > 0 && (
              <div>
                <div className={style.bold}>Email Addresses</div>
                {participant?.personal_emails.map((email, index) => (
                  <span key={index}>
                    {email}
                    {index === 0 ? " (Primary)" : ""}
                    {index !== participant?.personal_emails.length - 1 && ", "}
                  </span>
                ))}
              </div>
            )}
          {participant?.personal_numbers &&
            participant?.personal_numbers.length > 0 &&
            participant?.personal_numbers[0] !== "" && (
              <div>
                <div className={style.bold}>Phone Numbers</div>
                {participant?.personal_numbers.map((phone, index) => (
                  <span key={index}>
                    {phone}
                    {index !== participant?.personal_numbers.length - 1 && ", "}
                  </span>
                ))}
              </div>
            )}
        </div>
      </div>
      <Modal
        opened={opened}
        onClose={close}
        title={<Text weight={600}>Skills, Languages and more</Text>}
        size={isMobile ? "xs" : "md"}
        centered
      >
        <EditMoreDetails data={participant} onClose={close} />
      </Modal>
    </section>
  );
}

export function Volunteering() {
  const { participant } = useSelector((state) => state.participant);
  const { volunteer_work = [] } = participant || {};
  const { user } = useSelector((state) => state.auth);
  const [showAllItems, setShowAllItems] = useState(false);
  const maxVisibleVolunteering = 2;

  const visibleVolunteering = showAllItems
    ? volunteer_work
    : volunteer_work.slice(0, maxVisibleVolunteering);

  return (
    <DetailSection
      title="Volunteering"
      data={volunteer_work}
      maxVisibleItems={maxVisibleVolunteering}
      showAllItems={showAllItems}
      setShowAllItems={setShowAllItems}
      editComponent={EditVolunteeringExperience}
      
    >
      <div className={style.detailValue}>
        {visibleVolunteering &&
        visibleVolunteering.length > 0 &&
        visibleVolunteering[0].organization !== "" ? (
          visibleVolunteering.map((volunteer, index) => (
            <div key={index} className={style.volunteeringItem}>
              <div>
                <div className={style.bold}>{volunteer.role}</div>
                <div>{volunteer.organization}</div>
              </div>
              {volunteer.start_date && (
                <div className={style.grey}>
                  {dayjs(volunteer.start_date).format("MMM YYYY")} -{" "}
                  {volunteer.end_date
                    ? dayjs(volunteer.end_date).format("MMM YYYY")
                    : "Present"}
                  {" • "}
                  {calcDuration(volunteer.start_date, volunteer.end_date)}
                  {volunteer.cause && " • "}
                  {volunteer.cause}
                </div>
              )}
              <div>{volunteer.description}</div>
            </div>
          ))
        ) : (
          <span className={style.noData}>
            You have not added any volunteer experiences yet.
          </span>
        )}
      </div>
    </DetailSection>
  );
}

export function Certifications() {
  const { participant } = useSelector((state) => state.participant);
  const { certifications = [] } = participant || {};

  const [showAllItems, setShowAllItems] = useState(false);
  const maxVisibleCertifications = 2;

  const visibleCertifications = showAllItems
    ? certifications
    : certifications.slice(0, maxVisibleCertifications);

  return (
    <DetailSection
      title="Licenses & Certifications"
      data={certifications}
      maxVisibleItems={maxVisibleCertifications}
      showAllItems={showAllItems}
      setShowAllItems={setShowAllItems}
      editComponent={EditCertifications}
      
    >
      {visibleCertifications &&
      visibleCertifications.length > 0 &&
      visibleCertifications[0].name !== "" ? (
        visibleCertifications.map((certification, index) => (
          <div key={index} className={style.certificationItem}>
            <div>
              <div className={style.bold}>{certification.name}</div>
              <div>{certification.authority}</div>
            </div>
            {certification.issue_date && (
              <div className={style.grey}>
                Issued {dayjs(certification.issue_date).format("MMM YYYY")}
              </div>
            )}
          </div>
        ))
      ) : (
        <span className={style.noData}>
          You have not added any licenses & certifications yet.
        </span>
      )}
    </DetailSection>
  );
}

export function Recommendations() {
  const { participant } = useSelector((state) => state.participant);
  const { recommendations = [] } = participant || {};

  const [showAllItems, setShowAllItems] = useState(false);
  const [expandedRecommendations, setExpandedRecommendations] = useState({});
  const maxVisibleRecommendations = 2;
  const MAX_LENGTH = 500;

  const visibleRecommendations = showAllItems
    ? recommendations
    : recommendations.slice(0, maxVisibleRecommendations);

  const toggleRecommendation = (index) => {
    setExpandedRecommendations({
      ...expandedRecommendations,
      [index]: !expandedRecommendations[index],
    });
  };

  return (
    <DetailSection
      title="Recommendations"
      data={recommendations}
      maxVisibleItems={maxVisibleRecommendations}
      showAllItems={showAllItems}
      setShowAllItems={setShowAllItems}
    >
      {visibleRecommendations &&
      visibleRecommendations.length > 0 &&
      visibleRecommendations[0].name !== "" ? (
        visibleRecommendations.map((recommendation, index) => (
          <div key={index} className={style.recommendationItem}>
            <div>
              <div className={style.bold}>{recommendation.name}</div>
              {/* <div>{recommendation.position}</div> */}
            </div>
            <div>
              {expandedRecommendations[index]
                ? recommendation.text
                : recommendation.text.substring(0, MAX_LENGTH)}
              {recommendation.text.length > MAX_LENGTH && (
                <span
                  className={style.viewMore}
                  onClick={() => toggleRecommendation(index)}
                >
                  {expandedRecommendations[index]
                    ? " [see less]"
                    : "...[see more]"}
                </span>
              )}
            </div>
          </div>
        ))
      ) : (
        <span className={style.noData}>
          No recommendations found on LinkedIn.
        </span>
      )}
    </DetailSection>
  );
}

function DetailSection({
  title,
  data,
  maxVisibleItems = 2,
  showAllItems,
  setShowAllItems,
  editComponent = null,
  setProfile,
  children,
 
}) {
  const [opened, { open, close }] = useDisclosure(false);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const {participant} = useSelector((state) => state.participant);
  const {user}=useSelector((state)=>state.auth)
  return (
    <section className={style.detail}>
      <div className={style.detailTitle}>
        {title}
        {title !== "Recommendations" && user?.email === participant?.email && (
          <MdModeEditOutline className={style.editIcon} onClick={open} />
        )}
      </div>
      <div className={style.detailValue}>
        {children}

        {data.length > maxVisibleItems && (
          <div
            className={style.viewMore}
            onClick={() => setShowAllItems(!showAllItems)}
          >
            {showAllItems
              ? `View fewer ${title?.toLowerCase()}`
              : `View ${
                  data.length - maxVisibleItems
                } more ${title?.toLowerCase()}`}
          </div>
        )}
      </div>
      <Modal
        opened={opened}
        onClose={close}
        title={<Text weight={600}>Edit {title}</Text>}
        size={isMobile ? "xs" : "md"}
        centered
      >
        {editComponent &&
          React.createElement(editComponent, {
            data: data,
            onClose: close,
            setProfile: setProfile,
          })}
      </Modal>
    </section>
  );
}
