function getCharacters(string) {
  let words;
  if(!string) { words = ['??']; }
  else { words = string.split(' ') }
  let letters = words.map(word => word[0])
  if (letters.length > 1)
    return `${letters[0]}${letters[1]}`.toUpperCase();
  return `${letters[0]}`.toUpperCase();
}

export default getCharacters;