import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useDispatch } from "react-redux";
import { Table, Pagination } from "react-bootstrap";
import {
  getCustomerRequests,
  updateCustomerRequest,
} from "../../../../store/actions/customerRequestsActions";
import dayjs from "dayjs";


function CustomerRequests() {
  const dispatch = useDispatch();
  const { requests } = useSelector((state) => state.customerRequests);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({ status: "", type: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  let filteredRequests = requests.filter((request) =>
    Object.values(request)
      .join(" ")
      .toLowerCase()
      .includes(search.toLowerCase())
  );

  if (filter.status) {
    filteredRequests = filteredRequests.filter(
      (request) => request.status === filter.status
    );
  }

  if (filter.type) {
    filteredRequests = filteredRequests.filter(
      (request) => request.type === filter.type
    );
  }

  // Logic to calculate the indexes of the items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredRequests.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  useEffect(() => {
    dispatch(getCustomerRequests());
  }, [dispatch]);

  function handleStatusChange(status, id) {
    if (status && id) {
      dispatch(updateCustomerRequest(id, { status }));
    }
  }

  return (
    <div className="d-flex flex-column gap-4">
      <h2 className="text-center m-4">Customer Requests</h2>
      <div className="d-flex gap-4 flex-wrap">
        <div className="d-flex gap-2">
          <label htmlFor="filterStatus">Filter by status:</label>
          <select
            id="filterStatus"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, status: e.target.value }))
            }
            value={filter.status}
          >
            <option value="">all</option>
            <option value="pending">pending</option>
            <option value="in_progress">in progress</option>
            <option value="resolved">resolved</option>
          </select>
        </div>
        <div className="d-flex gap-2">
          <label htmlFor="filterRequestType">Filter by request-type:</label>
          <select
            id="filterRequestType"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, type: e.target.value }))
            }
            value={filter.type}
          >
            <option value="">all</option>
            <option value="manual_phone_verification">
              manual phone verification
            </option>
            <option value="account_deletion">account deletion</option>
          </select>
        </div>
        <div className="d-flex gap-2">
          <label htmlFor="search">Search:</label>
          <input
            type="text"
            id="search"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
      </div>
      <Table
        striped
        bordered
        hover
        style={{
          width: "calc(100vw - 300px)",
        }}
      >
        <thead>
          <tr>
            <th>sn</th>
            <th>created-at</th>
            <th>name</th>
            <th>email</th>
            <th>request-type</th>
            <th>response</th>
            <th>status</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((request, index) => (
            <React.Fragment key={request?._id}>
              <tr>
                <td>{indexOfFirstItem + index + 1}</td>
                <td>
                  {dayjs(request.createdAt).format("DD MMMM YYYY hh:mm A")}
                </td>
                <td>{request.name}</td>
                <td>{request.email}</td>
                <td>{request.type}</td>
                <td>{request.request}</td>
                <td>
                  <select
                    onChange={(e) => {
                      handleStatusChange(e.target.value, request?._id);
                    }}
                    value={request.status}
                  >
                    <option value="pending">pending</option>
                    <option value="in_progress">in progress</option>
                    <option value="resolved">resolved</option>
                  </select>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>

      <Pagination>
        {requests.length > itemsPerPage && (
          <Pagination.Prev
            onClick={() => {
              if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
              }
            }}
          />
        )}

        {requests.length > itemsPerPage && (
          <Pagination.Next
            onClick={() => {
              if (currentPage < Math.ceil(requests.length / itemsPerPage)) {
                setCurrentPage(currentPage + 1);
              }
            }}
          />
        )}
      </Pagination>
    </div>
  );
}

export default CustomerRequests;
