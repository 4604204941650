import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const SidebarIcon = ({ IconComponent, tooltipText, className }) => {
  return (
    <OverlayTrigger
      style={{ display: "inline" }}
      placement="right"
      key={tooltipText}
      delay={{ show: 80, hide: 200 }}
      overlay={(
        <Tooltip id={"button-tooltip"+tooltipText} style={{ zIndex: 999999}}>
          {tooltipText}
        </Tooltip>)}
      popperConfig={{
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              altAxis: true,
              boundary: "viewport",
            },
          },
        ],
      }}
    >
      <div>
      <IconComponent className={className} />
      </div>
    </OverlayTrigger>
  );
};

export default SidebarIcon;