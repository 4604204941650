import React, { useState } from "react";
import styles from "./index.module.css";
import { Button } from "react-bootstrap";
import { FiTrash } from "react-icons/fi";
import {
  changePlanUser,
  deleteUser,
  handleSignInAS,
  makeAdmin,
  removeAdmin,
  verifyUser,
  degradeUser,
} from "../../../../store/actions/adminActions";
import { useHistory } from "react-router-dom";
import { Select } from "@mantine/core";
import { Switch } from "@mantine/core";
function TableRow({ user, itemNumber }) {
  const history = useHistory();
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const isPhoneVerified = user?.phone_verified;
  const isEmailVerified = user?.email_verified;
  const isAdmin = user?.roles?.includes("admin");
  return (
    <tr className={styles.rowContainer}>
      <td>{itemNumber}</td>
      <td>{user._id}</td>
      <td>{user.name}</td>
      <td>{user.email}</td>
      <td>{user.email_verified ? "True" : "False"}</td>

      <td>{user.phone_verified ? "True" : "False"}</td>
      <td>
        <img
          src={user.picture}
          alt="profile"
          style={{ width: "50px", height: "50px" }}
        />
      </td>
      <td>
        <Switch
          checked={user.degradedModel}
          onChange={() => degradeUser(user.email)}
        />
      </td>
      <td>
        <div className={styles.filterContainer}>
          <PlanComponent user={user} plantype={user.plan?.type} />
        </div>
      </td>
      <td>{user.plan?.subscribed ? "True" : "False"}</td>
      <td>
        insightCredits: used: {user?.plan?.insightsCredits?.used} total:
        {user?.plan?.insightsCredits?.total}
        <br />
        storageCredits: used: {user?.plan?.storageCredits?.used} total:
        {user?.plan?.storageCredits?.total}
        <br />
        transcriptionCredits: used: {
          user?.plan?.transcriptionCredits?.used
        }{" "}
        total:
        {user?.plan?.transcriptionCredits?.total}
        <br />
        teamCredits: used: {user?.plan?.teamCredits?.used} total:
        {user?.plan?.teamCredits?.total}
      </td>
      <td>
        <div className={styles.actionButtons}>
          <Button
            onClick={() => {
              handleSignInAS(user._id, () => {
                history.push("/projects");
              });
            }}
          >
            Sign in{" "}
          </Button>

          <br />
          {deleteConfirmation ? (
            <Button
              variant="danger"
              onClick={() => {
                deleteUser(user?._id);
                setDeleteConfirmation(false);
              }}
            >
              Sure ?
            </Button>
          ) : (
            <Button
              variant="danger"
              onClick={() => {
                setDeleteConfirmation(true);
              }}
            >
              Delete <FiTrash />
            </Button>
          )}

          {!isPhoneVerified && (
            <Button onClick={() => verifyUser(user?._id, "phone")}>
              Verify Phone
            </Button>
          )}
          {!isEmailVerified && (
            <Button onClick={() => verifyUser(user?._id, "email")}>
              Verify Email
            </Button>
          )}

          {!isAdmin ? (
            <Button
              onClick={() => {
                makeAdmin(user._id);
              }}
              variant="success"
            >
              Make Admin
            </Button>
          ) : (
            <Button
              onClick={() => {
                removeAdmin(user._id);
              }}
              variant="danger"
            >
              Remove Admin
            </Button>
          )}
        </div>
      </td>
    </tr>
  );
}
const PlanComponent = ({ plantype, user }) => {
  const [planType, setPlanType] = useState(plantype);
  const [confirm, setConfirm] = useState(true);

  return (
    <div className={styles.planContainer}>
      <Select
        className={styles.selectPlan}
        data={[
          "Free",
          "Plus Monthly",
          "Plus Yearly",
          "Business Monthly",
          "Business Yearly",
          "Personal Monthly",
          "Personal Yearly",
          "Basic Monthly",
          "Basic Yearly",
          "Standard Monthly",
          "Standard Yearly",
        ]}
        value={planType}
        onChange={(value) => {
          setPlanType(value);
          if (plantype !== value) {
            setConfirm(false);
          } else {
            setConfirm(true);
          }
        }}
        defaultValue={planType}
      />

      {!confirm && (
        <>
          <Button
            disabled={planType === plantype}
            onClick={() => {
              changePlanUser(user._id, planType, "researcher");
              setConfirm(true);
            }}
          >
            Confirm
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setPlanType(plantype);
              setConfirm(true);
            }}
          >
            Cancel
          </Button>
        </>
      )}
    </div>
  );
};

export default TableRow;
