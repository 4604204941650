import React from "react";
import ReactGA from "react-ga4";
import { PageNavbarWaitlist } from "../components/navigation/navbar-waitlist";
ReactGA.initialize("G-5F43YVG7SP");

export const PageLayoutWaitlist = ({ children }) => {
  
  // record page view
  // ReactGA.pageview(window.location.pathname + window.location.search);
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

  return (
    <div className="page-layout-waitlist">
      <PageNavbarWaitlist />
      <div className="page-layout__content">{children}</div>
    </div>
  );
};
