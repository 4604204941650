import { useSelector } from "react-redux";

export const useGetCollectionCsvData = (
  { includeSource = true, includeNotes = true, includeTags = true },
  isTag
) => {
  const { lanes } = useSelector((state) => state.lanes);
  const { data } = useSelector((state) => state.ribbon);

  if (!isTag) {
    // First, find the maximum number of cards across all lanes
    const maxCards = Math.max(...lanes.map((lane) => lane.cards.length));

    // Initialize the result array
    const result = Array.from({ length: maxCards }, (_, index) => ({
      ID: index + 1,
    }));

    // Map for tracking duplicate lane titles
    const laneTitleCount = {};

    // Fill up the result array
    for (let laneIndex = 0; laneIndex < lanes.length; laneIndex++) {
      const lane = lanes[laneIndex];

      // Check for duplicate lane titles
      laneTitleCount[lane.title] = laneTitleCount[lane.title]
        ? laneTitleCount[lane.title] + 1
        : 1;
      const currentTitleCount = laneTitleCount[lane.title];
      const titleToUse =
        currentTitleCount > 1
          ? `${lane.title} ${currentTitleCount}`
          : lane.title;

      for (let i = 0; i < maxCards; i++) {
        const card = lane.cards[i];
        let cardString = "";

        if (card) {
          cardString += `Insight: ${card.insight}\n\n\n`;

          if (card.source && includeSource) {
            cardString += `Source: ${card.source}\n\n\n`;
          }

          if (card.notes && includeNotes) {
            cardString += `Notes: ${card.notes}\n\n\n`;
          }

          if (card.type && card.type.length > 0 && includeTags) {
            cardString += `Tags: ${card.type.join(", ")}`;
          }
        }

        result[i][titleToUse] = cardString;
      }
    }

    return result;
  }
  if (data?.tags) {
    const maxCards = Math.max(...data.tags.map((tag) => tag.tags.length));
    // Initialize the result array
    const result = Array.from({ length: maxCards }, (_, index) => ({
      ID: index + 1,
    }));
    // Map for tracking duplicate lane titles
    const laneTitleCount = {};
    for (let laneIndex = 0; laneIndex < data?.tags.length; laneIndex++) {
      const lane = data.tags[laneIndex];

      // Check for duplicate lane titles
      laneTitleCount[lane.title] = laneTitleCount[lane.title]
        ? laneTitleCount[lane.title] + 1
        : 1;
      const currentTitleCount = laneTitleCount[lane.title];
      const titleToUse =
        currentTitleCount > 1
          ? `${lane.title} ${currentTitleCount}`
          : lane.title;

      for (let i = 0; i < maxCards; i++) {
        const card = lane.tags[i];
        let cardString = "";
        if (card) {
          let flag = false;
          if (card.text && includeSource) {
            flag = true;
            cardString += `Source: ${card.text}\n\n\n`;
          }
          if (card?.asset?.name && includeTags) {
            flag = true;
            cardString += `Video Name: ${card.asset.name}`;
          }
          if (flag) {
            cardString += `\n\n\n`;
          }
        }

        result[i][titleToUse] = cardString;
      }
    }
    return result;
  } else {
    return [];
  }
};
