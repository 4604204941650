import { createSlice } from "@reduxjs/toolkit";

const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    organization: [],
    loading: true,
    error: null,
  },
  reducers: {
    requestStart(state, action) {
      state.loading = true;
      state.error = null;
    },
    setOrganization(state, action) {
      state.loading = false;
      state.organization = action.payload.organization;
      state.error = null;
    },
    requestFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    addLogo(state, action) {
      state.organization[0].logo = action.payload.logo;
    },
    setCustomVocabulary(state,action){
      state.organization[0].customVocabulary=action.payload
    }
  },
});

export default organizationSlice;
export const organizationActions = organizationSlice.actions;
