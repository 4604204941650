import { customerRequestsActions } from "../slices/customerRequestsSlice";
import { callExternalApi } from "../../services/external-api.service";
import { toast } from "react-toastify";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const createCustomerRequest = (requestData) => async (dispatch) => {
  dispatch(customerRequestsActions.setStatus("loading"));

  const config = {
    url: `${apiServerUrl}/api/customer-requests/`,
    method: "POST",
    data: requestData,
  };

  const { data, error } = await callExternalApi({ config });
  if (error) {
    toast.error("Failed to send your request. Please contact support@userevaluation.com");
    dispatch(customerRequestsActions.setError(error.message));
    dispatch(customerRequestsActions.setStatus("failed"));
    return;
  }
  dispatch(customerRequestsActions.addRequest(data));
  dispatch(customerRequestsActions.setStatus("succeeded"));
  toast.success("Thank you for submitting your request! We will get back to you soon.");
};

export const getCustomerRequests = () => async (dispatch) => {
  dispatch(customerRequestsActions.setStatus("loading"));
  const config = {
    url: `${apiServerUrl}/api/customer-requests/`,
    method: "GET",
  };

  const { data, error } = await callExternalApi({ config });
  if (error) {
    dispatch(customerRequestsActions.setError(error.message));
    dispatch(customerRequestsActions.setStatus("failed"));
    return;
  }
  dispatch(customerRequestsActions.setRequests(data?.customerRequests));
  dispatch(customerRequestsActions.setStatus("succeeded"));

  return data;
};

export const updateCustomerRequest = (id, requestData) => async (dispatch) => {


  dispatch(customerRequestsActions.setStatus("loading"));

  const config = {
    url: `${apiServerUrl}/api/customer-requests/${id}`,
    method: "PATCH",
    data: requestData,
  };

  const { data, error } = await callExternalApi({ config });
  if (error) {
    dispatch(customerRequestsActions.setError(error.message));
    dispatch(customerRequestsActions.setStatus("failed"));
    return;
  }
  dispatch(customerRequestsActions.updateRequest(data?.customerRequest));
  dispatch(customerRequestsActions.setStatus("succeeded"));

  return data;
};
