import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Pagination, Spinner, Table } from "react-bootstrap";
import {
  FiVideo,
  FiMusic,
  FiFileText,
  FiColumns,
  FiMessageCircle,
} from "react-icons/fi";

import { loadAssets } from "../../../../store/actions/mediaLibraryActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import truncateString from "../../../../utils/truncateString";

export default function BrowseFileLib({ selected, onChange }) {
  const [loading, setLoading] = useState(true);
  const assets = useSelector((state) => state.mediaLibrary.assets);
  const { darkMode } = useSelector((state) => state.theme);
  const params = new URLSearchParams(window.location.search);
  const currentPage = +params.get("page") || 1;
  const history = useHistory();
  const iconsForAssetType = [
    {
      type: "VIDEO",
      icon: <FiVideo />,
    },
    {
      type: "AUDIO",
      icon: <FiMusic />,
    },
    {
      type: "TEXT",
      icon: <FiFileText />,
    },
    {
      type: "CSV",
      icon: <FiColumns />,
    },
    {
      type: "PDF",
      icon: <FiFileText />,
    },
    {
      type: "DOC",
      icon: <FiFileText />,
    },
    {
      type: "LIVECHAT",
      icon: <FiMessageCircle />,
    },
    {
      type: "OTHER",
      icon: <FiFileText />,
    },
  ];
  useEffect(() => {
    const fetchAssets = async () => {
      try {
        await loadAssets();
        setLoading(false);
      } catch (error) {
        console.error("Error loading files:", error);
        setLoading(false);
      }
    };

    if (assets.length === 0) {
      fetchAssets();
    } else {
      setLoading(false);
    }
  }, [assets]);
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      onChange(assets.map((asset) => asset._id));
    } else {
      onChange([]);
    }
  };
  const handleCheckbox = (event) => {
      const id = event.target.getAttribute("data-id");
    if (id) {
      if (!selected.includes(id)) {
        onChange([...selected, id]);
      } else {
        onChange(selected.filter((x) => x !== id));
      }
    }
  };
  const nPages = Math.ceil(assets.length / 10);
  const paginationItems = () => {
    const items = [];

    // show max 5 pages at a time
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(nPages, currentPage + 2);

    if (currentPage > 3)
      items.push(
        <Pagination.Ellipsis
          key={currentPage - 3}
          onClick={() => history.replace(`?page=${currentPage - 3}`)}
        />
      );

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          onClick={() => history.replace(`?page=${i}`)}
          active={currentPage === i}
        >
          {i}
        </Pagination.Item>
      );
    }

    if (currentPage < nPages - 2)
      items.push(
        <Pagination.Ellipsis
          key={currentPage + 3}
          onClick={() => history.replace(`?page=${currentPage + 3}`)}
        />
      );

    return items;
  };
  const rows = assets
    .slice((currentPage - 1) * 10, currentPage * 10)
    .map((asset) => (
      <tr key={asset._id}>
        <td
          style={{
            width: "50px",
            textAlign: "center",
          }}
        >
          <input
            onChange={handleCheckbox}
            checked={selected.includes(asset._id)}
            data-id={asset?._id}
            type="checkbox"
          />
        </td>
        <td colSpan={3} data-id={asset?._id} onClick={handleCheckbox}>
          <span className="file-type-icon">
            {iconsForAssetType.find((icon) => icon.type === asset.assetType)
              ?.icon ||
              iconsForAssetType.find((icon) => icon.type === "OTHER").icon}
          </span>
          {truncateString(asset.name, 20)}
        </td>
        <td data-id={asset?._id} onClick={handleCheckbox}>
          {asset.createdBy.name}
        </td>
      </tr>
    ));

  return (
    <div>
      {loading ? (
        <div style={{ textAlign: "center", margin: "auto" }}>
          <Spinner animation="border" role="status" variant="primary" />
        </div>
      ) : (
        <div className="table-container-new">
          <div className="table-responsive-new">
            <Table
              bordered
              hover
              variant={darkMode ? "dark" : "light"}
              style={{ overflowY: "scroll" }}
              className="media-library-table media-library-table-new"
            >
              <thead>
                <tr>
                  <th
                    style={{
                      width: "50px",
                      textAlign: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={handleSelectAll}
                      checked={selected.length === assets.length}
                    />
                  </th>
                  <th colSpan={3}>File</th>
                  <th>Created By</th>
                </tr>
              </thead>
              <tbody>
                {rows}
                {assets?.length === 0 && !loading && (
                  <tr>
                    <td colSpan={6} className="text-center">
                      <p className="mt-4">
                        You have not uploaded or imported any files yet.
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {assets?.length > 10 ? (
            <Pagination className="justify-content-center pagination-new">
              <Pagination.First
                onClick={() => history.replace("?page=1")}
                disabled={currentPage === 1}
              />

              {paginationItems().map((item) => item)}

              <Pagination.Last
                onClick={() =>
                  history.replace(`?page=${Math.ceil(assets.length / 10)}`)
                }
                disabled={currentPage === Math.ceil(assets.length / 10)}
              />
            </Pagination>
          ) : null}
        </div>
      )}
    </div>
  );
}
