import { toast } from "react-toastify";
import store from "..";
import { callExternalApi } from "../../services/external-api.service";
import { emailActions } from "../slices/emailSlice";

const { dispatch } = store;
const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const loadEmailPrefs = async () => {
  dispatch(emailActions.requestStart());
  const config = {
    url: `${apiServerUrl}/api/emailprefs`,
    method: "GET",
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    console.error("loadEmailPrefsAction", error.message);
  }

  if (data) {
    dispatch(emailActions.setEmailPrefs(data));
  }
  dispatch(emailActions.requestEnd());
};

export const updateEmailPrefs = async (emailPrefs) => {
  const config = {
    url: `${apiServerUrl}/api/emailprefs`,
    method: "PUT",
    data: {
      prefs: emailPrefs,
    },
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    console.error("updateEmailPrefsAction", error.message);
  }

  if (data) {
    dispatch(emailActions.setEmailPrefs(data));
    toast.success("Email preferences updated successfully");
  }
};
