/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import style from "./edit.module.css";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { updateParticipant } from "../../../../../store/actions/participantActions";

export default function EditBio({ onClose }) {
  const { participant } = useSelector((state) => state.participant);
  const { headline, city, state, country, description } = participant || {};

  const [validated, setValidated] = useState(false);

  const handleSave = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      const { error } = await updateParticipant("bio", {
        headline: form.headline.value,
        city: form.city.value,
        state: form.state.value,
        country: form.country.value,
        description: form.description.value,
      });
      if (!error) onClose();
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSave}>
      <Form.Group controlId="formHeadline" className="mb-2">
        <Form.Label className={style.formLabel}>Headline</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter headline"
          name="headline"
          defaultValue={headline}
        />
      </Form.Group>
      <Form.Group controlId="formLocation" className="mb-2">
        <Form.Label className={style.formLabel}>Location</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter city"
          name="city"
          defaultValue={city}
          className="mb-2"
        />
        <Form.Control
          type="text"
          placeholder="Enter state"
          name="state"
          defaultValue={state}
          className="mb-2"
        />
        <Form.Control
          type="text"
          placeholder="Enter country"
          name="country"
          defaultValue={country}
        />
      </Form.Group>
      <Form.Group controlId="formDescription" className="mb-2">
        <Form.Label className={style.formLabel}>Description</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter description"
          name="description"
          defaultValue={description}
        />
      </Form.Group>

      <Form.Group
        controlId="formButtons"
        className="d-flex justify-content-between mt-3"
      >
        <Button
          variant="secondary"
          className={style.closeButton}
          onClick={onClose}
        >
          Close
        </Button>
        <Button type="submit" variant="primary" className={style.saveButton}>
          Save
        </Button>
      </Form.Group>
    </Form>
  );
}
