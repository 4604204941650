import { createSlice } from "@reduxjs/toolkit";

const sentimentSlice = createSlice({
  name: "sentiment",
  initialState: {
    sentiment: [],
    loading: false,
    error: null,
  },
  reducers: {
    requestStart(state, action) {
      state.loading = true;
      state.error = null;
    },
    setSentiment(state, action) {
      state.sentiment = [...state.sentiment,action.payload];
      state.loading = false;
      state.error = null;
    },
    requestFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    requestSuccess(state) {
      state.loading = false;
      state.error = null;
    },
  },
});

export default sentimentSlice;
export const sentimentActions = sentimentSlice.actions;
