import { callExternalApi } from "../../services/external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const sendInvitation = async (payload) => {

  const config = {
    url: `${apiServerUrl}/api/teaminvitation/send`,
    method: "POST",
    data: payload,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

