import React from "react";
import styles from "../index.module.css";
import PropTypes from "prop-types";
import { TagPopUp } from "../../../../project-vnext/components/popup-clips-tags-btn";
import { useSelector } from "react-redux";
import { Tooltip } from "@mantine/core";

import PopOverDropDown from "./popOver";
import { Spinner } from "react-bootstrap";

function Button({
  ele,
  pressed,
  type,
  hideName,
  handleClick,
  tooltipText,
  disabled,
  loading,
  activeTab,
  assetType,
  className = "",
}) {
  const { data } = useSelector((state) => state.ribbon);
  const { project } = useSelector((state) => state.currentProject);
  let isProcessing = project?.status === "Preparing";

  if (type === "drop-down") {
    return (
      <PopOverDropDown
        assetType={assetType}
        activeTab={activeTab}
        ele={ele}
        pressed={pressed}
        hideName={hideName}
        handleClick={handleClick}
        tooltipText={tooltipText}
        disabled={disabled || isProcessing}
      />
    );
  }

  if (ele.name === "Add a Tag" && data?.length > 0) {
    return (
      <button
        onClick={handleClick}
        className={`${styles.toolbar__button} ${pressed ? styles.pressed : ""}`}
        disabled={disabled || isProcessing}
      >
        {ele.icon}
        <TagPopUp
          isOpen={data[0]?.tagPopUpOpen}
          data={data[0].data}
          setIsOpen={data[0].setIsOpen}
          assetId={data[0].assetId}
          projectId={data[0].projectId}
          selectedUtterance={
            data[0].selectedUtterance ? data[0].selectedUtterance : 0
          }
          isDocument={data[0].isDocument}
          isCSV={data[0].isCSV}
        />
        <span className={hideName ? styles.hide : ""}>{ele.name}</span>
      </button>
    );
  }
  if (tooltipText) {
    return (
      <Tooltip label={tooltipText}>
        <button
          onClick={handleClick}
          className={`${styles.toolbar__button} ${
            pressed ? styles.pressed : ""
          }`}
          disabled={disabled || isProcessing}
        >
          {loading ? (
            <Spinner
              animation="border"
              style={{ width: "15px", height: "15px", color: "var(--grey5)" }}
            />
          ) : (
            ele.icon
          )}
          <span className={hideName ? styles.hide : ""}>{ele.name}</span>
        </button>
      </Tooltip>
    );
  }

  return (
    <button
      onClick={handleClick}
      className={`${styles.toolbar__button} ${
        pressed ? styles.pressed : ""
      } ${className}`}
      disabled={disabled || isProcessing}
    >
      {loading ? (
        <Spinner
          style={{ width: "15px", height: "15px", color: "var(--grey5)" }}
          animation="border"
        />
      ) : (
        ele.icon
      )}
      <span className={hideName ? styles.hide : ""}>{ele.name}</span>
    </button>
  );
}
Button.defaultProps = {
  pressed: false,
  type: "",
  hideName: false,
};

Button.propTypes = {
  ele: PropTypes.shape({
    icon: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  pressed: PropTypes.bool,
  type: PropTypes.oneOf(["drop-down", ""]),
  hideName: PropTypes.bool,
};

export default Button;
