import React, { useState } from "react";
import { FiPlus } from "react-icons/fi";
import { Button, Menu, UnstyledButton } from "@mantine/core";
import { NewAsset } from "../project/components/new-asset";
import { loadAssets } from "../../store/actions/mediaLibraryActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { IoCloudUploadOutline } from "react-icons/io5";
import styles from "./addNewFile.module.css";
import { LiaGoogleDrive } from "react-icons/lia";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../../store/slices/modalSlice";
const uploadHelpText =
  "Drop your audio or video files in the designated area to upload it.";
const AddNewFileBtn = () => {
  const [show, setShow] = useState(false);
  const { account: userAccount } = useSelector((state) => state.auth);
  let isFree = userAccount?.plan?.type === "Free";
  const history = useHistory();
  const dispatch = useDispatch();
  const handleShow = () => {
    if (!isFree) return history.push("/integration/google");
    dispatch(modalActions.showPayWallModal());
  };
  const uploadButton = (
    <UnstyledButton
      className={styles.option}
      onClick={() => {
        dispatch(modalActions.showAddAssetModal());
      }}
    >
      <div>
        <IoCloudUploadOutline />
      </div>
      <div>
        <p>Upload Files</p>
        <p>Audio / Video / DOC / CSV / TXT / PDF </p>
      </div>
    </UnstyledButton>
  );
  return (
    <Menu shadow="md" width={400}>
      <NewAsset
        onUploaded={loadAssets}
        helpText={uploadHelpText}
        showAssetBox={show}
        setShowAssetBox={setShow}
        buttonComponent={<button style={{ display: "none" }}>New Btn</button>}
      />
      <Menu.Target>
        <Button leftIcon={<FiPlus />} className="mantine-blue-btn">
          Add new files
        </Button>
      </Menu.Target>
      <Menu.Dropdown className={styles.uploadDropDown}>
        <Menu.Item>{uploadButton}</Menu.Item>
        <Menu.Item onClick={handleShow}>
          <UnstyledButton className={styles.option}>
            <div>
              <LiaGoogleDrive />
            </div>
            <div>
              <p>Import From Drive</p>
              <p> Bring your files from Google Drive </p>
            </div>
          </UnstyledButton>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default AddNewFileBtn;
