import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Papa from "papaparse";
import { useSelector } from "react-redux";
import styles from "./CsvTable.module.css";
import AITagsButton from "../../atTagsButton";
import TagPopUpBtn from "../../tagPopUpBtn";
import {
  deleteTag,
  getTagsByProjectId,
} from "../../../../../store/actions/tagsActions";
import AITagsModal from "../../aiTagsModal";
import { FiX } from "react-icons/fi";

function convertDataToNodesAndColumns(inputData, tags, showTag) {
  if (inputData.length === 0) return { nodes: [], COLUMNS: [] };

  // Extract column labels from the first data object's keys
  const columnLabels = Object.keys(inputData[0]);

  // Create a function to highlight text with tags
  function highlightTextWithTags(text, tagsForRow) {
    // Create a container element to hold the processed text
    const container = document.createElement("div");
    let lastIndex = 0;

    tagsForRow.forEach((tag) => {
      // Find the index of the tag text in the original text
      const index = text.indexOf(tag.text, lastIndex);
      if (index > -1) {
        // Add text before the tag text
        container.appendChild(
          document.createTextNode(text?.substring(lastIndex, index))
        );

        // Create the mark element for the tag text
        const mark = document.createElement("mark");
        mark.style.backgroundColor = tag.tagId.highlightColor;
        mark.appendChild(document.createTextNode(tag.text));
        container.appendChild(mark);

        // Update the last index to the end of the current tag text
        lastIndex = index + tag.text.length;
      }
    });

    // Add any remaining text after the last tag text
    container.appendChild(document.createTextNode(text?.substring(lastIndex)));

    return container.innerHTML;
  }

  // Map over inputData to create nodes
  const nodes = inputData.map((item, rowIndex) => {
    // Find tags for the row
    const tagsForRow = tags.filter((tag) => parseInt(tag.start) === rowIndex);

    // Reduce the item into a new object that has all original properties plus tags
    const node = columnLabels.reduce((acc, key) => {
      acc[key] = item[key];
      return acc;
    }, {});

    // Add a property for the highlighted text for each column
    columnLabels.forEach((label) => {
      node[label] = highlightTextWithTags(node[label], tagsForRow);
    });

    // Add tags information for the row
    node.tags = tagsForRow.map((tag) => ({
      name: `${tag.tagId.title}${
        tag.frequency !== undefined ? ` (${tag.frequency})` : ""
      }`,
      frequency: tag.frequency,
      color: tag.tagId.color,
      id: tag._id,
    }));

    return node;
  });

  // Create the column definitions, including the Tags column if showTag is true
  let COLUMNS = columnLabels.map((label) => ({
    accessorKey: label,
    header: label.charAt(0).toUpperCase() + label.slice(1), // Capitalize the first letter
    Cell: ({ cell, row }) => (
      <div dangerouslySetInnerHTML={{ __html: cell.getValue() }} />
    ),
  }));

  if (showTag) {
    COLUMNS.push({
      id: "tags",
      header: "Tags",
      Cell: ({ row }) => (
        <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
          {row.original.tags.map((tag, idx) => (
            <OverlayTrigger
              key={idx}
              placement="top"
              overlay={
                tag?.frequency ? (
                  <Tooltip id={`tooltip-${idx}`}>
                    Appears {tag?.frequency || 0}{" "}
                    {tag?.frequency === 1 ? "time" : "times"}
                  </Tooltip>
                ) : (
                  <Tooltip id={`tooltip-${idx}`}>Recently added</Tooltip>
                )
              }
            >
              <span
                key={idx}
                style={{
                  backgroundColor: tag.color,
                  padding: "2px 4px",
                  borderRadius: "4px",
                }}
              >
                {tag.name}
                <FiX
                  style={{ marginLeft: "5px", cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteTag(tag.id);
                  }}
                />
              </span>
            </OverlayTrigger>
          ))}
        </div>
      ),
    });
  }

  return { nodes, COLUMNS };
}

export const DataTable = ({ inputData, tags, showTag }) => {
  const [tableData, setTableData] = useState({ nodes: [], COLUMNS: [] });

  useEffect(() => {
    const { nodes, COLUMNS } = convertDataToNodesAndColumns(
      inputData,
      tags,
      showTag
    );
    setTableData({ nodes, COLUMNS });
  }, [inputData, tags, showTag]);

  const columns = useMemo(() => tableData.COLUMNS, [tableData.COLUMNS]);

  const table = useMantineReactTable({
    columns,
    data: tableData.nodes,
    enablePinning: true,
    initialState: {
      columnPinning: {
        right: showTag ? ["tags"] : [],
      },
    },
  });

  return <MantineReactTable table={table} />;
};

function Row({ rdata, row, tag, show }) {
  const cells = rdata.map((cell, idx) => {
    let newtag = tag?.filter((t) => parseInt(t.end) === idx);
    cell = cell.split(" ").map((word, index) => {
      let color = "";
      if (newtag?.length > 0) {
        newtag?.forEach((t) => {
          if (
            t.utteranceNo <= index &&
            index < t.utteranceNo + t?.text?.split(" ").length
          ) {
            color = t?.tagId?.highlightColor;
          }
        });
      }

      return (
        <span
          // eslint-disable-next-line react/no-unknown-property
          row={JSON.stringify(row)}
          // eslint-disable-next-line react/no-unknown-property
          col={JSON.stringify(idx)}
          // eslint-disable-next-line react/no-unknown-property
          index={index}
          key={index}
          style={{ backgroundColor: color }}
        >
          {word}{" "}
        </span>
      );
    });

    return <td key={idx}>{cell}</td>;
  });

  return (
    <tr style={{ overflow: "scroll", width: "fit-content" }}>
      {cells}
      <td className={styles.tagsToShow}>
        {show && (
          <div className={styles.tagsToShowUnder}>
            {tag?.map((tag, idx) => {
              return (
                <>
                  <span
                    key={idx}
                    className={styles.tag}
                    style={{ backgroundColor: tag?.tagId?.color }}
                  >
                    {tag?.tagId?.title}

                    <span style={{ opacity: 0.5, marginLeft: "5px" }}>
                      {tag?.frequency > 0 ? `${tag?.frequency}` : ""}
                    </span>
                    <FiX
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteTag(tag?._id);
                      }}
                    />
                  </span>
                </>
              );
            })}
            {tag?.length === 0 && <span className={styles.tags}> </span>}
          </div>
        )}
      </td>
    </tr>
  );
}

export default function CsvTable({ rawData, projectId, assetId, hideTagging }) {
  const tableRef = React.useRef(null);
  // const { darkMode } = useSelector((state) => state.theme);
  const [showTag, setShowTag] = React.useState(true);
  const [showPopUp, setShowPopUp] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(null);
  let { tags } = useSelector((state) => state.tags);
  tags = tags?.filter((tag) => tag.assetId === assetId);
  const data = Papa.parse(rawData);
  let tableHeaders = data.data[0]?.map((cell, idx) => (
    <th key={idx}>{cell}</th>
  ));
  if (showTag && tags?.length > 0) {
    tableHeaders?.push(<th className={styles.tagsHeader}></th>);
  }
  useEffect(() => {
    if (projectId) {
      getTagsByProjectId(projectId);
    }
  }, [projectId]);

  const rows = data.data?.map((rdata, idx) => {
    let show = false;
    if (tags.length > 0) {
      show = true;
    }
    if (showTag) {
      let tag = tags.filter((t) => {
        return parseInt(t.start) === idx;
      });

      if (tag && tag.length > 0) {
        return <Row key={idx} row={idx} rdata={rdata} tag={tag} show={show} />;
      } else {
        return <Row key={idx} row={idx} rdata={rdata} show={show} />;
      }
    }
    return <Row key={idx} row={idx} rdata={rdata} show={show} />;
  });

  // const variant = darkMode ? "dark" : "light";
  useEffect(() => {
    // add a mouseup event listener to the table
    const table = tableRef.current;
    table.addEventListener("mouseup", function (event) {
      const selection = window.getSelection();
      const selectedText = selection.toString();
      if (selectedText.length > 0) {
        setShowPopUp(true);
      }
    });
    
    return () => {
      table.removeEventListener("mouseup", function (event) {});
    };
  }, []);
  
  return (
    <>
      <AITagsModal assetId={assetId} projectId={projectId} />
      {(hideTagging!==true) && <><Form.Group controlId="formDarkMode" className="mt-sm-4 mt-2 mb-3 d-flex">
        <Form.Label className="d-inline">Show Tags</Form.Label>
        <Form.Check
          type="switch"
          style={{ display: "inline-block", marginLeft: "10px" }}
          label=""
          checked={showTag}
          onChange={() => setShowTag(!showTag)}
        />

        <AITagsButton />
      </Form.Group>
      <TagPopUpBtn
        isOpen={showPopUp}
        setIsOpen={setShowPopUp}
        projectId={projectId}
        assetId={assetId}
        data={selectedData}
        isDocument={true}
        isCSV={true}
        selectedUtterance={parseInt(selectedData?.startIndex)}
      /></>}
      <div
        ref={tableRef}
        onMouseUp={() => {
          handleMouseUp(setShowPopUp, setSelectedData, rows);
        }}
      >
        <DataTable inputData={data.data} tags={tags} showTag={showTag} />
      </div>
    </>
  );
}

function handleMouseUp(setShowPopUp, setSelectedData, rows) {
  const selection = window.getSelection();
  const selectedText = selection.toString();
  if (selectedText.length > 0) {
    let anchorNode = selection.anchorNode;
    let focusNode = selection.focusNode;

    // Find the td element for the anchor node
    while (anchorNode && anchorNode.nodeName !== "TD") {
      anchorNode = anchorNode.parentNode;
    }

    // Find the td element for the focus node
    while (focusNode && focusNode.nodeName !== "TD") {
      focusNode = focusNode.parentNode;
    }

    // Find the tr element and index for the anchor node
    let anchorRow = anchorNode;
    while (anchorRow && anchorRow.nodeName !== "TR") {
      anchorRow = anchorRow.parentNode;
    }
    const rowIndex = Array.from(anchorRow.parentNode.children).indexOf(
      anchorRow
    );

    // Find the tr element and index for the focus node
    let focusRow = focusNode;
    while (focusRow && focusRow.nodeName !== "TR") {
      focusRow = focusRow.parentNode;
    }
    const colIndex = Array.from(focusRow.children).indexOf(focusNode);
  
    // Use the rowIndex and colIndex to get the data
    setSelectedData({
      start: rowIndex,
      end: colIndex,
      data: selectedText,
    });

    setShowPopUp(true);
  }
}


