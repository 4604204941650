import React from "react";
import styles from "./index.module.css";
import first from "../../../../images/onboarding/first.png";
import second from "../../../../images/onboarding/second.png";
import third from "../../../../images/onboarding/third.png";
const page = [
  {
    title: "AI Insights and Tags",
    description:
      "Dive into AI-driven insights to start your exploration journey. Generate more AI insight categories to fit your specific research needs. Utilize AI tags for efficient affinity mapping of your transcripts.",
    image: first,
  },
  {
    title: "AI Chat",
    description:
      "Engage with your uploaded files to uncover key trends & patterns. Get AI-generated data visualizations, including flowcharts, bar charts, pie charts, and tables, for a detailed understanding of your data.",
    image: second,
  },
  {
    title: "Share reports and presentations",
    description:
      "Effortlessly share AI-powered reports and presentations with your team and stakeholders. Enrich your presentations with tools like Collections and Clips for a more impactful storytelling experience.",
    image: third,
  },
];

function Page({ number }) {
  const { title, description, image } = page[number];
  return (
    <div className={styles.page_container}>
      <div className={styles.page_image}>
        <img src={image} alt={title} />
      </div>
      <h1 className={styles.page_title}>{title}</h1>
      <p className={styles.page_description}>{description}</p>
    </div>
  );
}

export default Page;
