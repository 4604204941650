import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Menu, Tooltip } from "@mantine/core";
import { Button } from "react-bootstrap";
import backIconWhite from "../../../../images/icons/arrow-left-white.svg";
import styles from "../../index.module.css";
import TopNav from "../topNav";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { FiUpload } from "react-icons/fi";
import { TfiShare } from "react-icons/tfi";
import ProjectButton from "../buttons/secondaryButton";
import { updateProjectNameOrDesc } from "../../../../store/actions/projectActions";
import EditableTitle from "./components/EditableTitle";
import { supportedLanguages } from "./languages";
import { useDisclosure } from "@mantine/hooks";
import { Burger } from "@mantine/core";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PayWallModal from "../../../../components/paywallModal/paywallModal";
import { modalActions } from "../../../../store/slices/modalSlice";
import ShareProjectModal from "./components/shareProjectModal";
const tabs = [
  { name: "Data", value: "data" },
  { name: "Insights", value: "insights" },
  { name: "Chat", value: "chat" },
  { name: "Tags", value: "tags" },
  { name: "Collection", value: "collection" },
  { name: "Report", value: "report" },
  { name: "Presentation", value: "presentation" },
  { name: "Clips", value: "clips" },
  { name: "Settings", value: "settings" },
];

function TopBar({
  name,
  language,
  activeTab,
  setActiveTab,
  projectId,
  isUpload,
  selectedVideoId: assetId,
}) {
  const [opened, { toggle }] = useDisclosure();
  const [lang, setLang] = useState(language);
  const { account } = useSelector((state) => state.auth);
  const { project } = useSelector((state) => state.currentProject);
  const loading = project?.status === "Preparing";
  const dispatch = useDispatch();
  const isFreeOrBasic =
    account?.plan?.type === "Free" ||
    account?.plan?.type === "Basic Monthly" ||
    account?.plan?.type === "Basic Yearly";
  const handleUpdate = async (field, value) => {
    const payload = {
      _id: projectId,
      [field]: value,
    };

    try {
      let { data, error } = await updateProjectNameOrDesc(payload);

      if (error) {
        console.error(`Error updating ${field}:`, error);
        return;
      }

      if (data) {
        if (field === "language") {
          setLang(value);
          toast.success("Project language updated successfully");
        }
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };
  const history = useHistory();

  return (
    <div className={styles.topBarContainer}>
      <PayWallModal />
      <ShareProjectModal />
      <div className={styles.topBarContainer__title}>
        <button
          onClick={() => {
            if (project?.videos?.length > 0 && isUpload) {
              history.push("/project/" + projectId);
            } else {
              history.push("/projects");
            }
          }}
          className={styles.goBackBtn}
        >
          <img src={backIconWhite} alt="back" className="back-icon" />
        </button>

        <EditableTitle initialName={name} handleUpdate={handleUpdate} />
        <Tooltip
          label="Click to modify project language"
          position="bottom"
          offset={5}
        >
          <Menu
            shadow="md"
            width={200}
            className="menu-container"
            style={{
              fontSize: "0.7rem",
            }}
          >
            <Menu.Target>
              <Button variant="outline" color="blue" size="xs">
                {lang?.toLocaleLowerCase() === "detect" ? (
                  `🌐`
                ) : (
                  <span
                    className={`fi fi-${
                      supportedLanguages
                        ?.find(
                          (lan) =>
                            lan.code.toLocaleLowerCase() ===
                            lang?.toLocaleLowerCase()
                        )
                        ?.country_code?.toLowerCase() || "default"
                    }`}
                  ></span>
                )}{" "}
                {supportedLanguages?.find(
                  (lan) =>
                    lan.code.toLocaleLowerCase() === lang?.toLocaleLowerCase()
                )?.language || "Detect Language"}
              </Button>
            </Menu.Target>
            <Menu.Dropdown
              className="menu-dropdown"
              style={{ maxHeight: "50vh", overflow: "scroll" }}
            >
              {lang?.toLocaleLowerCase() !== "detect" && (
                <Menu.Item
                  onClick={() => {
                    handleUpdate("language", "detect");
                  }}
                >
                  🌐 Detect Language
                </Menu.Item>
              )}

              {supportedLanguages.map((lan) => {
                return (
                  <Menu.Item
                    style={{ color: "var(--grey6)" }}
                    key={lan.code}
                    onClick={() => {
                      handleUpdate("language", lan.code);
                    }}
                  >
                    <span
                      className={
                        `fi fi-${lan?.country_code?.toLowerCase()}` || "us"
                      }
                    ></span>{" "}
                    {lan.language}
                  </Menu.Item>
                );
              })}
            </Menu.Dropdown>
          </Menu>
        </Tooltip>

        <div className="processingSpinner">
          {loading && (
            <Spinner
              style={{ width: "20px", height: "20px" }}
              animation="border"
              variant="primary"
            />
          )}
        </div>
        <Burger
          className={styles.hiddenOnDesktop}
          style={{
            position: "absolute",
            right: "10px",
            top: "5px",
            color: "var(--grey10)",
          }}
          opened={opened}
          onClick={toggle}
          aria-label="Toggle navigation"
        />
      </div>
      {!isUpload && (
        <div className={`${styles.navContainer} ${opened ? "" : styles.hide}`}>
          <TopNav
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            tabs={tabs}
            projectId={projectId}
            toggle={toggle}
            assetId={assetId}
          />
          <div className={styles.topBarContainer__buttons}>
            <ProjectButton
              // color={"var(--pink)"}
              // backgroundColor={"rgba(0, 133, 255, 0.1)"}
              // size={"small"}
              // varient={"secondary"}

              size={"small"}
              varient={"secondary"}
              onClick={() => {
                history.push("/project/" + projectId + "/upload");
              }}
            >
              <FiUpload />
              Upload
            </ProjectButton>
            <ProjectButton
              color={"var(--grey1)"}
              backgroundColor={"var(--pink)"}
              size={"small"}
              varient={"primary"}
              onClick={() => {
                if (isFreeOrBasic) {
                  dispatch(modalActions.showPayWallModal());
                } else {
                  dispatch(modalActions.showShareProjectModal());
                }
              }}
            >
              <TfiShare />
              Share
            </ProjectButton>
          </div>
        </div>
      )}
    </div>
  );
}

export default TopBar;
