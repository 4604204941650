/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { MdInfo } from "react-icons/md";
import style from "./bottom-bar.module.css";

export function handleStep(stepNumber, testId) {
  switch (stepNumber) {
    case 1:
      return "/interviews/new";
    case 2:
      return `/interviews/ai-curated-interview/${testId}/describe-test`;
    case 3:
      return `/interviews/ai-curated-interview/${testId}/select-filter`;
    case 4:
      return `/interviews/ai-curated-interview/${testId}/screen`;
    case 5:
      return `/interviews/ai-curated-interview/${testId}/script`;
    case 6:
      return `/interviews/ai-curated-interview/${testId}/additional-details`;
    case 7:
      return `/interviews/ai-curated-interview/${testId}/review`;
    default:
      return "/interviews/new";
  }
}

function BottomBar({
  testId,
  currentStep,
  primaryButton = "Next",
  displaySkipButton = false,
  disableSkipButton = false,
  displayScriptInfo = false,
  onPrimaryButtonClick,
  onSkipButtonClick,
  disablePrimaryButton = false,
  primaryButtonLoading = false,
  estimatedTime,
}) {
  const {
    stepThreeChoice,
    currentTest: { script },
  } = useSelector((state) => state.test);
  const history = useHistory();

  const prevStep = handleStep(currentStep - 1, testId);
  const nextStep = handleStep(currentStep + 1, testId);

  function handleBackButton() {
    if (currentStep === 3) {
      history.push(`/interviews/ai-curated-interview/${testId}/select-filter`);
    } else if (currentStep === 4) {
      if (stepThreeChoice === "filter-audience") {
        history.push(
          `/interviews/ai-curated-interview/${testId}/filter-audience`
        );
      } else {
        history.push(
          `/interviews/ai-curated-interview/${testId}/add-participants`
        );
      }
    } else {
      history.push(prevStep);
    }
  }

  return (
    <div className={style.bottomBar}>
      <Button
        type="button"
        variant="secondary"
        className={style.backButton}
        onClick={() => handleBackButton()}
      >
        Back
      </Button>
      <div className={style.rightButtons}>
        {displaySkipButton && (
          <Button
            type="button"
            variant="outline-primary"
            className={style.skipButton}
            disabled={disableSkipButton}
            onClick={onSkipButtonClick}
          >
            Skip Screener
          </Button>
        )}
        {displayScriptInfo && (
          <div className={style.scriptInfo}>
            <MdInfo />
            <span>Estimated interview time: {estimatedTime || 0} minutes</span>
          </div>
        )}
        <Button
          type="button"
          variant="primary"
          className={style.primaryButton}
          onClick={onPrimaryButtonClick}
          disabled={disablePrimaryButton || primaryButtonLoading}
        >
          {primaryButtonLoading && (
            <>
              <Spinner
                animation="border"
                size="sm"
              />
              &nbsp;
            </>
          )}
          {primaryButton}
        </Button>
      </div>
    </div>
  );
}

export default BottomBar;
