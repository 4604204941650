import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Papa from "papaparse";
import { useDispatch, useSelector } from "react-redux";
import styles from "./csvTable.module.css";
// import AITagsButton from "../../atTagsButton";
// import TagPopUpBtn from "../../tagPopUpBtn";
import {
  deleteTag,
  getTagsByProjectId,
} from "../../../../store/actions/tagsActions";
import AITagsModal from "../modals/aiTagsModal";
import { FiX } from "react-icons/fi";
import { ribbonActions } from "../../../../store/slices/ribbonSlice";

function convertDataToNodesAndColumns(inputData, tags, showTag) {
  if (inputData.length === 0) return { nodes: [], COLUMNS: [] };

  // Extract column labels from the first data object's keys
  const columnLabels = Object.keys(inputData[0]);

  // Create a function to highlight text with tags
  function highlightTextWithTags(text, tagsForRow) {
    // Create a container element to hold the processed text
    const container = document.createElement("div");
    let lastIndex = 0;

    tagsForRow.forEach((tag) => {
      // Find the index of the tag text in the original text
      const index = text.indexOf(tag.text, lastIndex);
      if (index > -1) {
        // Add text before the tag text
        container.appendChild(
          document.createTextNode(text?.substring(lastIndex, index))
        );

        // Create the mark element for the tag text
        const mark = document.createElement("mark");
        mark.style.backgroundColor = tag.tagId.highlightColor;
        mark.appendChild(document.createTextNode(tag.text));
        container.appendChild(mark);

        // Update the last index to the end of the current tag text
        lastIndex = index + tag.text.length;
      }
    });

    // Add any remaining text after the last tag text
    container.appendChild(document.createTextNode(text?.substring(lastIndex)));

    return container.innerHTML;
  }

  // Map over inputData to create nodes
  const nodes = inputData.map((item, rowIndex) => {
    // Find tags for the row
    const tagsForRow = tags.filter((tag) => parseInt(tag.start) === rowIndex);

    // Reduce the item into a new object that has all original properties plus tags
    const node = columnLabels.reduce((acc, key) => {
      acc[key] = item[key];
      return acc;
    }, {});

    // Add a property for the highlighted text for each column
    columnLabels.forEach((label) => {
      node[label] = highlightTextWithTags(node[label], tagsForRow);
    });

    // Add tags information for the row
    node.tags = tagsForRow.map((tag) => ({
      name: `${tag.tagId.title}${
        tag.frequency !== undefined ? ` (${tag.frequency})` : ""
      }`,
      frequency: tag.frequency,
      color: tag.tagId.color,
      id: tag._id,
    }));

    return node;
  });

  // Create the column definitions, including the Tags column if showTag is true
  let COLUMNS = columnLabels.map((label) => ({
    accessorKey: label,
    header: label.charAt(0).toUpperCase() + label.slice(1), // Capitalize the first letter
    Cell: ({ cell, row }) => (
      <div dangerouslySetInnerHTML={{ __html: cell.getValue() }} />
    ),
  }));

  if (showTag) {
    COLUMNS.push({
      id: "tags",
      header: "Tags",
      Cell: ({ row }) => (
        <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
          {row.original.tags.map((tag, idx) => (
            <OverlayTrigger
              key={idx}
              placement="top"
              overlay={
                tag?.frequency ? (
                  <Tooltip id={`tooltip-${idx}`}>
                    Appears {tag?.frequency || 0}{" "}
                    {tag?.frequency === 1 ? "time" : "times"}
                  </Tooltip>
                ) : (
                  <Tooltip id={`tooltip-${idx}`}>Recently added</Tooltip>
                )
              }
            >
              <span
                key={idx}
                style={{
                  backgroundColor: tag.color,
                  padding: "2px 4px",
                  borderRadius: "4px",
                }}
              >
                {tag.name}
                <FiX
                  style={{ marginLeft: "5px", cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteTag(tag.id);
                  }}
                />
              </span>
            </OverlayTrigger>
          ))}
        </div>
      ),
    });
  }

  return { nodes, COLUMNS };
}

export const DataTable = ({ inputData, tags, showTag }) => {
  const [tableData, setTableData] = useState({ nodes: [], COLUMNS: [] });
  const dispatch = useDispatch();
  useEffect(() => {
    const { nodes, COLUMNS } = convertDataToNodesAndColumns(
      inputData,
      tags,
      showTag
    );
    setTableData({ nodes, COLUMNS });
  }, [inputData, tags, showTag]);

  const columns = useMemo(() => tableData.COLUMNS, [tableData.COLUMNS]);

  const table = useMantineReactTable({
    columns,
    autoResetPageIndex: false,
    mantineTableBodyRowProps: ({ row }) => ({
     
      onMouseUp: () => {
        handleMouseUp(dispatch,row.index);
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    data: tableData.nodes,
    enablePinning: true,
    initialState: {
      columnPinning: {
        right: showTag ? ["tags"] : [],
      },
    },
  });
 
  return <MantineReactTable table={table} />;
};



export default function CsvTable({ rawData, projectId, assetId }) {
  const tableRef = React.useRef(null);
  
  const { isPublic } = useSelector((state) => state.currentProject);
  let { tags } = useSelector((state) => state.tags);
  tags = tags?.filter((tag) => tag.assetId === assetId);
  const data = Papa.parse(rawData);
  let tableHeaders = data.data[0]?.map((cell, idx) => (
    <th key={idx}>{cell}</th>
  ));
  if (tags?.length > 0) {
    tableHeaders?.push(<th className={styles.tagsHeader}></th>);
  }
  useEffect(() => {
    if (projectId && tags?.length === 0 && !isPublic) {
      getTagsByProjectId(projectId);
    }
  }, [projectId, tags?.length,isPublic]);

 

  // const rows = data.data?.map((rdata, idx) => {
  //   let show = false;
  //   if (tags.length > 0) {
  //     show = true;
  //   }

  //   let tag = tags.filter((t) => {
  //     return parseInt(t.start) === idx;
  //   });

  //   if (tag && tag.length > 0) {
  //     return <Row key={idx} row={idx} rdata={rdata} tag={tag} show={show} />;
  //   } else {
  //     return <Row key={idx} row={idx} rdata={rdata} show={show} />;
  //   }

  //   // return <Row key={idx} row={idx} rdata={rdata} show={show} />;
  // });

  return (
    <>
      {!isPublic && <AITagsModal assetId={assetId} projectId={projectId} />}

      <div
        ref={tableRef}
       
      >
        <DataTable inputData={data.data} tags={tags} showTag={true} />
      </div>
    </>
  );
}

function handleMouseUp(dispatch,rowIndex) {
  const selection = window.getSelection();
  const selectedText = selection.toString();
  if (selectedText.length > 0) {
    let anchorNode = selection.anchorNode;
    let focusNode = selection.focusNode;

    // Find the td element for the anchor node
    while (anchorNode && anchorNode.nodeName !== "TD") {
      anchorNode = anchorNode.parentNode;
    }

    // Find the td element for the focus node
    while (focusNode && focusNode.nodeName !== "TD") {
      focusNode = focusNode.parentNode;
    }

    // Find the tr element and index for the anchor node
    let anchorRow = anchorNode;
    while (anchorRow && anchorRow.nodeName !== "TR") {
      anchorRow = anchorRow.parentNode;
    }
   
    // Find the tr element and index for the focus node
    let focusRow = focusNode;
    while (focusRow && focusRow.nodeName !== "TR") {
      focusRow = focusRow.parentNode;
    }
    const colIndex = Array.from(focusRow.children).indexOf(focusNode);
  
    // Use the rowIndex and colIndex to get the data
    dispatch(
      ribbonActions.setRibbonData({
        name: "selectionData",
        value: {
          start: rowIndex,
          end: colIndex,
          data: selectedText,
        },
      })
    );
    dispatch(
      ribbonActions.setRibbonData({
        name: "selectedUtterance",
        value: parseInt(rowIndex),
      })
    );
    
  }
}
