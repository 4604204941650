import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { Spinner, Pagination, Form, Container } from "react-bootstrap";
import styles from "./style.module.css";
import ImportModal from "./ImportModal";
import { getIntegrationApps } from "../../store/actions/integrationActions";
import { loadAssets } from "../../store/actions/mediaLibraryActions";
// import { NewAsset } from "../project-vnext/components/new-asset";
import { PageLayout } from "../../components/page-layout";
import PreviewModal from "./PreviewModal";
// import ImportFromDriveButon from "./ImportFromDrive";
import FileCard from "./fileCard";
import AddNewFileBtn from "./AddNewFileBtn";
import FilesMoreBtn from "./FilesMoreBtn";
import ControlledInput from "./ControlledInput";
import EmptyState from "./emptyState";
import { debounce } from "lodash";
import { FaCaretDown } from "react-icons/fa";

const pageSizeOptions = [10, 25, 50, 100];
export const MediaLibraryPage = () => {
  const [show, setShow] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState();
  const [assetsLoaded, setAssetsLoaded] = useState(false);
  const [appsLoaded, setAppsLoaded] = useState(false);
  // const apps = useSelector((state) => state.integrations.apps);
  const [isLoading, setIsLoading] = useState(true);
  const { assets } = useSelector((state) => state.mediaLibrary);
  const [assetToShow, setAssetToShow] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);
  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentAssets = assetToShow.slice(indexOfFirstItem, indexOfLastItem);
  const input1 = useRef();

  useEffect(() => {
    if (assets) {
      setAssetToShow(assets);
      setIsLoading(false);
    }
  }, [assets]);

  useEffect(() => {
    const fetchData = async () => {
      if (!assetsLoaded) {
        setIsLoading(true);
        await loadAssets();
        setAssetsLoaded(true);
      }
      if (!appsLoaded) {
        await getIntegrationApps();
        setAppsLoaded(true);
      }
    };
    fetchData();
  }, [assetsLoaded, appsLoaded]);

  const handleClose = () => setShow(false);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  const handleUpdate = useCallback(
    debounce((val) => {
      setAssetToShow(
        assets.filter(
          (asset) =>
            asset.name.toLowerCase().includes(val.toLowerCase()) ||
            asset.tags.some((tag) =>
              tag.title.toLowerCase().includes(val.toLowerCase())
            )
        )
      );
    }, 500),
    [assets]
  );

  return (
    <PageLayout>
      <PreviewModal
        asset={selectedVideo}
        onClose={() => setSelectedVideo(null)}
      />
      <div className={styles.wrapper}>
        <div className={styles.mediaLibraryContainer}>
          <ImportModal show={show} onClose={handleClose} />
          <Container>
            <div className={`${styles.mediaLibraryHeader}`}>
              <div style={{ minWidth: 300, width: "33%", fontWeight: "600" }}>
                Files
              </div>

              <ControlledInput
                onUpdate={(val) => {
                  input1.current = val;
                  handleUpdate(val);
                }}
              />

              <div className={styles.importButtonGroup}>
                <AddNewFileBtn />
                <FilesMoreBtn />
              </div>
            </div>
          </Container>
          <div className={styles.assetsContainer}>
            {isLoading ? (
              <Spinner style={{ textAlign: "center" }} />
            ) : currentAssets.length === 0 ? (
              <EmptyState />
            ) : (
              currentAssets.map((asset, index) => {
                return (
                  <React.Fragment key={index}>
                    <FileCard
                      file={asset}
                      setSelectedVideo={setSelectedVideo}
                    />
                  </React.Fragment>
                );
              })
            )}
          </div>
        </div>
        {assets?.length > 10 ? (
          <div className={styles.bottomBar}>
            <Pagination className={styles.pagination}>
              <Pagination.First
                disabled={currentPage === 1}
                className={styles.paginationItem}
                onClick={() => handlePageChange(1)}
                style={{ fontSize: "1.25rem" }}
              />

              <Pagination.Prev
                disabled={currentPage === 1}
                style={{ fontSize: "1.25rem" }}
                onClick={() =>
                  handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
                }
              />

              <Pagination.Item
                disabled={true}
                className={styles.paginationItem}
              >
                {currentPage}
              </Pagination.Item>

              <Pagination.Next
                style={{ fontSize: "1.25rem" }}
                onClick={() =>
                  handlePageChange(
                    currentPage < Math.ceil(assetToShow.length / pageSize)
                      ? currentPage + 1
                      : currentPage
                  )
                }
              />
              <Pagination.Last
                className={styles.paginationItem}
                style={{ fontSize: "1.25rem" }}
                onClick={() =>
                  handlePageChange(Math.ceil(assetToShow.length / pageSize))
                }
              />
            </Pagination>
            <div className={styles.bottomBarRight}>
              <div
                style={{ fontSize: "0.95rem", fontWeight: "500" }}
              >{`Showing ${indexOfFirstItem + 1}-${Math.min(
                indexOfLastItem,
                assetToShow.length
              )} of ${assetToShow.length}`}</div>
              <Form.Select
                className={styles.formSelect + " dropup"}
                onChange={handlePageSizeChange}
                value={pageSize}
              >
                {pageSizeOptions.map((size, index) => (
                  <option key={index} value={size}>
                    {size}
                  </option>
                ))}
                <FaCaretDown style={{ right: "0px", position: "absolute" }} />
              </Form.Select>
            </div>
          </div>
        ) : null}
      </div>
    </PageLayout>
  );
};
