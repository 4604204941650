import React from "react";
import { useSelector } from "react-redux";
import style from "./cover-image.module.css";
import { darkImages, lightImages } from "../../../images/project-images";
import { hashProjectId } from "../../participants/profile/components/cover-image";

export default function CoverImage({ projectDataId }) {
  const { darkMode } = useSelector((state) => state.theme);

  return (
    <div
      className={style.coverImage}
      style={{
        backgroundImage: `url(${
          darkMode
            ? darkImages[hashProjectId(projectDataId)]
            : lightImages[hashProjectId(projectDataId)]
        })`,
      }}
    ></div>
  );
}
