import React, { useState } from "react";
import styles from "./tagsAndInsightToggle.module.css";
import AITagsButton from "./atTagsButton";
import { useEffect } from "react";
function TagsAndInsightToggle({
  showTag,
  setShowTag,
  showInsight,
  setShowInsight,
}) {
  const [selectedTab, setSelectedTab] = useState("");
  useEffect(() => {
    if (showTag && !showInsight) {
      setSelectedTab("tags");
    }
    if (showInsight && !showTag) {
      setSelectedTab("insights");
    }
  }, [selectedTab]);


  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <div className={styles.buttonsContainer}>
      <div className={styles.TagsAndInsightToggle}>
        <button
          className={showTag ? styles.active : ""}
          style={{ borderWidth: selectedTab === "" ? "1px" : "" }}
          onClick={() => {
            if (selectedTab === "tags") {
              setShowTag(false);
              setSelectedTab("");
            }
            if (selectedTab === "") {
              setShowTag(true);
              setShowInsight(false);
              setSelectedTab("tags");
            }

            if (selectedTab === "insights") {
              handleTabClick("tags");
              setShowTag(true);
              setShowInsight(false);
            }
          }}
        >
          View Tags
        </button>
        <button
          className={showInsight ? styles.active : ""}
          onClick={() => {
            if (selectedTab === "insights") {
              setShowInsight(false);
              setSelectedTab("");
            }
            if (selectedTab === "") {
              setShowInsight(true);
              setShowTag(false);
              setSelectedTab("insights");
            }
            if (selectedTab === "tags") {
              handleTabClick("insights");
              setShowTag(false);
              setShowInsight(true);
            }
          }}
        >
          View Insights
        </button>
      </div>
      <AITagsButton />
    </div>
  );
}




export default TagsAndInsightToggle;
